import React, { PureComponent } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';

function removeSeparators(options, values) {
    if (!values) return options;
    return options.filter((option) => {
        if (option.separator) {
            return option.separator.reduce(
                (acc, curr) => acc || values.indexOf(curr) === -1,
                false
            );
        }

        return true;
    });
}

class RenderReactSelect extends PureComponent {
    render() {
        const {
            input,
            placeholder,
            label,
            options,
            readOnly, //to only show the value of the field
            disabled, //to only disable the value of the field
            className,
            multi,
            clearable,
            showLabel
        } = this.props;

        return (
            <FormGroup
                className={
                    'custom-select-wrapper floating-label-wrapper react-select-wrapper' +
                    (readOnly !== undefined && readOnly === true
                        ? ' readonly '
                        : ' ') +
                    (disabled ? ' disabled ' : ' ') +
                    (className !== undefined ? className : '') +
                    (this.props.meta.touched && this.props.meta.error
                        ? ' error'
                        : '')
                }
            >
                {(showLabel === undefined || showLabel) && (
                    <Label
                        className={
                            (this.props.meta !== undefined &&
                                ((multi && input.value.length > 0) ||
                                    (!multi && input.value !== '')) &&
                                !this.props.meta.error) ||
                            readOnly
                                ? 'float top'
                                : 'float'
                        }
                    >
                        {label !== undefined ? label : placeholder}
                    </Label>
                )}

                <Select
                    {...input}
                    value={
                        input.value && input.value[0] !== undefined
                            ? input.value[0].name
                                ? input.value.map((obj) => obj.name)
                                : input.value
                            : input.value
                            ? input.value
                            : null
                    }
                    placeholder={
                        readOnly
                            ? input.value || '-'
                            : label !== undefined
                            ? label
                            : placeholder
                    }
                    alwaysOpen={true}
                    onChange={
                        multi === true
                            ? (value) => {
                                  return input.onChange(
                                      value.map((p) => p.value)
                                  );
                              }
                            : (value) => {
                                  return input.onChange(
                                      value ? value.value : null
                                  );
                              }
                    }
                    onBlur={
                        multi === true
                            ? () => input.onBlur([...input.value])
                            : () => {}
                    }
                    options={
                        this.props.separators
                            ? removeSeparators(this.props.options, input.value)
                            : options
                    }
                    multi={multi === true ? true : false}
                    disabled={readOnly ? readOnly : false}
                    clearable={clearable !== undefined ? clearable : true}
                />
                <div
                    className={
                        this.props.meta.touched && this.props.meta.error
                            ? 'float error top'
                            : 'float error'
                    }
                >
                    {this.props.meta.error}
                </div>
                {this.props.help && (
                    <div className="select-help-text">{this.props.help}</div>
                )}
            </FormGroup>
        );
    }
}
export default RenderReactSelect;
