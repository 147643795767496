import React, { Component } from 'react';
import { Field } from 'redux-form';

import ReactSVG from 'react-svg';

import RenderField from '../../common/components/RenderField';
import add from '../../assets/svgs/add.svg';
import remove from '../../assets/svgs/remove.svg';

import { videoUrl } from '../../helpers/validate';

import UploadField from '../../common/components/UploadField';

class RenderVideoUrl extends Component {
    render() {
        const { fields } = this.props;
        return (
            <ul className="list-unstyled brokers-list yacht-video-list">
                {!this.props.readOnly && (
                    <li className="mb-3">
                        <button
                            className="btn btn-blue"
                            type="button"
                            onClick={() => fields.push()}
                        >
                            <ReactSVG path={add} style={{ width: 12 }} /> Add
                            video url
                        </button>
                    </li>
                )}

                {fields.map((video, index) => (
                    <li key={index} className="row">
                        <UploadField
                            accept="video/*"
                            name={`${video}.url`}
                            className="video-upload-button yacht-upload-video col-1"
                            type="single"
                            buttonOnly={true}
                            formName={this.props.meta.form}
                            initPhoto={this.props.user_photo}
                        />
                        <Field
                            name={`${video}.url`}
                            type="text"
                            placeholder="VIDEO URL"
                            readOnly={this.props.readOnly}
                            component={RenderField}
                            validate={[videoUrl]}
                            autoComplete={false}
                            className="col pl-0 ml-1"
                        />

                        {!this.props.readOnly && (
                            <button
                                className="btn btn-empty btn-remove"
                                type="button"
                                onClick={() => fields.remove(index)}
                            >
                                <ReactSVG path={remove} style={{ width: 16 }} />
                            </button>
                        )}
                    </li>
                ))}
            </ul>
        );
    }
}

export default RenderVideoUrl;
