import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';

import Header from '../../../common/header/Header';

import preview from '../../../assets/svgs/preview.svg';
import yachts from '../../../assets/svgs/yachts.svg';

class YachtMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                parent: yachts
            }
        };
    }

    componentDidMount() {
        const type = this.props.match.params.viewType; // If is charter or sales
        const view = this.props.match.path.includes('edit') ? 'edit' : 'view';
        const more = this.props.match.path.includes('more');
        const id = this.props.match.params.id;
        const is_broker = this.props.user_role.includes('broker');
        let should_show_action_btn = true;
        let menu = [];

        if (
            this.props.match.path === 'yachts/create' ||
            this.props.match.url.includes('bookings') ||
            this.props.match.url.includes('testimonials') ||
            is_broker
        ) {
            should_show_action_btn = false;
        }

        if (this.props.match.url === `/yachts/${type}/create`) {
            menu = [
                <NavLink exact to={`/yachts/create`}>
                    ADD NEW YACHT
                </NavLink>
            ];
        } else {
            menu = [
                <NavLink exact to={`/yachts/${view}/${type}/${id}`}>
                    SPECS
                </NavLink>
            ];

            if (view === 'edit') {
                menu = [
                    ...menu,
                    <NavLink exact to={`/yachts/${view}/${type}/${id}/more`}>
                        More Info
                    </NavLink>
                ];
            }

            if (!is_broker) {
                menu = [
                    ...menu,
                    <NavLink
                        exact
                        to={`/yachts/${view}/${type}/${id}/presentation/en`}
                    >
                        Website
                    </NavLink>
                ];
            }

            menu = [
                ...menu,
                <NavLink exact to={`/yachts/${view}/${type}/${id}/photos-docs`}>
                    Photos & Docs
                </NavLink>,
                <NavLink exact to={`/yachts/${view}/${type}/${id}/videos`}>
                    Videos
                </NavLink>
            ];

            if (type === 'charter') {
                menu = [
                    ...menu,
                    <NavLink
                        exact
                        to={`/yachts/${view}/charter/${id}/locationsAndRates`}
                    >
                        Locations & Rates
                    </NavLink>,
                    <NavLink
                        exact
                        to={`/yachts/view/charter/${id}/bookings`}
                        isActive={(e) =>
                            this.props.match.url.includes('bookings')
                        }
                    >
                        Bookings
                    </NavLink>,
                    <NavLink
                        exact
                        to={`/yachts/view/charter/${id}/testimonials`}
                    >
                        Testimonials
                    </NavLink>
                ];
            }

            if (should_show_action_btn) {
                let path =
                    view === 'edit'
                        ? this.props.match.url.replace('edit', 'view')
                        : this.props.match.url.replace('view', 'edit');

                menu = [
                    ...menu,
                    {
                        link: (
                            <NavLink
                                className="yacht-nav-link yacht-nav-link-edit"
                                exact
                                to={more ? path.replace('/more', '') : path}
                            >
                                <span>
                                    <ReactSVG
                                        path={preview}
                                        style={{ width: 16 }}
                                    />{' '}
                                    {view === 'edit' ? 'View' : 'Edit'}
                                </span>
                            </NavLink>
                        ),
                        className: 'ml-auto mr-0'
                    }
                ];
            }
        }

        this.setState({
            menu: {
                ...this.state.menu,
                items: menu
            }
        });
    }

    render() {
        return <Header menu={this.state.menu} history={this.props.history} />;
    }
}

export default YachtMenu;
