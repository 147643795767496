import React, { Component } from 'react';

import { Creatable } from 'react-select-v2';

export default class CreatableMulti extends Component {
    render() {
        const {
            options,
            isValid = true,
            value,
            disabled,
            placeholder
        } = this.props;
        const customStyles = {
            control: (base, state) => ({
                ...base,
                borderColor: state.isFocused
                    ? '#ddd'
                    : isValid
                    ? '#ddd'
                    : 'red',
                // overwrittes hover style
                '&:hover': {
                    borderColor: state.isFocused
                        ? '#ddd'
                        : isValid
                        ? '#ddd'
                        : 'red'
                }
            })
        };
        return (
            <Creatable
                isMulti
                isDisabled={disabled}
                value={value}
                onChange={this.props.onChange}
                isValid={isValid}
                styles={customStyles}
                options={options}
                placeholder={placeholder}
            />
        );
    }
}
