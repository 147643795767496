import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';

class RenderToggle extends Component {
    render() {
        const { input, label, name } = this.props;

        return (
            <FormGroup
                className={
                    'floating-label-wrapper toggle-wrapper ' +
                    (this.props.className !== undefined
                        ? this.props.className
                        : '')
                }
            >
                <div className="toggle">
                    <div
                        className={
                            'toggle-label ' +
                            (input.value === true ||
                            input.value === 1 ||
                            input.value === '1'
                                ? 'active'
                                : '')
                        }
                    >
                        {label}
                    </div>
                    <label htmlFor={name}>
                        <input
                            type="checkbox"
                            checked={
                                input.value === true ||
                                input.value === 1 ||
                                input.value === '1'
                                    ? true
                                    : false
                            }
                            id={name}
                            {...input}
                        />
                        <span />
                    </label>
                </div>
            </FormGroup>
        );
    }
}
export default RenderToggle;
