import React from 'react';
import { connect } from 'react-redux';
import {
    Field,
    FieldArray,
    reduxForm,
    change,
    formValueSelector
} from 'redux-form';
import { Row, Col, UncontrolledTooltip, Button } from 'reactstrap';
import moment from 'moment';

import OffersMenu from './components/OffersMenu';
import Loader from '../../../common/components/Loader';
import YachtsSelection from '../../yachts/components/YachtsSelection';
import SimpleModal from '../../../common/components/SimpleModal';
import Instructions from '../../../common/components/Instructions';

// Fields
import RenderField from '../../../common/components/RenderField';
import RenderCheckbox from '../../../common/components/RenderCheckbox';
import RenderDatePicker from '../../../common/components/RenderDatePicker';
import RenderTags from '../../../common/components/RenderTags';
import RenderRedactor from '../../../common/components/RenderRedactor';
import RenderReactSelect from '../../../common/components/RenderReactSelect';
import RenderOfferAttachments from './components/RenderOfferAttachments';
import Collapsable from '../../../common/components/Collapsable';
import UploadField from '../../../common/components/UploadField';

// Helpers
import { required, isBoolean, email } from 'helpers/validate';

// SVG
import full from 'assets/img/basic.jpg';
import comparison from 'assets/img/comparison.jpg';
import summary from 'assets/img/summary.jpg';
import { CUSTOMERS_URL } from 'helpers/variables';

const pdfPreviews = {
    full: full,
    comparison: comparison,
    summary: summary
};

const styleProposalLarge = 'font-size: 18px; font-weight: bold;';
const styleProposalfooter = 'font-size: 11px;';

const charterDefaultEmailContentBefore = (clientName) => {
    return `<p>Dear ${clientName},</p>
    <p>Thank you for your enquiry! We are delighted to be assisting you with your charter plans.</p>
    <p>I have selected some of the best yachts available based on your dates, location and preferences.</p>`;
};
const charterDefaultEmailContentAfter = (broker) => {
    return `
    <p>Once I have received your feedback on the initial selection linked above, we can review possible alternatives, look at yacht specifics (e.g. sample menus) and discuss itinerary options so that you make the best informed decision. I am here to support you throughout your charter, from the moment you book, to the time you step off the yacht; so if you have any questions at all, please do not hesitate to reach out!</p>
    <p>I have experience organizing yacht charters throughout the world, so should any of your plans change - be it dates, location or number of guests - please do let me know so I can resend you options accordingly.</p>
    <p>On behalf of IYC, we look forward to working with you and creating the most incredible charter experience!</p>
    <p>Kindest regards</p>
    <p>${broker}</p><br/>
    <p style ="${styleProposalLarge}">Useful information for first time charterers</p>
    <p><strong>Additional charter information</strong></p>
    <p>The Charter Fee includes: The yacht with crew service and all necessary equipment, properly insured for marine risks. All other expenses, including VAT, provisioning, food & beverage, fuel charges, communications costs, canal fees, harbor dues, local taxes and specific marina fees are taken from your prepaid expenses allowance (see definition below for APA). If embarkation and/or disembarkation take place at a different location from the yacht's home port, delivery/return fees and expenses will also be charged.</p>
    <p><strong>APA</strong></p>
    <p>Advanced Provisioning Allowance (APA) is the extra fee that you pay in advance to cover any running expenses while on board (e.g. transfers, fuel, food, berthing dues, harbor charges, agency fees, additional watertoys = requested etc.). The final APA % is determined by the vessel and is usually estimated between 30% - 40% of the Charter Fee. APA is handled by the Captain, who is required to keep an itemised  report of all expenses incurred and will present to the client before disembarkation. Any remaining funds will be returned to the charterer (you) either in cash or by bank transfer at the end of the charter. Alternatively, some charterers choose to leave any leftover APA for crew gratuity.</p>
    <p><strong>Crew Gratuity</strong></p>
    <p>It is customary to tip your crew at the end of your charter, however this is always at your discretion. The amount is usually between 10-20% of the charter fee (excluding APA and Taxes/VAT). There are a few different ways to leave gratuity, if there is an unused APA, bank transfer or Cash. You can of course send us an amount that can be held in our client escrow, upon disembarkation you can let us know how much you would like to have released to the crew.</p>
    <p>You can find more useful information in the <a href='https://iyc.com/luxury-yacht-charters/#faq'>Frequently Asked Questions</a> section on our website.</p></br>
    <hr style ="height: 0.2px;  background-color: #666666;"/>
    <p style ="${styleProposalfooter}">The information presented is subject to change as a result of new information, changes in circumstances, prevailing conditions and availability. This information does not constitute a final binding offer or acceptance of an offer. Final details will be agreed in writing via a binding contract.</p>`;
};

let ManageCharterOfferPresenter = (props) => {
    const previewLink = `${CUSTOMERS_URL}/proposals/${props?.initialValues?.url}/?preview=1`;
    // commented out after request DV-587
    // const [rateDateFrom, setRateDateFrom] = useState(
    //     props.initialValues.rate_date_from
    // );
    // const [rateDateTo, setRateDateTo] = useState(
    //     props.initialValues.rate_date_to
    // );

    return (
        <div className="content-inner-wrapper">
            <OffersMenu
                history={props.history}
                match={props.match}
                user_role={props.user_role}
                customer={props.customer}
            />

            <div className="content-inner">
                {props.loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <form onSubmit={props.handleSubmit(props.onSubmit)}>
                        <Row>
                            <Col xs="12" md="8" lg="9">
                                <Row>
                                    {props.match.path.includes('/create') ? (
                                        <div className="row">
                                            <div className="col-8">
                                                <div className="instructions instructions--info mt-0 ">
                                                    <p className="instructions__title">
                                                        If you wish to change
                                                        the order of the yachts,
                                                        you need to do so in the
                                                        <strong>
                                                            {' '}
                                                            Yachts{' '}
                                                        </strong>
                                                        page, in your
                                                        <strong>
                                                            {' '}
                                                            Current Selection{' '}
                                                        </strong>
                                                        sidebar,
                                                        <strong>
                                                            {' '}
                                                            before creating the
                                                            proposal
                                                        </strong>
                                                        .
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}

                                    <Col xs="12">
                                        <div className="name-wrapper no-actions">
                                            <Field
                                                name="name"
                                                type="text"
                                                placeholder="Insert proposal title (visible to client on PDF attachment)"
                                                className="mt-2"
                                                readOnly={props.read_only}
                                                disabled={props.read_only}
                                                onChange={(
                                                    event,
                                                    new_value
                                                ) => {
                                                    let url = new_value
                                                        .toLowerCase()
                                                        .replace(/(\s)/g, '-')
                                                        .replace(
                                                            /[&|?|#/\\\\]/g,
                                                            ''
                                                        );
                                                    props.changeFieldValue(
                                                        'url',
                                                        url +
                                                            '-' +
                                                            moment().unix()
                                                    );
                                                }}
                                                validate={[required]}
                                                component={RenderField}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs="12" md="6">
                                        <Field
                                            name="customer_name"
                                            type="text"
                                            className="mb-2"
                                            readOnly={true}
                                            placeholder="CLIENT NAME"
                                            component={RenderField}
                                        />
                                    </Col>

                                    <Col xs="12" md="6">
                                        <Field
                                            name="customer_email"
                                            type="email"
                                            className="mb-2"
                                            readOnly={true}
                                            placeholder="EMAIL"
                                            component={RenderField}
                                        />
                                    </Col>

                                    <Col
                                        xs="12"
                                        md="6"
                                        lg="4"
                                        className="d-flex justify-content-between"
                                    >
                                        <Field
                                            name="expires"
                                            component={RenderDatePicker}
                                            placeholder="PROPOSAL VISIBLE UNTIL"
                                            date_format="YYYY/MM/DD"
                                            readOnly={props.read_only}
                                        />

                                        {!props.read_only && (
                                            <div
                                                className="btn btn-indication"
                                                id="info-button"
                                            >
                                                i
                                            </div>
                                        )}
                                        {!props.read_only && (
                                            <UncontrolledTooltip
                                                placement="bottom"
                                                target="info-button"
                                            >
                                                Your contact will be able to
                                                access the URL with your
                                                proposal until the date that you
                                                set here. The proposal link will
                                                expire after this date.
                                            </UncontrolledTooltip>
                                        )}
                                    </Col>

                                    <Col xs="12">
                                        <Field
                                            name="template_logo"
                                            normalize={isBoolean}
                                            type="checkbox"
                                            label="Broker Friendly (no logo)"
                                            disabled={props.read_only}
                                            component={RenderCheckbox}
                                            className="mt-2"
                                        />
                                    </Col>

                                    {props.match.params.type === 'charter' && (
                                        <Col xs="12">
                                            <Row>
                                                <Col xs="12" md="5">
                                                    <Field
                                                        name="rate_location_range_from"
                                                        component={
                                                            RenderDatePicker
                                                        }
                                                        placeholder="RATES DISPLAYED (Date From)"
                                                        date_format="YYYY/MM/DD"
                                                        readOnly={
                                                            props.read_only
                                                        }
                                                    />
                                                </Col>

                                                <Col xs="12" md="5">
                                                    <Field
                                                        name="rate_location_range_to"
                                                        component={
                                                            RenderDatePicker
                                                        }
                                                        placeholder="RATES DISPLAYED (Date To)"
                                                        date_format="YYYY/MM/DD"
                                                        readOnly={
                                                            props.read_only
                                                        }
                                                    />
                                                </Col>

                                                {!props.read_only && (
                                                    <Col xs="1">
                                                        <div
                                                            className="btn btn-indication mt-1"
                                                            id="info-button-date-range"
                                                        >
                                                            i
                                                        </div>

                                                        <UncontrolledTooltip
                                                            placement="bottom"
                                                            target="info-button-date-range"
                                                        >
                                                            Select charter dates
                                                            to show yacht’s
                                                            weekly rate for the
                                                            respective period.
                                                        </UncontrolledTooltip>
                                                    </Col>
                                                )}
                                            </Row>
                                            {/* Commented out after request DV-587 */}
                                            {/* <Row>
                                                <Col xs="12" md="5">
                                                    <Field
                                                        name="rate_date_from"
                                                        component={
                                                            RenderDatePicker
                                                        }
                                                        placeholder="RATE DATE FROM"
                                                        date_format="YYYY/MM/DD"
                                                        readOnly={
                                                            props.read_only
                                                        }
                                                        validate={
                                                            rateDateTo &&
                                                            !rateDateFrom
                                                                ? [required]
                                                                : null
                                                        }
                                                        onChange={(e) =>
                                                            setRateDateFrom(e)
                                                        }
                                                    />
                                                </Col>

                                                <Col xs="12" md="5">
                                                    <Field
                                                        name="rate_date_to"
                                                        component={
                                                            RenderDatePicker
                                                        }
                                                        placeholder="RATE DATE TO"
                                                        date_format="YYYY/MM/DD"
                                                        readOnly={
                                                            props.read_only
                                                        }
                                                        validate={
                                                            rateDateFrom &&
                                                            !rateDateTo
                                                                ? [required]
                                                                : null
                                                        }
                                                        onChange={(e) =>
                                                            setRateDateTo(e)
                                                        }
                                                    />
                                                </Col>

                                                {!props.read_only && (
                                                    <Col xs="1">
                                                        <div
                                                            className="btn btn-indication"
                                                            id="info-button-rate-date"
                                                        >
                                                            i
                                                        </div>

                                                        <UncontrolledTooltip
                                                            placement="bottom"
                                                            target="info-button-rate-date"
                                                        >
                                                            Provide a date range
                                                            to calculate yacht
                                                            rates in website.
                                                        </UncontrolledTooltip>
                                                    </Col>
                                                )}
                                            </Row> */}
                                        </Col>
                                    )}

                                    <Col xs="12">
                                        <Field
                                            name="email_subject"
                                            type="text"
                                            className="mb-2"
                                            placeholder="EMAIL SUBJECT"
                                            component={RenderField}
                                            readOnly={props.read_only}
                                        />
                                    </Col>

                                    <Col>
                                        <RenderTags
                                            readOnly={props.read_only}
                                            changeFieldValue={
                                                props.changeFieldValue
                                            }
                                            newTagName="new_tag_cc"
                                            validators={[email]}
                                            newTag={props.new_tag_cc}
                                            tagPrefix="recipients_cc"
                                            className="mb-2 mt-1 f-width"
                                            name="CC"
                                        />
                                    </Col>

                                    <Col>
                                        <RenderTags
                                            readOnly={props.read_only}
                                            changeFieldValue={
                                                props.changeFieldValue
                                            }
                                            newTagName="new_tag_bcc"
                                            validators={[email]}
                                            newTag={props.new_tag_bcc}
                                            tagPrefix="recipients_bcc"
                                            className="mb-2 mt-1 f-width"
                                            name="BCC"
                                        />
                                    </Col>

                                    {props.match.params.type === 'charter' && (
                                        <hr className="my-2 filter-divider" />
                                    )}

                                    {props.match.params.type === 'charter' && (
                                        <Row>
                                            <Col xs="8">
                                                <label className="upload-label text-uppercase mt-0">
                                                    Itinerary links
                                                </label>
                                            </Col>
                                            {!props.read_only && (
                                                <Col xs="1">
                                                    <div
                                                        className="btn btn-indication"
                                                        id="info-itinerary-links"
                                                    >
                                                        i
                                                    </div>

                                                    <UncontrolledTooltip
                                                        placement="bottom"
                                                        target="info-itinerary-links"
                                                    >
                                                        Paste your itinerary
                                                        from
                                                        Charteritinerary.com.
                                                        You can only paste one
                                                        itinerary at a time -
                                                        not both.
                                                    </UncontrolledTooltip>
                                                </Col>
                                            )}
                                        </Row>
                                    )}

                                    {props.match.params.type === 'charter' && (
                                        <>
                                            <Col xs="11">
                                                <Field
                                                    name="itinerary_url"
                                                    type="text"
                                                    className="mb-2"
                                                    placeholder="FINAL ITINERARY URL"
                                                    component={RenderField}
                                                    readOnly={props.read_only}
                                                    onBlur={(event) =>
                                                        !props.read_only
                                                            ? props.onChangeItineraryUrl(
                                                                  event
                                                              )
                                                            : null
                                                    }
                                                />
                                            </Col>

                                            {!props.read_only && (
                                                <Col xs="1">
                                                    <div
                                                        className="btn btn-indication mt-1"
                                                        id="info-final-itinerary-url"
                                                    >
                                                        i
                                                    </div>

                                                    <UncontrolledTooltip
                                                        placement="bottom"
                                                        target="info-final-itinerary-url"
                                                    >
                                                        Paste “Final Link” from
                                                        Charteritinerary.com
                                                    </UncontrolledTooltip>
                                                </Col>
                                            )}

                                            {/* <Col xs="11">
                                                <Field
                                                    name="itinerary_captain_url"
                                                    type="text"
                                                    className="mb-2"
                                                    placeholder="CAPTAIN REVIEW URL"
                                                    component={RenderField}
                                                    readOnly={props.read_only}
                                                    onBlur={(event) =>
                                                        !props.read_only
                                                            ? props.onChangeItineraryUrl(
                                                                  event
                                                              )
                                                            : null
                                                    }
                                                />
                                            </Col> */}

                                            <Col xs="11">
                                                <Field
                                                    name="itinerary_client_url"
                                                    type="text"
                                                    className="mb-2"
                                                    placeholder="CLIENT REVIEW URL"
                                                    component={RenderField}
                                                    readOnly={props.read_only}
                                                    onBlur={(event) =>
                                                        !props.read_only
                                                            ? props.onChangeItineraryUrl(
                                                                  event
                                                              )
                                                            : null
                                                    }
                                                />
                                            </Col>

                                            {!props.read_only && (
                                                <Col xs="1">
                                                    <div
                                                        className="btn btn-indication mt-1"
                                                        id="info-client-revew-url"
                                                    >
                                                        i
                                                    </div>

                                                    <UncontrolledTooltip
                                                        placement="bottom"
                                                        target="info-client-revew-url"
                                                    >
                                                        Paste “Client for
                                                        Review” link from
                                                        Charteritinerary.com
                                                    </UncontrolledTooltip>
                                                </Col>
                                            )}

                                            <Col xs="11">
                                                <label className="upload-label text-uppercase mt-2">
                                                    ITINERARY SECTION BACKGROUND
                                                    IMAGE
                                                </label>
                                                <UploadField
                                                    name="itineraryImages"
                                                    fileLabelName="Click here to upload an image"
                                                    className="mb-2 mt-1"
                                                    disabled={props.read_only}
                                                    initPhoto={
                                                        props?.initialValues
                                                            ?.itineraryImages?.[0]
                                                            ?.url
                                                    }
                                                    type="single"
                                                    add={(urls, name) => {
                                                        const field =
                                                            'itineraryImages';
                                                        props.changeFieldValue(
                                                            field,
                                                            [{ url: urls }]
                                                        );
                                                    }}
                                                />
                                            </Col>

                                            <Col xs="11">
                                                <Field
                                                    name="itinerary_title"
                                                    type="text"
                                                    className="mb-2"
                                                    placeholder="ITINERARY SECTION TITLE"
                                                    component={RenderField}
                                                    readOnly={props.read_only}
                                                />
                                            </Col>
                                        </>
                                    )}
                                    <hr className="my-2 filter-divider" />

                                    <Col
                                        xs="12"
                                        className="mt-2 double-redactor"
                                    >
                                        <Row className="align-items-center">
                                            <Col xs="11">
                                                {props.read_only && (
                                                    <label className="upload-label text-uppercase mt-2">
                                                        Email Content
                                                    </label>
                                                )}

                                                <div className="redactor-wrap">
                                                    <div
                                                        id="external-toolbar_before"
                                                        className="external-toolbar"
                                                    />
                                                </div>

                                                <RenderRedactor
                                                    hiddenTitle={true}
                                                    name="email_content_before"
                                                    readOnly={props.read_only}
                                                    disabled={props.read_only}
                                                    id="email_content_id_before"
                                                    toolbarExternal="#external-toolbar_before"
                                                    onChange={(text) => {
                                                        props.changeFieldValue(
                                                            'email_content_before',
                                                            text
                                                        );
                                                    }}
                                                />

                                                <div
                                                    className={`fixed-text-redactor
                                            ${
                                                props.read_only
                                                    ? 'disabled'
                                                    : ''
                                            }`}
                                                >
                                                    {`You can review your initial online proposal by clicking {{here}}`}
                                                </div>
                                                <RenderRedactor
                                                    hiddenTitle={true}
                                                    name="email_content_after"
                                                    readOnly={props.read_only}
                                                    disabled={props.read_only}
                                                    id="email_content_id_after"
                                                    toolbarExternal="#external-toolbar_before"
                                                    onChange={(text) => {
                                                        props.changeFieldValue(
                                                            'email_content_after',
                                                            text
                                                        );
                                                    }}
                                                />

                                                {props.user &&
                                                    props.user.signature && (
                                                        <div
                                                            className="fixed-signature"
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    props.user
                                                                        .signature
                                                            }}
                                                        />
                                                    )}
                                            </Col>

                                            <Col xs="1">
                                                {!props.read_only && (
                                                    <div
                                                        className="btn btn-indication"
                                                        id="info-button-email"
                                                    >
                                                        i
                                                    </div>
                                                )}
                                                {!props.read_only && (
                                                    <UncontrolledTooltip
                                                        placement="bottom"
                                                        target="info-button-email"
                                                    >
                                                        Type the email message
                                                        that your contact will
                                                        receive in the email
                                                        body. "You can find your
                                                        offer here" is not
                                                        editable and contains
                                                        the offer link. You can
                                                        type your email above
                                                        and below the email
                                                        message.
                                                    </UncontrolledTooltip>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs="8" md="6" lg="4" className="mt-2">
                                        <Field
                                            name="template"
                                            placeholder="SELECT PDF TEMPLATE"
                                            readOnly={props.read_only}
                                            disabled={props.read_only}
                                            component={RenderReactSelect}
                                            options={[
                                                {
                                                    value: 'summary',
                                                    label: 'Summary'
                                                },
                                                {
                                                    value: 'full',
                                                    label: 'Full'
                                                },
                                                {
                                                    value: 'comparison',
                                                    label: 'Comparison'
                                                }
                                            ]}
                                        />
                                    </Col>

                                    <Col xs="8" md="6" lg="4" className="mt-2">
                                        <img
                                            src={pdfPreviews[props.template]}
                                            alt=""
                                        />
                                    </Col>

                                    <Col xs="12" className="mt-2">
                                        <YachtsSelection
                                            mode={
                                                props.match.url.includes(
                                                    'create'
                                                )
                                                    ? 'create'
                                                    : props.match.url.includes(
                                                          'edit'
                                                      )
                                                    ? 'edit'
                                                    : 'view'
                                            }
                                            showSearchOptions={true}
                                            should_show_saved_searches={true}
                                            read_only={props.read_only}
                                            user_role="admin"
                                            type={props.match.params.type}
                                            search_id={
                                                props.initialValues.search_id
                                            }
                                            search_type={
                                                props.initialValues.search_type
                                            }
                                            initial_yachts={
                                                props.initialValues.yachts
                                            }
                                            onChange={(value) => {
                                                props.changeFieldValue(
                                                    'yachts',
                                                    value
                                                );
                                            }}
                                            changeFieldValue={
                                                props.changeFieldValue
                                            }
                                            onChangeExternalUrl={
                                                props.onChangeExternalUrl
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Collapsable header="Attachments">
                                            <Row className="my-2">
                                                <Col xs="12">
                                                    <FieldArray
                                                        name="attachments"
                                                        component={
                                                            RenderOfferAttachments
                                                        }
                                                        locale={props.locale}
                                                        read_only={
                                                            props.read_only
                                                        }
                                                        changeFieldValue={
                                                            props.changeFieldValue
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Collapsable>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs="12" md="4" lg="3">
                                {!props.read_only && (
                                    <Button color="primary" type="submit">
                                        Save
                                    </Button>
                                )}

                                {props.read_only && (
                                    <a
                                        className="btn btn-blue"
                                        style={{
                                            maxWidth: '120px',
                                            marginBottom: '10px'
                                        }}
                                        href={previewLink}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        Preview
                                    </a>
                                )}

                                {props.read_only &&
                                    props.initialValues.sent_at === null && (
                                        <Button
                                            color="info"
                                            onClick={(event) => {
                                                props.history.push(
                                                    props.match.url.replace(
                                                        'view',
                                                        'edit'
                                                    )
                                                );
                                            }}
                                        >
                                            Edit
                                        </Button>
                                    )}

                                {props.read_only && (
                                    <>
                                        <Button
                                            color="primary"
                                            style={{ marginTop: '10px' }}
                                            onClick={props.onModalToggle}
                                        >
                                            {props.initialValues.sent_at
                                                ? 'Resend To Client'
                                                : 'Send To Client'}
                                        </Button>
                                        <Button
                                            color="primary"
                                            style={{ marginTop: '10px' }}
                                            onClick={() => {
                                                props.onModalToggle(true);
                                            }}
                                        >
                                            {props.initialValues.sent_at
                                                ? 'Resend To Self'
                                                : 'Send To Self'}
                                        </Button>
                                    </>
                                )}

                                {!props.read_only && (
                                    <Instructions
                                        mode="info"
                                        title={`Please first press "Save" in order to send to client.`}
                                    />
                                )}
                            </Col>
                        </Row>
                    </form>
                )}
            </div>

            {props.read_only && (
                <SimpleModal
                    header={props.modal_header}
                    body={props.modal_email}
                    modalOpen={props.is_modal_open}
                    toggle={props.onModalToggle}
                />
            )}
        </div>
    );
};

ManageCharterOfferPresenter = reduxForm({
    form: 'customers_offer',
    enableReinitialize: true
})(ManageCharterOfferPresenter);

const selector = formValueSelector('customers_offer');

const mapStateToProps = (state, own_props) => {
    let offer = state.customers.offers.initOffer;
    let initBroker = state.user?.name;
    let initCustomer = state?.customers?.customers?.initCustomer;
    let customer_name = `${initCustomer.first_name} ${initCustomer.last_name}`;
    let clientName = `${initCustomer?.first_name}`;
    let itineraryImages = state.customers.offers.initOffer.itineraryImages;
    let customer_email =
        initCustomer && initCustomer.emails
            ? initCustomer.emails.find((email) => email.primary === 1)
            : '';
    let search_type = `current_${own_props.match.params.type}`;
    let externalUrls = {};
    if (offer.id && offer.yachts) {
        offer.yachts.map((yacht) => {
            if (yacht._pivot_external_url) {
                externalUrls[`external_url${yacht.id}`] =
                    yacht._pivot_external_url;
            }
            return yacht;
        });
    }

    if (offer.id) {
        search_type = offer.chartering
            ? 'current_charter'
            : offer.search_id
            ? 'current_search'
            : 'current_sales';
    }

    return {
        initialValues: {
            customer_name: customer_name,
            customer_email:
                customer_email && customer_email.value
                    ? customer_email.value
                    : '',
            ...offer,
            search_type: search_type,
            chartering: offer.id
                ? offer.chartering
                : own_props.match.params.type === 'charter'
                ? 1
                : 0,
            email_content_before:
                offer.id && offer.email_content_before
                    ? offer.email_content_before
                    : own_props.match.params.type === 'charter'
                    ? charterDefaultEmailContentBefore(clientName)
                    : '',
            email_content_after:
                offer.id && offer.email_content_after
                    ? offer.email_content_after
                    : own_props.match.params.type === 'charter'
                    ? charterDefaultEmailContentAfter(initBroker)
                    : '',
            recipients_cc:
                offer.id && offer.recipients
                    ? offer.recipients.reduce((result, value) => {
                          if (value.type === 'cc') {
                              result.push({ name: value.address });
                          }

                          return result;
                      }, [])
                    : [],
            recipients_bcc:
                offer.id && offer.recipients
                    ? offer.recipients.reduce((result, value) => {
                          if (value.type === 'bcc') {
                              result.push({ name: value.address });
                          }

                          return result;
                      }, [])
                    : [],
            expires:
                offer.id && offer.expires
                    ? moment(offer.expires).format('YYYY/MM/DD')
                    : moment().add(3, 'months').format('YYYY/MM/DD'),
            rate_location_range_from:
                offer.id && offer.rate_location_range_from
                    ? moment(offer.rate_location_range_from).format(
                          'YYYY/MM/DD'
                      )
                    : own_props.history.location.state &&
                      own_props.history.location.state.date_from
                    ? own_props.history.location.state.date_from
                    : '',
            rate_location_range_to:
                offer.id && offer.rate_location_range_to
                    ? moment(offer.rate_location_range_to).format('YYYY/MM/DD')
                    : own_props.history.location.state &&
                      own_props.history.location.state.date_to
                    ? own_props.history.location.state.date_to
                    : '',
            // commented out after request DV-587
            // rate_date_from:
            //     offer.id && offer.rate_date_from
            //         ? moment(offer.rate_date_from).format('YYYY/MM/DD')
            //         : '',
            // rate_date_to:
            //     offer.id && offer.rate_date_to
            //         ? moment(offer.rate_date_to).format('YYYY/MM/DD')
            //         : '',
            itineraryImages: itineraryImages,
            yachts:
                offer.id && offer.yachts
                    ? offer.yachts.map((yacht) => {
                          let new_yacht = Object.assign({}, yacht);
                          if (yacht.selected === undefined) {
                              new_yacht.selected = 1;
                          }
                          return new_yacht;
                      })
                    : [],
            ...externalUrls
        },
        new_tag_cc: selector(state, 'new_tag_cc'),
        new_tag_bcc: selector(state, 'new_tag_bcc'),
        template: selector(state, 'template'),
        attachments: selector(state, 'attachments')
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change('customers_offer', field, value))
    };
};

ManageCharterOfferPresenter = connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageCharterOfferPresenter);

export default ManageCharterOfferPresenter;
