export const INIT_YACHT_LIST = 'init_yacht_list', 
    FETCH_YACHT_LISTS = 'fetch_yacht_lists',
    FETCH_YACHT_LISTS_SUCCESS = 'fetch_yacht_lists_success',
    FETCH_YACHT_LISTS_ERROR = 'fetch_yacht_lists_error',
    CREATE_YACHT_LIST = 'create_yacht_list',
    CREATE_YACHT_LIST_SUCCESS = 'create_yacht_list_success',
    CREATE_YACHT_LIST_ERROR = 'create_yacht_list_error',
    FETCH_YACHT_LIST = 'fetch_yacht_list',
    FETCH_YACHT_LIST_SUCCESS = 'fetch_yacht_list_success',
    FETCH_YACHT_LIST_ERROR = 'fetch_yacht_list_error',
    UPDATE_YACHT_LIST = 'update_yacht_list',
    UPDATE_YACHT_LIST_SUCCESS = 'update_yacht_list_success',
    UPDATE_YACHT_LIST_ERROR = 'update_yacht_list_error',
    DELETE_YACHT_LIST = 'delete_yacht_list',
    DELETE_YACHT_LIST_SUCCESS = 'delete_yacht_list_success',
    DELETE_YACHT_LIST_ERROR = 'delete_yacht_list_error';
