import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';

import pdf from '../../assets/svgs/file-pdf.svg';
import spreedsheets from '../../assets/svgs/file-spreedsheets.svg';
import text from '../../assets/svgs/file-text.svg';
import video from '../../assets/svgs/file-video.svg';
import word from '../../assets/svgs/file-word.svg';

import { Field } from 'redux-form';
import RenderField from './RenderField';

import $ from 'jquery';

class RenderImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imageLoaded: false,
            isImage: props.value.url
                ? props?.value?.url?.match?.(
                      /\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG)$/
                  ) != null
                    ? true
                    : false
                : false,
            isVideoUrl: /(?:https?:\/{2})?(?:w{3}\.)?(vimeo.com|youtu(?:be)?\.(?:com|be))/.test(
                props.value.url
            ),
            vimeoThumb: '',
            ext: props.value.url
                ? props.value.url.substr(props.value.url.lastIndexOf('.') + 1)
                : '',
            filename: props.value.url
                ? props.value.url.substring(
                      props.value.url.lastIndexOf('/') + 1,
                      props.value.url.lastIndexOf('.')
                  )
                : ''
        };
    }

    handleImageLoaded() {
        this.setState({ imageLoaded: true });
    }

    handleImageErrored() {
        this.setState({ imageLoaded: false });
    }

    getVideoUrlID() {
        let image = video;

        let youtubeID = this.props.value.url.match(
            /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
        );

        if (youtubeID != null && youtubeID[1]) {
            image = 'https://img.youtube.com/vi/' + youtubeID[1] + '/0.jpg';
        } else {
            let vimeoID = this.props.value.url.match(
                /(?:https?:\/{2})?(?:w{3}\.)?vimeo.com\/(\d+)($|\/)/
            );

            if (vimeoID && vimeoID[1]) {
                $.getJSON(
                    'https://vimeo.com/api/v2/video/' + vimeoID[1] + '.json',
                    { format: 'json' },
                    (data) => {
                        if (data[0] && data[0].thumbnail_medium)
                            this.setState({
                                vimeoThumb: data[0].thumbnail_medium
                            });

                        return data[0].thumbnail_medium;
                    }
                );
            }
        }

        return image;
    }

    getImageType() {
        if (this.state.isImage === true) return this.props.value.url;
        else if (this.state.ext === 'pdf') return pdf;
        else if (
            this.state.ext === 'xls' ||
            this.state.ext === 'xlsx' ||
            this.state.ext === 'xlr' ||
            this.state.ext === 'ods'
        )
            return spreedsheets;
        else if (
            this.state.ext === 'doc' ||
            this.state.ext === 'docx' ||
            this.state.ext === 'odt'
        )
            return word;
        else if (
            this.state.ext === 'avi' ||
            this.state.ext === 'mkv' ||
            this.state.ext === 'mov' ||
            this.state.ext === 'mp4' ||
            this.state.ext === 'mpg' ||
            this.state.ext === 'mpeg' ||
            this.state.ext === 'ogv' ||
            this.state.ext === 'webm'
        )
            return video;
        else if (this.state.isVideoUrl) {
            return this.getVideoUrlID();
        } else return text;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            imageLoaded: false,
            isImage:
                nextProps?.value?.url?.match?.(
                    /\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG)$/
                ) != null
                    ? true
                    : false,
            isVideoUrl: /(?:https?:\/{2})?(?:w{3}\.)?(vimeo.com|youtu(?:be)?\.(?:com|be))/.test(
                nextProps.value.url
            ),
            vimeoThumb: '',
            ext: nextProps.value.url.substr(
                nextProps.value.url.lastIndexOf('.') + 1
            ),
            filename: nextProps.value.url.substring(
                nextProps.value.url.lastIndexOf('/') + 1,
                nextProps.value.url.lastIndexOf('.')
            )
        });
    }

    imageDownload() {
        <a href={this.props.value.url} target="_blank" rel="noreferrer">
            <i aria-hidden="true" />
        </a>;
    }

    render() {
        const { imageClassName } = this.props;

        const DragHandle = SortableHandle(() => (
            <a href={this.props.value.url} target="_blank" rel="noreferrer">
                <div
                    className="image"
                    style={{
                        backgroundImage:
                            'url(' +
                            (this.state.vimeoThumb === ''
                                ? this.getImageType()
                                : this.state.vimeoThumb !== ''
                                ? this.state.vimeoThumb
                                : video) +
                            ')'
                    }}
                    onError={this.handleImageErrored.bind(this)}
                />

                {!this.state.isVideoUrl && (
                    <span
                        id={this.state.filename}
                        className={
                            'img-label ' + (this.state.isImage ? 'images' : '')
                        }
                    >
                        {this.state.filename + '.' + this.state.ext}
                    </span>
                )}
            </a>
        )); // This can be any component you want

        return (
            <div
                className={
                    `${imageClassName ? imageClassName : ''} ` +
                    'gen-type-wrap ' +
                    (!this.state.isVideoUrl
                        ? !this.state.isImage
                            ? ' file-type-wrap'
                            : ' file-type-wrap-image'
                        : ' ')
                }
                style={this.props.dragStyle}
                {...this.props.dragProps}
            >
                <DragHandle />
                {this.props.closeButton && (
                    <a
                        className="close-button"
                        onClick={() =>
                            this.props.remove(
                                this.props.value,
                                this.props.index
                            )
                        }
                    >
                        x
                    </a>
                )}

                {this.props.show_alt_text_input && (
                    <Field
                        name={`${this.props.name}.${this.props.index}.alt_text`}
                        placeholder="ALT TEXT"
                        component={RenderField}
                        className="mt-2"
                        readOnly={
                            this.props.locale !== 'en'
                                ? true
                                : this.props.read_only
                        }
                    />
                )}
            </div>
        );
    }
}
export default RenderImage;
