import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, FieldArray } from 'redux-form';

import { fetchSalesCompanies, fetchCharterCompanies } from '../_actions';

import RenderCompany from './RenderCompany';

class Companies extends Component {
    constructor(props) {
        super(props);

        this.state = {
            suggestions: [],
            active: 0
        };
    }

    select(comp, index) {
        this.setState({ active: -1 });
        if (this.props.charterSearch === 'charter') {
            let name = 'has_charterCompanies[0].name';
            this.props.changeFieldValue(name, comp.company);
        } else {
            let name = 'has_companies[0].name';
            this.props.changeFieldValue(name, comp.company);
        }
    }

    change(index, event, newValue, previousValue) {
        this.setState({ active: index });

        let query = {
            multiple: true,
            search: newValue
        };
        if (this.props.charterSearch === 'charter') {
            this.props.fetchCharterCompanies(query).then((suggestions) => {
                this.setState({ suggestions: suggestions });
            });
        } else {
            this.props.fetchSalesCompanies(query).then((suggestions) => {
                this.setState({ suggestions: suggestions });
            });
        }
    }

    render() {
        return (
            <div
                className={
                    'companies-wrap ' +
                    (this.props.className ? this.props.className : '')
                }
            >
                <FieldArray
                    name={
                        this.props.prefix !== undefined
                            ? Boolean(this.props.charterSearch)
                                ? this.props.prefix + 'charterCompanies'
                                : this.props.prefix + 'companies'
                            : 'companies'
                    }
                    single={this.props.single}
                    component={RenderCompany}
                    props={{
                        suggestions: this.state.suggestions,
                        active: this.state.active,
                        change: this.change.bind(this),
                        form: this.props.form,
                        select: this.select.bind(this)
                    }}
                    className="mb-2"
                    charterSearch={Boolean(this.props.charterSearch)}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetchSalesCompanies: (e) => dispatch(fetchSalesCompanies(e)),
        fetchCharterCompanies: (e) => dispatch(fetchCharterCompanies(e)),
        changeFieldValue: (field, value) =>
            dispatch(change(ownProps.form, field, value))
    };
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
