import axios from 'axios';

import { API_URL } from '../helpers/variables';
import { errorHandler } from './handlers';
import { authConfig } from '../helpers/authConfig';

const salesListings = {
    getAll: async (params) => {
        try {
            const config = {
                headers: authConfig(),
                params
            };
            const { data } = await axios.get(
                API_URL + '/sales-listings',
                config
            );
            return data;
        } catch (err) {
            errorHandler(err.response);
        }
    },
    getAllCharter: async (params) => {
        try {
            const config = {
                headers: authConfig(),
                params
            };
            const { data } = await axios.get(
                API_URL + '/sales-listings/charter',
                config
            );
            return data;
        } catch (err) {
            errorHandler(err.response);
        }
    },
    delete: async ({ id, params }) => {
        try {
            const config = {
                headers: authConfig()
            };
            const { data } = await axios.delete(
                `${API_URL}/sales-listings/${id}`,
                config
            );
            return data;
        } catch (err) {
            errorHandler(err.response);
        }
    },

    fetchById: async (id, params) => {
        try {
            const config = {
                headers: authConfig(),
                params
            };
            const { data } = await axios.get(
                `${API_URL}/sales-listings/${id}`,
                config
            );
            return data;
        } catch (err) {
            errorHandler(err.response);
        }
    },

    update: async (id, body) => {
        try {
            const config = {
                headers: authConfig()
            };
            const { data } = await axios.put(
                `${API_URL}/sales-listings/${id}`,
                body,
                config
            );
            return data;
        } catch (err) {
            errorHandler(err.response);
        }
    },

    removeYacht: async (salesListingId, yachtId) => {
        try {
            const config = {
                headers: authConfig()
            };
            const { data } = await axios.delete(
                `${API_URL}/sales-listings/${salesListingId}/${yachtId}`,
                config
            );
            return data;
        } catch (err) {
            errorHandler(err.response);
        }
    },

    updateYachtsOrder: async (salesListingId, yachtID, source, destination) => {
        try {
            const config = {
                headers: authConfig()
            };
            const data = { yacht_id: yachtID, source, destination };
            const { data: Data } = await axios.put(
                `${API_URL}/sales-listings/${salesListingId}/ordering`,
                data,
                config
            );
            return data;
        } catch (err) {
            errorHandler(err.response);
        }
    },

    updateYacht: async (salesListingId, data) => {
        try {
            const config = {
                headers: authConfig()
            };
            const { yacht_id: yachtId, id } = data;
            const { data: Data } = await axios.put(
                `${API_URL}/sales-listings/${salesListingId}/${yachtId || id}`,
                data,
                config
            );
            return data;
        } catch (err) {
            errorHandler(err.response);
        }
    }
};
const variable = { salesListings };

export default variable;
