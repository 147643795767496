import React, { Component } from 'react';
import { Field } from 'redux-form';
import { API_URL } from '../../helpers/variables';

import $ from 'jquery';

import '../../assets/redactor/redactor.css';
import '../../assets/redactor/redactorWebpackFixed.js';
import '../../assets/redactor/fontsize.js';

const renderField = ({ input, id, meta }) => {
    return (
        <textarea className="form-control redactor-editor" id={id} {...input} />
    );
};

class Redactor extends Component {
    componentDidUpdate(nextProps) {
        if ($('#' + this.props.id).prev('.redactor-layer')) {
            if (
                nextProps.readOnly !== undefined &&
                nextProps.readOnly !== this.props.readOnly
            ) {
                $('#' + this.props.id)
                    .prev('.redactor-layer')
                    .attr(
                        'contenteditable',
                        nextProps.readOnly === true ? 'false' : 'true'
                    );
            }
        }
    }

    componentDidMount() {
        if (this.props.array) {
            $(
                '#' +
                    this.props.fieldName +
                    '\\[' +
                    this.props.index +
                    '\\]\\.' +
                    this.props.underName
            ).redactor({
                buttons: [
                    'bold',
                    'italic',
                    'deleted',
                    'underline',
                    'lists',
                    'link',
                    'horizontalrule',
                    'image'
                ],
                minHeight: 144,
                maxHeight: 288,
                script: false,
                pastePlainText: true,
                pasteImages: false,
                dragImageUpload: false,
                dragFileUpload: false,
                contenteditable: false,
                toolbarFixed: false,
                callbacks: {
                    init: () => {
                        if (
                            this.props.readOnly &&
                            this.props.readOnly === true
                        ) {
                            $('#' + this.props.id)
                                .prev('.redactor-layer')
                                .attr('contenteditable', false);
                        }
                    },
                    change: code => {
                        if (this.props.onChange !== undefined) {
                            $(
                                '#' +
                                    this.props.fieldName +
                                    '\\[' +
                                    this.props.index +
                                    '\\]\\.' +
                                    this.props.underName
                            ).redactor('code.get', code);
                            this.props.onChange(code);
                        }
                    }
                }
            });
        } else if (this.props.id) {
            $('#' + this.props.id).redactor({
                buttons: [
                    'bold',
                    'italic',
                    'deleted',
                    'underline',
                    'lists',
                    'link',
                    'horizontalrule',
                    this.props.withImage ? 'image' : ''
                ],
                plugins: this.props.withFontSize ? ['fontsize'] : [],
                minHeight: 144,
                maxHeight: 288,
                script: false,
                pastePlainText: true,
                pasteImages: false,
                dragImageUpload: false,
                dragFileUpload: false,
                contenteditable: false,
                toolbarFixed: false,
                s3: API_URL + '/auth/s3',
                imageUpload: true,
                imageResizable: true,
                imagePosition: true,
                toolbarExternal:
                    this.props.toolbarExternal && !this.props.readOnly
                        ? this.props.toolbarExternal
                        : '',
                callbacks: {
                    init: () => {
                        if (
                            this.props.readOnly &&
                            this.props.readOnly === true
                        ) {
                            $('#' + this.props.id)
                                .prev('.redactor-layer')
                                .attr('contenteditable', false);
                        }
                    },
                    change: code => {
                        let errors = [];
                        const rules = this.props.validate || [];
                        rules.forEach(rule => {
                            const error = rule(code);

                            if (error) {
                                errors.push(error);
                            }
                        });

                        if (errors.length > 0) {
                            if (this.props.onError !== undefined) {
                                this.props.onError(errors[0]);
                            }
                            return;
                        }

                        if (this.props.onChange) {
                            this.props.onChange(code);
                        }
                    },
                    focus: this.props.onFocus ? this.props.onFocus : ''
                }
            });
        }
    }

    render() {
        return (
            <div
                className={
                    'redactor-wrap ' +
                    (this.props.error ? 'error' : '') +
                    (this.props.readOnly !== undefined &&
                    this.props.readOnly === true
                        ? 'preview-info unify-text'
                        : '')
                }
            >
                {this.props.validate !== undefined && this.props.error && (
                    <div className="error">{this.state.error}</div>
                )}

                {!this.props.hiddenTitle && (
                    <div className="redactor-label">{this.props.label}</div>
                )}

                <Field
                    name={this.props.name}
                    validate={this.props.validate}
                    component={renderField}
                    id={this.props.id}
                    readOnly={this.props.readOnly}
                />
            </div>
        );
    }
}

export default Redactor;
