import axios from 'axios';
import {
    MAILCHIMP_API,
    MAILCHIMP_KEY
} from '../../../infrastructure/helpers/globals';
import {
    FETCH_NEWSLETTER_REPORT_BY_TAG,
    FETCH_NEWSLETTER_REPORT_BY_TAG_SUCCESS,
    FETCH_NEWSLETTER_REPORT_BY_TAG_ERROR,
    FETCH_NEWSLETTER_REPORT,
    FETCH_NEWSLETTER_REPORT_SUCCESS,
    FETCH_NEWSLETTER_REPORT_ERROR
} from './_types';

import { errorHandler } from '../../../helpers/alerts';

const fetchNewsletterReportByTag = params => {
    return dispatch => {
        dispatch({ type: FETCH_NEWSLETTER_REPORT_BY_TAG });

        params = { ...params, key: MAILCHIMP_KEY };

        return axios
            .get(`${MAILCHIMP_API}messages/search.json`, {
                params: params
            })
            .then(response => {
                dispatch({
                    type: FETCH_NEWSLETTER_REPORT_BY_TAG_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_NEWSLETTER_REPORT_BY_TAG_ERROR });
            });
    };
};

const fetchNewsletterReport = id => {
    return dispatch => {
        dispatch({ type: FETCH_NEWSLETTER_REPORT });

        return axios
            .get(
                `${MAILCHIMP_API}messages/info.json?key=${MAILCHIMP_KEY}&id=${id}`
            )
            .then(response => {
                dispatch({
                    type: FETCH_NEWSLETTER_REPORT_SUCCESS,
                    payload: response.data
                });
                console.log(response);
                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_NEWSLETTER_REPORT_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

export { fetchNewsletterReportByTag, fetchNewsletterReport };
