import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm, formValueSelector, reset } from 'redux-form';
import Notifications from 'react-notification-system-redux';

import _filter from 'lodash/filter';
import _startsWith from 'lodash/startsWith';
import _endsWith from 'lodash/endsWith';
import _findIndex from 'lodash/findIndex';
import _merge from 'lodash/merge';
import { dropdowns } from '../../helpers/variables';
import {
    yachtStatuses,
    yachtStates,
    yachtCharterStates,
    yachtIncludes as includesCheckboxes
} from '../../helpers/variables';

import RenderCheckbox from '../../common/components/RenderCheckbox';

import { regionCountries } from '../../helpers/variables';

import { functions } from '../../helpers/functions';
import debounce from 'lodash/debounce';
import FiltersSidebar from './FiltersSidebar';
import FiltersCharterSidebar from './FiltersCharterSidebar';
import FiltersDropdown from './FiltersDropdown';

import get from 'lodash/get';

import { resetDropdownLocations } from '../../pages/locations/redux/_actions';

import {
    YACHT_TYPE_CHARTER,
    YACHT_TYPE_SALES
} from '../../infrastructure/helpers/globals';
import { isAdmin, isBrokerOrLess } from '../../infrastructure/helpers/roles';
import ToggledSidebar from '../../common/ToggledSidebar';

const form = reduxForm({
    form: 'yachts_filters',
    enableReinitialize: true,
    destroyOnUnmount: false
});

const RenderCheckboxes = ({ fields, min, max, type }) => (
    <div className="checkboxes-wrapper d-flex justify-content-between">
        {includesCheckboxes[type].map((field, i) => {
            return i >= min && i <= max ? (
                <Field
                    key={i}
                    value={false}
                    name={`equal_${field.group}`}
                    group={includesCheckboxes[type][i].group}
                    label={includesCheckboxes[type][i].label}
                    component={RenderCheckbox}
                />
            ) : null;
        })}
    </div>
);

const unitFields = ['lte_draft_max', 'gte_draft_max', 'gte_beam', 'lte_beam'];
const squaredUnitFields = [
    'gte_master_cabin',
    'lte_master_cabin',
    'gte_sundeck',
    'lte_sundeck'
];

const selector = formValueSelector('yachts_filters');

class Filters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false,
            value: ''
        };

        this.searchFunction = this.searchFunction.bind(this);

        if (this.props.searchFunction)
            this.changed = debounce(this.props.searchFunction, 400);
    }

    mapTypesToCharterTypeGroup(group) {
        const group_name =
            group === 1 ? 'Power' : group === 2 ? 'Sail' : 'Both';

        let names = dropdowns['yacht_types']
            .filter((type) => type.type === group_name)
            .map((n) => n.label);

        return this.props.types
            .filter((type) => names.includes(type.label))
            .map((t) => t.value);
    }

    handleFormSubmit = () => {
        let params = Object.assign({}, this.props.formValues);
        let valid = true;
        let query = {};

        if (this.props.type === 'charter') {
            delete params.equal_status;

            this.props.setCharterRatesLocationsDates(
                params.charter_rates_locations_date_from,
                params.charter_rates_locations_date_to
            );
            // delete params.include_status_detail;
        }

        // Map charter types
        if (this.props.type !== 'sales' && params.has_types != null) {
            params.has_types = this.mapTypesToCharterTypeGroup(
                params.has_types
            );
        }
        // if a user doesn't select a unit for sundeck or master cabin search the unit becomes square meters
        if (
            (params.gte_master_cabin || params.lte_master_cabin) &&
            !params.master_cabin_unit
        ) {
            params.master_cabin_unit = 'square_meters';
        }

        if (
            (params.gte_sundeck || params.lte_sundeck) &&
            !params.sundeck_unit
        ) {
            params.sundeck_unit = 'square_meters';
        }

        if (params.loa_unit === 'feet') {
            if (params.gte_loa) {
                params.gte_loa = functions.toFeet(params.gte_loa);
            }
            if (params.lte_loa) {
                params.lte_loa = functions.toFeet(params.lte_loa);
            }
        }

        if (params.search_previous_names) {
            params.search_previous_names = true;
        }

        if (params.gte_asking_price) {
            params.gte_asking_price = functions.toDollars(
                params.gte_asking_price,
                'usd',
                this.props.realRates
            );
        }

        if (params.lte_asking_price) {
            params.lte_asking_price = functions.toDollars(
                params.lte_asking_price,
                'usd',
                this.props.realRates
            );
        }

        unitFields.forEach((field) => {
            if (params[field]) {
                let r = field.replace('gte_', '').replace('lte_', '');
                params[field] = functions.toMeters(
                    params[field],
                    params[r + '_unit']
                );
            }
        });

        squaredUnitFields.forEach((field) => {
            if (params[field]) {
                let r = field.replace('gte_', '').replace('lte_', '');
                params[field] = functions.toSquareMeters(
                    params[field],
                    params[r + '_unit']
                );
            }
        });

        let equal = {};
        _filter(params, (o, i) => {
            if (!o) return o;
            if (_startsWith(i, 'equal_')) {
                equal[i.replace('equal_', '')] = o === true ? 1 : o;
                return o;
            }
        });

        let equal_array = {};
        _filter(params, (o, i) => {
            if (_startsWith(i, 'array_equal_')) {
                return o.forEach((val) => {
                    equal_array[val] = 1;
                });
            }
        });

        equal = Object.assign(equal, equal_array);

        let between = {};
        _filter(params, (o, i) => {
            if (_startsWith(i, 'between_')) {
                let type = between;
                let values;
                if ((o[0] && !o[1]) || (!o[0] && o[1])) {
                    type = equal;
                }

                if (o[0] || o[1]) {
                    if (o[2] === 'feet' || o[2] === 'meters') {
                        if (o[0] && o[1]) {
                            values = [
                                functions.toMeters(o[0], o[2]),
                                functions.toMeters(o[1], o[2])
                            ];
                        } else {
                            values = functions.toMeters(
                                o[0] ? o[0] : o[1],
                                o[2]
                            );
                        }
                    } else if (
                        o[2] === 'usd' ||
                        o[2] === 'eur' ||
                        o[2] === 'gbp' ||
                        o[2] === 'aud'
                    ) {
                        if (o[0] && o[1]) {
                            values = [
                                functions.toDollars(
                                    o[0].toString().replace(/,/g, ''),
                                    o[2],
                                    this.props.realRates
                                ),
                                functions.toDollars(
                                    o[1].toString().replace(/,/g, ''),
                                    o[2],
                                    this.props.realRates
                                )
                            ];
                        } else {
                            values = functions.toDollars(
                                o[0]
                                    ? o[0].toString().replace(/,/g, '')
                                    : o[1].toString().replace(/,/g, ''),
                                o[2],
                                this.props.realRates
                            );
                        }
                    } else {
                        if (o[0] && o[1]) {
                            values = [
                                o[0].toString().replace(/,/g, ''),
                                o[1].toString().replace(/,/g, '')
                            ];
                        } else {
                            values = o[0] ? o[0] : o[1];
                        }
                    }
                    type[i.replace('between_', '')] = values;
                }

                return o;
            }
        });

        let exist = {};
        if (params.exist) {
            Object.keys(params.exist).map((i) => {
                if (
                    params.exist[i] &&
                    params.exist[i] !== '' &&
                    params.exist[i] !== undefined
                ) {
                    if (includesCheckboxes[this.props.type][i] !== undefined) {
                        exist[
                            includesCheckboxes[this.props.type][i].group
                        ] = true;
                    }
                }
                return i;
            });
        }

        let include_like = {};
        _filter(params, (o, i) => {
            if (_startsWith(i, 'includes_like_')) {
                if (o.length > 0) {
                    let val = [];

                    o.map((j) => {
                        if (j && j.id && j.id !== undefined) val.push(j.id);
                        else if (j && j.name && j.name !== undefined)
                            val.push(j.name);
                        else if (j) val.push(j);
                        return j;
                    });

                    if (val.length !== 0)
                        include_like[i.replace('includes_like_', '')] = val;
                }
            }
        });

        let include = {};
        _filter(params, (o, i) => {
            if (_startsWith(i, 'include_')) {
                if (o.length > 0) {
                    let val = [];

                    o.map((j) => {
                        if (j && j.id && j.id !== undefined) val.push(j.id);
                        else if (j && j.name && j.name !== undefined)
                            val.push(j.name);
                        else if (j) val.push(j);
                        return j;
                    });

                    if (val.length !== 0)
                        include[i.replace('include_', '')] = val;
                }
            }
        });

        let has = {};
        _filter(params, (o, i) => {
            if (_startsWith(i, 'has_')) {
                if (o && o.length > 0) {
                    let val = [];

                    o.map((j) => {
                        if (j && j.id && j.id !== undefined) val.push(j.id);
                        else if (j && j.name && j.name !== undefined)
                            val.push(j.name);
                        else if (j) val.push(j);
                        return j;
                    });

                    if (val.length !== 0) has[i.replace('has_', '')] = val;
                }
            }
        });

        let like = {};
        _filter(params, (o, i) => {
            if (_startsWith(i, 'like_')) {
                like[i.replace('like_', '')] = o;

                return o;
            }
        });

        let gte = {};
        let price_gte = {};
        _filter(params, (o, i) => {
            if (_startsWith(i, 'gte_')) {
                if (_endsWith(i, 'asking_price')) {
                    price_gte.field = i.replace('gte_', '');
                    price_gte.amount = o;
                    price_gte.currency = params.season_currency;
                } else {
                    gte[i.replace('gte_', '')] = o;
                }

                return o;
            }
        });

        let lte = {};
        let price_lte = {};
        _filter(params, (o, i) => {
            if (_startsWith(i, 'lte_')) {
                if (_endsWith(i, 'asking_price')) {
                    price_lte.field = i.replace('lte_', '');
                    price_lte.amount = o;
                    price_lte.currency = params.season_currency;
                } else {
                    lte[i.replace('lte_', '')] = o;
                }

                return o;
            }
        });

        const custom = {};
        Object.entries(params).forEach(([name, value]) => {
            /**
             * parse names in the following format:
             * custom_[modifier]_[fieldName]-[value]
             *  ex.
             *    custom_include_status-sold
             */
            const matches = name.match(/custom_([^_]+)_([^-]+)-(.+)/);

            if (matches) {
                const [_, queryModifier, fieldName, valueName] = matches;
                if (!custom[queryModifier]) custom[queryModifier] = {};

                // [include] query for array of checkboxes
                if (
                    queryModifier === 'include' ||
                    queryModifier === 'exist' ||
                    queryModifier === 'orExist'
                ) {
                    if (!custom[queryModifier][fieldName])
                        custom[queryModifier][fieldName] = [];
                    if (value) custom[queryModifier][fieldName].push(valueName);

                    if (
                        queryModifier === 'exist' ||
                        queryModifier === 'orExist'
                    ) {
                        if (value) custom[queryModifier][fieldName] = valueName;
                    }
                } else
                    throw Error(`Modifier ${queryModifier} is not implemented`);
            }
        });

        if (params.for_sale) {
            if (equal && this.props.type === YACHT_TYPE_SALES) {
                equal.status = 'for_sale';
            }
        }

        if (params.locations_tree_filter) {
            has.locations = {
                location_ids: params.locations_tree_filter
            };

            if (
                params.charter_rates_locations_date_from &&
                params.charter_rates_locations_date_from !== 'Invalid date'
            ) {
                has.locations['date_from'] =
                    params.charter_rates_locations_date_from;
            }

            if (
                params.charter_rates_locations_date_to &&
                params.charter_rates_locations_date_to !== 'Invalid date'
            ) {
                has.locations.date_to = params.charter_rates_locations_date_to;
            }
        }

        if (params.slug) {
            has.slug = {};
            has.slug[''] = params.slug;
        }

        if (params.bookings_date_from && params.bookings_date_to) {
            has.bookings = {};
            has.bookings['date_from'] = params.bookings_date_from;
            has.bookings['date_to'] = params.bookings_date_to;
        }

        if (params.charter_rates_from) {
            has.rates = {};
            has.rates.price = [];
            has.rates.price[0] = {
                rate: params.charter_rates_from,
                operator: 'gte',
                currency: params.charter_rates_currency
                    ? params.charter_rates_currency
                    : 'usd'
            };
        }

        if (params.charter_rates_to) {
            if (!params.charter_rates_from) {
                has.rates = {};
                has.rates.price = [];
                has.rates.price[0] = {
                    rate: params.charter_rates_to,
                    operator: 'lte',
                    currency: params.charter_rates_currency
                        ? params.charter_rates_currency
                        : 'usd'
                };
            } else {
                has.rates.price[1] = {
                    rate: params.charter_rates_to,
                    operator: 'lte',
                    currency: params.charter_rates_currency
                        ? params.charter_rates_currency
                        : 'usd'
                };
            }
        }

        if (params.charter_rates_from || params.charter_rates_to) {
            if (
                params.charter_rates_locations_date_from &&
                params.charter_rates_locations_date_from !== 'Invalid date'
            ) {
                has.rates['date_from'] =
                    params.charter_rates_locations_date_from;
            }

            if (
                params.charter_rates_locations_date_to &&
                params.charter_rates_locations_date_to !== 'Invalid date'
            ) {
                has.rates['date_to'] = params.charter_rates_locations_date_to;
            }
        }

        if (valid === true) {
            if (Object.keys(equal).length > 0) {
                query.equal = equal;
                if (query.equal.sales_public === false) {
                    delete query.equal.sales_public;
                }
                if (query.equal.charter_public === false) {
                    delete query.equal.charter_public;
                }
                if (query.equal.is_archived === false) {
                    delete query.equal.is_archived;
                }
            }

            if (Object.keys(between).length > 0) {
                query.between = between;
            }

            if (Object.keys(exist).length > 0) {
                query.exist = exist;
            }

            if (Object.keys(include_like).length > 0) {
                query.include_like = include_like;
            }

            if (Object.keys(include).length > 0) {
                query.include = include;
            }

            if (Object.keys(has).length > 0) {
                query.has = has;
                if (this.props.type === YACHT_TYPE_CHARTER) {
                    delete query.has.companies;
                } else {
                    delete query.has.charterCompanies;
                }
            }

            if (Object.keys(like).length > 0) {
                query.like = like;
                if (query.like.imo === null || query.like.imo === '') {
                    delete query.like.imo;
                }
            }

            if (Object.keys(gte).length > 0) {
                query.gte = gte;
            }

            if (Object.keys(lte).length > 0) {
                query.lte = lte;
            }

            if (Object.keys(price_lte).length > 0) {
                query.price_lte = price_lte;
            }

            if (Object.keys(price_gte).length > 0) {
                query.price_gte = price_gte;
            }

            if (this.props.type === YACHT_TYPE_CHARTER) {
                query['_prefix'] = this.props.prefix;
            }

            if (Object.keys(custom).length > 0) {
                query = _merge(custom, query);
            }

            // hack
            const specialValues = Object.keys(regionCountries);
            if (query && query['include'] && query['include']['country']) {
                query['include']['country'] = query['include'][
                    'country'
                ].reduce((query, country) => {
                    if (specialValues.indexOf(country) !== -1) {
                        regionCountries[country].map((country) =>
                            query.push(country)
                        );
                    } else {
                        query.push(country);
                    }
                    return query;
                }, []);
            }
            window.scrollTo(0, 0);
            this.props.refetchYachts(query);
        }
    };

    cancelForm() {
        this.setState({ value: '' }, () => {
            if (this.changed) this.changed('search', '');
        });
        this.props.onClearSearch();
        this.props.resetForm();
        this.props.resetDropdownLocations(true);
    }

    searchFunction(event) {
        const val = event.target.value;

        this.setState({ value: val }, () => {
            if (this.changed) this.changed('search', val);
        });
    }

    componentDidMount() {
        this.setState({
            loaded: true
        });
    }

    componentDidUpdate(nextProps) {
        // In role change
        if (this.props.user_role !== nextProps.user_role) {
            // Hide sidebar if open
            if (this.props.mobileView) {
                this.props.hideSidebar();
            }
            // Reset search value in order to get everything if role_user is changed
            this.setState({ value: '' }, () => {
                if (this.changed) this.changed('search', '');
            });
            // Reset filters
            if (this.props.dirty) {
                this.cancelForm();
            }
        }
        if (
            nextProps.initSearch.id &&
            nextProps.initSearch.query &&
            !this.state.init
        ) {
            this.setState({ init: true });
        }
    }

    render() {
        const { handleSubmit, submitting, toggle, menu } = this.props;

        return (
            <div
                className={`yachts-filters-wrapper with-shadow px-1 ${
                    toggle === true ? ' toggled' : ' '
                } ${
                    isAdmin(this.props.user_role) && 'column-direction-wrapper'
                }`}
            >
                <form
                    className={`list-form ${
                        this.props.user_role.indexOf('broker') > -1
                            ? 'pt-0'
                            : ''
                    }`}
                    onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
                >
                    {isAdmin(this.props.user_role) &&
                        this.props.type === YACHT_TYPE_SALES && (
                            <ToggledSidebar
                                position={
                                    this.props.mobileView ? 'fixed' : 'relative'
                                }
                                is_open={this.props.is_sidebar_open}
                                onCloseSidebar={(e) =>
                                    this.props.onCloseSidebar(e, 'filters')
                                }
                            >
                                <FiltersSidebar
                                    menu={menu}
                                    submitting={submitting}
                                    cancelForm={this.cancelForm.bind(this)}
                                    value={this.state.value}
                                    searchFunction={this.searchFunction}
                                    includesOptions={
                                        includesCheckboxes[this.props.type] ||
                                        []
                                    }
                                    type={this.props.type}
                                    types={this.props.types}
                                    changeFieldValue={this.props.changeFieldValue.bind(
                                        this
                                    )}
                                    new_builder={this.props.new_builder}
                                    season={this.props.season}
                                    locations={this.props.locations}
                                    countries={this.props.countries}
                                    states={this.props.states}
                                    selectedCountry={this.props.country}
                                    readOnly={this.props.readOnly}
                                    externalProviders={
                                        this.props.externalProviders
                                    }
                                    yachtStatuses={yachtStatuses}
                                    yachtStates={yachtStates}
                                />
                            </ToggledSidebar>
                        )}

                    {isAdmin(this.props.user_role) &&
                        this.props.type === YACHT_TYPE_CHARTER && (
                            <ToggledSidebar
                                position={
                                    this.props.mobileView ? 'fixed' : 'relative'
                                }
                                is_open={this.props.is_sidebar_open}
                                onCloseSidebar={(e) =>
                                    this.props.onCloseSidebar(e, 'filters')
                                }
                            >
                                <FiltersCharterSidebar
                                    menu={menu}
                                    submitting={submitting}
                                    cancelForm={this.cancelForm.bind(this)}
                                    value={this.state.value}
                                    searchFunction={this.searchFunction}
                                    mobileView={this.props.mobileView}
                                    includesOptions={
                                        includesCheckboxes[this.props.type] ||
                                        []
                                    }
                                    type={this.props.type}
                                    types={this.props.types}
                                    changeFieldValue={this.props.changeFieldValue.bind(
                                        this
                                    )}
                                    new_builder={this.props.new_builder}
                                    season={this.props.season}
                                    locations={this.props.locations}
                                    countries={this.props.countries}
                                    states={this.props.states}
                                    selectedCountry={this.props.country}
                                    readOnly={this.props.readOnly}
                                    externalProviders={
                                        this.props.externalProviders
                                    }
                                    yachtStatuses={yachtStatuses}
                                    yachtStates={yachtStates}
                                    yachtCharterStates={yachtCharterStates}
                                    locationsTree={this.props.locationsTree}
                                />
                            </ToggledSidebar>
                        )}

                    {isBrokerOrLess(this.props.user_role) && (
                        <FiltersDropdown
                            RenderCheckboxes={RenderCheckboxes}
                            type={this.props.type}
                            types={this.props.types}
                            changeFieldValue={this.props.changeFieldValue.bind(
                                this
                            )}
                            new_builder={this.props.new_builder}
                            season={this.props.season}
                            locations={this.props.locations}
                            states={this.props.states}
                            countries={this.props.countries}
                            selectedCountry={this.props.country}
                            cancelForm={this.cancelForm.bind(this)}
                            locationsTree={this.props.locationsTree}
                        />
                    )}
                </form>
            </div>
        );
    }
}

let __t_initialValues, __t_ownProps;

function toFilterNames(filters) {
    return Object.keys(filters).reduce((acc, type) => {
        Object.keys(filters[type]).forEach(
            (field) => (acc[type + '_' + field] = filters[type][field])
        );

        return acc;
    }, {});
}

const groupYachtTypesByType = (types, type_ids) => {
    // Get the types that have id on type_ids and get only the label
    types = types
        .filter((type) => type_ids.includes(type.id))
        .map((type) => type.text);

    // Find in What group they are and get the unique values
    let groups = dropdowns['yacht_types']
        .filter((type) => types.includes(type.label))
        .map((type) => type.type);
    groups = [...new Set(groups)];

    // Return the group ID
    return (
        dropdowns['types']
            .filter((type) => groups.includes(type.label))
            .map((type) => type.value)[0] || ''
    );
};

function mapDispatchToProps(dispatch, ownProps) {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change('yachts_filters', field, value)),
        resetForm: () => dispatch(reset('yachts_filters')),
        notification: (title, message) => {
            let notificationOpts = {
                position: 'tc',
                autoDismiss: 0,
                title: title,
                message: message
            };

            dispatch(Notifications.error(notificationOpts));
        },
        resetDropdownLocations: (should_reset) =>
            dispatch(resetDropdownLocations(should_reset))
    };
}

function mapStateToProps(state, ownProps) {
    const search = state.saved_searches.initSearch;
    const yachtList = state.yacht_lists.yacht_list;
    let initialValues = {
        between_asking_price: ['', '', 'usd'],
        between_loa: ['', '', 'meters'],
        draft_max_unit: 'meters',
        beam_unit: 'meters',
        loa_unit: 'meters',
        has_brokers: [''],
        has_companies: [''],
        exist: {},
        season: '',
        season_currency: 'usd'
    };

    if (
        (search && search.id) ||
        (yachtList && yachtList.id && yachtList.type === 'dynamic')
    ) {
        let query = search.query
            ? search.query
            : yachtList.dynamic_query
            ? yachtList.dynamic_query
            : {};
        Object.keys(query).forEach((key) => {
            Object.keys(query[key]).forEach((key2) => {
                if (key !== '_prefix') {
                    if (key === 'exist') {
                        let i = _findIndex(
                            includesCheckboxes[ownProps.type],
                            function (o) {
                                return o.group === key2;
                            }
                        );

                        if (i !== undefined) {
                            initialValues.exist[i] = true;
                        }
                    } else if (key === 'has') {
                        if (key2 === 'locations') {
                            if (query[key][key2]['date_from']) {
                                initialValues[
                                    'charter_rates_locations_date_from'
                                ] = query[key][key2]['date_from'];
                            }

                            if (query[key][key2]['date_to']) {
                                initialValues[
                                    'charter_rates_locations_date_to'
                                ] = query[key][key2]['date_to'];
                            }

                            initialValues['locations_tree_filter'] =
                                query[key][key2]['location_ids'];
                        } else if (key2 === 'rates') {
                            if (query[key][key2]['date_from']) {
                                initialValues[
                                    'charter_rates_locations_date_from'
                                ] = query[key][key2]['date_from'];
                            }

                            if (query[key][key2]['date_to']) {
                                initialValues[
                                    'charter_rates_locations_date_to'
                                ] = query[key][key2]['date_to'];
                            }

                            let charter_rate_from =
                                query[key][key2]['price']
                                    .filter((rate) => rate.operator === 'gte')
                                    .map((rate) => rate.rate)[0] || null;
                            if (charter_rate_from) {
                                initialValues[
                                    'charter_rates_from'
                                ] = charter_rate_from;
                            }

                            let charter_rate_to =
                                query[key][key2]['price']
                                    .filter((rate) => rate.operator === 'lte')
                                    .map((rate) => rate.rate)[0] || null;
                            if (charter_rate_from) {
                                initialValues[
                                    'charter_rates_to'
                                ] = charter_rate_to;
                            }
                        } else if (key2 === 'brokers') {
                            if (state.saved_searches.initSearch.broker_info) {
                                let broker = [
                                    {
                                        id:
                                            state.saved_searches.initSearch
                                                .broker_info.id,
                                        name:
                                            (state.saved_searches.initSearch
                                                .broker_info.company
                                                ? state.saved_searches
                                                      .initSearch.broker_info
                                                      .company + ' - '
                                                : '') +
                                            (state.saved_searches.initSearch
                                                .broker_info.name
                                                ? state.saved_searches
                                                      .initSearch.broker_info
                                                      .name
                                                : '')
                                    }
                                ];

                                if (broker.length > 0)
                                    initialValues.has_brokers = broker;
                            }
                        } else if (key2 === 'companies') {
                            let company =
                                state.saved_searches.initSearch.query[key][
                                    key2
                                ];
                            initialValues.has_companies = [
                                { name: company[0] }
                            ];
                        } else if (
                            key2 === 'types' &&
                            ownProps.type === YACHT_TYPE_CHARTER
                        ) {
                            initialValues[
                                key + '_' + key2
                            ] = groupYachtTypesByType(
                                state.common.types,
                                query[key][key2]
                            );
                        } else {
                            initialValues[key + '_' + key2] = query[key][key2];
                        }
                    } else if (key === 'include_like') {
                        if (key2 === 'builder') {
                            let builder = [];
                            if (
                                query[key][key2] &&
                                query[key][key2].length > 0
                            ) {
                                query[key][key2].forEach(function (b, index) {
                                    builder.push({ name: b });
                                });
                            }

                            initialValues.includes_like_builder = builder;
                        } else {
                            initialValues[key + '_' + key2] = query[key][key2];
                        }
                    } else if (key === 'include') {
                        if (key2 === 'status') {
                            initialValues[
                                `custom_include_status-${query[key][key2]}`
                            ] = true;
                        } else if (key2 === 'status_detail') {
                            initialValues[
                                `custom_include_status_detail-${query[key][key2]}`
                            ] = true;
                        } else {
                            initialValues[key + '_' + key2] = query[key][key2];
                        }
                    } else if (key === 'equal') {
                        if (
                            key2 === 'year_built' ||
                            key2 === 'loa' ||
                            key2 === 'asking_price'
                        ) {
                            //correct matching for between keys that act as equal
                            initialValues['between_' + key2] = [];
                            initialValues['between_' + key2].push(
                                query[key][key2]
                            );
                        } else {
                            initialValues[key + '_' + key2] =
                                query[key][key2] === 1
                                    ? true
                                    : query[key][key2];
                        }
                    } else {
                        initialValues[key + '_' + key2] = query[key][key2];

                        const price_arrays = ['price_lte', 'price_gte'];
                        if (price_arrays.includes(key)) {
                            const priceIndex = price_arrays.findIndex(
                                (x) => x === key
                            );
                            const keyPrice = price_arrays[priceIndex];

                            const getTypePrice = keyPrice.substring(
                                keyPrice.lastIndexOf('_') + 1,
                                keyPrice.length
                            );

                            Object.keys(query[key]).map((keyVal) => {
                                if (keyVal === 'field') {
                                    initialValues[
                                        getTypePrice + '_' + query[key][keyVal]
                                    ] = query[key]['amount'];
                                } else if (keyVal === 'currency') {
                                    initialValues['season_' + keyVal] =
                                        query[key][keyVal];
                                }
                                return keyVal;
                            });
                        }

                        if (key2 === 'summer_low_season_rate') {
                            initialValues.season = 'summer';
                        } else if (key2 === 'winter_low_season_rate') {
                            initialValues.season = 'winter';
                        }
                    }
                }
            });
        });
    } else {
        if (ownProps.initialFilters) {
            // as a temp solution mimic memoization to avoid form reset
            if (
                !__t_initialValues ||
                __t_ownProps.initialFilters !== ownProps.initialFilters
            ) {
                __t_ownProps = ownProps;
                __t_initialValues = Object.assign(
                    initialValues,
                    toFilterNames(ownProps.initialFilters)
                );
            }

            initialValues = __t_initialValues;
        }
    }

    if (
        state.brokers.initBroker &&
        state.brokers.initBroker.id &&
        state.hasSearch
    ) {
        let broker = [
            {
                id: state.brokers.initBroker.id,
                name:
                    (state.brokers.initBroker.company
                        ? state.brokers.initBroker.company + ' - '
                        : '') +
                    (state.brokers.initBroker.name
                        ? state.brokers.initBroker.name
                        : '')
            }
        ];
        initialValues.has_brokers = broker;
    }

    //initialize company filter from url
    if (window.location.search.includes('?company=')) {
        initialValues.has_companies = [
            {
                name: decodeURI(window.location.search.replace('?company=', ''))
            }
        ];
    }

    // check initialValues for special country values
    if (initialValues.include_country) {
        let cValues = initialValues.include_country.sort();
        // check if any region is included in search
        Object.keys(regionCountries).forEach((key) => {
            const countryList = regionCountries[key].sort();
            let i = 0,
                j = 0;
            while (i < cValues.length && j < countryList.length) {
                if (cValues[i] < countryList[j]) {
                    i++;
                } else if (cValues[i] === countryList[j]) {
                    i++;
                    j++;
                } else {
                    return;
                }
            }

            if (j !== countryList.length) {
                return;
            }

            // replace region countries with region
            const nVals = [key];
            i = 0;
            j = 0;
            while (i < cValues.length) {
                if (cValues[i] === countryList[j]) {
                    j++;
                } else {
                    nVals.push(cValues[i]);
                }
                i++;
            }
            cValues = nVals.sort();
        });
        initialValues.include_country = cValues;
    }

    return {
        yachts: state.yachts.search,
        initSearch:
            ownProps.location.search && ownProps.location.search !== ''
                ? state.saved_searches.initSearch
                : {},
        countries: state.common.countries.map((st) => {
            return { id: st.name, name: st.name };
        }),
        states: state.common.states.map((st) => {
            return { id: st.name, name: st.name };
        }),
        types: state.common.types.map((type) => ({
            value: type.id,
            label: type.text
        })),
        locations: state.common.locations,
        brokers: state.yachts.search.brokers,
        country: selector(state, 'include_country'),
        new_builder: selector(state, 'new_builder'),
        season: selector(state, 'season'),
        initialValues: initialValues,
        realRates: state.rates.rates.rates,
        user_role: state.auth.user_role,
        formValues: get(state, 'form.yachts_filters.values', {})
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(form(Filters));
