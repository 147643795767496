import React from 'react';
import { Row, Col } from 'reactstrap';

import Header from '../../common/header/Header';
import BookingsTable from './components/BookingsTable';
import Loader from '../../common/components/Loader';

const IndexBookingsPresenter = props => {
    return (
        <div className="content-inner-wrapper">
            <Header menu={props.menu} history={props.history} />

            <div className="content-inner">
                {props.loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <Row>
                        <Col xs="12" lg="9">
                            <BookingsTable
                                table_headers={props.table_headers}
                                bookings={props.bookings}
                                hasPagination={true}
                                showYachtColumn={true}
                                onTableChange={props.onTableChange}
                                userRole={props.userRole}
                            />
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
};

export default IndexBookingsPresenter;
