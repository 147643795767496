import React, { Component } from 'react';

import ReactSVG from 'react-svg';
import { NavLink, Link } from 'react-router-dom';

import avatar from '../assets/svgs/avatar.svg';
import admin from '../assets/svgs/admin.svg';
import broker from '../assets/svgs/broker.svg';
import editIcon from '../assets/svgs/edit-account.svg';
import moderator from '../assets/svgs/moderator.svg';
import owner from '../assets/svgs/owner.svg';

import brokers from '../assets/svgs/brokers.svg';
import customers from '../assets/svgs/customers.svg';
import destinations from '../assets/svgs/destinations.svg';
import logoDavinci from '../assets/svgs/logo-davinci.svg';
import logOut from '../assets/svgs/log-out.svg';
import management from '../assets/svgs/management.svg';
import places from '../assets/svgs/places.svg';
import salesListings from '../assets/svgs/saleslistings_icon-02.svg';
import savedSearches from '../assets/svgs/saved-searches.svg';
import reports from '../assets/svgs/reports.svg';
import yachts from '../assets/svgs/yachts.svg';
import news from '../assets/svgs/news.svg';
import mail from '../assets/svgs/mail.svg';
import dashboard_icon from '../assets/svgs/dashboard_icon.svg';
import calendar from '../assets/svgs/icon-calendar.svg';

import can from '../infrastructure/helpers/can';
import {
    isOwner,
    isWebsiteAdmin,
    isAdmin,
    isModerator,
    isBrokerOrLess,
    isInEmailList
} from 'infrastructure/helpers/roles';

class Sidebar extends Component {
    render() {
        const { user, user_role } = this.props;

        let str = user && user.name ? user.name : '';
        let matches = str.match(/\b(\w)/g);
        let acronym = matches !== null ? matches.join('.') : '';

        return (
            <aside
                id="sidebar"
                className={
                    user_role !== 'broker' &&
                    user_role !== 'broker_sales' &&
                    user_role !== 'broker_charter'
                        ? 'sidebar-admin'
                        : ''
                }
            >
                <div className="sidebar-head">
                    <div className="sidebar-brand">
                        <Link to="/dashboard">
                            <ReactSVG
                                style={{ height: 32 }}
                                path={logoDavinci}
                            />
                        </Link>
                        {process.env.REACT_APP_ENV !== 'prod' && (
                            <div className="mt-1">
                                {process.env.REACT_APP_ENV === 'development'
                                    ? 'Dev'
                                    : 'Stage'}
                            </div>
                        )}
                    </div>
                    <div
                        className={`sidebar-user ${
                            !this.props.open && 'sidebar-closed'
                        }`}
                    >
                        <div className="user-avatar">
                            <Link to="/user">
                                <img
                                    className="img-rounded"
                                    width="88"
                                    height="88"
                                    src={
                                        user && user.photo ? user.photo : avatar
                                    }
                                    alt=""
                                />

                                <div className="edit-icon">
                                    <ReactSVG path={editIcon} />
                                </div>
                            </Link>
                        </div>
                        <span className="user-name">
                            <span className="fullname">{str}</span>
                            <span className="first-letter">
                                {acronym + '.'}
                            </span>
                        </span>
                        <div className="user-type">
                            <ReactSVG
                                style={{ height: 32 }}
                                path={
                                    isOwner(user_role)
                                        ? owner
                                        : isAdmin(user_role)
                                        ? admin
                                        : isModerator(user_role)
                                        ? moderator
                                        : isBrokerOrLess(user_role)
                                        ? broker
                                        : broker
                                }
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={`sidebar-body ${
                        user_role &&
                        (user_role === 'broker' ||
                            user_role === 'broker_sales' ||
                            user_role === 'broker_charter') &&
                        'marginTop10'
                    }`}
                >
                    {user_role &&
                        (user_role === 'broker' ||
                            user_role === 'broker_sales' ||
                            user_role === 'broker_charter') && (
                            <ul className="sidebar-list sidebar-nav list-unstyled">
                                {can('viewSales', this.props.user_role) && (
                                    <li>
                                        <NavLink
                                            className="d-flex align-items-center"
                                            to="/dashboard"
                                        >
                                            <ReactSVG
                                                style={{ height: 24 }}
                                                path={dashboard_icon}
                                            />
                                            <span>Dashboard</span>
                                        </NavLink>
                                    </li>
                                )}
                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to={
                                            '/clients' +
                                            (user_role !== 'broker_sales' &&
                                            user_role !== 'broker_charter'
                                                ? ''
                                                : user_role === 'broker_sales'
                                                ? '/sales'
                                                : '/charters')
                                        }
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={customers}
                                        />{' '}
                                        <span>Contacts and Proposals</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to={
                                            '/yachts/view' +
                                            (user_role !== 'broker_sales' &&
                                            user_role !== 'broker_charter'
                                                ? '/sales'
                                                : user_role === 'broker_sales'
                                                ? '/sales'
                                                : '/charter')
                                        }
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={yachts}
                                        />{' '}
                                        <span>Yachts</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="/searches"
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={savedSearches}
                                        />
                                        <span>My Saved Searches</span>
                                    </NavLink>
                                </li>
                                {can('viewSales', this.props.user_role) && (
                                    <li>
                                        <NavLink
                                            className="d-flex align-items-center"
                                            to="/drive"
                                        >
                                            <ReactSVG
                                                style={{ height: 24 }}
                                                path={reports}
                                            />
                                            <span>Useful Material</span>
                                        </NavLink>
                                    </li>
                                )}
                                {/* hide newsletters DV-492 */}
                                {/* <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="/newsletters"
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={mail}
                                            fill="#41506e"
                                        />
                                        <span>Newsletters</span>
                                    </NavLink>
                                </li> */}

                                {can('viewSales', this.props.user_role) && (
                                    <li>
                                        <NavLink
                                            className="d-flex align-items-center"
                                            to="/sales-listings"
                                        >
                                            <ReactSVG
                                                style={{ height: 24 }}
                                                path={salesListings}
                                            />
                                            <span>Sales Listings</span>
                                        </NavLink>
                                    </li>
                                )}
                                {/* To be uncommented after extra fixes in sprint15 */}
                                {/* {can('viewCharter', this.props.user_role) && (
                                    <li>
                                        <NavLink
                                            className="d-flex align-items-center"
                                            to="/charter-listings"
                                        >
                                            <ReactSVG
                                                style={{ height: 24 }}
                                                path={salesListings}
                                            />
                                            <span>Charter Listings</span>
                                        </NavLink>
                                    </li>
                                )} */}
                            </ul>
                        )}

                    {user_role &&
                        user_role !== 'broker' &&
                        user_role !== 'broker_sales' &&
                        user_role !== 'broker_charter' && (
                            <ul className="sidebar-list sidebar-nav list-unstyled">
                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="/dashboard"
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={dashboard_icon}
                                        />
                                        <span>Dashboard</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="/yachts/view/sales"
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={yachts}
                                        />{' '}
                                        <span>Yachts</span>
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="/external-yachts"
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={savedSearches}
                                        />
                                        <span>External Yachts</span>
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="/yacht-lists"
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={savedSearches}
                                        />
                                        <span>Yacht Lists</span>
                                    </NavLink>
                                </li>

                                {user_role && isWebsiteAdmin(user_role) && (
                                    <li>
                                        <NavLink
                                            className="d-flex align-items-center"
                                            to="/updated-yachts"
                                        >
                                            <ReactSVG
                                                style={{ height: 24 }}
                                                path={savedSearches}
                                            />
                                            <span>Updated Yachts</span>
                                        </NavLink>
                                    </li>
                                )}

                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="/sales-listings"
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={salesListings}
                                        />
                                        <span>Sales Listings</span>
                                    </NavLink>
                                </li>
                                {/* To be uncommented after extra fixes in sprint15 */}
                                {/* <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="/charter-listings"
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={salesListings}
                                        />
                                        <span>Charter Listings</span>
                                    </NavLink>
                                </li> */}

                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="/bookings"
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={calendar}
                                        />
                                        <span>Bookings</span>
                                    </NavLink>
                                </li>
                                {/* hide itineraries DV-493 */}
                                {/* <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="/itineraries"
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={destinations}
                                        />
                                        <span>Itineraries</span>
                                    </NavLink>
                                </li> */}
                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="/locations"
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={places}
                                        />
                                        <span>Locations</span>
                                    </NavLink>
                                </li>
                                {user.email && isInEmailList(user.email) && (
                                    <li>
                                        <NavLink
                                            className="d-flex align-items-center"
                                            to="/leads"
                                        >
                                            <ReactSVG
                                                style={{ height: 24 }}
                                                path={mail}
                                            />
                                            <span>Leads</span>
                                        </NavLink>
                                    </li>
                                )}

                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="/management"
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={management}
                                        />
                                        <span>Management</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="/drive"
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={reports}
                                        />
                                        <span>Useful Material</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="/contacts"
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={brokers}
                                        />
                                        <span>Broker Contacts</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="d-flex align-items-center"
                                        to="/news"
                                    >
                                        <ReactSVG
                                            style={{ height: 24 }}
                                            path={news}
                                        />
                                        <span>News</span>
                                    </NavLink>
                                </li>
                            </ul>
                        )}

                    <ul className="sidebar-list sidebar-actions list-unstyled">
                        <li>
                            <NavLink
                                className="d-flex align-items-center"
                                exact
                                to="/logout"
                            >
                                <ReactSVG
                                    style={{ height: 24 }}
                                    path={logOut}
                                />
                                <span>Log Out</span>
                            </NavLink>
                        </li>
                        <li className="hidden-md-down">
                            <button
                                type="button"
                                onClick={this.props.toggleSidebar}
                                className={
                                    'sidebar-toggle' +
                                    (this.props.open === true ? ' active' : '')
                                }
                                id="toggle-menu"
                            />
                        </li>
                    </ul>
                </div>
            </aside>
        );
    }
}

export default Sidebar;
