import axios from 'axios';
import { API_URL } from 'helpers/variables';
import { authConfig } from 'helpers/authConfig';

const url = `${API_URL}/status/public_changes_yachts`;

/**
 * Returns a list of yachts that have been updated
 * @returns {AxiosPromise}
 */
export function getUpdatedYachts() {
    return axios({
        url: `${url}`,
        method: 'GET',
        headers: authConfig()
    });
}
