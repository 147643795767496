export const equipmentWatersport = [
    {
        name: 'equipment_watersport_info',
        value: '',
        type: 'textarea',
        placeholder: 'INFO',
        className: 'mt-2'
    },
    {
        name: 'approved_rya_water_sports_center',
        value: '',
        type: 'checkbox',
        label: 'Approved rya water sports center'
    }
];
