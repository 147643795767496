import React from 'react';
import { Collapse } from 'reactstrap';

let is_open = false;

class Collapsable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            is_open: false
        };
    }

    render() {
        return (
            <div
                className={`collapse-wrapper my-2 ${
                    is_open === true ? ' opened' : ''
                }`}
            >
                <h2 className={'collapse-wrap'}>
                    <button
                        type="button"
                        className="btn btn-empty btn-title p-0"
                        data-toggle="collapse"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        onClick={() =>
                            this.setState({ is_open: !this.state.is_open })
                        }
                    >
                        <span className="collapse-label">
                            {this.props.header}
                        </span>
                    </button>

                    <span className="trigger-collapse">
                        <button
                            type="button"
                            data-toggle="collapse"
                            onClick={() =>
                                this.setState({ is_open: !this.state.is_open })
                            }
                        >
                            {this.state.is_open ? (
                                <span>-</span>
                            ) : (
                                <span>+</span>
                            )}
                        </button>
                    </span>
                </h2>
                <Collapse isOpen={this.state.is_open}>
                    {this.props.children}
                </Collapse>
            </div>
        );
    }
}

export default Collapsable;
