export const FETCH_COMMON_DESTINATIONS = 'fetch_common_destinations',
  FETCH_COMMON_DESTINATIONS_SUCCESS = 'fetch_common_destinations_success',
  FETCH_COMMON_DESTINATIONS_FAIL = 'fetch_common_destinations_fail',
  FETCH_COMMON_COUNTRIES = 'fetch_common_countries',
  FETCH_COMMON_COUNTRIES_SUCCESS = 'fetch_common_countries_success',
  FETCH_COMMON_COUNTRIES_FAIL = 'fetch_common_countries_fail',
  FETCH_COMMON_STATES = 'fetch_common_states',
  FETCH_COMMON_STATES_SUCCESS = 'fetch_common_states_success',
  FETCH_COMMON_STATES_FAIL = 'fetch_common_states_fail',
  FETCH_COMMON_LOCATIONS = 'fetch_common_locations',
  FETCH_COMMON_LOCATIONS_SUCCESS = 'fetch_common_locations_success',
  FETCH_COMMON_LOCATIONS_FAIL = 'fetch_common_locations_fail',
  FETCH_GET_YACHT_LIST = 'fetch_get_yacht_list',
  FETCH_GET_YACHT_LIST_SUCCESS = 'fetch_get_yacht_list_success',
  FETCH_GET_YACHT_LIST_FAIL = 'fetch_get_yacht_list_fail',
  FETCH_COMMON_YACHT_TYPES = 'fetch_common_yacht_types',
  FETCH_COMMON_YACHT_TYPES_SUCCESS = 'fetch_common_yacht_types_success',
  FETCH_COMMON_YACHT_TYPES_FAIL = 'fetch_common_yacht_types_fail',
  TOGGLE_SIDEBAR = 'toggle_sidebar';
