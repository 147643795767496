import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { success } from 'react-notification-system-redux';

import TestimonialsTable from './components/TestimonialsTable';
import YachtMenu from '../components/YachtMenu';
import Loader from 'common/components/Loader';
import { deleteTestimonial, getYachtTestimonials } from 'services/testimonials';
import { errorHandler } from 'helpers/alerts';

const table_headers = [
    {
        name: 'title',
        label: 'Name'
    },
    {
        name: 'description',
        label: 'Description'
    },
    {
        name: 'date',
        label: 'Date'
    },
    {
        name: 'actions',
        label: 'Actions'
    }
];

export default function Testimonials(props) {
    const dispatch = useDispatch();
    const user_role = useSelector((state) => state.auth.user_role);
    const history = useHistory();
    const match = useRouteMatch();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        loadTestimonials();
    }, []);

    const loadTestimonials = async () => {
        setLoading(true);
        try {
            const { data } = await getYachtTestimonials(params.id);
            console.log('data', data);
            setTestimonials(data);
        } catch (err) {
            errorHandler(dispatch, err?.response || err);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteTestimonial = async (id) => {
        setLoading(true);
        try {
            const { data } = await deleteTestimonial(id);
            loadTestimonials();
            dispatch(
                success({
                    title: 'Success',
                    message: 'Testimonial deleted'
                })
            );
        } catch (err) {
            errorHandler(dispatch, err?.response || err);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="content-inner-wrapper">
            <YachtMenu user_role={user_role} match={match} history={history} />
            <div className="content-inner">
                {loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <Row>
                        <Col xs="12" lg="9">
                            <TestimonialsTable
                                deleteTestimonial={handleDeleteTestimonial}
                                userRole={user_role}
                                history={history}
                                yacht_id={params.id}
                                table_headers={table_headers}
                                show_create_action={true}
                                data={testimonials}
                            />
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
}
