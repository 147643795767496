import axios from 'axios';

import {
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL
} from './_types';

import { API_URL } from '../../helpers/variables';
import { errorHandler, successHandler } from '../../helpers/alerts';
import { authConfig } from '../../helpers/authConfig';

export function editUser(params) {
  return dispatch => {
    dispatch({ type: EDIT_USER });

    const config = { headers: authConfig() };

    return axios
      .put(API_URL + '/users/current', params, config)
      .then(response => {
        dispatch({
          type: EDIT_USER_SUCCESS,
          payload: {
            user: response.data
          }
        });
        successHandler(dispatch, 'editUser');

        return response.data;
      })
      .catch(error => {
        errorHandler(dispatch, error.response, EDIT_USER_FAIL);
      });
  };
}
export function changePassword(params) {
  return dispatch => {
    dispatch({ type: CHANGE_PASSWORD });

    const config = { headers: authConfig() };

    return axios
      .put(API_URL + '/auth/change_password', params, config)
      .then(response => {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS
        });
        successHandler(dispatch, 'changePassword');

        return response.data;
      })
      .catch(error => {
        errorHandler(dispatch, error.response, CHANGE_PASSWORD_FAIL);
      });
  };
}
export function fetchUser(params) {
  return dispatch => {
    dispatch({ type: FETCH_USER });

    const config = { headers: authConfig() };

    return axios
      .get(API_URL + '/users/current', config)
      .then(response => {
        dispatch({
          type: FETCH_USER_SUCCESS,
          payload: {
            user: response.data
          }
        });

        return response.data;
      })
      .catch(error => {
        errorHandler(dispatch, error.response, FETCH_USER_FAIL);
      });
  };
}
