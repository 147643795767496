import React from 'react';
import { connect } from 'react-redux';

import ManageOfferDecorator from './ManageOfferDecorator';
import ManageOfferPresenter from './ManageOfferPresenter';

//  Redux
import { fetchCustomer } from '../../../customers/customers/_actions';
import { fetchInitOffer, fetchCreateOffer } from './redux/_actions';
import { fetchDropdownDestinations } from '../../destinations/redux/_actions';
import { functions } from '../../../helpers/functions';
import moment from 'moment';

class CreateOfferContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            externalUrlList: [],
            // itineraryCaptainUrl: '',
            itineraryClientUrl: '',
            itineraryUrl: ''
        };
    }

    componentDidMount() {
        const customer_id = this.props.match.params.id;

        this.props
            .fetchCustomer({ id: customer_id })
            .then((response) => this.setState({ loading: false }));
        this.props.fetchInitOffer({ cover_media_type: 'photo' });
        this.props.fetchDropdownDestinations();
    }

    onChangeExternalUrl = (yachtId, event) => {
        let eventValueTrimed = functions.getUrlFromInput(event);
        if (
            this.state.externalUrlList.findIndex(
                (item) => item.id === yachtId
            ) > -1
        ) {
            this.setState({
                externalUrlList: this.state.externalUrlList.map((i) => {
                    return i.id === yachtId
                        ? { ...i, url: eventValueTrimed }
                        : i;
                })
            });
        } else {
            let listItem = {
                id: yachtId,
                url: eventValueTrimed
            };

            let newlist = this.state.externalUrlList.concat(listItem);
            this.setState({ externalUrlList: newlist });
        }
    };

    onChangeItineraryUrl = (event) => {
        let eventValueTrimed = functions.getUrlFromInput(event);
        switch (event.target.name) {
            // case 'itinerary_captain_url':
            //     return this.setState({ itineraryCaptainUrl: eventValueTrimed });
            case 'itinerary_client_url':
                return this.setState({ itineraryClientUrl: eventValueTrimed });
            case 'itinerary_url':
                return this.setState({ itineraryUrl: eventValueTrimed });
        }
    };

    onSubmit(form_props) {
        let params = this.props.validateFormProps(form_props);
        params.yachts = form_props.yachts.map((yacht) => {
            const externalUrl = this.state.externalUrlList.find(
                (i) => i.id === yacht.yacht_id
            );

            return externalUrl
                ? { ...yacht, external_url: externalUrl.url }
                : yacht;
        });

        // if (this.state.itineraryCaptainUrl !== '') {
        //     params.itinerary_captain_url = this.state.itineraryCaptainUrl;
        // }
        if (this.state.itineraryClientUrl !== '') {
            params.itinerary_client_url = this.state.itineraryClientUrl;
        }
        if (this.state.itineraryUrl !== '') {
            params.itinerary_url = this.state.itineraryUrl;
        }
        params.itineraryImages = [];
        form_props.itineraryImages?.map((itineraryImage) => {
            if (itineraryImage?.url) {
                params.itineraryImages.push(itineraryImage);
            }
        });

        if (params.attachments) {
            params.attachments = params.attachments.map((i, index) => {
                return {
                    title: i.title,
                    url: i.url,
                    created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                    updated_at: moment().format('YYYY-MM-DD HH:mm:ss')
                };
            });
        }
        this.props.fetchCreateOffer(params).then((response) => {
            if (response) {
                this.props.history.push(
                    `/clients/proposals/${this.props.initCustomer.id}/${this.props.match.params.type}/view/${response.id}`
                );
            }
        });
    }

    render() {
        return (
            <ManageOfferPresenter
                loading={this.state.loading}
                history={this.props.history}
                match={this.props.match}
                user={this.props.user}
                user_role={this.props.user_role}
                customer={this.props.initCustomer}
                dropdown_destinations={this.props.dropdown_destinations}
                onSubmit={this.onSubmit.bind(this)}
                onChangeExternalUrl={this.onChangeExternalUrl}
                onChangeItineraryUrl={this.onChangeItineraryUrl}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        user_role: state.auth.user_role,
        initOffer: state.customers.offers.initOffer,
        initCustomer: state.customers.customers.initCustomer,
        dropdown_destinations: state.destinations.dropdown_destinations
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCustomer: (params) => dispatch(fetchCustomer(params)),
        fetchInitOffer: (params) => dispatch(fetchInitOffer(params)),
        fetchCreateOffer: (params) => dispatch(fetchCreateOffer(params)),
        fetchDropdownDestinations: (params) =>
            dispatch(fetchDropdownDestinations(params))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageOfferDecorator()(CreateOfferContainer));
