import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import IndexBookingsPresenter from './IndexBookingsPresenter';

import { fetchBookings } from './redux/_actions';

import calendar from '../../assets/svgs/icon-calendar.svg';

class IndexBookingsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                parent: calendar,
                items: [
                    <NavLink exact to={'/bookings'}>
                        All
                    </NavLink>
                ]
            },
            loading: true,
            order_dir: 'DESC',
            order_field: 'date_from',
            table_headers: [
                {
                    name: 'yacht_name',
                    label: 'Yacht',
                    sort: false
                },
                {
                    name: 'date_from',
                    label: 'Date From',
                    sort: true,
                    defaultSort: 'DESC'
                },
                {
                    name: 'date_to',
                    label: 'Date To',
                    sort: true,
                    defaultSort: 'DESC'
                },
                {
                    name: 'location',
                    label: 'Location From - To',
                    sort: false
                },
                {
                    name: 'status',
                    label: 'Status',
                    sort: true,
                    defaultSort: 'DESC'
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    sort: false
                }
            ]
        };
    }

    componentDidMount() {
        this.props
            .fetchBookings()
            .then((data) => this.setState({ loading: false }));
    }

    onTableChange(key, value) {
        const currentPagination = this.props.bookings.pagination;
        let params = {
            page: key === 'page' ? value : 1,
            page_size:
                key === 'page_size' ? value : currentPagination.page_size,
            order_field:
                key !== 'page' && key !== 'page_size'
                    ? key
                    : this.state.order_field,
            order_dir:
                key !== 'page' && key !== 'page_size'
                    ? value
                    : this.state.order_dir
        };

        if (key !== 'page') {
            this.setState({ order_dir: value, order_field: key });
        }

        this.props.fetchBookings(params);
    }

    render() {
        return (
            <IndexBookingsPresenter
                loading={this.state.loading}
                userRole={this.props.userRole}
                menu={this.state.menu}
                table_headers={this.state.table_headers}
                history={this.props.history}
                match={this.props.match}
                bookings={this.props.bookings}
                onTableChange={this.onTableChange.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userRole: state.auth.user_role,
        bookings: state.bookings.bookings
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBookings: (params) => dispatch(fetchBookings(params))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndexBookingsContainer);
