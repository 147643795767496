import React, { Component } from 'react';

class ActionButton extends Component {
  render() {
    return (
      <div>
        <button
          className={'btn ' + this.props.className}
          type="button"
          name="button"
          onClick={this.props.click}
        >
          {this.props.icon}
          {this.props.label}
        </button>
      </div>
    );
  }
}

export default ActionButton;
