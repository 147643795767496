import axios from 'axios';

import {
    FETCH_DESTINATIONS,
    FETCH_DESTINATIONS_SUCCESS,
    FETCH_DESTINATIONS_ERROR,
    FETCH_DROPDOWN_DESTINATIONS,
    FETCH_DROPDOWN_DESTINATIONS_SUCCESS,
    FETCH_DROPDOWN_DESTINATIONS_ERROR,
    CREATE_DESTINATION,
    CREATE_DESTINATION_SUCCESS,
    CREATE_DESTINATION_ERROR,
    FETCH_DESTINATION,
    FETCH_DESTINATION_SUCCESS,
    FETCH_DESTINATION_ERROR,
    UPDATE_DESTINATION,
    UPDATE_DESTINATION_SUCCESS,
    UPDATE_DESTINATION_ERROR,
    DELETE_DESTINATION,
    DELETE_DESTINATION_SUCCESS,
    DELETE_DESTINATION_ERROR,
    INIT_DESTINATION
} from './_types';

import { API_URL } from '../../../helpers/variables';
import { errorHandler, successHandler } from '../../../helpers/alerts';
import { authConfig } from '../../../helpers/authConfig';

const initDestination = params => {
    return dispatch => {
        dispatch({ type: INIT_DESTINATION });
    };
};

const fetchDestinations = params => {
    return dispatch => {
        dispatch({ type: FETCH_DESTINATIONS });

        const config = {
            headers: authConfig(),
            params: params
        };

        axios
            .get(API_URL + '/destinations', config)
            .then(response => {
                dispatch({
                    type: FETCH_DESTINATIONS_SUCCESS,
                    payload: {
                        destinations: response.data.destinations,
                        pagination: response.data.pagination,
                        sorting: {
                            order_dir: params.order_dir,
                            order_field: params.order_field,
                            page_size: params.page_size,
                            search: params.search ? params.search : '',
                            favourites: params.favourites
                        }
                    }
                });

                return response.data;
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: FETCH_DESTINATIONS_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchDropdownDestinations = params => {
    return dispatch => {
        dispatch({ type: FETCH_DROPDOWN_DESTINATIONS });

        const config = {
            headers: authConfig(),
            params: {
                ...params,
                format: 'dropdown'
            }
        };

        axios
            .get(API_URL + '/destinations', config)
            .then(response => {
                dispatch({
                    type: FETCH_DROPDOWN_DESTINATIONS_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: FETCH_DROPDOWN_DESTINATIONS_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchCreateDestination = params => {
    return dispatch => {
        dispatch({ type: CREATE_DESTINATION });

        const config = {
            headers: authConfig()
        };

        return axios
            .post(API_URL + '/destinations', params, config)
            .then(response => {
                dispatch({ type: CREATE_DESTINATION_SUCCESS });
                successHandler(dispatch, 'manageDestination');

                return response.data;
            })
            .catch(error => {
                errorHandler(
                    dispatch,
                    error.response,
                    CREATE_DESTINATION_ERROR
                );
            });
    };
};

const fetchDestination = (id, params) => {
    return dispatch => {
        dispatch({ type: FETCH_DESTINATION });

        const config = { headers: authConfig() };
        console.log(params);
        return axios
            .get(API_URL + '/destinations/' + id, config)
            .then(response => {
                console.log(response);
                dispatch({
                    type: FETCH_DESTINATION_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch(error => {
                errorHandler(dispatch, error.response, FETCH_DESTINATION_ERROR);
            });
    };
};

const fetchUpdateDestination = params => {
    return dispatch => {
        dispatch({ type: UPDATE_DESTINATION });

        const config = { headers: authConfig() };

        if (params.id) {
            return axios
                .put(API_URL + '/destinations/' + params.id, params, config)
                .then(response => {
                    dispatch({ type: UPDATE_DESTINATION_SUCCESS });
                    successHandler(dispatch, 'manageDestination');

                    return response.data;
                })
                .catch(error => {
                    errorHandler(
                        dispatch,
                        error.response,
                        UPDATE_DESTINATION_ERROR
                    );
                });
        }
    };
};

const fetchDeleteDestination = params => {
    console.log(params);
    return dispatch => {
        dispatch({ type: DELETE_DESTINATION });

        const config = { headers: authConfig() };

        return axios
            .delete(API_URL + '/destinations/' + params.id, config)
            .then(response => {
                dispatch({ type: DELETE_DESTINATION_SUCCESS });
                successHandler(dispatch, 'deleteDestination');

                return response.data;
            })
            .catch(error => {
                errorHandler(
                    dispatch,
                    error.response,
                    DELETE_DESTINATION_ERROR
                );
            });
    };
};

export {
    initDestination,
    fetchDestinations,
    fetchDropdownDestinations,
    fetchCreateDestination,
    fetchDestination,
    fetchUpdateDestination,
    fetchDeleteDestination
};
