import React, { Component } from 'react';
import { Field } from 'redux-form';

import RenderField from '../../common/components/RenderField';
import RenderTags from '../../common/components/RenderTags';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import { dropdowns } from '../../helpers/variables';

class ExtraFilters extends Component {
  render() {
    return (
      <div className="yachts-extra-filters-wrapper">
        <div className="row">
          <div className="col-5">
            {this.props.type === 'charters' && (
              <Field
                name="include_hull_type"
                placeholder="HULL TYPE"
                options={[
                  { value: 'displacement', label: 'Displacement' },
                  { value: 'semi_displacement', label: 'Semi displacement' },
                  { value: 'open', label: 'Open' },
                  { value: 'planing', label: 'Planing' }
                ]}
                multi={true}
                component={RenderReactSelect}
                clearable={true}
                readOnly={this.props.readOnly}
              />
            )}

            {this.props.type === 'charters' && (
              <Field
                name="include_hull_material"
                placeholder="HULL MATERIAL"
                options={dropdowns['hull_material']}
                multi={true}
                component={RenderReactSelect}
                clearable={true}
                readOnly={this.props.readOnly}
              />
            )}

            {this.props.type === 'charters' && (
              <Field
                name="equal_guests_static"
                parse={value => (value ? Number(value) : '')}
                type="number"
                placeholder="GUESTS (Static Events)"
                component={RenderField}
                readOnly={this.props.readOnly}
              />
            )}

            {this.props.type === 'charters' && (
              <Field
                name="equal_guests_cruising"
                parse={value => (value ? Number(value) : '')}
                type="number"
                placeholder="GUESTS (Cruising)"
                component={RenderField}
                readOnly={this.props.readOnly}
              />
            )}

            {this.props.type === 'sales' && (
              <div className="row">
                <div className="col-8">
                  <Field
                    name="lte_draft_max"
                    parse={value => (value ? Number(value) : '')}
                    type="number"
                    placeholder="DRAFT Max"
                    component={RenderField}
                    disabled={this.props.readOnly}
                    readOnly={this.props.readOnly}
                  />
                </div>
                <div className="col-4 pl-0">
                  <Field
                    name="draft_max_unit"
                    label=""
                    component={RenderReactSelect}
                    clearable={false}
                    options={[
                      { value: 'meters', label: 'Meter' },
                      { value: 'feet', label: 'Feet' }
                    ]}
                    disabled={this.props.readOnly}
                    readOnly={this.props.readOnly}
                  />
                </div>
              </div>
            )}

            {this.props.type === 'sales' && (
              <div className="row">
                <div className="col-4">
                  <Field
                    name="gte_beam"
                    parse={value => (value ? Number(value) : '')}
                    type="number"
                    className="mt-0"
                    placeholder="BEAM Min"
                    component={RenderField}
                    disabled={this.props.readOnly}
                    readOnly={this.props.readOnly}
                  />
                </div>
                <div className="col-4">
                  <Field
                    name="lte_beam"
                    parse={value => (value ? Number(value) : '')}
                    type="number"
                    className="mt-0"
                    placeholder="BEAM Max"
                    component={RenderField}
                    disabled={this.props.readOnly}
                    readOnly={this.props.readOnly}
                  />
                </div>
                <div className="col-4 pl-0">
                  <Field
                    name="beam_unit"
                    label=""
                    component={RenderReactSelect}
                    className="mt-0"
                    clearable={false}
                    options={[
                      { value: 'meters', label: 'Meter' },
                      { value: 'feet', label: 'Feet' }
                    ]}
                    disabled={this.props.readOnly}
                    readOnly={this.props.readOnly}
                  />
                </div>
              </div>
            )}

            {this.props.type === 'sales' && (
              <div className="row">
                <div className="col-12">
                  <Field
                    name="equal_water_capacity"
                    parse={value => (value ? Number(value) : '')}
                    className="mt-0"
                    type="number"
                    placeholder="WATER CAPACITY"
                    component={RenderField}
                    readOnly={this.props.readOnly}
                    disabled={this.props.readOnly}
                  />
                </div>
              </div>
            )}

            {this.props.type === 'sales' && (
              <div className="row align-items-center justify-content-between">
                <div className="col-2">
                  <div className="filter-label font-500 mb-2">SPEED</div>
                </div>
                <div className="col-4 pl-0">
                  <Field
                    name="between_speed_cruising[0]"
                    parse={value => (value ? Number(value) : '')}
                    type="number"
                    placeholder=""
                    className="mt-0"
                    component={RenderField}
                    readOnly={this.props.readOnly}
                  />
                </div>
                <div className="col-auto pr-1">
                  <div className="filter-label mb-2">To</div>
                </div>
                <div className="col-4 pl-0">
                  <Field
                    name="between_speed_cruising[1]"
                    parse={value => (value ? Number(value) : '')}
                    type="number"
                    placeholder=""
                    className="mt-0"
                    component={RenderField}
                    readOnly={this.props.readOnly}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="col-6 offset-1">
            <div className="row">
              {this.props.type === 'sales' && (
                <div className="col-9">
                  <Field
                    name="include_country"
                    placeholder="LOCATION"
                    options={[
                      { name: 'USA', id: 'usa' },
                      { name: 'Europe', id: 'eu' },
                      { name: 'America S. & C.', id: 'am-sc' }
                    ]
                      .concat(this.props.countries)
                      .map(val => {
                        return { value: val.id, label: val.name };
                      })}
                    multi={true}
                    component={RenderReactSelect}
                    clearable={true}
                    help={this.props.readOnly ? '' : 'You can select multiple values.'}
                    readOnly={this.props.readOnly}
                  />
                </div>
              )}

              {this.props.type === 'sales' && (
                <div className="col-9">
                  <Field
                    name="include_state"
                    placeholder="STATE"
                    options={this.props.states.map(val => {
                      return { value: val.id, label: val.name };
                    })}
                    multi={true}
                    className={
                      'mt-0 ' +
                      (this.props.selectedCountry &&
                      this.props.selectedCountry.includes('United States')
                        ? ''
                        : 'disabled')
                    }
                    component={RenderReactSelect}
                    clearable={true}
                    help={this.props.readOnly ? '' : 'You can select multiple values.'}
                    readOnly={this.props.readOnly}
                  />
                </div>
              )}

              {this.props.type === 'sales' && (
                <div className="col-9">
                  <Field
                    name="equal_city"
                    type="text"
                    placeholder="CITY"
                    className="mt-0"
                    component={RenderField}
                    readOnly={this.props.readOnly}
                    disabled={this.props.readOnly}
                  />
                </div>
              )}
            </div>

            {this.props.type === 'sales' && (
              <div className="row">
                <div className="col-9">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-2">
                      <div className="filter-label font-500 mb-2">CABINS</div>
                    </div>
                    <div className="col-4 pr-0">
                      <Field
                        name="between_cabins[0]"
                        parse={value => (value ? Number(value) : '')}
                        type="number"
                        placeholder=""
                        className="mt-0"
                        component={RenderField}
                        readOnly={this.props.readOnly}
                      />
                    </div>
                    <div className="col-auto px-0">
                      <div className="filter-label mb-2">To</div>
                    </div>
                    <div className="col-4 pl-0">
                      <Field
                        name="between_cabins[1]"
                        parse={value => (value ? Number(value) : '')}
                        type="number"
                        placeholder=""
                        className="mt-0"
                        component={RenderField}
                        readOnly={this.props.readOnly}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.props.type === 'charters' && (
              <div className="row">
                <div className="col-9">
                  <Field
                    name="like_flag"
                    type="text"
                    placeholder="FLAG"
                    component={RenderField}
                    readOnly={this.props.readOnly}
                  />
                </div>
              </div>
            )}

            {this.props.type === 'charters' && (
              <div className="row">
                <div className="col-9">
                  <RenderTags
                    name={'BUILDER'}
                    changeFieldValue={this.props.changeFieldValue}
                    newTagName="new_builder"
                    newTag={this.props.newTag}
                    tagPrefix="include_builder"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ExtraFilters;
