import React from 'react';
import RenderField from '../../common/components/RenderField';
import _debounce from 'lodash/debounce';

const regexLatinChar = /^[\u0000-\u007F]*$/;

class TableSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            errorMessage: false
        };
        this.handleSearch = _debounce(this.handleSearch, 500);
    }

    handleSearch = (search) => {
        if (regexLatinChar.test(search)) {
            this.setState({ errorMessage: false });
            this.props.onSearch('search', search);
        } else {
            this.setState({ errorMessage: true });
        }
    };

    render() {
        return (
            <div className="search-filter">
                <RenderField
                    name={'table_search'}
                    placeholder={'Search'}
                    input={{
                        value: this.state.search,
                        onChange: (e) => {
                            this.setState({ search: e.target.value });
                            this.handleSearch(e.target.value);
                        }
                    }}
                />
                <div className="text--errormesage">
                    {this.state.errorMessage
                        ? 'Search must be in english characters'
                        : ''}
                </div>
            </div>
        );
    }
}

export default TableSearch;
