import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Field, change, reduxForm, formValueSelector } from 'redux-form';
import { UncontrolledTooltip } from 'reactstrap';

import moment from 'moment';

import _orderBy from 'lodash/orderBy';

import Header from '../../common/header/Header';
import Results from '../../yachts/search/Results';

import RenderField from '../../common/components/RenderField';
import RenderRedactor from '../../common/components/RenderRedactor';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import CreatableMulti from '../../common/components/RenderReactMultiSelect';
import RenderRadio from '../../common/components/RenderRadio';

import Notifications from 'react-notification-system-redux';

import { required } from '../../helpers/validate';

import {
    fetchSavedSearches,
    fetchSavedSearchResults
} from '../../saved_searches/_actions';
import { fetchCustomers } from '../../customers/customers/_actions';

import {
    fetchSelectedYachts,
    fetchLoadYachts
} from '../../yachts/search/_actions';
import {
    fetchCreateNewsletter,
    fetchNewsletter,
    fetchSendNewsletter,
    updateNewsletter
} from './actions';

import customers from '../../assets/svgs/customers.svg';

const form = reduxForm({
    form: 'manage_newsletter'
});

const categoriesOptions = [
    { value: 'top_sales_deals', label: 'Top Sales Deals' },
    { value: 'new_on_the_market', label: 'New On The Market' },
    { value: 'featured_yachts', label: 'Featured Yachts' },
    { value: 'price_reduction', label: 'Price Reductions' }
];

const charterCategoriesOptions = [
    { value: 'new_on_the_market', label: 'New On The Market' },
    { value: 'featured_yachts', label: 'Featured Yachts' },
    { value: 'price_reduction', label: 'Special Offers' }
];

const selector = formValueSelector('manage_newsletter');

class ManageNewsletter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            menu: {
                parent: customers,
                items: []
            },
            selectedYachts: {},
            featured_yachts: [],
            new_on_the_market: [],
            price_reduction: [],
            top_sales_deals: [],
            customersIDS: [],
            newsletter_yachts: [],
            savedSearchYachts: {
                pagination: {
                    page: 1,
                    total: 0,
                    page_size: 25,
                    page_count: 1
                },
                sorting: {
                    order_field: 'loa',
                    order_dir: 'ASC',
                    search: ''
                },
                yachts: []
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps, state) {
        if (nextProps.search_type !== this.props.search_type) {
            this.setState({
                // featured_yachts: [],
                // new_on_the_market: [],
                // price_reduction: [],
                // top_sales_deals: [],
                selectedYachts: {}
            });
        }

        if (
            nextProps.search_type === 'current_sales' ||
            nextProps.search_type === 'current_charter'
        ) {
            this.setState({ by_saved_search: false });
            if (nextProps.search_type !== this.props.search_type) {
                const selected_type = nextProps.search_type.split('_').pop();
                this.mapSelectedYachts(selected_type);
            }
        }
    }

    async componentDidMount() {
        let menu = [
            <NavLink exact to={'/newsletters'}>
                All
            </NavLink>
        ];

        this.props.fetchSavedSearches({
            format: 'dropdown'
        });
        this.props.fetchCustomers({
            format: 'dropdown',
            related: 'emails'
        });
        if (this.props.match.path.includes('/create')) {
            menu.push(
                <NavLink exact to={'/newsletters/create'}>
                    Create
                </NavLink>
            );
            this.setState({ readOnly: false, isNew: true });
            if (
                this.props.history.location.search.includes('selection=charter')
            ) {
                this.props.changeFieldValue('search_type', 'current_charter');
            }
        } else if (this.props.match.path.indexOf('/view') !== -1) {
            menu.push(
                <NavLink
                    exact
                    to={'/newsletters/' + this.props.match.params.id + '/view'}
                >
                    View
                </NavLink>
            );

            this.setState({ readOnly: true });

            await this.props
                .fetchNewsletter({ id: this.props.match.params.id })
                .then(async (data) => {
                    this.props.changeFieldValue('title', data.title);
                    this.props.changeFieldValue(
                        'email_subject',
                        data.email_subject
                    );

                    if (data.tag) {
                        menu.push(
                            <NavLink
                                exact
                                to={
                                    '/newsletters/' +
                                    this.props.match.params.id +
                                    '/report/'
                                }
                            >
                                Report
                            </NavLink>
                        );
                    }

                    let customersIDS =
                        data.customers.length >= 1
                            ? data.customers.map((customer) => {
                                  return {
                                      value: customer.id,
                                      label:
                                          customer.first_name +
                                          ' ' +
                                          customer.last_name +
                                          ' (' +
                                          customer.emails[0].value +
                                          ')',
                                      isFixed: true
                                  };
                              })
                            : [];

                    this.setState({
                        title: data.title,
                        email_subject: data.email_subject,
                        emailStatus: data.status,
                        intro_text: data.intro_text,
                        featured_yachts_intro_text:
                            data.featured_yachts_intro_text,
                        new_on_the_market_intro_text:
                            data.new_on_the_market_intro_text,
                        price_reduction_intro_text:
                            data.price_reduction_intro_text,
                        top_sales_intro_text: data.top_sales_intro_text,
                        featured_yachts: JSON.parse(
                            data.featured_yachts_yachts
                        ),
                        new_on_the_market: JSON.parse(
                            data.new_on_the_market_yachts
                        ),
                        price_reduction: JSON.parse(
                            data.price_reduction_yachts
                        ),
                        top_sales_deals: JSON.parse(data.top_sales_yachts),
                        customersIDS: customersIDS,
                        initial_yachts_categories: {
                            featured_yachts: JSON.parse(
                                data.featured_yachts_yachts
                            ),
                            new_on_the_market: JSON.parse(
                                data.new_on_the_market_yachts
                            ),
                            price_reduction: JSON.parse(
                                data.price_reduction_yachts
                            ),
                            top_sales_deals: JSON.parse(data.top_sales_yachts)
                        },
                        emailsArrays:
                            data.emails && data.emails.length > 1
                                ? data.emails.split(',').map((e) => ({
                                      value: e,
                                      label: e,
                                      isFixed: true
                                  }))
                                : [],
                        chartering: data.chartering
                    });

                    if (data.chartering) {
                        this.props.changeFieldValue(
                            'search_type',
                            'current_charter'
                        );
                    } else {
                        this.props.changeFieldValue(
                            'search_type',
                            'current_sales'
                        );
                    }

                    if (data.by_saved_search === true) {
                        this.setState({
                            by_saved_search: true,
                            search_id: data.saved_search_id
                        });
                        this.props.changeFieldValue('search_type', 'search');

                        let activeSearch = this.props.saved_searches.filter(
                            (s) => s.value === data.saved_search_id
                        );
                        this.setState({
                            selectedSavedSearchTitle: activeSearch[0]
                        });

                        this.getSearchResultsByID(data.saved_search_id);
                    }
                });
        } else {
            const newsletterId = this.props.match.params.id;
            menu.push(
                <NavLink exact to={`/newsletters/${newsletterId}/edit`}>
                    Edit
                </NavLink>
            );

            this.props
                .fetchNewsletter({ id: newsletterId })
                .then(async (data) => {
                    this.props.changeFieldValue('title', data.title);
                    this.props.changeFieldValue(
                        'email_subject',
                        data.email_subject
                    );
                    this.props.changeFieldValue('emails', data.emails);

                    let customersIDS =
                        data.customers.length >= 1
                            ? data.customers.map((customer) => {
                                  return {
                                      value: customer.id,
                                      label:
                                          customer.first_name +
                                          ' ' +
                                          customer.last_name +
                                          ' (' +
                                          customer.emails[0].value +
                                          ')',
                                      isFixed: true
                                  };
                              })
                            : [];

                    this.setState({ customersIDS: customersIDS });
                    this.props.changeFieldValue('intro_text', data.intro_text);
                    this.props.changeFieldValue(
                        'featured_yachts_intro_text',
                        data.featured_yachts_intro_text
                    );
                    this.props.changeFieldValue(
                        'new_on_the_market_intro_text',
                        data.new_on_the_market_intro_text
                    );
                    this.props.changeFieldValue(
                        'price_reduction_intro_text',
                        data.price_reduction_intro_text
                    );
                    this.props.changeFieldValue(
                        'top_sales_intro_text',
                        data.top_sales_intro_text
                    );
                    this.setState({
                        emailsArrays:
                            data.emails && data.emails.length > 1
                                ? data.emails.split(',').map((e) => ({
                                      value: e,
                                      label: e
                                  }))
                                : []
                    });

                    await this.setState({
                        emailStatus: data.status,
                        featured_yachts: JSON.parse(
                            data.featured_yachts_yachts
                        ),
                        new_on_the_market: JSON.parse(
                            data.new_on_the_market_yachts
                        ),
                        price_reduction: JSON.parse(
                            data.price_reduction_yachts
                        ),
                        top_sales_deals: JSON.parse(data.top_sales_yachts),
                        initial_yachts_categories: {
                            featured_yachts: JSON.parse(
                                data.featured_yachts_yachts
                            ),
                            new_on_the_market: JSON.parse(
                                data.new_on_the_market_yachts
                            ),
                            price_reduction: JSON.parse(
                                data.price_reduction_yachts
                            ),
                            top_sales_deals: JSON.parse(data.top_sales_yachts)
                        },
                        readOnly: false,
                        newsletterId,
                        chartering: data.chartering
                    });

                    if (data.chartering) {
                        this.props.changeFieldValue(
                            'search_type',
                            'current_charter'
                        );
                    } else {
                        this.props.changeFieldValue(
                            'search_type',
                            'current_sales'
                        );
                    }

                    if (data.by_saved_search === true) {
                        this.setState({
                            by_saved_search: true,
                            search_id: data.saved_search_id
                        });
                        await this.props.changeFieldValue(
                            'search_type',
                            'search'
                        );
                        await this.props.changeFieldValue(
                            'search_id',
                            data.saved_search_id
                        );

                        this.getSearchResultsByID(data.saved_search_id);
                    }
                });
        }

        this.setState({ menu: { ...this.state.menu, items: menu } });
        this.mapSelectedYachts('sales');
    }

    get currentNewslettersYactsIDS() {
        return Array.prototype
            .concat(this.state.featured_yachts)
            .concat(this.state.new_on_the_market)
            .concat(this.state.price_reduction)
            .concat(this.state.top_sales_deals);
    }

    async mapSelectedYachts(type) {
        if (this.currentNewslettersYactsIDS.length > 0) {
            let obj = {};
            this.currentNewslettersYactsIDS.forEach(
                (yacht) => (obj[yacht] = true)
            );

            await this.props
                .fetchLoadYachts({ include: this.currentNewslettersYactsIDS })
                .then((response) => {
                    if (response) {
                        this.setState({ newsletter_yachts: response });
                    }
                });

            await this.setState({ selectedYachts: obj });
        }
    }

    fetchSelectedYachts(type) {
        const yachts = this.props
            .fetchSelectedYachts({ full_info: true }, type)
            .then((data) => {
                if (data) {
                    let selectedYachts = data.selection
                        ? data.selection.reduce((o, i, key) => {
                              o[i.id] = true;
                              return o;
                          }, {})
                        : {};

                    return selectedYachts;
                } else {
                    return {};
                }
            });

        return yachts;
    }

    getSearchResults = (event, newValue, previousValue) => {
        this.setState({ exclude: {} });
        this.setState({ selectedSearchYachts: {} });
        this.setState({ selectAllSearchYachts: false });

        if (newValue && newValue !== '') {
            let params = {
                page: 1,
                order_dir: this.props.yachts.sorting.order_dir,
                order_field: this.props.yachts.sorting.order_field,
                page_size: this.props.yachts.pagination.page_size,
                id: newValue
            };
            this.props.fetchSavedSearchResults(params).then((response) => {
                if (response.pagination && response.data) {
                    this.setState({
                        savedSearchYachts: {
                            pagination: response.pagination,
                            sorting: {
                                order_field: 'loa',
                                order_dir: 'ASC',
                                search: ''
                            },
                            yachts: response.data
                        }
                    });

                    let obj = {};
                    this.currentNewslettersYactsIDS.forEach(
                        (yacht) => (obj[yacht] = true)
                    );
                    this.setState({ selectedYachts: obj });
                }
            });
        }

        this.setState({ by_saved_search: true, saved_search_id: newValue });
    };

    getSearchResultsByID(id, params = null) {
        let _params =
            params === null
                ? {
                      page: 1,
                      order_dir: this.props.yachts.sorting.order_dir,
                      order_field: this.props.yachts.sorting.order_field,
                      page_size: this.props.yachts.pagination.page_size,
                      id: id
                  }
                : params;
        this.props.fetchSavedSearchResults(_params).then((response) => {
            if (response.pagination && response.data) {
                this.setState({
                    savedSearchYachts: {
                        pagination: response.pagination,
                        sorting: {
                            order_field: 'loa',
                            order_dir: 'ASC',
                            search: ''
                        },
                        yachts: response.data
                    }
                });

                let obj = {};
                this.currentNewslettersYactsIDS.forEach(
                    (yacht) => (obj[yacht] = true)
                );
                this.setState({ selectedYachts: obj });
            }
        });
    }

    appendSelectedYachtsTocategory = async (event, category) => {
        const yachtIds = Object.keys(this.state.selectedYachts);
        yachtIds.forEach((id) => {
            this.removeYachtFromAnyCategory(id.toString());
            this.changeYachtCategory(category, id);
        });
        this.setState({ selectedYachts: {} });
    };

    removeYachtFromAnyCategory = async (yachtId) => {
        const fyTmp = this.state.featured_yachts;
        const newOnMarketTmp = this.state.new_on_the_market;
        const priceReduction = this.state.price_reduction;
        const topSales = this.state.top_sales_deals;

        if (fyTmp.find((y) => y === yachtId)) {
            await this.setState({
                featured_yachts: fyTmp.filter((y) => y !== yachtId)
            });
        } else if (newOnMarketTmp.find((y) => y === yachtId)) {
            await this.setState({
                new_on_the_market: newOnMarketTmp.filter((y) => y !== yachtId)
            });
        } else if (priceReduction.find((y) => y === yachtId)) {
            await this.setState({
                price_reduction: priceReduction.filter((y) => y !== yachtId)
            });
        } else if (topSales.find((y) => y === yachtId)) {
            await this.setState({
                top_sales_deals: topSales.filter((y) => y !== yachtId)
            });
        }

        await this.setState({
            initial_yachts_categories: {
                featured_yachts: fyTmp.filter((y) => y !== yachtId),
                new_on_the_market: newOnMarketTmp.filter((y) => y !== yachtId),
                price_reduction: priceReduction.filter((y) => y !== yachtId),
                top_sales_deals: topSales.filter((y) => y !== yachtId)
            }
        });
    };

    getYachtCategory(yachtId) {
        // if (!this.state.initial_yachts_categories) return;
        const id = yachtId.toString();

        if (this.state.featured_yachts.includes(id)) return 'featured_yachts';
        if (this.state.new_on_the_market.includes(id))
            return 'new_on_the_market';
        if (this.state.price_reduction.includes(id)) return 'price_reduction';
        if (this.state.top_sales_deals.includes(id)) return 'top_sales_deals';
        return null;
    }

    resetInitialYachtsCategories() {
        this.setState({
            initial_yachts_categories: {
                featured_yachts: [],
                new_on_the_market: [],
                price_reduction: [],
                top_sales_deals: []
            }
        });
    }

    changeYachtCategory = async (category, yachtId) => {
        await this.removeYachtFromAnyCategory(yachtId.toString());
        if (category === null) return;
        const categoryList = this.state[category];
        categoryList.push(yachtId.toString());

        this.setState({ [category]: categoryList });
        const initialCats = this.state.initial_yachts_categories;
        initialCats[category].push(yachtId.toString());

        await this.setState({
            [category]: categoryList,
            initial_yachts_categories: initialCats
        });
    };

    excludeYacht(event, yacht) {
        const selectedYachts = { ...this.state.selectedYachts };
        if (selectedYachts[yacht.id]) {
            delete selectedYachts[yacht.id];
        } else {
            selectedYachts[yacht.id] = true;
        }
        this.setState({ selectedYachts });
    }

    selectAll = (e) => {
        const YachtsObject =
            this.props.search_type === 'current_sales' ||
            this.props.search_type === 'current_charter'
                ? this.props.selectedYachts
                : this.props.yachts;
        const selectedYachts = YachtsObject.yachts.reduce((obj, y) => {
            obj[y.id] = true;
            return obj;
        }, {});
        this.setState({ selectedYachts });
    };

    yachtsToShow = () => {
        if (this.props.match.path.includes('/create')) {
            const YachtsObject =
                this.props.search_type === 'current_sales' ||
                this.props.search_type === 'current_charter'
                    ? {
                          pagination: this.props.selectedYachts.pagination,
                          yachts: [
                              ...this.state.newsletter_yachts,
                              ...this.props.selectedYachts.yachts
                          ]
                      }
                    : this.state.savedSearchYachts;

            const { pagination } = YachtsObject;
            return {
                yachts: YachtsObject.yachts.map((yacht) => {
                    let newYacht = Object.assign({}, yacht);

                    if (this.state.selectedYachts[yacht.id]) {
                        newYacht.selected = 1;
                    } else {
                        newYacht.selected = 0;
                    }
                    newYacht.category = this.getYachtCategory(yacht.id);

                    return newYacht;
                }),
                pagination
            };
        } else {
            const YachtsObject =
                this.props.search_type === 'current_sales' ||
                this.props.search_type === 'current_charter'
                    ? {
                          pagination: this.props.selectedYachts.pagination,
                          yachts: [...this.state.newsletter_yachts]
                      }
                    : this.state.savedSearchYachts;

            const { pagination } = YachtsObject;
            return {
                yachts: YachtsObject.yachts.map((yacht) => {
                    let newYacht = Object.assign({}, yacht);

                    if (this.state.selectedYachts[yacht.id]) {
                        newYacht.selected = 1;
                    } else {
                        newYacht.selected = 0;
                    }
                    newYacht.category = this.getYachtCategory(yacht.id);

                    return newYacht;
                }),
                pagination
            };
        }
    };

    tableChange(key, newValue) {
        this.setState({ selectedYachts: {} });
        let params = {
            page: key !== 'page' ? 1 : newValue,
            order_dir:
                key !== 'page' && key !== 'page_size' && key !== 'search'
                    ? newValue
                    : this.props.yachts.sorting.order_dir,
            order_field:
                key !== 'page' && key !== 'page_size' && key !== 'search'
                    ? key
                    : this.props.yachts.sorting.order_field,
            page_size:
                key === 'page_size'
                    ? newValue
                    : this.props.yachts.pagination.page_size,
            id: this.props.search_id
        };

        this.getSearchResultsByID(this.props.search_id, params);
    }

    emailsUpdated = (customersIDS) => {
        const filteredCustomerIDs = customersIDS.filter(
            ({ value }) => !isNaN(value)
        );
        this.setState({ customersIDS: filteredCustomerIDs });
    };

    sendEmail(event) {
        event.preventDefault();

        this.props.fetchSendNewsletter({ id: this.props.match.params.id });
        this.props.history.push('/newsletters');
    }

    replicate = () => {
        const {
            title,
            emailsArrays,
            email_subject,
            featured_yachts_intro_text,
            featured_yachts,
            intro_text,
            new_on_the_market_intro_text,
            new_on_the_market,
            price_reduction_intro_text,
            price_reduction,
            top_sales_intro_text,
            top_sales_deals,
            by_saved_search,
            search_id,
            chartering
        } = this.state;
        const params = {
            broker_id: this.props.user.id,
            emails: emailsArrays.map((e) => e.value).join(','),
            title: `${title} [Draft]`,
            email_subject,
            featured_yachts_intro_text,
            featured_yachts_yachts: featured_yachts,
            intro_text,
            new_on_the_market_intro_text,
            new_on_the_market_yachts: new_on_the_market,
            price_reduction_intro_text,
            price_reduction_yachts: price_reduction,
            top_sales_intro_text,
            top_sales_deals_yachts: top_sales_deals,
            customers_ids: this.state.customersIDS.map(
                (customer) => customer.value
            ),
            chartering: chartering
        };

        if (by_saved_search) {
            params.by_saved_search = by_saved_search;
            params.search_id = search_id;
        }

        this.props.fetchCreateNewsletter(params).then((res) => {
            this.props.history.push('/newsletters');
        });
    };

    render() {
        const { handleSubmit } = this.props;

        return (
            <div className="content-inner-wrapper">
                <Header menu={this.state.menu} history={this.props.history} />

                <div className="content-inner">
                    <div className="row">
                        <div className="col-xl-11 col-12">
                            <form
                                className="list-form"
                                onSubmit={handleSubmit(
                                    this.handleFormSubmit.bind(this)
                                )}
                            >
                                <div className="row">
                                    <div
                                        className="col-8 col-xl-7"
                                        style={{ zIndex: 1 }}
                                    >
                                        <div className="row">
                                            <div className="col-11">
                                                <div className="name-wrapper no-actions">
                                                    <Field
                                                        name="title"
                                                        type="text"
                                                        placeholder="Insert newsletter title"
                                                        className="mt-0"
                                                        readOnly={
                                                            this.state.readOnly
                                                        }
                                                        disabled={
                                                            this.state.readOnly
                                                        }
                                                        validate={[required]}
                                                        component={RenderField}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-11">
                                                <Field
                                                    name="email_subject"
                                                    type="text"
                                                    className="mb-2"
                                                    placeholder="EMAIL SUBJECT"
                                                    component={RenderField}
                                                    readOnly={
                                                        this.state.readOnly
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-11 email-selector">
                                                <CreatableMulti
                                                    options={
                                                        this.props.customers
                                                    }
                                                    value={
                                                        this.state.customersIDS
                                                    }
                                                    onChange={
                                                        this.emailsUpdated
                                                    }
                                                    disabled={
                                                        this.state.readOnly
                                                    }
                                                />
                                            </div>
                                            <div
                                                className="btn btn-indication"
                                                id="contact-list-info"
                                            >
                                                i
                                            </div>
                                            <UncontrolledTooltip
                                                placement="right"
                                                target="contact-list-info"
                                            >
                                                Contacts must be created first
                                                before being used. Only the
                                                selected contacts from the list
                                                will be saved. Any manually
                                                written emails will be removed.
                                            </UncontrolledTooltip>
                                        </div>

                                        <div className="row my-2">
                                            {!this.state.readOnly && (
                                                <div className="col-11">
                                                    <div className="redactor-wrap">
                                                        <div
                                                            id="intro_text_toolbar"
                                                            className={
                                                                'external-toolbar before' +
                                                                (this.state
                                                                    .readOnly
                                                                    ? 'disabled'
                                                                    : '')
                                                            }
                                                        />
                                                    </div>
                                                    <RenderRedactor
                                                        hiddenTitle={false}
                                                        label="Intro Text"
                                                        name="intro_text"
                                                        readOnly={
                                                            this.state.readOnly
                                                        }
                                                        disabled={
                                                            this.state.readOnly
                                                        }
                                                        id="intro_text"
                                                        toolbarExternal="#intro_text_toolbar"
                                                        onChange={(value) => {
                                                            this.props.changeFieldValue(
                                                                'intro_text',
                                                                value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            {this.state.readOnly && (
                                                <div className="col-11">
                                                    <h2>Intro text</h2>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.state
                                                                .intro_text
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        {this.props.search_type === 'search' ||
                                            (this.props.search_type ===
                                                'current_sales' && (
                                                <div className="row my-2">
                                                    {!this.state.readOnly && (
                                                        <div className="col-11">
                                                            <div className="redactor-wrap">
                                                                <div
                                                                    id="top_sales_intro_text_toolbar"
                                                                    className={
                                                                        'external-toolbar before' +
                                                                        (this
                                                                            .state
                                                                            .readOnly
                                                                            ? 'disabled'
                                                                            : '')
                                                                    }
                                                                />
                                                            </div>
                                                            <RenderRedactor
                                                                hiddenTitle={
                                                                    false
                                                                }
                                                                label="Top Sales Deals Intro Text"
                                                                name="top_sales_intro_text"
                                                                readOnly={
                                                                    this.state
                                                                        .readOnly
                                                                }
                                                                disabled={
                                                                    this.state
                                                                        .readOnly
                                                                }
                                                                id="top_sales_intro_text"
                                                                toolbarExternal="#top_sales_intro_text_toolbar"
                                                                onChange={(
                                                                    value
                                                                ) => {
                                                                    this.props.changeFieldValue(
                                                                        'top_sales_intro_text',
                                                                        value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    )}

                                                    {this.state.readOnly && (
                                                        <div className="col-11">
                                                            <h2>
                                                                Top sales intro
                                                                text
                                                            </h2>
                                                            <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html: this
                                                                        .state
                                                                        .top_sales_intro_text
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            ))}

                                        <div className="row my-2">
                                            {!this.state.readOnly && (
                                                <div className="col-11">
                                                    <div className="redactor-wrap">
                                                        <div
                                                            id="new_on_the_market_intro_text_toolbar"
                                                            className={
                                                                'external-toolbar before' +
                                                                (this.state
                                                                    .readOnly
                                                                    ? 'disabled'
                                                                    : '')
                                                            }
                                                        />
                                                    </div>
                                                    <RenderRedactor
                                                        hiddenTitle={false}
                                                        label="New On The Market Intro Text"
                                                        name="new_on_the_market_intro_text"
                                                        readOnly={
                                                            this.state.readOnly
                                                        }
                                                        disabled={
                                                            this.state.readOnly
                                                        }
                                                        id="new_on_the_market_intro_text"
                                                        toolbarExternal="#new_on_the_market_intro_text_toolbar"
                                                        onChange={(value) => {
                                                            this.props.changeFieldValue(
                                                                'new_on_the_market_intro_text',
                                                                value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            {this.state.readOnly && (
                                                <div className="col-11">
                                                    <h2>
                                                        New on the market intro
                                                        text
                                                    </h2>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.state
                                                                .new_on_the_market_intro_text
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <div className="row my-2">
                                            {!this.state.readOnly && (
                                                <div className="col-11">
                                                    <div className="redactor-wrap">
                                                        <div
                                                            id="featured_yachts_intro_text_toolbar"
                                                            className={
                                                                'external-toolbar before' +
                                                                (this.state
                                                                    .readOnly
                                                                    ? 'disabled'
                                                                    : '')
                                                            }
                                                        />
                                                    </div>
                                                    <RenderRedactor
                                                        hiddenTitle={false}
                                                        label="Featured Yachts Intro Text"
                                                        name="featured_yachts_intro_text"
                                                        readOnly={
                                                            this.state.readOnly
                                                        }
                                                        disabled={
                                                            this.state.readOnly
                                                        }
                                                        id="featured_yachts_intro_text"
                                                        toolbarExternal="#featured_yachts_intro_text_toolbar"
                                                        onChange={(value) => {
                                                            this.props.changeFieldValue(
                                                                'featured_yachts_intro_text',
                                                                value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            {this.state.readOnly && (
                                                <div className="col-11">
                                                    <h2>
                                                        Featured yachts text
                                                    </h2>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.state
                                                                .featured_yachts_intro_text
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <div className="row my-2">
                                            {!this.state.readOnly && (
                                                <div className="col-11">
                                                    <div className="redactor-wrap">
                                                        <div
                                                            id="price_reduction_intro_text_toolbar"
                                                            className={
                                                                'external-toolbar before' +
                                                                (this.state
                                                                    .readOnly
                                                                    ? 'disabled'
                                                                    : '')
                                                            }
                                                        />
                                                    </div>
                                                    <RenderRedactor
                                                        hiddenTitle={false}
                                                        label={
                                                            this.props
                                                                .search_type ===
                                                                'search' ||
                                                            this.props
                                                                .search_type ===
                                                                'current_sales'
                                                                ? 'Price Reductions Intro Text'
                                                                : 'Special Offers Intro Text'
                                                        }
                                                        name="price_reduction_intro_text"
                                                        readOnly={
                                                            this.state.readOnly
                                                        }
                                                        disabled={
                                                            this.state.readOnly
                                                        }
                                                        id="price_reduction_intro_text"
                                                        toolbarExternal="#price_reduction_intro_text_toolbar"
                                                        onChange={(value) => {
                                                            this.props.changeFieldValue(
                                                                'price_reduction_intro_text',
                                                                value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            {this.state.readOnly && (
                                                <div className="col-11">
                                                    <h2>
                                                        {this.props
                                                            .search_type ===
                                                            'search' ||
                                                        this.props
                                                            .search_type ===
                                                            'current_sales'
                                                            ? 'Price Reductions Intro Text'
                                                            : 'Special Offers Intro Text'}
                                                    </h2>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.state
                                                                .price_reduction_intro_text
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <div className="row selection-label-wrapper">
                                            <div className="heading-label small-head selection-label">
                                                LOAD YACHTS
                                            </div>
                                        </div>
                                        <div className="row">
                                            {this.props.user_role !==
                                                'broker_charter' && (
                                                <div className="col-4">
                                                    <Field
                                                        name="search_type"
                                                        label="FROM SALES SELECTION"
                                                        group="search_type_current"
                                                        className="mb-2"
                                                        type="radio"
                                                        readOnly={
                                                            this.state.readOnly
                                                        }
                                                        disabled={
                                                            this.state.readOnly
                                                        }
                                                        value="current_sales"
                                                        component={RenderRadio}
                                                    />
                                                </div>
                                            )}

                                            {this.props.user_role !==
                                                'broker_sales' && (
                                                <div className="col-4">
                                                    <Field
                                                        name="search_type"
                                                        label="FROM CHARTER SELECTION"
                                                        group="search_type_current"
                                                        className="mb-2"
                                                        type="radio"
                                                        readOnly={
                                                            this.state.readOnly
                                                        }
                                                        disabled={
                                                            this.state.readOnly
                                                        }
                                                        value="current_charter"
                                                        component={RenderRadio}
                                                    />
                                                </div>
                                            )}

                                            <div className="col-4">
                                                <Field
                                                    name="search_type"
                                                    label="SAVED SEARCH"
                                                    group="search_type_search"
                                                    className="mb-2"
                                                    readOnly={
                                                        this.state.readOnly
                                                    }
                                                    disabled={
                                                        this.state.readOnly
                                                    }
                                                    type="radio"
                                                    value="search"
                                                    component={RenderRadio}
                                                />
                                            </div>
                                        </div>
                                        {this.props.match.path.indexOf(
                                            '/view'
                                        ) === -1 &&
                                            this.props.search_type ===
                                                'search' && (
                                                <div className="row no-gutters">
                                                    <div className="col-3">
                                                        <Field
                                                            name="search_id"
                                                            placeholder="MY SAVED SEARCHES"
                                                            validate={[
                                                                required
                                                            ]}
                                                            component={
                                                                RenderReactSelect
                                                            }
                                                            onChange={this.getSearchResults.bind(
                                                                this
                                                            )}
                                                            options={
                                                                this.props
                                                                    .saved_searches
                                                            }
                                                            readOnly={
                                                                this.state
                                                                    .readOnly
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                        {this.props.match.path.indexOf(
                                            '/view'
                                        ) !== -1 &&
                                            this.props.search_type ===
                                                'search' && (
                                                <div className="row selection-label-wrapper">
                                                    <div className="col-12 floating-label-wrapper">
                                                        <div className="float top ">
                                                            SELECTED SAVED
                                                            SEARCH
                                                        </div>
                                                        {this.state
                                                            .selectedSavedSearchTitle && (
                                                            <p
                                                                style={{
                                                                    marginTop:
                                                                        '20px'
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .selectedSavedSearchTitle
                                                                        .label
                                                                }
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                        {/* <div className="row">
                                            <div className="col-8">
                                                <Field
                                                    name="template"
                                                    placeholder="CHOOSE CATEGORY TO APPEND SELECTED YACHTS"
                                                    readOnly={
                                                        this.state.readOnly
                                                    }
                                                    disabled={
                                                        this.state.readOnly
                                                    }
                                                    component={
                                                        RenderReactSelect
                                                    }
                                                    onChange={this.appendSelectedYachtsTocategory.bind(
                                                        this
                                                    )}
                                                    options={
                                                        this.props
                                                            .search_type ===
                                                            'search' ||
                                                        this.props
                                                            .search_type ===
                                                            'current_sales'
                                                            ? categoriesOptions
                                                            : charterCategoriesOptions
                                                    }
                                                />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div
                                        className="col-4 col-xl-4"
                                        style={{ zIndex: 0 }}
                                    >
                                        {this.props.match.path.indexOf(
                                            '/view'
                                        ) !== -1 &&
                                        this.state.emailStatus === 'draft' ? (
                                            <div>
                                                {this.state.customersIDS
                                                    .length > 0 && (
                                                    <button
                                                        type="button"
                                                        onClick={this.sendEmail.bind(
                                                            this
                                                        )}
                                                        className="btn btn-primary ml-1"
                                                        disabled={
                                                            this.state
                                                                .emailStatus ===
                                                            'sent'
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        Send
                                                    </button>
                                                )}

                                                <NavLink
                                                    className="btn btn-primary btn-search ml-1 mt-1 d-inline-flex"
                                                    exact
                                                    to={
                                                        '/newsletters/' +
                                                        this.props.match.params
                                                            .id +
                                                        '/edit'
                                                    }
                                                >
                                                    Edit
                                                </NavLink>
                                            </div>
                                        ) : this.props.match.path.indexOf(
                                              '/view'
                                          ) !== -1 ? (
                                            ''
                                        ) : (
                                            <button
                                                type="submit"
                                                className="btn btn-primary ml-1"
                                            >
                                                Save
                                            </button>
                                        )}

                                        {this.props.match.path.indexOf(
                                            '/view'
                                        ) !== -1 && (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    this.replicate();
                                                }}
                                                className="btn btn-primary btn-search mt-1 ml-1"
                                            >
                                                Replicate
                                            </button>
                                        )}

                                        {this.state.userSelectedYachts}
                                    </div>

                                    <div className="col-12 col-xl-9">
                                        <h2
                                            style={{
                                                fontSize: '1.375rem',
                                                color: '#41506e',
                                                fontWeight: 500
                                            }}
                                        >
                                            You must append at least one yacht
                                            to a category. (Maximum yachts per
                                            category: 6)
                                        </h2>
                                        <Results
                                            allCheckbox={!this.state.readOnly}
                                            categories={
                                                this.props.search_type ===
                                                    'search' ||
                                                this.props.search_type ===
                                                    'current_sales'
                                                    ? categoriesOptions
                                                    : charterCategoriesOptions
                                            }
                                            changeYachtCategory={
                                                this.changeYachtCategory
                                            }
                                            showCheckbox={false}
                                            readOnly={this.state.readOnly}
                                            onCheckboxChange={this.excludeYacht.bind(
                                                this
                                            )}
                                            selected={
                                                Object.keys(
                                                    this.state.selectedYachts
                                                ).length
                                            }
                                            viewType={
                                                this.props.search_type ===
                                                'search'
                                                    ? 'sales'
                                                    : this.props.search_type ===
                                                      'current_sales'
                                                    ? 'sales'
                                                    : 'charter'
                                            }
                                            selectAll={!this.state.readOnly}
                                            onSelectAllChange={this.selectAll.bind(
                                                this
                                            )}
                                            userRole={this.props.user_role}
                                            yachts={this.yachtsToShow()}
                                            tableChange={this.tableChange.bind(
                                                this
                                            )}
                                            hideActions={true}
                                            hidePagination={
                                                this.props.search_type ===
                                                    'current_sales' ||
                                                this.props.search_type ===
                                                    'current_charter'
                                                    ? true
                                                    : false
                                            }
                                            hideExtraFilter={
                                                this.props.search_type ===
                                                    'current_sales' ||
                                                this.props.search_type ===
                                                    'current_charter'
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleFormSubmit(props) {
        const isChartering = this.props.search_type === 'current_charter';

        if (
            this.state.featured_yachts.length === 0 &&
            this.state.new_on_the_market.length === 0 &&
            this.state.price_reduction.length === 0 &&
            !isChartering &&
            this.state.top_sales_deals.length === 0 // top_sales_deals category is only availiable in sales newsletter type
        ) {
            const notificationOpts = {
                message: 'You must append at least one yacht to a category!',
                position: 'tr'
            };
            this.props.errorHandler(notificationOpts);
            return false;
        }

        if (
            this.state.featured_yachts.length > 6 ||
            this.state.new_on_the_market.length > 6 ||
            this.state.price_reduction.length > 6 ||
            this.state.top_sales_deals.length > 6
        ) {
            const notificationOpts = {
                message: 'You cannot append more than 6 yachts to a category!',
                position: 'tr'
            };
            this.props.errorHandler(notificationOpts);
            return false;
        }

        let params = Object.assign({}, props, {
            featured_yachts_yachts: this.state.featured_yachts,
            new_on_the_market_yachts: this.state.new_on_the_market,
            price_reduction_yachts: this.state.price_reduction,
            top_sales_deals_yachts: this.state.top_sales_deals,
            broker_id: this.props.user.id,
            by_saved_search: this.state.by_saved_search,
            chartering: isChartering
        });

        // If newletter type is chartering
        // then we dont send top sales data to api
        if (isChartering) {
            params.top_sales_deals_yachts = null;
            params.top_sales_intro_text = null;
        }
        params.customers_ids = this.state.customersIDS.map(
            (customer) => customer.value
        );
        if (!params.intro_text) params.intro_text = null;
        if (!params.featured_yachts_intro_text)
            params.featured_yachts_intro_text = null;
        if (!params.new_on_the_market_intro_text)
            params.new_on_the_market_intro_text = null;
        if (!params.price_reduction_intro_text)
            params.price_reduction_intro_text = null;
        if (!params.top_sales_intro_text) params.top_sales_intro_text = null;
        if (this.state.isNew) {
            this.props.fetchCreateNewsletter(params).then((data) => {
                if (data) {
                    this.props.history.push(
                        '/newsletters/' + data.id + '/view/'
                    );
                }
            });
        } else {
            params.updated_at = moment().format('YYYY-MM-DD HH:mm:ss');
            this.props
                .updateNewsletter({ id: this.state.newsletterId, params })
                .then((response) => {
                    this.props.history.push(
                        '/newsletters/' + this.state.newsletterId + '/view/'
                    );
                });
        }
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change('manage_newsletter', field, value)),
        fetchLoadYachts: (e) => dispatch(fetchLoadYachts(e, 'charter')),
        fetchSelectedYachts: (e, type) =>
            dispatch(fetchSelectedYachts(e, type)),
        fetchSavedSearches: (e) => dispatch(fetchSavedSearches(e, 'charter')),
        fetchSavedSearchResults: (e) =>
            dispatch(fetchSavedSearchResults(e, 'charter')),
        fetchCreateNewsletter: (e) =>
            dispatch(fetchCreateNewsletter(e, 'charter')),
        updateNewsletter: (e) => dispatch(updateNewsletter(e, 'charter')),
        fetchNewsletter: (e) => dispatch(fetchNewsletter(e, 'charter')),
        fetchSendNewsletter: (e) => dispatch(fetchSendNewsletter(e, 'charter')),
        fetchCustomers: (e) => dispatch(fetchCustomers(e, 'charter')),
        errorHandler: (options) => dispatch(Notifications.error(options))
    };
}

function mapStateToProps(state) {
    const customers = state.customers.customers.customers
        .map((customer) => {
            const email =
                customer.emails && customer.emails.length
                    ? customer.emails.find((e) => e.primary)
                        ? customer.emails.find((e) => e.primary).value
                        : customer.emails[0].value
                    : null;
            return {
                id: customer.id,
                name: `${customer.last_name} ${customer.first_name}`,
                email
            };
        })
        .filter((c) => c.email)
        .map((c) => ({
            label: `${c.name} (${c.email})`,
            value: c.id
        }));
    return {
        intro_text: selector(state, 'intro_text'),
        featured_yachts_intro_text: selector(
            state,
            'featured_yachts_intro_text'
        ),
        new_on_the_market_intro_text: selector(
            state,
            'new_on_the_market_intro_text'
        ),
        price_reduction_intro_text: selector(
            state,
            'price_reduction_intro_text'
        ),
        top_sales_intro_text: selector(state, 'top_sales_intro_text'),
        user: state.user,
        user_role: state.auth.user_role,
        saved_searches: state.saved_searches.dropdown.map((val) => {
            return { value: val.id, label: val.name };
        }),
        customers: _orderBy(customers, ['label'], ['asc']),
        yachts: state.saved_searches.results,
        selectedYachts: {
            pagination: {
                page: 1,
                page_size: 25,
                page_count: 1,
                total: state.yachts.search.selectedYachts.selection.length
            },
            yachts: state.yachts.search.selectedYachts.selection.map(
                (yacht) => {
                    let modYacht = Object.assign({}, yacht);
                    if (yacht.selected === undefined) {
                        modYacht.selected = 1;
                        modYacht.helloSotoman = 'yes';
                    }
                    return modYacht;
                }
            )
        },
        title: selector(state, 'title'),
        search_id: selector(state, 'search_id'),
        search_type: selector(state, 'search_type'),
        initialValues: {
            search_type:
                state.auth.user_role !== 'broker_charter'
                    ? 'current_sales'
                    : 'current_charter'
        },
        newsletter_yachts: state.yachts
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(form(ManageNewsletter));
