import React, { Component } from 'react';
import Datetime from 'react-datetime';
import enhanceWithClickOutside from 'react-click-outside';
import moment from 'moment';

class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isToday: props.initDate ? this.isToday(props.initDate) : false,
            isTomorrow: props.initDate ? this.isTomorrow(props.initDate) : false
        };
        this.isValidDate = this.isValidDate.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.initDate !== nextProps.initDate) {
            this.setState({
                open: false,
                isToday: nextProps.initDate
                    ? this.isToday(nextProps.initDate)
                    : false,
                isTomorrow: nextProps.initDate
                    ? this.isTomorrow(nextProps.initDate)
                    : false
            });
        }
    }

    toggle() {
        this.setState({
            open: !this.state.open
        });
    }

    goToToday() {
        this.setState({
            isToday: true,
            isTomorrow: false
        });

        this.props.onSelect(moment());
    }

    goToTomorrow() {
        this.setState({
            isToday: false,
            isTomorrow: true
        });

        this.props.onSelect(moment().add(1, 'day'));
    }

    isToday(date) {
        return moment(date).isSame(moment(), 'day');
    }

    isTomorrow(date) {
        return moment(date).isSame(moment().add(1, 'day'), 'day');
    }

    isValidDate(currentDate) {
        if (this.props.afterTodayValid) {
            return currentDate.isSameOrAfter(moment(), 'day');
        }
        if (this.props.beforeTodayValid) {
            return currentDate.isSameOrBefore(moment(), 'day');
        }
        return true;
    }

    onDateChange(selected) {
        this.setState({
            open: false,
            isToday: this.isToday(selected),
            isTomorrow: this.isTomorrow(selected)
        });

        this.props.onSelect(selected);
    }

    handleClickOutside() {
        this.setState({
            open: false
        });
    }

    render() {
        const { todayString, tomorrowString, dateString } = this.props;

        return (
            <div>
                <button
                    type="button"
                    disabled={this.props.readOnly}
                    className={
                        'btn btn-empty btn-date ' +
                        (this.state.open ? ' open' : '') +
                        (this.state.isToday ||
                        this.state.isTomorrow ||
                        this.props.initDate === ''
                            ? ' changed'
                            : '')
                    }
                    onClick={(e) => {
                        this.toggle();
                    }}
                >
                    {this.state.isToday
                        ? todayString
                        : this.state.isTomorrow
                        ? tomorrowString
                        : this.props.initDate
                        ? moment(this.props.initDate).format(
                              this.props.formatDate
                                  ? this.props.formatDate
                                  : 'DD/MM/YYYY'
                          )
                        : dateString}
                </button>
                <div
                    className={
                        'date-dropdown ' + (this.state.open ? 'open' : '')
                    }
                >
                    {!this.props.hideActionButtons && (
                        <div className="side-links-wrap">
                            <button
                                type="button"
                                className={
                                    'btn btn-empty ' +
                                    (this.state.isToday ? 'active' : '')
                                }
                                onClick={(e) => this.goToToday()}
                            >
                                {todayString}
                            </button>
                            <button
                                type="button"
                                className={
                                    'btn btn-empty ' +
                                    (this.state.isTomorrow ? 'active' : '')
                                }
                                onClick={(e) => this.goToTomorrow()}
                            >
                                {tomorrowString}
                            </button>
                        </div>
                    )}
                    <Datetime
                        locale={this.props.locale}
                        timeFormat={false}
                        open={true}
                        value={
                            this.props.initDate
                                ? moment(this.props.initDate)
                                : null
                        }
                        onChange={this.onDateChange}
                        isValidDate={this.isValidDate}
                    />
                </div>
            </div>
        );
    }
}

export default enhanceWithClickOutside(DatePicker);
