import React from 'react';
import moment from 'moment';

const ManageOfferDecorator = (props) => (WrappedComponent) => {
    return class extends React.Component {
        validateFormProps(form_props) {
            let params = {
                ...form_props,
                expires: moment(
                    form_props.expires,
                    'YYYY/MM/DD',
                    true
                ).isValid()
                    ? moment(form_props.expires).format('YYYY-MM-DD HH:mm')
                    : null,
                customer_id: this.props.match.params.id,
                template_logo:
                    form_props.template_logo &&
                    form_props.template_logo === true
                        ? 0
                        : 1,
                recipients: [
                    ...form_props.recipients_cc.map((value) => {
                        return { type: 'cc', address: value.name };
                    }),
                    ...form_props.recipients_bcc.map((value) => {
                        return { type: 'bcc', address: value.name };
                    })
                ]
            };

            delete params.recipients_cc;
            delete params.recipients_bcc;

            return params;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    validateFormProps={this.validateFormProps.bind(this)}
                />
            );
        }
    };
};

export default ManageOfferDecorator;
