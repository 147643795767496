import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Notifications from 'react-notification-system-redux';

import notificationStyle from './helpers/notificationStyle';
import { disableScrollOnNumberInputs } from 'helpers/utils';

import Home from './public/Home';
import NotFoundPage from './public/NotFoundPage';

import PrivateAdminRoute from './auth/routes/PrivateAdminRoute';
import PrivateBrokerRoute from './auth/routes/PrivateBrokerRoute';
import PrivateRoute from './auth/routes/PrivateRoute';
import PublicRoute from './auth/routes/PublicRoute';

import Login from './auth/Login';
import Logout from './auth/Logout';
import ResetPassoword from './auth/ResetPassoword';
import EditUser from './auth/user/EditUser';

import App from './App';

import IndexCustomersContainer from './pages/customers/customers/IndexCustomersContainer';
import ManageCustomer from './customers/customers/ManageCustomer';
import IndexOffersContainer from './pages/customers/offers/IndexOffersContainer';
import CreateOfferContainer from './pages/customers/offers/CreateOfferContainer';
import EditOfferContainer from './pages/customers/offers/EditOfferContainer';
import ViewOfferContainer from './pages/customers/offers/ViewOfferContainer';

import NewsletterHistory from './brokers/newsletters/NewsletterHistory';
import ManageNewsletter from './brokers/newsletters/ManageNewsletter';
import NewsletterCustomersReportContainer from './pages/newsletters/NewsletterCustomersReportContainer';
import NewsletterReportContainer from './pages/newsletters/NewsletterReportContainer';

import Brokers from './brokers/Brokers';
import ManageBroker from './brokers/broker/ManageBroker';
import IndexDestinationsContainer from './pages/destinations/IndexDestinationsContainer';
import CreateDestinationContainer from './pages/destinations/CreateDestinationContainer';
import EditDestinationContainer from './pages/destinations/EditDestinationContainer';
import SalesListings from './sales-listings/SalesListings';
import ManageSalesListings from './sales-listings/sales/ManageSalesListings';
import NewSalesListing from './sales-listings/sales/NewSalesListing';
import CharterListings from './charter-listings/CharterListings';
import NewCharterListing from './charter-listings/charter/NewCharterListing';
import ManageCharterListings from './charter-listings/charter/ManageCharterListings';
import Management from './management/Management';
import SavedSearches from './saved_searches/SavedSearches';
import IndexYachtContainer from './pages/yachts/list/IndexYachtContainer';
import ManageYacht from './yachts/yacht/ManageYacht';
import ViewYacht from './yachts/yacht/ViewYacht';
import MediaYacht from './yachts/yacht/MediaYacht';
import VideoYacht from './yachts/yacht/VideoYacht';
import ManageYachtLocationsAndRatesContainer from './pages/yachts/locationsAndRates/ManageYachtLocationsAndRatesContainer';
//import ManageYachtLocationsContainer from './pages/yachts/locations/ManageYachtLocationsContainer';
//import ManageYachtRatesContainer from './pages/yachts/rates/ManageYachtRatesContainer';
import IndexYachtBookingsContainer from './pages/yachts/bookings/IndexYachtBookingsContainer';
import CreateYachtTestimonial from './pages/yachts/testimonials/CreateYachtTestimonial';
import ViewYachtTestimonial from './pages/yachts/testimonials/ViewYachtTestimonial';
import EditYachtTestimonial from './pages/yachts/testimonials/EditYachtTestimonial';
import TestimonialsRoute from './pages/yachts/testimonials/';
import CreateYachtBookingContainer from './pages/yachts/bookings/CreateYachtBookingContainer';
import EditYachtBookingContainer from './pages/yachts/bookings/EditYachtBookingContainer';
import ViewYachtBookingContainer from './pages/yachts/bookings/ViewYachtBookingContainer';
import IndexBookingsContainer from './pages/bookings/IndexBookingsContainer';
import Dashboard from './dashboard/Dashboard';
import NewsForm from './news/NewsForm';
import News from './news/News';
import Drive from './drive/Drive';
import DriveList from './drive/DriveList';
import IndexLocationContainer from './pages/locations/IndexLocationContainer';
import CreateLocationContainer from './pages/locations/CreateLocationContainer';
import EditLocationContainer from './pages/locations/EditLocationContainer';
import ManagePresentationContainer from './pages/yachts/presentation/ManagePresentationContainer';
import IndexYachtListContainer from './pages/yacht-lists/IndexYachtListContainer';
import EditYachtListContainer from './pages/yacht-lists/EditYachtListContainer';
import IndexUpdatedYachts from './pages/updated-yachts/IndexUpdatedYachts';
import ExternalYachts from './pages/external-yachts/ExternalYachts';
import ExternalYachtView from './pages/external-yachts/ExternalYachtView';
import IndexLeadsContainer from './pages/leads/IndexLeadsContainer';
import ViewLeadContainer from './pages/leads/ViewLeadContainer';
import { ping } from 'services/user';

export default function Routes(props) {
    const location = useLocation();
    const isAuthenticated = useSelector((state) => state.auth.authenticated);

    useEffect(() => {
        // on route change ping the server declare the user is still active.
        // inactive users will be logged out automatically
        pingUser();
    }, [location]);

    useEffect(() => {
        //disable changing values on inputs of type number
        disableScrollOnNumberInputs();
    }, []);

    function pingUser() {
        if (isAuthenticated) {
            ping().catch((err) => {
                console.log('Ping fail with error ', err);
            });
        }
    }

    return (
        <>
            <Switch>
                <Route exact path="/" component={Home} />

                <PublicRoute
                    path="/login"
                    authenticated={props.authenticated}
                    component={Login}
                />
                <PrivateRoute
                    path="/logout"
                    authenticated={props.authenticated}
                    component={Logout}
                />
                <PublicRoute
                    path="/reset-password/:token"
                    authenticated={props.authenticated}
                    component={ResetPassoword}
                />
                <PublicRoute
                    path="/reset-password"
                    authenticated={props.authenticated}
                    component={ResetPassoword}
                />

                <App user={props.user} user_role={props.user_role}>
                    <PrivateAdminRoute
                        path="/contacts"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={Brokers}
                    />
                    <PrivateAdminRoute
                        path="/contacts/create"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageBroker}
                    />
                    <PrivateAdminRoute
                        path="/contacts/edit/:id"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageBroker}
                    />

                    <PrivateAdminRoute
                        path="/itineraries"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={IndexDestinationsContainer}
                    />
                    <PrivateAdminRoute
                        path="/itineraries/create"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={CreateDestinationContainer}
                    />
                    <PrivateAdminRoute
                        path="/itineraries/:id/edit"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={EditDestinationContainer}
                    />
                    <PrivateRoute
                        path="/sales-listings"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={SalesListings}
                    />
                    <PrivateAdminRoute
                        path="/sales-listings/create"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={NewSalesListing}
                    />
                    <PrivateAdminRoute
                        path="/sales-listings/edit/:id"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageSalesListings}
                    />
                    <PrivateRoute
                        path="/charter-listings"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={CharterListings}
                    />
                    <PrivateAdminRoute
                        path="/charter-listings/create"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={NewCharterListing}
                    />
                    <PrivateAdminRoute
                        path="/charter-listings/edit/:id"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageCharterListings}
                    />

                    <PrivateAdminRoute
                        path="/management"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={Management}
                    />

                    <PrivateAdminRoute
                        path="/locations"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={IndexLocationContainer}
                    />

                    <PrivateAdminRoute
                        path="/locations/create"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={CreateLocationContainer}
                    />

                    <PrivateAdminRoute
                        path="/locations/:id/edit"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={EditLocationContainer}
                    />

                    <PrivateBrokerRoute
                        path="/clients"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={IndexCustomersContainer}
                    />
                    <PrivateBrokerRoute
                        path="/clients/sales"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={IndexCustomersContainer}
                    />
                    <PrivateBrokerRoute
                        path="/clients/charters"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={IndexCustomersContainer}
                    />
                    <PrivateBrokerRoute
                        path="/clients/create"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageCustomer}
                    />
                    <PrivateBrokerRoute
                        path="/clients/view/:id"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageCustomer}
                    />
                    <PrivateBrokerRoute
                        path="/clients/edit/:id"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageCustomer}
                    />
                    <PrivateBrokerRoute
                        path="/clients/proposals/:id"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={IndexOffersContainer}
                    />
                    <PrivateBrokerRoute
                        path="/clients/proposals/:id/:type/create"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={CreateOfferContainer}
                    />
                    <PrivateBrokerRoute
                        path="/clients/proposals/:id/:type/edit/:offer_id"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={EditOfferContainer}
                    />
                    <PrivateBrokerRoute
                        path="/clients/proposals/:id/:type/view/:offer_id"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ViewOfferContainer}
                    />
                    <PrivateBrokerRoute
                        path="/newsletters"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={NewsletterHistory}
                    />
                    <PrivateBrokerRoute
                        path="/newsletters/create"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageNewsletter}
                    />
                    <PrivateBrokerRoute
                        path="/newsletters/:id/view"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageNewsletter}
                    />
                    <PrivateBrokerRoute
                        path="/newsletters/:id/edit"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageNewsletter}
                    />

                    <PrivateBrokerRoute
                        path="/newsletters/:id/report"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={NewsletterCustomersReportContainer}
                    />

                    <PrivateBrokerRoute
                        path="/newsletters/:id/report/:email_id/email"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={NewsletterReportContainer}
                    />

                    <PrivateBrokerRoute
                        path="/searches"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={SavedSearches}
                    />

                    <PrivateRoute
                        path="/user"
                        authenticated={props.authenticated}
                        component={EditUser}
                    />

                    <PrivateAdminRoute
                        path="/yachts/create"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageYacht}
                    />

                    <PrivateRoute
                        path="/yachts/view/:viewType"
                        authenticated={props.authenticated}
                        component={IndexYachtContainer}
                        exact
                    />

                    <PrivateAdminRoute
                        path="/yachts/edit/:viewType/:id"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageYacht}
                    />
                    <PrivateAdminRoute
                        path="/yachts/edit/:viewType/:id/more"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageYacht}
                    />
                    <PrivateAdminRoute
                        path="/yachts/edit/:viewType/:id/presentation/:locale"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManagePresentationContainer}
                    />
                    <PrivateAdminRoute
                        path="/yachts/edit/:viewType/:id/photos-docs"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={MediaYacht}
                    />
                    <PrivateAdminRoute
                        path="/yachts/edit/:viewType/:id/videos"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={VideoYacht}
                    />

                    {/* <PrivateAdminRoute
                        path="/yachts/edit/:viewType/:id/locations"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageYachtLocationsContainer}
                    /> */}

                    {/* <PrivateAdminRoute
                        path="/yachts/edit/:viewType/:id/rates"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageYachtRatesContainer}
                    /> */}

                    <PrivateAdminRoute
                        path="/yachts/edit/:viewType/:id/bookings/create"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={CreateYachtBookingContainer}
                    />

                    <PrivateAdminRoute
                        path="/yachts/edit/:viewType/:id/bookings/:booking_id/edit"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={EditYachtBookingContainer}
                    />

                    <PrivateAdminRoute
                        path="/yachts/view/:viewType/:id/presentation/:locale"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManagePresentationContainer}
                    />

                    <PrivateAdminRoute
                        path="/leads"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={IndexLeadsContainer}
                    />

                    <PrivateAdminRoute
                        path="/leads/:id"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ViewLeadContainer}
                    />

                    <PrivateRoute
                        path="/yachts/view/:viewType/:id/photos-docs"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={MediaYacht}
                    />
                    <PrivateRoute
                        path="/yachts/view/:viewType/:id/videos"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={VideoYacht}
                    />
                    <PrivateRoute
                        path="/yachts/view/:viewType/:id"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ViewYacht}
                    />

                    <PrivateRoute
                        path="/yachts/view/:viewType/:id/locationsAndRates"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageYachtLocationsAndRatesContainer}
                    />

                    <PrivateRoute
                        path="/yachts/edit/:viewType/:id/locationsAndRates"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageYachtLocationsAndRatesContainer}
                    />

                    {/* <PrivateRoute
                        path="/yachts/view/:viewType/:id/locations"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageYachtLocationsContainer}
                    /> */}

                    {/* <PrivateRoute
                        path="/yachts/view/:viewType/:id/rates"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ManageYachtRatesContainer}
                    /> */}

                    <PrivateRoute
                        path="/yachts/view/:viewType/:id/bookings"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={IndexYachtBookingsContainer}
                    />

                    <PrivateRoute
                        path="/yachts/view/:viewType/:id/testimonials"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={TestimonialsRoute}
                    />

                    <PrivateAdminRoute
                        path="/yachts/edit/:viewType/:id/testimonials/create"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={CreateYachtTestimonial}
                    />

                    <PrivateRoute
                        path="/yachts/view/:viewType/:id/testimonials/:testimonialId"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ViewYachtTestimonial}
                    />

                    <PrivateAdminRoute
                        path="/yachts/edit/:viewType/:id/testimonials/:testimonialId/edit"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={EditYachtTestimonial}
                    />

                    <PrivateRoute
                        path="/yachts/view/:viewType/:id/bookings/:booking_id"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ViewYachtBookingContainer}
                    />

                    <PrivateAdminRoute
                        path="/yacht-lists"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={IndexYachtListContainer}
                    />

                    <PrivateAdminRoute
                        path="/yacht-lists/:id/edit"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={EditYachtListContainer}
                    />

                    <PrivateAdminRoute
                        path="/updated-yachts"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={IndexUpdatedYachts}
                    />

                    <PrivateAdminRoute
                        path="/external-yachts"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ExternalYachts}
                    />

                    <PrivateAdminRoute
                        path="/external-yacht-view/:id/view"
                        user_role={props.user_role}
                        authenticated={props.authenticated}
                        component={ExternalYachtView}
                    />

                    <PrivateRoute
                        path="/bookings"
                        authenticated={props.authenticated}
                        component={IndexBookingsContainer}
                    />

                    <PrivateRoute
                        path="/drive"
                        authenticated={props.authenticated}
                        component={Drive}
                    />

                    <PrivateRoute
                        path="/drive/:id"
                        authenticated={props.authenticated}
                        component={DriveList}
                    />

                    <PrivateRoute
                        path="/dashboard"
                        authenticated={props.authenticated}
                        component={Dashboard}
                    />

                    <PrivateAdminRoute
                        path="/news/create"
                        authenticated={props.authenticated}
                        component={NewsForm}
                        user_role={props.user_role}
                    />

                    <PrivateAdminRoute
                        path="/news"
                        authenticated={props.authenticated}
                        component={News}
                        user_role={props.user_role}
                    />

                    <PrivateAdminRoute
                        path="/news/edit/:id"
                        authenticated={props.authenticated}
                        component={NewsForm}
                        user_role={props.user_role}
                    />
                </App>

                <Route component={NotFoundPage} />
            </Switch>

            <Notifications
                notifications={props.notifications}
                style={notificationStyle}
            />
        </>
    );
}
