import React, { Component } from 'react';

import UploadField from '../../common/components/UploadField';
import PhotoGallery from '../../common/components/PhotoGallery';

class SectionDocuments extends Component {
    render() {
        return (
            <div className={'col-12'}>
                {this.props.documents && this.props.documents.length > 0 && (
                    <PhotoGallery
                        galleryPhotos={this.props.documents}
                        closeButton={this.props.readOnly ? false : true}
                        remove={(a, b) => this.props.removeMedia(a, b)}
                        label="FILES"
                    />
                )}
                {!this.props.readOnly && (
                    <UploadField
                        name="files"
                        fileLabelName="Upload Business Card, Photo, CA agreement or other files"
                        type="multiple"
                        add={this.props.addMedia}
                        className="mb-0 mt-0"
                    />
                )}
            </div>
        );
    }
}

export default SectionDocuments;
