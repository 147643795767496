import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateAdminRoute = ({ component, ...rest }) => (
  <Route
    exact
    {...rest}
    render={props =>
      rest.authenticated &&
      rest.user_role &&
      rest.user_role !== 'broker' &&
      rest.user_role !== 'broker_sales' &&
      rest.user_role !== 'broker_charter' ? (
        React.createElement(component, props)
      ) : !rest.authenticated ? (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      ) : (
        <p>You have no access to view this page. </p>
      )
    }
  />
);

export default PrivateAdminRoute;
