import {
    FETCH_SAVED_SEARCHES,
    FETCH_SAVED_SEARCHES_SUCCESS,
    FETCH_SAVED_SEARCHES_FAIL,
    FETCH_SAVED_SEARCH,
    FETCH_SAVED_SEARCH_SUCCESS,
    FETCH_SAVED_SEARCH_FAIL,
    FETCH_SAVED_SEARCH_RESULTS,
    FETCH_SAVED_SEARCH_RESULTS_SUCCESS,
    FETCH_SAVED_SEARCH_RESULTS_FAIL,
    INIT_SAVED_SEARCH
} from './_types';

import { INIT_SEARCH } from '../yachts/search/_types';

const INITIAL_STATE = {
    pagination: { page: 1, total: 0, page_size: 1, page_count: 1 },
    sorting: { order_field: 'last_name', order_dir: 'ASC', search: '' },
    searches: [],
    initSearch: {},
    dropdown: [],
    results: {
        pagination: { page: 1, total: 0, page_size: 25, page_count: 1 },
        sorting: { order_field: 'loa', order_dir: 'ASC', search: '' },
        yachts: []
    },
    initSavedSearch: {}
};

export default function SavedSearchesReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_SAVED_SEARCHES:
            return state;
        case FETCH_SAVED_SEARCHES_SUCCESS:
            if (action.payload.dropdown) {
                return {
                    ...state,
                    dropdown: action.payload.dropdown
                };
            } else {
                return {
                    ...state,
                    pagination: {
                        page:
                            action.payload.pagination.total === 0
                                ? 0
                                : action.payload.pagination.page >
                                  action.payload.pagination.page_count
                                ? 1
                                : action.payload.pagination.page,
                        total: action.payload.pagination.total,
                        page_size: action.payload.pagination.page_size,
                        page_count: action.payload.pagination.page_count
                    },
                    searches: action.payload.searches,
                    sorting: action.payload.sorting
                };
            }
        case FETCH_SAVED_SEARCHES_FAIL:
            return { ...state, error: action.payload };
        case FETCH_SAVED_SEARCH:
            return state;
        case FETCH_SAVED_SEARCH_SUCCESS:
            return {
                ...state,
                initSearch: action.payload
            };
        case FETCH_SAVED_SEARCH_FAIL:
            return { ...state, error: action.payload };
        case INIT_SEARCH:
            return { ...state, initSearch: {} };
        case FETCH_SAVED_SEARCH_RESULTS:
            return state;
        case FETCH_SAVED_SEARCH_RESULTS_SUCCESS:
            return {
                ...state,
                results: {
                    pagination: {
                        page:
                            action.payload.pagination.total === 0
                                ? 0
                                : action.payload.pagination.page >
                                  action.payload.pagination.page_count
                                ? 1
                                : action.payload.pagination.page,
                        total: action.payload.pagination.total,
                        page_size: action.payload.pagination.page_size,
                        page_count: action.payload.pagination.page_count
                    },
                    sorting: action.payload.sorting,
                    yachts: action.payload.yachts
                }
            };
        case FETCH_SAVED_SEARCH_RESULTS_FAIL:
            return { ...state, error: action.payload };
        case INIT_SAVED_SEARCH:
            return {
                ...state,
                initSavedSearch: action.payload
            };
        default:
            return state;
    }
}
