import React from 'react';
import { Field } from 'redux-form';
import { generatePath } from 'react-router';
import RenderReactSelect from '../../common/components/RenderReactSelect';

const LocalizationDecorator = props => WrappedComponent => {
    return class extends React.Component {
        onLocaleChange(event, value) {
            let new_params = Object.assign({}, this.props.match.params);
            new_params.locale = value;
            const new_path = generatePath(this.props.match.path, new_params);

            this.props.history.push(new_path);
        }

        renderLocaleSelection() {
            return (
                <Field
                    name="locale"
                    placeholder="CHOOSE TRANSLATION"
                    component={RenderReactSelect}
                    className="mt-0 mb-2"
                    clearable={false}
                    options={[
                        { value: 'en', label: 'English' },
                        { value: 'ru', label: 'Russian' }
                    ]}
                    onChange={this.onLocaleChange.bind(this)}
                />
            );
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onLocaleChange={this.onLocaleChange.bind(this)}
                    renderLocaleSelection={this.renderLocaleSelection.bind(
                        this
                    )}
                />
            );
        }
    };
};

export default LocalizationDecorator;
