import React, { Component } from 'react';
import { FieldArray } from 'redux-form';

import ToggleCollapse from '../../common/components/ToggleCollapse';
import RenderFieldArray from '../../common/components/RenderFieldArray';

class SectionSalesHistory extends Component {
    render() {
        return (
            <ToggleCollapse
                body={
                    <div className="row">
                        <div className="col-12 pl-0">
                            <FieldArray
                                name="sales_history"
                                component={RenderFieldArray}
                                readOnly={this.props.readOnly}
                                moreLabel="ADD MORE"
                                className="col mt-2"
                                searchChange={this.props.searchChange}
                                dropdownList={this.props.dropdownList}
                                onClickList={this.props.onClickList}
                                openSearch={this.props.openSearch}
                                initFirst={true}
                                props={{
                                    customFields: [
                                        {
                                            name: 'yacht_name',
                                            placeholder: 'YACHT NAME',
                                            type: 'text',
                                            searchChange: true,
                                            className: 'col-4',
                                            searchType: 'sales'
                                        },
                                        {
                                            name: 'builder',
                                            placeholder: 'BUILDER',
                                            type: 'text',
                                            className: 'col-4'
                                        },
                                        {
                                            name: 'loa',
                                            placeholder: 'LOA',
                                            type: 'number',
                                            className: 'col-2'
                                        },
                                        {
                                            name: 'loa_unit',
                                            placeholder: 'UNIT',
                                            type: 'select',
                                            className: 'col-2',
                                            options: [
                                                {
                                                    value: 'meters',
                                                    label: 'Meter'
                                                },
                                                { value: 'feet', label: 'Feet' }
                                            ],
                                            clearable: false
                                        }
                                    ]
                                }}
                            />
                        </div>
                    </div>
                }
                header="SALES HISTORY"
            />
        );
    }
}

export default SectionSalesHistory;
