import React from 'react';

export const Radio = ({ label, ...others }) => (
  <div className="form-group radio mb-1">
    <label className="form-check-label">
      <input type="radio" {...others} className="form-control" />
      <span>{label}</span>
    </label>
  </div>
);

export const Toggle = ({ name, label, value, className = '', ...others }) => (
  <div className={'floating-label-wrapper toggle-wrapper ' + className}>
    <div className="toggle">
      <div className={'toggle-label' + value ? ' active' : ''}>{label}</div>
      <label htmlFor={name}>
        <input type="checkbox" checked={value} id={name} {...others} />
        <span />
      </label>
    </div>
  </div>
);

export const Checkbox = ({ className, disabled, label, value, ...others }) => (
  <div
    className={
      'form-group checkbox-wrapper ' + (disabled ? ' disabled ' : '') + (className ? className : '')
    }
  >
    <div className="checkbox">
      <label>
        <input type="checkbox" checked={value} disabled={disabled} {...others} />
        <span className="checkmark-box">{label}</span>
      </label>
    </div>
  </div>
);
