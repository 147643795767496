import React, { Component } from 'react';
import { FormGroup, UncontrolledTooltip } from 'reactstrap';
import { Field } from 'redux-form';

import ReactSVG from 'react-svg';

import favorite from '../../assets/svgs/favorite.svg';
import favoriteEmpty from '../../assets/svgs/favorite-empty.svg';

class RenderFavorite extends Component {
  render() {
    return (
      <FormGroup
        className={
          'checkbox-wrapper customer-favourite ' +
          (this.props.className ? this.props.className : '')
        }
        style={{ height: this.props.height ? this.props.height + 8 : 16 }}
      >
        <div className="checkbox">
          <label className={this.props.favourite === true ? 'active' : ''} id="favorite-label">
            <Field
              name="favourite"
              component="input"
              type="checkbox"
              disabled={
                this.props.disabled !== undefined && this.props.disabled === true ? true : false
              }
            />
            <ReactSVG
              path={this.props.favourite === true ? favorite : favoriteEmpty}
              style={{ height: this.props.height ? this.props.height : 16 }}
            />
          </label>
          {(this.props.tooltip === undefined || this.props.tooltip) && (
            <UncontrolledTooltip placement="bottom" target="favorite-label">
              Favorite
            </UncontrolledTooltip>
          )}
        </div>
      </FormGroup>
    );
  }
}

export default RenderFavorite;
