import {
    FETCH_MANAGE_YACHT,
    FETCH_MANAGE_YACHT_SUCCESS,
    FETCH_MANAGE_YACHT_FAIL,
    FETCH_GET_YACHT,
    FETCH_GET_YACHT_SUCCESS,
    FETCH_GET_YACHT_FAIL,
    FETCH_GET_YACHT_TYPE,
    FETCH_GET_YACHT_TYPE_SUCCESS,
    FETCH_GET_YACHT_TYPE_FAIL,
    FETCH_INIT_YACHT,
    FETCH_HISTORY_LOG,
    FETCH_HISTORY_LOG_SUCCESS,
    FETCH_HISTORY_LOG_FAIL,
    FETCH_MANAGE_MEDIA,
    FETCH_MANAGE_MEDIA_SUCCESS,
    FETCH_MANAGE_MEDIA_FAIL,
    FETCH_GET_YACHT_MEDIA,
    FETCH_GET_YACHT_MEDIA_SUCCESS,
    FETCH_GET_YACHT_MEDIA_FAIL,
    FETCH_GET_YACHT_COMMENTS,
    FETCH_GET_YACHT_COMMENTS_SUCCESS,
    FETCH_GET_YACHT_COMMENTS_FAIL,
    CHANGE_YACHT_SELECTION,
    CHANGE_YACHT_SELECTION_CHARTER,
    CHANGE_YACHT_SELECTION_SALES
} from './_types';

const INITIAL_STATE = {
    initYacht: {},
    initYachtTypes: {},
    initYachtMedia: {},
    selectedYachts: [],
    historyLog: [],
    comments: {
        comments: [],
        pagination: { page: 1, total: 0, page_size: 5, page_count: 1 }
    }
};

export default function yachtReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_MANAGE_YACHT:
            return state;
        case FETCH_MANAGE_YACHT_SUCCESS:
        case FETCH_MANAGE_YACHT_FAIL:
            return { ...state, error: action.payload };
        case FETCH_GET_YACHT:
            return {
                ...state,
                initYacht: {},
                initYachtTypes: {},
                initYachtMedia: {}
            };
        case FETCH_GET_YACHT_SUCCESS:
            return { ...state, initYacht: action.payload };
        case FETCH_GET_YACHT_FAIL:
            return { ...state, initYacht: {} };
        case FETCH_GET_YACHT_TYPE:
            return state;
        case FETCH_GET_YACHT_TYPE_SUCCESS:
            if (action.payload.data !== '') {
                let newType = {};
                newType[action.payload.type] = action.payload.data;
                let types = Object.assign(state.initYachtTypes, newType);

                return { ...state, initYachtTypes: types };
            }
            return state;
        case FETCH_GET_YACHT_TYPE_FAIL:
            return { ...state, initYachtTypes: {} };
        case FETCH_INIT_YACHT:
            return {
                ...state,
                initYacht: {},
                initYachtTypes: {},
                initYachtMedia: {}
            };
        case FETCH_HISTORY_LOG:
            return state;
        case FETCH_HISTORY_LOG_SUCCESS:
            return {
                ...state,
                historyLog: action.payload.data
            };
        case FETCH_HISTORY_LOG_FAIL:
            return state;
        case FETCH_MANAGE_MEDIA:
        case FETCH_MANAGE_MEDIA_SUCCESS:
        case FETCH_MANAGE_MEDIA_FAIL:
            return state;
        case FETCH_GET_YACHT_MEDIA:
            return state;
        case FETCH_GET_YACHT_MEDIA_SUCCESS:
            if (action.payload.data !== '') {
                let newMedia = {};
                newMedia[action.payload.type] = action.payload.data;
                let media = Object.assign(state.initYachtMedia, newMedia);

                return { ...state, initYachtMedia: media };
            }

            return state;
        case FETCH_GET_YACHT_MEDIA_FAIL:
            return { ...state, initYachtMedia: {} };
        case FETCH_GET_YACHT_COMMENTS:
            return state;
        case FETCH_GET_YACHT_COMMENTS_SUCCESS:
            return {
                ...state,
                comments: action.payload
            };
        case FETCH_GET_YACHT_COMMENTS_FAIL:
            return state;
        case CHANGE_YACHT_SELECTION:
            return {
                ...state,
                initYacht: {
                    ...state.initYacht,
                    selected: state.initYacht.selected === 0 ? 1 : 0
                }
            };
        case CHANGE_YACHT_SELECTION_CHARTER:
            return {
                ...state,
                initYacht: {
                    ...state.initYacht,
                    selected_on_charter:
                        state.initYacht.selected_on_charter === 0 ? 1 : 0
                }
            };
        case CHANGE_YACHT_SELECTION_SALES:
            return {
                ...state,
                initYacht: {
                    ...state.initYacht,
                    selected_on_sales:
                        state.initYacht.selected_on_sales === 0 ? 1 : 0
                }
            };
        default:
            return state;
    }
}
