import React from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';

const RenderDatePicker = ({
    input,
    placeholder,
    defaultValue,
    meta: { touched, error },
    disabled,
    readOnly,
    className,
    date_format
}) => {
    return (
        <div>
            <div className="mb-2 align-items-center customDatePicker floating-label-wrapper  form-group">
                <label
                    className={
                        (input.value !== '' && !error) ||
                        (disabled !== undefined && disabled)
                            ? 'float top'
                            : 'float'
                    }
                >
                    {placeholder}
                </label>

                <Datetime
                    dateForm={date_format ? date_format : 'MM/DD/YYYY'}
                    selected={
                        input.value
                            ? moment(input.value).format('YYYY/MM/DD')
                            : null
                    }
                    dateFormat={date_format ? date_format : 'MM/DD/YYYY'}
                    timeFormat={false}
                    inputProps={{
                        name: input.name,
                        placeholder: placeholder ? placeholder : 'Date',
                        required: false,
                        disabled: readOnly
                    }}
                    value={input.value !== '' ? input.value : ''}
                    onChange={(param) =>
                        input.onChange(
                            date_format
                                ? moment(param, date_format, true).isValid()
                                    ? moment(param).format(date_format)
                                    : null
                                : moment(param).isValid()
                                ? moment(param)
                                : null
                        )
                    }
                />
                {touched && error && <span>{error}</span>}
            </div>
        </div>
    );
};

export default RenderDatePicker;
