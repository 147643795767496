import React from 'react';
import moment from 'moment';

const InlineDatePickerRow = props => {
    return <div className="in-cal__row">{props.children}</div>;
};

const InlineDatePickerDay = props => {
    return (
        <div
            className={`in-cal__day in-cal__day--${props.status} ${
                props.className ? props.className : ''
            }`}
        >
            {props.day}
        </div>
    );
};

const InlineDatePickerDaysRow = props => {
    let days = [];
    let start_of_the_month = props.date;
    let end_of_the_month = moment(start_of_the_month).endOf('month');

    for (
        start_of_the_month;
        start_of_the_month.isBefore(end_of_the_month);
        start_of_the_month.add(1, 'days')
    ) {
        let day = moment(start_of_the_month).date();
        let classes = '';
        let event = props.events[day];
        let prev_event = props.events[day - 1];
        let next_event = props.events[day + 1];

        if (day === 1 || event !== prev_event) {
            classes = 'first';
        }

        if (
            moment(start_of_the_month)
                .endOf('month')
                .date() === day ||
            event !== next_event
        ) {
            classes += ' last';
        }

        days.push(
            <InlineDatePickerDay
                key={start_of_the_month.format('MMMM') + day}
                day={day}
                status={props.events[day] || 'availiable'}
                className={classes}
            />
        );
    }

    return <div className="in-cal__days">{days}</div>;
};

const InlineDatePicker = props => {
    let from = moment(props.date_from);
    let to = moment(props.date_to);
    let calendar = [];

    for (from; from.isBefore(to); from.add(1, 'months')) {
        let month = from.format('MMMM');

        calendar.push(
            <InlineDatePickerRow key={month}>
                <div className="in-cal__month">{month}</div>

                <InlineDatePickerDaysRow
                    date={moment(from)}
                    events={props.events[month] || {}}
                />
            </InlineDatePickerRow>
        );
    }

    return (
        <div className="in-cal">
            <div className="in-cal__date-actions d-flex align-items-center justify-content-between my-2">
                <h4 className="mb-0">
                    <span style={{fontWeight: 300, fontSize: '1.3rem'}}>Date Range: </span>
                    <strong>{ moment(props.date_from).format('YYYY/MM/DD') } {' - '} { moment(props.date_to).format('YYYY/MM/DD') }</strong>
                </h4>

                <div className="d-flex align-items-center">
                    <button className="btn btn-primary btn-search" onClick={props.onPreviousDate}>
                        <strong className="mr-1">{'<'}</strong> 
                        Previous
                    </button>
                    <button className="btn btn-primary btn-search ml-1" onClick={props.onNextDate}>
                        Next
                        <strong className="ml-1">{'>'}</strong>
                    </button>
                </div>
            </div>

            <div>{calendar}</div>
        </div>
    );
};

export default InlineDatePicker;
