import {
    INIT_YACHT_LIST,
    FETCH_YACHT_LISTS_SUCCESS,
    FETCH_YACHT_LIST_SUCCESS,
    UPDATE_YACHT_LIST_SUCCESS
} from './_types';

const YACHT_LISTS = {
    data: [],
    pagination: {
        page: 1,
        page_count: 1,
        page_size: 15,
        total: 1
    }
};

const YACHT_LIST = {};

const INITIAL_STATE = {
    yacht_lists: YACHT_LISTS,
    yacht_list: YACHT_LIST
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INIT_YACHT_LIST: 
            return {
                ...state,
                yacht_list: YACHT_LIST
            }
        case FETCH_YACHT_LISTS_SUCCESS:
            return {
                ...state,
                yacht_lists: {
                    data: action.payload.yacht_lists,
                    pagination: action.payload.pagination
                }
            };
        case FETCH_YACHT_LIST_SUCCESS:
        case UPDATE_YACHT_LIST_SUCCESS:
            return {
                ...state,
                yacht_list: action.payload
            };
        default:
            return state;
    }
};

export default reducer;
