import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { reduxForm, Field, change, reset } from 'redux-form';
import { errorHandler } from 'helpers/alerts';

import YachtMenu from '../components/YachtMenu';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';

// Fields
import RenderDatePicker from 'common/components/RenderDatePicker';
import RenderField from 'common/components/RenderField';

// Helpers
import { required } from 'helpers/validate';
import moment from 'moment';
import { addTestimonial } from 'services/testimonials';

export default function CreateYachtTestimonial(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const match = useRouteMatch();
    const params = useParams();

    const user_role = useSelector((state) => state.auth.user_role);

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (data) => {
        setLoading(true);

        try {
            await addTestimonial({
                title: data.title,
                description: data.description,
                yacht_id: params.id,
                date: moment(data.date, 'DD/MM/YYYY').format(
                    'YYYY-MM-DD HH:mm:ss'
                )
            });

            reset();
            history.push(`/yachts/view/charter/${params.id}/testimonials`);
        } catch (err) {
            errorHandler(dispatch, err?.response || err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="content-inner-wrapper">
            <YachtMenu user_role={user_role} match={match} history={history} />

            <form
                onSubmit={props.handleSubmit(handleSubmit)}
                className="content-inner"
            >
                <Row>
                    <Col
                        xs="12"
                        xl="8"
                        className="d-flex align-items-center justify-content-between mt-1 mb-2"
                    >
                        <h4 className="m-0">CREATE TESTIMONIAL</h4>

                        <div className="d-flex">
                            <button type="submit" className="btn btn-primary">
                                {loading ? (
                                    <i className="fa fa-spinner fa-pulse " />
                                ) : (
                                    <span>Save</span>
                                )}
                            </button>

                            <NavLink
                                to={`/yachts/view/charter/${params.id}/testimonials`}
                                className="btn btn-primary btn-red ml-1"
                            >
                                Cancel
                            </NavLink>
                        </div>
                    </Col>

                    <Col xs="12" xl="8" className="mt-2">
                        <Row>
                            <Col xs="12" md="6" className="customDatePicker">
                                <Field
                                    name="title"
                                    type="text"
                                    component={RenderField}
                                    placeholder="NAME"
                                    validate={[required]}
                                />
                            </Col>

                            <Col xs="12" md="6" className="customDatePicker">
                                <Field
                                    name="date"
                                    component={RenderDatePicker}
                                    placeholder="DATE"
                                    validate={[required]}
                                    date_format="DD/MM/YYYY"
                                />
                            </Col>

                            <Col xs="12">
                                <label className="upload-label text-uppercase mt-2">
                                    Description
                                </label>

                                <textarea
                                    className="news-desc form-control"
                                    rows="10"
                                    name="description"
                                    id="description"
                                    onChange={(ev) => {
                                        dispatch(
                                            change(
                                                'create_yacht_testimonial',
                                                'description',
                                                ev.target.value
                                            )
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        </div>
    );
}

CreateYachtTestimonial = reduxForm({
    form: 'create_yacht_testimonial',
    enableReinitialize: true
})(CreateYachtTestimonial);
