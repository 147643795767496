import React from 'react';
import { connect } from 'react-redux';
import {
    Field,
    reduxForm,
    change,
    formValueSelector,
    FieldArray
} from 'redux-form';
import { Row, Col } from 'reactstrap';

import DestinationsMenu from './components/DestinationsMenu';
import Loader from '../../common/components/Loader';
import LabelSeperator from '../../common/components/LabelSeperator';
import RenderSuggestions from './components/RenderSuggestions';
import CoverMediaType from '../../common/components/CoverMediaType';

// Fields
import RenderDraggablePoints from '../../common/components/RenderDraggablePoints';
import RenderField from '../../common/components/RenderField';

// Helpers
import { required } from '../../helpers/validate';

let ManageDestinationPresenter = props => {
    return (
        <div className="content-inner-wrapper">
            <DestinationsMenu match={props.match} history={props.history} />

            <div className="content-inner">
                {props.loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <form
                        onSubmit={props.handleSubmit(props.onSubmit)}
                        className="destinations-manage"
                    >
                        <Row>
                            <Col xs="12" lg="9">
                                <Row>
                                    <Col xs="12">
                                        <Field
                                            name="title_public"
                                            type="text"
                                            className="mb-1"
                                            placeholder="Public Title"
                                            validate={[required]}
                                            component={RenderField}
                                        />
                                    </Col>

                                    <Col xs="12">
                                        <CoverMediaType
                                            name="cover_photo_url"
                                            media_type={props.media_type}
                                            changeFieldValue={
                                                props.changeFieldValue
                                            }
                                            initial_photo={
                                                props.cover_photo_url
                                            }
                                        />
                                    </Col>

                                    <Col xs="12">
                                        <Field
                                            name="title_internal"
                                            type="text"
                                            placeholder="ITINERARY NAME IYC"
                                            validate={[required]}
                                            component={RenderField}
                                        />
                                    </Col>

                                    <FieldArray
                                        name="locations"
                                        component={RenderDraggablePoints}
                                        dropdownPlaces={
                                            props.dropdown_locations
                                        }
                                    />

                                    <Col
                                        xs="12"
                                        className="suggestions-wrap mb-3 mt-3"
                                    >
                                        <LabelSeperator label="SUGGESTIONS" />

                                        <RenderSuggestions />
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs="12" lg="3">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Save
                                </button>
                            </Col>
                        </Row>
                    </form>
                )}
            </div>
        </div>
    );
};

ManageDestinationPresenter = reduxForm({
    form: 'destination_from',
    enableReinitialize: true
})(ManageDestinationPresenter);

const selector = formValueSelector('destination_from');

const mapStateToProps = state => {
    console.log(state.destinations.destination);
    return {
        cover_photo_url: selector(state, 'cover_photo_url'),
        media_type: selector(state, 'cover_media_type'),
        initialValues: state.destinations.destination
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change('destination_from', field, value))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageDestinationPresenter);
