import React from 'react';

import { UncontrolledTooltip } from 'reactstrap';

import RenderField from '../../common/components/RenderField';
import { Field } from 'redux-form';

import { dropdowns } from '../../helpers/variables';
import { imoValidationError, invalidDate } from '../../helpers/validate';
import RenderCheckbox from '../../common/components/RenderCheckbox';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import Companies from './multiple/Companies';
import AdminFiltersActions from './AdminFiltersActions';
import { parseNumberValue } from 'common/utils';

import RenderDatePicker from '../../common/components/RenderDatePicker';
import LocationTree from '../../common/components/locations/LocationTree';
import builders from '../../infrastructure/data/builders';

export default class FiltersCharterSidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bookingDateFrom: '',
            bookingDateTo: ''
        };
    }

    setBookingDateFrom = (value) => {
        this.setState({ bookingDateFrom: value });
    };
    setBookingDateTo = (value) => {
        this.setState({ bookingDateTo: value });
    };

    render() {
        return (
            <div className={`row  mb-3 no-gutters`}>
                <div className="col-12">
                    <AdminFiltersActions cancelForm={this.props.cancelForm} />
                    <div>
                        <div className="row">
                            <div className="search-filter col-12 mb-1">
                                <RenderField
                                    name={'table_search'}
                                    placeholder="Search Yacht name, Model, or any keyword"
                                    input={{
                                        value: this.props.value,
                                        onChange: this.props.searchFunction
                                    }}
                                />
                            </div>
                        </div>

                        <div className="field-outer-wrapper mb-2">
                            <Field
                                className="mb-1"
                                component={RenderCheckbox}
                                label={'Search Previous Yacht Names too'}
                                name={'search_previous_names'}
                            />
                        </div>

                        <div className="field-outer-wrapper mb-2">
                            <Field
                                className="mb-1"
                                component={RenderCheckbox}
                                label={'Is Public?'}
                                name={'equal_charter_public'}
                            />
                        </div>

                        <div className="field-outer-wrapper mb-2">
                            <Field
                                className="mb-1"
                                component={RenderCheckbox}
                                label={'Is Archived'}
                                name={'equal_is_archived'}
                            />
                        </div>

                        <div className="mb-1">
                            <Field
                                name="like_imo"
                                type="text"
                                placeholder="IMO NUMBER"
                                className="mb-2"
                                component={RenderField}
                                validate={[imoValidationError]}
                                parse={parseNumberValue}
                                maxLength="7"
                            />
                        </div>

                        <div className="mb-1">
                            <Field
                                name="slug"
                                type="text"
                                placeholder="SLUG SEARCH"
                                className="mb-2"
                                component={RenderField}
                            />
                        </div>

                        <div className="field-outer-wrapper mb-2 mt-1">
                            <Field
                                name={'equal_curation_level'}
                                label="CURATION LEVEL"
                                component={RenderReactSelect}
                                clearable={true}
                                options={dropdowns['curation_level']}
                            />
                        </div>

                        <div className="field-outer-wrapper mb-2">
                            <Field
                                name="has_external_providers"
                                placeholder=" EXTERNAL PROVIDERS"
                                className="mb-2"
                                options={this.props.externalProviders.map(
                                    (externalProvider) => ({
                                        value: externalProvider.id,
                                        label: externalProvider.name
                                    })
                                )}
                                multi={true}
                                component={RenderReactSelect}
                                help={'You can select multiple values.'}
                            />
                        </div>
                        {/* I'll leave this here commented out, just in case they want to use it again */}

                        {/* <div className="field-outer-wrapper">
                            <div className="filter-label font-500 mb-1">
                                VESSEL STATUS
                            </div>
                            {this.props.yachtCharterStates.map((status, i) => (
                                <Field
                                    key={i}
                                    className="mb-1"
                                    component={RenderCheckbox}
                                    label={status.label}
                                    name={`custom_include_status_detail-${status.value}`}
                                />
                            ))}
                            <Field
                                className="mb-1"
                                component={RenderCheckbox}
                                label={'Without status'}
                                name={'custom_orExist_status_detail-false'}
                            />
                        </div> */}

                        <div className="floating-label-wrapper mt-0 mb-2 form-group">
                            <Field
                                name="has_types"
                                placeholder="Type"
                                className="mt-0 mb-2"
                                options={dropdowns['types']}
                                multi={false}
                                component={RenderReactSelect}
                            />
                        </div>

                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <div className="filter-label font-500">
                                YEAR BUILT
                            </div>
                            <div className="row">
                                <div className="col-2 col-md-6 pr-0">
                                    <Field
                                        name="gte_year_built"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="From"
                                        className="mt-0"
                                        component={RenderField}
                                    />
                                </div>
                                <div className="col-2 col-md-6 pr-0 pr-lg-1">
                                    <Field
                                        name="lte_year_built"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="To"
                                        className="mt-0"
                                        component={RenderField}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <div className="filter-label font-500">LOA</div>
                            <div className="row">
                                <div className="col-2 col-md-4 pr-0">
                                    <Field
                                        name="gte_loa"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="From"
                                        className="mt-0"
                                        component={RenderField}
                                    />
                                </div>
                                <div className="col-2 col-md-4 pr-0">
                                    <Field
                                        name="lte_loa"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="To"
                                        className="mt-0"
                                        component={RenderField}
                                    />
                                </div>
                                <div className="col-3 col-md-4 pr-0 pr-lg-1">
                                    <Field
                                        name="loa_unit"
                                        label=""
                                        component={RenderReactSelect}
                                        className="mt-0"
                                        clearable={false}
                                        options={[
                                            { value: 'meters', label: 'Meter' },
                                            { value: 'feet', label: 'Feet' }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <div className="filter-label font-500">
                                MASTER CABIN
                            </div>
                            <div className="row">
                                <div className="col-2 col-md-4 pr-0">
                                    <Field
                                        name="gte_master_cabin"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        className="mt-0"
                                        placeholder="Min"
                                        component={RenderField}
                                    />
                                </div>
                                <div className="col-2 col-md-4 pr-0">
                                    <Field
                                        name="lte_master_cabin"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        className="mt-0"
                                        placeholder="Max"
                                        component={RenderField}
                                    />
                                </div>
                                <div className="col-3 col-md-4 pr-0 pr-lg-1">
                                    <Field
                                        name="master_cabin_unit"
                                        label=""
                                        component={RenderReactSelect}
                                        className="mt-0"
                                        clearable={false}
                                        options={[
                                            {
                                                value: 'square_meters',
                                                label: 'm²'
                                            },
                                            {
                                                value: 'square_feet',
                                                label: 'ft²'
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <div className="filter-label font-500">SUNDECK</div>
                            <div className="row">
                                <div className="col-2 col-md-4 pr-0">
                                    <Field
                                        name="gte_sundeck"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        className="mt-0"
                                        placeholder="Min"
                                        component={RenderField}
                                    />
                                </div>
                                <div className="col-2 col-md-4 pr-0">
                                    <Field
                                        name="lte_sundeck"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        className="mt-0"
                                        placeholder="Max"
                                        component={RenderField}
                                    />
                                </div>
                                <div className="col-3 col-md-4 pr-0 pr-lg-1">
                                    <Field
                                        name="sundeck_unit"
                                        label=""
                                        component={RenderReactSelect}
                                        className="mt-0"
                                        clearable={false}
                                        options={[
                                            {
                                                value: 'square_meters',
                                                label: 'm²'
                                            },
                                            {
                                                value: 'square_feet',
                                                label: 'ft²'
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <div className="filter-label font-500">GUESTS</div>
                            <div className="row">
                                <div className="col-2 col-md-6 pr-0">
                                    <Field
                                        name="gte_guests_charter"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="From"
                                        className="mt-0"
                                        component={RenderField}
                                    />
                                </div>
                                <div className="col-2 col-md-6 pr-0 pr-lg-1">
                                    <Field
                                        name="lte_guests_charter"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="To"
                                        className="mt-0"
                                        component={RenderField}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <div className="filter-label font-500">CABINS</div>
                            <div className="row">
                                <div className="col-2 col-md-6 pr-0">
                                    <Field
                                        name="gte_cabins_charter"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="From"
                                        className="mt-0"
                                        component={RenderField}
                                    />
                                </div>
                                <div className="col-2 col-md-6 pr-0 pr-lg-1">
                                    <Field
                                        name="lte_cabins_charter"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="To"
                                        className="mt-0"
                                        component={RenderField}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <label
                                className="filter-label font-500 text-uppercase"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                Booking availability
                                <div>
                                    <div
                                        className="btn btn-indication"
                                        id="info-booking"
                                    >
                                        i
                                    </div>
                                    <UncontrolledTooltip
                                        placement="left"
                                        target="info-booking"
                                    >
                                        Provide a date range within which to
                                        search for booking availability
                                    </UncontrolledTooltip>
                                </div>
                            </label>

                            <div className="row">
                                <div className="col-6 customDatePicker">
                                    <Field
                                        name="bookings_date_from"
                                        component={RenderDatePicker}
                                        validate={
                                            this.state.bookingDateTo
                                                ? [invalidDate]
                                                : null
                                        }
                                        placeholder="DATE FROM"
                                        className="mt-0"
                                        date_format={'YYYY-MM-DD'}
                                        onChange={this.setBookingDateFrom}
                                    />
                                </div>

                                <div className="col-6 customDatePicker">
                                    <Field
                                        name="bookings_date_to"
                                        component={RenderDatePicker}
                                        validate={
                                            this.state.bookingDateFrom
                                                ? [invalidDate]
                                                : null
                                        }
                                        placeholder="DATE TO"
                                        className="mt-0"
                                        date_format={'YYYY-MM-DD'}
                                        onChange={this.setBookingDateTo}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <label
                                className="filter-label font-500 text-uppercase"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                RATES & LOCATION DATE RANGE
                                <div>
                                    <div
                                        className="btn btn-indication"
                                        id="info-button"
                                    >
                                        i
                                    </div>
                                    <UncontrolledTooltip
                                        placement="left"
                                        target="info-button"
                                    >
                                        Provide a date range within which to
                                        search for selected rates and selected
                                        locations
                                    </UncontrolledTooltip>
                                </div>
                            </label>

                            <div className="row">
                                <div className="col-6 customDatePicker">
                                    <Field
                                        name="charter_rates_locations_date_from"
                                        component={RenderDatePicker}
                                        placeholder="DATE FROM"
                                        className="mt-0"
                                        date_format={'YYYY/MM/DD'}
                                    />
                                </div>

                                <div className="col-6 customDatePicker">
                                    <Field
                                        name="charter_rates_locations_date_to"
                                        component={RenderDatePicker}
                                        placeholder="DATE TO"
                                        className="mt-0"
                                        date_format={'YYYY/MM/DD'}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <div className="filter-label font-500 text-uppercase">
                                CHARTERING RATES
                            </div>
                            <div className="row">
                                <div className="col-4 customDatePicker pr-0">
                                    <Field
                                        name="charter_rates_from"
                                        type="number"
                                        component={RenderField}
                                        placeholder="RATE FROM"
                                        className="mt-0"
                                    />
                                </div>

                                <div className="col-4 customDatePicker pr-0 pl-0">
                                    <Field
                                        name="charter_rates_to"
                                        type="number"
                                        component={RenderField}
                                        placeholder="RATE TO"
                                        className="mt-0"
                                    />
                                </div>

                                <div className="col-4 pl-0">
                                    <Field
                                        name={`charter_rates_currency`}
                                        placeholder="CURRENCY"
                                        component={RenderReactSelect}
                                        clearable={false}
                                        options={[
                                            { value: 'usd', label: 'USD' },
                                            { value: 'eur', label: 'EURO' },
                                            { value: 'gbp', label: 'GBP' },
                                            { value: 'aud', label: 'AUD' }
                                        ]}
                                        className="mt-0"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <div className="filter-label font-500">
                                CRUISING SPEED
                            </div>
                            <div className="row">
                                <div className="col-2 col-md-6 pr-0">
                                    <Field
                                        name="gte_charter_speed_cruising"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="From"
                                        className="mt-0"
                                        component={RenderField}
                                    />
                                </div>
                                <div className="col-2 col-md-6 pr-0 pr-lg-1">
                                    <Field
                                        name="lte_charter_speed_cruising"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="To"
                                        className="mt-0"
                                        component={RenderField}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <div className="filter-label font-500 text-uppercase">
                                Max No of Guests for Quayside Events
                            </div>
                            <div className="row">
                                <div className="col-2 col-md-6 pr-0">
                                    <Field
                                        name="gte_guests_static"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="From"
                                        className="mt-0"
                                        component={RenderField}
                                    />
                                </div>
                                <div className="col-2 col-md-6 pr-0 pr-lg-1">
                                    <Field
                                        name="lte_guests_static"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="To"
                                        className="mt-0"
                                        component={RenderField}
                                    />
                                </div>
                            </div>
                        </div>

                        <Field
                            name="includes_like_builder"
                            placeholder="BUILDER"
                            className="mb-2"
                            options={builders.map((builder) => ({
                                value: builder,
                                label: builder
                            }))}
                            multi={true}
                            component={RenderReactSelect}
                            help={'You can select multiple values.'}
                        />

                        <div className="floating-label-wrapper my-0 form-group">
                            <Field
                                name="include_hull_type"
                                placeholder="HULL TYPE"
                                options={dropdowns['hull_type']}
                                multi={true}
                                component={RenderReactSelect}
                                clearable={true}
                                readOnly={this.props.readOnly}
                                className="mt-0 mb-2"
                            />
                        </div>

                        <div className="floating-label-wrapper my-0 form-group">
                            <Field
                                name="include_flag"
                                placeholder="Flag"
                                className="mt-0 mb-2"
                                options={dropdowns['flags']}
                                multi={true}
                                component={RenderReactSelect}
                                help={'You can select multiple values.'}
                            />
                        </div>

                        <div className="floating-label-wrapper my-0 form-group">
                            <Companies
                                form="yachts_filters"
                                single={true}
                                prefix="has_"
                                className="mb-2"
                                charterSearch="charter"
                            />
                        </div>

                        <div className="search-filter my-0">
                            <Field
                                name="like_captain_name"
                                type="text"
                                placeholder="Captain"
                                className="mt-0 mb-2"
                                component={RenderField}
                            />
                        </div>

                        <div className="search-filter my-0">
                            <Field
                                name="like_chef"
                                type="text"
                                placeholder="Chef"
                                className="mt-0 mb-0"
                                component={RenderField}
                            />
                        </div>

                        <div className="row mt-2">
                            <div className="col-12">
                                <Field
                                    name="locations_tree_filter"
                                    component={LocationTree}
                                    placeholder="LOCATION"
                                    mode="free"
                                    should_cascade={true}
                                    only_for_yachts={true}
                                />
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Stabilisers at anchor"
                                    name="equal_zero_speed_stabilizers"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Stabilisers underway"
                                    name="equal_stabilizers_underway"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Jacuzzi"
                                    name="equal_jacuzzi"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Helipad"
                                    name="equal_helipad"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Wheelchair Accessibility"
                                    name="equal_handicap"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Elevator Lift"
                                    name="equal_elevator"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Wifi"
                                    name="equal_wifi"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Gym / Exercise Equipment"
                                    name="equal_gym"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Spa"
                                    name="equal_spa"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Slide"
                                    name="equal_slide"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Main Deck Cabin"
                                    name="equal_main_deck_cabin"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Beach club"
                                    name="equal_beach_club"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Approved RYA water sports center"
                                    name="equal_approved_rya_water_sports_center"
                                />
                            </div>
                        </div>

                        <hr className="col-xl-12 col-md-12 my-2 filter-divider" />

                        <div className="row mt-2">
                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Kid friendly"
                                    name="equal_kid_friendly"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Pet friendly"
                                    name="equal_pet_friendly"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Award winning"
                                    name="equal_award_winning"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Rich toy collection"
                                    name="equal_rich_toy_collection"
                                />
                            </div>

                            <div className="col-6  mb-1 form-group">
                                <Field
                                    className="mt-0 mb-1"
                                    component={RenderCheckbox}
                                    label="Diving Facilities and Equipment"
                                    name="equal_diving_facilities"
                                />
                            </div>
                        </div>

                        <hr className="col-xl-12 col-md-12 my-2 filter-divider" />

                        <AdminFiltersActions
                            cancelForm={this.props.cancelForm}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
