import React from 'react';
import ReactSVG from 'react-svg';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import Header from '../../common/header/Header';
import SimpleTable from '../../common/components/SimpleTable';
import SimpleModal from '../../common/components/SimpleModal';
import moment from 'moment';

// SVG

import eye from '../../assets/svgs/open-eye.svg';
import copy from '../../assets/svgs/copy-to-Davinci-DB.svg';
import { normalizePrice } from '../../helpers/validate';

let ExternalYachtsTable = ({
    menu,
    history,
    onTableChange,
    data,
    table_headers,
    onModalOpen,
    modal_header,
    modal_body,
    modalOpen
}) => {
    return (
        <div className="content-inner-wrapper">
            <Header menu={menu} history={history} />

            <div className="content-inner">
                <Row>
                    <Col xs="12">
                        <SimpleTable
                            search={false}
                            tableChange={onTableChange}
                            noDataText="There are no results for this search."
                            paging={data.pagination}
                            colWidths={[3, 1, 2, 1, 2, 2, 1]}
                            headers={table_headers}
                            hideExtraFilter={true}
                            rowData={
                                data.yachts !== undefined &&
                                data.yachts.map((value, index) => {
                                    return [
                                        <div>
                                            {value.davinci_yacht_id != null ? (
                                                <NavLink
                                                    to={`yachts/view/sales/${value.davinci_yacht_id}`}
                                                    target="_blank"
                                                >
                                                    {value.name}
                                                </NavLink>
                                            ) : (
                                                `${value.name}`
                                            )}
                                        </div>,
                                        <div>
                                            <span>
                                                {value.year_built
                                                    ? value.year_built
                                                    : '--'}
                                            </span>
                                        </div>,
                                        <div>
                                            {value.builder
                                                ? value.builder
                                                : '--'}
                                        </div>,
                                        <div>
                                            {value.loa ? value.loa : '--'}
                                        </div>,
                                        <div>
                                            {value.asking_price === null
                                                ? '--'
                                                : `${normalizePrice(
                                                      value?.asking_price
                                                  )} ${value?.asking_price_currency?.toUpperCase()}`}
                                        </div>,
                                        <div
                                            className={
                                                value.ImportedAt != null
                                                    ? moment(value.ImportedAt) <
                                                      moment(value.updated_at)
                                                        ? 'dv_status_needs_update'
                                                        : 'dv_status_Imported'
                                                    : 'dv_status_not_Imported'
                                            }
                                        >
                                            {value.ImportedAt != null
                                                ? moment(value.ImportedAt) <
                                                  moment(value.updated_at)
                                                    ? 'Needs update'
                                                    : 'Imported'
                                                : 'Not imported'}
                                        </div>,

                                        <ul className="list-unstyled table-td-actions">
                                            <li className="text-fill-hover">
                                                <NavLink
                                                    to={`external-yacht-view/${value.id}/view`}
                                                    target="_blank"
                                                >
                                                    <ReactSVG
                                                        path={eye}
                                                        style={{
                                                            height: 24
                                                        }}
                                                    />
                                                </NavLink>
                                            </li>
                                            <li>
                                                <button
                                                    id={`btncp_${index}`}
                                                    className="btn btn-td-action"
                                                    onClick={() =>
                                                        // props.onImportExternalYacht(
                                                        //     props.data.yachts[
                                                        //         index
                                                        //     ]
                                                        // )
                                                        onModalOpen(
                                                            data.yachts[index]
                                                        )
                                                    }
                                                >
                                                    <ReactSVG
                                                        path={copy}
                                                        style={{
                                                            height: 24
                                                        }}
                                                    />
                                                </button>
                                            </li>
                                        </ul>
                                    ];
                                })
                            }
                        />
                    </Col>
                    <Col>
                        <SimpleModal
                            header={modal_header}
                            body={modal_body}
                            modalOpen={modalOpen}
                            backdrop={'static'}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ExternalYachtsTable;
