import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { Field } from 'redux-form';

import RenderRedactor from './RenderRedactor';
import RenderField from './RenderField';
import RenderReactSelect from './RenderReactSelect';
import RenderPhoneNumber from './RenderPhoneNumber';
import RenderRadio from './RenderRadio';
import DateFromToPicker from './datetimepicker/DateFromToPicker';

import add from '../../assets/svgs/add.svg';
import remove from '../../assets/svgs/remove.svg';

class RenderFieldArray extends Component {
    getFieldComponent(fieldType) {
        switch (fieldType) {
            case 'select':
                return RenderReactSelect;
            case 'radio':
                return RenderRadio;
            case 'mobile':
                return RenderPhoneNumber;
            case 'editor':
                return RenderRedactor;
            default:
                return RenderField;
        }
    }

    dateChange(date, customField, field) {
        this.props.changeFieldValue(
            field + '.' + customField.name,
            date.fromDate
        );
    }

    removeItem(index) {
        if (this.props.initFirst) {
            this.props.fields.remove(index);
            if (
                this.props.primary &&
                this.props.primary.value &&
                parseInt(this.props.primary.value) > index
            ) {
                this.props.changeFieldValue(
                    this.props.primary.name,
                    String(parseInt(this.props.primary.value) - 1)
                );
            }
        } else {
            this.props.fields.remove(index);
        }
    }

    checkDisabled(arrayField, value, key, index, currentField) {
        if (arrayField && arrayField[key] !== value) {
            if (arrayField[currentField]) {
                arrayField[currentField] = '';
            }
            return true;
        } else if (arrayField === undefined) {
            return true;
        }

        return false;
    }

    render() {
        const { fields } = this.props;

        return (
            <div
                className={
                    'multiple-wrap  ' +
                    (this.props.className !== undefined
                        ? this.props.className
                        : '') +
                    (fields.length <= 0 ? ' ' : '')
                }
            >
                {!this.props.readOnly && !this.props.initFirst && (
                    <div className={fields.length > 0 ? ' mb-1' : ''}>
                        <button
                            className="btn btn-blue"
                            type="button"
                            onClick={() => fields.push()}
                        >
                            <ReactSVG path={add} style={{ width: 12 }} />{' '}
                            {this.props.moreLabel
                                ? this.props.moreLabel
                                : 'Add more'}
                        </button>
                    </div>
                )}
                <ul className={'list-unstyled'}>
                    {fields.map((field, index) => (
                        <li
                            className={
                                'row align-items-center ' +
                                (this.props.initFirst
                                    ? 'mt-1 item-wrapper'
                                    : '')
                            }
                            key={index}
                        >
                            <div className="col">
                                {this.props.customFields ? (
                                    <div className="row">
                                        {this.props.customFields.map(
                                            (customField, indexF) => (
                                                <div
                                                    key={indexF}
                                                    className={
                                                        customField.className
                                                    }
                                                >
                                                    {customField.type ===
                                                    'date' ? (
                                                        <div>
                                                            <DateFromToPicker
                                                                labels={{
                                                                    from:
                                                                        customField.placeholder
                                                                }}
                                                                afterTodayValid={
                                                                    true
                                                                }
                                                                beforeTodayValid={
                                                                    true
                                                                }
                                                                initFromDate={
                                                                    this.props
                                                                        .initialValues &&
                                                                    this.props
                                                                        .initialValues[
                                                                        index
                                                                    ] &&
                                                                    this.props
                                                                        .initialValues[
                                                                        index
                                                                    ][
                                                                        customField
                                                                            .name
                                                                    ]
                                                                        ? this
                                                                              .props
                                                                              .initialValues[
                                                                              index
                                                                          ][
                                                                              customField
                                                                                  .name
                                                                          ]
                                                                        : ''
                                                                }
                                                                onChangeFunction={(
                                                                    date
                                                                ) =>
                                                                    this.dateChange(
                                                                        date,
                                                                        customField,
                                                                        field
                                                                    )
                                                                }
                                                                single={true}
                                                                disabled={
                                                                    this.props
                                                                        .readOnly
                                                                }
                                                            />

                                                            <Field
                                                                name={`${field}.${customField.name}`}
                                                                type="hidden"
                                                                component="input"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <Field
                                                            name={
                                                                customField.type ===
                                                                'radio'
                                                                    ? `${customField.name}`
                                                                    : `${field}.${customField.name}`
                                                            }
                                                            type={
                                                                customField.type
                                                            }
                                                            placeholder={
                                                                customField.placeholder
                                                            }
                                                            readOnly={
                                                                this.props
                                                                    .readOnly
                                                            }
                                                            parse={
                                                                customField.type ===
                                                                'number'
                                                                    ? (value) =>
                                                                          value
                                                                              ? Number(
                                                                                    value
                                                                                )
                                                                              : ''
                                                                    : null
                                                            }
                                                            validate={
                                                                customField.validators !==
                                                                undefined
                                                                    ? customField.validators
                                                                    : null
                                                            }
                                                            normalize={
                                                                customField.normalize !==
                                                                undefined
                                                                    ? customField.normalize
                                                                    : null
                                                            }
                                                            className={
                                                                ((index ===
                                                                    fields.length -
                                                                        1 &&
                                                                    !this.props
                                                                        .initFirst) ||
                                                                this.props
                                                                    .initFirst
                                                                    ? 'mb-2'
                                                                    : '') +
                                                                (' ' +
                                                                    customField.classNameInner)
                                                            }
                                                            component={this.getFieldComponent(
                                                                customField.type
                                                            )}
                                                            options={
                                                                customField.type ===
                                                                'select'
                                                                    ? customField.options
                                                                    : null
                                                            }
                                                            label={
                                                                customField.type ===
                                                                    'select' ||
                                                                customField.type ===
                                                                    'radio'
                                                                    ? customField.label
                                                                    : null
                                                            }
                                                            value={
                                                                customField.type ===
                                                                'radio'
                                                                    ? `${index}`
                                                                    : null
                                                            }
                                                            group={
                                                                customField.type ===
                                                                'radio'
                                                                    ? customField.groupName
                                                                    : null
                                                            }
                                                            disabled={
                                                                !this.props
                                                                    .readOnly
                                                                    ? customField.readOnlyField &&
                                                                      customField.isNotDisabledFor
                                                                        ? this.checkDisabled(
                                                                              fields.get(
                                                                                  index
                                                                              ),
                                                                              customField.isNotDisabledFor,
                                                                              customField.readOnlyField,
                                                                              index,
                                                                              customField.name
                                                                          )
                                                                        : this
                                                                              .props
                                                                              .readOnly
                                                                    : this.props
                                                                          .readOnly
                                                            }
                                                            onChange={
                                                                customField.type ===
                                                                    'text' &&
                                                                customField.searchChange
                                                                    ? (event) =>
                                                                          this.props.searchChange(
                                                                              event,
                                                                              customField.searchType
                                                                                  ? customField.searchType
                                                                                  : null
                                                                          )
                                                                    : null
                                                            }
                                                            dropdownList={
                                                                this.props
                                                                    .dropdownList
                                                            }
                                                            onClickList={
                                                                this.props
                                                                    .onClickList
                                                            }
                                                            openSearch={
                                                                this.props
                                                                    .openSearch
                                                            }
                                                            changeFieldValue={
                                                                this.props
                                                                    .changeFieldValue
                                                            }
                                                            multi={
                                                                customField.multipleDropdown
                                                                    ? customField.multipleDropdown
                                                                    : null
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            )
                                        )}
                                    </div>
                                ) : (
                                    <Field
                                        name={`${field}`}
                                        type={this.props.type}
                                        placeholder={
                                            this.props.placeholder +
                                            ' #' +
                                            (index + 1)
                                        }
                                        component={RenderField}
                                        validate={
                                            this.props.validators !== undefined
                                                ? this.props.validators
                                                : null
                                        }
                                        readOnly={this.props.readOnly}
                                        className={
                                            index === fields.length - 1
                                                ? 'mb-1'
                                                : ''
                                        }
                                    />
                                )}
                            </div>
                            <div className="col-auto">
                                {((!this.props.readOnly &&
                                    !this.props.initFirst) ||
                                    (fields.length > 1 &&
                                        this.props.initFirst &&
                                        !this.props.readOnly &&
                                        !this.props.primary) ||
                                    (fields.length > 1 &&
                                        this.props.initFirst &&
                                        this.props.primary &&
                                        this.props.primary.value &&
                                        parseInt(this.props.primary.value) !==
                                            index &&
                                        !this.props.readOnly)) && (
                                    <button
                                        className="btn btn-empty btn-remove"
                                        type="button"
                                        onClick={() => this.removeItem(index)}
                                    >
                                        <ReactSVG
                                            path={remove}
                                            style={{ width: 16 }}
                                        />
                                    </button>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
                {!this.props.readOnly && this.props.initFirst && (
                    <div
                        className={
                            (this.props.classNameAddMore
                                ? this.props.classNameAddMore
                                : '') + (fields.length > 0 ? ' mb-1 mt-0' : '')
                        }
                    >
                        <button
                            className={'btn btn-blue reverse'}
                            type="button"
                            onClick={() => fields.push()}
                        >
                            <ReactSVG path={add} style={{ width: 12 }} />{' '}
                            {this.props.moreLabel
                                ? this.props.moreLabel
                                : 'Add more'}
                        </button>
                    </div>
                )}
            </div>
        );
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextProps.fields.length === 0 && this.props.atLeastOne) {
            nextProps.fields.push();
        }
    }

    componentDidMount() {
        if (this.props.initFirst && this.props.fields.length < 1) {
            this.props.fields.push();
        }
    }
}

export default RenderFieldArray;
