import React from 'react';
import { connect } from 'react-redux';

import ManageDestinationPresenter from './ManageDestinationPresenter';

// Redux
import { fetchDropdownLocations } from '../locations/redux/_actions';
import { fetchDestination, fetchUpdateDestination } from './redux/_actions';

class EditDestinationContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null,
            loading: false
        };
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        this.setState({ id: id, loading: true });

        this.props.fetchDropdownLocations({ format: 'dropdown' });

        this.props.fetchDestination(id).then(res => {
            this.setState({ loading: false });
            console.log(res);
        });
    }

    onSubmit(form_props) {
        this.props.fetchUpdateDestination(form_props).then(response => {
            if (response) {
                this.props.history.push('/itineraries');
            }
        });
    }

    render() {
        return (
            <ManageDestinationPresenter
                match={this.props.match}
                history={this.props.history}
                dropdown_locations={this.props.dropdown_locations}
                loading={this.state.loading}
                onSubmit={this.onSubmit.bind(this)}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        dropdown_locations: state.locations.dropdown_locations
    };
};

const mapDispatchToProps = (dispatch, own_props) => {
    return {
        fetchDropdownLocations: params =>
            dispatch(fetchDropdownLocations(params)),
        fetchDestination: (id, params) =>
            dispatch(fetchDestination(id, params)),
        fetchUpdateDestination: params =>
            dispatch(fetchUpdateDestination(params))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditDestinationContainer);
