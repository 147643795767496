import React, { Component } from 'react';

class LabelSeperator extends Component {
    render() {
        return (
            <div
                className={
                    'label-seperator' +
                    (this.props.className !== undefined
                        ? this.props.className
                        : '')
                }
            >
                <span className="seperator-title">{this.props.label}</span>
            </div>
        );
    }
}

export default LabelSeperator;
