export const FETCH_CREATE_OFFER = 'fetch_create_offer',
    FETCH_CREATE_OFFER_SUCCESS = 'fetch_create_offer_success',
    FETCH_CREATE_OFFER_FAIL = 'fetch_create_offer_fail',
    FETCH_OFFERS_HISTORY = 'fetch_offers_history',
    FETCH_OFFERS_HISTORY_SUCCESS = 'fetch_offers_history_success',
    FETCH_OFFERS_HISTORY_FAIL = 'fetch_offers_history_fail',
    FETCH_CUSTOMER_OFFER = 'fetch_customer_offer',
    FETCH_CUSTOMER_OFFER_SUCCESS = 'fetch_customer_offer_success',
    FETCH_CUSTOMER_OFFER_FAIL = 'fetch_customer_offer_fail',
    INIT_OFFER = 'init_offer',
    FETCH_DELETE_CUSTOMER_OFFER = 'fetch_delete_customer_offer',
    FETCH_DELETE_CUSTOMER_OFFER_SUCCESS = 'fetch_delete_customer_offer_success',
    FETCH_DELETE_CUSTOMER_OFFER_FAIL = 'fetch_delete_customer_offer_fail',
    FETCH_SEND_CUSTOMER_OFFER = 'fetch_send_customer_offer',
    FETCH_SEND_CUSTOMER_OFFER_SUCCESS = 'fetch_send_customer_offer_success',
    FETCH_SEND_CUSTOMER_OFFER_FAIL = 'fetch_send_customer_offer_fail';
