import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, FieldArray } from 'redux-form';

import { Button } from 'reactstrap';

import YachtMenu from '../../pages/yachts/components/YachtMenu';

import { fetchManageMedia, fetchGetYachtMedia } from './_actions';

import _without from 'lodash/without';
import _map from 'lodash/map';

import PhotoGallery from '../../common/components/PhotoGallery';
import RenderVideoUrl from './RenderVideoUrl';

const form = reduxForm({
    form: 'video_yacht',
    enableReinitialize: true
});

class VideoYacht extends Component {
    constructor(props) {
        super(props);

        let view = 'view';

        if (props.location.pathname.indexOf('/yachts/edit/') !== -1) {
            view = 'edit';
        }

        this.state = {
            loaded: false,
            view: view
        };
    }

    handleFormSubmit(formProps) {
        let params = Object.assign({}, formProps);
        let newArray = _map(params.urls, function (value) {
            if (value && value !== undefined && value.url !== '')
                return { url: value.url };
        });
        params.urls = _without(newArray, undefined);

        params.type = 'video';
        params.id = this.props.match.params.id;

        this.props.fetchManageMedia(params);
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            let params = { type: 'video', id: this.props.match.params.id };

            this.props.fetchGetYachtMedia(params);
        }
        if (this.props?.initialValues) {
            this.props.initialize(this.props.initialValues);
        }
    }

    render() {
        const { handleSubmit, invalid, submitting } = this.props;

        return (
            <div className="content-inner-wrapper">
                <YachtMenu
                    user_role={this.props.user_role}
                    match={this.props.match}
                    history={this.props.history}
                />
                <div className="content-inner">
                    <div className="row">
                        <div
                            className={
                                this.state.view === 'edit' ? 'col-4' : 'col-9'
                            }
                        >
                            {this.state.view !== 'edit' &&
                                this.props.videos !== undefined &&
                                this.props.videos.length > 0 && (
                                    <PhotoGallery
                                        galleryPhotos={this.props.videos}
                                        closeButton={false}
                                        label="VIDEOS"
                                    />
                                )}

                            {this.state.view === 'edit' && (
                                <form
                                    className="list-form"
                                    onSubmit={handleSubmit(
                                        this.handleFormSubmit.bind(this)
                                    )}
                                >
                                    <div className="brokers-wrap">
                                        <FieldArray
                                            name="urls"
                                            readOnly={this.props.readOnly}
                                            component={RenderVideoUrl}
                                        />
                                    </div>

                                    <div className="text-right">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={invalid || submitting}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    let initialValues = {};

    let videos = [];

    if (
        state.yachts.yacht.initYachtMedia &&
        state.yachts.yacht.initYachtMedia.video &&
        state.yachts.yacht.initYachtMedia.video.length > 0
    ) {
        initialValues.urls = state.yachts.yacht.initYachtMedia.video;
        videos = state.yachts.yacht.initYachtMedia.video;
    } else {
        initialValues.urls = [{ url: '' }];
    }

    return {
        user_role: state.auth.user_role,
        initialValues: initialValues,
        videos: videos
    };
}

export default connect(mapStateToProps, {
    fetchManageMedia,
    fetchGetYachtMedia
})(form(VideoYacht));
