import RenderTextarea from 'common/components/RenderTextarea';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    change,
    reduxForm,
    Field,
    FieldArray,
    formValueSelector
} from 'redux-form';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';

import Loader from 'common/components/Loader';
import YachtMenu from '../components/YachtMenu';
import PhotoGallery from 'common/components/PhotoGallery';
import Collapsable from 'common/components/Collapsable';

// Fields
import RenderField from 'common/components/RenderField';
import RenderCheckbox from 'common/components/RenderCheckbox';
import RenderRedactor from 'common/components/RenderRedactor';
import UploadField from 'common/components/UploadField';
import RenderMoreInfoFields from '../components/RenderMoreInfoFields';
import RenderKeyFeaturesFields from '../components/RenderKeyFeaturesFields';
import RenderHighlightsFields from '../components/RenderHighlightsFields';
import RenderToysFields from '../components/RenderToysFields';
import RenderToysFieldsMultiple from '../components/RenderToysFieldsMultiple';
import RenderVirtualTourUrl from 'common/components/RenderVirtualTourUrl';

// Helpers
import { required, slugValidation, isBoolean } from 'helpers/validate';
import {
    YACHT_TYPE_CHARTER,
    YACHT_TYPE_SALES
} from 'infrastructure/helpers/globals';
import { YACHT_PREVIEW_URL, YACHT_PUBLIC_URL } from 'helpers/variables';
import SelectPhotosFromGallery from 'common/components/SelectPhotosFromGallery';
import _uniqBy from 'lodash/uniqBy';
import { fetchYachtExternalProviders } from 'yachts/search/_actions';

let ManagePresentationPresenter = (props) => {
    const [yachtExternalProvider, setYachtExternalProvider] = useState();

    useEffect(() => {
        props
            .fetchYachtExternalProviders({ id: props.match.params.id })
            .then((data) => {
                setYachtExternalProvider(data);
            });
    }, []);

    const hubspotFeaturedImage =
        props.viewType === YACHT_TYPE_CHARTER
            ? props.charterHubspotFeaturedImage &&
              props.charterHubspotFeaturedImage.length > 0
                ? props.charterHubspotFeaturedImage[0].url
                : null
            : props.hubspotFeaturedImage &&
              props.hubspotFeaturedImage.length > 0
            ? props.hubspotFeaturedImage[0].url
            : null;

    let preview_link;
    if (props[`${props.viewType}_public`] === 1) {
        preview_link = `${YACHT_PUBLIC_URL}${
            props.viewType === YACHT_TYPE_CHARTER ? 'charter' : 'yachts'
        }/${props.slug}`;
    } else {
        preview_link = `${YACHT_PREVIEW_URL}${
            props.viewType === YACHT_TYPE_CHARTER ? 'charter' : 'yachts'
        }/${props.slug}?preview=1`;
    }

    return (
        <div className="content-inner-wrapper">
            <YachtMenu
                user_role={props.user_role}
                match={props.match}
                history={props.history}
            />

            <div className="content-inner">
                {props.loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <form
                        onSubmit={props.handleSubmit((formProps) =>
                            props.onSubmit({
                                ...formProps,
                                hasChanges: props.dirty
                            })
                        )}
                    >
                        <Row>
                            <Col xs="12" md="6">
                                {props.renderLocaleSelection()}
                            </Col>

                            <Col xs="12" md="6" className="d-flex">
                                {!props.read_only && (
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Save
                                    </button>
                                )}

                                {props.slug && (
                                    <a
                                        className="btn btn-blue ml-1"
                                        style={{ maxWidth: '120px' }}
                                        href={preview_link}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        Preview
                                    </a>
                                )}
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12" xl="9">
                                <Row>
                                    {props.viewType === YACHT_TYPE_CHARTER ? (
                                        <>
                                            <Col xs="3" xl="3">
                                                <Field
                                                    name="new_listing_charter"
                                                    normalize={isBoolean}
                                                    type="checkbox"
                                                    label="NEW CHARTER LISTING"
                                                    component={RenderCheckbox}
                                                    disabled={props.read_only}
                                                    className="mb-1"
                                                />
                                            </Col>

                                            <Col xs="3" xl="3">
                                                <Field
                                                    name="special_offer"
                                                    normalize={isBoolean}
                                                    type="checkbox"
                                                    label="SPECIAL OFFER"
                                                    component={RenderCheckbox}
                                                    disabled={props.read_only}
                                                    className="mb-1"
                                                />
                                            </Col>
                                        </>
                                    ) : (
                                        <>
                                            <Col xs="3" xl="3">
                                                <Field
                                                    name="new_listing_sales"
                                                    normalize={isBoolean}
                                                    type="checkbox"
                                                    label="NEW SALES LISTING"
                                                    component={RenderCheckbox}
                                                    disabled={props.read_only}
                                                    className="mb-1"
                                                />
                                            </Col>
                                            <Col xs="3" xl="3">
                                                <Field
                                                    name="price_reduction"
                                                    normalize={isBoolean}
                                                    type="checkbox"
                                                    label="PRICE REDUCTION"
                                                    component={RenderCheckbox}
                                                    disabled={props.read_only}
                                                    className="mb-1"
                                                />
                                            </Col>{' '}
                                        </>
                                    )}
                                </Row>
                            </Col>

                            <Col xs="12" xl="9">
                                <Collapsable header="Header Info">
                                    <Row className="my-2">
                                        <Col xs="12">
                                            <Field
                                                name={
                                                    props.viewType ===
                                                    YACHT_TYPE_CHARTER
                                                        ? 'presentation.charterHeaderVideos'
                                                        : 'presentation.headerVideos'
                                                }
                                                placeholder="HEADER VIDEO URL"
                                                component={RenderField}
                                                className="mt-2"
                                                readOnly={
                                                    props.locale !== 'en'
                                                        ? true
                                                        : props.read_only
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Collapsable>

                                <Collapsable header="Static Fields">
                                    <Row className="my-2">
                                        <Col xs="6">
                                            {props.is_archived &&
                                            props.is_archived === 1 ? (
                                                <div className="text-danger">
                                                    Remove yacht from archived
                                                    to enable publishing.
                                                </div>
                                            ) : null}
                                            <Field
                                                name={`presentation.translations.${props.viewType}_public`}
                                                label="IS PUBLIC?"
                                                component={RenderCheckbox}
                                                className="mt-2"
                                                value={
                                                    props[
                                                        `${props.viewType}_public`
                                                    ] === 1
                                                }
                                                disabled={
                                                    props.read_only ||
                                                    props.is_archived === 1
                                                }
                                            />

                                            {yachtExternalProvider?.availableForPublishing ===
                                            1 ? (
                                                <span>
                                                    YC: Available to 3rd Party
                                                    Sites
                                                </span>
                                            ) : null}
                                        </Col>
                                        <Col xs="6">
                                            <Field
                                                name={
                                                    props.viewType ===
                                                    YACHT_TYPE_CHARTER
                                                        ? 'presentation.is_poa_charter'
                                                        : 'presentation.is_poa'
                                                }
                                                label="PRICE ON APPLICATION?"
                                                component={RenderCheckbox}
                                                className="mt-2"
                                                value={
                                                    props.viewType ===
                                                    YACHT_TYPE_CHARTER
                                                        ? props.is_poa_charter ===
                                                          1
                                                        : props.is_poa === 1
                                                }
                                                onChange={(e) => {
                                                    const field =
                                                        props.viewType ===
                                                        YACHT_TYPE_CHARTER
                                                            ? 'presentation.is_poa_charter'
                                                            : 'presentation.is_poa';

                                                    props.changeFieldValue(
                                                        field,
                                                        e.target.checked
                                                    );
                                                }}
                                                disabled={
                                                    props.locale !== 'en'
                                                        ? true
                                                        : props.read_only
                                                }
                                            />
                                        </Col>

                                        <Col xs="12" md="6" lg="4">
                                            <Field
                                                name="presentation.slug"
                                                placeholder="SLUG"
                                                validate={[slugValidation]}
                                                component={RenderField}
                                                className="mt-2"
                                                readOnly={
                                                    props.locale !== 'en'
                                                        ? true
                                                        : props.read_only
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Collapsable>

                                <Collapsable header="SEO Fields">
                                    <Row className="my-2">
                                        <Col xs="12">
                                            <Field
                                                name={
                                                    props.viewType ===
                                                    YACHT_TYPE_CHARTER
                                                        ? 'presentation.translations.charter_meta_title'
                                                        : 'presentation.translations.meta_title'
                                                }
                                                type="text"
                                                placeholder="Meta Title"
                                                component={RenderField}
                                                className="mt-2"
                                                readOnly={props.read_only}
                                                maxLength="150"
                                                extraInfo="Characters limit are 150"
                                            />
                                        </Col>

                                        <Col xs="12">
                                            <label className="upload-label text-uppercase mt-1">
                                                Meta Description
                                            </label>

                                            <Field
                                                name={
                                                    props.viewType ===
                                                    YACHT_TYPE_CHARTER
                                                        ? 'presentation.translations.charter_meta_description'
                                                        : 'presentation.translations.meta_description'
                                                }
                                                type="text"
                                                component={RenderTextarea}
                                                className="mt-1"
                                                readOnly={props.read_only}
                                                disabled={props.read_only}
                                                maxLength="500"
                                                extraInfo="Characters limit are 500"
                                            />
                                        </Col>
                                    </Row>
                                </Collapsable>

                                {props.viewType === YACHT_TYPE_SALES && (
                                    <Collapsable header="Basic Info">
                                        <Row className="my-2">
                                            <Col
                                                xs="12"
                                                md="6"
                                                lg="4"
                                                className="d-flex align-center"
                                            >
                                                <Field
                                                    name="presentation.translations.selling_announcement_url"
                                                    placeholder="SALE ANNOUNCEMENT URL"
                                                    component={RenderField}
                                                    className="mt-0 w-100"
                                                    readOnly={props.read_only}
                                                />

                                                {!props.read_only && (
                                                    <div>
                                                        <div
                                                            className="btn btn-indication"
                                                            id="info-button"
                                                        >
                                                            i
                                                        </div>
                                                        <UncontrolledTooltip
                                                            placement="right"
                                                            target="info-button"
                                                        >
                                                            Copy and paste here
                                                            the link of the
                                                            Announcement posted
                                                            in the front
                                                            website, e.g:
                                                            https://iyc.com/announcements/echelon-sold-by-iyc/
                                                        </UncontrolledTooltip>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </Collapsable>
                                )}

                                <Collapsable header="Layout Fields">
                                    <Row className="my-2">
                                        <Col xs="11">
                                            <Field
                                                name={
                                                    props.viewType ===
                                                    YACHT_TYPE_CHARTER
                                                        ? 'presentation.translations.charter_tagline_text'
                                                        : 'presentation.translations.tagline_text'
                                                }
                                                type="text"
                                                placeholder="TAGLINE"
                                                component={RenderField}
                                                className="mt-2"
                                                readOnly={props.read_only}
                                                maxLength="150"
                                                extraInfo="Characters limit are 150"
                                            />
                                        </Col>
                                        <Col xs="1" className="mt-2">
                                            {!props.read_only && (
                                                <div>
                                                    <div
                                                        className="btn btn-indication"
                                                        id="tagline-info"
                                                    >
                                                        i
                                                    </div>
                                                    <UncontrolledTooltip
                                                        placement="right"
                                                        target="tagline-info"
                                                    >
                                                        A few words that appear
                                                        next to the Intro text,
                                                        at the top of the page.
                                                    </UncontrolledTooltip>
                                                </div>
                                            )}
                                        </Col>
                                        <Col xs="11">
                                            <label className="upload-label text-uppercase mt-2">
                                                INTRO TEXT
                                            </label>

                                            <RenderRedactor
                                                hiddenTitle={true}
                                                name={
                                                    props.viewType ===
                                                    YACHT_TYPE_CHARTER
                                                        ? 'presentation.translations.charter_description'
                                                        : 'presentation.translations.description'
                                                }
                                                readOnly={props.read_only}
                                                disabled={props.read_only}
                                                id="description-redactor"
                                                onChange={(text) => {
                                                    const field =
                                                        props.viewType ===
                                                        YACHT_TYPE_CHARTER
                                                            ? 'presentation.translations.charter_description'
                                                            : 'presentation.translations.description';

                                                    props.changeFieldValue(
                                                        field,
                                                        text
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col xs="1" className="mt-2">
                                            {!props.read_only && (
                                                <div>
                                                    <div
                                                        className="btn btn-indication"
                                                        id="intro-text-info"
                                                    >
                                                        i
                                                    </div>
                                                    <UncontrolledTooltip
                                                        placement="right"
                                                        target="intro-text-info"
                                                    >
                                                        A small, introductory
                                                        description of the
                                                        yacht.
                                                    </UncontrolledTooltip>
                                                </div>
                                            )}
                                        </Col>

                                        {props.viewType ===
                                            YACHT_TYPE_CHARTER && (
                                            <Col xs="12">
                                                <label className="upload-label text-uppercase mt-2">
                                                    Charter Info
                                                </label>

                                                <RenderRedactor
                                                    hiddenTitle={true}
                                                    name="presentation.translations.charter_info"
                                                    readOnly={props.read_only}
                                                    disabled={props.read_only}
                                                    id="charter-info-redactor"
                                                    onChange={(text) => {
                                                        props.changeFieldValue(
                                                            'presentation.translations.charter_info',
                                                            text
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        )}
                                        {props.viewType ===
                                            YACHT_TYPE_CHARTER && (
                                            <>
                                                <Col xs="12" className="mb-2">
                                                    <label className="upload-label text-uppercase mt-2">
                                                        Toys
                                                    </label>
                                                    <RenderToysFields
                                                        read_only={
                                                            props.read_only
                                                        }
                                                        changeFieldValue={
                                                            props.changeFieldValue
                                                        }
                                                    />
                                                </Col>

                                                <Col xs="12">
                                                    <FieldArray
                                                        name={
                                                            props.viewType ===
                                                            YACHT_TYPE_CHARTER
                                                                ? 'presentation.charterToys'
                                                                : 'presentation.salesToys'
                                                        }
                                                        component={
                                                            RenderToysFieldsMultiple
                                                        }
                                                        locale={props.locale}
                                                        // viewType={
                                                        //     props.viewType
                                                        // }
                                                        // yachtfolio_id={
                                                        //     props.yachtfolio_id
                                                        // }
                                                        read_only={
                                                            // props.yachtfolio_id &&
                                                            // props.locale ===
                                                            //     'en' &&
                                                            // props.viewType ===
                                                            // 'charter'
                                                            //     ? true
                                                            props.read_only
                                                        }
                                                        changeFieldValue={
                                                            props.changeFieldValue
                                                        }
                                                    />
                                                </Col>
                                            </>
                                        )}

                                        <Col xs="12">
                                            <Collapsable header="VIRTUAL TOUR URLS">
                                                <Row xs="12" className="my-2">
                                                    <Col xs="12">
                                                        <FieldArray
                                                            name="presentation.virtual_tour_url"
                                                            component={
                                                                RenderVirtualTourUrl
                                                            }
                                                            className="mt-2"
                                                            readOnly={
                                                                props.locale !==
                                                                'en'
                                                                    ? true
                                                                    : props.read_only
                                                            }
                                                            changeFieldValue={
                                                                props.changeFieldValue
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Collapsable>
                                        </Col>

                                        <Col xs="12">
                                            <label className="upload-label text-uppercase mt-2">
                                                VIRTUAL TOUR TEXT
                                            </label>

                                            <RenderRedactor
                                                hiddenTitle={true}
                                                name="presentation.translations.virtual_tour_text"
                                                readOnly={props.read_only}
                                                disabled={props.read_only}
                                                id="virtual-tour-redactor"
                                                onChange={(text) => {
                                                    props.changeFieldValue(
                                                        'presentation.translations.virtual_tour_text',
                                                        text
                                                    );
                                                }}
                                            />

                                            <div className="extra-options-info">
                                                If left empty we will show the
                                                default text: <br />
                                                Virtual tour <br />
                                                Step aboard :yachtname and take
                                                a 360° tour of her timeless
                                                interior
                                            </div>
                                        </Col>

                                        <Col xs="12" className="mt-2">
                                            {props.virtualTourImages &&
                                                props.virtualTourImages.length >
                                                    0 && (
                                                    <PhotoGallery
                                                        name="presentation.virtualTourImages"
                                                        show_alt_text_input={
                                                            true
                                                        }
                                                        read_only={
                                                            props.read_only
                                                        }
                                                        locale={
                                                            props.initialValues
                                                                .locale
                                                        }
                                                        galleryPhotos={props.virtualTourImages.map(
                                                            (photo) => ({
                                                                url: photo.url
                                                            })
                                                        )}
                                                        remove={(value) => {
                                                            props.changeFieldValue(
                                                                'presentation.virtualTourImages',
                                                                props.virtualTourImages.filter(
                                                                    (photo) =>
                                                                        photo.url !==
                                                                        value.url
                                                                )
                                                            );
                                                        }}
                                                        label="VIRTUAL TOUR IMAGES"
                                                    />
                                                )}

                                            {!props.read_only &&
                                                props.locale === 'en' && (
                                                    <UploadField
                                                        name="presentation.virtualTourImages"
                                                        desc="Upload images!"
                                                        label="UPLOAD VIRTUAL TOUR IMAGES"
                                                        fileLabelName="Click here to upload photo(s)"
                                                        disabled={
                                                            props.locale !==
                                                            'en'
                                                                ? true
                                                                : props.read_only
                                                        }
                                                        add={(urls, name) => {
                                                            urls = urls.map(
                                                                (url) => {
                                                                    return {
                                                                        url: url,
                                                                        alt_text:
                                                                            ''
                                                                    };
                                                                }
                                                            );

                                                            props.changeFieldValue(
                                                                'presentation.virtualTourImages',
                                                                [
                                                                    ...props.virtualTourImages,
                                                                    ...urls
                                                                ]
                                                            );
                                                        }}
                                                        type="multiple"
                                                        className="mb-2"
                                                    />
                                                )}
                                        </Col>

                                        <Col xs="12">
                                            <label className="upload-label text-uppercase mt-2">
                                                TEXT WITH BACKGROUND IMAGE
                                            </label>

                                            <RenderRedactor
                                                hiddenTitle={true}
                                                name="presentation.translations.promo_text"
                                                readOnly={props.read_only}
                                                disabled={props.read_only}
                                                id="promo-redactor"
                                                onChange={(text) => {
                                                    props.changeFieldValue(
                                                        'presentation.translations.promo_text',
                                                        text
                                                    );
                                                }}
                                            />
                                        </Col>

                                        <Col xs="12" className="mt-2">
                                            <UploadField
                                                name="presentation.promoImages"
                                                desc="Upload images!"
                                                label="UPLOAD TEXT WITH BACKGROUND IMAGE"
                                                fileLabelName="Click here to upload photo"
                                                initPhoto={
                                                    props.promoImages
                                                        ? props.promoImages.url
                                                        : null
                                                }
                                                show_alt_text_input={true}
                                                add={(urls, name) => {
                                                    props.changeFieldValue(
                                                        'presentation.promoImages',
                                                        {
                                                            url: urls,
                                                            alt_text: ''
                                                        }
                                                    );
                                                }}
                                                type="single"
                                                className="mb-2"
                                                disabled={
                                                    props.locale !== 'en'
                                                        ? true
                                                        : props.read_only
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Collapsable>

                                <Collapsable header="Media & Attachments">
                                    <Row className="my-2">
                                        {props.read_only ? null : (
                                            <Col xs="12" md="9">
                                                <SelectPhotosFromGallery
                                                    label={'Select from Photos'}
                                                    selectedImages={props.gallery.map(
                                                        (i) => {
                                                            return i.url;
                                                        }
                                                    )}
                                                    setSelectedImages={(
                                                        urls
                                                    ) => {
                                                        const field =
                                                            props.viewType ===
                                                            YACHT_TYPE_CHARTER
                                                                ? 'presentation.charterGallery'
                                                                : 'presentation.gallery';
                                                        props.changeFieldValue(
                                                            field,
                                                            _uniqBy(
                                                                [
                                                                    ...urls,
                                                                    ...props.gallery
                                                                ],
                                                                'url'
                                                            )
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        )}

                                        <Col xs="12" md="9" className="mt-2">
                                            {props.gallery && (
                                                <PhotoGallery
                                                    name={
                                                        props.viewType ===
                                                        YACHT_TYPE_CHARTER
                                                            ? 'presentation.charterGallery'
                                                            : 'presentation.gallery'
                                                    }
                                                    show_alt_text_input={true}
                                                    read_only={props.read_only}
                                                    locale={
                                                        props.initialValues
                                                            .locale
                                                    }
                                                    galleryPhotos={props.gallery.map(
                                                        (photo) => ({
                                                            url: photo.url
                                                        })
                                                    )}
                                                    remove={(value) => {
                                                        const field =
                                                            props.viewType ===
                                                            YACHT_TYPE_CHARTER
                                                                ? 'presentation.charterGallery'
                                                                : 'presentation.gallery';
                                                        props.changeFieldValue(
                                                            field,
                                                            props.gallery.filter(
                                                                (photo) =>
                                                                    photo.url !==
                                                                    value.url
                                                            )
                                                        );
                                                    }}
                                                    edit={true}
                                                    galleryType="grid"
                                                    reorder={(
                                                        startIndex,
                                                        endIndex
                                                    ) => {
                                                        const field =
                                                            props.viewType ===
                                                            YACHT_TYPE_CHARTER
                                                                ? 'presentation.charterGallery'
                                                                : 'presentation.gallery';
                                                        let reordered = [
                                                            ...props.gallery
                                                        ];
                                                        const temp = {
                                                            ...reordered[
                                                                startIndex
                                                            ]
                                                        };

                                                        reordered.splice(
                                                            startIndex,
                                                            1
                                                        );
                                                        reordered.splice(
                                                            endIndex,
                                                            0,
                                                            temp
                                                        );
                                                        props.changeFieldValue(
                                                            field,
                                                            reordered
                                                        );
                                                    }}
                                                    loading={false}
                                                    optionalLabel="(Optional)"
                                                    label="GALLERY"
                                                    isImageGallery={true}
                                                    closeButton={
                                                        !props.read_only
                                                    }
                                                />
                                            )}
                                            <hr className="my-2 filter-divider" />
                                        </Col>

                                        {props.read_only ? null : props
                                              .hubspotGallery.length < 8 ? (
                                            <Col xs="12" md="9">
                                                <SelectPhotosFromGallery
                                                    label={'Select from Photos'}
                                                    selectedImages={props.hubspotGallery.map(
                                                        (i) => {
                                                            return i.url;
                                                        }
                                                    )}
                                                    setSelectedImages={(
                                                        urls
                                                    ) => {
                                                        const field =
                                                            props.viewType ===
                                                            YACHT_TYPE_CHARTER
                                                                ? 'presentation.charterHubspotGallery'
                                                                : 'presentation.hubspotGallery';
                                                        props.changeFieldValue(
                                                            field,
                                                            _uniqBy(
                                                                [
                                                                    ...urls,
                                                                    ...props.hubspotGallery
                                                                ],
                                                                'url'
                                                            )
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        ) : null}

                                        <Col xs="12" md="9" className="mt-2">
                                            {props.hubspotGallery && (
                                                <PhotoGallery
                                                    name={
                                                        props.viewType ===
                                                        YACHT_TYPE_CHARTER
                                                            ? 'presentation.charterHubspotGallery'
                                                            : 'presentation.hubspotGallery'
                                                    }
                                                    show_alt_text_input={true}
                                                    read_only={props.read_only}
                                                    locale={
                                                        props.initialValues
                                                            .locale
                                                    }
                                                    galleryPhotos={props.hubspotGallery.map(
                                                        (photo) => ({
                                                            url: photo.url
                                                        })
                                                    )}
                                                    remove={(value) => {
                                                        const field =
                                                            props.viewType ===
                                                            YACHT_TYPE_CHARTER
                                                                ? 'presentation.charterHubspotGallery'
                                                                : 'presentation.hubspotGallery';
                                                        props.changeFieldValue(
                                                            field,
                                                            props.hubspotGallery.filter(
                                                                (photo) =>
                                                                    photo.url !==
                                                                    value.url
                                                            )
                                                        );
                                                    }}
                                                    edit={true}
                                                    galleryType="grid"
                                                    reorder={(
                                                        startIndex,
                                                        endIndex
                                                    ) => {
                                                        const field =
                                                            props.viewType ===
                                                            YACHT_TYPE_CHARTER
                                                                ? 'presentation.charterHubspotGallery'
                                                                : 'presentation.hubspotGallery';
                                                        let reordered = [
                                                            ...props.hubspotGallery
                                                        ];
                                                        const temp = {
                                                            ...reordered[
                                                                startIndex
                                                            ]
                                                        };

                                                        reordered.splice(
                                                            startIndex,
                                                            1
                                                        );
                                                        reordered.splice(
                                                            endIndex,
                                                            0,
                                                            temp
                                                        );
                                                        props.changeFieldValue(
                                                            field,
                                                            reordered
                                                        );
                                                    }}
                                                    loading={false}
                                                    optionalLabel="(Optional: You can select up to 8 images)"
                                                    label="HUBSPOT GALLERY"
                                                    isImageGallery={true}
                                                    closeButton={
                                                        !props.read_only
                                                    }
                                                />
                                            )}

                                            <hr className="my-2 filter-divider" />
                                        </Col>

                                        <Col xs="12" md="9" className="mt-2">
                                            <label className="upload-label text-uppercase mt-2">
                                                HUBSPOT FEATURED IMAGE
                                            </label>
                                            <UploadField
                                                name={
                                                    props.viewType ===
                                                    YACHT_TYPE_CHARTER
                                                        ? 'charterHubspotFeaturedImage'
                                                        : 'hubspotFeaturedImage'
                                                }
                                                fileLabelName="Click here to upload an image"
                                                className="mb-2 mt-1"
                                                initPhoto={hubspotFeaturedImage}
                                                disabled={
                                                    props.locale !== 'en'
                                                        ? true
                                                        : props.read_only
                                                }
                                                type="single"
                                                add={(urls, name) => {
                                                    const field =
                                                        props.viewType ===
                                                        YACHT_TYPE_CHARTER
                                                            ? 'presentation.charterHubspotFeaturedImage'
                                                            : 'presentation.hubspotFeaturedImage';
                                                    props.changeFieldValue(
                                                        field,
                                                        [{ url: urls }]
                                                    );
                                                }}
                                            />
                                            <hr className="my-2 filter-divider" />
                                        </Col>

                                        {props.read_only ? null : (
                                            <Col xs="12" md="9">
                                                <SelectPhotosFromGallery
                                                    label={
                                                        'Select from Blueprints Photos'
                                                    }
                                                    selectedImages={props.blueprintGallery.map(
                                                        (i) => {
                                                            return i.url;
                                                        }
                                                    )}
                                                    selectionType="blueprint"
                                                    setSelectedImages={(
                                                        urls
                                                    ) => {
                                                        const field =
                                                            props.viewType ===
                                                            YACHT_TYPE_CHARTER
                                                                ? 'presentation.charterGeneralArrangementImage'
                                                                : 'presentation.generalArrangementImage';
                                                        props.changeFieldValue(
                                                            field,
                                                            _uniqBy(
                                                                [
                                                                    ...urls,
                                                                    ...props.blueprintGallery
                                                                ],
                                                                'url'
                                                            )
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        )}
                                        <Col xs="12" md="9" className="mt-2">
                                            {props.blueprintGallery && (
                                                <PhotoGallery
                                                    name={
                                                        props.viewType ===
                                                        YACHT_TYPE_CHARTER
                                                            ? 'presentation.charterGeneralArrangementImage'
                                                            : 'presentation.generalArrangementImage'
                                                    }
                                                    show_alt_text_input={true}
                                                    read_only={props.read_only}
                                                    locale={
                                                        props.initialValues
                                                            .locale
                                                    }
                                                    galleryPhotos={props.blueprintGallery.map(
                                                        (photo) => ({
                                                            url: photo.url
                                                        })
                                                    )}
                                                    remove={(value) => {
                                                        const field =
                                                            props.viewType ===
                                                            YACHT_TYPE_CHARTER
                                                                ? 'presentation.charterGeneralArrangementImage'
                                                                : 'presentation.generalArrangementImage';
                                                        props.changeFieldValue(
                                                            field,
                                                            props.blueprintGallery.filter(
                                                                (photo) =>
                                                                    photo.url !==
                                                                    value.url
                                                            )
                                                        );
                                                    }}
                                                    edit={true}
                                                    galleryType="grid"
                                                    reorder={(
                                                        startIndex,
                                                        endIndex
                                                    ) => {
                                                        const field =
                                                            props.viewType ===
                                                            YACHT_TYPE_CHARTER
                                                                ? 'presentation.charterGeneralArrangementImage'
                                                                : 'presentation.generalArrangementImage';
                                                        let reordered = [
                                                            ...props.blueprintGallery
                                                        ];
                                                        const temp = {
                                                            ...reordered[
                                                                startIndex
                                                            ]
                                                        };

                                                        reordered.splice(
                                                            startIndex,
                                                            1
                                                        );
                                                        reordered.splice(
                                                            endIndex,
                                                            0,
                                                            temp
                                                        );
                                                        props.changeFieldValue(
                                                            field,
                                                            reordered
                                                        );
                                                    }}
                                                    loading={false}
                                                    optionalLabel="(Optional)"
                                                    label="GENERAL ARRANGEMENT GALLERY"
                                                    isImageGallery={true}
                                                    closeButton={
                                                        !props.read_only
                                                    }
                                                />
                                            )}
                                            <hr className="my-2 filter-divider" />
                                        </Col>

                                        <Col xs="12">
                                            <Field
                                                name={
                                                    props.viewType ===
                                                    YACHT_TYPE_CHARTER
                                                        ? 'presentation.translations.charterVideos'
                                                        : 'presentation.translations.videos'
                                                }
                                                placeholder="VIDEO URL"
                                                component={RenderField}
                                                className="mt-2"
                                                readOnly={props.read_only}
                                            />
                                        </Col>

                                        <Col xs="12">
                                            {props.brochures &&
                                                props.brochures.length > 0 && (
                                                    <PhotoGallery
                                                        galleryPhotos={
                                                            props.brochures
                                                        }
                                                        closeButton={
                                                            !props.read_only
                                                        }
                                                        label="BROCHURE"
                                                        remove={() => {
                                                            const field =
                                                                props.viewType ===
                                                                YACHT_TYPE_CHARTER
                                                                    ? 'presentation.translations.charterBrochures'
                                                                    : 'presentation.translations.brochures';

                                                            props.changeFieldValue(
                                                                field,
                                                                []
                                                            );
                                                        }}
                                                        edit={props.read_only}
                                                        galleryType="grid"
                                                    />
                                                )}

                                            {!props.read_only &&
                                                props.brochures &&
                                                props.brochures.length ===
                                                    0 && (
                                                    <div>
                                                        <label className="upload-label text-uppercase mt-2">
                                                            BROCHURE
                                                        </label>

                                                        <UploadField
                                                            name={
                                                                props.viewType ===
                                                                YACHT_TYPE_CHARTER
                                                                    ? 'presentation.translations.charterBrochures'
                                                                    : 'presentation.translations.brochures'
                                                            }
                                                            fileLabelName="Click here to upload a document"
                                                            accept=".pdf"
                                                            className="mb-2 mt-1"
                                                            type="single"
                                                            add={(
                                                                urls,
                                                                name
                                                            ) => {
                                                                const field =
                                                                    props.viewType ===
                                                                    YACHT_TYPE_CHARTER
                                                                        ? 'presentation.translations.charterBrochures'
                                                                        : 'presentation.translations.brochures';

                                                                props.changeFieldValue(
                                                                    field,
                                                                    [
                                                                        {
                                                                            url: urls
                                                                        }
                                                                    ]
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                        </Col>

                                        {props.viewType ===
                                            YACHT_TYPE_CHARTER && (
                                            <>
                                                <Col xs="3">
                                                    {props.charterSampleMenu &&
                                                        props.charterSampleMenu
                                                            .length > 0 && (
                                                            <PhotoGallery
                                                                galleryPhotos={
                                                                    props.charterSampleMenu
                                                                }
                                                                closeButton={
                                                                    !props.read_only
                                                                }
                                                                label="SAMPLE MENU"
                                                                remove={() => {
                                                                    const field =
                                                                        'presentation.translations.charterSampleMenu';
                                                                    props.changeFieldValue(
                                                                        field,
                                                                        []
                                                                    );
                                                                }}
                                                                edit={
                                                                    props.read_only
                                                                }
                                                                galleryType="grid"
                                                            />
                                                        )}

                                                    {!props.read_only &&
                                                        props.charterSampleMenu &&
                                                        props.charterSampleMenu
                                                            .length === 0 && (
                                                            <div>
                                                                <label className="upload-label text-uppercase mt-2">
                                                                    SAMPLE MENU
                                                                </label>

                                                                <UploadField
                                                                    name={
                                                                        'presentation.translations.charterSampleMenu'
                                                                    }
                                                                    fileLabelName="Click here to upload a document"
                                                                    accept=".pdf"
                                                                    className="mb-2 mt-1"
                                                                    type="single"
                                                                    add={(
                                                                        urls,
                                                                        name
                                                                    ) => {
                                                                        const field =
                                                                            'presentation.translations.charterSampleMenu';
                                                                        props.changeFieldValue(
                                                                            field,
                                                                            [
                                                                                {
                                                                                    url: urls
                                                                                }
                                                                            ]
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                </Col>

                                                <Col xs="9">
                                                    {!props.read_only && (
                                                        <div>
                                                            <div
                                                                className={
                                                                    props
                                                                        .charterSampleMenu
                                                                        ?.length >
                                                                    0
                                                                        ? 'btn btn-indication'
                                                                        : 'btn btn-indication mt-1'
                                                                }
                                                                id="info-button"
                                                            >
                                                                i
                                                            </div>
                                                            <UncontrolledTooltip
                                                                placement="right"
                                                                target="info-button"
                                                            >
                                                                This will only
                                                                be shown when
                                                                the yacht is
                                                                viewed as part
                                                                of a Charter
                                                                proposal.
                                                            </UncontrolledTooltip>
                                                        </div>
                                                    )}
                                                </Col>

                                                <Col xs="3">
                                                    {props.charterCrewProfiles &&
                                                        props
                                                            .charterCrewProfiles
                                                            .length > 0 && (
                                                            <PhotoGallery
                                                                galleryPhotos={
                                                                    props.charterCrewProfiles
                                                                }
                                                                closeButton={
                                                                    !props.read_only
                                                                }
                                                                label="CREW PROFILES"
                                                                remove={() => {
                                                                    const field =
                                                                        'presentation.translations.charterCrewProfiles';
                                                                    props.changeFieldValue(
                                                                        field,
                                                                        []
                                                                    );
                                                                }}
                                                                edit={
                                                                    props.read_only
                                                                }
                                                                galleryType="grid"
                                                            />
                                                        )}

                                                    {!props.read_only &&
                                                        props.charterCrewProfiles &&
                                                        props
                                                            .charterCrewProfiles
                                                            .length === 0 && (
                                                            <div>
                                                                <label className="upload-label text-uppercase mt-2">
                                                                    CREW
                                                                    PROFILES
                                                                </label>

                                                                <UploadField
                                                                    name={
                                                                        'presentation.translations.charterCrewProfiles'
                                                                    }
                                                                    fileLabelName="Click here to upload a document"
                                                                    accept=".pdf"
                                                                    className="mb-2 mt-1"
                                                                    type="single"
                                                                    add={(
                                                                        urls,
                                                                        name
                                                                    ) => {
                                                                        const field =
                                                                            'presentation.translations.charterCrewProfiles';
                                                                        props.changeFieldValue(
                                                                            field,
                                                                            [
                                                                                {
                                                                                    url: urls
                                                                                }
                                                                            ]
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                </Col>

                                                <Col xs="9">
                                                    {!props.read_only && (
                                                        <div>
                                                            <div
                                                                className={
                                                                    props
                                                                        .charterCrewProfiles
                                                                        ?.length >
                                                                    0
                                                                        ? 'btn btn-indication'
                                                                        : 'btn btn-indication mt-1'
                                                                }
                                                                id="info-button"
                                                            >
                                                                i
                                                            </div>
                                                            <UncontrolledTooltip
                                                                placement="right"
                                                                target="info-button"
                                                            >
                                                                This will only
                                                                be shown when
                                                                the yacht is
                                                                viewed as part
                                                                of a Charter
                                                                proposal.
                                                            </UncontrolledTooltip>
                                                        </div>
                                                    )}
                                                </Col>
                                            </>
                                        )}

                                        <Col xs="12">
                                            <label className="upload-label text-uppercase mt-2">
                                                ATTACHMENTS
                                            </label>

                                            {props.attachments &&
                                                props.attachments.length >
                                                    0 && (
                                                    <PhotoGallery
                                                        galleryPhotos={
                                                            props.attachments
                                                        }
                                                        closeButton={
                                                            !props.read_only
                                                        }
                                                        remove={(
                                                            value,
                                                            index
                                                        ) => {
                                                            const field =
                                                                props.viewType ===
                                                                YACHT_TYPE_CHARTER
                                                                    ? 'presentation.translations.charterAttachments'
                                                                    : 'presentation.translations.attachments';

                                                            let urls = props.attachments.filter(
                                                                (att, i) =>
                                                                    i !== index
                                                            );

                                                            props.changeFieldValue(
                                                                field,
                                                                urls
                                                            );
                                                        }}
                                                        edit={props.read_only}
                                                        galleryType="grid"
                                                    />
                                                )}

                                            {!props.read_only && (
                                                <UploadField
                                                    name={
                                                        props.viewType ===
                                                        YACHT_TYPE_CHARTER
                                                            ? 'presentation.translations.charterAttachments'
                                                            : 'presentation.translations.attachments'
                                                    }
                                                    fileLabelName="Click here to upload attachments"
                                                    accept=".pdf, .xls, .xlsx, .xlr, .ods, .doc, .docx, .odt, .pdf, .txt"
                                                    className="mb-2 mt-1"
                                                    type="multiple"
                                                    add={(urls, name) => {
                                                        const field =
                                                            props.viewType ===
                                                            YACHT_TYPE_CHARTER
                                                                ? 'presentation.translations.charterAttachments'
                                                                : 'presentation.translations.attachments';

                                                        urls = urls.map(
                                                            (url) => ({
                                                                url: url
                                                            })
                                                        );
                                                        props.changeFieldValue(
                                                            field,
                                                            [
                                                                ...props.attachments,
                                                                ...urls
                                                            ]
                                                        );
                                                    }}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Collapsable>

                                <Collapsable header="More Info Section">
                                    <Row className="my-2">
                                        <Col xs="12">
                                            <Field
                                                name={
                                                    props.viewType ===
                                                    YACHT_TYPE_CHARTER
                                                        ? 'presentation.charterMoreInfo'
                                                        : 'presentation.salesMoreInfo'
                                                }
                                                component={RenderMoreInfoFields}
                                                locale={props.locale}
                                                read_only={props.read_only}
                                                viewType={props.viewType}
                                                changeFieldValue={
                                                    props.changeFieldValue
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Collapsable>

                                <Collapsable header="Highlights">
                                    <Row className="my-2">
                                        <Col xs="12">
                                            <FieldArray
                                                name={
                                                    props.viewType ===
                                                    YACHT_TYPE_CHARTER
                                                        ? 'presentation.charterHighlights'
                                                        : 'presentation.highlights'
                                                }
                                                component={
                                                    RenderHighlightsFields
                                                }
                                                // locale={props.locale}
                                                // viewType={props.viewType}
                                                // yachtfolio_id={
                                                //     props.yachtfolio_id
                                                // }
                                                read_only={
                                                    // props.yachtfolio_id &&
                                                    // props.locale === 'en' &&
                                                    // props.viewType === 'charter'
                                                    //     ? true
                                                    props.read_only
                                                }
                                                changeFieldValue={
                                                    props.changeFieldValue
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Collapsable>

                                <Collapsable header="Key Features">
                                    <Row className="my-2">
                                        <Col xs="12">
                                            <FieldArray
                                                name={
                                                    props.viewType ===
                                                    YACHT_TYPE_CHARTER
                                                        ? 'presentation.charterKeyFeatures'
                                                        : 'presentation.keyFeatures'
                                                }
                                                component={
                                                    RenderKeyFeaturesFields
                                                }
                                                // locale={props.locale}
                                                // viewType={props.viewType}
                                                // yachtfolio_id={
                                                //     props.yachtfolio_id
                                                // }
                                                read_only={
                                                    // props.yachtfolio_id &&
                                                    // props.locale === 'en' &&
                                                    // props.viewType === 'charter'
                                                    //     ? true
                                                    props.read_only
                                                }
                                                changeFieldValue={
                                                    props.changeFieldValue
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Collapsable>
                            </Col>
                        </Row>

                        {!props.read_only && (
                            <Row className="my-2">
                                <Col xs="12" md="6">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Save
                                    </button>
                                </Col>
                            </Row>
                        )}
                    </form>
                )}
            </div>
        </div>
    );
};

ManagePresentationPresenter = reduxForm({
    form: 'manage_presentation_fields',
    enableReinitialize: true
})(ManagePresentationPresenter);

const selector = formValueSelector('manage_presentation_fields');

const mapStateToProps = (state, own_props) => {
    const presentation = state.yacht_presentation.presentation;
    const header_video =
        presentation.headerVideos && presentation.headerVideos[0]
            ? presentation.headerVideos[0]
            : '';
    const promo_images =
        presentation.promoImages && presentation.promoImages[0]
            ? presentation.promoImages[0]
            : '';

    const introTaglineField =
        own_props.viewType === YACHT_TYPE_CHARTER
            ? 'charter_tagline_text'
            : 'tagline_text';
    const descriptionField =
        own_props.viewType === YACHT_TYPE_CHARTER
            ? 'charter_description'
            : 'description';
    const metaTitleField =
        own_props.viewType === YACHT_TYPE_CHARTER
            ? 'charter_meta_title'
            : 'meta_title';
    const metaDescriptionField =
        own_props.viewType === YACHT_TYPE_CHARTER
            ? 'charter_meta_description'
            : 'meta_description';
    const brochureField =
        own_props.viewType === YACHT_TYPE_CHARTER
            ? 'charterBrochures'
            : 'brochures';
    const sampleMenuField = 'charterSampleMenu';
    const crewProfilesField = 'charterCrewProfiles';
    const attachmentsField =
        own_props.viewType === YACHT_TYPE_CHARTER
            ? 'charterAttachments'
            : 'attachments';
    const videoField =
        own_props.viewType === YACHT_TYPE_CHARTER ? 'charterVideos' : 'videos';
    const galleryField =
        own_props.viewType === YACHT_TYPE_CHARTER
            ? 'charterGallery'
            : 'gallery';
    const hubspotGalleryField =
        own_props.viewType === YACHT_TYPE_CHARTER
            ? 'charterHubspotGallery'
            : 'hubspotGallery';
    const bluprintGalleryField =
        own_props.viewType === YACHT_TYPE_CHARTER
            ? 'charterGeneralArrangementImage'
            : 'generalArrangementImage';
    const video =
        presentation.translations &&
        presentation.translations[videoField] &&
        presentation.translations[videoField][0]
            ? presentation.translations[videoField][0]
            : '';
    const listingLabels = presentation.listing_labels?.split(',');
    return {
        slug: selector(state, 'presentation.slug'),
        [`${own_props.viewType}_public`]: selector(
            state,
            `presentation.translations.${own_props.viewType}_public`
        ),
        is_poa: selector(state, 'presentation.is_poa'),
        is_poa_charter: selector(state, 'presentation.is_poa_charter'),
        gallery: selector(state, `presentation.${galleryField}`) || [],
        hubspotGallery:
            selector(state, `presentation.${hubspotGalleryField}`) || [],
        blueprintGallery:
            selector(state, `presentation.${bluprintGalleryField}`) || [],
        virtualTourImages:
            selector(state, 'presentation.virtualTourImages') || [],
        promoImages: selector(state, 'presentation.promoImages'),
        brochures: selector(
            state,
            `presentation.translations.${brochureField}`
        ),
        charterSampleMenu: selector(
            state,
            `presentation.translations.${sampleMenuField}`
        ),
        charterCrewProfiles: selector(
            state,
            `presentation.translations.${crewProfilesField}`
        ),
        attachments: selector(
            state,
            `presentation.translations.${attachmentsField}`
        ),
        generalArrangementImage: selector(
            state,
            `presentation.generalArrangementImage`
        ),
        charterGeneralArrangementImage: selector(
            state,
            `presentation.charterGeneralArrangementImage`
        ),
        hubspotFeaturedImage: selector(
            state,
            `presentation.hubspotFeaturedImage`
        ),
        charterHubspotFeaturedImage: selector(
            state,
            `presentation.charterHubspotFeaturedImage`
        ),

        yatco: state.yachts.yacht.initYacht.yatco,
        initialValues: {
            locale: own_props.locale,
            new_listing_charter: listingLabels?.includes('new_listing_charter')
                ? true
                : false,
            special_offer: listingLabels?.includes('special_offer')
                ? true
                : false,
            new_listing_sales: listingLabels?.includes('new_listing_sales')
                ? true
                : false,
            price_reduction: listingLabels?.includes('price_reduction')
                ? true
                : false,
            id: state.yacht_presentation.yacht_id,
            jetskis: presentation.toys ? presentation.toys.jetskis : '',
            seabob: presentation.toys ? presentation.toys.seabob : '',
            diving_equipment: presentation.toys
                ? presentation.toys.diving_equipment
                : '',
            snorkeling: presentation.toys ? presentation.toys.snorkeling : '',
            fishing_rod: presentation.toys ? presentation.toys.fishing_rod : '',
            paddle_board: presentation.toys
                ? presentation.toys.paddle_board
                : '',
            presentation: {
                ...presentation,

                is_poa: presentation.is_poa === 1,
                is_poa_charter: presentation.is_poa_charter === 1,

                headerVideos: header_video,
                promoImages: promo_images,
                generalArrangementImage: presentation.generalArrangementImage
                    ? presentation.generalArrangementImage
                    : [],
                charterGeneralArrangementImage: presentation.charterGeneralArrangementImage
                    ? presentation.charterGeneralArrangementImage
                    : [],
                hubspotFeaturedImage: presentation.hubspotFeaturedImage
                    ? presentation.hubspotFeaturedImage
                    : [],
                charterHubspotFeaturedImage: presentation.charterHubspotFeaturedImage
                    ? presentation.charterHubspotFeaturedImage
                    : [],
                keyFeatures: presentation.keyFeatures || [],
                charterKeyFeatures: presentation.charterKeyFeatures || [],
                highlights: presentation.highlights || [],
                charterHighlights: presentation.charterHighlights || [],
                salesToys: presentation.salesToys || [],
                charterToys: presentation.charterToys || [],
                translations: {
                    ...presentation.translations,
                    [`${own_props.viewType}_public`]: presentation.translations
                        ? own_props.viewType === YACHT_TYPE_CHARTER
                            ? presentation.translations.charter_public === 1
                            : presentation.translations.sales_public === 1
                        : false,
                    [introTaglineField]: presentation.translations
                        ? presentation.translations[introTaglineField]
                        : null,
                    [descriptionField]: presentation.translations
                        ? presentation.translations[descriptionField]
                        : null,
                    [metaTitleField]: presentation.translations
                        ? presentation.translations[metaTitleField]
                        : null,
                    [metaDescriptionField]: presentation.translations
                        ? presentation.translations[metaDescriptionField]
                        : null,
                    brochures:
                        presentation.translations &&
                        presentation.translations.brochures
                            ? presentation.translations.brochures.map(
                                  (brochure) => ({
                                      url: brochure
                                  })
                              )
                            : [],
                    charterBrochures:
                        presentation.translations &&
                        presentation.translations.charterBrochures
                            ? presentation.translations.charterBrochures.map(
                                  (brochure) => ({
                                      url: brochure
                                  })
                              )
                            : [],
                    charterSampleMenu:
                        presentation.translations &&
                        presentation.translations.charterSampleMenu
                            ? presentation.translations.charterSampleMenu.map(
                                  (menu) => ({
                                      url: menu
                                  })
                              )
                            : [],
                    charterCrewProfiles:
                        presentation.translations &&
                        presentation.translations.charterCrewProfiles
                            ? presentation.translations.charterCrewProfiles.map(
                                  (crewProfile) => ({
                                      url: crewProfile
                                  })
                              )
                            : [],
                    attachments:
                        presentation.translations &&
                        presentation.translations.attachments
                            ? presentation.translations.attachments.map(
                                  (attachment) => ({ url: attachment })
                              )
                            : [],
                    charterAttachments:
                        presentation.translations &&
                        presentation.translations.charterAttachments
                            ? presentation.translations.charterAttachments.map(
                                  (attachment) => ({ url: attachment })
                              )
                            : [],
                    [videoField]: video
                }
            }
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchYachtExternalProviders: (e) =>
            dispatch(fetchYachtExternalProviders(e)),
        //fetchPresentationToys: (e) => dispatch(fetchPresentationToys(e)),
        changeFieldValue: (field, value) =>
            dispatch(change('manage_presentation_fields', field, value))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManagePresentationPresenter);
