import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import { ModalBody, Button, Row, Col } from 'reactstrap';
import SearchYachtListDropdown from './SearchYachtListDropdown';
import Instructions from '../../../common/components/Instructions';

// Fields
import RenderRadio from '../../../common/components/RenderRadio';
import RenderField from '../../../common/components/RenderField';
import RenderRedactor from '../../../common/components/RenderRedactor';

// Redux
import {
    createYachtList,
    fetchYachtList,
    updateYachtList
} from '../redux/_actions';

// Helpers
import { required } from '../../../helpers/validate';
import { YACHT_TYPE_CHARTER } from '../../../infrastructure/helpers/globals';

class ManageYachtListModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            is_searching: false,
            instructions_mode: 'info',
            errorMessage: ''
        };
    }

    onTypeChange(event, value) {
        if (value === 'existing') {
            this.props.changeFieldValue('selection_type', 'dynamic');

            if (!this.props.initialValues.id) {
                this.setState({
                    is_searching: true
                });
            }
        } else {
            this.props.changeFieldValue('internal_title', '');
            this.props.changeFieldValue('notes', '');
            this.props.changeFieldValue('id', '');
            this.setState({
                is_searching: false,
                instructions_mode: 'info'
            });
        }
    }

    onSubmit(form_props) {
        let params = {
            internal_title: form_props.internal_title,
            type: form_props.selection_type,
            is_public: form_props.is_public,
            notes: form_props.notes
        };

        if (form_props.id) {
            params.id = form_props.id;
        }

        if (this.props.view_type === YACHT_TYPE_CHARTER) {
            params.chartering = 1;
        }

        if (params.type === 'dynamic') {
            params.dynamic_query = JSON.stringify(this.props.yachts.query);
            params.order_field = this.props.sorting.order_field;
            params.order_dir = this.props.sorting.order_dir;
        } else {
            params.yachts = this.props.yachts.selectedYachts.selection.map(
                (yacht) => yacht.id
            );
        }

        if (params.id) {
            this.props
                .updateYachtList(params.id, params)
                .then((response) => this.props.history.push('/yacht-lists'));
        } else {
            this.props.createYachtList(params).then((response) => {
                if (response.error) {
                    this.setState({ errorMessage: response.message });
                } else {
                    this.props.history.push('/yacht-lists');
                }
            });
        }
    }

    onInternalTitleChange(value) {
        this.setState({ errorMessage: '' });
        console.log('asdasd', value.target.defaultValue);
    }

    onSuggestionSelect(id) {
        this.setState({ is_searching: true });
        this.props.changeFieldValue('id', id);
        this.props
            .fetchYachtList(id)
            .then((data) => this.setState({ is_searching: false }));
    }

    render() {
        return (
            <form
                className="modal-wrapper"
                onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}
            >
                <ModalBody>
                    <Row className="px-2 py-1">
                        <Col xs="12" className="d-flex justify-content-between">
                            <Field
                                name="yacht_list_type"
                                label="New Yacht List"
                                type="radio"
                                value="new"
                                component={RenderRadio}
                                className="mb-2"
                                onChange={this.onTypeChange.bind(this)}
                            />
                            <Field
                                name="yacht_list_type"
                                label="Existing Yacht List"
                                type="radio"
                                value="existing"
                                component={RenderRadio}
                                className="mb-2"
                                onChange={this.onTypeChange.bind(this)}
                            />
                        </Col>

                        <Col
                            xs="12"
                            className="d-flex justify-content-between"
                            onClick={() => {
                                if (this.props.type_form_value === 'existing') {
                                    this.setState({
                                        instructions_mode: 'error'
                                    });
                                }
                            }}
                        >
                            <Field
                                name="selection_type"
                                label="By Filters"
                                type="radio"
                                value="dynamic"
                                component={RenderRadio}
                                className="mb-1"
                                disabled={
                                    this.props.type_form_value === 'existing'
                                }
                            />
                            <Field
                                name="selection_type"
                                label="By Selection"
                                type="radio"
                                value="static"
                                component={RenderRadio}
                                className="mb-1"
                                disabled={
                                    this.props.type_form_value === 'existing'
                                }
                            />
                        </Col>

                        {this.state.is_searching && (
                            <Col xs="12">
                                <SearchYachtListDropdown
                                    form="manage_yacht_list"
                                    onSuggestionSelect={this.onSuggestionSelect.bind(
                                        this
                                    )}
                                />
                            </Col>
                        )}

                        {!this.state.is_searching && (
                            <Col xs="12">
                                <Row>
                                    <Col xs="12">
                                        <Field
                                            name="internal_title"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Internal Title"
                                            validate={[required]}
                                            className="mb-0"
                                            onChange={this.onInternalTitleChange.bind(
                                                this
                                            )}
                                        />
                                        <div className="text--errormesage mt-1">
                                            {this.state.errorMessage
                                                ? this.state.errorMessage
                                                : ''}
                                        </div>
                                    </Col>

                                    <Col xs="12">
                                        <label className="upload-label text-uppercase mt-2">
                                            Notes
                                        </label>

                                        <RenderRedactor
                                            hiddenTitle={true}
                                            name="notes"
                                            id="notes"
                                            onChange={(text) =>
                                                this.props.changeFieldValue(
                                                    'notes',
                                                    text
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        )}

                        <Col
                            xs="12"
                            className="d-flex justify-content-between mt-2"
                        >
                            <Button color="success" type="submit">
                                Save
                            </Button>
                        </Col>

                        <Col xs="12">
                            <Instructions
                                mode={this.state.instructions_mode}
                                title="If you wish to update a <strong>By selection</strong> Yacht List, you can just: "
                                messages={[
                                    'Add the yachts to your Current Selection.',
                                    'Go to <strong>Yacht Lists</strong> and edit the Yacht List in question.',
                                    "You'll find the yachts from your Current Selection in the list. Check the ones you want and click <strong>Save</strong>."
                                ]}
                            />
                        </Col>
                    </Row>
                </ModalBody>
            </form>
        );
    }
}

ManageYachtListModal = reduxForm({
    form: 'manage_yacht_list',
    enableReinitialize: true
})(ManageYachtListModal);

const selector = formValueSelector('manage_yacht_list');

const mapStateToPros = (state, own_props) => {
    const yacht_list = state.yacht_lists.yacht_list;
    return {
        type_form_value: selector(state, 'yacht_list_type'),
        yachts: state.yachts.search,
        initialValues: {
            id: yacht_list.id,
            yacht_list_type: yacht_list.id ? 'existing' : own_props.type,
            selection_type: yacht_list.id
                ? yacht_list.type
                : own_props.selection_type,
            is_public: 1,
            internal_title: yacht_list.id ? yacht_list.internal_title : '',
            notes: yacht_list.id ? yacht_list.notes : ''
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change('manage_yacht_list', field, value)),
        createYachtList: (params) => dispatch(createYachtList(params)),
        fetchYachtList: (id) => dispatch(fetchYachtList(id)),
        updateYachtList: (id, params) => dispatch(updateYachtList(id, params))
    };
};

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(ManageYachtListModal);
