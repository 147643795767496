import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';

import { change as changeFieldValue, unregisterField } from 'redux-form';
import { Checkbox } from '../../common/components/simpleInputs';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import RenderField from '../../common/components/RenderField';
import SelectField from 'common/components/Forms/SelectField';

import CreatableMulti from '../../common/components/RenderReactMultiSelect';

import RenderCheckbox from '../../common/components/RenderCheckbox';

import { yachtStates } from '../../helpers/variables';
import { yachtCharterStates } from '../../helpers/variables';
import { fetchSavedSearches } from '../../saved_searches/_actions';

const form = reduxForm({
    form: 'yachts_filters',
    enableReinitialize: true
});

connect(
    (state) => ({
        value: get(state, 'form.yachts_filters.values.equal_status')
    }),
    { changeFieldValue, unregisterField }
)(
    class CustomField extends React.Component {
        componentWillUnmount() {
            this.props.unregisterField('yachts_filters', 'equal_status');
        }

        render() {
            const { props } = this;

            return (
                <Checkbox
                    value={props.value === 'for_sale'}
                    onChange={(event) => {
                        if (event.target.checked) {
                            props.changeFieldValue(
                                'yachts_filters',
                                'equal_status',
                                'for_sale'
                            );
                        } else {
                            props.changeFieldValue(
                                'yachts_filters',
                                'equal_status',
                                null
                            );
                        }
                    }}
                    label="For sale only"
                    {...props}
                />
            );
        }
    }
);

class BrokerTopFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            search: '',
            vesselStatuses: [
                yachtStates.find((s) => s.value === 'in_operation'),
                yachtStates.find((s) => s.value === 'in_build')
            ],
            previous_names: false,
            for_sale: true,
            saved_searches: [],
            selectedSearch: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.changed = debounce(this.handleChange, 2000);
    }

    async updateVesselStatus(value) {
        await this.setState({ vesselStatuses: value });
        this.doSearch();
    }

    async handleChange(event, newValue, oldValue, name) {
        const eventName = get(event, 'target.name', name);
        if (this.props.tableChange && eventName && eventName === 'search') {
            await this.setState({ search: newValue });
            this.doSearch();
        }
    }

    async checkBox(name) {
        const curr = this.state[name];
        await this.setState({ [name]: !curr });
        this.doSearch();
    }

    doSearch() {
        const { search, vesselStatuses, previous_names } = this.state;
        const for_sale =
            this.props.type === 'sales' ? this.state.for_sale : false;

        this.props.updateSearchParameters({
            search,
            vesselStatuses,
            for_sale,
            previous_names
        });
    }

    componentDidMount() {
        this.props.fetchSavedSearches({
            format: 'dropdown'
        });
        this.setState({
            loaded: true
        });

        if (window.location.href.includes('search')) {
            let url = window.location.href.split('=');
            let myindex = this.props.saved_searches.reduce(function (
                result,
                index
            ) {
                if (index.value.toString() === url[1]) {
                    result.push(index);
                }
                return result;
            },
            []);
            this.setState({ selectedSearch: url[1] });
            this.handleSavedSearchSelect(myindex[0]);
        }
    }

    setVesselStatuses = (event) => {
        let statusDetail = [];
        yachtStates.map((index) => {
            if (event.query.includes(index.value)) {
                statusDetail.push(index);
            }
        });
        return statusDetail;
    };

    handleSavedSearchSelect = (event) => {
        if (event) {
            let vesselStatusesArray = this.setVesselStatuses(event);

            let forSale = event.query.includes('for_sale');
            this.setState({
                selectedSearch: event.value,
                for_sale: forSale,
                vesselStatuses: vesselStatusesArray
            });
            this.props.onComponentUpdate(
                event.chartering === 1 ? 'charter' : 'sales',
                event.value
            );
            this.props.updateSearchParameters({
                search: this.state.search,
                previous_names: this.state.previous_names,
                for_sale: forSale,
                vesselStatuses: vesselStatusesArray
            });
            this.props.history.push(
                '/yachts/view/' +
                    (event.chartering === 1 ? 'charter' : 'sales') +
                    `?search=${event.value}`
            );
        } else {
            //this is for when you remove your saved search selection
            let pathname = window.location.pathname.split('/');

            this.props.onClearSearch(pathname[3]);
        }
    };

    resetFormFields() {
        if (this.props.type === 'sales') {
            const fields = [
                { name: 'equal_status', value: 'for_sale' },
                {
                    name: 'include_status_detail',
                    value: ['in_operation', 'in_build']
                }
            ];

            fields.map((val) => {
                this.props.changeFieldValue(val.name, val.value);
                return val;
            });
        }
    }

    componentDidUpdate(nextProps) {
        if (
            this.props.hasSearch !== undefined &&
            nextProps.hasSearch !== undefined
        ) {
            if (
                nextProps.hasSearch !== this.props.hasSearch &&
                nextProps.hasSearch === false &&
                this.state.loaded
            ) {
                //reset fields that are seperate fromd yachts filters form
                setTimeout(() => {
                    this.resetFormFields();
                }, 200);
            }
        }
    }

    render() {
        return (
            <div style={{ marginBottom: 24 }}>
                <div className="row yacht-broker-filter-wrapper">
                    <div className="search-filter col-6 col-md-4 col-xl-4">
                        <Field
                            name="search"
                            placeholder="Search Yacht name or Builder"
                            type="text"
                            className="no-float"
                            component={RenderField}
                            onChange={this.changed}
                            disabled={this.props.loading}
                            // onKeyPress={event => {
                            //   console.log('Event', event, this.state.search)
                            //   if (event.key === 'Enter') {
                            //     this.doSearch()
                            //   }
                            // }}
                        />
                    </div>

                    <div className="col-4 col-md-4">
                        {this.props.type === 'sales' && (
                            <RenderCheckbox
                                label="For sale only"
                                input={{
                                    value: this.state.for_sale,
                                    onChange: (e) => this.checkBox('for_sale')
                                }}
                            />
                        )}
                        <RenderCheckbox
                            label="Search Previous Yacht Names too"
                            input={{
                                value: this.state.previous_names,
                                onChange: (e) => this.checkBox('previous_names')
                            }}
                        />
                    </div>
                    {this.props.type === 'sales' ? (
                        <div className="col-lg-4 col-md-4 col-4 mt-select">
                            <CreatableMulti
                                placeholder="Vessel Status"
                                options={
                                    this.props.type === 'sales'
                                        ? yachtStates
                                        : yachtCharterStates
                                }
                                value={this.state.vesselStatuses}
                                onChange={this.updateVesselStatus.bind(this)}
                            />
                        </div>
                    ) : (
                        <div className="col-lg-4 col-md-4 col-4 mt-select"></div>
                    )}
                    <div className="col-lg-4 col-md-4 col-4">
                        <SelectField
                            name="saved-searches"
                            placeholder="Saved Searches"
                            className="mt-2 mb-2 external-yachts-filters-dropdown"
                            isMulti={false}
                            isClearable={false}
                            isSearchable={false}
                            value={this.state.selectedSearch}
                            options={this.props.saved_searches}
                            onChange={this.handleSavedSearchSelect.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        initialValues: get(state, 'form.yachts_filters.values', {}),
        saved_searches: state.saved_searches.dropdown.map((val) => {
            return {
                value: val.id,
                label: val.name,
                chartering: val.chartering,
                query: val.query
            };
        })
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change('yachts_filters', field, value)),
        fetchSavedSearches: (e) => dispatch(fetchSavedSearches(e))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(form(BrokerTopFilters));
