class QueueCell {
    constructor(item, next = null) {
        this.item = item;
        this.next = next;
    }
}

export default class Queue {
    constructor() {
        this.head = null;
        this.last = null;
    }

    enqueue(item) {
        const cell = new QueueCell(item);
        if (this.last === null) {
            this.last = cell;
            this.head = cell;
        } else {
            this.last.next = cell;
            this.last = cell;
        }
    }

    dequeue() {
        if (this.head === null) {
            return null;
        } else if (this.head === this.last) {
            const result = this.head.item;
            this.head = null;
            this.last = null;
            return result;
        } else {
            const result = this.head.item;
            this.head = this.head.next;
            if (this.head === null) {
                this.tail = null;
            }
            return result;
        }
    }

    isEmpty() {
        return this.head === null;
    }
}
