import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';

import Brokers from '../../brokers/multiple/Brokers';
import RenderField from './RenderField';

import { ModalBody, ModalFooter, Button } from 'reactstrap';

const form = reduxForm({
    form: 'sales_listing_yacht_update'
});

class EditSalesListingYacht extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleteTrigger: false,
            notes: this.props.notes,
            broker: this.props.broker
        };

        // this.props.changeFieldValue('brokers', brokers);
    }
    componentDidMount() {
        this.props.brokers
            ? this.props.brokers
                  .filter((b) => b && b.id)
                  .forEach((broker, index) => {
                      const { id, name } = broker;
                      const displayName =
                          (broker.company !== null ? broker.company : '') +
                          (broker.company !== null && broker.name !== null
                              ? '-'
                              : '') +
                          (broker.name !== null ? broker.name : '');
                      this.props.changeFieldValue(`brokers[${index}]`, {
                          id,
                          name: displayName
                      });
                  })
            : this.props.changeFieldValue('brokers', [{}]);
    }

    async handleFormSubmit(formProps) {
        const { brokers } = formProps;
        const { notes } = this.state;
        await this.props.updateYacht({
            id: this.props.yacht.id,
            brokers,
            notes,
            order: this.props.order
        });
        this.props.toggle();
    }

    notesChange(event, previousValue) {
        this.setState({ notes: event.target.value });
        // this.props.changeFieldValue('notes', newValue);
    }

    render() {
        const { handleSubmit, submitting, invalid } = this.props;

        return (
            <form
                className="modal-wrapper"
                onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
            >
                <ModalBody>
                    <div className="modal-form-wrap">
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 mb-2 mt-2 text-left">
                                <div>Yacht: {this.props.yacht.name}</div>

                                {/* <div className="mt-1">
                                    Do you wish to continue?
                                </div> */}

                                <div className="mt-2 mb-1">
                                    <RenderField
                                        name="notes"
                                        type="notes"
                                        input={{
                                            value: this.state.notes,
                                            onChange: (value) =>
                                                this.notesChange.bind(this)(
                                                    value
                                                )
                                        }}
                                        placeholder="Add notes"
                                        onChange={this.notesChange.bind(this)}
                                        autoFocus={true}
                                    />
                                </div>
                                <div className="row">
                                    <Brokers
                                        type={this.props.viewType}
                                        form="sales_listing_yacht_update"
                                        className="col-12"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="submit"
                        color="primary"
                        disabled={invalid || submitting}
                    >
                        Save
                    </Button>
                    <Button
                        type="button"
                        color="secondary"
                        disabled={submitting}
                        onClick={this.props.toggle}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </form>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change('sales_listing_yacht_update', field, value))
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(form(EditSalesListingYacht));
