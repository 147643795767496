import React from 'react';
import { connect } from 'react-redux';

import ManageYachtDecorator from '../ManageYachtDecorator';
import ManageYachtLocationsAndRatesPresenter from './ManageYachtLocationsAndRatesPresenter';

import { fetchLocationTree } from '../../locations/redux/_actions';

import {
    fetchInitYachtLocations,
    fetchYachtLocations,
    fetchUpdateYachtLocations
} from './redux/_actions';

class ManageyachtLocationsAndRatesContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            is_read_only: false
        };
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (this.props.match.url.includes('view')) {
            this.setState({ is_read_only: true });
        }
        this.props.fetchInitYachtLocations();
        this.props
            .fetchYachtLocations(id)
            .then((response) => this.setState({ loading: false }));
    }

    onSubmit(params) {
        this.props.fetchUpdateYachtLocations(params.id, params);
    }

    render() {
        return (
            <ManageYachtLocationsAndRatesPresenter
                loading={this.state.loading}
                user_role={this.props.user_role}
                history={this.props.history}
                match={this.props.match}
                menu={this.props.menu}
                locations={this.props.locations}
                onSubmit={this.onSubmit.bind(this)}
                read_only={this.state.is_read_only}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user_role: state.auth.user_role,
        locationsTree: state.locations.locationsTree
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchInitYachtLocations: () => dispatch(fetchInitYachtLocations()),
        fetchLocationTree: (params) => dispatch(fetchLocationTree(params)),
        fetchYachtLocations: (id) => dispatch(fetchYachtLocations(id)),
        fetchUpdateYachtLocations: (id, locations) =>
            dispatch(fetchUpdateYachtLocations(id, locations))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageYachtDecorator()(ManageyachtLocationsAndRatesContainer));
