import React from 'react';
import { Row, Col } from 'reactstrap';
import { Field } from 'redux-form';
import ReactSVG from 'react-svg';

// Fields
import RenderField from './RenderField';
import UploadField from './UploadField';

// Helpers
import { videoUrl } from '../../helpers/validate';

// SVG's
import add from '../../assets/svgs/add.svg';

const CoverMediaType = props => {
    return (
        <Row>
            <Col xs="12">
                <label className="upload-label text-uppercase mt-2">
                    Cover photo/video
                </label>
            </Col>

            <Col xs="12" md="6">
                {props.media_type === 'video' && (
                    <div className="d-flex justify-content-start align-items-center">
                        <Field
                            name={props.name}
                            type="text"
                            placeholder="VIDEO URL"
                            className="mb-2 mt-1 col p-0"
                            component={RenderField}
                            validate={[videoUrl]}
                            autoComplete={false}
                        />
                    </div>
                )}

                {props.media_type === 'photo' && (
                    <UploadField
                        name={props.name}
                        fileLabelName="Click here to upload photo"
                        initPhoto={props.initial_photo}
                        className="mb-2 mt-1"
                        type="single"
                        add={(urls, name) => {
                            props.changeFieldValue(props.name, urls);
                        }}
                    />
                )}
            </Col>

            <Col md="2" className="text-center">
                <label className="upload-label text-uppercase or-label">
                    or
                </label>
            </Col>

            <Col className="mt-1">
                <button
                    className="btn btn-blue"
                    type="button"
                    onClick={(event, value) => {
                        props.changeFieldValue(
                            'cover_media_type',
                            props.media_type === 'photo' ? 'video' : 'photo'
                        );
                    }}
                >
                    <ReactSVG path={add} style={{ width: 12 }} />
                    {props.media_type === 'photo' ? 'Add Video' : 'Add Photo'}
                </button>
            </Col>
        </Row>
    );
};

export default CoverMediaType;
