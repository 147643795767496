import React, { PureComponent } from 'react';
import ReactTelephoneInput from 'react-telephone-input';
import 'react-telephone-input/css/default.css';
import { Label } from 'reactstrap';

class RenderPhoneNumber extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { telephone: '', key: 0 };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.input.value !== this.state.telephone)
            this.setState({ key: this._newKey() });
    }

    _newKey() {
        return this.state.key + 1;
    }

    render() {
        return (
            <div className="custom-select-wrapper floating-label-wrapper react-select-wrapper">
                <Label className="float top">{this.props.placeholder}</Label>
                <ReactTelephoneInput
                    key={this.state.key}
                    defaultCountry="us"
                    autoFormat={false}
                    inputProps={{
                        name: this.props.input.name
                    }}
                    className={this.props.readOnly ? 'readonly' : 'editPhone'}
                    initialValue={this.props.input.value}
                    placeholder={this.props.placeholder}
                    onBlur={(telNumber) => {
                        this.props.changeFieldValue(
                            this.props.input.name,
                            telNumber
                        );

                        this.setState({ telephone: telNumber });
                    }}
                />
            </div>
        );
    }
}
export default RenderPhoneNumber;
