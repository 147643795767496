import axios from 'axios';
import { API_URL } from '../../../../helpers/variables';
import { errorHandler, successHandler } from '../../../../helpers/alerts';
import { authConfig } from '../../../../helpers/authConfig';

import {
    FETCH_INIT_YACHT_LOCATIONS,
    FETCH_YACHT_LOCATIONS,
    FETCH_YACHT_LOCATIONS_SUCCESS,
    FETCH_YACHT_LOCATIONS_ERROR,
    UPDATE_YACHT_LOCATIONS,
    UPDATE_YACHT_LOCATIONS_SUCCESS,
    UPDATE_YACHT_LOCATIONS_ERROR
} from './_types';
import {
    formattedPriceToNumber,
    normalizePrice
} from '../../../../helpers/validate';

const fetchInitYachtLocations = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_INIT_YACHT_LOCATIONS });

        return {};
    };
};

const fetchYachtLocations = (id) => {
    return (dispatch) => {
        dispatch({ type: FETCH_YACHT_LOCATIONS });

        const config = {
            headers: authConfig()
        };

        return axios
            .get(`${API_URL}/yachts/${id}/locations`, config)
            .then((response) => {
                //For some reason the deesply nested data of the react redux form are not getting updated correctly
                // and we need to force the update of the rates number to the formatted string version
                let formattedLocationSeasons = response.data.location_seasons.map(
                    (loc) => {
                        return {
                            ...loc,
                            rates: loc.rates.map((r) => {
                                return {
                                    ...r,
                                    rate: normalizePrice(r.rate)
                                };
                            })
                        };
                    }
                );

                dispatch({
                    type: FETCH_YACHT_LOCATIONS_SUCCESS,
                    payload: {
                        ...response.data,
                        location_seasons: formattedLocationSeasons
                    }
                });
                return response.data;
            })
            .catch((error) => {
                dispatch({ type: FETCH_YACHT_LOCATIONS_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchUpdateYachtLocations = (id, params) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_YACHT_LOCATIONS });

        const config = {
            headers: authConfig()
        };

        let formattedData = params.location_seasons.map((loc) => {
            return {
                ...loc,
                rates: loc.rates.map((r) => {
                    return {
                        ...r,
                        rate: formattedPriceToNumber(r.rate)
                    };
                })
            };
        });

        return axios
            .put(
                `${API_URL}/yachts/${id}/locations`,
                { ...params, location_seasons: formattedData },
                config
            )
            .then((response) => {
                dispatch({
                    type: UPDATE_YACHT_LOCATIONS_SUCCESS,
                    payload: response.data
                });

                successHandler(dispatch, 'manageYacht');
                return response.data;
            })
            .catch((error) => {
                dispatch({ type: UPDATE_YACHT_LOCATIONS_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

export {
    fetchInitYachtLocations,
    fetchYachtLocations,
    fetchUpdateYachtLocations
};
