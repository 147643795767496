import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactSVG from 'react-svg';
import { NavLink } from 'react-router-dom';

import Header from '../common/header/Header';

import SimpleTable from '../common/components/SimpleTable';
import SimpleModal from '../common/components/SimpleModal';

import DeleteConfirmation from '../common/components/DeleteConfirmation';

import edit from '../assets/svgs/edit.svg';
import deleteSVG from '../assets/svgs/delete.svg';

import savedSearches from '../assets/svgs/saved-searches.svg';

import {
    fetchSavedSearches,
    initSavedSearch,
    fetchDeleteSavedSearch
} from './_actions';

class SavedSearches extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                parent: savedSearches,
                items: [],
                searchFunction: this.tableChange.bind(this),
                searchFunctionCol: 5,
                searchLabel: 'Search saved search name'
            },
            headers: [
                {
                    name: 'name',
                    label: 'Name',
                    sort: true,
                    defaultSort: 'ASC'
                },
                {
                    name: 'vessels',
                    label: 'No. Of Vessels',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'created_at',
                    label: 'Created on',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'updated_at',
                    label: 'Updated on',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'times_used',
                    label: 'Times used',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'search_type',
                    label: 'Type',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'action',
                    label: 'Action',
                    sort: false,
                    defaultSort: ''
                }
            ],
            modalDelete: (
                <DeleteConfirmation
                    toggle={this.toggleModal.bind(this)}
                    action={this.deleteSavedSearch.bind(this)}
                    body=""
                />
            ),
            modalOpen: false
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    refetchSavedSearches(page) {
        this.props.fetchSavedSearches({
            page: page ? page : this.props.saved_searches.pagination.page,
            order_field: this.props.saved_searches.sorting.order_field,
            order_dir: this.props.saved_searches.sorting.order_dir,
            page_size: this.props.saved_searches.pagination.page_size,
            search: this.props.saved_searches.sorting.search
        });
    }

    deleteModal(saved_search) {
        this.props.initSavedSearch(saved_search).then(() => {
            this.setState({
                modalDelete: (
                    <DeleteConfirmation
                        toggle={this.toggleModal.bind(this)}
                        action={this.deleteSavedSearch.bind(this)}
                        body={
                            'You are about to delete saved search ' +
                            '"' +
                            saved_search.name +
                            '"' +
                            '.'
                        }
                    />
                )
            });
            this.setState({ defaultModal: this.state.modalDelete });
            this.setState({ modalHeader: 'Delete saved search' });

            this.toggleModal();
        });
    }

    deleteSavedSearch() {
        this.props
            .fetchDeleteSavedSearch({ id: this.props.initSearch.id })
            .then((data) => {
                if (data) {
                    if (this.props.saved_searches.length === 1)
                        this.refetchSavedSearches(
                            this.props.saved_searches.pagination.page - 1 > 0
                                ? this.props.saved_searches.pagination.page - 1
                                : 1
                        );
                    else this.refetchSavedSearches();
                }
            });
    }

    componentDidMount() {
        let params = {
            page: 1,
            order_field: 'name',
            order_dir: 'ASC',
            page_size: 25
        };

        if (
            this.props.user_role === 'broker_sales' ||
            this.props.user_role === 'broker_charter'
        ) {
            params.chartering = this.props.user_role === 'broker_sales' ? 0 : 1;
        }

        this.props.fetchSavedSearches(params);
    }

    tableChange(key, newValue) {
        let params = {
            page: key !== 'page' ? 1 : newValue,
            order_dir:
                key !== 'page' && key !== 'page_size' && key !== 'search'
                    ? newValue
                    : this.props.saved_searches.sorting.order_dir,
            order_field:
                key !== 'page' && key !== 'page_size' && key !== 'search'
                    ? key
                    : this.props.saved_searches.sorting.order_field,
            page_size:
                key === 'page_size'
                    ? newValue
                    : this.props.saved_searches.pagination.page_size,
            search:
                key === 'search'
                    ? newValue
                    : this.props.saved_searches.sorting.search
        };

        if (
            this.props.user_role === 'broker_sales' ||
            this.props.user_role === 'broker_charter'
        ) {
            params.chartering = this.props.user_role === 'broker_sales' ? 0 : 1;
        }

        this.props.fetchSavedSearches(params);
    }

    render() {
        return (
            <div className="content-inner-wrapper">
                <Header menu={this.state.menu} history={this.props.history} />

                <div className="content-inner">
                    <div className="row">
                        <div className="col-xl-9 col-12">
                            <SimpleTable
                                tableChange={this.tableChange.bind(this)}
                                paging={this.props.saved_searches.pagination}
                                colWidths={[
                                    '4 col-lg-2',
                                    2,
                                    '2 hidden-md-down',
                                    2,
                                    2,
                                    1,
                                    '1 text-center'
                                ]}
                                headers={this.state.headers}
                                rowData={this.props.saved_searches.searches.map(
                                    (value) => {
                                        return [
                                            <div>
                                                <NavLink
                                                    to={
                                                        `/yachts/view` +
                                                        (value.chartering === 1
                                                            ? '/charter'
                                                            : '/sales') +
                                                        `?search=${value.id}`
                                                    }
                                                    className="btn btn-td-action text-left"
                                                >
                                                    {value.name}
                                                </NavLink>
                                            </div>,
                                            <div>{value.vessel_count}</div>,
                                            <div>
                                                {value.created_at &&
                                                    moment(
                                                        value.created_at
                                                    ).format(
                                                        'DD/MM/YYYY HH:mm'
                                                    )}
                                            </div>,
                                            <div>
                                                {value.updated_at &&
                                                    moment(
                                                        value.updated_at
                                                    ).format(
                                                        'DD/MM/YYYY HH:mm'
                                                    )}
                                            </div>,
                                            <div>{value.times_used}</div>,
                                            <div>
                                                {value.chartering === 0 ? (
                                                    <div className="customer-cat customer-sales">
                                                        S
                                                    </div>
                                                ) : (
                                                    <div className="customer-cat customer-chartering">
                                                        C
                                                    </div>
                                                )}
                                            </div>,
                                            <ul className="list-unstyled table-td-actions">
                                                <li>
                                                    <NavLink
                                                        to={
                                                            `/yachts/view` +
                                                            (value.chartering ===
                                                            1
                                                                ? '/charter'
                                                                : '/sales') +
                                                            `?search=${value.id}`
                                                        }
                                                        className="btn btn-td-action"
                                                    >
                                                        <ReactSVG
                                                            path={edit}
                                                            style={{
                                                                height: 24
                                                            }}
                                                        />
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <button
                                                        className="btn btn-td-action"
                                                        onClick={() => {
                                                            this.deleteModal(
                                                                value
                                                            );
                                                        }}
                                                    >
                                                        <ReactSVG
                                                            path={deleteSVG}
                                                            style={{
                                                                height: 24
                                                            }}
                                                        />
                                                    </button>
                                                </li>
                                            </ul>
                                        ];
                                    }
                                )}
                            />

                            <SimpleModal
                                header={this.state.modalHeader}
                                body={this.state.defaultModal}
                                modalOpen={this.state.modalOpen}
                                toggle={this.toggleModal.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        saved_searches: state.saved_searches,
        initSearch: state.saved_searches.initSavedSearch,
        user_role: state.auth.user_role
    };
}

export default connect(mapStateToProps, {
    fetchSavedSearches,
    initSavedSearch,
    fetchDeleteSavedSearch
})(SavedSearches);
