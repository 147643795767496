import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';
import moment, { relativeTimeRounding } from 'moment';
import { UncontrolledTooltip } from 'reactstrap';

import Header from '../../../common/header/Header';

import SimpleTableActions from '../../../common/components/SimpleTableActions';
import SimpleTable from '../../../common/components/SimpleTable';
import SimpleModal from '../../../common/components/SimpleModal';

import { ModalBody } from 'reactstrap';
import DeleteConfirmation from '../../../common/components/DeleteConfirmation';

import add from '../../../assets/svgs/add.svg';
import createOffer from '../../../assets/svgs/create-offer.svg';
import customers from '../../../assets/svgs/customers.svg';
import favorite from '../../../assets/svgs/favorite.svg';
import favoriteEmpty from '../../../assets/svgs/favorite-empty.svg';
import deleteSVG from '../../../assets/svgs/delete.svg';
import edit from '../../../assets/svgs/edit.svg';
import makeDollar from '../../../assets/svgs/make-dollar.svg';
import UploadField from '../../../common/components/Forms/UploadField';

import {
    fetchCustomers,
    fetchManageCustomer,
    initCustomer,
    fetchDeleteCustomer
} from '../../../customers/customers/_actions';
import { importCustomers } from '../../../services/customers';
import { success } from 'react-notification-system-redux';
import { errorHandler } from '../../../helpers/alerts';

class IndexCustomersContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                parent: customers,
                items:
                    this.props.user_role !== undefined &&
                    (this.props.user_role === 'broker_sales' ||
                        this.props.user_role === 'broker_charter')
                        ? [
                              <NavLink
                                  exact
                                  to={
                                      '/clients/' +
                                      (this.props.user_role === 'broker_sales'
                                          ? 'sales'
                                          : 'charters')
                                  }
                              >
                                  {this.props.user_role === 'broker_sales'
                                      ? 'Sales'
                                      : 'Charter'}
                              </NavLink>
                          ]
                        : [
                              <NavLink exact to="/clients">
                                  All
                              </NavLink>,
                              <NavLink exact to="/clients/sales">
                                  Sales
                              </NavLink>,
                              <NavLink exact to="/clients/charters">
                                  Charter
                              </NavLink>
                          ],
                searchFunction: this.tableChange.bind(this),
                searchFunctionCol: 5,
                searchLabel: 'Search customer'
            },
            headers: [
                {
                    name: 'offer',
                    label: 'Create Proposal',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'last_name',
                    label: 'Name',
                    sort: true,
                    defaultSort: 'ASC'
                },
                {
                    name: 'category',
                    label: 'Category',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'offer_date',
                    label: 'Last proposal',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'no_offers',
                    label: 'Proposals',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'no_newsletters',
                    label: 'Newsletters',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'action',
                    label: 'Action',
                    sort: false,
                    defaultSort: ''
                }
            ],
            modalHeader: 'Create new contact',
            modalOpen: false,
            modalDelete: (
                <DeleteConfirmation
                    toggle={this.toggleModal.bind(this)}
                    action={this.deleteCustomer.bind(this)}
                    body=""
                />
            ),
            modalBody: '',
            documents: []
        };
    }

    addMedia(url, name) {
        let obj = { url: url, type: name };

        this.setState({
            ...this.state,
            documents: [...this.state.documents, obj]
        });
        console.log('file: ', this.state.documents);
    }

    tableChange(key, newValue) {
        let params = {
            page: key !== 'page' ? 1 : newValue,
            order_dir:
                key !== 'page' &&
                key !== 'page_size' &&
                key !== 'search' &&
                key !== 'favourite'
                    ? newValue
                    : this.props.customers.sorting.order_dir,
            order_field:
                key !== 'page' &&
                key !== 'page_size' &&
                key !== 'search' &&
                key !== 'favourite'
                    ? key
                    : this.props.customers.sorting.order_field,
            page_size:
                key === 'page_size'
                    ? newValue
                    : this.props.customers.pagination.page_size,
            favourite:
                key === 'favourite'
                    ? newValue
                    : this.props.customers.sorting.favourite,
            search:
                key === 'search'
                    ? newValue
                    : this.props.customers.sorting.search
        };

        if (this.props.match.path === '/clients/sales') {
            params.chartering = 0;
        } else if (this.props.match.path === '/clients/charters') {
            params.chartering = 1;
        }

        if (params.favourite === 0) {
            delete params.favourite;
        }

        this.props.fetchCustomers(params);
    }

    refetchCustomers(page) {
        this.props.fetchCustomers({
            page: page ? page : this.props.customers.pagination.page,
            order_field: this.props.customers.sorting.order_field,
            order_dir: this.props.customers.sorting.order_dir,
            page_size: this.props.customers.pagination.page_size,
            search: this.props.customers.sorting.search,
            favorite: this.props.customers.sorting.favorite
        });
    }

    manageCustomer(value) {
        let params = { id: value.id, favourite: value.favourite === 1 ? 0 : 1 };

        this.props.fetchManageCustomer(params).then((data) => {
            if (data) {
                this.refetchCustomers();
            }
        });
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    deleteModal(customer) {
        this.props.initCustomerModal(customer).then(() => {
            this.setState({
                modalBody: (
                    <DeleteConfirmation
                        toggle={this.toggleModal.bind(this)}
                        action={this.deleteCustomer.bind(this)}
                        body={
                            'You are about to delete client ' +
                            customer.last_name +
                            ' ' +
                            customer.first_name +
                            '.'
                        }
                    />
                )
            });
            this.setState({ modalHeader: 'Delete client' });

            this.toggleModal();
        });
    }

    deleteCustomer() {
        this.props
            .fetchDeleteCustomer({ id: this.props.initCustomer.id })
            .then((data) => {
                if (data) {
                    if (this.props.customers.customers.length === 1)
                        this.refetchCustomers(
                            this.props.customers.pagination.page - 1 > 0
                                ? this.props.customers.pagination.page - 1
                                : 1
                        );
                    else this.refetchCustomers();

                    this.props.initCustomerModal({});
                }
            });
    }

    componentDidMount() {
        let params = {
            page: 1,
            page_size: 25,
            order_field: 'last_name',
            order_dir: 'ASC'
        };

        if (this.props.match.path === '/clients/sales') {
            params.chartering = 0;
        } else if (this.props.match.path === '/clients/charters') {
            params.chartering = 1;
        }

        this.props.fetchCustomers(params);
        this.props.initCustomerModal({});
    }

    onCreateProposal(customer) {
        if (this.props.match.path === '/clients') {
            // show modal
            this.setState({
                modalHeader: 'Choose type of proposal',
                modalOpen: true,
                modalBody: (
                    <ModalBody>
                        <div className="row justify-content-center my-2">
                            <div className="col-6 col-xl-4">
                                <NavLink
                                    className="btn btn-primary"
                                    to={`/clients/proposals/${customer.id}/sales/create`}
                                >
                                    <ReactSVG
                                        path={makeDollar}
                                        style={{
                                            width: 26
                                        }}
                                    />
                                    Sales Proposal
                                </NavLink>
                            </div>
                            <div className="col-6 col-xl-4">
                                <NavLink
                                    className="btn btn-primary"
                                    to={`/clients/proposals/${customer.id}/charter/create`}
                                >
                                    <ReactSVG
                                        path={makeDollar}
                                        style={{
                                            width: 26
                                        }}
                                    />
                                    Charter Proposal
                                </NavLink>
                            </div>
                        </div>
                    </ModalBody>
                )
            });
            return;
        }

        const type = this.props.match.url.includes('sales')
            ? 'sales'
            : 'charter';
        const pathname = `/clients/proposals/${customer.id}/${type}/create`;

        this.props.history.push({
            pathname: pathname
        });
    }

    render() {
        return (
            <div className="content-inner-wrapper">
                <Header menu={this.state.menu} history={this.props.history} />

                <div className="content-inner">
                    <div className="row" style={{ maxWidth: '1250px' }}>
                        <div className="col-12 col-xl-11">
                            <div className="row">
                                <SimpleTableActions
                                    label="Create new contact"
                                    icon={add}
                                    onClick={() =>
                                        this.props.history.push(
                                            '/clients/create'
                                        )
                                    }
                                />
                                <UploadField
                                    name="csv"
                                    label="Import contacts from CSV"
                                    type="multiple"
                                    accept=".csv"
                                    add={this.addMedia.bind(this)}
                                    className="ml-auto"
                                    action={importCustomers}
                                    onSuccess={(res) => {
                                        this.props.success(
                                            `${res.data.customersAdded} customer(s) added`
                                        );
                                    }}
                                    onError={(err) => {
                                        this.props.error(err?.response || err);
                                    }}
                                />
                            </div>
                            {this.props.customers.customers &&
                            this.props.customers.customers.length > 0 ? (
                                <SimpleTable
                                    tableChange={this.tableChange.bind(this)}
                                    paging={this.props.customers.pagination}
                                    colWidths={[
                                        '2 text-center',
                                        2,
                                        '2 text-center hidden-md-down',
                                        '2 date',
                                        '1 text-center',
                                        '1 text-center',
                                        '2 text-center ml-auto'
                                    ]}
                                    headers={this.state.headers}
                                    rowData={this.props.customers.customers.map(
                                        (value) => {
                                            return [
                                                <button
                                                    className="btn btn-blue btn-offer ghost"
                                                    onClick={() =>
                                                        this.onCreateProposal(
                                                            value
                                                        )
                                                    }
                                                    style={{ minWidth: 'auto' }}
                                                >
                                                    <ReactSVG
                                                        path={makeDollar}
                                                        style={{
                                                            width: 20
                                                        }}
                                                    />
                                                    Create Proposal
                                                </button>,
                                                <NavLink
                                                    to={
                                                        '/clients/view/' +
                                                        value.id
                                                    }
                                                >
                                                    {value.last_name +
                                                        ' ' +
                                                        value.first_name}
                                                </NavLink>,
                                                <div className="text-capitalize">
                                                    {value.customer_type !==
                                                    'industry'
                                                        ? value.customer_type
                                                        : value.customer_type +
                                                          ' pro'}
                                                </div>,
                                                <div>
                                                    {value.last_offer_created &&
                                                        moment(
                                                            value.last_offer_created
                                                        ).format('DD/MM/YYYY')}
                                                </div>,
                                                <NavLink
                                                    to={
                                                        '/clients/proposals/' +
                                                        value.id
                                                    }
                                                >
                                                    {value.total_offers}
                                                </NavLink>,
                                                <NavLink
                                                    to={
                                                        '/newsletters?customer_id=' +
                                                        value.id
                                                    }
                                                >
                                                    {value.total_newsletters}
                                                </NavLink>,
                                                <ul className="list-unstyled table-td-actions">
                                                    <li>
                                                        <button
                                                            className="btn btn-td-action btn-empty d-flex align-items-center p-0"
                                                            onClick={(e) =>
                                                                this.manageCustomer(
                                                                    value
                                                                )
                                                            }
                                                            id={
                                                                'favourite' +
                                                                value.id
                                                            }
                                                        >
                                                            <ReactSVG
                                                                path={
                                                                    value.favourite ===
                                                                    1
                                                                        ? favorite
                                                                        : favoriteEmpty
                                                                }
                                                                style={{
                                                                    height: 16
                                                                }}
                                                            />
                                                        </button>
                                                        <UncontrolledTooltip
                                                            placement="bottom"
                                                            target={
                                                                'favourite' +
                                                                value.id
                                                            }
                                                        >
                                                            {value.favourite
                                                                ? 'Remove from favorites'
                                                                : 'Add to favorites'}
                                                        </UncontrolledTooltip>
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            className="btn btn-td-action"
                                                            to={
                                                                '/clients/proposals/' +
                                                                value.id
                                                            }
                                                            id={
                                                                'offer' +
                                                                value.id
                                                            }
                                                        >
                                                            <ReactSVG
                                                                path={
                                                                    createOffer
                                                                }
                                                                style={{
                                                                    width: 22
                                                                }}
                                                            />
                                                        </NavLink>
                                                        <UncontrolledTooltip
                                                            placement="bottom"
                                                            target={
                                                                'offer' +
                                                                value.id
                                                            }
                                                        >
                                                            Proposal history
                                                        </UncontrolledTooltip>
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            className="btn btn-td-action"
                                                            to={
                                                                '/clients/edit/' +
                                                                value.id
                                                            }
                                                            id={
                                                                'edit' +
                                                                value.id
                                                            }
                                                        >
                                                            <ReactSVG
                                                                path={edit}
                                                                style={{
                                                                    height: 22
                                                                }}
                                                            />
                                                        </NavLink>
                                                        <UncontrolledTooltip
                                                            placement="bottom"
                                                            target={
                                                                'edit' +
                                                                value.id
                                                            }
                                                        >
                                                            Edit
                                                        </UncontrolledTooltip>
                                                    </li>
                                                    <li>
                                                        <button
                                                            className="btn btn-td-action"
                                                            onClick={() =>
                                                                this.deleteModal(
                                                                    value
                                                                )
                                                            }
                                                            id={
                                                                'delete' +
                                                                value.id
                                                            }
                                                        >
                                                            <ReactSVG
                                                                path={deleteSVG}
                                                                style={{
                                                                    height: 22
                                                                }}
                                                            />
                                                        </button>

                                                        <UncontrolledTooltip
                                                            placement="bottom"
                                                            target={
                                                                'delete' +
                                                                value.id
                                                            }
                                                        >
                                                            Delete
                                                        </UncontrolledTooltip>
                                                    </li>
                                                </ul>
                                            ];
                                        }
                                    )}
                                    actions={
                                        <div className="table-favorites">
                                            <button
                                                className={
                                                    'btn btn-empty btn-favorite-action d-flex align-items-center ' +
                                                    (this.props.customers
                                                        .sorting.favourite === 1
                                                        ? 'selected'
                                                        : '')
                                                }
                                                onClick={(e) =>
                                                    this.tableChange(
                                                        'favourite',
                                                        this.props.customers
                                                            .sorting
                                                            .favourite === 1
                                                            ? 0
                                                            : 1
                                                    )
                                                }
                                            >
                                                <ReactSVG
                                                    path={
                                                        this.props.customers
                                                            .sorting
                                                            .favourite === 1
                                                            ? favorite
                                                            : favoriteEmpty
                                                    }
                                                    style={{
                                                        height: 16,
                                                        width: 16
                                                    }}
                                                />
                                                <span>See favorites</span>
                                            </button>
                                        </div>
                                    }
                                />
                            ) : (
                                <div className="col-12">
                                    <div className="empty-customers-message">
                                        <ReactSVG
                                            path={customers}
                                            style={{ height: 80 }}
                                        />
                                        <div className="mt-1 text-main size-12 font-400">
                                            Welcome to CRM and Yacht Data Base.
                                            Fill in your client info in order to
                                            benefit the full experience
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <SimpleModal
                    header={this.state.modalHeader}
                    body={this.state.modalBody}
                    modalOpen={this.state.modalOpen}
                    toggle={this.toggleModal.bind(this)}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        success: (msg) =>
            dispatch(
                success({
                    title: 'Success',
                    message: msg
                })
            ),
        error: (err) => errorHandler(dispatch, err),
        fetchCustomers: (e) => dispatch(fetchCustomers(e)),
        fetchManageCustomer: (e) => dispatch(fetchManageCustomer(e)),
        initCustomerModal: (e) => dispatch(initCustomer(e)),
        fetchDeleteCustomer: (e) => dispatch(fetchDeleteCustomer(e))
    };
}

function mapStateToProps(state) {
    return {
        user: state.user,
        customers: state.customers.customers,
        initCustomer: state.customers.customers.initCustomer,
        user_role: state.auth.user_role
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndexCustomersContainer);
