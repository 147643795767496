import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';
import moment from 'moment';

import SimpleTableActions from '../../../../common/components/SimpleTableActions';
import SimpleTable from '../../../../common/components/SimpleTable';

// Helpers
import can from '../../../../infrastructure/helpers/can';

// SVG

import deleteIcon from '../../../../assets/svgs/delete.svg';
import add from '../../../../assets/svgs/add.svg';
import edit from '../../../../assets/svgs/edit.svg';
import open_eye from '../../../../assets/svgs/open-eye.svg';

const TestimonialsTable = (props) => {
    const getTableData = () => {
        return props.data.map((item) => {
            let data = [
                <div>{item.title}</div>,
                <div className="text--ellipsis">{item.description}</div>,
                <div>{moment(item.date).format('DD/MM/YYYY')}</div>,

                <ul className="list-unstyled table-td-actions">
                    {can('manageTestimonials', props.userRole) && (
                        <li className="text-fill-hover">
                            <a
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    props.deleteTestimonial(item.id);
                                }}
                            >
                                <ReactSVG
                                    path={deleteIcon}
                                    style={{ height: 24 }}
                                />
                            </a>
                        </li>
                    )}

                    {can('manageTestimonials', props.userRole) && (
                        <li className="text-fill-hover">
                            <NavLink
                                to={`/yachts/edit/charter/${item.yacht_id}/testimonials/${item.id}/edit`}
                            >
                                <ReactSVG path={edit} style={{ height: 24 }} />
                            </NavLink>
                        </li>
                    )}

                    <li className="text-fill-hover">
                        <NavLink
                            to={`/yachts/view/charter/${item.yacht_id}/testimonials/${item.id}`}
                        >
                            <ReactSVG path={open_eye} style={{ height: 24 }} />
                        </NavLink>
                    </li>
                </ul>
            ];

            if (props.showYachtColumn) {
                data.unshift(
                    <div>
                        <NavLink to={`/yachts/view/charter/${item.yacht_id}/`}>
                            {item.yacht.name}
                        </NavLink>
                    </div>
                );
            }

            return data;
        });
    };

    return (
        <div>
            {can('manageTestimonials', props.userRole) && (
                <SimpleTableActions
                    label="Create new Testimonial"
                    icon={add}
                    onClick={() =>
                        props.history.push(
                            `/yachts/edit/charter/${props.yacht_id}/testimonials/create`
                        )
                    }
                />
            )}

            <SimpleTable
                tableChange={props.onTableChange}
                paging={
                    props.hasPagination ? props.bookings.pagination : undefined
                }
                colWidths={[4, 4, 3, 1]}
                headers={props.table_headers}
                rowData={getTableData()}
            />
            {props.data.length <= 0 && (
                <div className="text-center py-sm-4">
                    There is no data in this table yet.
                </div>
            )}
        </div>
    );
};

export default TestimonialsTable;
