import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ModalBody } from 'reactstrap';
import { reduxForm } from 'redux-form';

import RenderRedactor from '../../common/components/RenderRedactor';

import {
    fetchYachtComments,
    fetchPostYachtComment,
    fetchYachtCommentsView
} from './_actions';

import avatar from '../../assets/svgs/avatar.svg';

import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import $ from 'jquery';

const form = reduxForm({
    form: 'comments',
    enableReinitialize: true
});

let objDiv = null;

class CommentsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comments: [],
            loaded: false
        };
    }

    componentDidMount() {
        if (this.props.newComments) {
            this.props.fetchYachtCommentsView({ id: this.props.yachtId });
        }
        this.props
            .fetchYachtComments({
                id: this.props.yachtId,
                page: 1,
                page_size: 5
            })
            .then(data => {
                if (data) {
                    this.setState({
                        comments: _orderBy(
                            data.comments,
                            ['created_at'],
                            ['asc']
                        ),
                        loaded: true
                    });
                }
                objDiv = document.getElementById('comments-wrapper');
            });
    }

    handleFormSubmit(formProps) {
        if (
            document.getElementById('comment_content') &&
            document.getElementById('comment_content').value
        ) {
            let params = {
                content: document.getElementById('comment_content').value,
                id: this.props.yachtId
            };

            return this.props.fetchPostYachtComment(params).then(data => {
                if (data) {
                    this.setState({
                        comments: [...this.state.comments, data]
                    });
                    this.props.fetchYachtCommentsView({
                        id: this.props.yachtId
                    });
                    this.props.fetchYachtComments({
                        id: this.props.yachtId,
                        page: this.props.comments.pagination.page,
                        page_size: 5
                    });
                    objDiv.scrollTop = objDiv.scrollHeight;

                    $('#comment_content').redactor('code.set', '');
                }
            });
        }
    }

    loadMore() {
        if (this.props.comments.pagination.total) {
            if (
                this.state.comments.length !==
                this.props.comments.pagination.total
            ) {
                let params = {
                    id: this.props.yachtId,
                    page: this.props.comments.pagination.page + 1,
                    page_size: 5
                };

                if (params.page > this.props.comments.pagination.page_count)
                    params.page = this.props.comments.pagination.page_count;

                this.props.fetchYachtComments(params).then(data => {
                    if (data) {
                        let array = _orderBy(
                            this.state.comments.concat(data.comments),
                            ['created_at'],
                            ['asc']
                        );
                        array = _uniqBy(array, 'id');

                        this.setState({
                            comments: array
                        });

                        objDiv.scrollTop = 0;
                    }
                });
            }
        }
    }

    createMarkup(body) {
        return { __html: body };
    }

    render() {
        const { handleSubmit, submitting } = this.props;

        return (
            <ModalBody>
                <div className="row">
                    <div className="col-sm-10 offset-sm-1 mb-1 mt-1">
                        {this.state.loaded === true && (
                            <div>
                                {this.state.comments.length !== 0 && (
                                    <div
                                        className="comments-wrapper mb-1"
                                        id="comments-wrapper"
                                    >
                                        <div className="comments-list">
                                            {this.state.comments.map(
                                                (com, key) => {
                                                    return (
                                                        <div
                                                            className="row no-gutters comment-wrap align-items-start mb-1"
                                                            key={com.id}
                                                        >
                                                            <div className="col-1">
                                                                <img
                                                                    className="img-rounded"
                                                                    width="48"
                                                                    height="48"
                                                                    src={
                                                                        !com.user ||
                                                                        (com.user &&
                                                                            !com
                                                                                .user
                                                                                .photo)
                                                                            ? avatar
                                                                            : com
                                                                                  .user
                                                                                  .photo
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="col-11 comment-author pl-lg-1 pl-2">
                                                                <div className="row">
                                                                    <div className="col-6 comment-name">
                                                                        {com.user &&
                                                                        com.user
                                                                            .name
                                                                            ? com
                                                                                  .user
                                                                                  .name
                                                                            : 'System Entry'}
                                                                    </div>
                                                                    <div className="col-6 comment-date text-right">
                                                                        {moment(
                                                                            com.created_at
                                                                        ).calendar()}
                                                                    </div>
                                                                    <div className="col-12 comment-desc">
                                                                        <div
                                                                            dangerouslySetInnerHTML={this.createMarkup(
                                                                                com.user &&
                                                                                    com
                                                                                        .user
                                                                                        .name
                                                                                    ? com.content
                                                                                    : '<p>' +
                                                                                          com.content +
                                                                                          '</p>'
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                )}

                                {this.props.comments.pagination &&
                                    this.state.comments.length !== 0 && (
                                        <div
                                            className={
                                                'comments-pagination-wrapper d-flex align-items-center pb-1 ' +
                                                (this.state.comments.length <
                                                this.props.comments.pagination
                                                    .total
                                                    ? 'justify-content-between'
                                                    : 'justify-content-end')
                                            }
                                        >
                                            {this.state.comments.length <
                                                this.props.comments.pagination
                                                    .total && (
                                                <button
                                                    type="button"
                                                    className="btn btn-empty"
                                                    onClick={this.loadMore.bind(
                                                        this
                                                    )}
                                                >
                                                    view more
                                                </button>
                                            )}
                                            <div className="pagination-wrapper">
                                                {this.state.comments.length} of{' '}
                                                {
                                                    this.props.comments
                                                        .pagination.total
                                                }
                                            </div>
                                        </div>
                                    )}
                            </div>
                        )}

                        <form
                            className="list-form"
                            onSubmit={handleSubmit(
                                this.handleFormSubmit.bind(this)
                            )}
                        >
                            <RenderRedactor
                                label="COMMENT"
                                name="content"
                                id="comment_content"
                            />

                            <div className="mt-2 text-right">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-search"
                                    disabled={submitting}
                                >
                                    Post
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </ModalBody>
        );
    }
}

function mapStateToProps(state) {
    return {
        comments: state.yachts.yacht.comments
    };
}

export default connect(mapStateToProps, {
    fetchYachtComments,
    fetchPostYachtComment,
    fetchYachtCommentsView
})(form(CommentsModal));
