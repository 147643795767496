import { 
    INIT_BOOKING, 
    FETCH_BOOKINGS_SUCCESS, 
    FETCH_BOOKING_SUCCESS, 
    FETCH_BOOKINGS_LOCATIONS_SUCCESS 
} from './_types';

const BOOKINGS = {
    data: [],
    pagination: {
        page: 1,
        page_count: 1,
        page_size: 15,
        total: 1,
        search: ''
    }
};

const INITIAL_STATE = {
    bookings: BOOKINGS,
    booking: {}
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INIT_BOOKING:
            return {
                ...state,
                booking: {}
            }
        case FETCH_BOOKINGS_SUCCESS:
            return {
                ...state,
                bookings: {
                    data: action.payload.data,
                    pagination: action.payload.pagination
                }
            };
        case FETCH_BOOKING_SUCCESS:
            return {
                ...state,
                booking: action.payload
            };
        case FETCH_BOOKINGS_LOCATIONS_SUCCESS:
            return {
                ...state,
                suggestions: action.payload
            }
        default:
            return state;
    }
};

export default reducer;
