import axios from 'axios';

import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  INIT_USER_MODAL,
  FETCH_MANAGE_USER,
  FETCH_MANAGE_USER_SUCCESS,
  FETCH_MANAGE_USER_FAIL,
  FETCH_DELETE_USER,
  FETCH_DELETE_USER_SUCCESS,
  FETCH_DELETE_USER_FAIL,
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAIL
} from './_types';

import { API_URL } from '../../helpers/variables';
import { errorHandler, successHandler } from '../../helpers/alerts';
import { authConfig } from '../../helpers/authConfig';

export function fetchUsers(params) {
  return dispatch => {
    dispatch({ type: FETCH_USERS });

    const config = {
      headers: authConfig(),
      params: params
    };

    axios
      .get(API_URL + '/users', config)
      .then(response => {
        dispatch({
          type: FETCH_USERS_SUCCESS,
          payload: {
            users: response.data.users,
            pagination: response.data.pagination,
            sorting: {
              order_dir: params.order_dir,
              order_field: params.order_field,
              page_size: params.page_size
            }
          }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_USERS_FAIL });
        errorHandler(dispatch, error.response);
      });
  };
}

export function fetchAllUsers() {
  return dispatch => {
    dispatch({ type: FETCH_ALL_USERS });

    const config = {
      headers: authConfig()
    };

    return axios
      .get(API_URL + '/users', config)
      .then(response => {
        dispatch({ type: FETCH_ALL_USERS_SUCCESS, payload: response.data });
        return response.data;
      })
      .catch(error => {
        dispatch({ type: FETCH_ALL_USERS_FAIL });
        errorHandler(dispatch, error.response);
      });
  };
}

export function initUserModal(user) {
  return dispatch => {
    dispatch({ type: INIT_USER_MODAL, payload: user });

    return new Promise(function(resolve, reject) {
      resolve('Success!');
    });
  };
}

export function fetchManageUser(params) {
  return dispatch => {
    dispatch({ type: FETCH_MANAGE_USER });

    const config = { headers: authConfig() };

    if (params.id) {
      return axios
        .put(API_URL + '/users/' + params.id, params, config)
        .then(response => {
          dispatch({ type: FETCH_MANAGE_USER_SUCCESS });
          successHandler(dispatch, 'manageUser');

          return response.data;
        })
        .catch(error => {
          errorHandler(dispatch, error.response, FETCH_MANAGE_USER_FAIL);
        });
    } else {
      return axios
        .post(API_URL + '/users', params, config)
        .then(response => {
          dispatch({ type: FETCH_MANAGE_USER_SUCCESS });
          successHandler(dispatch, 'manageUser');

          return response.data;
        })
        .catch(error => {
          errorHandler(dispatch, error.response, FETCH_MANAGE_USER_FAIL);
        });
    }
  };
}

export function fetchDeleteUser(params) {
  return dispatch => {
    dispatch({ type: FETCH_DELETE_USER });

    const config = { headers: authConfig() };

    return axios
      .delete(API_URL + '/users/' + params.id, config)
      .then(response => {
        dispatch({ type: FETCH_DELETE_USER_SUCCESS });
        successHandler(dispatch, 'deleteUser');

        return response.data;
      })
      .catch(error => {
        errorHandler(dispatch, error.response, FETCH_DELETE_USER_FAIL);
      });
  };
}
