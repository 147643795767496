import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Field, change, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import Notifications from 'react-notification-system-redux';

import Header from '../../common/header/Header';

import salesListings from '../../assets/svgs/saleslistings_icon-02.svg';

import RenderField from '../../common/components/RenderField';
import UploadField from '../../common/components/UploadField';

import { required } from '../../helpers/validate';
import RenderReactSelect from '../../common/components/RenderReactSelect';

import { createSalesListing } from './_actions';

import { fetchSelectedYachts } from '../../yachts/search/_actions';
import {
    fetchSavedSearches,
    fetchSavedSearchResults
} from '../../saved_searches/_actions';

const form = reduxForm({
    form: 'manage_destination'
});

class NewSalesListing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                parent: salesListings,
                items: [
                    <NavLink exact to="/sales-listings/create">
                        Add new Sales Listings
                    </NavLink>
                ],
                backLink: '/sales-listings'
            },
            open: false,
            photos: [
                { url: 'https://iycstorage.s3.amazonaws.com/topio1.jpg' },
                { url: 'https://iycstorage.s3.amazonaws.com/topio2.jpg' },
                { url: 'https://iycstorage.s3.amazonaws.com/topio3.jpg' },
                { url: 'https://iycstorage.s3.amazonaws.com/topio4.jpg' },
                { url: 'https://iycstorage.s3.amazonaws.com/topio5.jpg' },
                { url: 'https://iycstorage.s3.amazonaws.com/topio6.jpg' }
            ],
            suggestions: {
                selected: '',
                list: []
            },
            dropdownPlaces: [],
            destination: {
                cover_photo: ''
            },
            isVideoUrl: 'photo',
            cover_media_type: 'photo',
            is_public: 0,
            source: 'selection'
        };
    }

    async componentDidMount() {
        if (this.props.history.location.search.includes('selection=charter')) {
            this.setSource('selection_charter');
        }

        if (this.props.history.location.search.includes('selection=sales')) {
            this.setSource('selection_sales');
        }

        this.props.fetchSavedSearches({ format: 'dropdown' });
        this.fetchSelectedYachts();
        if (this.props.match.params && this.props.match.params.id) {
            this.setState({
                menu: {
                    ...this.state.menu,
                    items: [
                        ...this.state,
                        <NavLink
                            exact
                            to={
                                '/sales-listings/edit/' +
                                this.props.match.params.id
                            }
                        >
                            Edit Sales Listing
                        </NavLink>
                    ]
                }
            });
        }
    }

    async fetchSelectedYachts() {
        const data = await this.props.fetchSelectedYachts();

        if (data) {
            const selectedYachts = data.selection;

            this.setState({ selectedYachts });
            this.setState({
                selectionYachts: {
                    yachts: data.selection,
                    pagination: {
                        page: 1,
                        page_size: 25,
                        page_count: 1,
                        total: data.total
                    }
                }
            });
        }
    }

    getSearchResults = (event, newValue, previousValue) => {
        this.setState({ exclude: {} });
        this.setState({ selectedSearchYachts: {} });
        this.setState({ selectAllSearchYachts: false });

        if (newValue && newValue !== '') {
            let params = {
                page: 1,
                order_dir: this.props.savedSearchYachts.sorting.order_dir,
                order_field: this.props.savedSearchYachts.sorting.order_field,
                page_size: this.props.savedSearchYachts.pagination.page_size,
                id: newValue
            };
            this.props.fetchSavedSearchResults(params);
        }
    };

    toggle() {
        this.setState({
            open: !this.state.open
        });
    }

    addPhoto(url, name) {
        this.setState({ destination: { cover_photo: url } });
    }

    async handleFormSubmit(formProps) {
        let valid = true;

        let params = {};
        let errors = [];

        if (errors.image_sug !== undefined) {
            this.props.notification('Error', 'Please add image to suggestion');
            return;
        }

        if (errors.text_sug !== undefined) {
            this.props.notification('Error', 'Please add text to suggestion');
            return;
        }

        if (this.state.cover_media_type === 'video') {
            params.cover_photo_url = formProps.video_url;
            params.cover_media_type = 'video';
        } else {
            params.cover_photo_url = this.state.destination.cover_photo;
            params.cover_media_type = 'photo';
        }

        const { cover_photo_url } = params;
        const { public_title, internal_title, search_id } = formProps;
        const { is_public, source } = this.state;

        const data = {
            public_title,
            internal_title,
            cover_photo_url,
            is_public,
            source,
            search_id
        };

        if (valid === true) {
            await this.props.createSalesListing(data);
            this.props.history.push('/sales-listings');
        }
    }

    changePublic() {
        const { is_public } = this.state;
        this.setState({ is_public: !is_public });
    }

    cancelForm() {
        this.props.history.push('/sales-listings');
    }

    changeMedia() {
        this.setState({
            cover_media_type:
                this.state.cover_media_type === 'video' ? 'photo' : 'video'
        });
    }

    setSource(source) {
        this.setState({ source: source });
    }

    render() {
        const { handleSubmit, submitting } = this.props;

        return (
            <div className="content-inner-wrapper">
                <Header menu={this.state.menu} history={this.props.history} />

                <div className="content-inner destinations-manage">
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <form
                                className="list-form"
                                onSubmit={handleSubmit(
                                    this.handleFormSubmit.bind(this)
                                )}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <div className="name-wrapper">
                                            <Field
                                                name="public_title"
                                                type="text"
                                                className="mb-1 public-title"
                                                placeholder="Public Title"
                                                validate={[required]}
                                                component={RenderField}
                                            />
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <label className="upload-label text-uppercase mt-2">
                                                    Cover photo
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row align-items-start">
                                            <div
                                                className={
                                                    this.state
                                                        .cover_media_type ===
                                                        'photo' &&
                                                    this.state.destination
                                                        .cover_photo !== ''
                                                        ? 'col-xl-2 col-lg-3 col-md-4'
                                                        : 'col-4'
                                                }
                                            >
                                                {this.state.cover_media_type ===
                                                    'photo' && (
                                                    <UploadField
                                                        name="cover_photo"
                                                        fileLabelName="Click here to upload photo"
                                                        initPhoto={
                                                            this.state
                                                                .destination
                                                                .cover_photo
                                                        }
                                                        className="mb-3 mt-1"
                                                        type="single"
                                                        add={this.addPhoto.bind(
                                                            this
                                                        )}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-5 role-switch-wrapper">
                                        <Field
                                            name="internal_title"
                                            type="text"
                                            placeholder="SALES LISTING NAME IYC"
                                            validate={[required]}
                                            component={RenderField}
                                        />
                                        <div className="toggle-wrapper">
                                            <div className="row">
                                                <div
                                                    className={
                                                        'toggle-label ' +
                                                        this.state.is_public
                                                            ? 'active'
                                                            : ''
                                                    }
                                                    style={{
                                                        paddingRight: '16px'
                                                    }}
                                                >
                                                    {'Make it public?'}
                                                </div>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={
                                                            this.state.is_public
                                                        }
                                                        onChange={() =>
                                                            this.changePublic()
                                                        }
                                                        // id={name}
                                                        // {...input}
                                                    />
                                                    <span />
                                                </label>
                                            </div>
                                        </div>

                                        <div
                                            className="yacht-source"
                                            style={{ marginTop: '32px' }}
                                        >
                                            <RenderReactSelect
                                                name="ordering"
                                                label="Select source"
                                                placeholder="Select source"
                                                meta={{}}
                                                input={{
                                                    value: this.state.source,
                                                    onChange: (source) => {
                                                        this.setState({
                                                            source
                                                        });
                                                    }
                                                }}
                                                showLabel={false}
                                                clearable={false}
                                                options={[
                                                    {
                                                        value: 'saved_search',
                                                        label:
                                                            'From a Saved Search'
                                                    },
                                                    {
                                                        value:
                                                            'selection_sales',
                                                        label:
                                                            'From sales seletion'
                                                    }
                                                ]}
                                            />

                                            {this.state.source ===
                                                'saved_search' && (
                                                <Field
                                                    name="search_id"
                                                    placeholder="MY SAVED SEARCHES"
                                                    validate={[required]}
                                                    component={
                                                        RenderReactSelect
                                                    }
                                                    onChange={this.getSearchResults.bind(
                                                        this
                                                    )}
                                                    options={
                                                        this.props
                                                            .saved_searches
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-12 text-right mt-4">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={submitting}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            type="button"
                                            color="secondary"
                                            disabled={submitting}
                                            onClick={this.cancelForm.bind(this)}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        createSalesListing: (e) => dispatch(createSalesListing(e)),
        fetchSelectedYachts: (e) => dispatch(fetchSelectedYachts(e, 'charter')),
        fetchSavedSearches: (e) => dispatch(fetchSavedSearches(e)),
        fetchSavedSearchResults: (e) => dispatch(fetchSavedSearchResults(e)),
        changeFieldValue: (field, value) =>
            dispatch(change('manage_destination', field, value)),
        notification: (title, message) => {
            let notificationOpts = {
                position: 'tc',
                autoDismiss: 0,
                title: title,
                message: message
            };

            dispatch(Notifications.error(notificationOpts));
        }
    };
}

function mapStateToProps(state) {
    return {
        user: state.user,
        initialValues: {
            public_title: '',
            internal_title: '',
            yachtIds: [],
            ordering: 'selection_sales'
        },
        saved_searches: state.saved_searches.dropdown.map((val) => {
            return { value: val.id, label: val.name };
        }),
        savedSearchYachts: state.saved_searches.results
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(form(NewSalesListing));
