import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';

import SimpleModal from '../common/components/SimpleModal';

import RenderField from '../common/components/RenderField';
import RenderRedactor from '../common/components/RenderRedactor';
import UploadFileField from '../common/components/UploadFileField';

import DeleteConfirmation from '../common/components/DeleteConfirmation';

import { required } from '../helpers/validate';

const form = reduxForm({
    form: 'upload_material'
});

class DriveManageItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            createModalStatus: false,
            createModalBody: this.createModalBody(),
            fileDetails: {},
            fileUploaded: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            createModalTitle:
                nextProps.item != null
                    ? 'Edit Material'
                    : 'Upload New Material',
            createModalStatus: nextProps.modalStatus,
            createModalBody: this.createModalBody(nextProps.item)
        });

        if (this.props.action !== nextProps.action) {
            let value = nextProps.action === 'edit' ? nextProps.item.name : '';

            let descValue =
                nextProps.action === 'edit' ? nextProps.item.description : '';

            this.props.dispatch(change('upload_material', 'name', value));
            this.props.dispatch(
                change('upload_material', 'description', descValue)
            );
        }
    }

    toggleCreateModal() {
        this.setState({
            createModalStatus:
                this.state.createModalStatus === true ? false : true
        });
    }

    createModalBody(item = null) {
        const { handleSubmit } = this.props;

        if (this.props.action !== 'delete') {
            return (
                <div>
                    <form
                        className="list-form pb-2"
                        onSubmit={handleSubmit(
                            this.createModalHandleSubmit.bind(this)
                        )}
                    >
                        <div className="row justify-content-center">
                            <div className="col-8">
                                <Field
                                    name="name"
                                    type="text"
                                    className="mb-2"
                                    placeholder="Name"
                                    component={RenderField}
                                    readOnly={false}
                                    validate={[required]}
                                />
                            </div>

                            <div className="col-8">
                                <div className="redactor-wrap">
                                    <div
                                        id="description_toobar"
                                        className={'external-toolbar before'}
                                    />
                                </div>
                                <RenderRedactor
                                    hiddenTitle={false}
                                    label="Description"
                                    name="description"
                                    id="description"
                                    toolbarExternal="#description_toobar"
                                    onChange={(value) => {
                                        this.props.dispatch(
                                            change(
                                                'upload_material',
                                                'description',
                                                value
                                            )
                                        );
                                    }}
                                />
                            </div>

                            {this.props.item == null && (
                                <div className="col-8">
                                    <UploadFileField
                                        name="file"
                                        type="single"
                                        fileLabelName="Click here to upload file!"
                                        className="mb-2"
                                        component={RenderField}
                                        readOnly={false}
                                        accept="*"
                                        onUploadFinish={this.onUploadFinish.bind(
                                            this
                                        )}
                                    />
                                </div>
                            )}

                            <div className="col-8">
                                <button
                                    type="submit"
                                    className="btn btn-primary ml-1"
                                >
                                    {item != null ? 'Update' : 'Save'}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            );
        } else {
            return (
                <DeleteConfirmation
                    toggle={this.props.toggleManageModal}
                    action={this.handleDelete.bind(this)}
                    body="You are about to delete item"
                />
            );
        }
    }

    createModalHandleSubmit(values) {
        if (this.props.item == null) {
            if (!this.state.fileUploaded) {
                return false;
            }

            let params = {
                name: values.name,
                description: values.description,
                path: this.state.fileDetails.path,
                type: this.state.fileDetails.filetype,
                filename: this.state.fileDetails.filename
            };

            this.props.onCreateItem(params);
            // this.props.dispatch(change('upload_material', 'name', ''));
        } else {
            let val = Object.assign(values, { id: this.props.item.id });
            this.props.onUpdateItem(val);
        }
    }

    handleDelete() {
        this.props.onDeleteItem(this.props.item.id);
    }

    onUploadFinish(values) {
        this.setState({ fileDetails: values, fileUploaded: true });
    }

    render() {
        return (
            <div>
                <SimpleModal
                    header={this.state.createModalTitle}
                    body={this.state.createModalBody}
                    modalOpen={this.props.modalStatus}
                    toggle={this.props.toggleManageModal}
                    className="modal-lg"
                />
            </div>
        );
    }
}

export default connect()(form(DriveManageItem));
