import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { Field, change, reduxForm, formValueSelector } from 'redux-form';
import Notifications from 'react-notification-system-redux';
import { Button, ModalBody } from 'reactstrap';

import moment from 'moment';

import _without from 'lodash/without';

import _map from 'lodash/map';

import { customerFormValidation } from './_helpers';
import SectionAddress from './SectionAddress';
import SectionCharterClient from './SectionCharterClient';
import SectionCommunicationManagement from './SectionCommunicationManagement';
import SectionDocuments from './SectionDocuments';
import SectionEmailTelephone from './SectionEmailTelephone';
import SectionGeneral from './SectionGeneral';
import SectionSalesHistory from './SectionSalesHistory';
import SectionSocial from './SectionSocial';
import SectionClientSettings from './SectionClientSettings';

import { isBoolean } from '../../helpers/validate';
import { functions } from '../../helpers/functions';

import Header from '../../common/header/Header';
import RenderCustomerCat from '../../common/components/RenderCustomerCat';
import RenderRadio from '../../common/components/RenderRadio';
import RenderCheckbox from '../../common/components/RenderCheckbox';

import SimpleTableActions from '../../common/components/SimpleTableActions';
import SimpleModal from '../../common/components/SimpleModal';

import add from '../../assets/svgs/add.svg';
import customers from '../../assets/svgs/customers.svg';
import editIcon from '../../assets/svgs/edit-account.svg';
import previewIcon from '../../assets/svgs/preview.svg';

import { fetchManageCustomer, fetchCustomer, initCustomer } from './_actions';
import { fetchYachtList } from '../../helpers/common/actions';

import { parseQueryFields, getInitialQueryValues } from './_helpers';

import makeDollar from '../../assets/svgs/make-dollar.svg';

const form = reduxForm({
    form: 'manage_customer',
    enableReinitialize: true
});

const selector = formValueSelector('manage_customer');

const currencyFields = ['price_from', 'price_to'];
const unitFields = ['loa_from', 'loa_to'];

const WAIT_INTERVAL = 450;

const industries = _map(functions.getIndustries(), function (value, key) {
    return { value: value, label: value };
});

const daysArray = functions.getDays(31);
const monthsArray = _map(moment.months(), function (value, key) {
    return { value: key + 1, label: value };
});
const arrayYears = functions.getYearsBackwards(90, false, 17);

class ManageCustomer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            edit: this.props.match.path.includes('edit'),
            menu: {
                parent: customers,
                items: this.initHeaderMenu(
                    props,
                    this.props.location.pathname.includes('edit')
                ),
                backLink:
                    this.props.user_role &&
                    this.props.user_role !== 'broker_charter' &&
                    this.props.user_role !== 'broker_sales'
                        ? '/clients'
                        : this.props.user_role === 'broker_charter'
                        ? '/clients/charters'
                        : '/clients/sales'
            },
            readOnly: false,
            loaded: false,
            documents: [],
            documentsAcc:
                'image/*, .pdf, .xls, .xlsx, .xlr, .ods, .doc, .docx, .odt, .pdf, .txt',
            types: ['photo', 'blueprint', 'document'],
            dropdownList: [],
            openSearch: {
                sales_yacht_name: false,
                ca_sales_yacht_name: false
            },
            modalHeader: 'Create new contact',
            modalOpen: false,
            modalBody: ''
        };
    }

    initHeaderMenu(props, edit) {
        let items = [];
        items.push(
            <NavLink
                exact
                to={'/clients/view/' + props.match.params.id}
                className="contactProposalsMenuLinks"
            >
                {props.initCustomer.first_name
                    ? `${props.initCustomer.first_name} ${props.initCustomer.last_name}`
                    : ''}
            </NavLink>
        );
        items.push(
            <NavLink
                exact
                to={'/clients/proposals/' + props.match.params.id}
                className="contactProposalsMenuLinks"
            >
                Proposal history
            </NavLink>
        );
        if (!props.match.path.includes('create')) {
            items.push({
                link: (
                    <NavLink
                        className="yacht-nav-link"
                        exact
                        to={`/clients/${!edit ? 'edit' : 'view'}/${
                            props.match.params.id
                        }`}
                    >
                        <span>
                            <ReactSVG
                                path={!edit ? editIcon : previewIcon}
                                style={{ width: 16 }}
                            />{' '}
                            {!edit ? 'Edit' : 'View'}
                        </span>
                    </NavLink>
                ),
                className: 'offset-4'
            });
        } else {
            items = [
                <NavLink exact to="/clients/create">
                    Add new contact
                </NavLink>
            ];
        }
        return items;
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    addMedia(urls, name) {
        let params = { urls: urls, type: name };
        params.urls = [...this.state.documents.map((i) => i.url), ...urls];
        this.setState({
            ...this.state,
            documents: params.urls.map((url) => {
                return { url };
            })
        });
    }

    removeMedia(value, index, type) {
        if (value) {
            this.setState({
                documents: _without(this.state.documents, value)
            });
        }
    }

    handleFormSubmit(formProps) {
        let validation = customerFormValidation(
            formProps,
            this.props.initCustomer.id,
            this.state.documents
        );

        validation.params.interest_query = parseQueryFields(
            formProps.interest_query,
            this.props.realRates
        );

        if (!validation.valid) {
            this.props.notification('Error', 'Please insert all fields');
        } else {
            return this.props
                .fetchManageCustomer(validation.params)
                .then((data) => {
                    if (data) {
                        this.cancelForm();
                    }
                });
        }
    }

    cancelForm() {
        if (this.props.match.params && this.props.match.params.id) {
            this.props.history.push(
                `/clients/view/${this.props.match.params.id}`
            );
            window.scrollTo(0, 0);
        } else {
            this.props.history.push(this.state.menu.backLink);
        }
    }

    dateChange(date, field) {
        if (field) {
            this.props.changeFieldValue(field, date.fromDate);
        } else {
            this.props.changeFieldValue('birthday', date.fromDate);
        }
    }

    searchChange(event, type) {
        let params = {
            search: event.target.value
        };

        if (type) {
            params[type] = 1;
        }

        if (event.target.value === '') {
            delete params.search;
            this.setState({
                ...this.state,
                openSearch: {
                    [event.target.name]: false
                }
            });
        }

        clearTimeout(this.timer);

        this.timer = setTimeout(
            () => {
                this.props.fetchYachtList(params).then((data) => {
                    this.setState({
                        ...this.state,
                        openSearch: {
                            [event.target.name]: true
                        },
                        dropdownList: data
                    });
                });
            },

            WAIT_INTERVAL
        );
    }

    onClickList(value, field) {
        this.props.changeFieldValue(field, value.name);
        this.setState({ openSearch: false, dropdownList: [] });
    }

    componentDidMount() {
        this.timer = null;

        if (this.props.match.params && this.props.match.params.id) {
            this.props
                .fetchCustomer({ id: this.props.match.params.id })
                .then((data) => {
                    if (data) {
                        if (this.props.match.path.includes('/view')) {
                            this.setState({ readOnly: true });
                        }

                        this.setState({
                            ...this.state,
                            documents: _map(
                                this.props.initialValues.documents,
                                function (value, key) {
                                    return { url: value };
                                }
                            )
                        });

                        this.setState({ loaded: true });
                    }
                });
        } else {
            this.setState({ loaded: true });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            menu: {
                ...this.state.menu,
                items: this.initHeaderMenu(nextProps, this.state.edit)
            }
        });
    }

    componentWillUnmount() {
        this.props.initCustomerView({});
    }

    handleRadioChange(event) {
        if (
            (this.props.sales === '0' || this.props.sales === 'false') &&
            (this.props.chartering === '0' || this.props.chartering === 'false')
        ) {
            this.props.changeFieldValue('sales', '1');
        }
    }

    onCreateProposal() {
        const customer = this.props.initCustomer;
        if (customer.sales === 1 && customer.chartering === 1) {
            // show modal
            this.setState({
                modalHeader: 'Choose type of proposal',
                modalOpen: true,
                modalBody: (
                    <ModalBody>
                        <div className="row justify-content-center my-2">
                            <div className="col-4">
                                <NavLink
                                    className="btn btn-primary"
                                    to={`/clients/proposals/${customer.id}/sales/create`}
                                >
                                    <ReactSVG
                                        path={makeDollar}
                                        style={{
                                            width: 26
                                        }}
                                    />
                                    Sales Proposal
                                </NavLink>
                            </div>
                            <div className="col-4">
                                <NavLink
                                    className="btn btn-primary"
                                    to={`/clients/proposals/${customer.id}/charter/create`}
                                >
                                    <ReactSVG
                                        path={makeDollar}
                                        style={{
                                            width: 26
                                        }}
                                    />
                                    Charter Proposal
                                </NavLink>
                            </div>
                        </div>
                    </ModalBody>
                )
            });
            return;
        }

        const type = customer.sales === 1 ? 'sales' : 'charter';
        const pathname = `/clients/proposals/${customer.id}/${type}/create`;

        this.props.history.push({
            pathname: pathname
        });
    }

    render() {
        const { handleSubmit, submitting } = this.props;

        return (
            <div className="content-inner-wrapper">
                <Header menu={this.state.menu} history={this.props.history} />

                <div className="content-inner">
                    <div className="row">
                        <div className="col-12 col-xl-8">
                            {this.props.initCustomer.id && (
                                <SimpleTableActions
                                    label="Create Proposal"
                                    icon={add}
                                    onClick={() => this.onCreateProposal()}
                                />
                            )}

                            {this.state.loaded && (
                                <form
                                    className="list-form"
                                    onSubmit={handleSubmit(
                                        this.handleFormSubmit.bind(this)
                                    )}
                                >
                                    <div className="row seperator-line">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-2">
                                                    <Field
                                                        name="customer_type"
                                                        label="Client"
                                                        group="customer_type_current"
                                                        className="mb-2 mt-1"
                                                        type="radio"
                                                        readOnly={
                                                            this.state.readOnly
                                                        }
                                                        disabled={
                                                            this.state.readOnly
                                                        }
                                                        onChange={this.handleRadioChange.bind(
                                                            this
                                                        )}
                                                        component={RenderRadio}
                                                        value="client"
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <Field
                                                        name="customer_type"
                                                        label="Broker"
                                                        group="customer_type_current"
                                                        className="mb-2 mt-1"
                                                        type="radio"
                                                        readOnly={
                                                            this.state.readOnly
                                                        }
                                                        disabled={
                                                            this.state.readOnly
                                                        }
                                                        onChange={this.handleRadioChange.bind(
                                                            this
                                                        )}
                                                        component={RenderRadio}
                                                        value="broker"
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <Field
                                                        name="customer_type"
                                                        label="Industry Professional"
                                                        group="customer_type_current"
                                                        className="mb-2 mt-1"
                                                        type="radio"
                                                        readOnly={
                                                            this.state.readOnly
                                                        }
                                                        disabled={
                                                            this.state.readOnly
                                                        }
                                                        component={RenderRadio}
                                                        value="industry"
                                                    />
                                                </div>
                                            </div>
                                            {this.props.customer_type !==
                                                'industry' && (
                                                <RenderCustomerCat
                                                    disabled={
                                                        this.state.readOnly
                                                    }
                                                    chartering={
                                                        this.props.chartering
                                                    }
                                                    sales={this.props.sales}
                                                    category={
                                                        this.props.user_role
                                                    }
                                                    changeFieldValue={this.props.changeFieldValue.bind(
                                                        this
                                                    )}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    {((!this.props.match.path.includes(
                                        'view'
                                    ) &&
                                        this.props.customer_type === 'client' &&
                                        this.props.sales == 1 &&
                                        this.props.user_role !==
                                            'broker_charter') ||
                                        (this.props.match.path.includes(
                                            'view'
                                        ) &&
                                            this.props.sales == 1 &&
                                            (this.props.sales_agreement ||
                                                this.props.wants_to_buy ||
                                                this.props.wants_to_sell))) && (
                                        <div className="row mt-2">
                                            <div className="col-4">
                                                <Field
                                                    name="sales_agreement"
                                                    label="CA Sales Agreement"
                                                    group="sales"
                                                    type="checkbox"
                                                    normalize={isBoolean}
                                                    component={RenderCheckbox}
                                                    disabled={
                                                        this.state.readOnly
                                                    }
                                                    className="mb-0"
                                                />
                                            </div>
                                            <div className="col-3">
                                                <Field
                                                    name="wants_to_buy"
                                                    label="Wants to Buy"
                                                    group="wants_to_buy"
                                                    type="checkbox"
                                                    normalize={isBoolean}
                                                    component={RenderCheckbox}
                                                    disabled={
                                                        this.state.readOnly
                                                    }
                                                    className="mb-0"
                                                />
                                            </div>
                                            <div className="col-3">
                                                <Field
                                                    name="wants_to_sell"
                                                    label="Wants to Sell"
                                                    group="wants_to_sell"
                                                    type="checkbox"
                                                    normalize={isBoolean}
                                                    component={RenderCheckbox}
                                                    disabled={
                                                        this.state.readOnly
                                                    }
                                                    className="mb-0"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {((!this.props.match.path.includes(
                                        'view'
                                    ) &&
                                        this.props.customer_type === 'client' &&
                                        this.props.sales == 1) ||
                                        (this.props.match.path.includes(
                                            'view'
                                        ) &&
                                            (this.props.sales_agreement ||
                                                this.props.wants_to_buy ||
                                                this.props.wants_to_sell))) && (
                                        <div className="row seperator-line pb-2">
                                            <SectionClientSettings
                                                readOnly={this.state.readOnly}
                                                user_role={this.props.user_role}
                                                sales_agreement={
                                                    this.props.sales_agreement
                                                }
                                                wants_to_buy={
                                                    this.props.wants_to_buy
                                                }
                                                wants_to_sell={
                                                    this.props.wants_to_sell
                                                }
                                                dateChange={this.dateChange.bind(
                                                    this
                                                )}
                                                ca_sales_termination_date={
                                                    this.props
                                                        .ca_sales_termination_date
                                                }
                                                initialValues={
                                                    this.props.enQuiries
                                                }
                                                changeFieldValue={this.props.changeFieldValue.bind(
                                                    this
                                                )}
                                                dropdownList={
                                                    this.state.dropdownList
                                                }
                                                searchChange={this.searchChange.bind(
                                                    this
                                                )}
                                                onClickList={this.onClickList.bind(
                                                    this
                                                )}
                                                openSearch={
                                                    this.state.openSearch
                                                }
                                                new_tag={this.props.new_tag}
                                            />
                                        </div>
                                    )}
                                    <div className="row mt-2">
                                        <SectionDocuments
                                            readOnly={this.state.readOnly}
                                            documents={this.state.documents}
                                            addMedia={this.addMedia.bind(this)}
                                            removeMedia={this.removeMedia.bind(
                                                this
                                            )}
                                        />
                                    </div>
                                    <div className="row mt-1">
                                        <SectionGeneral
                                            readOnly={this.state.readOnly}
                                            industries={industries}
                                            gender={this.props.gender}
                                            favourite={this.props.favourite}
                                            daysArray={daysArray}
                                            monthsArray={monthsArray}
                                            arrayYears={arrayYears}
                                        />
                                    </div>
                                    <div className="row">
                                        <SectionEmailTelephone
                                            primary_email={
                                                this.props.primary_email
                                            }
                                            changeFieldValue={this.props.changeFieldValue.bind(
                                                this
                                            )}
                                            initialValues={
                                                this.props.initialValues
                                            }
                                            primary_phone={
                                                this.props.primary_phone
                                            }
                                            readOnly={this.state.readOnly}
                                        />
                                    </div>
                                    <div className="row mt-2">
                                        <SectionAddress
                                            readOnly={this.state.readOnly}
                                            changeFieldValue={this.props.changeFieldValue.bind(
                                                this
                                            )}
                                            initialValues={
                                                this.props.initialValues
                                            }
                                            primary_address={
                                                this.props.primary_address
                                            }
                                            countries={this.props.countries}
                                            states={this.props.states}
                                        />
                                    </div>
                                    <div className="row mt-2">
                                        <SectionSocial
                                            readOnly={this.state.readOnly}
                                            changeFieldValue={this.props.changeFieldValue.bind(
                                                this
                                            )}
                                            initialValues={
                                                this.props.initialValues
                                            }
                                        />
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <SectionCommunicationManagement
                                                boatShowsOther={
                                                    this.props.boatShowsOther
                                                }
                                                communicationMaterialOther={
                                                    this.props
                                                        .communicationMaterialOther
                                                }
                                                readOnly={this.state.readOnly}
                                            />
                                        </div>
                                    </div>
                                    {this.props.chartering === '1' &&
                                        this.props.customer_type !==
                                            'industry' && (
                                            <div className="row mt-2">
                                                <div className="col-12">
                                                    <SectionCharterClient
                                                        changeFieldValue={this.props.changeFieldValue.bind(
                                                            this
                                                        )}
                                                        readOnly={
                                                            this.state.readOnly
                                                        }
                                                        initialValues={
                                                            this.props
                                                                .charterHistory
                                                        }
                                                        dropdownList={
                                                            this.state
                                                                .dropdownList
                                                        }
                                                        searchChange={this.searchChange.bind(
                                                            this
                                                        )}
                                                        onClickList={this.onClickList.bind(
                                                            this
                                                        )}
                                                        openSearch={
                                                            this.state
                                                                .openSearch
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    {this.props.sales === '1' &&
                                        this.props.customer_type !==
                                            'industry' && (
                                            <div className="row mt-2">
                                                <div className="col-12">
                                                    <SectionSalesHistory
                                                        changeFieldValue={this.props.changeFieldValue.bind(
                                                            this
                                                        )}
                                                        readOnly={
                                                            this.state.readOnly
                                                        }
                                                        dropdownList={
                                                            this.state
                                                                .dropdownList
                                                        }
                                                        searchChange={this.searchChange.bind(
                                                            this
                                                        )}
                                                        onClickList={this.onClickList.bind(
                                                            this
                                                        )}
                                                        openSearch={
                                                            this.state
                                                                .openSearch
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    <div className="row">
                                        {this.state.readOnly === false && (
                                            <div className="col-12 mt-3 text-right">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    disabled={submitting}
                                                >
                                                    Save
                                                </Button>{' '}
                                                <Button
                                                    type="button"
                                                    color="secondary"
                                                    disabled={submitting}
                                                    onClick={this.cancelForm.bind(
                                                        this
                                                    )}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>

                <SimpleModal
                    header={this.state.modalHeader}
                    body={this.state.modalBody}
                    modalOpen={this.state.modalOpen}
                    toggle={this.toggleModal.bind(this)}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change('manage_customer', field, value)),
        fetchManageCustomer: (e) => dispatch(fetchManageCustomer(e)),
        fetchCustomer: (e) => dispatch(fetchCustomer(e)),
        initCustomerView: (e) => dispatch(initCustomer(e)),
        fetchYachtList: (e) => dispatch(fetchYachtList(e)),
        notification: (title, message) => {
            let notificationOpts = {
                position: 'tc',
                autoDismiss: 0,
                title: title,
                message: message
            };

            dispatch(Notifications.error(notificationOpts));
        }
    };
}

function mapStateToProps(state, ownProps) {
    let initialValues = {};
    let readOnly = false;
    if (ownProps.match.path.indexOf('/clients/view') !== -1) {
        readOnly = true;
    }

    if (
        state.customers.customers.initCustomer &&
        state.customers.customers.initCustomer.id
    ) {
        let data = state.customers.customers.initCustomer;
        Object.keys(data).forEach((key, index) => {
            if (key === 'chartering' || key === 'sales') {
                initialValues[key] = data[key] === 1 ? true : false;
            } else if (key === 'interests') {
                Object.keys(data[key]).map((item, keyItem) => {
                    if (data[key][item] || item === 'loa_unit') {
                        if (
                            item !== 'loa_unit' &&
                            item !== 'id' &&
                            item !== 'customer_id'
                        ) {
                            initialValues['wants_to_buy'] =
                                data[key][item] !== null ? true : false;
                        }
                        initialValues[item] =
                            item !== 'loa_unit' ? data[key][item] : 'meters';
                    }

                    return item;
                });
            } else if (key === 'favourite')
                initialValues.favourite =
                    data.favourite && data.favourite === 1 ? true : false;
            else if (
                key === 'emails' ||
                key === 'phones' ||
                key === 'addresses'
            ) {
                let temp = data[key].map((item, keyItem) => {
                    if (item.primary && item.primary === 1) {
                        if (key === 'phones') {
                            initialValues.primary_phone = String(keyItem);
                        } else if (key === 'emails') {
                            initialValues.primary_email = String(keyItem);
                        } else if (key === 'addresses') {
                            initialValues.primary_address = String(keyItem);
                        }
                    }
                    return item;
                });

                if (key === 'addresses') {
                    if (data[key].length === 0) {
                        initialValues['primary_address'] = '0';
                    }

                    data[key].map((value) => {
                        if (value.country) {
                            value.country = parseInt(value.country);
                        }

                        if (value.state) {
                            value.state = parseInt(value.state);
                        }
                        return value;
                    });
                }

                if (key === 'phones') {
                    if (!initialValues.primary_phone) {
                        initialValues.primary_phone = '0';
                    }
                }

                initialValues[key] = temp;
            } else if (data[key] && data[key] !== null) {
                initialValues[key] = data[key];

                if (
                    key === 'sales_remind_weeks' ||
                    key === 'sales_yacht_name'
                ) {
                    initialValues['wants_to_sell'] = true;
                }

                if (
                    key === 'ca_sales_yacht_name' ||
                    key === 'ca_sales_termination_date' ||
                    key === 'ca_sales_remind_weeks' ||
                    (data['enquiries'] && data['enquiries'].length > 0)
                ) {
                    initialValues['sales_agreement'] = true;
                }

                if (key === 'interest_query') {
                    if (initialValues[key].length > 0) {
                        initialValues['wants_to_buy'] = true;
                    }
                }
            }

            let cur = currencyFields.indexOf(key);
            if (cur !== -1) {
                if (readOnly) {
                    initialValues[key] = functions.currency(
                        data[key],
                        data[key + '_currency'],
                        readOnly
                    );
                } else {
                    initialValues[key] = functions.thousands(
                        functions.currency(
                            data[key],
                            data[key + '_currency'],
                            readOnly
                        )
                    );
                }
            }

            let unit = unitFields.indexOf(key);
            if (unit !== -1) {
                initialValues[key] = functions.unit(
                    data[key],
                    data[key + '_unit'],
                    readOnly
                );
            }
        });

        if (data.other_show && data.other_show.length !== 0) {
            initialValues.boat_shows_other = true;
        }

        if (data.other_material && data.other_material.length !== 0) {
            initialValues.communication_material_other = true;
        }

        if (data.interest_query) {
            const q = JSON.parse(data.interest_query);
            initialValues.interest_query = getInitialQueryValues(q);
        }
    } else {
        initialValues = {
            chartering: state.auth.user_role === 'broker_sales' ? '0' : '1',
            sales: '1',
            monaco_yacht_show: false,
            cannes_festival: false,
            flibs: false,
            miami_boat_show: false,
            palm_beach_boat_show: false,
            yacht_collection: false,
            horizons_magazine: false,
            christmas_card_print: false,
            christmas_card_electronic: false,
            gift: false,
            other_show: '',
            other_material: '',
            wants_to_buy: false,
            sales_agreement: false,
            wants_to_sell: false,
            customer_type: 'client',
            emails: [],
            phones: [],
            enquiries: [],
            primary_email: '0',
            primary_phone: '0',
            primary_address: '0',
            loa_unit: 'meters',
            price_currency: 'usd',
            documents: [],
            way_of_address: 'none',
            interest_query: getInitialQueryValues()
        };
    }

    return {
        user: state.user,
        customers: state.customers.customers,
        initCustomer: state.customers.customers.initCustomer,
        initialValues: initialValues,
        gender: selector(state, 'gender'),
        chartering: selector(state, 'chartering'),
        sales: selector(state, 'sales'),
        favourite: selector(state, 'favourite'),
        new_tag: selector(state, 'new_tag'),
        birthday: selector(state, 'birthday'),
        boatShowsOther: selector(state, 'boat_shows_other'),
        communicationMaterialOther: selector(
            state,
            'communication_material_other'
        ),
        charterHistory: selector(state, 'charter_history'),
        enQuiries: selector(state, 'enquiries'),
        currencyRates: state.rates.rates.rates,
        user_role: state.auth.user_role,
        wants_to_buy: selector(state, 'wants_to_buy'),
        sales_agreement: selector(state, 'sales_agreement'),
        wants_to_sell: selector(state, 'wants_to_sell'),
        wants_to_sell_yacht_name: selector(state, 'wants_to_sell_yacht_name'),
        countries: state.common.countries.map((val) => {
            return { value: val.id, label: val.name };
        }),
        states: state.common.states.map((val) => {
            return { value: val.id, label: val.name };
        }),
        customer_type: selector(state, 'customer_type'),
        primary_email: selector(state, 'primary_email'),
        primary_phone: selector(state, 'primary_phone'),
        primary_address: selector(state, 'primary_address'),
        ca_sales_termination_date: selector(state, 'ca_sales_termination_date'),
        addresses: selector(state, 'addresses'),
        realRates: state.rates.rates.rates
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(form(ManageCustomer));
