import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';

import Header from '../common/header/Header';
import Widget from '../common/Widget';
import SimpleTable from '../common/components/SimpleTable';
import NewsFeedItem from '../common/components/NewsFeedItem';
import StackedBar from '../common/components/StackedBar';

import {
    fetchPriceReductions,
    fetchReportsYachts,
    fetchYachtsSold,
    getYearlySales,
    hideSoldYachts,
    hideReductionYachts,
    hideNewestYachts
} from '../reports/_actions';
import { fetchNews } from '../news/_actions';

import { functions } from '../helpers/functions';

import ribbon_label from '../assets/svgs/ribbon_label.svg';
import dashboard_icon from '../assets/svgs/dashboard_icon.svg';
import RenderCheckbox from '../common/components/RenderCheckbox';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                searchLabel: 'Search Yacht name, Model, or any keyword',
                searchFunctionCol: 5,
                parent: dashboard_icon,
                items: [
                    <NavLink
                        isActive={() =>
                            props.history.location.pathname === '/dashboard'
                        }
                        exact={true}
                        to="/dashboard"
                    >
                        DASHBOARD
                    </NavLink>
                ]
            },
            sliderSettings: {
                dots: false,
                infinite: props.newsFeed.data.length >= 5,
                slidesToShow:
                    props.newsFeed.data.length >= 4
                        ? 4
                        : props.newsFeed.data.length,
                autoplay: props.newsFeed.data.length >= 5,
                speed: 3000,
                autoplaySpeed: 3000,
                swipe: false,
                draggable: false,
                cssEase: 'linear',
                onInit: this.fetchData('newsFeed')
            },
            sliderShowing: true,
            yachts_sold: {},
            new_entries: {},
            reductions: {},
            tables: [
                {
                    title: 'Latest Sold Yachts',
                    subtitle: '',
                    isTable: true,
                    hasColumns: 2,
                    componentName: 'yachts_sold',
                    headers: [
                        {
                            name: 'hideBroker',
                            label: ' ',
                            sort: false,
                            defaultSort: ''
                        },
                        {
                            name: 'created_at',
                            label: 'DATE',
                            sort: true,
                            defaultSort: 'DESC'
                        },
                        {
                            name: 'name',
                            label: 'YACHT',
                            sort: true,
                            defaultSort: 'ASC'
                        },
                        {
                            name: 'loa',
                            label: 'LENGTH',
                            sort: true,
                            defaultSort: 'ASC'
                        },
                        {
                            name: 'year_built',
                            label: 'BUILD',
                            sort: true,
                            defaultSort: 'ASC'
                        },
                        {
                            name: 'builder',
                            label: 'BUILDER',
                            sort: true,
                            defaultSort: 'ASC'
                        },
                        {
                            name: 'asking_price_displayed',
                            label: 'LAST ASKING PRICE',
                            sort: true,
                            defaultSort: 'ASC'
                        }
                    ],
                    colWidths: ['-1', '1', '3', '2', '1', '2', '2', '1']
                },
                {
                    title: 'Latest Yachts Listed For Sale',
                    subtitle: '',
                    isTable: true,
                    hasColumns: 1,
                    componentName: 'new_entries',
                    headers: [
                        {
                            name: 'hideBroker',
                            label: ' ',
                            sort: false,
                            defaultSort: ''
                        },
                        {
                            name: 'created_at',
                            label: 'DATE',
                            sort: true,
                            defaultSort: 'DESC'
                        },
                        {
                            name: 'name',
                            label: 'YACHT',
                            sort: true,
                            defaultSort: 'ASC'
                        },
                        {
                            name: 'loa',
                            label: 'LENGTH',
                            sort: true,
                            defaultSort: 'ASC'
                        },
                        {
                            name: 'year_built',
                            label: 'BUILD',
                            sort: true,
                            defaultSort: 'ASC'
                        },
                        {
                            name: 'builder',
                            label: 'BUILDER',
                            sort: true,
                            defaultSort: 'ASC'
                        },
                        {
                            name: 'asking_price_displayed',
                            label: 'LAST ASKING PRICE',
                            sort: true,
                            defaultSort: 'ASC'
                        },
                        {
                            name: 'broker',
                            label: 'BROKER',
                            sort: false
                        }
                    ],
                    colWidths: ['-1', '1', '2', '2', '1', '2', '2', '1', '1']
                },
                {
                    title: 'Price Changes',
                    subtitle: '',
                    isTable: true,
                    hasColumns: 2,
                    componentName: 'reductions',
                    headers: [
                        {
                            name: 'hideBroker',
                            label: ' ',
                            sort: false,
                            defaultSort: ''
                        },
                        {
                            name: 'created_at',
                            label: 'DATE',
                            sort: true,
                            defaultSort: 'DESC'
                        },
                        {
                            name: 'name',
                            label: 'YACHT',
                            sort: true,
                            defaultSort: 'ASC'
                        },
                        {
                            name: 'loa',
                            label: 'LENGTH',
                            sort: true,
                            defaultSort: 'ASC'
                        },
                        {
                            name: 'year_built',
                            label: 'BUILD',
                            sort: true,
                            defaultSort: 'ASC'
                        },
                        {
                            name: 'builder',
                            label: 'BUILDER',
                            sort: true,
                            defaultSort: 'ASC'
                        },
                        {
                            name: 'price_before',
                            label: 'PREVIOUS PRICE',
                            sort: true,
                            defaultSort: 'ASC'
                        },
                        {
                            name: 'price_after',
                            label: 'CURRENT PRICE',
                            sort: true,
                            defaultSort: 'ASC'
                        },
                        {
                            name: 'percentage',
                            label: 'DIF',
                            sort: true,
                            defaultSort: 'ASC'
                        }
                    ],
                    colWidths: ['-1', '1', '1', '2', '1', '1', '2', '2', '1']
                }
            ],
            colWidths: ['2', '2', '2', '2', '2', '2', '2', '2', '2'],
            newsFeed: [],
            chartData: {
                datasets: [],
                labels: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec'
                ]
            }
        };

        this.fetchData = this.fetchData.bind(this);
        this.renderRowData = this.renderRowData.bind(this);
        this.sort = this.sort.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.getMonthsAndData = this.getMonthsAndData.bind(this);
        this.toggleSlider = this.toggleSlider.bind(this);
        this.getChart = this.getChart.bind(this);

        this.toggleHidedata = this.toggleHidedata.bind(this);
    }

    fetchData(type, params) {
        if (type === 'yachts_sold') {
            this.props.fetchYachtsSold(params);
        }

        if (type === 'reductions') {
            this.props.fetchPriceReductions(params);
        }

        if (type === 'new_entries') {
            this.props.fetchReportsYachts('new_entries', params);
        }

        if (type === 'newsFeed') {
            this.props.fetchNews({ public: 1 }).then((response) => {
                this.setState({
                    newsFeed: response ? response : [],
                    sliderSettings: {
                        dots: false,
                        infinite: this.props.newsFeed.data.length >= 4,
                        slidesToShow:
                            this.props.newsFeed.data.length >= 4
                                ? 4
                                : this.props.newsFeed.data.length,
                        autoplay: this.props.newsFeed.data.length >= 4,
                        speed: 3000,
                        autoplaySpeed: 3000,
                        swipe: false,
                        draggable: false,
                        cssEase: 'linear'
                    }
                });
            });
        }
    }

    sort(key, newValue, componentName) {
        let params = {};

        params.order_field = key;
        params.order_dir = newValue;
        params.page = key !== 'page' ? 1 : newValue;
        params.page_size = 6;

        this.setState({
            [componentName]: {
                order_field: key,
                order_dir: newValue
            }
        });

        if (componentName === 'yachts_sold') {
            this.fetchData('yachts_sold', params);
        }
        if (componentName === 'reductions') {
            this.fetchData('reductions', params);
        }
        if (componentName === 'new_entries') {
            this.fetchData('new_entries', params);
        }
    }

    getMonthsAndData(dataset) {
        const object = dataset.reduce(function (accumulator, currentValue) {
            accumulator[currentValue.sales_month] = currentValue.sales_count;
            return accumulator;
        }, {});

        let data = [];
        for (let i = 1; i <= 12; i++) {
            if (object[i] === undefined) {
                data.push(0);
            } else {
                data.push(object[i]);
            }
        }

        return data;
    }

    componentDidMount() {
        if (this.props.user_role === 'broker_charter') {
            this.props.history.push('/yachts/charter');
        }
        const params = {
            order_field: 'created_at',
            order_dir: 'DESC',
            page_size: 6,
            page: 1
        };

        this.fetchData('yachts_sold', params);
        this.fetchData('reductions', params);
        this.fetchData('new_entries', params);

        this.props.getYearlySales().then((response) => {
            if (response) {
                const short = {
                    backgroundColor: '#F7F7F8',
                    data: this.getMonthsAndData(response.short),
                    fill: false,
                    label: '25-50',
                    type: 'bar'
                };

                const medium = {
                    backgroundColor: '#D8DDE5',
                    data: this.getMonthsAndData(response.medium),
                    fill: false,
                    label: '51-70',
                    type: 'bar'
                };

                const long = {
                    backgroundColor: '#AAB4C7',
                    data: this.getMonthsAndData(response.long),
                    fill: false,
                    label: '71-100',
                    type: 'bar'
                };

                const other = {
                    backgroundColor: '#41506E',
                    data: this.getMonthsAndData(response.other),
                    fill: false,
                    label: '101+',
                    type: 'bar'
                };

                this.setState({
                    chartData: {
                        ...this.state.chartData,
                        datasets: [short, medium, long, other]
                    }
                });
            }
        });
    }

    loadMore(componentName) {
        let params = {};
        const tableKey = this.props[componentName];

        if (
            tableKey.pagination &&
            tableKey.pagination.page < tableKey.pagination.page_count
        ) {
            params.page = tableKey.pagination.page + 1;
            params.order_field = this.state[componentName].order_field
                ? this.state[componentName].order_field
                : tableKey.sorting;
            params.order_dir = this.state[componentName].order_dir
                ? this.state[componentName].order_dir
                : tableKey.order;
            params.page_size = 6;

            this.fetchData(componentName, params);
        }
    }

    toggleSlider() {
        this.setState({
            sliderShowing: !this.state.sliderShowing
        });
    }

    getChart(componentName) {
        const components = {
            yachts_sold: (
                <StackedBar
                    data={this.state.chartData}
                    height={150}
                    width={150}
                />
            ),
            reductions: (
                <Pie
                    height={180}
                    data={this.props.reductions.chartData}
                    options={{ legend: { labels: { boxWidth: 12 } } }}
                />
            )
        };

        return components[componentName];
    }

    toggleHidedata(e, yacht, type) {
        let params = {
            entry_id: yacht.entry_id,
            broker_visible: !yacht.broker_visible
        };

        const tableKey = this.props[type];
        if (type === 'yachts_sold') {
            params.target = 'status_change';
            this.props.hideSoldYachts(params);
        } else if (type === 'new_entries') {
            params.target = 'status_change';
            this.props.hideNewestYachts(params);
        } else if (type === 'reductions') {
            params.target = 'reduction';
            this.props.hideReductionYachts(params);
        }
    }

    renderRowData(component) {
        // Map through each prop and create an array of elements tha will get passed as props to SimpleTable and will be rendered
        const componentsToRender = {
            yachts_sold: this.props.yachts_sold.data.map((yacht) => {
                return yacht
                    ? [
                          //checkbox visibility to broker
                          !functions.isBroker(this.props.user_role) && (
                              <div
                                  className={
                                      functions.isBroker(
                                          this.props.user_role
                                      ) && yacht.broker_visible === false
                                          ? 'd-none'
                                          : 'gray-field'
                                  }
                              >
                                  <RenderCheckbox
                                      onDashboard={true}
                                      input={{
                                          checked: yacht.broker_visible,
                                          value: yacht.broker_visible,
                                          name: `sold_${yacht.entry_id}`,
                                          onChange: (e) => {
                                              this.toggleHidedata(
                                                  e,
                                                  yacht,
                                                  'yachts_sold'
                                              );
                                          }
                                      }}
                                  />
                              </div>
                          ),
                          <div
                              className={
                                  functions.isBroker(this.props.user_role) &&
                                  yacht.broker_visible === false
                                      ? 'd-none'
                                      : 'gray-field'
                              }
                          >
                              {yacht.created_at
                                  ? moment(yacht.created_at).format(
                                        'DD/MM/YYYY'
                                    )
                                  : '-'}
                          </div>,
                          <div
                              className={
                                  functions.isBroker(this.props.user_role) &&
                                  yacht.broker_visible === false
                                      ? 'd-none'
                                      : ''
                              }
                          >
                              <NavLink
                                  exact
                                  to={`/yachts/view/sales/${yacht.yacht_id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                              >
                                  {yacht.name ? yacht.name : ''}
                              </NavLink>
                          </div>,
                          <div
                              className={
                                  functions.isBroker(this.props.user_role) &&
                                  yacht.broker_visible === false
                                      ? 'd-none'
                                      : 'gray-field'
                              }
                          >
                              {yacht.loa
                                  ? functions.unit(
                                        yacht.loa,
                                        'meters',
                                        'meters_only'
                                    )
                                  : '-'}
                              <span>
                                  &nbsp;{' '}
                                  {yacht.loa
                                      ? `(${functions.unit(
                                            yacht.loa,
                                            'feet',
                                            'feet_only'
                                        )})`
                                      : '-'}
                              </span>
                          </div>,
                          <div
                              className={
                                  functions.isBroker(this.props.user_role) &&
                                  yacht.broker_visible === false
                                      ? 'd-none'
                                      : 'gray-field'
                              }
                          >
                              {yacht.year_built && yacht.year_built !== 0
                                  ? yacht.year_built
                                  : '-'}
                          </div>,
                          <div
                              className={
                                  functions.isBroker(this.props.user_role) &&
                                  yacht.broker_visible === false
                                      ? 'd-none'
                                      : ''
                              }
                          >
                              {yacht.builder}
                          </div>,
                          <div
                              className={
                                  functions.isBroker(this.props.user_role)
                                      ? 'd-none'
                                      : ''
                              }
                          >
                              {yacht.asking_price_displayed
                                  ? yacht.asking_price_currency
                                      ? functions.currency(
                                            yacht.asking_price_displayed,
                                            yacht.asking_price_currency.toLowerCase(),
                                            true
                                        )
                                      : yacht.asking_price_displayed
                                  : '-'}
                          </div>
                      ]
                    : [];
            }),
            new_entries:
                this.props.new_entries && this.props.new_entries.data.length > 0
                    ? this.props.new_entries.data.map((yacht) => {
                          return yacht.name ||
                              yacht.asking_price_displayed ||
                              yacht.asking_price_currency ||
                              yacht.year_built
                              ? [
                                    //checkbox visibility to broker
                                    !functions.isBroker(
                                        this.props.user_role
                                    ) && (
                                        <div
                                            className={
                                                functions.isBroker(
                                                    this.props.user_role
                                                ) &&
                                                yacht.broker_visible === false
                                                    ? 'd-none'
                                                    : 'gray-field'
                                            }
                                        >
                                            <RenderCheckbox
                                                onDashboard={true}
                                                input={{
                                                    checked:
                                                        yacht.broker_visible,
                                                    value: yacht.broker_visible,
                                                    name: `new_entries_${yacht.entry_id}`,
                                                    onChange: (e) =>
                                                        this.toggleHidedata(
                                                            e,
                                                            yacht,
                                                            'new_entries'
                                                        )
                                                }}
                                            />
                                        </div>
                                    ),
                                    <div
                                        className={
                                            functions.isBroker(
                                                this.props.user_role
                                            ) && yacht.broker_visible === false
                                                ? 'd-none'
                                                : 'gray-field'
                                        }
                                    >
                                        {yacht.created_at
                                            ? moment(yacht.created_at).format(
                                                  'DD/MM/YYYY'
                                              )
                                            : '-'}
                                    </div>,
                                    <div
                                        className={
                                            functions.isBroker(
                                                this.props.user_role
                                            ) && yacht.broker_visible === false
                                                ? 'd-none'
                                                : ''
                                        }
                                    >
                                        <NavLink
                                            exact
                                            to={`/yachts/view/sales/${yacht.id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {yacht.name ? yacht.name : ''}
                                        </NavLink>
                                    </div>,
                                    <div
                                        className={
                                            functions.isBroker(
                                                this.props.user_role
                                            ) && yacht.broker_visible === false
                                                ? 'd-none'
                                                : 'gray-field'
                                        }
                                    >
                                        {yacht.loa
                                            ? yacht.loa.toString() + 'm'
                                            : '-'}
                                        <span>
                                            &nbsp;{' '}
                                            {yacht.loa
                                                ? `(${functions.unit(
                                                      yacht.loa,
                                                      'feet',
                                                      'feet_only'
                                                  )})`
                                                : '-'}
                                        </span>
                                    </div>,
                                    <div
                                        className={
                                            functions.isBroker(
                                                this.props.user_role
                                            ) && yacht.broker_visible === false
                                                ? 'd-none'
                                                : 'gray-field'
                                        }
                                    >
                                        {yacht.year_built &&
                                        yacht.year_built !== 0
                                            ? yacht.year_built
                                            : '-'}
                                    </div>,
                                    <div
                                        className={
                                            functions.isBroker(
                                                this.props.user_role
                                            ) && yacht.broker_visible === false
                                                ? 'd-none'
                                                : ''
                                        }
                                    >
                                        {yacht.builder ? yacht.builder : '-'}
                                    </div>,
                                    <div
                                        className={
                                            functions.isBroker(
                                                this.props.user_role
                                            ) && yacht.broker_visible === false
                                                ? 'd-none'
                                                : ''
                                        }
                                    >
                                        {yacht.asking_price_displayed
                                            ? yacht.asking_price_currency
                                                ? functions.currency(
                                                      yacht.asking_price_displayed,
                                                      yacht.asking_price_currency.toLowerCase(),
                                                      true
                                                  )
                                                : yacht.asking_price_displayed
                                            : '-'}
                                    </div>,
                                    <div
                                        className={
                                            functions.isBroker(
                                                this.props.user_role
                                            ) && yacht.broker_visible === false
                                                ? 'd-none'
                                                : ''
                                        }
                                    >
                                        {yacht.sales_brokers.length
                                            ? yacht.sales_brokers.map(
                                                  (broker, i) => (
                                                      <span key={i}>
                                                          {broker.company
                                                              ? `${
                                                                    broker.company
                                                                }${
                                                                    i <
                                                                    yacht
                                                                        .sales_brokers
                                                                        .length -
                                                                        1
                                                                        ? ', '
                                                                        : ''
                                                                }`
                                                              : '-'}
                                                      </span>
                                                  )
                                              )
                                            : '-'}
                                    </div>
                                ]
                              : [];
                      })
                    : [],
            reductions: this.props.reductions.reductions.map((yacht) => {
                return yacht
                    ? [
                          //checkbox visibility to broker
                          !functions.isBroker(this.props.user_role) && (
                              <div
                                  className={
                                      functions.isBroker(
                                          this.props.user_role
                                      ) && yacht.broker_visible === false
                                          ? 'd-none'
                                          : 'gray-field'
                                  }
                              >
                                  <RenderCheckbox
                                      onDashboard={true}
                                      input={{
                                          checked: yacht.broker_visible,
                                          value: yacht.broker_visible,
                                          name: `reductions_${yacht.entry_id}`,
                                          onChange: (e) =>
                                              this.toggleHidedata(
                                                  e,
                                                  yacht,
                                                  'reductions'
                                              )
                                      }}
                                  />
                              </div>
                          ),
                          <div
                              className={
                                  functions.isBroker(this.props.user_role) &&
                                  yacht.broker_visible === false
                                      ? 'd-none'
                                      : 'gray-field'
                              }
                          >
                              {yacht.created_at
                                  ? moment(yacht.created_at).format(
                                        'DD/MM/YYYY'
                                    )
                                  : '-'}
                          </div>,
                          <div
                              className={
                                  functions.isBroker(this.props.user_role) &&
                                  yacht.broker_visible === false
                                      ? 'd-none'
                                      : ''
                              }
                          >
                              <NavLink
                                  exact
                                  to={`/yachts/view/sales/${yacht.yacht_id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                              >
                                  {yacht.name ? yacht.name : ''}
                              </NavLink>
                          </div>,
                          <div
                              className={
                                  functions.isBroker(this.props.user_role) &&
                                  yacht.broker_visible === false
                                      ? 'd-none'
                                      : 'gray-field'
                              }
                          >
                              {yacht.loa
                                  ? functions.unit(
                                        yacht.loa,
                                        'meters',
                                        'meters_only'
                                    )
                                  : '-'}
                              <span>
                                  &nbsp;{' '}
                                  {yacht.loa
                                      ? `(${functions.unit(
                                            yacht.loa,
                                            'feet',
                                            'feet_only'
                                        )})`
                                      : '-'}
                              </span>
                          </div>,
                          <div
                              className={
                                  functions.isBroker(this.props.user_role) &&
                                  yacht.broker_visible === false
                                      ? 'd-none'
                                      : 'gray-field'
                              }
                          >
                              {yacht.year_built && yacht.year_built !== 0
                                  ? yacht.year_built
                                  : '-'}
                          </div>,
                          <div
                              className={
                                  functions.isBroker(this.props.user_role) &&
                                  yacht.broker_visible === false
                                      ? 'd-none'
                                      : ''
                              }
                          >
                              {yacht.builder ? yacht.builder : '-'}
                          </div>,
                          <div
                              className={
                                  functions.isBroker(this.props.user_role) &&
                                  yacht.broker_visible === false
                                      ? 'd-none'
                                      : ''
                              }
                          >
                              {yacht.price_before
                                  ? yacht.currency_before
                                      ? functions.currency(
                                            yacht.price_before,
                                            yacht.currency_before.toLowerCase(),
                                            true
                                        )
                                      : yacht.price_before
                                  : '-'}
                          </div>,
                          <div
                              className={
                                  functions.isBroker(this.props.user_role) &&
                                  yacht.broker_visible === false
                                      ? 'd-none'
                                      : ''
                              }
                          >
                              {yacht.price_after
                                  ? yacht.currency_after
                                      ? functions.currency(
                                            yacht.price_after,
                                            yacht.currency_after.toLowerCase(),
                                            true
                                        )
                                      : yacht.price_after
                                  : '-'}
                          </div>,
                          <div
                              className={
                                  functions.isBroker(this.props.user_role) &&
                                  yacht.broker_visible === false
                                      ? 'd-none'
                                      : ''
                              }
                          >
                              {yacht.percentage
                                  ? yacht.price_before && yacht.price_after
                                      ? yacht.price_before > yacht.price_after
                                          ? '-'
                                          : '+'
                                      : ''
                                  : ''}
                              &nbsp;
                              {yacht.percentage ? `${yacht.percentage}%` : '-'}
                          </div>
                      ]
                    : [];
            })
        };

        return componentsToRender[component];
    }

    render() {
        const { location } = this.props;
        const { tables } = this.state;

        const slidesToShow =
            this.state.newsFeed &&
            this.state.newsFeed.map((news, i) => {
                return (
                    <div key={news.id}>
                        <NewsFeedItem
                            image={
                                functions.getNewsPhoto(news.image_url)
                                    ? functions.getNewsPhoto(news.image_url)
                                          .image
                                    : ''
                            }
                            position={i}
                            image_url={news.image_url}
                            title={news.title}
                            description={news.description}
                            source_url={news.source_url}
                            newsLength={this.state.newsFeed.length}
                            date={moment(news.created_at).format('DD MMM')}
                        />
                    </div>
                );
            });

        return (
            <div>
                <div className="marginBottom80">
                    <Header
                        menu={this.state.menu}
                        history={this.props.history}
                    />
                </div>

                <div
                    className={`row ${
                        functions.isBroker(this.props.user_role) &&
                        this.state.sliderShowing
                            ? 'row marginBottom120'
                            : ''
                    } dashboard-widget-wrapper`}
                >
                    {tables.map((table, i) => (
                        <div key={i} className="col-xl-12 col-md-12 pb-2 ">
                            <Widget
                                title={table.title}
                                user={this.props.user}
                                isTable={table.isTable}
                                hasColumns={table.hasColumns}
                            >
                                <div className="px-1 table-body-wrap">
                                    {table.isTable ? (
                                        <div className="row pt-1">
                                            <div
                                                className={`dashboard-wrapper ${
                                                    table.hasColumns === 2
                                                        ? 'col-xl-9'
                                                        : 'col-xl-9 border-0'
                                                }`}
                                            >
                                                <SimpleTable
                                                    headers={table.headers}
                                                    colWidths={table.colWidths}
                                                    rowData={this.renderRowData(
                                                        table.componentName
                                                    )}
                                                    location={location}
                                                    tableChange={(
                                                        key,
                                                        newValue
                                                    ) =>
                                                        this.sort(
                                                            key,
                                                            newValue,
                                                            table.componentName
                                                        )
                                                    }
                                                    componentName={
                                                        table.componentName
                                                    }
                                                />
                                            </div>
                                            <div
                                                className={`${
                                                    table.hasColumns === 2
                                                        ? 'col-xl-3 d-flex align-items-center'
                                                        : 'd-none'
                                                }`}
                                            >
                                                {this.getChart(
                                                    table.componentName
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <div className="row">
                                        <div className="col-12 load-more-wrapper">
                                            <span
                                                className={`load-more-btn ${
                                                    this.props[
                                                        table.componentName
                                                    ].loading
                                                        ? 'disabled'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    this.loadMore(
                                                        table.componentName
                                                    )
                                                }
                                            >
                                                Load More
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Widget>
                        </div>
                    ))}
                </div>
                {this.state.newsFeed &&
                    functions.isBroker(this.props.user_role) && (
                        <div
                            onClick={this.toggleSlider}
                            className={`col-12 fixed-bottom d-flex slider-wrapper ${
                                this.state.sliderShowing ? '' : 'ribbon-hidden'
                            } ${
                                this.props.isSidebarOpen && 'slider-width'
                            } paddingY24`}
                        >
                            <button className="btn btn-tabs">
                                {!this.state.sliderShowing
                                    ? 'Maximize '
                                    : 'Minimize'}
                                <div
                                    className={`Select-arrow ${
                                        this.state.sliderShowing ? '' : 'rotate'
                                    }`}
                                />
                            </button>
                            <div className="col-1 d-flex justify-content-center newsfeed-label-wrapper">
                                <img height="104" src={ribbon_label} alt="" />
                            </div>
                            <div className="col-11">
                                <Slider {...this.state.sliderSettings}>
                                    {slidesToShow}
                                </Slider>
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        yachts_sold: state.reports.yachts_sold,
        reductions: state.reports.price_reductions,
        new_entries: state.reports.reports_yachts.new_entries,
        yearlySales: state.reports.reports_yachts.yearlySales,
        user_role: state.auth.user_role,
        user: state.user,
        newsFeed: state.news,
        isSidebarOpen: state.common.windowWidth
    };
}

export default connect(mapStateToProps, {
    fetchYachtsSold,
    fetchReportsYachts,
    fetchPriceReductions,
    fetchNews,
    getYearlySales,
    hideSoldYachts,
    hideReductionYachts,
    hideNewestYachts
})(Dashboard);
