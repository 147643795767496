import React, { Component } from 'react';
import UltimatePaginationBootstrap4 from 'react-ultimate-pagination-bootstrap-4';
import TableSearch from '../../components/TableSearch';
class SimplePaginationWrapper extends Component {
    constructor(props) {
        super(props);
        this.onPageChangeFromPagination = this.onPageChangeFromPagination.bind(
            this
        );
        this.handleChange = this.handleChange.bind(this);
    }

    onPageChangeFromPagination(newPage) {
        this.props.pageChangeFunction(
            'page',
            newPage,
            '',
            this.props.yachtStatus,
            '',
            this.props.yachtName,
            this.props.is_public,
            this.props.is_archived,
            this.props.curation_level
        );
    }

    handleChange(event) {
        this.props.pageChangeFunction(
            'page_size',
            event.target.value,
            '',
            this.props.yachtStatus,
            '',
            this.props.yachtName,
            this.props.is_public,
            this.props.is_archived,
            this.props.curation_level
        );
    }

    render() {
        return (
            <div
                className={
                    'pagination-wrapper ' +
                    this.props.className +
                    (this.props.currentPage === 1 ? ' disabled-prev' : '') +
                    (this.props.currentPage === this.props.totalPages
                        ? ' disabled-next'
                        : '')
                }
            >
                <div className="row align-items-center">
                    {this.props.footer === undefined && this.props.actions ? (
                        <div className="col-3 d-flex align-items-center">
                            <div>
                                <span className="actions d-inline-block align-middle">
                                    {this.props.actions}
                                </span>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.footer === undefined &&
                        this.props.allCheckbox !== undefined &&
                        this.props.allCheckbox === true &&
                        this.props.selected !== undefined && (
                            <div className="col-3 d-flex align-items-center">
                                <span className="total-wrap ml-1">
                                    Selected: {this.props.selected}
                                </span>
                            </div>
                        )}
                    {this.props.footer === undefined &&
                        this.props.extraFilter &&
                        (!this.props.hideExtraFilter ||
                            this.props.hideExtraFilter === false) && (
                            <div className="col-3 extra-filter-wrapper">
                                {this.props.extraFilter}
                            </div>
                        )}

                    {this.props.search && (
                        <div className="col-3 col-md-3 col-lg-4 col-xl-4">
                            <TableSearch onSearch={this.props.onSearch} />
                        </div>
                    )}

                    {this.props.totalPages !== 0 &&
                    (!this.props.hidePagination ||
                        this.props.hidePagination === false) ? (
                        this.props.footer === undefined ? (
                            <div className="align-items-center col col-auto d-flex justify-content-end ml-auto table-pagination-wrapper">
                                <span className="total-wrap text-nowrap border d-inline-block align-middle">
                                    Total: {this.props.total}
                                </span>
                                <div className="pagination-label text-sec">
                                    Page
                                </div>
                                <UltimatePaginationBootstrap4
                                    currentPage={this.props.currentPage}
                                    totalPages={this.props.totalPages}
                                    onChange={this.onPageChangeFromPagination}
                                    hideFirstAndLastPageLinks={true}
                                />
                                <div className="pagination-label">of</div>
                                <div className="pagination-label text-sec">
                                    {this.props.totalPages}
                                </div>
                                <div className="pagination-label text-sec">
                                    View
                                </div>
                                <div className="custom-select-wrapper">
                                    <select
                                        className="custom-select"
                                        value={this.props.pageSize}
                                        onChange={this.handleChange}
                                    >
                                        <option value="15">15</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        ) : (
                            <div className="col-6 d-flex justify-content-end align-items-center ml-auto table-pagination-wrapper">
                                <UltimatePaginationBootstrap4
                                    currentPage={this.props.currentPage}
                                    totalPages={this.props.totalPages}
                                    onChange={this.onPageChangeFromPagination}
                                    hideFirstAndLastPageLinks={true}
                                />
                            </div>
                        )
                    ) : null}
                </div>
            </div>
        );
    }
}

export default SimplePaginationWrapper;
