export const confidential = [
    {
        name: 'date_visited',
        value: '',
        type: 'date',
        placeholder: 'DATE VISITED'
    },
    {
        name: 'listing_date',
        value: '',
        type: 'date',
        placeholder: 'LISTING DATE'
    },
    {
        name: 'sold_date',
        value: '',
        type: 'date',
        placeholder: 'SOLD DATE'
    },
    {
        name: 'expiration_date',
        value: '',
        type: 'date',
        placeholder: 'EXPIRATION DATE'
    },
    {
        name: 'estimated_closing_price_displayed',
        value: '',
        type: 'price',
        placeholder: 'ESTIMATED CLOSING PRICE'
    },
    {
        name: 'sold_price_displayed',
        value: '',
        type: 'price',
        placeholder: 'SOLD PRICE'
    },
    {
        name: 'agreement_type',
        value: '',
        type: 'text',
        placeholder: 'AGREEMENT TYPE'
    },
    {
        name: 'sales_person',
        value: '',
        type: 'text',
        placeholder: 'SALES PERSON'
    },
    {
        name: 'co_sales_person',
        value: '',
        type: 'text',
        placeholder: 'CO SALES PERSON'
    },
    {
        name: 'owner_name',
        value: '',
        type: 'text',
        placeholder: 'OWNER NAME'
    },
    {
        name: 'registration_number',
        value: '',
        type: 'text',
        placeholder: 'REGISTRATION NUMBER'
    },
    {
        name: 'captain_name',
        value: '',
        type: 'text',
        placeholder: 'CAPTAIN NAME'
    },
    {
        name: 'captain_mobile',
        value: '',
        type: 'text',
        placeholder: 'CAPTAIN MOBILE'
    },
    {
        name: 'chef',
        value: '',
        type: 'text',
        placeholder: 'CHEF NAME'
    },
    {
        name: 'showing_instructions',
        value: '',
        type: 'text',
        placeholder: 'SHOWING INSTRUCTIONS'
    },
    {
        name: 'leasing',
        value: '',
        type: 'text',
        placeholder: 'LEASING'
    },
    {
        name: 'condition_rate',
        value: '',
        type: 'text',
        placeholder: 'CONDITION RATE'
    },
    {
        name: 'commission_status',
        value: '',
        type: 'text',
        placeholder: 'COMMISSION STATUS'
    },
    {
        name: 'website',
        value: '',
        type: 'text',
        placeholder: 'WEBSITE'
    },
    {
        name: 'short_description',
        value: '',
        type: 'textarea',
        placeholder: 'SHORT DESCRIPTION'
    },
    {
        name: 'confidential_notes',
        value: '',
        type: 'textarea',
        placeholder: 'NOTES'
    },
    {
        name: 'confidential_info',
        value: '',
        type: 'textarea',
        placeholder: 'INFO'
    }
];

export const initialValuesConfidential = {
    estimated_closing_price_currency: 'usd',
    sold_price_currency: 'usd'
};
