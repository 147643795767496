export const isInEmailList = (email) => {
    return (
        email === 'is@iyc.com' ||
        email === 'il@iyc.com' ||
        email === 'elvira@iyc.com' ||
        email === 'gpitsiladis@iyc.com' ||
        email === 'dv@iyc.com'
    );
};

export const isOwner = (role) => {
    return role === 'owner';
};

export const isSuperAdmin = (role) => {
    return role === 'super_admin' || role === 'owner';
};

export const isWebsiteAdmin = (role) => {
    return (
        role === 'super_admin' || role === 'owner' || role === 'admin_website'
    );
};

export const isAdmin = (role) => {
    return (
        role === 'super_admin' ||
        role === 'admin' ||
        role === 'owner' ||
        role === 'admin_website'
    );
};

export const isModerator = (role) => {
    return role === 'moderator' || isAdmin(role);
};

export const isBroker = (role) => {
    return role === 'broker';
};

export const isSalesBroker = (role) => {
    return role === 'broker_sales';
};

export const isCharterBroker = (role) => {
    return role === 'broker_charter';
};

export const isBrokerOrLess = (role) => {
    return isBroker(role) || isSalesBroker(role) || isCharterBroker(role);
};
