import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-datetime/css/react-datetime.css';
import 'react-select/dist/react-select.css';
import './assets/css/main.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppRouter from './AppRouter';
import 'helpers/sentry';
import configureStore from './store';

import { saveState } from './localStorage';

import moment from 'moment';

moment.locale('en');
moment.updateLocale('en', {
    calendar: {
        sameDay: '[Today] [at] hh:mm a',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday] [at] hh:mm a',
        lastWeek: '[Last] dddd [at] hh:mm a',
        sameElse: 'DD/MM/YYYY [at] hh:mm a'
    }
});

export const store = configureStore();
store.subscribe(() => {
    saveState({
        auth: store.getState().auth
    });
});

ReactDOM.render(
    <Provider store={store}>
        <AppRouter />
    </Provider>,
    document.getElementById('root')
);
