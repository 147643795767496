import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from 'helpers/variables';
import { authConfig } from 'helpers/authConfig';
import alerts from 'locales/alerts';
import { success, warning, error } from 'react-notification-system-redux';

import { store } from 'index';

let def = 'en';

class ClearWebsiteCache extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            yachts: []
        };
    }

    componentDidMount() {
        axios
            .get(API_URL + '/status/public_changes_yachts', {
                headers: authConfig()
            })
            .then((updatedYachts) => {
                this.setState({ yachts: updatedYachts.data });
            });
    }

    clearWebsiteCache = async () => {
        this.setState({ loading: true });
        return await axios
            .get(API_URL + '/status/public_changes_hook', {
                headers: authConfig()
            })
            .then(async (response) => {
                // if the cache is changed the data is 'true'
                if (response.data) {
                    await axios
                        .post(API_URL + '/status/public_changes_hook', null, {
                            headers: authConfig()
                        })
                        .then((res) => {
                            store.dispatch(
                                success({
                                    title: 'Success',
                                    message: 'Website cache cleared'
                                })
                            );
                        })
                        .catch((err) => {
                            store.dispatch(
                                error({
                                    title: alerts.error.websiteCache[def].title,
                                    message:
                                        alerts.error.websiteCache[def].message
                                })
                            );
                        });
                } else {
                    store.dispatch(
                        warning({
                            title: alerts.error.websiteCacheWarn[def].title,
                            message: alerts.error.websiteCacheWarn[def].message
                        })
                    );
                }
            })
            .catch((err) => {
                store.dispatch(
                    error({
                        title: alerts.error.websiteCache[def].title,
                        message: alerts.error.websiteCache[def].message
                    })
                );
            })
            .finally(async () => {
                await axios
                    .get(API_URL + '/status/public_changes_yachts', {
                        headers: authConfig()
                    })
                    .then((updatedYachts) => {
                        this.setState({ yachts: updatedYachts.data });
                    });
                this.setState({ loading: false });
            });
    };

    render() {
        const { loading } = this.state;
        return (
            <div className={'clearWebsiteCache'}>
                <button
                    disabled={loading}
                    className="btn btn-danger"
                    onClick={this.clearWebsiteCache}
                >
                    {loading ? (
                        <i className="fa fa-spinner fa-pulse " />
                    ) : (
                        <span>Clear cache ({this.state.yachts.length})</span>
                    )}
                </button>
            </div>
        );
    }
}

export default ClearWebsiteCache;
