import React from 'react';
import { Bar } from 'react-chartjs-2';

const StackedBar = ({ data, height, width }) => {
    return (
        <Bar
            data={data}
            height={height}
            width={width}
            options={{
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            display: true,
                            gridLines: {
                                display: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            stacked: true,
                            display: true,
                            gridLines: {
                                display: false
                            }
                        }
                    ]
                },
                legend: {
                    display: true,
                    position: 'top'
                },
                responsive: true
            }}
        />
    );
};

export default StackedBar;
