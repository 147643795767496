import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import { FieldArray } from 'redux-form';

import RenderTagField from './RenderTagField';

class RenderTags extends Component {
    render() {
        return (
            <FormGroup
                className={
                    'tags-wrapper ' +
                    (this.props.className !== undefined
                        ? this.props.className
                        : '')
                }
            >
                <FieldArray
                    name={this.props.tagPrefix}
                    component={RenderTagField}
                    props={{
                        readOnly: this.props.readOnly,
                        changeFieldValue: this.props.changeFieldValue,
                        newTag: this.props.newTag,
                        name: this.props.name,
                        newTagName: this.props.newTagName,
                        tagPrefix: this.props.tagPrefix,
                        validators: this.props.validators
                    }}
                />
            </FormGroup>
        );
    }
}

export default RenderTags;
