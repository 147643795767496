import {
    FETCH_NEWSLETTER_REPORT_BY_TAG_SUCCESS,
    FETCH_NEWSLETTER_REPORT_SUCCESS
} from './_types';

const REPORT = {
    opens: 0,
    clicks: 0,
    clicks_detail: []
};

const INITIAL_STATE = {
    customers_report: [],
    report: REPORT
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_NEWSLETTER_REPORT_BY_TAG_SUCCESS:
            return {
                ...state,
                customers_report: action.payload
            };
        case FETCH_NEWSLETTER_REPORT_SUCCESS:
            return {
                ...state,
                report: {
                    opens: action.payload.opens,
                    clicks: action.payload.clicks,
                    clicks_detail: action.payload.clicks_detail
                }
            };
        default:
            return state;
    }
};

export default reducer;
