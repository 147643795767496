import { combineReducers } from 'redux';

import customers from './customers/_reducers';
import offers from '../pages/customers/offers/redux/_reducers';

const management = combineReducers({
    customers: customers,
    offers: offers
});

export default management;
