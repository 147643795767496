import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

import Header from '../../common/header/Header';
import Instructions from '../../common/components/Instructions';
import SimpleTable from '../../common/components/SimpleTable';
import Loader from '../../common/components/Loader';

const NewsletterReportPresenter = props => {
    return (
        <div className="content-inner-wrapper">
            <Header menu={props.menu} history={props.history} />

            <div className="content-inner">
                {props.loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <Row>
                        <Col xs="12" className="mb-2">
                            <Instructions title="Please keep in mind that report data is not real time!" />
                        </Col>

                        <Col xs="12">
                            <p>
                                <strong className="h2">No. Opens:</strong>
                                <span> {props.report.opens}</span>
                            </p>
                            <p>
                                <strong className="h2">No. Clicks:</strong>
                                <span> {props.report.clicks}</span>
                            </p>
                        </Col>

                        <Col xs="12" xl="9">
                            <SimpleTable
                                colWidths={['3 text-center', 3, 3, 3]}
                                headers={props.table_headers}
                                hideExtraFilter={true}
                                rowData={
                                    props.report.clicks_detail &&
                                    props.report.clicks_detail.map(detail => {
                                        return [
                                            <div>
                                                <a
                                                    href={detail.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {detail.url}
                                                </a>
                                            </div>,
                                            <div>
                                                {moment
                                                    .unix(detail.ts)
                                                    .format(
                                                        'ddd D, YYYY h:mm a'
                                                    )}
                                            </div>,
                                            <div>{detail.location}</div>,
                                            <div>{detail.ua}</div>
                                        ];
                                    })
                                }
                            />
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
};

export default NewsletterReportPresenter;
