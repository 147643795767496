import React from 'react';
import { NavLink } from 'react-router-dom';

import Header from '../../../common/header/Header';

import destinations from '../../../assets/svgs/destinations.svg';

class DestinationsMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                parent: destinations,
                items: [
                    <NavLink exact to="/itineraries">
                        Itineraries
                    </NavLink>
                ],
                searchFunction: this.props.tableChange,
                searchLabel: 'Search itinerary',
                searchFunctionCol: 5
            }
        };
    }

    componentDidMount() {
        const view = this.props.match.path.includes('edit') ? 'edit' : 'create';
        const id = this.props.match.params.id;

        let menu = [
            <NavLink exact to={'/itineraries'}>
                All
            </NavLink>
        ];

        if (view === 'create') {
            menu = [
                ...menu,
                <NavLink exact to={'/itineraries/create'}>
                    Create
                </NavLink>
            ];
        }

        if (view === 'edit') {
            menu = [
                ...menu,
                <NavLink exact to={`/itineraries/${id}/edit`}>
                    Edit
                </NavLink>
            ];
        }

        this.setState({
            menu: {
                ...this.state.menu,
                items: menu
            }
        });
    }

    render() {
        return <Header menu={this.state.menu} history={this.props.history} />;
    }
}

export default DestinationsMenu;
