import React, { Component } from 'react';
import ReactSVG from 'react-svg';

import save from '../../assets/svgs/save.svg';

class BrokerActions extends Component {
    render() {
        return (
            <div className="extra-table-actions">
                {this.props.hasSearch === true && (
                    <button
                        className="btn btn-blue btn-primary"
                        onClick={this.props.onClick}
                    >
                        <ReactSVG
                            path={save}
                            style={{ height: 22, width: 22 }}
                        />
                        Save search
                    </button>
                )}
            </div>
        );
    }
}

export default BrokerActions;
