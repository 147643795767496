import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchGetYachtMedia } from 'yachts/yacht/_actions.js';

class SelectPhotosFromGallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            edit: false,
            gallery: []
        };
    }

    componentDidMount() {
        const path = window.location.pathname.split('/');
        const { selectedImages } = this.props;

        let params = {
            id: path[4],
            type: this.props.selectionType || 'photo'
        };
        if (path[2] === 'edit') {
            this.setState({ edit: true });
        } else {
            this.setState({ edit: false });
        }

        this.props
            .fetchGetYachtMedia(params)
            .then((data) => {
                this.setState({
                    gallery: data.map((i) => {
                        return selectedImages &&
                            selectedImages.find((j) => i.url === j)
                            ? { ...i, isSelected: true }
                            : { ...i, isSelected: false };
                    }),
                    loading: false
                });
            })
            .catch((err) => {
                this.setState({ loading: false });
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedImages !== this.props.selectedImages) {
            this.setState({
                gallery: this.state.gallery.map((i) => {
                    return this.props.selectedImages &&
                        this.props.selectedImages.find((j) => i.url === j)
                        ? { ...i, isSelected: true }
                        : { ...i, isSelected: false };
                }),
                loading: false
            });
        }
    }

    handleImageSelect(imageIndex) {
        this.setState(
            {
                gallery: this.state.gallery.map((i, index) => {
                    return index === imageIndex
                        ? { ...i, isSelected: true }
                        : { ...i, isSelected: false };
                })
            },
            () => {
                this.props.setSelectedImages(
                    this.state.gallery
                        .filter((item) => item.isSelected)
                        .map((i) => {
                            return { url: i.url, label: i.label };
                        })
                );
            }
        );
    }

    render() {
        const { label } = this.props;
        const { gallery, edit, loading } = this.state;

        return (
            <div className="selectPhotosFromGallery">
                <div>
                    {edit ? (
                        <div>
                            {label ? (
                                <h4
                                    className={'selectPhotosFromGallery__label'}
                                >
                                    {label}
                                </h4>
                            ) : null}

                            {loading ? (
                                <div
                                    className={
                                        'selectPhotosFromGallery__loader'
                                    }
                                >
                                    <i className="fa fa-spinner fa-pulse " />
                                </div>
                            ) : (
                                <div className="selectPhotosFromGallery__list">
                                    {gallery && gallery.length > 0 ? (
                                        gallery.map((i, index) => (
                                            <div
                                                key={index}
                                                onClick={() =>
                                                    this.handleImageSelect(
                                                        index
                                                    )
                                                }
                                                className={
                                                    i.isSelected
                                                        ? 'selectPhotosFromGallery__item selectPhotosFromGallery__item--selected'
                                                        : 'selectPhotosFromGallery__item'
                                                }
                                                style={{
                                                    backgroundImage: `url(${i.url})`
                                                }}
                                            >
                                                <a
                                                    className="selectPhotosFromGallery__download"
                                                    href={i.url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <i className="fa fa-download " />
                                                </a>
                                            </div>
                                        ))
                                    ) : (
                                        <div
                                            className={
                                                'selectPhotosFromGallery__empty'
                                            }
                                        >
                                            No photos to select
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchGetYachtMedia: (e) => dispatch(fetchGetYachtMedia(e))
    };
};

export default connect(null, mapDispatchToProps)(SelectPhotosFromGallery);
