export const FETCH_YACHTS = 'fetch_yachts',
    FETCH_YACHTS_SUCCESS = 'fetch_yachts_success',
    FETCH_YACHTS_FAIL = 'fetch_yachts_fail',
    FETCH_LOAD_YACHTS = 'fetch_load_yachts',
    FETCH_LOAD_YACHTS_SUCCESS = 'fetch_load_yachts_success',
    FETCH_LOAD_YACHTS_FAIL = 'fetch_load_yachts_fail',
    FETCH_BROKERS_LIST = 'fetch_brokers_list',
    FETCH_BROKERS_LIST_SUCCESS = 'fetch_brokers_list_success',
    FETCH_BROKERS_LIST_FAIL = 'fetch_brokers_list_fail',
    INIT_SEARCH = 'init_search',
    FETCH_GET_SELECTED_YACHTS = 'fetch_get_selected_yachts',
    FETCH_GET_SELECTED_YACHTS_SUCCESS = 'fetch_get_selected_yachts_success',
    FETCH_GET_SELECTED_YACHTS_FAIL = 'fetch_get_selected_yachts_fail',
    FETCH_SELECT_YACHTS = 'fetch_select_yachts',
    FETCH_SELECT_YACHTS_SUCCESS = 'fetch_select_yachts_success',
    FETCH_SELECT_YACHTS_FAIL = 'fetch_select_yachts_fail',
    FETCH_DELETE_SELECTED_YACHTS = 'fetch_delete_selected_yachts',
    FETCH_DELETE_SELECTED_YACHTS_SUCCESS =
        'fetch_delete_selected_yachts_success',
    FETCH_DELETE_SELECTED_YACHTS_FAIL = 'fetch_delete_selected_yachts_fail',
    FETCH_VIEW_YACHT_COMMENTS = 'fetch_view_yacht_comments',
    FETCH_VIEW_YACHT_COMMENTS_SUCCESS = 'fetch_view_yacht_comments_success',
    FETCH_VIEW_YACHT_COMMENTS_FAIL = 'fetch_view_yacht_comments_fail',
    CHANGE_YACHT_SELECTION = 'change_yacht_selection',
    CHANGE_YACHT_SELECTION_IN_SELECTED = 'change_yacht_selection_in_selected',
    CHANGE_ALL_YACHTS_SELECTION = 'change_all_yachts_selection',
    CHANGE_ALL_YACHTS_SELECTION_IN_SELECTED =
        'change_all_yachts_selection_in_selected',
    FETCH_COMPANIES = 'fetch_companies',
    FETCH_COMPANIES_SEARCH_SUCCESS = 'fetch_companies_search_success',
    FETCH_COMPANIES_SEARCH_FAIL = 'fetch_companies_search_fail',
    YACHT_ORDER_CHANGE = 'yacht_order_change',
    CHANGE_YACHT_STATUS_REQUEST = 'change_yacht_status_request',
    CHANGE_YACHT_STATUS_SUCCESS = 'change_yacht_status_success',
    CHANGE_YACHT_STATUS_FAILURE = 'change_yacht_status_failure',
    DESELECT_ALL_YACHTS_IN_SELECTION = 'deselect_all_yachts_in_selection',
    FETCH_EXTERNAL_PROVIDERS = 'fetch_external_providers',
    FETCH_EXTERNAL_PROVIDERS_SUCCESS = 'fetch_external_providers_success',
    FETCH_EXTERNAL_PROVIDERS_FAIL = 'fetch_external_providers_fail',
    FETCH_YACHT_EXTERNAL_PROVIDERS = 'fetch_yacht_external_providers',
    FETCH_YACHT_EXTERNAL_PROVIDERS_SUCCESS =
        'fetch_yacht_external_providers_success',
    FETCH_YACHT_EXTERNAL_PROVIDERS_FAIL = 'fetch_yacht_external_providers_fail';
