import axios from 'axios';
import { API_URL } from 'helpers/variables';
import { authConfig } from 'helpers/authConfig';

const url = `${API_URL}/customers`;

/**
 * Batch import customers by csv
 * @returns {AxiosPromise}
 */
export function importCustomers(formData) {
    return axios({
        url: `${url}/import`,
        method: 'POST',
        data: formData,
        headers: { ...authConfig(), contentType: 'none' }
    });
}
