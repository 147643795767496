import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';
import moment from 'moment';
import { Row, Col } from 'reactstrap';

import SimpleTableActions from '../../../common/components/SimpleTableActions';
import SimpleTable from '../../../common/components/SimpleTable';

// Helpers
import can from '../../../infrastructure/helpers/can';

// SVG
import add from '../../../assets/svgs/add.svg';
import edit from '../../../assets/svgs/edit.svg';
import open_eye from '../../../assets/svgs/open-eye.svg';

const BookingsTable = props => {
    const getTableData = () => {
        return props.bookings.data.map(booking => {
            let data = [
                <div>
                    {moment(booking.date_from).format('YYYY/MM/DD')}
                </div>,
                <div>
                    {moment(booking.date_to).format('YYYY/MM/DD')}
                </div>,
                <div>{
                    `${booking.location_from == null ? '--' : booking.location_from} | 
                    ${booking.location_to == null ? '--' : booking.location_to}`
                }
                </div>,
                <div>
                    <span
                        className={`booking__status__point booking__status__point--${
                            booking.status
                        }`}
                    />
                    {booking.status.replace('_', ' ').toUpperCase()}
                </div>,
                <ul className="list-unstyled table-td-actions">
                    {can('manageBookings', props.userRole) && (
                        <li className="text-fill-hover">
                            <NavLink
                                to={`/yachts/edit/charter/${
                                    booking.yacht.id
                                }/bookings/${booking.id}/edit`}
                            >
                                <ReactSVG
                                    path={edit}
                                    style={{ height: 24 }}
                                />
                            </NavLink>
                        </li>
                    )}

                    <li className="text-fill-hover">
                        <NavLink
                            to={`/yachts/view/charter/${booking.yacht.id}/bookings/${booking.id}`}
                        >
                            <ReactSVG
                                path={open_eye}
                                style={{ height: 24 }}
                            />
                        </NavLink>
                    </li>
                </ul>
            ];

            if (props.showYachtColumn) {
                data.unshift(
                    <div>
                        <NavLink
                            to={`/yachts/view/charter/${booking.yacht.id}/`}
                        >
                            { booking.yacht.name }
                        </NavLink>
                    </div>
                );
            }

            return data;
        })
    }

    return (
        <div>
            {can('manageBookings', props.userRole) && props.show_create_action && (
                <SimpleTableActions
                    label="Create new booking"
                    icon={add}
                    onClick={() =>
                        props.history.push(
                            `/yachts/edit/charter/${
                                props.yacht_id
                            }/bookings/create`
                        )
                    }
                />
            )}

            {props.bookingsDateInfo && (
                <Row>
                    <Col xs="12">
                        <h4 className="my-2">
                            <span style={{fontWeight: 300, fontSize: '1.3rem'}}>Date Range: </span>
                            <strong>{ props.bookingsDateInfo.date_from } {' - '} { props.bookingsDateInfo.date_to }</strong>
                        </h4>
                    </Col>
                </Row>
            )}

            <SimpleTable
                tableChange={props.onTableChange}
                paging={props.hasPagination ? props.bookings.pagination : undefined}
                colWidths={props.showYachtColumn 
                    ? [2, 2, 2, 3, 2, 1]
                    : [2, 2, 4, 2, 1]
                }
                headers={props.table_headers}
                rowData={getTableData()}
            />
        </div>
    );
};

export default BookingsTable;
