import React from 'react';
import ReactSVG from 'react-svg';
import { Row, Col } from 'reactstrap';
import { FieldArray } from 'redux-form';

import RenderSuggestionsField from './RenderSuggestionsField';

// SVG
import sea from '../../../assets/svgs/sea.svg';
import restaurants from '../../../assets/svgs/restaurants.svg';
import culture from '../../../assets/svgs/culture.svg';
import events from '../../../assets/svgs/events.svg';
import activities from '../../../assets/svgs/activities.svg';
import other from '../../../assets/svgs/other.svg';

const suggestions = [
    {
        path: sea,
        label: 'Beaches'
    },
    {
        path: restaurants,
        label: 'Fnb'
    },
    {
        path: culture,
        label: 'Culture'
    },
    {
        path: events,
        label: 'Events'
    },
    {
        path: activities,
        label: 'Activities'
    },
    {
        path: other,
        label: 'Other'
    }
];

class RenderSuggestions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active_suggestion: 'beaches'
        };
    }

    onSuggestionSelect(suggestion) {
        this.setState({
            active_suggestion: suggestion
        });
    }

    render() {
        return (
            <Row>
                {suggestions.map((suggestion, index) => (
                    <Col
                        md="2"
                        className="d-flex justify-content-between mb-2 suggestions-list"
                        key={index}
                    >
                        <div className="d-inline-flex align-items-center">
                            <button
                                type="button"
                                className={
                                    'btn btn-empty d-inline-flex align-items-center p-0 ' +
                                    (this.state.active_suggestion ===
                                    suggestion.label.toLowerCase()
                                        ? 'selected'
                                        : '')
                                }
                                onClick={() =>
                                    this.onSuggestionSelect(
                                        suggestion.label.toLowerCase()
                                    )
                                }
                            >
                                <ReactSVG
                                    path={suggestion.path}
                                    style={{
                                        height: 32
                                    }}
                                />
                                <div className="ml-1 size-12">
                                    {suggestion.label}
                                </div>
                            </button>
                        </div>
                    </Col>
                ))}

                {suggestions.map((suggestion, index) => (
                    <Col
                        xs="12"
                        style={
                            this.state.active_suggestion ===
                            suggestion.label.toLowerCase()
                                ? { display: 'block' }
                                : { display: 'none' }
                        }
                        key={index}
                    >
                        <FieldArray
                            name={`suggestions.${suggestion.label.toLowerCase()}`}
                            component={RenderSuggestionsField}
                            type={suggestion.label.toLowerCase()}
                        />
                    </Col>
                ))}
            </Row>
        );
    }
}

export default RenderSuggestions;
