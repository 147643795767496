import Queue from './Queue';

export default class DirectedGraph {
    constructor() {
        this._nodes = new Map();
        this._adjacencyList = new Map();
    }

    hasNode(node) {
        return this._nodes.has(node);
    }

    label(node) {
        if (!this.hasNode(node)) {
            throw new Error('Node does not exist');
        }
        return this._nodes.get(node);
    }

    addNode(node, label) {
        this._nodes.set(node, label);
        return this;
    }

    addEdge(node1, node2) {
        if (!this.hasNode(node1)) {
            throw new Error('Node does not exist');
        }
        if (!this.hasNode(node2)) {
            throw new Error('Node does not exist');
        }
        const neighbours = this._adjacencyList.get(node1) || new Set();
        neighbours.add(node2);
        this._adjacencyList.set(node1, neighbours);
        return this;
    }

    /**
     * Returns all the nodes that have no inbound edges
     */
    roots() {
        const notRoots = new Set();
        for (const node of this._nodes.keys()) {
            for (const neighbour of this.children(node)) {
                notRoots.add(neighbour);
            }
        }
        const result = new Set();
        for (const node of this._nodes.keys()) {
            if (!notRoots.has(node)) {
                result.add(node);
            }
        }
        return result;
    }

    children(node) {
        return this._adjacencyList.get(node) || new Set();
    }

    hasCycles() {
        const finished = new Set();
        for (const start_node of this._nodes.keys()) {
            if (!finished.has(start_node)) {
                let { cycle, seen } = this._dfs(start_node);
                if (cycle.length) return true;
                finished.add(...seen);
            }
        }
        return false;
    }

    reachableFrom(node) {
        return this._bfs(node);
    }

    invert() {
        const result = new DirectedGraph();
        for (const node of this._nodes) {
            result.addNode(node[0], node[1]);
        }
        for (const node of this._nodes) {
            for (const child of this.children(node[0])) {
                result.addEdge(child, node[0]);
            }
        }
        return result;
    }

    _bfs(start_node) {
        const seen = new Set();

        if (!this.hasNode(start_node)) return seen;
        const todo = new Queue();
        todo.enqueue(start_node);
        while (!todo.isEmpty()) {
            let current_node = todo.dequeue();
            seen.add(current_node);
            for (const neighbour of this.children(current_node)) {
                if (!seen.has(neighbour)) {
                    todo.enqueue(neighbour);
                }
            }
        }
        return seen;
    }

    _dfs(node) {
        const seen = new Set();
        const finished = new Set();
        const tests = { cycle: [] };

        this._dfs_recursion(node, seen, finished, tests);

        return { seen, cycle: tests.cycle };
    }

    _dfs_recursion(node, seen, finished, tests) {
        seen.add(node);
        for (const neighbour of this.children(node)) {
            if (!seen.has(neighbour)) {
                this._dfs_recursion(neighbour, seen, finished, tests);
            }
            if (seen.has(neighbour) && !finished.has(neighbour)) {
                tests.cycle.push(node);
            }
        }
        finished.add(node);
    }
}
