import axios from 'axios';

import { API_URL } from '../../helpers/variables';
import { authConfig } from '../../helpers/authConfig';
import { errorHandler, successHandler } from '../../helpers/alerts';

import {
    FETCH_CREATE_NEWSLETTER,
    FETCH_NEWSLETTER,
    FETCH_NEWSLETTER_HISTORY,
    FETCH_NEWSLETTER_HISTORY_SUCCESS,
    FETCH_DELETE_NEWSLETTER,
    FETCH_DELETE_NEWSLETTER_SUCCESS,
    FETCH_SEND_NEWSLETTER,
    FETCH_SEND_NEWSLETTER_SUCCESS,
    UPDATE_NEWSLETTER
} from './types';

const fetchCreateNewsletter = params => {
    return dispatch => {
        dispatch({ type: FETCH_CREATE_NEWSLETTER });
        console.log(params);
        const config = {
            headers: authConfig()
        };

        return axios
            .post(API_URL + '/newsletters', params, config)
            .then(response => {
                dispatch({ type: FETCH_CREATE_NEWSLETTER });
                successHandler(dispatch, 'createNewsletter');

                return response.data;
            })
            .catch(error => {
                dispatch({ type: 'test' });
                errorHandler(dispatch, error.response, 'error');
            });
    };
};

const updateNewsletter = ({ params, id }) => {
    return dispatch => {
        console.log(params);
        dispatch({ type: UPDATE_NEWSLETTER });

        const config = {
            headers: authConfig()
        };

        return axios
            .put(`${API_URL}/newsletters/${id}`, params, config)
            .then(response => {
                dispatch({ type: UPDATE_NEWSLETTER });
                successHandler(dispatch, 'updateNewsletter');

                return response.data;
            })
            .catch(error => {
                dispatch({ type: 'test' });
                errorHandler(dispatch, error.response, 'error');
            });
    };
};

const fetchNewsletter = params => {
    return dispatch => {
        dispatch({ type: FETCH_NEWSLETTER });

        const config = {
            headers: authConfig()
        };

        return axios
            .get(API_URL + '/newsletters/' + params.id, config)
            .then(response => {
                console.log(response);
                dispatch({ type: FETCH_NEWSLETTER });

                return response.data;
            })
            .catch(error => {
                dispatch({ type: 'test' });
                errorHandler(dispatch, error.response, 'error');
            });
    };
};

const fetchNewslettersHistory = params => {
    return dispatch => {
        dispatch({ type: FETCH_NEWSLETTER_HISTORY });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/newsletters', config)
            .then(response => {
                console.log(response);
                dispatch({
                    type: FETCH_NEWSLETTER_HISTORY_SUCCESS,
                    payload: {
                        data: response.data.data,
                        pagination: response.data.pagination,

                        sorting: {
                            order_dir: params.order_dir,
                            order_field: params.order_field,
                            page_size: params.page_size
                        }
                    }
                });

                return response.data;
            })
            .catch(error => {
                dispatch({ type: 'test' });
                errorHandler(dispatch, error.response, 'error');
            });
    };
};

const fetchDeleteNewsletter = params => {
    return dispatch => {
        dispatch({ type: FETCH_DELETE_NEWSLETTER });

        const config = {
            headers: authConfig()
        };

        return axios
            .delete(API_URL + '/newsletters/' + params.id, config)
            .then(response => {
                dispatch({ type: FETCH_DELETE_NEWSLETTER_SUCCESS });
                successHandler(dispatch, 'deleteNewsletter');

                return response.data;
            })
            .catch(error => {
                errorHandler(dispatch, error.response, 'error');
            });
    };
};

const fetchSendNewsletter = params => {
    return dispatch => {
        dispatch({ type: FETCH_SEND_NEWSLETTER });

        const config = {
            headers: authConfig()
        };

        return axios
            .post(API_URL + '/newsletters/' + params.id + '/send', {}, config)
            .then(response => {
                dispatch({ type: FETCH_SEND_NEWSLETTER_SUCCESS });
                successHandler(dispatch, 'newsletterSended');

                return response.data;
            })
            .catch(error => {
                errorHandler(dispatch, error.response, 'error');
            });
    };
};

export {
    fetchCreateNewsletter,
    updateNewsletter,
    fetchNewsletter,
    fetchNewslettersHistory,
    fetchDeleteNewsletter,
    fetchSendNewsletter
};
