export const FETCH_MANAGE_YACHT = 'fetch_manage_yacht',
  FETCH_MANAGE_YACHT_SUCCESS = 'fetch_manage_yacht_success',
  FETCH_MANAGE_YACHT_FAIL = 'fetch_manage_yacht_fail',
  FETCH_GET_YACHT = 'fetch_get_yacht',
  FETCH_GET_YACHT_SUCCESS = 'fetch_get_yacht_success',
  FETCH_GET_YACHT_FAIL = 'fetch_get_yacht_fail',
  FETCH_MANAGE_YACHT_TYPE = 'fetch_manage_yacht_type',
  FETCH_MANAGE_YACHT_TYPE_SUCCESS = 'fetch_manage_yacht_type_success',
  FETCH_MANAGE_YACHT_TYPE_FAIL = 'fetch_manage_yacht_type_fail',
  FETCH_GET_YACHT_TYPE = 'fetch_get_yacht_type',
  FETCH_GET_YACHT_TYPE_SUCCESS = 'fetch_get_yacht_type_success',
  FETCH_GET_YACHT_TYPE_FAIL = 'fetch_get_yacht_type_fail',
  FETCH_INIT_YACHT = 'fetch_init_yacht',
  FETCH_HISTORY_LOG = 'fetch_history_log',
  FETCH_HISTORY_LOG_SUCCESS = 'fetch_history_log_success',
  FETCH_HISTORY_LOG_FAIL = 'fetch_history_log_fail',
  FETCH_MANAGE_MEDIA = 'fetch_manage_media',
  FETCH_MANAGE_MEDIA_SUCCESS = 'fetch_manage_media_success',
  FETCH_MANAGE_MEDIA_FAIL = 'fetch_manage_media_fail',
  FETCH_GET_YACHT_MEDIA = 'fetch_get_yacht_media',
  FETCH_GET_YACHT_MEDIA_SUCCESS = 'fetch_get_yacht_media_success',
  FETCH_GET_YACHT_MEDIA_FAIL = 'fetch_get_yacht_media_fail',
  FETCH_DELETE_YACHT = 'fetch_delete_yacht',
  FETCH_DELETE_YACHT_SUCCESS = 'fetch_delete_yacht_success',
  FETCH_DELETE_YACHT_FAIL = 'fetch_delete_yacht_fail',
  FETCH_GET_YACHT_COMMENTS = 'fetch_get_yacht_comments',
  FETCH_GET_YACHT_COMMENTS_SUCCESS = 'fetch_get_yacht_comments_success',
  FETCH_GET_YACHT_COMMENTS_FAIL = 'fetch_get_yacht_comments_fail',
  FETCH_VIEW_YACHT_COMMENTS = 'fetch_view_yacht_comments',
  FETCH_VIEW_YACHT_COMMENTS_SUCCESS = 'fetch_view_yacht_comments_success',
  FETCH_VIEW_YACHT_COMMENTS_FAIL = 'fetch_view_yacht_comments_fail',
  FETCH_POST_YACHT_COMMENT = 'fetch_post_yacht_comment',
  FETCH_POST_YACHT_COMMENT_SUCCESS = 'fetch_post_yacht_comment_success',
  FETCH_POST_YACHT_COMMENT_FAIL = 'fetch_post_yacht_comment_fail',
  FETCH_SEND_YACHT_MAIL = 'fetch_send_yacht_mail',
  FETCH_SEND_YACHT_MAIL_SUCCESS = 'fetch_send_yacht_mail_success',
  FETCH_SEND_YACHT_MAIL_FAIL = 'fetch_send_yacht_mail_fail',
  CHANGE_YACHT_SELECTION = 'change_yacht_selection',
  CHANGE_YACHT_SELECTION_CHARTER = 'change_yacht_selection_charter',
  CHANGE_YACHT_SELECTION_SALES = 'change_yacht_selection_sales';
