import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import CheckboxField from 'common/components/Forms/CheckboxField';
import InputField from 'common/components/Forms/InputField';

import { dropdowns } from 'helpers/variables';
import SelectField from 'common/components/Forms/SelectField';

export default function ExternalYachtsFilters(props) {
    const [filters, setFilters] = useState({
        davinci_status: [],
        status: [],
        builder: '',
        name: '',
        type: [],
        year_built_from: '',
        year_built_to: '',
        loa_from: '',
        loa_to: '',
        beam_from: '',
        beam_to: '',
        cabins_from: '',
        cabins_to: '',
        asking_price_from: '',
        asking_price_to: ''
    });

    const handleClearFilters = () => {
        setFilters({
            davinci_status: [],
            status: [],
            builder: '',
            name: '',
            type: [],
            year_built_from: '',
            year_built_to: '',
            loa_from: '',
            loa_to: '',
            beam_from: '',
            beam_to: '',
            cabins_from: '',
            cabins_to: '',
            asking_price_from: '',
            asking_price_to: ''
        });
        document
            .querySelectorAll('input[type=checkbox]')
            .forEach((el) => (el.checked = false));
        document
            .querySelectorAll('input[type=text]')
            .forEach((el) => (el.value = ''));
        document
            .querySelectorAll('input[type=number]')
            .forEach((el) => (el.value = ''));

        setTimeout(() => {
            props.onClick([]);
        }, 1);
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const existingItem = filters[name].find((i) => i === value);

        if (existingItem === undefined) {
            //handle item add
            setFilters({
                ...filters,
                [name]: [...filters[name], value]
            });
        } else {
            setFilters({
                ...filters,
                [name]: filters[name].filter((i) => i !== value)
            });
        }
    };
    const handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setFilters({
            ...filters,
            [name]: value
        });
    };

    const handleSelectChange = (selected, action) => {
        setFilters({
            ...filters,
            type: [...selected]
        });
    };

    return (
        <div className="content-inner-wrapper">
            <div className="external-yachts-filters">
                <Row>
                    <Col xs="12">
                        <Row className="my-2 justify-content-center">
                            <Col lg="5">
                                <button
                                    type="button"
                                    className="btn btn-primary btn-search"
                                    onClick={() => props.onClick(filters)}
                                >
                                    Apply Filters
                                </button>
                            </Col>

                            <Col lg="5">
                                <Button
                                    type="button"
                                    color="secondary-blue"
                                    onClick={handleClearFilters}
                                >
                                    Reset Filters
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12">
                        <div className="field-outer-wrapper mb-1">
                            <div className="filter-label font-500 mb-1">
                                DAVINCI STATUS
                            </div>
                            <CheckboxField
                                name="davinci_status"
                                label="Imported"
                                className="mb-1"
                                value="imported"
                                onChange={handleChange}
                            />
                            <CheckboxField
                                name="davinci_status"
                                label="Not Imported"
                                className="mb-1"
                                value="not_imported"
                                onChange={handleChange}
                            />
                            <CheckboxField
                                name="davinci_status"
                                label="Needs Update"
                                className="mb-1"
                                value="update"
                                onChange={handleChange}
                            />
                            <div className="filter-label font-500 mb-1">
                                STATUS
                            </div>
                            <CheckboxField
                                name="status"
                                label="In Process"
                                className="mb-1"
                                value="In Process"
                                onChange={handleChange}
                            />
                            <CheckboxField
                                name="status"
                                label="Active"
                                className="mb-1"
                                value="Active"
                                onChange={handleChange}
                            />
                            <CheckboxField
                                name="status"
                                label="Expired"
                                className="mb-1"
                                value="Expired"
                                onChange={handleChange}
                            />
                            <CheckboxField
                                name="status"
                                label="Off the Market"
                                className="mb-1"
                                value="Off the Market"
                                onChange={handleChange}
                            />
                            <CheckboxField
                                name="status"
                                label="Sales Pending"
                                className="mb-1"
                                value="Sales Pending"
                                onChange={handleChange}
                            />
                            <CheckboxField
                                name="status"
                                label="Withdrawn"
                                className="mb-1"
                                value="Withdrawn"
                                onChange={handleChange}
                            />
                            <CheckboxField
                                name="status"
                                label="Sub Listing"
                                className="mb-1"
                                value="Sub Listing"
                                onChange={handleChange}
                            />
                            <CheckboxField
                                name="status"
                                label="Sold"
                                className="mb-1"
                                value="Sold"
                                onChange={handleChange}
                            />
                        </div>
                    </Col>
                    <Col xs="12" lg="10">
                        <InputField
                            name="builder"
                            placeholder="BUILDER"
                            className="mt-2 mb-2"
                            type="text"
                            onChange={handleInputChange}
                        />

                        <InputField
                            name="name"
                            placeholder="NAME"
                            className="mt-2 mb-2"
                            type="text"
                            onChange={handleInputChange}
                        />
                        <SelectField
                            name="type"
                            placeholder="TYPE"
                            className="mt-2 mb-2 external-yachts-filters-dropdown"
                            isSearchable
                            isClearable
                            isMulti
                            value={filters.type}
                            options={dropdowns['yatco_yacht_types']}
                            onChange={handleSelectChange}
                        />
                    </Col>
                    <Col xs="12">
                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <div className="filter-label font-500">
                                YEAR BUILT
                            </div>
                            <div className="row">
                                <div className="col-2 col-md-5 pr-1">
                                    <InputField
                                        name="year_built_from"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="From"
                                        className="mt-0"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-2 col-md-5 pr-1">
                                    <InputField
                                        name="year_built_to"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="To"
                                        className="mt-0"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs="12">
                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <div className="filter-label font-500">LOA</div>
                            <div className="row">
                                <div className="col-2 col-md-5 pr-1">
                                    <InputField
                                        name="loa_from"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="From"
                                        className="mt-0"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-2 col-md-5 pr-1">
                                    <InputField
                                        name="loa_to"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="To"
                                        className="mt-0"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs="12">
                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <div className="filter-label font-500">BEAM</div>
                            <div className="row">
                                <div className="col-2 col-md-5 pr-1">
                                    <InputField
                                        name="beam_from"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="MAX"
                                        className="mt-0"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-2 col-md-5 pr-1">
                                    <InputField
                                        name="beam_to"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="MIN"
                                        className="mt-0"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs="12">
                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <div className="filter-label font-500">CABINS</div>
                            <div className="row">
                                <div className="col-2 col-md-5 pr-1">
                                    <InputField
                                        name="cabins_from"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="From"
                                        className="mt-0"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-2 col-md-5 pr-1">
                                    <InputField
                                        name="cabins_to"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="To"
                                        className="mt-0"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs="12">
                        <div className="flex-direction-column justify-content-between field-outer-wrapper">
                            <div className="filter-label font-500">
                                ASKING PRICE
                            </div>
                            <div className="row">
                                <div className="col-2 col-md-5 pr-1">
                                    <InputField
                                        name="asking_price_from"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="From"
                                        className="mt-0"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-2 col-md-5 pr-1">
                                    <InputField
                                        name="asking_price_to"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="To"
                                        className="mt-0"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
