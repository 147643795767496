import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

import YachtMenu from '../components/YachtMenu';
import BookingsTable from '../../bookings/components/BookingsTable';
import Loader from '../../../common/components/Loader';
import InlineDatePicker from '../../../common/components/inlinedatepicker/InlineDatePicker';

const buildBookingEvents = bookings => {
    let events = {};

    for (const booking of bookings) {
        var date_from = moment(booking.date_from);
        var date_to = moment(booking.date_to);
        
        for (
            date_from;
            date_from.isSameOrBefore(date_to);
            date_from.add(1, 'days')
        ) {
            let month = date_from.clone().format('MMMM');
            if (events[month] === undefined) events[month] = {};

            events[month][date_from.date()] = booking.status;
        }
    }
    
    return events;
};

const IndexYachtBookingsPresenter = props => {
    let events = buildBookingEvents(props.bookings.data);

    return (
        <div className="content-inner-wrapper">
            <YachtMenu
                user_role={props.user_role}
                match={props.match}
                history={props.history}
            />

            <div className="content-inner">
                {props.loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <Row>
                        <Col xs="12" lg="9">
                            <BookingsTable
                                history={props.history}
                                yacht_id={props.match.params.id}
                                table_headers={props.table_headers}
                                hasPagination={false}
                                showYachtColumn={false}
                                bookings={props.bookings}
                                bookingsDateInfo={props.bookingsQueryDates}
                                userRole={props.user_role}
                                show_create_action={true}
                                onTableChange={props.onTableChange}
                            />
                        </Col>

                        { props.bookings.data.length === 0 && (
                            <Col xs="12" lg="9" className="text-center">
                                <p><strong>No bookings in this date range.</strong></p>
                                <p>Use the buttons "Previous" and "Next" view past or future bookings.</p>
                            </Col>
                        )}

                        <Col xs="12" lg="9">
                            <InlineDatePicker
                                events={events}
                                date_from={props.bookingsQueryDates.date_from}
                                date_to={props.bookingsQueryDates.date_to}
                                onPreviousDate={props.onPreviousDate}
                                onNextDate={props.onNextDate}
                            />
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
};

export default IndexYachtBookingsPresenter;
