import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Field, reduxForm, FieldArray } from 'redux-form';
import Notifications from 'react-notification-system-redux';
import { Button } from 'reactstrap';

import { fetchManageBroker, fetchBroker, initBrokerModal } from '../actions';

import Header from '../../common/header/Header';
import RenderField from '../../common/components/RenderField';
import RenderFieldArray from '../../common/components/RenderFieldArray';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import UploadField from '../../common/components/UploadField';

import { required, email } from '../../helpers/validate';

import photoSVG from '../../assets/svgs/photo.svg';
import brokers from '../../assets/svgs/brokers.svg';

const form = reduxForm({
    form: 'manage_broker',
    enableReinitialize: true,
    destroyOnUnmount: false
});

class ManageBroker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photo: '',
            menu: {
                parent: brokers,
                items: [
                    <NavLink
                        exact
                        to={
                            props.match.params.id
                                ? '/contacts/edit/' + props.match.params.id
                                : '/contacts/add'
                        }
                    >
                        {props.match.params.id
                            ? 'Edit broker contact'
                            : 'Add new broker contact'}
                    </NavLink>
                ],
                backLink: '/contacts'
            }
        };
    }

    componentDidMount() {
        this.setState({
            photo: this.props.photoBroker
        });

        if (this.props.match.params.id) {
            this.props
                .fetchBroker({ id: this.props.match.params.id })
                .then((data) => {
                    if (data) {
                        this.setState({
                            photo: data.photo
                        });
                    }
                });
        } else {
            this.setState({ photo: '' });
            this.props.initBrokerModal({});
        }
    }

    componentWillUnmount() {
        this.setState({ photo: '' });
        this.props.initBrokerModal({});
    }

    handleFormSubmit(formProps) {
        let params = formProps;
        let valid = true;
        if (this.props.id !== undefined) {
            params.id = this.props.id;
        }
        if (this.state.photo !== undefined && this.state.photo !== '') {
            params.photo = this.state.photo;
        } else {
            valid = false;
            this.props.notification('Error', 'Please add photo');
        }
        if (valid) {
            this.props.fetchManageBroker(params).then((data) => {
                if (data) {
                    this.props.history.push('/contacts');
                }
            });
        }
    }

    addPhoto(url) {
        this.setState({
            photo: url
        });
    }

    render() {
        const { handleSubmit, submitting, invalid } = this.props;
        return (
            <div className="content-inner-wrapper">
                <Header menu={this.state.menu} history={this.props.history} />

                <div className="content-inner">
                    <div className="row">
                        <div className="col-8">
                            <form
                                className="list-form"
                                onSubmit={handleSubmit(
                                    this.handleFormSubmit.bind(this)
                                )}
                            >
                                <div className="row">
                                    <div className="col-6 col-xl-4">
                                        <UploadField
                                            name="photo"
                                            box={true}
                                            desc="Dimension of photo must be 250x90"
                                            fileLabelName="Click here to upload photo(s)"
                                            initPhoto={this.state.photo}
                                            icon={photoSVG}
                                            add={this.addPhoto.bind(this)}
                                            type="single"
                                            formName="manage_yacht"
                                            className="modal-box mb-2"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-xl-4">
                                        <Field
                                            name="name"
                                            type="text"
                                            placeholder="NAME"
                                            validate={[required]}
                                            component={RenderField}
                                            className="mb-3"
                                        />

                                        <Field
                                            name="contact"
                                            type="text"
                                            placeholder="EMAIL"
                                            validate={[required]}
                                            component={RenderField}
                                            className="mb-2"
                                        />

                                        <FieldArray
                                            name="emails"
                                            placeholder="ADDITIONAL EMAIL"
                                            component={RenderFieldArray}
                                            validators={[email]}
                                            type="text"
                                            className="mb-3"
                                        />

                                        <Field
                                            name="main"
                                            type="text"
                                            placeholder="PHONE"
                                            validate={[required]}
                                            component={RenderField}
                                            className="mb-2"
                                        />

                                        <FieldArray
                                            name="phones"
                                            placeholder="ADDITIONAL PHONE"
                                            component={RenderFieldArray}
                                            type="text"
                                            className="mb-3"
                                        />

                                        <Field
                                            name="mobile"
                                            type="text"
                                            placeholder="MOBILE"
                                            validate={[required]}
                                            component={RenderField}
                                            className="mb-3"
                                        />

                                        <Field
                                            name="company"
                                            type="text"
                                            placeholder="COMPANY NAME"
                                            validate={[required]}
                                            component={RenderField}
                                            className="mb-3"
                                        />

                                        <Field
                                            name="address"
                                            type="text"
                                            placeholder="ADDRESS"
                                            component={RenderField}
                                            className="mb-3"
                                        />

                                        <Field
                                            name="country"
                                            placeholder="COUNTRY"
                                            component={RenderReactSelect}
                                            options={this.props.countries}
                                        />
                                        <Field
                                            name="type"
                                            placeholder="TYPE"
                                            component={RenderReactSelect}
                                            options={[
                                                {
                                                    value: 'sales',
                                                    label: 'Sales'
                                                },
                                                {
                                                    value: 'chartering',
                                                    label: 'Charter'
                                                },
                                                {
                                                    value: 'both',
                                                    label: 'Sales & Charter'
                                                }
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4 col-md-6 text-right mt-2">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={invalid || submitting}
                                        >
                                            Save
                                        </Button>{' '}
                                        <Button
                                            type="button"
                                            color="secondary"
                                            onClick={() =>
                                                this.props.history.push(
                                                    '/contacts'
                                                )
                                            }
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetchManageBroker: (e) => dispatch(fetchManageBroker(e)),
        fetchBroker: (e) => dispatch(fetchBroker(e)),
        initBrokerModal: (e) => dispatch(initBrokerModal(e)),
        notification: (title, message) => {
            let notificationOpts = {
                position: 'tc',
                autoDismiss: 0,
                title: title,
                message: message
            };

            dispatch(Notifications.error(notificationOpts));
        }
    };
}

function mapStateToProps(state, ownProps) {
    let initialValues = {};

    if (ownProps.match.params.id) {
        initialValues = {
            company: state.brokers.initBroker.company,
            contact: state.brokers.initBroker.contact,
            mobile: state.brokers.initBroker.mobile,
            main: state.brokers.initBroker.main,
            name: state.brokers.initBroker.name,
            address: state.brokers.initBroker.address,
            country: state.brokers.initBroker.country,
            emails: state.brokers.initBroker.emails,
            phones: state.brokers.initBroker.phones,
            type: state.brokers.initBroker.type
        };
    }

    return {
        initialValues: initialValues,
        id: ownProps.match.params.id ? state.brokers.initBroker.id : null,
        photoBroker: state.brokers.initBroker.photo,
        countries: state.common.countries.map((st) => {
            return { value: st.name, label: st.name };
        })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(form(ManageBroker));
