import React from 'react';

const ToggledSidebar = props => {
    return (
        <div
            className={`toggled-sidebar ${props.position}  ${
                props.is_open ? 'active' : ''
            }`}
        >
            {props.position === 'fixed' && (
                <div className="toggled-sidebar__close">
                    <button
                        className="btn btn-secondary toggled-sidebar__button"
                        onClick={props.onCloseSidebar}
                    >
                        <span aria-hidden="true" className="pr-1">
                            ×
                        </span>
                        Close
                    </button>
                </div>
            )}

            <div className="toggled-sidebar__inner">{props.children}</div>
        </div>
    );
};

export default ToggledSidebar;
