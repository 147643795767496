import React from 'react';
import { connect } from 'react-redux';
import { FieldArray } from 'redux-form';

import RenderBookingsLocationsDropdown from './RenderBookingsLocationsDropdown';

// Redux
import { fetchBookingsLocations } from '../redux/_actions';

class BookingsLocationsDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            suggestions: [],
            active: 0
        };
    }

    onSelect(suggestion, index) {
        this.setState({ is_active: false });

        this.props.onSelect(suggestion);
    }

    onChange(event) {
        const search_query = event.target.value;
        if (search_query.length < 3) return;

        this.setState({ is_active: true });
        this.props
            .fetchBookingsLocations(search_query)
    }

    render() {
        return (
            <div
                className="companies-wrap mt-2"
            >
                <FieldArray
                    name="search_bookings_locations"
                    single={true}
                    component={RenderBookingsLocationsDropdown}
                    props={{
                        suggestions: this.props.suggestions,
                        is_active: this.state.is_active,
                        change: this.onChange.bind(this),
                        select: this.onSelect.bind(this),
                        name: this.props.name,
                        placeholder: this.props.placeholder
                    }}
                    readOnly={this.props.readOnly}
                    className="mb-2"
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        suggestions: state.bookings.suggestions
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchBookingsLocations: search_query => dispatch(fetchBookingsLocations(search_query))
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(BookingsLocationsDropdown);