import React, { useState } from 'react';
import { error } from 'react-notification-system-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { updateYachtSelection, deleteYachtSelection } from 'services/yachts';

export const CopyToCurrentSelectionModal = ({ isOpen, toggle, selection }) => {
    const [loading, setLoading] = useState(false);

    const handleReplaceSelection = async () => {
        const newSelection = selection
            .map((selection) => selection?.yachts || [])
            .flat()
            .reverse();

        setLoading(true);
        try {
            await deleteYachtSelection();

            for (const yacht of newSelection) {
                try {
                    await updateYachtSelection(yacht.id);
                } catch (err) {
                    error({
                        message: `Error adding yacht with id ${yacht.id} to selection`
                    });
                }
            }
        } catch (err) {
            error({
                message: `Error updating selection`
            });
        }
        setLoading(false);

        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Copy to current selection</ModalHeader>
            <ModalBody>
                The yachts in your Current selection will be cleared and
                replaced by the yachts of this Sales Listing. Are you sure you
                want to proceed?
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <Button
                    color="danger"
                    onClick={handleReplaceSelection}
                    disabled={loading}
                >
                    {loading
                        ? 'This may take a long time, please don’t close this window…'
                        : 'Yes, replace the current selection'}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CopyToCurrentSelectionModal;
