import React, { Component } from 'react';
import { FormGroup, Label } from 'reactstrap';
import ReactSVG from 'react-svg';

import openeye from '../../assets/svgs/open-eye.svg';
import closedEye from '../../assets/svgs/closed-eye.svg';

class RenderPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'password'
    };
  }

  switchType() {
    let type = this.state.type === 'password' ? 'text' : 'password';
    this.setState({ type: type });
  }

  render() {
    return (
      <FormGroup className={'floating-label-wrapper password-wrapper ' + this.props.className}>
        <Label
          className={
            this.props.input.value !== '' && !this.props.meta.error && !this.props.meta.warning
              ? 'float top'
              : 'float'
          }
        >
          {this.props.placeholder}
        </Label>
        <input
          className={
            this.props.meta.touched && this.props.meta.error ? 'form-control error' : 'form-control'
          }
          type={this.state.type}
          id={this.props.group}
          placeholder={this.props.placeholder}
          {...this.props.input}
        />
        <div
          className={
            this.props.meta.touched && (this.props.meta.error || this.props.meta.warning)
              ? 'float error top'
              : 'float error'
          }
        >
          {this.props.meta.error || this.props.meta.warning}
        </div>

        <button
          className="btn btn-empty btn-action"
          type="button"
          tabIndex="-1"
          onClick={this.switchType.bind(this)}
        >
          <ReactSVG
            path={this.state.type === 'password' ? closedEye : openeye}
            style={{ height: 20 }}
          />
        </button>
      </FormGroup>
    );
  }
}

export default RenderPassword;
