import React from 'react';

function Loader() {
    return (
        <div className="loader mt-3">
            <i className="fa fa-spinner fa-pulse fa-3x fa-fw" />
        </div>
    );
}

export default Loader;
