import {
    UPLOAD_FILE,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAIL
} from './_types.js';

import { API_URL } from '../variables';
import { authConfig } from '../authConfig';
import { errorHandler } from '../alerts';

import axios from 'axios';
import { AUTH_ERROR } from '../../auth/_types';

const uploadFile = params => {
    const headers = {
        headers: authConfig('image'),
        params: params
    };

    console.log(params);

    return dispatch => {
        dispatch({
            type: UPLOAD_FILE
        });

        return axios
            .get(API_URL + '/auth/s3', headers)
            .then(response => {
                console.log(response);
                dispatch({
                    type: UPLOAD_FILE_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch(error => {
                dispatch({
                    type: UPLOAD_FILE_FAIL
                });
                errorHandler(dispatch, error.response, AUTH_ERROR);
            });
    };
};

export { uploadFile };
