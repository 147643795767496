import React from 'react';

const defaultImages = ['construction_news', 'yacht_news', 'economy_news', 'iyc_news'];

const NewsFeedItem = props => {
  return (
    <a
      href={props.source_url}
      target="_blank"
      rel="noopener noreferrer"
      className={`${
        props.newsLength > 1 ? 'col-12' : 'col-4'
      } ribbon-item d-flex justify-content-around align-items-center`}
    >
      <div className="col-xl-4 col-md-6 p-0 ribbon-img-wrapper" style={{ height: 104, width: 104 }}>
        {props.date && (
          <div className="ribbon-img-label d-flex justify-content-center align-items-center text-uppercase">
            {props.date}
          </div>
        )}
        {props.image && (
          <img
            className={`col-12 mx-auto p-0 ${
              defaultImages.includes(props.image_url) ? '' : 'ribbon-item-img'
            }`}
            style={{ height: 104, width: 104 }}
            src={props.image}
            alt={props.title}
          />
        )}
      </div>
      <div className="col-8 align-self-baseline">
        <div className="ribbon-item-title font-500">{props.title}</div>
        <div className="ribbon-item-desc">
          {props.description && props.description.length >= 100
            ? props.description.substring(0, 100) + '...'
            : props.description}
        </div>
      </div>
    </a>
  );
};

export default NewsFeedItem;
