import React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'reactstrap';

// Fields
import RenderField from '../../../common/components/RenderField';

class RenderToysFields extends React.Component {
    render() {
        const { read_only } = this.props;
        return (
            <div className="field-outer-wrapper">
                <Col>
                    <Row className="align-items-center">
                        <div className="filter-label font-500">JET SKIS</div>
                        <div className="col-md-1 mt-1">
                            <Field
                                name="jetskis"
                                type="number"
                                placeholder="0"
                                className="mt-0"
                                component={RenderField}
                                readOnly={read_only}
                            />
                        </div>

                        <div className="filter-label font-500">SEABOB F5S</div>
                        <div className="col-md-1 mt-1">
                            <Field
                                name="seabob"
                                type="number"
                                placeholder="0"
                                className="mt-0"
                                component={RenderField}
                                readOnly={read_only}
                            />
                        </div>

                        <div className="filter-label font-500">
                            DIVING EQUIPMENT
                        </div>
                        <div className="col-md-1 mt-1">
                            <Field
                                name="diving_equipment"
                                type="number"
                                placeholder="0"
                                className="mt-0"
                                component={RenderField}
                                readOnly={read_only}
                            />
                        </div>
                    </Row>
                    <Row className="align-items-center">
                        <div className="filter-label font-500">SNORKELING</div>
                        <div className="col-md-1 mt-1">
                            <Field
                                name="snorkeling"
                                type="number"
                                placeholder="0"
                                className="mt-0"
                                component={RenderField}
                                readOnly={read_only}
                            />
                        </div>

                        <div className="filter-label font-500">
                            PADDLE BOARD
                        </div>
                        <div className="col-md-1 mt-1">
                            <Field
                                name="paddle_board"
                                type="number"
                                placeholder="0"
                                className="mt-0"
                                component={RenderField}
                                readOnly={read_only}
                            />
                        </div>

                        <div className="filter-label font-500">FISHING ROD</div>
                        <div className="col-md-1 mt-1">
                            <Field
                                name="fishing_rod"
                                type="number"
                                placeholder="0"
                                className="mt-0"
                                component={RenderField}
                                readOnly={read_only}
                            />
                        </div>
                    </Row>
                </Col>
            </div>
        );
    }
}

export default RenderToysFields;
