import PhotoGalleryWithLabels from 'common/components/PhotoGalleryWithLabels';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchYacht, fetchManageMedia, fetchGetYachtMedia } from './_actions';

import _without from 'lodash/without';
import _map from 'lodash/map';

import YachtMenu from 'pages/yachts/components/YachtMenu';
import UploadField from 'common/components/UploadField';

class MediaYacht extends Component {
    constructor(props) {
        super(props);

        let view = 'view';

        if (props.location.pathname.includes('/yachts/edit/')) {
            view = 'edit';
        }

        this.state = {
            loaded: false,
            uploading: false,
            view: view,
            photo: [],
            blueprint: [],
            document: [],
            selectedImage: null,
            blueprintsAcc: '.jpg, .jpeg, .png',
            documentsAcc:
                '.pdf, .xls, .xlsx, .xlr, .ods, .doc, .docx, .odt, .txt',
            types: ['photo_sales', 'photo_charter', 'blueprint', 'document']
        };
    }

    addMedia = (urls, name, type = null) => {
        let params = {
            id: this.props.match.params.id,
            chartering:
                type !== null && (type === 'sales' || type === 'photo_sales')
                    ? 0
                    : 1,
            type: name
        };
        let state_name =
            name === 'photo'
                ? type === 'sales' || type === 'photo_sales'
                    ? 'photo_sales'
                    : 'photo_charter'
                : name;

        params.urls = this.state[state_name]
            .map((i) => {
                return { url: i.url, label: i.label };
            })
            .concat(
                urls.map((i) => {
                    return { url: i, label: '' };
                })
            );

        this.setState({
            [state_name]: params.urls.map((url) => {
                return { ...url };
            })
        });

        if (this.props.match.params.id) {
            this.props
                .fetchManageMedia({ ...params, hasChanges: true })
                .then(() => {
                    this.splitPhotosToTypes();
                });
        }
    };

    removeMedia(value, index, type) {
        if (value.id) {
            let params = {
                media_id: value.id,
                type: type,
                id: this.props.match.params.id
            };
            //type :  'photo_sales', 'photo_charter' , 'blueprint' , 'document'
            this.props
                .fetchManageMedia({ ...params, hasChanges: true })
                .then(() => {
                    this.setState({
                        [type]: _without(this.state[type], value)
                    });
                });
        }
    }

    updateLabel = (label, itemIndex, mediaType, name) => {
        this.setState(
            {
                [mediaType]: this.state[mediaType].map((item, index) => {
                    return itemIndex === index ? { ...item, label } : item;
                })
            },
            async () => {
                await this.addMedia([], name, mediaType);
            }
        );
    };

    array_move(arr, old_index, new_index) {
        let reordered = [...arr];

        const temp = { ...reordered[old_index] };
        reordered.splice(old_index, 1);
        reordered.splice(new_index, 0, temp);

        return reordered;
    }

    reorderMedia(startIndex, endIndex, type) {
        //type :  'photo_sales', 'photo_charter' , 'blueprint' , 'document'
        const newArray = this.array_move(
            this.state[type],
            startIndex,
            endIndex
        );

        this.setState({
            ...this.state,
            [type]: newArray,
            uploading: true
        });

        //save order
        let params = {
            id: this.props.match.params.id,
            chartering: type.startsWith('photo')
                ? type === 'photo_sales'
                    ? 0
                    : 1
                : null,
            type: type.startsWith('photo') ? 'photo' : type
        };

        params.urls = _map(newArray, function (value) {
            return { url: value.url, label: value?.label };
        });

        if (this.props.match.params.id) {
            this.props
                .fetchManageMedia({ ...params, hasChanges: true })
                .then(() => {
                    this.splitPhotosToTypes();
                });
        }
    }

    componentDidMount() {
        this.props.fetchYacht({ id: this.props.match.params.id });

        this.splitPhotosToTypes();
    }

    splitPhotosToTypes() {
        if (this.props.match.params.id) {
            this.state.types.forEach((type) => {
                let params =
                    type === 'photo_sales' || type === 'photo_charter'
                        ? {
                              type: 'photo',
                              id: this.props.match.params.id,
                              chartering: type === 'photo_sales' ? 0 : 1
                          }
                        : { type: type, id: this.props.match.params.id };

                this.props.fetchGetYachtMedia(params).then((data) => {
                    let newArray = _map(data, function (value) {
                        return {
                            url: value.url,
                            id: value.id,
                            label: value.label
                        };
                    });

                    this.setState({ [type]: newArray, uploading: false });
                });
            });
        }
    }

    render() {
        const { yacht } = this.props;

        return (
            <div className="content-inner-wrapper">
                <YachtMenu
                    user_role={this.props.user_role}
                    match={this.props.match}
                    history={this.props.history}
                />
                <div className="content-inner">
                    <div className="row">
                        {yacht && yacht.sales === 1 && (
                            <div className="col-8">
                                {this.state.photo_sales &&
                                    this.state.photo_sales.length > 0 && (
                                        <PhotoGalleryWithLabels
                                            galleryPhotos={
                                                this.state.photo_sales
                                            }
                                            closeButton={
                                                this.state.view === 'edit'
                                            }
                                            updateLabel={this.updateLabel}
                                            remove={(a, b) =>
                                                this.removeMedia(
                                                    a,
                                                    b,
                                                    'photo_sales'
                                                )
                                            }
                                            reorder={(startIndex, endIndex) =>
                                                this.reorderMedia(
                                                    startIndex,
                                                    endIndex,
                                                    'photo_sales'
                                                )
                                            }
                                            label="SALES GALLERY PHOTOS"
                                            edit={this.state.view === 'edit'}
                                            loading={this.state.uploading}
                                            galleryType="grid"
                                            mediaType={'photo_sales'}
                                            name="photo"
                                        />
                                    )}

                                {this.state.view === 'edit' && (
                                    <UploadField
                                        name="photo"
                                        desc="Dimension of photo should be 1920x1080"
                                        accept={this.state.blueprintsAcc}
                                        fileLabelName="Click here to upload sales photo(s)"
                                        add={(urls, nam) =>
                                            this.addMedia(urls, nam, 'sales')
                                        }
                                        type="multiple"
                                        className="mt-1"
                                    />
                                )}
                                {this.state.view !== 'edit' &&
                                    this.state.photo &&
                                    this.state.photo.length > 0 && (
                                        <div className="mb-2" />
                                    )}
                            </div>
                        )}

                        {yacht && yacht.chartering === 1 && (
                            <div className="col-8">
                                {this.state.photo_charter &&
                                    this.state.photo_charter.length > 0 && (
                                        <PhotoGalleryWithLabels
                                            galleryPhotos={
                                                this.state.photo_charter
                                            }
                                            closeButton={
                                                this.state.view === 'edit'
                                            }
                                            updateLabel={this.updateLabel}
                                            remove={(a, b) =>
                                                this.removeMedia(
                                                    a,
                                                    b,
                                                    'photo_charter'
                                                )
                                            }
                                            reorder={(startIndex, endIndex) =>
                                                this.reorderMedia(
                                                    startIndex,
                                                    endIndex,
                                                    'photo_charter'
                                                )
                                            }
                                            label="CHARTER GALLERY PHOTOS"
                                            edit={this.state.view === 'edit'}
                                            loading={this.state.uploading}
                                            galleryType="grid"
                                            mediaType={'photo_charter'}
                                            name="photo"
                                        />
                                    )}

                                {this.state.view === 'edit' && (
                                    <UploadField
                                        name="photo"
                                        desc="Dimension of photo should be 1920x1080"
                                        accept={this.state.blueprintsAcc}
                                        fileLabelName="Click here to upload charter photo(s)"
                                        add={(urls, nam) =>
                                            this.addMedia(urls, nam, 'charter')
                                        }
                                        type="multiple"
                                        className="mt-1"
                                    />
                                )}
                                {this.state.view !== 'edit' &&
                                    this.state.photo &&
                                    this.state.photo.length > 0 && (
                                        <div className="mb-2" />
                                    )}
                            </div>
                        )}

                        <div className="col-8">
                            {this.state.blueprint &&
                                this.state.blueprint.length > 0 && (
                                    <PhotoGalleryWithLabels
                                        galleryPhotos={this.state.blueprint}
                                        closeButton={this.state.view === 'edit'}
                                        updateLabel={this.updateLabel}
                                        remove={(a, b) =>
                                            this.removeMedia(a, b, 'blueprint')
                                        }
                                        label="BLUEPRINTS"
                                        edit={this.state.view === 'edit'}
                                        reorder={(startIndex, endIndex) =>
                                            this.reorderMedia(
                                                startIndex,
                                                endIndex,
                                                'blueprint'
                                            )
                                        }
                                        galleryType="grid"
                                        mediaType={'blueprint'}
                                        name="blueprint"
                                    />
                                )}

                            {this.state.view === 'edit' && (
                                <UploadField
                                    name="blueprint"
                                    desc="Accepted image types: .jpeg, .jpg, .png"
                                    accept={this.state.blueprintsAcc}
                                    fileLabelName="Click here to upload blueprint(s)"
                                    add={this.addMedia.bind(this)}
                                    type="multiple"
                                    className="mt-1"
                                />
                            )}
                            {this.state.view !== 'edit' &&
                                this.state.blueprint &&
                                this.state.blueprint.length > 0 && (
                                    <div className="mb-2" />
                                )}
                        </div>

                        <div className="col-8">
                            {this.state.document &&
                                this.state.document.length > 0 && (
                                    <PhotoGalleryWithLabels
                                        galleryPhotos={this.state.document}
                                        closeButton={this.state.view === 'edit'}
                                        remove={(a, b) =>
                                            this.removeMedia(a, b, 'document')
                                        }
                                        label="DOCUMENTS"
                                        updateLabel={this.updateLabel}
                                        reorder={(startIndex, endIndex) =>
                                            this.reorderMedia(
                                                startIndex,
                                                endIndex,
                                                'document'
                                            )
                                        }
                                        edit={this.state.view === 'edit'}
                                        galleryType="grid"
                                        mediaType={'document'}
                                        name="document"
                                    />
                                )}

                            {this.state.view === 'edit' && (
                                <UploadField
                                    name="document"
                                    desc="Accepted file types: .pdf, .xls, .xlsx, .xlr, .ods, .doc, .docx, .odt, .pdf, .txt"
                                    accept={this.state.documentsAcc}
                                    fileLabelName="Click here to upload document(s)"
                                    add={this.addMedia.bind(this)}
                                    type="multiple"
                                    className="mt-1"
                                />
                            )}
                            {this.state.view !== 'edit' &&
                                this.state.document &&
                                this.state.document.length > 0 && (
                                    <div className="mb-2" />
                                )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user_role: state.auth.user_role,
        yacht: state.yachts.yacht.initYacht
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchYacht: (e) => dispatch(fetchYacht(e)),
        fetchManageMedia: (e) => dispatch(fetchManageMedia(e)),
        fetchGetYachtMedia: (e) => dispatch(fetchGetYachtMedia(e))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaYacht);
