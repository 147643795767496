import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Field } from 'redux-form';
import ReactSVG from 'react-svg';
import upload from '../../../assets/svgs/upload.svg';

const UploadField = ({
    id,
    disabled,
    action,
    icon,
    fileType,
    label,
    name,
    accept,
    multiple,
    handleChange,
    onSuccess,
    onError
}) => {
    const [loading, setLoading] = React.useState(false);

    const handleFileSelect = (evt) => {
        /*const reader = new FileReader();
        const files = evt.target.files;
        let fileBase64 = null;

        setLoading(true);
        reader.addEventListener(
            'load',
            (ev) => {
                fileBase64 = ev.target.result.split(',')[1];

                uploadFile(
                    fileBase64,
                    files[0].name,
                    files[0].name,
                    null,
                    fileType
                )
                    .then((res) => {
                        onSuccess(res);
                    })
                    .catch((err) => {
                        onError(err);
                    })
                    .finally(() => setLoading(false));
            },
            false
        );

        reader.readAsDataURL(files[0]);*/

        const files = evt.target.files;
        let formData = new FormData();
        formData.append('file', files[0]);
        setLoading(true);
        action(formData)
            .then((res) => {
                onSuccess(res);
            })
            .catch((err) => {
                onError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <FormGroup className="floating-label-wrapper upload-panel default-panel ml-auto form-group">
            {/* <Label className="upload-label text-uppercase">{label}</Label>*/}

            <label className="upload-input">
                <input
                    name={`name`}
                    accept={accept}
                    multiple={multiple}
                    disabled={loading || disabled}
                    id={id}
                    type="file"
                    className={'uploadField__input'}
                    onChange={(e) => {
                        if (handleChange) {
                            handleChange(e);
                        }
                        handleFileSelect(e);
                    }}
                    onClick={(event) => {
                        event.target.value = null;
                    }}
                />

                <div className="input-wrap">
                    <div className="row">
                        <div className="col upload-icon-photo">
                            <div className="d-inline-block">
                                {loading === true ? (
                                    <div className="loading" />
                                ) : (
                                    <ReactSVG
                                        path={
                                            icon !== undefined ? icon : upload
                                        }
                                        style={{ height: 16 }}
                                    />
                                )}
                            </div>
                            {label && (
                                <div className="d-inline-block ml-1 label-text">
                                    {label}
                                </div>
                            )}
                        </div>
                        {/*  {desc !== undefined && box === undefined && (
                            <div className="description col-6">
                                {this.props.desc}
                            </div>
                        )}*/}
                    </div>
                </div>
            </label>
        </FormGroup>
    );
};

UploadField.defaultProps = {
    accept: 'application/pdf',
    selectionType: 'default'
};

export default React.memo(UploadField);
