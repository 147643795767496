import _isBoolean from 'lodash/isBoolean';
import { S3_BUCKET_URL } from './variables';

export const email = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Invalid email address'
        : undefined;

export const coordinates = (value) =>
    value && !/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/i.test(value)
        ? 'Invalid coordinates'
        : undefined;

export const required = (value) => (value ? undefined : 'Required');

export const slugValidation = (value) => {
    if (value && !/^[a-zA-Z0-9-]*$/.test(value)) {
        return 'Invalid slug';
    } else return value ? undefined : 'Required';
};

export const invalidDate = (value) => {
    return value ? undefined : 'Invalid Date';
};

export const imoValidationError = (value) => {
    return (value &&
        value.toString().length === 7 &&
        Number.isInteger(value)) ||
        !value
        ? undefined
        : 'This field must be a 7 digit integer number';
};

export const warn = (values) => {
    const errors = {};
    if (values.password && values.password_confirmation) {
        if (values.password !== values.password_confirmation)
            errors.password_confirmation = "Passwords don't match";
    }
    return errors;
};

const minLength = (min) => (value) => {
    return value && value.length < min
        ? `Must be at least ${min} characters`
        : undefined;
};

export const minLength6 = minLength(6);

export const maxLength = (max) => (value) => {
    return value && value.length > max
        ? `Must be less than ${max} characters`
        : undefined;
};

export const normalizePrice = (value, previousValue) => {
    if (!value) {
        return value;
    }

    let val = value.toString().replace(/,/g, '');

    if (isNaN(val) === true) {
        return previousValue;
    }

    return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const formattedPriceToNumber = (value) => {
    if (!value) {
        return null;
    }

    return Number(value.toString().replace(/,/g, ''));
};

export const videoUrl = (value) =>
    value &&
    !value.startsWith(S3_BUCKET_URL) &&
    value &&
    !/(?:https?:\/{2})?(?:w{3}\.)?(vimeo.com|youtu(?:be)?\.(?:com|be))/.test(
        value
    )
        ? 'Not a valid video url'
        : undefined;

export const isBoolean = (v) => {
    return _isBoolean(v) ? v : false;
};
