import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import RenderField from '../../common/components/RenderField';
import { Field } from 'redux-form';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import RenderDatePicker from '../../common/components/RenderDatePicker';
import LocationTree from '../../common/components/locations/LocationTree';

import ToggleCollapse from '../../common/components/ToggleCollapse';
import ExtraFilters from './ExtraFilters';

import { dropdowns } from '../../helpers/variables';
import { normalizePrice } from '../../helpers/validate';
import builders from '../../infrastructure/data/builders';

const FiltersDropdown = (props) => {
    return (
        <div className="p-1">
            <div className="row">
                <div className="col-lg-4 col-5">
                    <div className="row">
                        <div className="col-12 mt-2">
                            <Field
                                name="has_types"
                                placeholder="Type"
                                className="mt-0 mb-2"
                                options={
                                    props.type === 'sales'
                                        ? props.types
                                        : dropdowns['types']
                                }
                                multi={true}
                                component={RenderReactSelect}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <Field
                                name="gte_year_built"
                                parse={(value) => (value ? Number(value) : '')}
                                type="number"
                                placeholder="YEAR BUILT FROM"
                                className="mt-0 mb-2"
                                component={RenderField}
                            />
                        </div>

                        <div className="col-6 pl-0">
                            <Field
                                name="lte_year_built"
                                parse={(value) => (value ? Number(value) : '')}
                                type="number"
                                placeholder="YEAR BUILT TO"
                                className="mt-0 mb-2"
                                component={RenderField}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <Field
                                name="gte_loa"
                                parse={(value) => (value ? Number(value) : '')}
                                type="number"
                                placeholder="LOA FROM"
                                className="mt-0 mb-2"
                                component={RenderField}
                            />
                        </div>
                        <div className="col-4 pl-0">
                            <Field
                                name="lte_loa"
                                parse={(value) => (value ? Number(value) : '')}
                                type="number"
                                placeholder="LOA TO"
                                className="mt-0 mb-2"
                                component={RenderField}
                            />
                        </div>
                        <div className="col-4">
                            <Field
                                name="loa_unit"
                                label=""
                                component={RenderReactSelect}
                                className="mt-0 mb-2"
                                clearable={false}
                                options={[
                                    { value: 'meters', label: 'Meter' },
                                    { value: 'feet', label: 'Feet' }
                                ]}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <Field
                                name={
                                    props.type === 'sales'
                                        ? 'gte_guests'
                                        : 'gte_guests_charter'
                                }
                                parse={(value) => (value ? Number(value) : '')}
                                type="number"
                                placeholder="GUESTS FROM"
                                className="mt-0 mb-2"
                                component={RenderField}
                            />
                        </div>

                        <div className="col-6 pl-0">
                            <Field
                                name={
                                    props.type === 'sales'
                                        ? 'lte_guests'
                                        : 'lte_guests_charter'
                                }
                                parse={(value) => (value ? Number(value) : '')}
                                type="number"
                                placeholder="GUESTS TO"
                                className="mt-0 mb-2"
                                component={RenderField}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <Field
                                name={
                                    props.type === 'sales'
                                        ? 'between_cabins[0]'
                                        : 'between_cabins_charter[0]'
                                }
                                parse={(value) => (value ? Number(value) : '')}
                                type="number"
                                placeholder="CABINS FROM"
                                className="mt-0 mb-2"
                                component={RenderField}
                            />
                        </div>
                        <div className="col-6 pl-0">
                            <Field
                                name={
                                    props.type === 'sales'
                                        ? 'between_cabins[1]'
                                        : 'between_cabins_charter[1]'
                                }
                                parse={(value) => (value ? Number(value) : '')}
                                type="number"
                                placeholder="CABINS TO"
                                className="mt-0 mb-2"
                                component={RenderField}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-5 col-lg-4 offset-1 offset-lg-2 d-flex flex-direction-column">
                    <div className="row">
                        {props.type === 'sales' && (
                            <div className="col-12 mt-2">
                                <Field
                                    name="includes_like_builder"
                                    placeholder="BUILDER"
                                    className=" mt-0 mb-2"
                                    options={builders.map((builder) => ({
                                        value: builder,
                                        label: builder
                                    }))}
                                    multi={true}
                                    component={RenderReactSelect}
                                    help={'You can select multiple values.'}
                                />
                            </div>
                        )}

                        {props.type !== 'sales' && (
                            <>
                                <div className="row">
                                    <div className="col-12 field-outer-wrapper">
                                        <label
                                            className="filter-label font-500 text-uppercase"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            Booking availability
                                            <div
                                                className="btn btn-indication"
                                                id="info-booking"
                                                style={{
                                                    margin: '0 0 0 5px',
                                                    width: '24px',
                                                    height: '24px',
                                                    justifyContent: 'center',
                                                    alignSelf: 'center',
                                                    padding: '15px'
                                                }}
                                            >
                                                i
                                            </div>
                                            <UncontrolledTooltip
                                                placement="left"
                                                target="info-booking"
                                            >
                                                Provide a date range within
                                                which to search for booking
                                                availability
                                            </UncontrolledTooltip>
                                        </label>
                                    </div>
                                    <div className="col-6 customDatePicker">
                                        <Field
                                            name="bookings_date_from"
                                            component={RenderDatePicker}
                                            placeholder="DATE FROM"
                                            className="mt-0"
                                            date_format={'YYYY-MM-DD'}
                                        />
                                    </div>

                                    <div className="col-6 customDatePicker">
                                        <Field
                                            name="bookings_date_to"
                                            component={RenderDatePicker}
                                            placeholder="DATE TO"
                                            className="mt-0"
                                            date_format={'YYYY-MM-DD'}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 field-outer-wrapper">
                                        <label
                                            className="filter-label font-500 text-uppercase"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            RATES & LOCATION DATE RANGE
                                            <div
                                                className="btn btn-indication"
                                                id="info-button"
                                                style={{
                                                    margin: '0 0 0 5px',
                                                    width: '24px',
                                                    height: '24px',
                                                    justifyContent: 'center',
                                                    alignSelf: 'center',
                                                    padding: '15px'
                                                }}
                                            >
                                                i
                                            </div>
                                            <UncontrolledTooltip
                                                placement="bottom"
                                                target="info-button"
                                            >
                                                Provide a date range within
                                                which to search for selected
                                                rates and selected locations
                                            </UncontrolledTooltip>
                                        </label>
                                    </div>
                                    <div className="col-6 customDatePicker">
                                        <Field
                                            type="text"
                                            name="charter_rates_locations_date_from"
                                            component={RenderDatePicker}
                                            placeholder="DATE FROM"
                                            date_format="YYYY/MM/DD"
                                        />
                                    </div>

                                    <div className="col-6 customDatePicker">
                                        <Field
                                            type="text"
                                            name="charter_rates_locations_date_to"
                                            component={RenderDatePicker}
                                            placeholder="DATE TO"
                                            date_format="YYYY/MM/DD"
                                        />
                                    </div>
                                </div>
                            </>
                        )}

                        {props.type !== 'sales' && (
                            <div className="row">
                                <div className="col-4 customDatePicker">
                                    <Field
                                        name="charter_rates_from"
                                        type="number"
                                        component={RenderField}
                                        placeholder="RATE FROM"
                                    />
                                </div>

                                <div className="col-4 customDatePicker">
                                    <Field
                                        name="charter_rates_to"
                                        type="number"
                                        component={RenderField}
                                        placeholder="RATE TO"
                                    />
                                </div>

                                <div className="col-4">
                                    <Field
                                        name={`charter_rates_currency`}
                                        component={RenderReactSelect}
                                        clearable={false}
                                        options={[
                                            { value: 'usd', label: 'USD' },
                                            { value: 'eur', label: 'EURO' },
                                            { value: 'gbp', label: 'GBP' },
                                            { value: 'aud', label: 'AUD' }
                                        ]}
                                        className="mt-0"
                                    />
                                </div>
                            </div>
                        )}

                        {props.type !== 'sales' && (
                            <div className="col-12">
                                <Field
                                    name="locations_tree_filter"
                                    component={LocationTree}
                                    placeholder="LOCATION"
                                    should_cascade={true}
                                    only_for_yachts={true}
                                />
                            </div>
                        )}

                        {props.type === 'sales' && (
                            <div className="col-12">
                                <Field
                                    name="include_hull_material"
                                    placeholder="HULL MATERIAL"
                                    options={dropdowns['hull_material']}
                                    multi={true}
                                    component={RenderReactSelect}
                                    help={'You can select multiple values.'}
                                    className="mt-0 mb-2"
                                />
                            </div>
                        )}

                        {props.type === 'sales' && (
                            <div className="col-12">
                                <Field
                                    name="include_num_engines"
                                    placeholder="NUMBER OF ENGINES"
                                    options={dropdowns['num_engines']}
                                    multi={true}
                                    component={RenderReactSelect}
                                    className="mt-0 mb-2"
                                    help={'You can select multiple value.'}
                                />
                            </div>
                        )}

                        {props.type === 'sales' && (
                            <div className="col-12">
                                <Field
                                    name="like_engine_manufacturer"
                                    type="text"
                                    placeholder="ENGINE MANUFACTURER"
                                    className="mt-0 mb-2"
                                    component={RenderField}
                                />
                            </div>
                        )}
                    </div>

                    {props.type === 'sales' && (
                        <div className="row">
                            <div className="col-12 field-outer-wrapper">
                                <label className="filter-label font-500 text-uppercase">
                                    ASKING PRICE
                                </label>
                            </div>
                            <div className="col-4">
                                <Field
                                    name="gte_asking_price"
                                    type="text"
                                    placeholder="FROM"
                                    className="mt-0 mb-2"
                                    normalize={normalizePrice}
                                    component={RenderField}
                                />
                            </div>
                            <div className="col-4 pl-0">
                                <Field
                                    name="lte_asking_price"
                                    type="text"
                                    placeholder="TO"
                                    className="mt-0 mb-2"
                                    normalize={normalizePrice}
                                    component={RenderField}
                                />
                            </div>
                            <div className="col-4 pr-0 pr-lg-1">
                                <Field
                                    name={`season_currency`}
                                    component={RenderReactSelect}
                                    className="mt-0 mb-2"
                                    clearable={false}
                                    options={[
                                        { value: 'usd', label: 'USD' },
                                        { value: 'eur', label: 'EURO' },
                                        { value: 'gbp', label: 'GBP' },
                                        { value: 'aud', label: 'AUD' }
                                    ]}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-1">
                    <ToggleCollapse
                        initOpen={false}
                        body={
                            <ExtraFilters
                                states={props.states}
                                countries={props.countries}
                                selectedCountry={props.selectedCountry}
                                type={props.type}
                                changeFieldValue={props.changeFieldValue}
                                newTag={props.new_builder}
                            />
                        }
                        header="EXTRA FIELDS"
                    />
                </div>
            </div>

            <div className="col-12 mb-2 text-right">
                <button
                    type="submit"
                    className="btn btn-primary btn-search  mb-2 "
                    disabled={props.submitting}
                >
                    Search
                </button>
            </div>
        </div>
    );
};

export default FiltersDropdown;
