import React from 'react';
import ReactSVG from 'react-svg';
import { Field } from 'redux-form';

// Fields
import RenderField from '../../../common/components/RenderField';
import RenderRedactor from '../../../common/components/RenderRedactor';
import UploadField from '../../../common/components/UploadField';
import ActionButton from '../../../common/components/ActionButton';

// SVG
import add from '../../../assets/svgs/add.svg';

// Helpers
import { required } from '../../../helpers/validate';

const RenderSuggestionsField = ({
    fields,
    meta: { touched, error, submitFailed },
    type
}) => (
    <div className="col-12">
        <div className="row">
            <div className="col-5 mb-2">
                <ActionButton
                    className="btn-blue"
                    icon={<ReactSVG path={add} style={{ height: 12 }} />}
                    label="Add new"
                    click={() =>
                        fields.push({
                            title: '',
                            type: type,
                            image: '',
                            text: ''
                        })
                    }
                />
            </div>
            {fields.map((i, index) => (
                <div className="col-12 mb-2" key={index}>
                    <div className="row">
                        <div className="col-8 mb-2">
                            <Field
                                name={`${i}.title`}
                                type="text"
                                className="mb-0"
                                placeholder="TITLE"
                                validate={[required]}
                                component={RenderField}
                            />

                            <Field
                                name={`${i}.type`}
                                type="hidden"
                                className="my-0"
                                component="input"
                            />
                        </div>
                        <div className="col-8 mb-2">
                            <UploadField
                                name={`${i}.image`}
                                label="Photo"
                                desc="Dimension of photo must be 860x752"
                                fileLabelName="Click here to upload photo"
                                initPhoto={fields.get(index).image}
                                type="single"
                                add={(url, name) => {
                                    let value = fields.get(index);
                                    value.image = url;
                                }}
                                className="mb-1"
                            />
                        </div>
                        <div className="col-8 mb-2">
                            <RenderRedactor
                                label="TEXT"
                                name={`${i}.text`}
                                id={`${i}.text`}
                                fieldName={fields.name}
                                index={index}
                                underName="text"
                                array="true"
                                onChange={value => {
                                    let getValue = fields.get(index);
                                    getValue.text = value;
                                }}
                            />
                        </div>
                        <div className="col-8">
                            <ActionButton
                                className="btn-danger"
                                label="Remove"
                                click={() => fields.remove(index)}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

export default RenderSuggestionsField;
