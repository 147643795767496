import React, { Component } from 'react';
import { FieldArray } from 'redux-form';

import ToggleCollapse from '../../common/components/ToggleCollapse';
import RenderFieldArray from '../../common/components/RenderFieldArray';

class SectionCharterClient extends Component {
  render() {
    return (
      <ToggleCollapse
        body={
          <div>
            <div className="row">
              <FieldArray
                name="charter_history"
                placeholder="CHARTER HISTORY"
                component={RenderFieldArray}
                readOnly={this.props.readOnly}
                moreLabel="ADD MORE"
                changeFieldValue={this.props.changeFieldValue}
                initialValues={this.props.initialValues}
                searchChange={this.props.searchChange}
                dropdownList={this.props.dropdownList}
                onClickList={this.props.onClickList}
                openSearch={this.props.openSearch}
                initFirst={true}
                className="col mt-2"
                props={{
                  customFields: [
                    {
                      name: 'date_from',
                      placeholder: 'DATE FROM',
                      type: 'date',
                      className: 'col-6 mt-1'
                    },
                    {
                      name: 'date_to',
                      placeholder: 'DATE TO',
                      type: 'date',
                      className: 'col-6 mt-1'
                    },
                    {
                      name: 'yacht_name',
                      placeholder: 'YACHT NAME',
                      type: 'text',
                      className: 'col-5',
                      searchType: 'chartering',
                      searchChange: true
                    },
                    {
                      name: 'location',
                      placeholder: 'LOCATION',
                      type: 'text',
                      className: 'col-5 offset-1'
                    }
                  ]
                }}
              />
            </div>
          </div>
        }
        header="CHARTER CLIENT"
      />
    );
  }
}

export default SectionCharterClient;
