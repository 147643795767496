import React, { Component } from 'react';
import DatePicker from './DatePicker';
import moment from 'moment';

class DateFromToPicker extends Component {
  dateTimeChange = {
    fromDate: this.fromDateChange.bind(this),
    toDate: this.toDateChange.bind(this)
  };

  fromDateChange(selected) {
    this.props.onChangeFunction({
      fromDate: selected,
      toDate: this.props.initToDate
    });
  }

  toDateChange(selected) {
    this.props.onChangeFunction({
      fromDate: this.props.initFromDate,
      toDate: selected
    });
  }

  render() {
    return (
      <div className={'datepicker-wrapper' + (this.props.disabled === true ? ' disabled' : '')}>
        <div className="dates-wrapper">
          <div className="date">
            {this.props.labels && this.props.labels.from && <label>{this.props.labels.from}</label>}
            <div className="date-inner">
              {this.props.disabled === true ? (
                moment(this.props.initFromDate).format('DD/MM/YYYY')
              ) : (
                <DatePicker
                  initDate={this.props.initFromDate}
                  afterTodayValid={this.props.afterTodayValid}
                  beforeTodayValid={this.props.beforeTodayValid}
                  todayString="Today"
                  tomorrowString="Tomorrow"
                  dateString={this.props.dateString ? this.props.dateString : 'Date'}
                  onSelect={this.dateTimeChange.fromDate}
                  hideActionButtons={this.props.hideActionButtons}
                  readOnly={this.props.readOnly}
                  formatDate={this.props.formatDate}
                />
              )}
            </div>
          </div>

          {!this.props.single && (
            <div className="date">
              <label>{this.props.labels.to}</label>
              <div className="date-inner">
                <DatePicker
                  initDate={this.props.initToDate}
                  afterTodayValid={this.props.afterTodayValid}
                  beforeTodayValid={this.props.beforeTodayValid}
                  todayString="Today"
                  tomorrowString="Tomorrow"
                  dateString={this.props.dateString ? this.props.dateString : 'Date'}
                  onSelect={this.dateTimeChange.toDate}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DateFromToPicker;
