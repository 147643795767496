import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import IndexLeadsPresenter from './IndexLeadsPresenter';
import DeleteConfirmation from '../../common/components/DeleteConfirmation';

import {
    fetchLeads,
    fetchExportLeads,
    deleteLead,
    fetchLucentForms
} from './redux/_actions';

import mail from '../../assets/svgs/mail.svg';

class IndexLeadsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            menu: {
                parent: mail,
                items: [
                    <NavLink exact to={'/bookings'} isActive={() => true}>
                        All
                    </NavLink>
                ]
            },
            tableHeaders: [
                {
                    name: 'name',
                    label: 'Name',
                    sort: false
                },
                {
                    name: 'email',
                    label: 'User Email',
                    sort: true
                },
                {
                    name: 'recipient_email',
                    label: 'Recipient Email',
                    sort: true
                },
                {
                    name: 'submitted_at',
                    label: 'Submitted On',
                    sort: true
                },
                {
                    name: 'actions',
                    label: 'Actions'
                }
            ],
            modal_header: 'You are about to delete a lead',
            modal_body: (
                <DeleteConfirmation
                    toggle={this.onToggleModal.bind(this)}
                    action={this.onDeleteConfirmed.bind(this)}
                />
            ),
            is_modal_open: false,
            lead_to_delete: null,
            selectedLucentForm: null
        };
    }

    componentDidMount() {
        this.props.fetchLucentForms();
        this.fetchLeads();
    }

    fetchLeads() {
        this.setState({ loading: true });

        let params = {
            page: 1,
            page_size: 15,
            order_field: 'submitted_at',
            order_dir: 'DESC'
        };

        if (this.state.selectedLucentForm) {
            params.name = this.state.selectedLucentForm;
        }

        this.props
            .fetchLeads(params)
            .then((data) => this.setState({ loading: false }));
    }

    onTableChange(key, value) {
        this.setState({ loading: true });
        const currentPagination = this.props.leads.pagination;
        let params = {
            page: key === 'page' ? value : 1,
            page_size:
                key === 'page_size' ? value : currentPagination.page_size,
            order_field:
                key !== 'page' && key !== 'page_size' ? key : 'submitted_at',
            order_dir: key !== 'page' && key !== 'page_size' ? value : 'DESC'
        };

        if (this.state.selectedLucentForm) {
            params.name = this.state.selectedLucentForm;
        }

        this.props
            .fetchLeads(params)
            .then((data) => this.setState({ loading: false }));
    }

    onDownloadAction() {
        this.setState({ loading: true });

        let params = {};
        if (this.state.selectedLucentForm) {
            params.name = this.state.selectedLucentForm;
        }
        this.props
            .fetchExportLeads(params)
            .then((response) => this.setState({ loading: false }));
    }

    onFormChange(value) {
        this.setState({ selectedLucentForm: value }, this.fetchLeads);
    }

    onToggleModal() {
        this.setState({ is_modal_open: !this.state.is_modal_open });
    }

    onDeleteModalToggle(id) {
        this.setState({ lead_to_delete: id });
        this.onToggleModal();
    }

    onDeleteConfirmed() {
        const id = this.state.lead_to_delete;

        this.setState({ loading: true });

        this.props.deleteLead(id).then((response) => {
            this.props
                .fetchLeads()
                .then((data) => this.setState({ loading: false }));
        });
    }

    render() {
        return (
            <IndexLeadsPresenter
                menu={this.state.menu}
                loading={this.state.loading}
                history={this.props.history}
                tableHeaders={this.state.tableHeaders}
                userRole={this.props.userRole}
                leads={this.props.leads}
                selectedLucentForm={this.state.selectedLucentForm}
                lucentForms={this.props.lucentForms}
                onTableChange={this.onTableChange.bind(this)}
                onDownloadAction={this.onDownloadAction.bind(this)}
                onFormChange={this.onFormChange.bind(this)}
                onDeleteModalToggle={this.onDeleteModalToggle.bind(this)}
                modal_header={this.state.modal_header}
                modal_body={this.state.modal_body}
                is_modal_open={this.state.is_modal_open}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userRole: state.auth.user_role,
        leads: state.leads.leads,
        lucentForms: state.leads.lucent_forms
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLeads: (params) => dispatch(fetchLeads(params)),
        fetchExportLeads: (params) => dispatch(fetchExportLeads(params)),
        deleteLead: (id) => dispatch(deleteLead(id)),
        fetchLucentForms: () => dispatch(fetchLucentForms())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndexLeadsContainer);
