import React, { Component } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';

import { Field, change } from 'redux-form';

import ReactS3Uploader from 'react-s3-uploader';

import { uploadFile } from '../../helpers/uploadFile/_actions';

import upload from '../../assets/svgs/upload.svg';

class UploadFileField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: '',
            loading: false,
            readyToSend: [],
            fileName: '',
            uploaded: false
        };
    }

    replaceName(name) {
        let newName =
            new Date().getTime() + '_' + name.replace(/[^\w\d_\-.]+/gi, '');

        this.setState({ fileName: newName });

        return newName;
    }

    getSignedUrl(file, callback) {
        let file_name = this.replaceName(file.name);

        const params = {
            filename: file_name,
            filetype: file.type
        };

        this.props.uploadFile(params).then(response => {
            let data = {
                signedUrl: response.signed
            };

            callback(data);

            this.setState({ uploaded: true });
            this.props.onUploadFinish({
                filename: file_name,
                filetype: file.type,
                path: response.url
            });
        });
    }

    onUploadFinish(value, file) {
        this.setState({ loading: false, uploaded: true });
    }

    onProgress(value) {
        this.setState({ loading: true });
    }

    onError(value) {
        console.log(value);
    }

    render() {
        const { desc, icon, box, label } = this.props;

        return (
            <div>
                {!this.state.uploaded ? (
                    <FormGroup className="floating-label-wrapper upload-panel upload-box">
                        <Label className="upload-label text-uppercase">
                            {label}
                        </Label>

                        <Field
                            name={`${this.props.name}`}
                            component="input"
                            value={this.state.url}
                            type="hidden"
                        />

                        <label className="upload-input">
                            <ReactS3Uploader
                                name={`${this.props.name}_file`}
                                getSignedUrl={this.getSignedUrl.bind(this)}
                                accept="*"
                                multiple="single"
                                onProgress={this.onProgress.bind(this)}
                                onFinish={this.onUploadFinish.bind(this)}
                                disabled={this.state.loading ? true : false}
                                onError={this.onError.bind(this)}
                            />

                            <div className="input-wrap">
                                <div className="row">
                                    <div className="col upload-icon-photo">
                                        <div className="d-inline-block">
                                            {this.state.loading === true ? (
                                                <div className="loading" />
                                            ) : (
                                                <ReactSVG
                                                    path={
                                                        icon !== undefined
                                                            ? icon
                                                            : upload
                                                    }
                                                    style={{ height: 16 }}
                                                />
                                            )}
                                        </div>
                                        {this.props.fileLabelName && (
                                            <div className="d-inline-block ml-1 label-text">
                                                {this.props.fileLabelName}
                                            </div>
                                        )}
                                    </div>
                                    {desc !== undefined &&
                                        box === undefined && (
                                            <div className="description col-6">
                                                {this.props.desc}
                                            </div>
                                        )}
                                </div>
                            </div>
                        </label>
                    </FormGroup>
                ) : (
                    <div className="row">
                        <div className="col">
                            <h2>File Details</h2>

                            <p>Name: {this.state.fileName}</p>
                            <p>Path: {this.state.filePath}</p>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change(ownProps.formName, field, value)),
        uploadFile: e => dispatch(uploadFile(e))
    };
}

function mapStateToProps(state, ownProps) {
    return {
        uploadedFile: state.uploadFile
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadFileField);
