import axios from 'axios';

import {
    FETCH_CHARTER_LISTINGS,
    FETCH_CHARTER_LISTINGS_SUCCESS,
    FETCH_CHARTER_LISTINGS_FAIL,
    CREATE_CHARTER_LISTING,
    CREATE_CHARTER_LISTING_SUCCESS,
    CREATE_CHARTER_LISTING_FAIL,
    DELETE_CHARTER_LISTING,
    DELETE_CHARTER_LISTING_SUCCESS,
    DELETE_CHARTER_LISTING_FAIL
} from './_types';

import { API_URL } from '../../helpers/variables';
import { errorHandler } from '../../helpers/alerts';
import { authConfig } from '../../helpers/authConfig';

export function fetchCharterListings(params) {
    return async (dispatch) => {
        dispatch({ type: FETCH_CHARTER_LISTINGS });

        const config = {
            headers: authConfig(),
            params
        };

        try {
            const response = await axios.get(
                API_URL + '/sales-listings/charter',
                config
            );
            dispatch({
                type: FETCH_CHARTER_LISTINGS_SUCCESS,
                payload: { ...response }
            });
        } catch (error) {
            dispatch({ type: FETCH_CHARTER_LISTINGS_FAIL });
            errorHandler(dispatch, error.response);
        }
    };
}

export function createCharterListing(data) {
    return async (dispatch) => {
        dispatch({ type: CREATE_CHARTER_LISTING });

        const config = {
            headers: authConfig()
        };

        try {
            const response = await axios.post(
                API_URL + '/sales-listings',
                data,
                config
            );
            dispatch({
                type: CREATE_CHARTER_LISTING_SUCCESS,
                payload: { ...response }
            });
        } catch (error) {
            dispatch({ type: CREATE_CHARTER_LISTING_FAIL });
            errorHandler(dispatch, error.response);
        }
    };
}

export function deleteSalesListing({ id }) {
    return async (dispatch) => {
        dispatch({ type: DELETE_CHARTER_LISTING });

        const config = {
            headers: authConfig()
        };

        try {
            const response = await axios.delete(
                `${API_URL}/sales-listings/${id}`,
                config
            );
            dispatch({
                type: DELETE_CHARTER_LISTING_SUCCESS,
                payload: { ...response }
            });
        } catch (error) {
            dispatch({ type: DELETE_CHARTER_LISTING_FAIL });
            errorHandler(dispatch, error.response);
        }
    };
}
