import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

import UpdatedYachtsTable from './UpdatedYachtsTable';
import Loader from 'common/components/Loader';
import { getUpdatedYachts } from 'services/updatedYachts';
import { errorHandler } from 'helpers/alerts';
import { NavLink } from 'react-router-dom';
import savedSearches from '../../assets/svgs/saved-searches.svg';

import axios from 'axios';
import { API_URL } from 'helpers/variables';
import { authConfig } from 'helpers/authConfig';

const table_headers = [
    {
        name: 'yacht_photo',
        label: 'Photo'
    },
    {
        name: 'yacht_name',
        label: 'Name'
    },
    {
        name: 'yacht_type',
        label: 'Type'
    },
    {
        name: 'updated_at',
        label: 'Last Updated'
    }
];

const menu = {
    parent: savedSearches,
    items: [
        <NavLink exact to={'/updated-yachts'}>
            All
        </NavLink>
    ]
};

export default function UpdatedYachts(props) {
    const dispatch = useDispatch();
    const user_role = useSelector((state) => state.auth.user_role);
    const history = useHistory();
    const match = useRouteMatch();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [updatedYachts, setUpdatedYachts] = useState([]);
    const [lastClearCache, setLastClearCache] = useState([]);

    useEffect(() => {
        loadUpdatedYachts();
    }, []);

    const loadUpdatedYachts = async () => {
        setLoading(true);
        try {
            const { data } = await getUpdatedYachts();
            setUpdatedYachts(data);
            axios
                .get(API_URL + '/status/public_changes_hook', {
                    headers: authConfig()
                })
                .then((response) => {
                    setLastClearCache(
                        moment(response.data.last_clear_cache).format(
                            'DD/MM/YYYY HH:mm:ss'
                        )
                    );
                    console.log('data', lastClearCache);
                });
        } catch (err) {
            errorHandler(dispatch, err?.response || err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="content-inner-wrapper">
            <div className="content-inner">
                {loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <Row>
                        <Col xs="12" lg="9">
                            <UpdatedYachtsTable
                                userRole={user_role}
                                history={history}
                                table_headers={table_headers}
                                data={updatedYachts}
                                lastClearCache={lastClearCache}
                                menu={menu}
                            />
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
}
