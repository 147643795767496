export const INIT_DESTINATION = 'init_destination',
    FETCH_DESTINATIONS = 'fetch_destinations',
    FETCH_DESTINATIONS_SUCCESS = 'fetch_destinations_success',
    FETCH_DESTINATIONS_ERROR = 'fetch_destinations_fail',
    FETCH_DROPDOWN_DESTINATIONS = 'fetch_dropdown_destinations',
    FETCH_DROPDOWN_DESTINATIONS_SUCCESS = 'fetch_dropdown_destinations_success',
    FETCH_DROPDOWN_DESTINATIONS_ERROR = 'fetch_dropdown_destinations_fail',
    CREATE_DESTINATION = 'create_destination',
    CREATE_DESTINATION_SUCCESS = 'create_destination_success',
    CREATE_DESTINATION_ERROR = 'create_destination_error',
    FETCH_DESTINATION = 'fetch_destination',
    FETCH_DESTINATION_SUCCESS = 'fetch_destination_success',
    FETCH_DESTINATION_ERROR = 'fetch_destination_error',
    UPDATE_DESTINATION = 'update_destination',
    UPDATE_DESTINATION_SUCCESS = 'update_destination_success',
    UPDATE_DESTINATION_ERROR = 'update_destination_error',
    DELETE_DESTINATION = 'delete_destination',
    DELETE_DESTINATION_SUCCESS = 'delete_destination_success',
    DELETE_DESTINATION_ERROR = 'delete_destination_fail';
