import moment from 'moment';

import _without from 'lodash/without';

import { functions } from '../../helpers/functions';

import _filter from 'lodash/filter';
import _startsWith from 'lodash/startsWith';
import _findIndex from 'lodash/findIndex';
import { regionCountries } from '../../helpers/variables';

const currencyFields = ['price_from', 'price_to'];
const unitFields = ['loa_from', 'loa_to'];

export function customerFormValidation(formProps, customerId, documents) {
    let params = Object.assign({}, formProps);
    let valid = true;
    params.favourite = params.favourite === true ? 1 : 0;

    params.chartering =
        params.chartering == 1 || params.chartering === 'true' ? '1' : '0';
    params.sales = params.sales == 1 || params.sales === 'true' ? '1' : '0';

    if (params.customer_type && params.customer_type === 'industry') {
        params.sales = '0';
        params.chartering = '0';
    }

    if (params.wants_to_sell === false) {
        params.sales_yacht_name = null;
        params.sales_remind_weeks = null;
    }

    if (params.sales_agreement === false) {
        params.ca_sales_yacht_name = null;
        params.ca_sales_termination_date = null;
        params.ca_sales_remind_weeks = null;
        params.enquiries = [];
    }

    if (documents.length > 0) {
        params.documents = documents;
    } else {
        params.documents = [];
    }

    if (params.emails.length > 0) {
        params.emails = _without(params.emails, undefined);

        params.emails.map((value, key) => {
            if (parseInt(params.primary_email) === key) {
                value.primary = 1;
            } else {
                value.primary = 0;
            }
            return value;
        });
    }

    if (params.phones.length > 0) {
        params.phones = _without(params.phones, undefined);

        params.phones.map((value, key) => {
            if (parseInt(params.primary_phone) === key) {
                value.primary = 1;
            } else {
                value.primary = 0;
            }
            return value;
        });
    }

    if (params.social_media.length > 0) {
        params.social_media = _without(params.social_media, undefined);
    }

    if (params.enquiries.length > 0 && params.sales_agreement === true) {
        params.enquiries = _without(params.enquiries, undefined);

        params.enquiries.map((val) => {
            if (
                val.date &&
                (moment.isMoment(val.date) ||
                    moment(val.date).format('YYYY-MM-DD') !== 'Invalid date')
            ) {
                val.date = moment(val.date).format('YYYY-MM-DD');
            } else {
                delete val.date;
            }
            return val;
        });
    }

    if (params.ca_sales_termination_date) {
        params.ca_sales_termination_date = moment(
            params.ca_sales_termination_date
        ).format('YYYY-MM-DD');
    }

    if (params.addresses.length > 0) {
        params.addresses = _without(params.addresses, undefined);

        params.addresses.map((value, key) => {
            if (parseInt(params.primary_address) === key) {
                value.primary = 1;
            } else {
                value.primary = 0;
            }
            return value;
        });
    }

    if (params.sa_termination_date) {
        params.sa_termination_date = moment(params.sa_termination_date).format(
            'YYYY-MM-DD'
        );
    }

    if (document.getElementById('customer_notes').value) {
        params.notes = document.getElementById('customer_notes').value;
    }

    delete params.boat_shows_other;
    if (
        document.getElementById('boat_shows_other_notes') &&
        document.getElementById('boat_shows_other_notes').value
    ) {
        params.other_show = document.getElementById(
            'boat_shows_other_notes'
        ).value;
    } else {
        params.other_show = '';
    }

    delete params.communication_material_other;
    if (
        document.getElementById('communication_material_notes') &&
        document.getElementById('communication_material_notes').value
    ) {
        params.other_material = document.getElementById(
            'communication_material_notes'
        ).value;
    } else {
        params.other_material = '';
    }

    if (customerId) {
        params.id = customerId;
    }

    if (params.charter_history) {
        params.charter_history = _without(params.charter_history, undefined);

        params.charter_history.forEach((val) => {
            if (
                val.date_from &&
                (moment.isMoment(val.date_from) ||
                    moment(val.date_from).format('YYYY-MM-DD') ===
                        val.date_from ||
                    moment(val.date_from).format('YYYY-MM-DD') !==
                        'Invalid date')
            ) {
                val.date_from = moment(val.date_from).format('YYYY-MM-DD');
            }

            if (
                (val.date_to &&
                    (moment.isMoment(val.date_to) ||
                        moment(val.date_to).format('YYYY-MM-DD') ===
                            val.date_to)) ||
                moment(val.date_to).format('YYYY-MM-DD') !== 'Invalid date'
            ) {
                val.date_to = moment(val.date_to).format('YYYY-MM-DD');
            }
        });
    }

    if (params.sales_history) {
        params.sales_history = _without(params.sales_history, undefined);
    }

    params.interests = {};

    if (params.wants_to_buy === true) {
        currencyFields.forEach((f) => {
            if (params[f]) {
                params['interests'][f] = params[f].toString().replace(/,/g, '');
                delete params[f];

                if (params['price_currency']) {
                    params['interests']['price_currency'] =
                        params['price_currency'];
                    delete params['price_currency'];
                }
            }
        });

        const arrayFields = ['year_built', 'type', 'cabins'];

        arrayFields.map((value, key) => {
            if (params[value]) {
                params['interests'][value] = params[value];
                delete params[value];
            }
            return value;
        });

        unitFields.forEach((f) => {
            if (params[f] && params['loa_unit']) {
                params['interests'][f] = functions.toMeters(
                    params[f],
                    params['loa_unit']
                );

                if (params['loa_unit']) {
                    params['interests']['loa_unit'] = params['loa_unit'];
                }
            }
        });
    } else {
        params['preferred_builders'] = [];
    }

    if (params.sales && params.sales === '0') {
        params.sales_history = [];
    }

    if (params.chartering && params.chartering === '0') {
        params.charter_history = [];
    }

    delete params.loa_unit;
    delete params.sales_agreement;
    delete params.wants_to_buy;
    delete params.wants_to_sell;

    return { valid: valid, params: params };
}

let includesCheckboxes = {
    sales: [
        { group: 'elevator', label: 'Elevator', sales: 1, charters: 1 },
        { group: 'helipad', label: 'Helipad', sales: 1, charters: 1 },
        { group: 'gym', label: 'Gym', sales: 1, charters: 1 },
        { group: 'jacuzzi', label: 'Jacuzzi', sales: 1, charters: 1 },
        {
            group: 'handicap',
            label: 'Wheelchair access',
            sales: 1,
            charters: 1
        },
        { group: 'beach_club', label: 'Beach Club', sales: 1, charters: 1 },
        { group: 'spa', label: 'Spa', sales: 1, charters: 1 },
        {
            group: 'night_vision_camera',
            label: 'Night vision camera',
            sales: 1,
            charters: 0
        }
    ],
    charters: [
        { group: 'elevator', label: 'Elevator', sales: 1, charters: 1 },
        { group: 'helipad', label: 'Helipad', sales: 1, charters: 1 },
        { group: 'gym', label: 'Gym', sales: 1, charters: 1 },
        { group: 'jacuzzi', label: 'Jacuzzi', sales: 1, charters: 1 },
        { group: 'stabilizers', label: 'Stabilizers', sales: 0, charters: 1 },
        {
            group: 'zero_speed_stabilizers',
            label: 'Zero speed stabilizers',
            sales: 0,
            charters: 1
        },
        {
            group: 'handicap',
            label: 'Wheelchair access',
            sales: 1,
            charters: 1
        },
        { group: 'beach_club', label: 'Beach Club', sales: 1, charters: 1 },
        { group: 'spa', label: 'Spa', sales: 1, charters: 1 },
        { group: 'barbeque', label: 'Barbeque', sales: 1, charters: 1 },
        { group: 'wifi', label: 'Wifi', sales: 0, charters: 1 },
        {
            group: 'master_on_main_deck',
            label: 'Master on main deck',
            sales: 0,
            charters: 1
        }
    ]
};

export function parseQueryFields(formProps, realRates, type = 'sales') {
    const unitFields = ['lte_draft_max', 'gte_beam', 'lte_beam'];
    let params = Object.assign({}, formProps);
    let valid = true;
    let query = {};

    unitFields.forEach((f) => {
        if (params[f]) {
            let r = f.replace('gte_', '').replace('lte_', '');
            params[f] = functions.toMeters(params[f], params[r + '_unit']);
        }
    });

    let equal = {};
    _filter(params, (o, i) => {
        if (_startsWith(i, 'equal_')) {
            equal[i.replace('equal_', '')] = o === true ? 1 : o;
            return o;
        }
    });

    let between = {};
    _filter(params, (o, i) => {
        if (_startsWith(i, 'between_')) {
            let type = between;
            let values;
            if ((o[0] && !o[1]) || (!o[0] && o[1])) {
                type = equal;
            }

            if (o[0] || o[1]) {
                if (o[2] === 'feet' || o[2] === 'meters') {
                    if (o[0] && o[1]) {
                        values = [
                            functions.toMeters(o[0], o[2]),
                            functions.toMeters(o[1], o[2])
                        ];
                    } else {
                        values = functions.toMeters(o[0] ? o[0] : o[1], o[2]);
                    }
                } else if (
                    o[2] === 'usd' ||
                    o[2] === 'eur' ||
                    o[2] === 'gbp' ||
                    o[2] === 'aud'
                ) {
                    if (o[0] && o[1]) {
                        values = [
                            functions.toDollars(
                                o[0].toString().replace(/,/g, ''),
                                o[2],
                                realRates
                            ),
                            functions.toDollars(
                                o[1].toString().replace(/,/g, ''),
                                o[2],
                                realRates
                            ),
                            o[2]
                        ];
                    } else {
                        values = functions.toDollars(
                            o[0]
                                ? o[0].toString().replace(/,/g, '')
                                : o[1].toString().replace(/,/g, ''),
                            o[2],
                            realRates
                        );
                    }
                } else {
                    if (o[0] && o[1]) {
                        values = [
                            o[0].toString().replace(/,/g, ''),
                            o[1].toString().replace(/,/g, '')
                        ];
                    } else {
                        values = o[0] ? o[0] : o[1];
                    }
                }
                type[i.replace('between_', '')] = values;
            }

            return o;
        }
    });

    let exist = {};
    if (params.exist) {
        Object.keys(params.exist).map((i) => {
            if (
                params.exist[i] &&
                params.exist[i] !== '' &&
                params.exist[i] !== undefined
            ) {
                if (includesCheckboxes[type][i] !== undefined) {
                    exist[includesCheckboxes[type][i].group] = true;
                }
            }
            return i;
        });
    }

    let include_like = {};
    _filter(params, (o, i) => {
        if (_startsWith(i, 'includes_like_')) {
            if (o.length > 0) {
                let val = [];

                o.map((j) => {
                    if (j && j.id && j.id !== undefined) val.push(j.id);
                    else if (j && j.name && j.name !== undefined)
                        val.push(j.name);
                    else if (j) val.push(j);
                    return j;
                });

                if (val.length !== 0)
                    include_like[i.replace('includes_like_', '')] = val;
            }
        }
    });

    let include = {};
    _filter(params, (o, i) => {
        if (_startsWith(i, 'include_')) {
            if (o.length > 0) {
                let val = [];

                o.map((j) => {
                    if (j && j.id && j.id !== undefined) val.push(j.id);
                    else if (j && j.name && j.name !== undefined)
                        val.push(j.name);
                    else if (j) val.push(j);
                    return j;
                });

                if (val.length !== 0) include[i.replace('include_', '')] = val;
            }
        }
    });

    let has = {};
    _filter(params, (o, i) => {
        if (_startsWith(i, 'has_')) {
            if (o.length > 0) {
                let val = [];

                o.map((j) => {
                    if (j && j.id && j.id !== undefined) val.push(j.id);
                    else if (j && j.name && j.name !== undefined)
                        val.push(j.name);
                    else if (j) val.push(j);
                    return j;
                });

                if (val.length !== 0) has[i.replace('has_', '')] = val;
            }
        }
    });

    let like = {};
    _filter(params, (o, i) => {
        if (_startsWith(i, 'like_')) {
            like[i.replace('like_', '')] = o;

            return o;
        }
    });

    let gte = {};
    _filter(params, (o, i) => {
        if (_startsWith(i, 'gte_')) {
            gte[i.replace('gte_', '')] = o;

            return o;
        }
    });

    let lte = {};
    _filter(params, (o, i) => {
        if (_startsWith(i, 'lte_')) {
            lte[i.replace('lte_', '')] = o;

            return o;
        }
    });

    if (params.season === undefined || params.season === '') {
        delete gte.summer_low_season_rate;
        delete lte.summer_low_season_rate;
        delete gte.winter_low_season_rate;
        delete lte.winter_low_season_rate;
    } else if (params.season === 'summer') {
        delete gte.winter_low_season_rate;
        delete lte.winter_low_season_rate;

        if (gte.summer_low_season_rate) {
            gte.summer_low_season_rate = functions.toDollars(
                gte.summer_low_season_rate.toString().replace(/,/g, ''),
                params.season_currency,
                realRates
            );
        }
        if (lte.summer_low_season_rate) {
            lte.summer_low_season_rate = functions.toDollars(
                lte.summer_low_season_rate.toString().replace(/,/g, ''),
                params.season_currency,
                realRates
            );
        }
    } else if (params.season === 'winter') {
        delete gte.summer_low_season_rate;
        delete lte.summer_low_season_rate;

        if (gte.winter_low_season_rate) {
            gte.winter_low_season_rate = functions.toDollars(
                gte.winter_low_season_rate.toString().replace(/,/g, ''),
                params.season_currency,
                realRates
            );
        }
        if (lte.winter_low_season_rate) {
            lte.winter_low_season_rate = functions.toDollars(
                lte.winter_low_season_rate.toString().replace(/,/g, ''),
                params.season_currency,
                realRates
            );
        }
    }

    if (valid === true) {
        if (Object.keys(equal).length > 0) {
            query.equal = equal;
        }

        if (Object.keys(between).length > 0) {
            query.between = between;
        }

        if (Object.keys(exist).length > 0) {
            query.exist = exist;
        }

        if (Object.keys(include_like).length > 0) {
            query.include_like = include_like;
        }

        if (Object.keys(include).length > 0) {
            query.include = include;
        }

        if (Object.keys(has).length > 0) {
            query.has = has;
        }

        if (Object.keys(like).length > 0) {
            query.like = like;
        }

        if (Object.keys(gte).length > 0) {
            query.gte = gte;
        }

        if (Object.keys(lte).length > 0) {
            query.lte = lte;
        }

        if (type === 'charters') {
            query['_prefix'] = this.props.prefix;
        }

        // hack
        const specialValues = Object.keys(regionCountries);
        if (query && query['include'] && query['include']['country']) {
            query['include']['country'] = query['include']['country'].reduce(
                (query, country) => {
                    if (specialValues.indexOf(country) !== -1) {
                        regionCountries[country].map((country) =>
                            query.push(country)
                        );
                    } else {
                        query.push(country);
                    }
                    return query;
                },
                []
            );
        }

        return query;
    }
}

export function getInitialQueryValues(query, ownProps = { type: 'sales' }) {
    const initialValues = {
        between_asking_price: ['', '', 'usd'],
        between_loa: ['', '', 'meters'],
        draft_max_unit: 'meters',
        beam_unit: 'meters',
        has_brokers: [''],
        has_companies: [''],
        exist: {},
        season: '',
        season_currency: 'usd'
    };

    if (!query) return initialValues;

    Object.keys(query).forEach((key) => {
        Object.keys(query[key]).forEach((key2) => {
            if (key !== '_prefix') {
                if (key === 'exist') {
                    let i = _findIndex(
                        includesCheckboxes[ownProps.type],
                        function (o) {
                            return o.group === key2;
                        }
                    );

                    if (i !== undefined) {
                        initialValues.exist[i] = true;
                    }
                } else if (key === 'has') {
                    initialValues[key + '_' + key2] = query[key][key2];
                } else if (key === 'include_like') {
                    if (key2 === 'builder') {
                        let builder = [];
                        if (query[key][key2] && query[key][key2].length > 0) {
                            query[key][key2].forEach(function (b, index) {
                                builder.push({ name: b });
                            });
                        }

                        initialValues.includes_like_builder = builder;
                    } else {
                        initialValues[key + '_' + key2] = query[key][key2];
                    }
                } else if (key === 'include') {
                    initialValues[key + '_' + key2] = query[key][key2];
                } else if (key === 'equal') {
                    if (
                        key2 === 'year_built' ||
                        key2 === 'loa' ||
                        key2 === 'asking_price' ||
                        key2 === 'cabins' ||
                        key2 === 'speed_cruising'
                    ) {
                        //correct matching for between keys that act as equal
                        initialValues['between_' + key2] = [];
                        initialValues['between_' + key2].push(query[key][key2]);
                    } else {
                        initialValues[key + '_' + key2] =
                            query[key][key2] === 1 ? true : query[key][key2];
                    }
                } else {
                    initialValues[key + '_' + key2] = query[key][key2];

                    if (key2 === 'summer_low_season_rate') {
                        initialValues.season = 'summer';
                    } else if (key2 === 'winter_low_season_rate') {
                        initialValues.season = 'winter';
                    }
                }
            }
        });
    });

    // check initialValues for special country values
    if (initialValues.include_country) {
        let cValues = initialValues.include_country.sort();
        // check if any region is included in search
        Object.keys(regionCountries).forEach((key) => {
            const countryList = regionCountries[key].sort();
            let i = 0,
                j = 0;
            while (i < cValues.length && j < countryList.length) {
                if (cValues[i] < countryList[j]) {
                    if (key === 'am-sc')
                        console.log(cValues[i], countryList[j]);
                    i++;
                } else if (cValues[i] === countryList[j]) {
                    i++;
                    j++;
                } else {
                    return;
                }
            }

            if (j !== countryList.length) {
                return;
            }

            // replace region countries with region
            const nVals = [key];
            i = 0;
            j = 0;
            while (i < cValues.length) {
                if (cValues[i] === countryList[j]) {
                    j++;
                } else {
                    nVals.push(cValues[i]);
                }
                i++;
            }
            cValues = nVals.sort();
        });
        initialValues.include_country = cValues;
    }

    return initialValues;
}
