import {
    FETCH_PUBLISH_NEWS,
    FETCH_PUBLISH_NEWS_SUCCESS,
    FETCH_PUBLISH_NEWS_FAIL,
    FETCH_NEWS,
    FETCH_NEWS_SUCCESS,
    FETCH_NEWS_FAIL,
    FETCH_EDIT_SINGLE_NEWS,
    FETCH_EDIT_SINGLE_NEWS_SUCCESS,
    FETCH_EDIT_SINGLE_NEWS_FAIL,
    FETCH_DELETE_NEWS,
    FETCH_DELETE_NEWS_SUCCESS,
    FETCH_DELETE_NEWS_FAIL,
    REORDER_NEWS,
    REORDER_NEWS_SUCCESS,
    REORDER_NEWS_FAIL
} from './_types';

const INITIAL_STATE = {
    data: []
};

export default function newsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_PUBLISH_NEWS:
            return state;
        case FETCH_PUBLISH_NEWS_SUCCESS:
            return {
                data: [
                    ...state.data.filter(post => post.id !== action.payload.id),
                    action.payload
                ]
            };
        case FETCH_PUBLISH_NEWS_FAIL:
            return { ...state, error: action.payload };
        case FETCH_NEWS:
            return { ...state };
        case FETCH_NEWS_SUCCESS:
            return { ...state, data: action.payload };
        case FETCH_NEWS_FAIL:
            return { ...state, error: action.payload };
        case FETCH_EDIT_SINGLE_NEWS:
            return state;
        case FETCH_EDIT_SINGLE_NEWS_SUCCESS:
            return {
                ...state,
                data: state.data.map(post => {
                    if (action.payload.id !== post.id) {
                        return post;
                    }
                    return {
                        ...post,
                        public: post.public === 1 ? 0 : 1
                    };
                })
            };
        case FETCH_EDIT_SINGLE_NEWS_FAIL:
            return { ...state, error: action.payload };
        case FETCH_DELETE_NEWS:
            return state;
        case FETCH_DELETE_NEWS_SUCCESS:
            return {
                ...state,
                data: state.data.filter(post => post.id !== action.postId)
            };
        case FETCH_DELETE_NEWS_FAIL:
            return { ...state, error: action.payload };
        case REORDER_NEWS:
            return state;
        case REORDER_NEWS_SUCCESS:
            return { ...state };
        case REORDER_NEWS_FAIL:
            return { ...state, error: action.payload };
        default:
            return state;
    }
}
