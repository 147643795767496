import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import SimplePaginationWrapper from '../../../common/components/pagination/SimplePaginationWrapper';
import ResultView from '../../../yachts/yacht/ResultView';
import Loader from '../../../common/components/Loader';

const SortableItem = SortableElement(
    ({ yacht, viewType, userRole, readOnly, onCheckboxChange }) => {
        return (
            <div>
                <ResultView
                    key={yacht.id}
                    viewType={viewType}
                    shouldUseSelected={true}
                    showCheckbox={true}
                    selected={yacht.selected === 1 ? true : false}
                    onCheckboxChange={onCheckboxChange}
                    userRole={userRole}
                    readOnly={readOnly}
                    yacht={yacht}
                    hideActions={true}
                />
            </div>
        );
    }
);

const SortableList = SortableContainer(
    ({ yachts, viewType, userRole, readOnly, onCheckboxChange }) => {
        return (
            <div>
                {yachts.map((yacht, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        yacht={yacht}
                        indexSort={index}
                        viewType={viewType}
                        userRole={userRole}
                        readOnly={readOnly}
                        onCheckboxChange={onCheckboxChange}
                    />
                ))}
            </div>
        );
    }
);

const YachtsList = ({
    loading,
    viewType,
    readOnly,
    userRole,
    yachts,
    selected,
    sortable,
    onTableChange,
    onCheckboxChange,
    onReorder,
    onChangeExternalUrl
}) => {
    const selectedYachts = yachts.yachts.filter(
        (yacht) => yacht.selected === 1
    );
    const unselectedYachts = yachts.yachts.filter(
        (yacht) => yacht.selected === 0
    );

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) onReorder(oldIndex, newIndex);
    };

    return (
        <div className="table search-results">
            {yachts.pagination && (
                <SimplePaginationWrapper
                    className={'pagination-header'}
                    total={yachts.pagination.total}
                    currentPage={yachts.pagination.page}
                    totalPages={yachts.pagination.page_count}
                    pageChangeFunction={onTableChange}
                    pageSize={yachts.pagination.page_size}
                    selected={selected}
                    hidePagination={true}
                    hideExtraFilter={true}
                />
            )}

            <div className="yachts-wrapper mt-2">
                {loading && <Loader />}

                {!sortable &&
                    !loading &&
                    selectedYachts.map((yacht, index) => {
                        return (
                            <ResultView
                                key={yacht.id}
                                viewType={viewType}
                                shouldUseSelected={true}
                                showCheckbox={true}
                                selected={yacht.selected === 1 ? true : false}
                                onCheckboxChange={onCheckboxChange}
                                userRole={userRole}
                                showExternalUrl={true}
                                readOnly={readOnly}
                                yacht={yacht}
                                hideActions={true}
                                onChangeExternalUrl={onChangeExternalUrl}
                            />
                        );
                    })}

                {sortable && !loading && (
                    <SortableList
                        yachts={selectedYachts}
                        viewType={viewType}
                        userRole={userRole}
                        readOnly={readOnly}
                        axis={'y'}
                        onCheckboxChange={onCheckboxChange}
                        onSortEnd={onSortEnd}
                        pressDelay={200}
                    />
                )}

                {!loading && unselectedYachts.length > 0 && (
                    <div style={{ margin: '20px 0' }}>
                        <h5 style={{ color: '#41506e', marginBottom: '10px' }}>
                            YACHTS FROM CURRENT SELECTION
                        </h5>
                        <p
                            style={{ color: '#41506e', marginBottom: '10px' }}
                            className="text--helper"
                        >
                            In order to add them to this list, please check them
                            and click 'Save'
                        </p>
                    </div>
                )}

                {!loading &&
                    unselectedYachts.map((yacht, index) => {
                        return (
                            <ResultView
                                key={yacht.id}
                                viewType={viewType}
                                shouldUseSelected={true}
                                showCheckbox={true}
                                selected={yacht.selected === 1 ? true : false}
                                onCheckboxChange={onCheckboxChange}
                                userRole={userRole}
                                readOnly={readOnly}
                                yacht={yacht}
                                hideActions={true}
                                onChangeExternalUrl={onChangeExternalUrl}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export default YachtsList;
