import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Field, change, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import Notifications from 'react-notification-system-redux';

import Header from '../../common/header/Header';

import salesListings from '../../assets/svgs/saleslistings_icon-02.svg';

import RenderField from '../../common/components/RenderField';

import UploadField from '../../common/components/UploadField';

import Loader from '../../common/components/Loader';

import { required } from '../../helpers/validate';

import { fetchCharterListings } from './_actions';

import api from '../../api';

import CharterListingYachts from './CharterListingYachts';

const form = reduxForm({
    form: 'manage_charter_listing'
});

const WAIT_INTERVAL = 450;

class ManageCharterListing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                parent: salesListings,
                items: [
                    <NavLink exact to="/charter-listings">
                        Charter Listings
                    </NavLink>
                ],
                backLink: '/charter-listings'
            },
            open: false,
            suggestions: {
                selected: '',
                list: []
            },
            dropdownPlaces: [],
            cover_photo: '',
            cover_media_type: 'photo',
            yachts: [],
            is_public: 0,
            loading: true
        };
    }

    async UNSAFE_componentWillMount() {
        if (this.props.match.params && this.props.match.params.id) {
            const id = this.props.match.params.id;
            this.setState({
                menu: {
                    ...this.state.menu,
                    items: [
                        // ...this.state,
                        <NavLink exact to={'/charter-listings/edit/' + id}>
                            Edit Charter Listing
                        </NavLink>
                    ]
                },
                charterListingId: id
            });
            const charterListing = await api.salesListings.fetchById(id);
            this.props.changeFieldValue(
                'public_title',
                charterListing.public_title
            );
            this.props.changeFieldValue(
                'internal_title',
                charterListing.internal_title
            );
            this.props.changeFieldValue(
                'cover_photo',
                charterListing.cover_photo_url
            );

            this.setState({
                yachts: charterListing.yachts,
                cover_photo: charterListing.cover_photo_url,
                is_public: charterListing.is_public,
                loading: false,
                chartering: charterListing.chartering
            });
        }
    }

    toggle() {
        this.setState({
            open: !this.state.open
        });
    }

    addPhoto(url, name) {
        this.setState({ cover_photo: url });
        this.props.changeFieldValue('cover_photo', url);
    }

    async removeYacht(yachtId) {
        this.setState({ loading: true });
        const { charterListingId: id } = this.state;
        await api.salesListings.removeYacht(id, yachtId);
        const charterListing = await api.salesListings.fetchById(id);
        this.setState({ yachts: charterListing.yachts });
        this.setState({ loading: false });
    }

    async updateYacht(data) {
        const { charterListingId } = this.state;
        await api.salesListings.updateYacht(charterListingId, data);

        const charterListing = await api.salesListings.fetchById(
            charterListingId
        );
        this.setState({
            yachts: charterListing.yachts
        });
    }

    async onOrderChanged(event) {
        await api.salesListings.updateYachtsOrder(
            this.state.charterListingId,
            event.yachtID,
            event.source,
            event.destination
        );
    }

    async handleFormSubmit(formProps) {
        const id = this.props.match.params && this.props.match.params.id;

        const {
            public_title,
            internal_title,
            cover_photo: cover_photo_url
        } = formProps;

        const { is_public } = this.state;

        const body = {
            public_title,
            internal_title,
            is_public
        };

        body.cover_photo_url = cover_photo_url ? cover_photo_url : '';

        const resp = await api.salesListings.update(id, body);
        if (resp) {
            this.props.notification(
                'info',
                'Success',
                'Charter Listing updated'
            );
        } else {
            this.props.notification(
                'error',
                'Error',
                'Failed to update Charter Listing'
            );
        }
        this.props.history.push('/charter-listings');
    }

    cancelForm() {
        this.props.history.push('/charter-listings');
    }

    changePublic() {
        const { is_public } = this.state;
        this.setState({ is_public: !is_public });
    }

    selectAll = (e) => {
        const YachtsObject =
            this.props.search_type === 'current'
                ? this.props.selectedYachts
                : this.props.yachts;
        const selectedYachts = YachtsObject.yachts.reduce((obj, y) => {
            obj[y.id] = true;
            return obj;
        }, {});
        this.setState({ selectedYachts });
    };

    tableChange(key, newValue) {
        this.setState({ selectedYachts: {} });
        let params = {
            page: key !== 'page' ? 1 : newValue,
            order_dir:
                key !== 'page' && key !== 'page_size' && key !== 'search'
                    ? newValue
                    : this.props.yachts.sorting.order_dir,
            order_field:
                key !== 'page' && key !== 'page_size' && key !== 'search'
                    ? key
                    : this.props.yachts.sorting.order_field,
            page_size:
                key === 'page_size'
                    ? newValue
                    : this.props.yachts.pagination.page_size,
            id: this.props.search_id
        };

        this.props.fetchSavedSearchResults(params);
    }

    render() {
        const { handleSubmit, submitting } = this.props;

        return (
            <div className="content-inner-wrapper">
                <Header menu={this.state.menu} history={this.props.history} />

                <div className="content-inner destinations-manage">
                    {this.state.loading ? (
                        <Loader />
                    ) : (
                        <div>
                            <form
                                className="list-form"
                                onSubmit={handleSubmit(
                                    this.handleFormSubmit.bind(this)
                                )}
                            >
                                <div className="row">
                                    <div className="col-6 col-lg-6">
                                        <div className="col-12">
                                            <div className="col-12">
                                                <div className="name-wrapper">
                                                    <Field
                                                        name="public_title"
                                                        type="text"
                                                        className="mb-1 public-title"
                                                        placeholder="Public Title"
                                                        value={
                                                            this.state
                                                                .public_title
                                                        }
                                                        validate={[required]}
                                                        component={RenderField}
                                                    />
                                                </div>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <label className="upload-label text-uppercase mt-2">
                                                            Cover photo
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="row align-items-start">
                                                    <div
                                                        className={
                                                            this.state
                                                                .cover_media_type ===
                                                                'photo' &&
                                                            this.state
                                                                .cover_photo !==
                                                                ''
                                                                ? 'col-xl-2 col-lg-3 col-md-4'
                                                                : 'col-4'
                                                        }
                                                    >
                                                        {this.state
                                                            .cover_media_type ===
                                                            'photo' && (
                                                            <UploadField
                                                                name="cover_photo"
                                                                fileLabelName="Click here to upload photo"
                                                                initPhoto={
                                                                    this.state
                                                                        .cover_photo
                                                                }
                                                                className="mb-3 mt-1"
                                                                type="single"
                                                                add={this.addPhoto.bind(
                                                                    this
                                                                )}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <Field
                                                    name="internal_title"
                                                    type="text"
                                                    placeholder="CHARTER LISTING NAME IYC"
                                                    validate={[required]}
                                                    component={RenderField}
                                                />
                                                <div
                                                    className="toggle-wrapper" /*style="padding-left: 16px;"*/
                                                >
                                                    <div className="row">
                                                        <div
                                                            className={
                                                                'toggle-label ' +
                                                                this.state
                                                                    .is_public
                                                                    ? 'active'
                                                                    : ''
                                                            }
                                                            style={{
                                                                paddingRight:
                                                                    '16px'
                                                            }}
                                                        >
                                                            {'Make it public?'}
                                                        </div>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    this.state
                                                                        .is_public
                                                                }
                                                                onChange={() =>
                                                                    this.changePublic()
                                                                }
                                                                // id={name}
                                                                // {...input}
                                                            />
                                                            <span />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2 mt-2">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={submitting}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            type="button"
                                            color="secondary"
                                            disabled={submitting}
                                            onClick={this.cancelForm.bind(this)}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </form>
                            <div
                                className="row"
                                style={{
                                    maxWidth: '1200px',
                                    marginTop: '40px'
                                }}
                            >
                                <CharterListingYachts
                                    yachts={this.state.yachts}
                                    removeYacht={this.removeYacht.bind(this)}
                                    updateYacht={this.updateYacht.bind(this)}
                                    onOrderChanged={this.onOrderChanged.bind(
                                        this
                                    )}
                                    viewType={
                                        this.state.chartering
                                            ? 'charter'
                                            : 'sales'
                                    }
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetchCharterListings: (e) => dispatch(fetchCharterListings(e)),
        changeFieldValue: (field, value) =>
            dispatch(change('manage_charter_listing', field, value)),
        notification: (level, title, message) => {
            let notificationOpts = {
                position: 'tc',
                autoDismiss: 2,
                title: title,
                message: message
            };
            if (level === 'error')
                dispatch(Notifications.error(notificationOpts));
            else dispatch(Notifications.info(notificationOpts));
        }
    };
}

function mapStateToProps(state) {
    return {
        user: state.user,
        yachts: state.saved_searches.results,
        selectedYachts: {
            pagination: {
                page: 1,
                page_size: 25,
                page_count: 1,
                total: state.yachts.search.selectedYachts.selection.length
            },
            yachts: state.yachts.search.selectedYachts.selection.map(
                (yacht) => {
                    let modYacht = Object.assign({}, yacht);
                    if (yacht.selected === undefined) {
                        modYacht.selected = 1;
                        modYacht.helloSotoman = 'yes';
                    }
                    return modYacht;
                }
            )
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(form(ManageCharterListing));
