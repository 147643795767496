import axios from 'axios';
import { API_URL } from '../../../helpers/variables';
import {
    errorHandler,
    successHandler,
    customErrorMessageHandler
} from '../../../helpers/alerts';
import { authConfig } from '../../../helpers/authConfig';

import LocationHierarchy from '../../../common/components/locations/hierarchy';

import {
    INIT_LOCATION,
    FETCH_LOCATIONS,
    FETCH_LOCATIONS_SUCCESS,
    FETCH_LOCATIONS_ERROR,
    FETCH_DROPDOWN_LOCATIONS,
    FETCH_DROPDOWN_LOCATIONS_SUCCESS,
    FETCH_DROPDOWN_LOCATIONS_ERROR,
    CREATE_LOCATION,
    CREATE_LOCATION_SUCCESS,
    CREATE_LOCATION_ERROR,
    UPDATE_LOCATION,
    UPDATE_LOCATION_SUCCESS,
    UPDATE_LOCATION_ERROR,
    DELETE_LOCATION,
    DELETE_LOCATION_SUCCESS,
    DELETE_LOCATION_ERROR,
    FETCH_LOCATION,
    FETCH_LOCATION_SUCCESS,
    FETCH_LOCATION_ERROR,
    FETCH_LOCATIONS_HIERARCHY,
    FETCH_LOCATIONS_HIERARCHY_SUCCESS,
    FETCH_LOCATIONS_HIERARCHY_ERROR,
    RESET_DROPDOWN_LOCATIONS
} from './_types';

const initLocation = params => {
    return dispatch => {
        dispatch({ type: INIT_LOCATION });
    };
};

const fetchLocations = params => {
    return dispatch => {
        dispatch({ type: FETCH_LOCATIONS });

        const options = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/locations', options)
            .then(response => {
                dispatch({
                    type: FETCH_LOCATIONS_SUCCESS,
                    payload: {
                        locations: response.data.locations,
                        pagination: {
                            page: response.data.pagination.page,
                            page_count: response.data.pagination.page_count,
                            page_size: response.data.pagination.page_size,
                            total: response.data.pagination.total,
                            search: params && params.search ? params.search : ''
                        }
                    }
                });

                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_LOCATIONS_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchDropdownLocations = params => {
    return dispatch => {
        dispatch({ type: FETCH_DROPDOWN_LOCATIONS });

        const options = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/locations', options)
            .then(response => {
                dispatch({
                    type: FETCH_DROPDOWN_LOCATIONS_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_DROPDOWN_LOCATIONS_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchCreateLocation = params => {
    return dispatch => {
        dispatch({ type: CREATE_LOCATION });

        const options = {
            headers: authConfig()
        };

        return axios
            .post(API_URL + '/locations', params, options)
            .then(response => {
                dispatch({
                    type: CREATE_LOCATION_SUCCESS,
                    payload: response.data
                });
                successHandler(dispatch, 'locationCreated');

                return response.data;
            })
            .catch(error => {
                dispatch({ type: CREATE_LOCATION_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchLocation = (id, params) => {
    return dispatch => {
        dispatch({ type: FETCH_LOCATION });

        const options = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/locations/' + id, options)
            .then(response => {
                dispatch({
                    type: FETCH_LOCATION_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_LOCATION_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchUpdateLocation = (id, params) => {
    return dispatch => {
        dispatch({ type: UPDATE_LOCATION });

        const options = {
            headers: authConfig()
        };

        return axios
            .put(API_URL + '/locations/' + id, params, options)
            .then(response => {
                dispatch({
                    type: UPDATE_LOCATION_SUCCESS,
                    payload: response.data
                });

                successHandler(dispatch, 'locationUpdated');

                return response.data;
            })
            .catch(error => {
                dispatch({ type: UPDATE_LOCATION_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchDeleteLocation = id => {
    return dispatch => {
        dispatch({ type: DELETE_LOCATION });

        const options = {
            headers: authConfig(),
            params: {
                detach_yachts: true
            }
        };

        return axios
            .delete(API_URL + '/locations/' + id, options)
            .then(response => {
                dispatch({
                    type: DELETE_LOCATION_SUCCESS,
                    payload: response.data
                });
                successHandler(dispatch, 'locationDeleted');

                return response.data;
            })
            .catch(error => {
                console.log(error.response);
                dispatch({ type: DELETE_LOCATION_ERROR });

                if (error.response.status === 400) {
                    customErrorMessageHandler(
                        dispatch,
                        'UNAUTHORIZED',
                        error.response.data.error
                    );
                } else {
                    errorHandler(dispatch, error.response);
                }
            });
    };
};

const fetchLocationTree = params => {
    return dispatch => {
        dispatch({ type: FETCH_LOCATIONS_HIERARCHY });

        const options = {
            headers: authConfig(),
            params: params
        };
        console.log(params);
        console.trace();
        return axios
            .get(API_URL + '/locations/hierarchy', options)
            .then(response => {
                let location_hierarchy = LocationHierarchy.load(response.data);

                dispatch({
                    type: FETCH_LOCATIONS_HIERARCHY_SUCCESS,
                    payload: location_hierarchy
                });

                return location_hierarchy;
            })
            .catch(error => {
                dispatch({ type: FETCH_LOCATIONS_HIERARCHY_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const resetDropdownLocations = should_reset => {
    return dispatch => {
        dispatch({
            type: RESET_DROPDOWN_LOCATIONS,
            payload: should_reset
        });
    };
};

export {
    initLocation,
    fetchLocations,
    fetchDropdownLocations,
    fetchCreateLocation,
    fetchLocation,
    fetchUpdateLocation,
    fetchDeleteLocation,
    fetchLocationTree,
    resetDropdownLocations
};
