import {
    AUTH_USER,
    UNAUTH_USER,
    AUTH_ERROR,
    SWITCH_USER_ROLE,
    FETCH_RESET_PASSWORD,
    FETCH_RESET_PASSWORD_SUCCESS,
    FETCH_RESET_PASSWORD_FAIL,
    FETCH_RESET_PASSWORD_LOADING
} from './_types';

const INITIAL_STATE = {
    authenticated: false,
    token: null,
    user_role: null,
    resetPasswordLoading: false
};

export default function authReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTH_USER:
            return {
                ...state,
                authenticated: true,
                token: action.payload.token,
                user_role: action.payload.user_role
            };
        case UNAUTH_USER:
            return {
                ...state,
                authenticated: false,
                token: null,
                user_role: null
            };
        case AUTH_ERROR:
            return { ...state, error: action.payload };
        case SWITCH_USER_ROLE:
            return {
                ...state,
                user_role: action.payload
            };
        case FETCH_RESET_PASSWORD_LOADING:
            return {
                ...state,
                resetPasswordLoading: action.payload
            };
        case FETCH_RESET_PASSWORD:
        case FETCH_RESET_PASSWORD_SUCCESS:
        case FETCH_RESET_PASSWORD_FAIL:
            return state;
        default:
            return state;
    }
}
