import React, { Component } from 'react';

import { Collapse } from 'reactstrap';

class ToggleCollapse extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: props.initOpen ? props.initOpen : false
        };
    }

    toggle() {
        this.setState({ open: !this.state.open });

        if (this.props.onToggle !== undefined) {
            this.props.onToggle();
        }
    }

    createMarkup(body) {
        return { __html: body };
    }

    render() {
        const {
            body,
            header,
            justLine,
            className,
            wrapperClassName,
            collapseClassName
        } = this.props;
        return (
            <div
                className={
                    'collapse-wrapper ' +
                    (wrapperClassName ? wrapperClassName : '') +
                    (this.state.open === true ? ' opened' : '')
                }
            >
                <h2
                    className={'collapse-wrap' + (justLine ? ' just-line' : '')}
                >
                    {!justLine && (
                        <button
                            type="button"
                            className="btn btn-empty btn-title p-0"
                            data-toggle="collapse"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                            onClick={this.toggle.bind(this)}
                        >
                            <span
                                className={
                                    'collapse-label ' +
                                    (className ? className : '')
                                }
                            >
                                {header}
                            </span>
                        </button>
                    )}
                    {!justLine && (
                        <span className="trigger-collapse">
                            <button
                                type="button"
                                data-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                onClick={this.toggle.bind(this)}
                                className={className ? className : ''}
                            >
                                {this.state.open ? (
                                    <span>-</span>
                                ) : (
                                    <span>+</span>
                                )}
                            </button>
                        </span>
                    )}
                </h2>
                <Collapse
                    isOpen={this.state.open}
                    className={collapseClassName ? collapseClassName : ''}
                >
                    {React.isValidElement(body) ? (
                        body
                    ) : (
                        <div
                            dangerouslySetInnerHTML={this.createMarkup(body)}
                        />
                    )}
                </Collapse>
            </div>
        );
    }
}

export default ToggleCollapse;
