import React from 'react';
import { NavLink } from 'react-router-dom';

// @TODO MOve Selected Yachts in this folder
import SelectedYachts from '../../../../yachts/selection/SelectedYachts';
// Helpers
import {
    isBrokerOrLess,
    isModerator
} from '../../../../infrastructure/helpers/roles';
import { YACHT_TYPE_SALES } from '../../../../infrastructure/helpers/globals';

// REQUIRED PROPS
// onSidebarClose
// is_sidebar_open
// user_role
// yachts

const SelectionSidebar = (props) => {
    return (
        <div className="selected-yachts-wrapper">
            <div className="selected-yachts-inner togglable-sidebar  pl-1">
                {isBrokerOrLess(props.user_role) && (
                    <div className="table-actions d-flex align-items-center justify-content-between">
                        <span className="action-label">Current selection</span>
                        <button
                            className={
                                'btn btn-offer btn-blue ' +
                                (props.yachts.selectedYachts.length <= 0
                                    ? 'disabled'
                                    : '')
                            }
                            onClick={props.onCreateOfferToggle}
                            disabled={
                                props.yachts.selectedYachts.length <= 0
                                    ? true
                                    : false
                            }
                        >
                            Create Proposal
                        </button>
                    </div>
                )}

                {/* DV-492 hide create newsletter button */}
                {/* {isBrokerOrLess(props.user_role) && (
                    <div className="table-actions d-flex align-items-center justify-content-between">
                        <span className="action-label">Current selection</span>
                        <NavLink
                            className="btn btn-offer btn-blue"
                            to={`/newsletters/create?selection=${props.view_type}`}
                        >
                            Create Newsletter
                        </NavLink>
                    </div>
                )} */}

                {isModerator(props.user_role) &&
                    props.view_type === YACHT_TYPE_SALES && (
                        <div className="table-actions d-flex align-items-center justify-content-between">
                            <span className="action-label">
                                Current selection
                            </span>
                            <NavLink
                                className="btn btn-offer btn-blue"
                                to="/sales-listings/create?selection=sales"
                            >
                                Create Sales Listing
                            </NavLink>
                        </div>
                    )}

                <div className="info-label text-center">
                    Drag and drop to reorder selection
                </div>

                <div className="selected-yachts-header d-flex align-items-center justify-content-between">
                    <span className="total-wrap">
                        Selected: {props.yachts.selectedYachts.total}
                    </span>
                    <button
                        type="button"
                        className="btn btn-empty btn-clear"
                        onClick={props.clearAllSelected}
                    >
                        CLEAR ALL
                    </button>
                </div>

                {props.yachts.selectedYachts.selection.length > 0 && (
                    <SelectedYachts
                        yachts={props.yachts.selectedYachts}
                        onOrderChanged={props.onReorder}
                        onRemoveFromSelection={props.onSelectYacht}
                        viewType={props.view_type}
                    />
                )}
            </div>
        </div>
    );
};

export default SelectionSidebar;
