import React from 'react';

const labeledInfo = (props) => {
    return (
        <div>
            <div className="heading-label small-head">{props.label}</div>
            <div className={props.color ? props.color : 'heading-body'}>
                {props.value}
            </div>
        </div>
    );
};
export default labeledInfo;
