export const FETCH_PUBLISH_NEWS = 'fetch_publish_news',
  FETCH_PUBLISH_NEWS_SUCCESS = 'fetch_publish_news_success',
  FETCH_PUBLISH_NEWS_FAIL = 'fetch_publish_news_fail',
  FETCH_NEWS = 'fetch_news',
  FETCH_NEWS_SUCCESS = 'fetch_news_success',
  FETCH_NEWS_FAIL = 'fetch_news_fail',
  FETCH_SINGLE_NEWS = 'fetch_single_news',
  FETCH_SINGLE_NEWS_SUCCESS = 'fetch_single_news_success',
  FETCH_SINGLE_NEWS_FAIL = 'fetch_single_news_fail',
  FETCH_EDIT_SINGLE_NEWS = 'fetch_edit_single_news',
  FETCH_EDIT_SINGLE_NEWS_SUCCESS = 'fetch_edit_single_news_success',
  FETCH_EDIT_SINGLE_NEWS_FAIL = 'fetch_edit_single_news_fail',
  FETCH_DELETE_NEWS = 'fetch_delete_news',
  FETCH_DELETE_NEWS_SUCCESS = 'fetch_delete_news_success',
  FETCH_DELETE_NEWS_FAIL = 'fetch_delete_news_fail',
  REORDER_NEWS = 'reorder_news',
  REORDER_NEWS_SUCCESS = 'reorder_news_success',
  REORDER_NEWS_FAIL = 'reorder_news_fail';
