import moment from 'moment';
import fx from 'money';

import construction_news from '../assets/svgs/construction_news.svg';
import economy_news from '../assets/svgs/economy_news.svg';
import yacht_news from '../assets/svgs/yacht_news.svg';
import iyc_news from '../assets/svgs/iyc_news.svg';

export const functions = {
    EUR_RATE: 0.84,
    GBP_RATE: 0.74,
    AUD_RATE: 1.25,
    USD_RATE: 1,

    EUR_DOLAR_RATE: 1.19,
    GBP_DOLAR_RATE: 1.35,
    AUD_DOLAR_RATE: 0.79,
    USD_DOLAR_RATE: 1,

    FEET_RATIO: 3.28084,
    METERS_RATIO: 1,

    SQUARE_FEET_RATIO: 10.7639,
    SQUARE_METERS_RATIO: 1,

    LITERS_RATIO: 1,
    GALLONS_RATIO: 0.26,

    signs: {
        usd: '$',
        eur: '€',
        aud: '$',
        gbp: '£'
    },

    rates: {
        EUR: 0.84,
        GBP: 0.74,
        AUD: 1.25,
        USD: 1
    },

    industries: [
        'Advertising / Marketing',
        'Art',
        'Consulting',
        'Consumer Products',
        'Education',
        'Entertainment / Media',
        'Finance',
        'Government',
        'Healthcare',
        'Legal',
        'Manufacturing',
        'Medicine',
        'Military',
        'Non-Profit',
        'Pharmaceuticals / Healthcare',
        'Real Estate',
        'Retail',
        'Shipping',
        'Technology',
        'Telecommunications',
        'Utilities',
        'Yachting',
        'Other'
    ],
    defaultImages: [
        'construction_news',
        'yacht_news',
        'economy_news',
        'iyc_news'
    ],

    getRates: function (amount, data, from) {
        fx.base = 'USD';
        // fx.rates = data;
        fx.rates = {
            EUR: 0.85,
            GBP: 0.74,
            AUD: 1.3,
            USD: 1
        };

        var rate = fx(amount).from(from).to('USD');

        return parseFloat(Math.round(rate * 100) / 100).toFixed(2);
    },

    thousands: function (number) {
        if (!number) {
            return '';
        }

        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },

    toDollars: function (number, to, rates) {
        if (!number) {
            return '';
        }

        return this.getRates(number, rates, to.toUpperCase());
    },

    toMeters: function (number, to) {
        if (!number) {
            return '';
        }

        let newNum = parseFloat(number) / this[to.toUpperCase() + '_RATIO'];

        newNum = Number(Math.round(newNum + 'e4') + 'e-4');

        return newNum;
    },

    toSquareMeters: function (number, to) {
        if (!number) {
            return '';
        }

        let newNum = parseFloat(number) / this[to.toUpperCase() + '_RATIO'];

        newNum = Number(Math.round(newNum + 'e4') + 'e-4');

        return newNum;
    },

    toFeet: function (number) {
        if (!number) {
            return '';
        }

        let newNum = parseFloat(number) * 0.3048;
        return newNum.toFixed(2);
    },

    toSquareFeet: function (number) {
        if (!number) {
            return '';
        }

        let newNum = parseFloat(number) * 0.092903;
        return newNum.toFixed(2);
    },

    toLiters: function (number, to) {
        if (!number) {
            return '';
        }
        return Math.round(number / this[to.toUpperCase() + '_RATIO']);
    },

    currency: function (number, to, display, noFixed = false) {
        if (!number) {
            return '';
        }

        let converted = number;
        if (noFixed) {
            converted = parseFloat(converted).toFixed(0);
        }
        if (display === true) {
            converted = this.thousands(converted);
            if (to) {
                return this.signs[to] + converted;
            }
        }

        return converted;
    },

    unit: function (number, to, display) {
        if (!number) return '';
        if (display === 'meters_only') return `${number.toFixed(2)}m`;

        const feet = number / 0.3048;

        if (display === 'edit') {
            return Math.round(feet * 10) / 10;
        }

        let rounded = parseInt(feet);
        let inches = Math.ceil((feet - rounded) * 12);

        if (inches === 12) {
            rounded += 1;
            inches = 0;
        }

        if (display === true)
            return `${rounded}' ${
                inches === 0 ? '' : inches + `''`
            } /${number.toFixed(2)}m`;
        if (display === 'feet_only')
            return `${rounded}' ${inches === 0 ? '' : inches + `''`}`;

        return to === 'feet' ? rounded : number.toFixed(2);
    },

    squareUnit: function (number, to, display) {
        if (!number) return '';

        const squareFeet = number / 0.092903;

        if (display === 'edit') {
            return Math.round(squareFeet * 10) / 10;
        }

        let rounded = parseInt(squareFeet);
        let squareInches = Math.ceil((squareFeet - rounded) * 144);

        if (squareInches === 144) {
            rounded += 1;
            squareInches = 0;
        }

        if (display === true)
            return `${rounded}' ${
                squareInches === 0 ? '' : squareInches + `''`
            } /${number.toFixed(2)}m`;
        if (display === 'feet_only')
            return `${rounded}' ${
                squareInches === 0 ? '' : squareInches + `''`
            }`;

        return to === 'square_feet' ? rounded : number.toFixed(2);
    },

    volumeUnit: function (number, to, display) {
        if (!number) {
            return '';
        }

        let converted = Math.round(this['GALLONS_RATIO'] * number);

        if (display === true) {
            return `${number} L (${converted} gal)`;
        }

        return to === 'gallons' ? converted : number;
    },
    getYearsBackwards: function (years, year, withoutYears) {
        if (year) {
            parseInt(year);
        } else {
            year = moment().get('year');
        }

        if (withoutYears) {
            year = year - withoutYears;
        }

        var array = [];
        var i = 0;
        for (i; i < years; i++) {
            array.push({ value: year, label: String(year) });
            year--;
        }

        return array;
    },
    getIndustries: function () {
        return this.industries;
    },
    getDays: function (days) {
        var array = [];
        var i = 1;
        for (i; i <= days; i++) {
            array.push({ value: i, label: String(i) });
        }

        return array;
    },
    // If uploaded photo exists will be shown, else the svg that were chosen during the post will be shown
    getNewsPhoto: function (photo) {
        if (photo === 'construction_news') {
            return {
                title: 'construction_news',
                image: construction_news
            };
        }
        if (photo === 'economy_news') {
            return {
                title: 'economy_news',
                image: economy_news
            };
        }
        if (photo === 'yacht_news') {
            return {
                title: 'yacht_news',
                image: yacht_news
            };
        }
        if (photo === 'iyc_news') {
            return {
                title: 'iyc_news',
                image: iyc_news
            };
        }
        return {
            title: 'photo',
            image: photo
        };
    },
    getWindowSize: function () {
        return (
            window.clientWidth || window.innerWidth || document.body.clientWidth
        );
    },
    isAdmin: function (role) {
        return (
            role === 'super_admin' ||
            role === 'admin' ||
            role === 'owner' ||
            role === 'admin_website'
        );
    },

    isModerator: function (role) {
        return (
            role === 'super_admin' ||
            role === 'admin' ||
            role === 'owner' ||
            role === 'moderator' ||
            role === 'admin_website'
        );
    },

    isBroker: function (role) {
        return (
            role === 'broker' ||
            role === 'broker_sales' ||
            role === 'broker_charter'
        );
    },
    priceCurrencyFormat(price, currency) {
        price = this.thousands(price);

        switch (currency) {
            case 'eur':
                return '€' + price;
            case 'usd':
                return '$' + price;
            case 'gbp':
                return '£' + price;
            default:
                return price;
        }
    },

    getUrlFromInput(event) {
        let eventValueTrimed = event.target.value.trim();
        if (
            eventValueTrimed === '' ||
            eventValueTrimed === 'https://' ||
            eventValueTrimed === 'http://'
        ) {
            eventValueTrimed = null;
        } else if (
            eventValueTrimed.indexOf('https://') === -1 &&
            eventValueTrimed.indexOf('http://') === -1
        ) {
            eventValueTrimed = 'https://' + eventValueTrimed;
        }
        return eventValueTrimed;
    }
};
