import React from 'react';
import { Row, Col, Button } from 'reactstrap';

const AdminFiltersActions = (props) => {
    return (
        <Row className="my-2 justify-content-center">
            <Col lg="5">
                <button type="submit" className="btn btn-primary btn-search">
                    Apply Filters
                </button>
            </Col>

            <Col lg="5">
                <Button
                    type="submit"
                    color="secondary-blue"
                    onClick={props.cancelForm}
                >
                    Reset Filters
                </Button>
            </Col>
        </Row>
    );
};

export default AdminFiltersActions;
