import {
    FETCH_CREATE_NEWSLETTER,
    FETCH_NEWSLETTER_HISTORY_SUCCESS,
    UPDATE_NEWSLETTER
} from './types';

const initialState = {
    multiple: [],
    pagination: { page: 1, total: 0, page_size: 1, page_count: 1 },
    sorting: { order_field: 'company', order_dir: 'ASC' },
    brokers: [],
    initBroker: {},
    search: ''
};

export default function newsletters(state = initialState, action) {
    switch (action.type) {
        case FETCH_CREATE_NEWSLETTER:
            return state;
        case UPDATE_NEWSLETTER:
            return state;
        case FETCH_NEWSLETTER_HISTORY_SUCCESS:
            return {
                ...state,
                pagination: {
                    page:
                        action.payload.pagination.total === 0
                            ? 0
                            : action.payload.pagination.page >
                              action.payload.pagination.page_count
                            ? 1
                            : action.payload.pagination.page,
                    total: action.payload.pagination.total,
                    page_size: action.payload.pagination.page_size,
                    page_count: action.payload.pagination.page_count
                },
                data: action.payload.data,
                history: action.payload.data
            };
        default:
            return state;
    }
}
