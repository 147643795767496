import React from 'react';
import { Row, Col } from 'reactstrap';

import YachtListMenu from '../components/YachtListMenu';
import SelectionSidebar from '../components/selection/SelectionSidebar';

import BrokerTopFilters from '../../../yachts/yacht/BrokerTopFilters';
import SimpleTableActions from '../../../common/components/SimpleTableActions';
import Filters from '../../../yachts/search/Filters';
import Results from '../../../yachts/search/Results';
import SimpleModal from '../../../common/components/SimpleModal';
import BrokerActions from '../../../yachts/search/BrokerActions';
import RenderToggle from '../../../common/components/RenderToggle';

// Helpers
import { isAdmin, isBrokerOrLess } from '../../../infrastructure/helpers/roles';
import { YACHT_CHARTER_TABLE_PREFIX } from '../../../infrastructure/helpers/globals';

// Assets
import filter from '../../../assets/svgs/filter.svg';
import ToggledSidebar from '../../../common/ToggledSidebar';

const IndexYachtPresenter = (props) => {
    return (
        <div className="content-inner-wrapper">
            <YachtListMenu
                user_role={props.user_role}
                history={props.history}
                match={props.match}
                view_type={props.view_type}
            />

            <div
                className={
                    'content-inner ' +
                    (isBrokerOrLess(props.user_role)
                        ? 'yachts-inner-wrapper'
                        : '')
                }
            >
                <Row>
                    <Col xs="12" xl="8">
                        <Row>
                            {isBrokerOrLess(props.user_role) && (
                                <Col xs="12">
                                    <div>
                                        <BrokerTopFilters
                                            loading={props.loading}
                                            tableChange={props.onTableChange}
                                            hasSearch={props.has_search}
                                            updateSearchParameters={
                                                props.onUpdateSearchParameters
                                            }
                                            type={props.view_type}
                                            history={props.history}
                                            onComponentUpdate={
                                                props.onComponentUpdate
                                            }
                                            onClearSearch={props.onClearSearch}
                                        />

                                        <SimpleTableActions
                                            label="Advanced filters"
                                            icon={filter}
                                            clearSearch={props.onClearSearch}
                                            hasSearch={props.has_search}
                                            onClick={props.onToggleSearch}
                                            clearFiltersLabel={'RESET FILTERS'}
                                            extra={
                                                <BrokerActions
                                                    hasSearch={props.has_search}
                                                    onClick={
                                                        props.onSavedSeachModalToggle
                                                    }
                                                />
                                            }
                                        />
                                    </div>
                                </Col>
                            )}

                            {isBrokerOrLess(props.user_role) && (
                                <Col xs="12">
                                    <Filters
                                        toggle={props.is_dropdown_filters_open}
                                        refetchYachts={props.onRefetchYachts}
                                        type={props.view_type}
                                        location={props.history.location}
                                        prefix={YACHT_CHARTER_TABLE_PREFIX}
                                        mobileView={props.is_mobile_view}
                                        onClearSearch={props.onClearSearch}
                                        initialFilters={props.initial_filters}
                                        externalProviders={
                                            props.externalProviders
                                        }
                                        setCharterRatesLocationsDates={
                                            props.setCharterRatesLocationsDates
                                        }
                                    />
                                </Col>
                            )}

                            <Col xs="12">
                                <Results
                                    viewType={props.view_type}
                                    loading={props.loading}
                                    allCheckbox={true}
                                    showCheckbox={true}
                                    selectAll={true}
                                    userRole={props.user_role}
                                    yachts={props.yachts}
                                    is_mobile_view={props.is_mobile_view}
                                    active_sidebar={props.active_sidebar}
                                    onCheckboxChange={props.onSelectYacht}
                                    onSelectAllChange={props.onSelectYacht}
                                    tableChange={props.onTableChange}
                                    favouriteYacht={props.onFavouriteYacht}
                                    toggleSidebar={props.onToggleSidebar}
                                    initialOrderDir={props.initialOrderDir}
                                    initialOrderField={props.initialOrderField}
                                    onYachtStatusChange={
                                        props.onYachtStatusChange
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xl="4">
                        {isAdmin(props.user_role) && (
                            <div className="d-flex align-items-center justify-content-center mb-2">
                                <button
                                    className={'btn btn-primary btn-search'}
                                    onClick={props.onCreateYachtListToggle}
                                >
                                    Create/Update Yacht List
                                </button>
                            </div>
                        )}

                        {!props.is_mobile_view && isAdmin(props.user_role) && (
                            <div className="d-flex align-items-center justify-content-center mb-1">
                                <div className="field-outer-wrapper pt-1">
                                    <span
                                        className="filter-label font-500"
                                        style={{ fontSize: '14px' }}
                                    >
                                        View Filters
                                    </span>
                                </div>
                                <div className="role-switch-wrapper">
                                    <RenderToggle
                                        name="sidebar_switch"
                                        className="mt-0 mb-0 px-1"
                                        input={{
                                            value:
                                                props.active_sidebar ===
                                                'filters'
                                                    ? false
                                                    : true,
                                            onChange: (event, v) => {
                                                const sdb =
                                                    props.active_sidebar ===
                                                    'filters'
                                                        ? 'selection'
                                                        : 'filters';
                                                props.onSetActiveSidebar(sdb);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="field-outer-wrapper pt-1">
                                    <span
                                        className="filter-label font-500"
                                        style={{ fontSize: '14px' }}
                                    >
                                        View Selection
                                    </span>
                                </div>
                            </div>
                        )}

                        {props.active_sidebar === 'selection' && (
                            <ToggledSidebar
                                position={
                                    props.is_mobile_view ? 'fixed' : 'relative'
                                }
                                is_open={props.is_sidebar_open}
                                onCloseSidebar={(e) =>
                                    props.onToggleSidebar(e, 'filters')
                                }
                            >
                                <SelectionSidebar
                                    user_role={props.user_role}
                                    is_sidebar_open={props.is_mobile_view}
                                    onSidebarClose={props.onToggleSidebar}
                                    clearAllSelected={props.onClearAllSelected}
                                    onSelectYacht={props.onSelectYacht}
                                    view_type={props.view_type}
                                    yachts={props.yachts}
                                    onCreateOfferToggle={
                                        props.onCreateOfferToggle
                                    }
                                    onReorder={props.onSelectionReorder}
                                    onCreateYachtListToggle={
                                        props.onCreateYachtListToggle
                                    }
                                />
                            </ToggledSidebar>
                        )}

                        {isAdmin(props.user_role) &&
                            props.active_sidebar === 'filters' && (
                                <Filters
                                    is_sidebar_open={props.is_sidebar_open}
                                    toggle={props.is_dropdown_filters_open}
                                    refetchYachts={props.onRefetchYachts}
                                    type={props.view_type}
                                    mobileView={props.is_mobile_view}
                                    location={props.history.location}
                                    prefix={YACHT_CHARTER_TABLE_PREFIX}
                                    onClearSearch={props.onClearSearch}
                                    searchFunction={
                                        isAdmin(props.user_role)
                                            ? props.onTableChange
                                            : undefined
                                    }
                                    initialFilters={props.initial_filters}
                                    externalProviders={props.externalProviders}
                                    setCharterRatesLocationsDates={
                                        props.setCharterRatesLocationsDates
                                    }
                                    onCloseSidebar={(e) =>
                                        props.onToggleSidebar(e, 'filters')
                                    }
                                />
                            )}
                    </Col>
                </Row>
            </div>

            <SimpleModal
                header={props.modal_header}
                body={props.modal_body}
                modalOpen={props.is_modal_open}
                toggle={props.onModalToggle}
            />
        </div>
    );
};

export default IndexYachtPresenter;
