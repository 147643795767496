import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import NewsletterReportPresenter from './NewsletterReportPresenter';

// Redux
import { fetchNewsletterReport } from './redux/_actions';

// SVG
import mail from '../../assets/svgs/mail.svg';

class NewsletterReportContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            menu: {
                parent: mail,
                items: []
            },
            table_headers: [
                {
                    name: 'url',
                    label: 'URL',
                    sort: false
                },
                {
                    name: 'datetime',
                    label: 'DATETIME',
                    sort: false
                },
                {
                    name: 'location',
                    label: 'LOCATION',
                    sort: false
                },
                {
                    name: 'client',
                    label: 'CLIENT',
                    sort: false
                }
            ]
        };
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const email_id = this.props.match.params.email_id;
        let menu = [
            <NavLink exact to={`/newsletters/${id}/view`}>
                View
            </NavLink>,
            <NavLink exact to={`/newsletters/${id}/report`}>
                Report
            </NavLink>,
            <NavLink
                exact
                to={`/newsletters/${id}/report/${email_id}`}
                isActive={() => true}
            >
                Email
            </NavLink>
        ];

        this.setState({
            menu: {
                ...this.state.menu,
                items: menu
            }
        });

        this.props
            .fetchNewsletterReport(email_id)
            .then(data => {
                this.setState({ loading: false });
            })
            .catch(error => console.log(error));
    }

    render() {
        return (
            <NewsletterReportPresenter
                history={this.props.history}
                loading={this.state.loading}
                menu={this.state.menu}
                table_headers={this.state.table_headers}
                report={this.props.report}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        report: state.newsletter_report.report
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchNewsletterReport: id => dispatch(fetchNewsletterReport(id))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsletterReportContainer);
