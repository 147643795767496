import {
    FETCH_DESTINATIONS,
    FETCH_DESTINATIONS_SUCCESS,
    FETCH_DROPDOWN_DESTINATIONS_SUCCESS,
    FETCH_DESTINATION_SUCCESS,
    INIT_DESTINATION
} from './_types';

const INITIAL_STATE = {
    destinations: {
        pagination: { page: 1, total: 0, page_size: 1, page_count: 1 },
        sorting: {
            order_field: 'title_internal',
            order_dir: 'ASC',
            favourites: 0
        },
        data: []
    },
    destination: {
        cover_media_type: 'photo'
    },
    dropdown_destinations: []
};

export default function destinationsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_DESTINATIONS:
            return state;
        case FETCH_DESTINATIONS_SUCCESS:
            return {
                ...state,
                destinations: {
                    pagination: {
                        page:
                            action.payload.pagination.total === 0
                                ? 0
                                : action.payload.pagination.page >
                                  action.payload.pagination.page_count
                                ? 1
                                : action.payload.pagination.page,
                        total: action.payload.pagination.total,
                        page_size: action.payload.pagination.page_size,
                        page_count: action.payload.pagination.page_count
                    },
                    data: action.payload.destinations,
                    sorting: action.payload.sorting
                }
            };
        case FETCH_DROPDOWN_DESTINATIONS_SUCCESS: {
            return {
                ...state,
                dropdown_destinations: action.payload
            };
        }
        case FETCH_DESTINATION_SUCCESS:
            return { ...state, destination: action.payload };
        case INIT_DESTINATION:
            return { ...state, destination: INITIAL_STATE.destination };
        default:
            return state;
    }
}
