import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';

import Header from '../../common/header/Header';
import { Row, Col } from 'reactstrap';

import SimpleTableActions from '../../common/components/SimpleTableActions';
import SimpleTable from '../../common/components/SimpleTable';
import SimpleModal from '../../common/components/SimpleModal';

import add from '../../assets/svgs/add.svg';
import edit from '../../assets/svgs/edit.svg';
import deleteSVG from '../../assets/svgs/delete.svg';

const IndexLocationPresenter = props => {
    return (
        <div className="content-inner-wrapper">
            <Header menu={props.menu} history={props.history} />

            <div className="content-inner">
                <Row>
                    <Col xs="12" lg="9">
                        <SimpleTableActions
                            label="Create new location"
                            icon={add}
                            onClick={() =>
                                props.history.push('/locations/create')
                            }
                        />
                        <SimpleTable
                            search={true}
                            tableChange={props.onTableChange}
                            paging={props.locations.pagination}
                            colWidths={['9', '3 text-center']}
                            headers={props.table_headers}
                            rowData={
                                props.locations.data &&
                                props.locations.data.map(location => {
                                    return [
                                        <div>{location.name}</div>,
                                        <ul className="list-unstyled table-td-actions">
                                            <li className="text-fill-hover">
                                                <NavLink
                                                    to={`/locations/${
                                                        location.id
                                                    }/edit`}
                                                >
                                                    <ReactSVG
                                                        path={edit}
                                                        style={{ height: 24 }}
                                                    />
                                                </NavLink>
                                            </li>
                                            <li>
                                                <button
                                                    className="btn btn-td-action"
                                                    onClick={() =>
                                                        props.onDelete(
                                                            location.id
                                                        )
                                                    }
                                                >
                                                    <ReactSVG
                                                        path={deleteSVG}
                                                        style={{ height: 24 }}
                                                    />
                                                </button>
                                            </li>
                                        </ul>
                                    ];
                                })
                            }
                        />
                    </Col>

                    <Col xs="12">
                        <SimpleModal
                            header={props.modalHeader}
                            body={props.modalBody}
                            modalOpen={props.isModalOpen}
                            toggle={props.onToggleModal}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default IndexLocationPresenter;
