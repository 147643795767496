import React, { PureComponent } from 'react';

import { Field } from 'redux-form';
import RenderCheckbox from './RenderCheckbox';
import { isBoolean } from '../../helpers/validate';

class RenderCustomerCat extends PureComponent {
    handleChange(event) {
        let otherCheckbox = this.props[
            event.target.name === 'sales' ? 'chartering' : 'sales'
        ];

        if (
            (otherCheckbox == 0 || otherCheckbox === 'false') &&
            (event.target.value == 1 || event.target.value === 'true')
        ) {
            if (event.target.name === 'sales') {
                this.props.changeFieldValue('chartering', true);
            } else {
                this.props.changeFieldValue('sales', true);
            }
        }
    }

    render() {
        return (
            <div className="row">
                {this.props.category === undefined ||
                    ((this.props.category === 'broker_sales' ||
                        this.props.category === 'broker') && (
                        <div className="col-2">
                            <Field
                                name="sales"
                                label="Sales"
                                type="checkbox"
                                normalize={isBoolean}
                                component={RenderCheckbox}
                                disabled={this.props.disabled}
                                onChange={this.handleChange.bind(this)}
                            />
                        </div>
                    ))}
                {this.props.category === undefined ||
                    ((this.props.category === 'broker_charter' ||
                        this.props.category === 'broker') && (
                        <div className="col-2">
                            <Field
                                name="chartering"
                                label="Charter"
                                type="checkbox"
                                normalize={isBoolean}
                                component={RenderCheckbox}
                                disabled={this.props.disabled}
                                onChange={this.handleChange.bind(this)}
                            />
                        </div>
                    ))}
            </div>
        );
    }
}

export default RenderCustomerCat;
