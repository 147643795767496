export const FETCH_USERS = 'fetch_users',
  FETCH_USERS_SUCCESS = 'fetch_users_success',
  FETCH_USERS_FAIL = 'fetch_users_fail',
  INIT_USER_MODAL = 'init_user_modal',
  FETCH_MANAGE_USER = 'fetch_manage_user',
  FETCH_MANAGE_USER_SUCCESS = 'fetch_manage_user_success',
  FETCH_MANAGE_USER_FAIL = 'fetch_manage_user_fail',
  FETCH_DELETE_USER = 'fetch_delete_user',
  FETCH_DELETE_USER_SUCCESS = 'fetch_delete_user_success',
  FETCH_DELETE_USER_FAIL = 'fetch_delete_user_fail',
  FETCH_ALL_USERS = 'fetch_all_user',
  FETCH_ALL_USERS_SUCCESS = 'fetch_all_user_success',
  FETCH_ALL_USERS_FAIL = 'fetch_all_user_fail';
