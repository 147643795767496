import React, { Component } from 'react';
import ReactSVG from 'react-svg';

class SimpleTableActions extends Component {
    render() {
        return (
            <div className="table-actions d-flex align-items-center">
                <button
                    type="button"
                    className="btn btn-main btn-action d-flex align-items-center justify-content-center"
                    onClick={this.props.onClick}
                >
                    <ReactSVG path={this.props.icon} style={{ height: 12 }} />
                </button>
                {this.props.hasSearch === true ? (
                    <button
                        className="btn btn-empty btn-clear"
                        onClick={this.props.clearSearch}
                    >
                        {this.props.clearFiltersLabel
                            ? this.props.clearFiltersLabel
                            : 'CLEAR FILTERS'}
                    </button>
                ) : (
                    <button
                        type="button"
                        className="btn btn-empty action-label"
                        onClick={this.props.onClick}
                    >
                        {this.props.label}
                    </button>
                )}

                {this.props.extra !== undefined ? this.props.extra : null}
            </div>
        );
    }
}

export default SimpleTableActions;
