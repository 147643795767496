import axios from 'axios';
import { API_URL } from 'helpers/variables';
import { authConfig } from 'helpers/authConfig';
import moment from 'moment';

const url = `${API_URL}/yatco`;

/**
 * Returns a list of yachts that have been updated
 * @returns {AxiosPromise}
 */
export function getExternalYachts(params) {
    return axios({
        url: `${url}`,
        method: 'GET',
        headers: authConfig(),
        params
    });
}

export function getExternalYacht(id) {
    return axios({
        url: `${url}/${id}`,
        method: 'GET',
        headers: authConfig()
    });
}

export function updateExternalYacht(vessel_id) {
    return axios({
        url: `${url}/update?vessel_id=${vessel_id}`,
        method: 'GET',
        headers: authConfig()
    });
}

export function importExternalYacht(yacht) {
    console.log('importedyacht', yacht);
    return axios({
        url: `${url}/import`,
        method: 'POST',
        data: {
            ...yacht,
            created_at: moment(yacht.created_at).format('YYYY-MM-DD HH:mm:ss'),
            updated_at: moment(yacht.updated_at).format('YYYY-MM-DD HH:mm:ss')
        },
        headers: authConfig()
    });
}

export function externalYachtsSearch(params) {
    let formattedParams = Object.keys(params).reduce((acc, key) => {
        let data;
        if (key === 'type') {
            data = params[key].map((i) => i.value);
        } else {
            data = params[key];
        }

        const paramToString = data.toString();
        if (paramToString !== '') {
            return { ...acc, [key]: paramToString };
        }
        return acc;
    }, {});

    //console.log('formattedParams', formattedParams);

    return axios({
        url: `${url}`,
        method: 'GET',
        headers: authConfig(),
        params: {
            ...formattedParams
        }
    });
}
