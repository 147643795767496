import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, FieldArray } from 'redux-form';

import {
    fetchYachtLists,
    fetchYachtListsNoPagination
} from '../redux/_actions';
import RenderYachtListsSuggestions from './RenderYachtListsSuggestions';

class SearchYachtListDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            suggestions: [],
            active: 0
        };
    }

    select(suggestion, index) {
        this.setState({ is_active: false });

        this.props.changeFieldValue(
            'yacht_list_search',
            suggestion.internal_title
        );
        this.props.onSuggestionSelect(suggestion.id);
    }

    change(event, value) {
        this.setState({ is_active: true });
        console.log('value', value);

        let params = {
            search: value,
            type: 'dynamic'
        };

        this.props.fetchYachtListsNoPagination(params);
    }

    render() {
        return (
            <div className="companies-wrap mt-2">
                <FieldArray
                    name="search_yacht_list"
                    single={true}
                    component={RenderYachtListsSuggestions}
                    props={{
                        suggestions: this.props.search_results,
                        is_active: this.state.is_active,
                        change: this.change.bind(this),
                        select: this.select.bind(this)
                    }}
                    className="mb-2"
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetchYachtLists: (e) => dispatch(fetchYachtLists(e)),
        fetchYachtListsNoPagination: (e) =>
            dispatch(fetchYachtListsNoPagination(e)),
        changeFieldValue: (field, value) =>
            dispatch(change(ownProps.form, field, value))
    };
}

function mapStateToProps(state) {
    return {
        search_results: state.yacht_lists.yacht_lists.data
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchYachtListDropdown);
