import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { NavLink } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FixedSizeList, areEqual } from 'react-window';

import Header from '../common/header/Header';
import SimpleTableActions from '../common/components/SimpleTableActions';

import {
    fetchNews,
    fetchEditNews,
    fetchDeleteNews,
    reOrderNews
} from './_actions';
import { functions } from '../helpers/functions';

import news from '../assets/svgs/news.svg';
import add from '../assets/svgs/add.svg';
import moment from 'moment';
import RenderToggle from '../common/components/RenderToggle';
import ReactSVG from 'react-svg';
import edit from '../assets/svgs/edit.svg';
import deleteSVG from '../assets/svgs/delete.svg';
import rearrange from '../assets/svgs/rearrange.svg';

function getStyle({ provided, style, isDragging }) {
    // If you don't want any spacing between your items
    // then you could just return this.
    // I do a little bit of magic to have some nice visual space
    // between the row items
    const combined = {
        ...style,
        ...provided.draggableProps.style
    };

    const marginBottom = 8;
    const withSpacing = {
        ...combined,
        height: isDragging ? combined.height : combined.height - marginBottom,
        marginBottom
    };
    return withSpacing;
}

function Item({
    provided,
    post,
    style,
    isDragging,
    activeToggle,
    editNewsPost,
    deletePost
}) {
    return (
        <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={getStyle({ provided, style, isDragging })}
            className={`item ${isDragging ? 'is-dragging' : ''}`}
        >
            <div
                className={isDragging ? 'is-dragging' : ''}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                    // provided.draggableStyle,
                    isDragging
                )}
            >
                <div>
                    <div className="col-12 d-flex align-items-center table-row px-0 py-1">
                        <div className="col-1">
                            {post?.image_url && (
                                <img
                                    className={`rounded-circle ${
                                        functions.defaultImages.includes(
                                            functions.getNewsPhoto(
                                                post?.image_url
                                            ).title
                                        )
                                            ? ''
                                            : 'object-fit-cover'
                                    }`}
                                    width="38"
                                    height="38"
                                    src={
                                        functions.getNewsPhoto(post?.image_url)
                                            .image
                                    }
                                    alt=""
                                />
                            )}
                        </div>
                        {post?.title && (
                            <div className="col-3 news-title">
                                {post?.title}
                            </div>
                        )}
                        <div className="col-2 news-author">{post?.author}</div>
                        <div className="col-2 news-created-date">
                            {moment(post?.created_at).format('DD/MM/YYYY')}
                        </div>
                        <div className="col-2">
                            <RenderToggle
                                name={'active' + post?.id}
                                className="m-0"
                                input={{
                                    value: post?.public,
                                    onChange: (event) =>
                                        activeToggle(event, post)
                                }}
                            />
                        </div>
                        <div className="col-1">
                            <ul className="list-unstyled table-td-actions">
                                <li>
                                    <button
                                        className="btn btn-td-action"
                                        onClick={() => editNewsPost(post?.id)}
                                    >
                                        <ReactSVG
                                            path={edit}
                                            style={{
                                                height: 24
                                            }}
                                        />
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="btn btn-td-action"
                                        onClick={() => deletePost(post?.id)}
                                    >
                                        <ReactSVG
                                            path={deleteSVG}
                                            style={{
                                                height: 24
                                            }}
                                        />
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-1 drag-handle">
                            <ReactSVG
                                path={rearrange}
                                style={{
                                    height: 24
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Recommended react-window performance optimisation: memoize the row render function
// Things are still pretty fast without this, but I am a sucker for making things faster
const Row = React.memo(function Row(props) {
    const { data, index, style } = props;
    const { items, activeToggle, editNewsPost, deletePost } = data;
    const item = items[index];
    return (
        <Draggable draggableId={`${item.id}`} index={index} key={index}>
            {(provided, snapshot) => (
                <Item
                    provided={provided}
                    post={item}
                    snapshot={snapshot}
                    style={style}
                    activeToggle={activeToggle}
                    editNewsPost={editNewsPost}
                    deletePost={deletePost}
                />
            )}
        </Draggable>
    );
}, areEqual);

const getItemStyle = (draggableStyle, isDragging) => ({
    // styles we need to apply on draggables
    ...draggableStyle
});

class News extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                searchLabel: 'Search Yacht name, Model, or any keyword',
                searchFunctionCol: 5,
                parent: news,
                items: [
                    <NavLink
                        to="/news/create"
                        isActive={() =>
                            props.history.location.pathname === `/news`
                        }
                    >
                        Edit News
                    </NavLink>
                ]
            },
            users: [],
            headers: [
                {
                    header: 'IMAGE',
                    classname: 'col-1'
                },
                {
                    header: 'TITLE',
                    classname: 'col-3'
                },
                {
                    header: 'CREATED BY',
                    classname: 'col-2'
                },
                {
                    header: 'DATE',
                    classname: 'col-2'
                },
                {
                    header: 'PUBLIC',
                    classname: 'col-2'
                },
                {
                    header: 'ACTIONS',
                    classname: 'col-1'
                },
                {
                    header: ' ',
                    classname: 'col-1'
                }
            ],
            newsFeed: []
        };
    }

    async componentDidMount() {
        await this.props.fetchNews();
    }

    activeToggle = async (event, news) => {
        let params = {
            id: news.id,
            public: !news.public
        };
        await this.props.fetchEditNews(params);
    };

    editNewsPost = (id) => {
        this.props.history.push(`/news/edit/${id}`);
    };

    deletePost = (id) => {
        this.props.fetchDeleteNews(id);
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const target = this.props.news.filter(
            (post, index) => index === result.destination.index
        );

        const params = {
            id: result.draggableId,
            target: target[0].sort_index
        };
        this.props.reOrderNews(params).then((response) => {
            this.props.fetchNews();
        });
    };

    render() {
        const { news } = this.props;

        return (
            <div>
                <div className="marginBottom80">
                    <Header
                        menu={this.state.menu}
                        history={this.props.history}
                    />
                </div>

                <div className="paddingLeft56">
                    <div className="row">
                        <div className="col-12 col-xl-9">
                            <SimpleTableActions
                                label="Add new post"
                                icon={add}
                                onClick={() =>
                                    this.props.history.push('/news/create')
                                }
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-xl-9">
                            <div className="table-wrapper dnd-table">
                                <div className="table-header">
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        {this.state.headers.map((header, i) => (
                                            <span
                                                key={i}
                                                className={`table-title table-title--dark ${header.classname}`}
                                            >
                                                {header.header}
                                            </span>
                                        ))}
                                    </div>
                                </div>

                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Droppable
                                        droppableId="droppable"
                                        mode="virtual"
                                        renderClone={(
                                            provided,
                                            snapshot,
                                            rubric
                                        ) => (
                                            <Item
                                                provided={provided}
                                                snapshot={snapshot}
                                                isDragging={snapshot.isDragging}
                                                post={news[rubric.source.index]}
                                            />
                                        )}
                                    >
                                        {(provided) => (
                                            <FixedSizeList
                                                height={500}
                                                itemCount={news.length}
                                                itemSize={80}
                                                width={'100%'}
                                                outerRef={provided.innerRef}
                                                itemData={{
                                                    items: news,
                                                    activeToggle: this
                                                        .activeToggle,
                                                    editNewsPost: this
                                                        .editNewsPost,
                                                    deletePost: this.deletePost
                                                }}
                                            >
                                                {Row}
                                            </FixedSizeList>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user_role: state.user_role,
        news: state.news.data
    };
}

export default connect(mapStateToProps, {
    fetchNews,
    fetchEditNews,
    fetchDeleteNews,
    reOrderNews
})(News);
