import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Field, reduxForm } from 'redux-form';
import RenderField from '../common/components/RenderField';
import RenderPassword from '../common/components/RenderPassword';
import { email, required, warn, minLength6 } from '../helpers/validate';

import { resetPassword } from './_actions';
import { showotification } from '../helpers/alerts';

const form = reduxForm({
    form: 'reset_password',
    warn
});

class ResetPassoword extends Component {
    handleFormSubmit(formProps) {
        let params = {
            email: formProps.email
        };

        if (this.props.token !== undefined) {
            params.new_password = formProps.password;
            params.token = this.props.token;
        }
        let extraOpts = {
            position: 'tc',
            action: {
                label: 'Ok',
                callback: () => {
                    this.props.history.push('/login');
                }
            },
            autoDismiss: 0
        };
        this.props.resetPassword(params, extraOpts);
    }

    render() {
        const { handleSubmit, resetPasswordLoading } = this.props;
        return (
            <div className="login-wrapper d-flex align-items-center">
                <div className="form-wrap reset-password-wrap">
                    <div className="row">
                        <div className="col-12">
                            <div className="login-form text-center">
                                <form
                                    onSubmit={handleSubmit(
                                        this.handleFormSubmit.bind(this)
                                    )}
                                >
                                    <div className="text-center mb-3">
                                        <h4>
                                            {this.props.token !== undefined
                                                ? 'Set your password'
                                                : 'Reset your password'}
                                        </h4>
                                        <strong>
                                            All fields are required.
                                        </strong>
                                    </div>
                                    <Field
                                        name="email"
                                        type="email"
                                        placeholder="EMAIL"
                                        validate={[required, email]}
                                        component={RenderField}
                                    />

                                    {this.props.token !== undefined && (
                                        <div>
                                            <Field
                                                name="password"
                                                placeholder="PASSWORD"
                                                validate={[
                                                    required,
                                                    minLength6
                                                ]}
                                                component={RenderPassword}
                                            />
                                            <Field
                                                name="password_confirmation"
                                                placeholder="PASSWORD CONFIRMATION"
                                                validate={[
                                                    required,
                                                    minLength6
                                                ]}
                                                component={RenderPassword}
                                            />
                                        </div>
                                    )}

                                    <button
                                        type="submit"
                                        disabled={resetPasswordLoading}
                                        className="btn btn-success btn-lg btn-block mt-3 mb-1"
                                    >
                                        {!resetPasswordLoading ? (
                                            'Reset'
                                        ) : (
                                            <i className="fa fa-spinner fa-pulse" />
                                        )}
                                    </button>

                                    <strong>
                                        or <Link to="/login">Login</Link> to
                                        your account.
                                    </strong>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        resetPasswordLoading: state.auth.resetPasswordLoading,
        token: ownProps.match.params.token
    };
}

export default connect(mapStateToProps, {
    resetPassword,
    showotification
})(form(ResetPassoword));
