import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';

import Header from '../common/header/Header';

import SimpleTableActions from '../common/components/SimpleTableActions';
import SimpleTable from '../common/components/SimpleTable';
import SimpleModal from '../common/components/SimpleModal';

import salesListings from '../assets/svgs/saleslistings_icon-02.svg';

import DeleteConfirmation from '../common/components/DeleteConfirmation';

import add from '../assets/svgs/add.svg';
import edit from '../assets/svgs/edit.svg';
import eye from '../assets/svgs/open-eye.svg';

import deleteSVG from '../assets/svgs/delete.svg';

import { fetchSalesListings } from './sales/_actions';

import api from '../api';

import { SALES_LISTINGS_URL } from '../helpers/variables';
import { functions } from '../helpers/functions';

class SalesListings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                parent: salesListings,
                items: [
                    <NavLink exact to="/sales-listings">
                        Sales Listings
                    </NavLink>
                ]
            },
            headers: [
                {
                    name: 'internal_title',
                    label: 'Sales Listing IYC'
                },
                {
                    name: 'public_title',
                    label: 'Public Title'
                },
                {
                    name: 'yachts',
                    label: '# of Yachts'
                },
                {
                    name: 'is_public',
                    label: 'Is Published'
                },
                {
                    name: 'action',
                    label: 'Action'
                }
            ],
            modalOpen: false,
            salesListings: {
                pagination: {
                    page: 1,
                    page_size: 15,
                    page_count: 1,
                    total: 0
                },
                sorting: {
                    order_field: 'created_at',
                    order_dir: 'ASC'
                },
                data: []
            }
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    async refetchSalesListings(page) {
        const salesListings = await api.salesListings.getAll({
            page: 1,
            page_size: 15,
            order_field: 'created_at',
            order_dir: 'ASC'
        });
        this.setState({ salesListings });
    }

    async componentDidMount() {
        const salesListings = await api.salesListings.getAll({
            page: 1,
            page_size: 15,
            order_field: 'created_at',
            order_dir: 'ASC'
        });
        this.setState({ salesListings }, () => {
            //console.log(this.state);
        });
    }

    async tableChange(key, newValue) {
        let params = {
            page: key !== 'page' ? 1 : newValue,
            page_size:
                key === 'page_size'
                    ? newValue
                    : this.state.salesListings.pagination.page_size
        };

        const salesListings = await api.salesListings.getAll(params);
        this.setState({ salesListings });
    }

    async deleteModal(salesListing) {
        await this.setState({
            modalDelete: (
                <DeleteConfirmation
                    toggle={this.toggleModal.bind(this)}
                    action={() => this.deleteSalesListing(salesListing)}
                    body={
                        'You are about to delete sales listing ' +
                        salesListing.internal_title +
                        '.'
                    }
                />
            )
        });
        this.setState({ defaultModal: this.state.modalDelete });
        this.setState({ modalHeader: 'Delete Sales Listing' });

        this.toggleModal();
    }

    async deleteSalesListing(listing) {
        const { id } = listing || {};
        await api.salesListings.delete({ id });
        this.refetchSalesListings();
    }

    render() {
        const { role } = this.props.user;
        return (
            <div className="content-inner-wrapper">
                <Header menu={this.state.menu} history={this.props.history} />

                <div className="content-inner">
                    <div className="row">
                        <div className="col-xl-9 col-12">
                            {functions.isModerator(role) &&
                                this.props.userMode !== 'broker' && (
                                    <SimpleTableActions
                                        label="Create new Sales Listing"
                                        icon={add}
                                        onClick={() =>
                                            this.props.history.push(
                                                '/sales-listings/create'
                                            )
                                        }
                                    />
                                )}

                            <SimpleTable
                                tableChange={this.tableChange.bind(this)}
                                paging={this.state.salesListings.pagination}
                                colWidths={[
                                    '3 text-left',
                                    '3 text-left',
                                    1,
                                    '3 text-center',
                                    '2 text-center'
                                ]}
                                headers={this.state.headers}
                                hideExtraFilter={true}
                                rowData={
                                    this.state.salesListings !== undefined &&
                                    this.state.salesListings.data.map(
                                        (value) => {
                                            return [
                                                <div>
                                                    {value.internal_title}
                                                </div>,
                                                <div>{value.public_title}</div>,
                                                <div>
                                                    {value.yachts
                                                        ? value.yachts.length
                                                        : 0}
                                                </div>,
                                                <div>
                                                    {value.is_public
                                                        ? 'Yes'
                                                        : 'No'}
                                                </div>,
                                                <ul className="list-unstyled table-td-actions">
                                                    <li className="text-fill-hover">
                                                        <a
                                                            className="size-12"
                                                            href={`${SALES_LISTINGS_URL}/sales-listings/${value.id}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <ReactSVG
                                                                path={eye}
                                                                style={{
                                                                    height: 24
                                                                }}
                                                            />
                                                        </a>
                                                    </li>
                                                    {functions.isModerator(
                                                        role
                                                    ) &&
                                                        this.props.userMode !==
                                                            'broker' && (
                                                            <li className="text-fill-hover">
                                                                <NavLink
                                                                    to={`/sales-listings/edit/${value.id}`}
                                                                >
                                                                    <ReactSVG
                                                                        path={
                                                                            edit
                                                                        }
                                                                        style={{
                                                                            height: 24
                                                                        }}
                                                                    />
                                                                </NavLink>
                                                            </li>
                                                        )}
                                                    {functions.isModerator(
                                                        role
                                                    ) &&
                                                        this.props.userMode !==
                                                            'broker' && (
                                                            <li>
                                                                <button
                                                                    className="btn btn-td-action"
                                                                    onClick={() =>
                                                                        this.deleteModal(
                                                                            value
                                                                        )
                                                                    }
                                                                >
                                                                    <ReactSVG
                                                                        path={
                                                                            deleteSVG
                                                                        }
                                                                        style={{
                                                                            height: 24
                                                                        }}
                                                                    />
                                                                </button>
                                                            </li>
                                                        )}
                                                </ul>
                                            ];
                                        }
                                    )
                                }
                            />

                            <SimpleModal
                                header={this.state.modalHeader}
                                body={this.state.defaultModal}
                                modalOpen={this.state.modalOpen}
                                toggle={this.toggleModal.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetchSalesListings: (e) => dispatch(fetchSalesListings(e))
    };
}

function mapStateToProps(state) {
    return {
        user: state.user,
        salesListings: state.salesListings.salesListings,
        userMode: state.auth.user_role
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesListings);
