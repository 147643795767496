export const FETCH_MANAGE_SAVED_SEARCH = 'fetch_manage_saved_search',
  FETCH_MANAGE_SAVED_SEARCH_SUCCESS = 'fetch_manage_saved_search_success',
  FETCH_MANAGE_SAVED_SEARCH_FAIL = 'fetch_manage_saved_search_fail',
  FETCH_SAVED_SEARCHES = 'fetch_saved_searches',
  FETCH_SAVED_SEARCHES_SUCCESS = 'fetch_saved_searches_success',
  FETCH_SAVED_SEARCHES_FAIL = 'fetch_saved_searches_fail',
  FETCH_SAVED_SEARCH = 'fetch_saved_search',
  FETCH_SAVED_SEARCH_SUCCESS = 'fetch_saved_search_success',
  FETCH_SAVED_SEARCH_FAIL = 'fetch_saved_search_fail',
  FETCH_SAVED_SEARCH_RESULTS = 'fetch_saved_search_results',
  FETCH_SAVED_SEARCH_RESULTS_SUCCESS = 'fetch_saved_search_results_success',
  FETCH_SAVED_SEARCH_RESULTS_FAIL = 'fetch_saved_search_results_fail',
  FETCH_DELETE_SAVED_SEARCH = 'fetch_delete_saved_search',
  FETCH_DELETE_SAVED_SEARCH_SUCCESS = 'fetch_delete_saved_search_success',
  FETCH_DELETE_SAVED_SEARCH_FAIL = 'fetch_delete_saved_search_fail',
  INIT_SAVED_SEARCH = 'init_saved_search';
