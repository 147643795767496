import axios from 'axios';
import { API_URL } from '../../../../helpers/variables';
import { errorHandler, successHandler } from '../../../../helpers/alerts';
import { authConfig } from '../../../../helpers/authConfig';

import {
    FETCH_YACHT_PRESENTATION,
    FETCH_YACHT_PRESENTATION_SUCCESS,
    FETCH_YACHT_PRESENTATION_ERROR,
    UPDATE_YACHT_PRESENTATION,
    UPDATE_YACHT_PRESENTATION_SUCCESS,
    UPDATE_YACHT_PRESENTATION_ERROR,
    FETCH_PRESENTATION_TOYS,
    FETCH_PRESENTATION_TOYS_SUCCESS,
    FETCH_PRESENTATION_TOYS_FAIL,
    UPDATE_PRESENTATION_TOYS,
    UPDATE_PRESENTATION_TOYS_SUCCESS,
    UPDATE_PRESENTATION_TOYS_FAIL
} from './_types';

const fetchYachtPresentation = (id, params) => {
    return (dispatch) => {
        dispatch({ type: FETCH_YACHT_PRESENTATION });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(`${API_URL}/yachts/presentation/${id}`, config)
            .then((response) => {
                dispatch({
                    type: FETCH_YACHT_PRESENTATION_SUCCESS,
                    payload: response.data
                });
                return response.data;
            })
            .catch((error) => {
                dispatch({ type: FETCH_YACHT_PRESENTATION_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchUpdateYachtPresentation = (id, params) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_YACHT_PRESENTATION });

        const config = {
            headers: authConfig()
        };

        return axios
            .put(`${API_URL}/yachts/presentation/${id}`, params, config)
            .then((response) => {
                dispatch({
                    type: UPDATE_YACHT_PRESENTATION_SUCCESS,
                    payload: response.data
                });

                successHandler(dispatch, 'manageYacht');
                return response.data;
            })
            .catch((error) => {
                dispatch({ type: UPDATE_YACHT_PRESENTATION_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchPresentationToys = (id) => {
    return (dispatch) => {
        dispatch({ type: FETCH_PRESENTATION_TOYS });

        const config = {
            headers: authConfig()
        };

        return axios
            .get(API_URL + `/yachts/${id}/equipment_watersport/`, config)
            .then((response) => {
                dispatch({
                    type: FETCH_PRESENTATION_TOYS_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch((error) => {
                dispatch({ type: FETCH_PRESENTATION_TOYS_FAIL });
                errorHandler(dispatch, error.response);
            });
    };
};

const updatePresentationToys = (id, params) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_PRESENTATION_TOYS });

        const config = {
            headers: authConfig()
        };

        return axios
            .put(
                API_URL + `/yachts/${id}/equipment_watersport/`,
                params,
                config
            )
            .then((response) => {
                dispatch({
                    type: UPDATE_PRESENTATION_TOYS_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch((error) => {
                dispatch({ type: UPDATE_PRESENTATION_TOYS_FAIL });
                errorHandler(dispatch, error.response);
            });
    };
};

export {
    fetchYachtPresentation,
    fetchUpdateYachtPresentation,
    fetchPresentationToys,
    updatePresentationToys
};
