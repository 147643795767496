import React, { Component } from 'react';
import { change, Field, reduxForm } from 'redux-form';
import connect from 'react-redux/es/connect/connect';
import { NavLink } from 'react-router-dom';

import Header from '../common/header/Header';

import upload_news from '../assets/svgs/upload_news.svg';
import UploadField from '../common/components/UploadField';
import { fetchSingleNews, fetchPublishNews, fetchEditNews } from './_actions';
import { functions } from '../helpers/functions';
import { required } from '../helpers/validate';
import RenderField from '../common/components/RenderField';
import news from '../assets/svgs/news.svg';

import DateFromToPicker from '../common/components/datetimepicker/DateFromToPicker';
import * as moment from 'moment';

const form = reduxForm({
    form: 'news-form',
    enableReinitialize: true,
    destroyOnUnmount: false
});

class NewsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                searchLabel: 'Search Yacht name, Model, or any keyword',
                searchFunctionCol: 5,
                parent: news,
                items: [
                    props.match.params.id ? (
                        <NavLink
                            to="/news/create"
                            isActive={() =>
                                props.history.location.pathname ===
                                `/news/edit/${props.match.params.id}`
                            }
                        >
                            Edit News
                        </NavLink>
                    ) : (
                        <NavLink
                            to="/news/create"
                            isActive={() =>
                                props.history.location.pathname ===
                                '/news/create'
                            }
                        >
                            Add News
                        </NavLink>
                    )
                ]
            },
            description: '',
            images: [
                'construction_news',
                'economy_news',
                'yacht_news',
                'iyc_news'
            ],
            selectedImage: 'construction_news',
            uploadImage: '',
            published_date: new Date()
        };

        this.setDescription = this.setDescription.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getUploadedImage = this.getUploadedImage.bind(this);
    }

    setDescription(e) {
        this.props.changeFieldValue('description', e.target.value);
        this.setState({
            description: e.target.value
        });
    }

    pickImage(image) {
        this.setState({
            selectedImage: image
        });
    }

    uploadImage(imgUrl) {
        this.setState({
            uploadImage: imgUrl
        });
    }

    handleSubmit(formProps) {
        let params = {
            title: formProps.title,
            source_url: formProps.source_url,
            description: formProps.description,
            image_url: this.state.uploadImage
                ? this.state.uploadImage
                : this.state.selectedImage,
            public: false,
            published_date: moment(this.state.published_date).format(
                'YYYY-MM-DD'
            )
        };

        if (this.props.match.params && this.props.match.params.id) {
            params.id = this.props.match.params.id;
            this.props.fetchEditNews(params).then(response => {
                this.props.history.push(`/news`);
            });
        } else {
            this.props.fetchPublishNews(params).then(response => {
                this.props.history.push(`/news`);
            });
        }
    }

    getUploadedImage(image) {
        if (this.state.images.includes(image)) {
            return this.setState({
                selectedImage: image,
                uploadImage: ''
            });
        }
        return image;
    }

    dateChange(date, field) {
        this.setState({
            ...this.state,
            [field]: date
        });

        this.props.changeFieldValue(field.name, date.fromDate);
    }

    componentDidMount() {
        if (this.props.match.params && this.props.match.params.id) {
            this.props
                .fetchSingleNews({ id: this.props.match.params.id })
                .then(response => {
                    if (response) {
                        this.props.changeFieldValue('title', response.title);
                        this.props.changeFieldValue(
                            'source_url',
                            response.source_url
                        );
                        this.props.changeFieldValue(
                            'description',
                            response.description
                        );
                        this.setState({
                            uploadImage: response.image_url,
                            description: response.description,
                            published_date: moment(response.published_date)
                        });
                    }
                });
        }
    }

    render() {
        return (
            <div>
                <div className="marginBottom80">
                    <Header
                        menu={this.state.menu}
                        history={this.props.history}
                    />
                </div>
                <div className="paddingTop20 news-form-wrapper">
                    <div className="row">
                        <div className="col-8">
                            <form
                                className="list-form tags-wrapper"
                                onSubmit={this.props.handleSubmit(
                                    this.handleSubmit
                                )}
                            >
                                <div className="name-wrapper">
                                    <Field
                                        name="title"
                                        type="text"
                                        className="mb-1"
                                        placeholder="Title"
                                        validate={[required]}
                                        component={RenderField}
                                    />
                                </div>
                                <div className="tags-list mb-2 pt-1">
                                    <Field
                                        name="source_url"
                                        type="text"
                                        placeholder="SOURCE URL"
                                        component={RenderField}
                                    />
                                </div>
                                <div className="mb-2">
                                    <DateFromToPicker
                                        labels={{
                                            from: 'PUBLISHED DATE'
                                        }}
                                        initFromDate={this.state.published_date}
                                        formatDate={'DD/MM//YYYY'}
                                        single={true}
                                        onChangeFunction={date =>
                                            this.dateChange(
                                                date.fromDate,
                                                'published_date'
                                            )
                                        }
                                    />
                                </div>
                                <div className="marginBottom40 pt-1">
                                    <div className="desc-header">
                                        DESCRIPTION
                                    </div>
                                    <textarea
                                        className="news-desc form-control"
                                        rows="10"
                                        name="description"
                                        id="description"
                                        value={
                                            this.state.description
                                                ? this.state.description
                                                : ''
                                        }
                                        onChange={e => this.setDescription(e)}
                                    />
                                </div>
                                <div className="photo-upload-wrapper">
                                    <div className="row">
                                        <div className="col-4 col-xl-5 col-md-7 mb-2">
                                            <label
                                                htmlFor="coverPhoto"
                                                className="font-500 mb-1 photo-upload-label"
                                            >
                                                COVER PHOTO
                                            </label>
                                            <div className="row no-gutters">
                                                <div className="col-6">
                                                    <img
                                                        className="selected-image"
                                                        style={{
                                                            height: 120,
                                                            width: 120
                                                        }}
                                                        src={
                                                            functions.getNewsPhoto(
                                                                this.state
                                                                    .selectedImage
                                                            )
                                                                ? functions.getNewsPhoto(
                                                                      this.state
                                                                          .selectedImage
                                                                  ).image
                                                                : ''
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="col-5 d-flex flex-wrap justify-content-between align-content-between">
                                                    {this.state.images.map(
                                                        (image, i) => (
                                                            <div
                                                                key={i}
                                                                className="col-6 pl-0"
                                                            >
                                                                <img
                                                                    className={`image-choice ${
                                                                        this
                                                                            .state
                                                                            .selectedImage ===
                                                                        image
                                                                            ? 'image-active'
                                                                            : ''
                                                                    }`}
                                                                    style={{
                                                                        height: 57,
                                                                        width: 57
                                                                    }}
                                                                    src={
                                                                        functions.getNewsPhoto(
                                                                            image
                                                                        )
                                                                            ? functions.getNewsPhoto(
                                                                                  image
                                                                              )
                                                                                  .image
                                                                            : ''
                                                                    }
                                                                    alt={i}
                                                                    onClick={() =>
                                                                        this.pickImage(
                                                                            image
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row no-gutters">
                                        <label
                                            htmlFor="or"
                                            className="font-500 photo-upload-label"
                                        >
                                            Or
                                        </label>
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col-auto mb-2">
                                            <UploadField
                                                name="news_photo"
                                                fileLabelName="Click here to upload"
                                                icon={upload_news}
                                                initPhoto={this.getUploadedImage(
                                                    this.state.uploadImage
                                                )}
                                                add={this.uploadImage.bind(
                                                    this
                                                )}
                                                type="single"
                                                formName="news-form"
                                            />
                                        </div>
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col-12">
                                            <div className="float-right">
                                                <button
                                                    className={`col-4 btn btn-primary`}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    className="col-4 btn btn-clear"
                                                    onClick={() =>
                                                        this.props.history.push(
                                                            '/news'
                                                        )
                                                    }
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change('news-form', field, value)),
        fetchPublishNews: e => dispatch(fetchPublishNews(e)),
        fetchSingleNews: e => dispatch(fetchSingleNews(e)),
        fetchEditNews: e => dispatch(fetchEditNews(e))
    };
}

function mapStateToProps(state, ownProps) {
    return {
        user_role: state.user_role,
        initialValues: {
            title: '',
            source_url: '',
            description: ''
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(form(NewsForm));
