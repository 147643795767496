import React from 'react';
import { yachtStatuses, yachtStates } from '../../helpers/variables';
import moment from 'moment';
const getLabel = (obj) => (obj ? obj.label.toUpperCase() : '-');

const yachtStatus = ({ yacht, className = '' }) => (
    <div className={('yacht-status-container' + className).trim()}>
        {yacht.sales === 1 && (
            <div className="yacht-status">
                {getLabel(
                    yachtStatuses.find(
                        ({ value, label }) =>
                            yacht.status === value || yacht.status === label
                    )
                )}
                {yacht.status &&
                    yacht.status === 'for_sale' &&
                    yacht.last_for_sale &&
                    ` - 
        ${
            moment(new Date()).diff(moment(yacht.last_for_sale), 'days') > 0
                ? moment(new Date()).diff(moment(yacht.last_for_sale), 'days') +
                  ' days'
                : 'TODAY'
        }`}
            </div>
        )}
        {yacht.status_detail && (
            <div className="yacht-status-detail">
                {getLabel(
                    yachtStates.find(
                        ({ value, label }) =>
                            yacht.status_detail === value ||
                            yacht.status_detail === label
                    )
                )}
            </div>
        )}
    </div>
);
export default yachtStatus;
