import React from 'react';
import { connect } from 'react-redux';

import ManageYachtBookingPresenter from './ManageYachtBookingPresenter';

// Redux
import {
    fetchBooking,
    fetchUpdateBooking
} from '../../bookings/redux/_actions';

class EditYachtBookingContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            isReadOnly: false,
            pageTitle: 'UPDATE BOOKING'
        };
    }

    componentDidMount() {
        const id = this.props.match.params.booking_id;
        let params = {};
        this.props
            .fetchBooking(id, params)
            .then(response => this.setState({ loading: false }));
    }

    onSubmit(form_props) {
        const yachtID = this.props.match.params.id;
        const params = Object.assign({}, {
            ...form_props,
            yacht_id: yachtID
        });

        this.setState({ loading: true });
        this.props.fetchUpdateBooking(this.props.match.params.booking_id, params)
            .then(data => {
                this.setState({ loading: false });

                if (data) {
                    this.props.history.push(`/yachts/view/charter/${yachtID}/bookings/`);
                }
            });
    }

    render() {
        return (
            <ManageYachtBookingPresenter
                loading={this.state.loading}
                user_role={this.props.user_role}
                history={this.props.history}
                match={this.props.match}
                isReadOnly={this.state.isReadOnly}
                pageTitle={this.state.pageTitle}
                onSubmit={this.onSubmit.bind(this)}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        user_role: state.auth.user_role
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchBooking: (id, params) => dispatch(fetchBooking(id, params)),
        fetchUpdateBooking: (id, params) =>
            dispatch(fetchUpdateBooking(id, params))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditYachtBookingContainer);
