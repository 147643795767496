import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, change, FieldArray } from 'redux-form';
import { Row, Col } from 'reactstrap';

import YachtMenu from '../components/YachtMenu';
import Loader from '../../../common/components/Loader';
import RenderLocationFields from '../components/RenderLocationFields';

let ManageYachtLocationsAndRatesPresenter = (props) => {
    return (
        <div className="content-inner-wrapper">
            <YachtMenu
                user_role={props.user_role}
                match={props.match}
                history={props.history}
            />

            <div className="content-inner">
                {props.loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <form onSubmit={props.handleSubmit(props.onSubmit)}>
                        <Row>
                            <Col xs="12" lg="6">
                                <h2>Manage yacht locations and rates</h2>
                            </Col>

                            {!props.read_only && (
                                <Col xs="12" lg="6">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Save
                                    </button>
                                </Col>
                            )}

                            {props.read_only &&
                                props.initialValues.location_seasons.length ===
                                    0 && (
                                    <Col xs="12">
                                        {props.user_role.includes('broker') && (
                                            <p style={{ color: '#41506e' }}>
                                                There is no locations assigned
                                                to this yacht
                                            </p>
                                        )}
                                        {!props.user_role.includes(
                                            'broker'
                                        ) && (
                                            <p style={{ color: '#41506e' }}>
                                                There are no locations assigned
                                                to this yacht, click
                                                <NavLink
                                                    to={`/yachts/edit/charter/${props.match.params.id}/locationsAndRates`}
                                                >
                                                    {' '}
                                                    here{' '}
                                                </NavLink>
                                                to add some!
                                            </p>
                                        )}
                                    </Col>
                                )}

                            <Col xs="12">
                                <FieldArray
                                    name="location_seasons"
                                    component={RenderLocationFields}
                                    read_only={props.read_only}
                                    changeFieldValue={props.changeFieldValue}
                                />
                            </Col>
                        </Row>
                    </form>
                )}
            </div>
        </div>
    );
};

ManageYachtLocationsAndRatesPresenter = reduxForm({
    form: 'yacht_locations_form',
    enableReinitialize: true
})(ManageYachtLocationsAndRatesPresenter);

const mapStateToProps = (state) => {
    return {
        initialValues: {
            id: state.yacht_locations.yacht_id,
            location_seasons: state.yacht_locations.location_seasons
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change('yacht_locations_form', field, value))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageYachtLocationsAndRatesPresenter);
