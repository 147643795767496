import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import ScrollToTop from './common/components/ScrollToTop';

import Notifications from 'react-notification-system-redux';

import notificationStyle from './helpers/notificationStyle';
import { fetchInitialState } from './helpers/initial_state/initState';
import { hideNotification } from './helpers/alerts';

import {
    fetchCountries,
    fetchStates,
    fetchLocations,
    fetchTypes
} from './helpers/common/actions';

import { fetchLocationTree } from './pages/locations/redux/_actions';

import { getRealRates } from './helpers/rates/_actions';
import Routes from './Routes';

let init = false;

class AppRouter extends Component {
    componentDidMount() {
        if (this.props.authenticated === true && !this.props.user.name) {
            this.props.fetchInitialState();
        }
        // this.props.getRealRates()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.authenticated === true &&
            nextProps.token.length > 0 &&
            this.props.countries.length === 0 &&
            init === false
        ) {
            init = true;
            this.props.fetchCountries();
            this.props.fetchStates();
            this.props.fetchLocations();
            this.props.fetchTypes();
        }

        if (nextProps.authenticated === false) {
            init = false;
        }
    }

    render() {
        // if (this.props.notifications.length > 0) {
        //     this.props.hideNotification(this.props.notifications[this.props.notifications.length - 1].uid);
        // }

        return (
            <Router history={this.props.history}>
                <ScrollToTop>
                    <div className="content-wrapper">
                        <Routes {...this.props} />

                        <Notifications
                            notifications={this.props.notifications}
                            style={notificationStyle}
                        />
                    </div>
                </ScrollToTop>
            </Router>
        );
    }
}

function mapStateToProps(state) {
    let lastNotification =
        state.notifications && state.notifications.length > 0
            ? [state.notifications[state.notifications.length - 1]]
            : [];

    return {
        authenticated: state.auth.authenticated,
        user_role: state.auth.user_role,
        user: state.user,
        notifications: lastNotification,
        countries: state.common.countries,
        real_rates: state.rates.rates,
        token: state.auth.token
    };
}

export default connect(mapStateToProps, {
    fetchInitialState,
    hideNotification,
    fetchCountries,
    fetchStates,
    fetchLocations,
    fetchTypes,
    getRealRates,
    fetchLocationTree
})(AppRouter);
