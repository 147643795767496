import {
    FETCH_YACHT_PRESENTATION,
    FETCH_YACHT_PRESENTATION_SUCCESS,
    FETCH_PRESENTATION_TOYS_SUCCESS
} from './_types';

const INITIAL_STATE = {
    yacht_id: null,
    presentation: {
        featuredImages: [],
        translations: {}
    }
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_YACHT_PRESENTATION_SUCCESS:
            const { id, presentation } = action.payload;
            return {
                ...state,
                yacht_id: id,
                presentation: {
                    ...state.presentation,
                    ...presentation
                }
            };
        case FETCH_YACHT_PRESENTATION:
            return INITIAL_STATE;
        case FETCH_PRESENTATION_TOYS_SUCCESS:
            return {
                ...state,
                presentation: {
                    ...state.presentation,
                    toys: action.payload
                }
            };
        default:
            return state;
    }
};

export default reducer;
