export const equipmentCommunication = [
    {
        name: 'wifi',
        value: '',
        type: 'checkbox',
        label: 'WiFi connection on board',
        className: 'mt-2'
    },
    {
        name: 'equipment_communication_info',
        value: '',
        type: 'textarea',
        placeholder: 'INFO',
        className: 'mt-2'
    }
];
