export const FETCH_LEADS = 'fetch_leads',
    FETCH_LEADS_SUCCESS = 'fetch_leads_success',
    FETCH_LEADS_ERROR = 'fetch_leads_error',
    FETCH_LEAD = 'fetch_lead',
    FETCH_LEAD_SUCCESS = 'fetch_lead_success',
    FETCH_LEAD_ERROR = 'fetch_lead_error',
    FETCH_EXPORT_LEADS = 'fetch_export_leads',
    FETCH_EXPORT_LEADS_SUCCESS = 'fetch_export_leads_success',
    FETCH_EXPORT_LEADS_ERROR = 'fetch_export_leads_error',
    FETCH_LUCENT_FORMS = 'fetch_lucent_forms',
    FETCH_LUCENT_FORMS_SUCCESS = 'fetch_lucent_forms_success',
    FETCH_LUCENT_FORMS_ERROR = 'fetch_lucent_forms_error',
    DELETE_LEAD = 'delete_lead',
    DELETE_LEAD_SUCCESS = 'delete_lead_success',
    DELETE_LEAD_ERROR = 'delete_lead_error';
