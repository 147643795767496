import axios from 'axios';

import {
    FETCH_CREATE_OFFER,
    FETCH_CREATE_OFFER_SUCCESS,
    FETCH_CREATE_OFFER_FAIL,
    FETCH_OFFERS_HISTORY,
    FETCH_OFFERS_HISTORY_SUCCESS,
    FETCH_OFFERS_HISTORY_FAIL,
    FETCH_CUSTOMER_OFFER,
    FETCH_CUSTOMER_OFFER_SUCCESS,
    FETCH_CUSTOMER_OFFER_FAIL,
    INIT_OFFER,
    FETCH_DELETE_CUSTOMER_OFFER,
    FETCH_DELETE_CUSTOMER_OFFER_SUCCESS,
    FETCH_DELETE_CUSTOMER_OFFER_FAIL,
    FETCH_SEND_CUSTOMER_OFFER,
    FETCH_SEND_CUSTOMER_OFFER_SUCCESS,
    FETCH_SEND_CUSTOMER_OFFER_FAIL
} from './_types';

import { API_URL } from '../../../../helpers/variables';
import { errorHandler, successHandler } from '../../../../helpers/alerts';
import { authConfig } from '../../../../helpers/authConfig';

export function fetchCreateOffer(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_CREATE_OFFER });

        const config = { headers: authConfig() };

        if (params.id) {
            return axios
                .put(API_URL + '/customers/offers/' + params.id, params, config)
                .then((response) => {
                    dispatch({ type: FETCH_CREATE_OFFER_SUCCESS });
                    successHandler(dispatch, 'createOffer');

                    return response.data;
                })
                .catch((error) => {
                    errorHandler(
                        dispatch,
                        error.response,
                        FETCH_CREATE_OFFER_FAIL
                    );
                });
        } else {
            return axios
                .post(
                    API_URL + '/customers/' + params.customer_id + '/offers',
                    params,
                    config
                )
                .then((response) => {
                    dispatch({ type: FETCH_CREATE_OFFER_SUCCESS });
                    successHandler(dispatch, 'createOffer');

                    return response.data;
                })
                .catch((error) => {
                    errorHandler(
                        dispatch,
                        error.response,
                        FETCH_CREATE_OFFER_FAIL
                    );
                });
        }
    };
}

export function fetchOffersHistory(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_OFFERS_HISTORY });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(
                API_URL + '/customers/' + params.customer_id + '/offers',
                config
            )
            .then((response) => {
                dispatch({
                    type: FETCH_OFFERS_HISTORY_SUCCESS,
                    payload: {
                        history: response.data.data,
                        pagination: response.data.pagination,
                        customer: response.data.customer,
                        sorting: {
                            order_dir: params.order_dir,
                            order_field: params.order_field,
                            page_size: params.page_size
                        }
                    }
                });

                return response.data;
            })
            .catch((error) => {
                dispatch({ type: FETCH_OFFERS_HISTORY_FAIL });
                errorHandler(dispatch, error.response);
            });
    };
}

export function fetchCustomerOffer(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_CUSTOMER_OFFER });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/customers/offers/' + params.id + '', config)
            .then((response) => {
                dispatch({
                    type: FETCH_CUSTOMER_OFFER_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch((error) => {
                dispatch({ type: FETCH_CUSTOMER_OFFER_FAIL });
                errorHandler(dispatch, error.response);
            });
    };
}

export function fetchInitOffer(offer) {
    return (dispatch) => {
        dispatch({ type: INIT_OFFER, payload: offer });

        return new Promise(function (resolve, reject) {
            resolve('Success!');
        });
    };
}

export function fetchDeleteOffer(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_DELETE_CUSTOMER_OFFER });

        const config = { headers: authConfig() };

        return axios
            .delete(API_URL + '/customers/offers/' + params.id, config)
            .then((response) => {
                dispatch({ type: FETCH_DELETE_CUSTOMER_OFFER_SUCCESS });
                successHandler(dispatch, 'deleteCustomer');

                return response.data;
            })
            .catch((error) => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_DELETE_CUSTOMER_OFFER_FAIL
                );
            });
    };
}

export function fetchSendOffer(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_SEND_CUSTOMER_OFFER });

        const config = { headers: authConfig() };
        const url = API_URL + '/customers/offers/' + params.id;

        return axios
            .post(
                params.self ? url + '/send-test' : url + '/send',
                params,
                config
            )
            .then((response) => {
                dispatch({ type: FETCH_SEND_CUSTOMER_OFFER_SUCCESS });
                successHandler(dispatch, 'sendOffer');

                return response.data;
            })
            .catch((error) => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_SEND_CUSTOMER_OFFER_FAIL
                );
            });
    };
}
