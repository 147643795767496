import axios from 'axios';

import {
    FETCH_MANAGE_YACHT,
    FETCH_MANAGE_YACHT_SUCCESS,
    FETCH_MANAGE_YACHT_FAIL,
    FETCH_GET_YACHT,
    FETCH_GET_YACHT_SUCCESS,
    FETCH_GET_YACHT_FAIL,
    FETCH_MANAGE_YACHT_TYPE,
    FETCH_MANAGE_YACHT_TYPE_SUCCESS,
    FETCH_MANAGE_YACHT_TYPE_FAIL,
    FETCH_GET_YACHT_TYPE,
    FETCH_GET_YACHT_TYPE_SUCCESS,
    FETCH_GET_YACHT_TYPE_FAIL,
    FETCH_HISTORY_LOG,
    FETCH_HISTORY_LOG_SUCCESS,
    FETCH_HISTORY_LOG_FAIL,
    FETCH_INIT_YACHT,
    FETCH_MANAGE_MEDIA,
    FETCH_MANAGE_MEDIA_SUCCESS,
    FETCH_MANAGE_MEDIA_FAIL,
    FETCH_GET_YACHT_MEDIA,
    FETCH_GET_YACHT_MEDIA_SUCCESS,
    FETCH_GET_YACHT_MEDIA_FAIL,
    FETCH_DELETE_YACHT,
    FETCH_DELETE_YACHT_SUCCESS,
    FETCH_DELETE_YACHT_FAIL,
    FETCH_GET_YACHT_COMMENTS,
    FETCH_GET_YACHT_COMMENTS_SUCCESS,
    FETCH_GET_YACHT_COMMENTS_FAIL,
    FETCH_VIEW_YACHT_COMMENTS,
    FETCH_VIEW_YACHT_COMMENTS_SUCCESS,
    FETCH_VIEW_YACHT_COMMENTS_FAIL,
    FETCH_POST_YACHT_COMMENT,
    FETCH_POST_YACHT_COMMENT_SUCCESS,
    FETCH_POST_YACHT_COMMENT_FAIL,
    FETCH_SEND_YACHT_MAIL,
    FETCH_SEND_YACHT_MAIL_SUCCESS,
    FETCH_SEND_YACHT_MAIL_FAIL,
    CHANGE_YACHT_SELECTION,
    CHANGE_YACHT_SELECTION_CHARTER,
    CHANGE_YACHT_SELECTION_SALES
} from './_types';

import { API_URL } from '../../helpers/variables';
import { errorHandler, successHandler } from '../../helpers/alerts';
import { authConfig } from '../../helpers/authConfig';

export function fetchManageYachts(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_MANAGE_YACHT });

        const config = { headers: authConfig() };

        if (params.id) {
            return axios
                .put(API_URL + '/yachts/' + params.id, params, config)
                .then((response) => {
                    dispatch({ type: FETCH_MANAGE_YACHT_SUCCESS });
                    successHandler(dispatch, 'manageYacht');

                    return response.data;
                })
                .catch((error) => {
                    errorHandler(
                        dispatch,
                        error.response,
                        FETCH_MANAGE_YACHT_FAIL
                    );
                });
        } else {
            return axios
                .post(API_URL + '/yachts', params, config)
                .then((response) => {
                    dispatch({ type: FETCH_MANAGE_YACHT_SUCCESS });
                    successHandler(dispatch, 'manageYacht');

                    return response.data;
                })
                .catch((error) => {
                    errorHandler(
                        dispatch,
                        error.response,
                        FETCH_MANAGE_YACHT_FAIL
                    );
                });
        }
    };
}

export function fetchYacht(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_GET_YACHT });

        const config = { headers: authConfig() };

        return axios
            .get(API_URL + '/yachts/' + params.id, config)
            .then((response) => {
                dispatch({
                    type: FETCH_GET_YACHT_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, FETCH_GET_YACHT_FAIL);
            });
    };
}

export function fetchManageYachtType(params) {
    return (dispatch) => {
        let par = Object.assign({}, params);

        dispatch({ type: FETCH_MANAGE_YACHT_TYPE });

        const config = { headers: authConfig() };

        delete par.id;
        delete par.section_type;

        return axios
            .put(
                API_URL + '/yachts/' + params.id + '/' + params.section_type,
                par,
                config
            )
            .then((response) => {
                dispatch({ type: FETCH_MANAGE_YACHT_TYPE_SUCCESS });
                successHandler(dispatch, 'manageYacht');

                return response.data;
            })
            .catch((error) => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_MANAGE_YACHT_TYPE_FAIL
                );
            });
    };
}

export function fetchGetYachtType(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_GET_YACHT_TYPE });

        const config = { headers: authConfig() };

        return axios
            .get(API_URL + '/yachts/' + params.id + '/' + params.type, config)
            .then((response) => {
                dispatch({
                    type: FETCH_GET_YACHT_TYPE_SUCCESS,
                    payload: { type: params.type, data: response.data }
                });

                return response.data;
            })
            .catch((error) => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_GET_YACHT_TYPE_FAIL
                );
            });
    };
}

export function initYacht() {
    return (dispatch) => {
        dispatch({ type: FETCH_INIT_YACHT });

        return new Promise(function (resolve, reject) {
            resolve('Success!');
        });
    };
}

export function fetchHistoryLog(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_HISTORY_LOG });

        const config = { headers: authConfig() };

        return axios
            .get(API_URL + '/yachts/' + params.id + '/history', config)
            .then((response) => {
                dispatch({
                    type: FETCH_HISTORY_LOG_SUCCESS,
                    payload: {
                        data: response.data,
                        yachtId: params.id
                    }
                });
                return response.data;
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, FETCH_HISTORY_LOG_FAIL);
            });
    };
}

export function fetchManageMedia(params) {
    return (dispatch) => {
        let par = Object.assign({}, params);

        dispatch({ type: FETCH_MANAGE_MEDIA });

        delete par.id;

        if (params.media_id) {
            par.id = params.media_id;
            delete params.media_id;

            const config = { headers: authConfig(), params: par };

            return axios
                .delete(API_URL + '/yachts/' + params.id + '/media', config)
                .then((response) => {
                    dispatch({ type: FETCH_MANAGE_MEDIA_SUCCESS });

                    successHandler(dispatch, 'manageYacht');
                    return response.data;
                })
                .catch((error) => {
                    errorHandler(
                        dispatch,
                        error.response,
                        FETCH_MANAGE_MEDIA_FAIL
                    );
                });
        } else {
            const config = { headers: authConfig() };

            return axios
                .post(API_URL + '/yachts/' + params.id + '/media', par, config)
                .then((response) => {
                    dispatch({ type: FETCH_MANAGE_MEDIA_SUCCESS });

                    successHandler(dispatch, 'manageYacht', {
                        autoDismiss: 3
                    });
                    return response.data;
                })
                .catch((error) => {
                    errorHandler(
                        dispatch,
                        error.response,
                        FETCH_MANAGE_MEDIA_FAIL
                    );
                });
        }
    };
}

export function fetchGetYachtMedia(params) {
    return (dispatch) => {
        let par = Object.assign({}, params);

        dispatch({ type: FETCH_GET_YACHT_MEDIA });

        delete par.id;

        const config = { headers: authConfig(), params: par };

        return axios
            .get(API_URL + '/yachts/' + params.id + '/media', config)
            .then((response) => {
                dispatch({
                    type: FETCH_GET_YACHT_MEDIA_SUCCESS,
                    payload: { data: response.data, type: par.type }
                });

                return response.data;
            })
            .catch((error) => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_GET_YACHT_MEDIA_FAIL
                );
            });
    };
}

export function fetchDeleteYacht(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_DELETE_YACHT });

        const config = { headers: authConfig() };

        return axios
            .delete(API_URL + '/yachts/' + params.id, config)
            .then((response) => {
                dispatch({ type: FETCH_DELETE_YACHT_SUCCESS });
                successHandler(dispatch, 'deleteYacht');

                return response.data;
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, FETCH_DELETE_YACHT_FAIL);
            });
    };
}

export function fetchYachtComments(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_GET_YACHT_COMMENTS });

        const config = { headers: authConfig(), params: params };

        return axios
            .get(API_URL + '/yachts/' + params.id + '/comments', config)
            .then((response) => {
                dispatch({
                    type: FETCH_GET_YACHT_COMMENTS_SUCCESS,
                    payload: response.data
                });
                return response.data;
            })
            .catch((error) => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_GET_YACHT_COMMENTS_FAIL
                );
            });
    };
}

export function fetchYachtCommentsView(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_VIEW_YACHT_COMMENTS });

        const config = { headers: authConfig() };

        return axios
            .put(API_URL + '/yachts/' + params.id + '/view', params, config)
            .then((response) => {
                dispatch({
                    type: FETCH_VIEW_YACHT_COMMENTS_SUCCESS,
                    payload: { yachtId: params.id }
                });
                return response.data;
            })
            .catch((error) => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_VIEW_YACHT_COMMENTS_FAIL
                );
            });
    };
}

export function fetchPostYachtComment(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_POST_YACHT_COMMENT });

        const config = { headers: authConfig() };

        return axios
            .post(
                API_URL + '/yachts/' + params.id + '/comments',
                params,
                config
            )
            .then((response) => {
                dispatch({ type: FETCH_POST_YACHT_COMMENT_SUCCESS });

                return response.data;
            })
            .catch((error) => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_POST_YACHT_COMMENT_FAIL
                );
            });
    };
}

export function fetchSendYachtEmail(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_SEND_YACHT_MAIL });

        const config = { headers: authConfig() };

        return axios
            .post(API_URL + '/yachts/' + params.id + '/email', params, config)
            .then((response) => {
                dispatch({ type: FETCH_SEND_YACHT_MAIL_SUCCESS });

                successHandler(dispatch, 'mailYacht');

                return response.data;
            })
            .catch((error) => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_SEND_YACHT_MAIL_FAIL
                );
            });
    };
}

export function checkYachtNameExists(params) {
    return axios({
        url: `${API_URL}/yachts/find-duplicate-names?name=${params}`,
        method: 'GET',
        headers: authConfig()
    });
}

export function changeYachtSelection(params) {
    return (dispatch) => {
        dispatch({ type: CHANGE_YACHT_SELECTION });
    };
}

export function changeYachtSelectionCharter(params) {
    return (dispatch) => {
        dispatch({ type: CHANGE_YACHT_SELECTION_CHARTER });
    };
}

export function changeYachtSelectionSales(params) {
    return (dispatch) => {
        dispatch({ type: CHANGE_YACHT_SELECTION_SALES });
    };
}

export function getYachtMissingFields(params) {
    return axios({
        url: `${API_URL}/hubspot/${params.id}`,
        method: 'GET',
        headers: authConfig()
    });
}

export function getYachtArchiveValidation(id) {
    return axios({
        url: `${API_URL}/yachts/${id}/archiving-validation`,
        method: 'GET',
        headers: authConfig()
    });
}
