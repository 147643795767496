import {
    FETCH_PRICE_REDUCTIONS,
    FETCH_PRICE_REDUCTIONS_SUCCESS,
    FETCH_PRICE_REDUCTIONS_FAIL,
    FETCH_PRICES,
    FETCH_PRICES_SUCCESS,
    FETCH_PRICES_FAIL,
    FETCH_YACHTS_SOLD,
    FETCH_YACHTS_SOLD_SUCCESS,
    FETCH_YACHTS_SOLD_FAIL,
    FETCH_REPORTS_YACHTS,
    FETCH_REPORTS_YACHTS_SUCCESS,
    FETCH_REPORTS_YACHTS_FAIL,
    FETCH_YEARLY_SALES,
    FETCH_YEARLY_SALES_SUCCESS,
    FETCH_YEARLY_SALES_FAIL,
    //
    HIDE_YACHT_SOLD_SUCCESS,
    HIDE_PRICE_REDUCTION_SUCCESS,
    HIDE_NEWEST_YACHT_SUCCESS
} from './_types';

import _map from 'lodash/map';
import randomColor from 'randomcolor';
import { functions } from '../helpers/functions';

const INITIAL_STATE = {
    yachts_sold: {
        data: [],
        loading: false
    },
    price_reductions: {
        reductions: [],
        total: 0,
        loading: false,
        chartData: {
            labels: [],
            datasets: [{ label: '' }]
        }
    },
    prices: {
        builder: {
            data: [],
            pagination: { page: 1, total: 0, page_size: 1, page_count: 1 }
        },
        size: {
            labels: [],
            datasets: [{ label: 'Average price ($) by size' }]
        },
        tonnage: {
            labels: [],
            datasets: [{ label: 'Average price ($) by tonnage' }]
        },
        hide: false
    },
    reports_yachts: {
        top_one_hundred: {
            data: [],
            pagination: []
        },
        private_deals: {
            data: [],
            pagination: []
        },
        new_entries: {
            data: [],
            pagination: [],
            loading: false
        },
        yachts_not_for_sale: {
            data: [],
            pagination: []
        },
        sorting: {
            order_dir: 'ASC',
            field: 'name'
        },
        yearlySales: {
            data: []
        }
    }
};

const ranges = {
    size: 10,
    tonnage: 100
};

const units = {
    size: 'm',
    tonnage: ' GT'
};

function createLabels(value, type) {
    let label = '';
    label = value;
    if (type === 'size' || type === 'tonnage') {
        if (value) {
            label = `${value * ranges[type] - ranges[type]} - ${value *
                ranges[type]}${units[type]}`;
        } else {
            label = 'Not set';
        }
    }
    return label;
}

export default function reportsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_PRICE_REDUCTIONS:
            return {
                ...state,
                price_reductions: { ...state.price_reductions, loading: true }
            };
        case FETCH_PRICE_REDUCTIONS_SUCCESS: {
            let chartData = {};
            let labels = _map(action.payload.distribution, value => {
                return createLabels(value.label, 'size');
            });
            let data = _map(action.payload.distribution, 'total_reductions');
            let colors = randomColor({
                hue: '#41516C',
                count: action.payload.data.pagination.total,
                seed: action.payload.data.pagination.total
            });
            chartData = {
                labels: labels,
                datasets: [
                    {
                        data: data,
                        backgroundColor: colors
                    }
                ]
            };
            return {
                ...state,
                price_reductions: {
                    total: action.payload.data.total,
                    reductions:
                        action.payload.data.pagination &&
                        action.payload.data.pagination.page === 1
                            ? action.payload.data.data
                            : [
                                  ...state.price_reductions.reductions,
                                  ...action.payload.data.data
                              ],
                    sorting: action.payload.params
                        ? action.payload.params.order_field
                        : undefined,
                    order: action.payload.params
                        ? action.payload.params.order_dir
                        : undefined,
                    pagination: action.payload.data.pagination,
                    chartData: chartData,
                    loading:
                        action.payload.data.pagination.page ===
                        action.payload.data.pagination.page_count
                }
            };
        }
        case FETCH_PRICE_REDUCTIONS_FAIL:
            return {
                ...state,
                price_reductions: { ...state.price_reductions, loading: false }
            };

        case FETCH_PRICES:
            return state;
        case FETCH_PRICES_SUCCESS: {
            let data = {};
            let key = action.payload.type;
            if (key === 'size' || key === 'tonnage') {
                let labels = [];
                labels = _map(action.payload.data, value => {
                    return createLabels(value.label, key);
                });
                let datasets = _map(action.payload.data, 'avg_price');
                data[key] = {
                    labels: labels,
                    datasets: [
                        {
                            label: state.prices[key].datasets[0].label,
                            data: datasets,
                            backgroundColor: '#D8DDE5'
                        }
                    ]
                };
            } else {
                data[key] = {
                    data: action.payload.data.data.map(val => [
                        val.label,
                        functions.currency(val.avg_price, 'usd', true, true)
                    ]),
                    pagination: action.payload.data.pagination
                };
            }
            return {
                ...state,
                prices: { ...state.prices, ...data }
            };
        }
        case FETCH_PRICES_FAIL:
            return state;
        case FETCH_YACHTS_SOLD:
            return {
                ...state,
                yachts_sold: {
                    ...state.yachts_sold,
                    loading: true
                }
            };
        case FETCH_YACHTS_SOLD_SUCCESS:
            return {
                ...state,
                yachts_sold: {
                    data:
                        action.payload.data &&
                        action.payload.data.pagination.page === 1
                            ? action.payload.data.data
                            : [
                                  ...state.yachts_sold.data,
                                  ...action.payload.data.data
                              ],
                    pagination: action.payload.data.pagination,
                    sorting: action.payload.params
                        ? action.payload.params.order_field
                        : undefined,
                    order: action.payload.params
                        ? action.payload.params.order_dir
                        : undefined,
                    loading:
                        action.payload.data.pagination.page ===
                        action.payload.data.pagination.page_count
                }
            };
        case FETCH_YACHTS_SOLD_FAIL:
            return {
                ...state,
                yachts_sold: {
                    data: [],
                    loading: false
                }
            };
        case FETCH_REPORTS_YACHTS:
            return {
                ...state,
                reports_yachts: {
                    new_entries: {
                        ...state.reports_yachts.new_entries,
                        loading: true
                    }
                }
            };
        case FETCH_REPORTS_YACHTS_SUCCESS:
            console.log('REDUCER', action.payload);
            let ret = {
                ...state,
                reports_yachts: {
                    ...state.reports_yachts,
                    [action.payload.type]: {
                        data:
                            action.payload.pagination.page === 1
                                ? action.payload.data
                                : [
                                      ...state.reports_yachts[
                                          action.payload.type
                                      ].data,
                                      ...action.payload.data
                                  ],
                        pagination: action.payload.pagination,
                        sorting: action.payload.sorting,
                        order: action.payload.order,
                        loading:
                            action.payload.type === 'new_entries'
                                ? action.payload.pagination.page ===
                                  action.payload.pagination.page_count
                                : undefined
                    }
                }
            };
            console.log('REDUCER RET', ret);
            return ret;
        case FETCH_REPORTS_YACHTS_FAIL:
            return {
                ...state,
                reports_yachts: {
                    ...state.reports_yachts.new_entries,
                    loading: false
                }
            };
        case FETCH_YEARLY_SALES:
            return state;
        case FETCH_YEARLY_SALES_SUCCESS:
            return {
                ...state,
                yearlySales: action.payload
            };
        case FETCH_YEARLY_SALES_FAIL:
            return state;

        case HIDE_YACHT_SOLD_SUCCESS:
            return {
                ...state,
                yachts_sold: {
                    ...state.reports_yachts,
                    data: state.yachts_sold.data.map(dato =>
                        dato.entry_id !== action.payload.entry_id
                            ? dato
                            : {
                                  ...dato,
                                  broker_visible: action.payload.broker_visible
                              }
                    )
                }
            };

        case HIDE_PRICE_REDUCTION_SUCCESS:
            return {
                ...state,
                price_reductions: {
                    ...state.price_reductions,
                    reductions: state.price_reductions.reductions.map(dato => {
                        return dato.entry_id !== action.payload.entry_id
                            ? dato
                            : {
                                  ...dato,
                                  broker_visible: action.payload.broker_visible
                              };
                    })
                }
            };

        case HIDE_NEWEST_YACHT_SUCCESS:
            return {
                ...state,
                reports_yachts: {
                    new_entries: {
                        data: state.reports_yachts.new_entries.data.map(dato =>
                            dato.entry_id !== action.payload.entry_id
                                ? dato
                                : {
                                      ...dato,
                                      broker_visible:
                                          action.payload.broker_visible
                                  }
                        )
                    }
                }
            };

        default:
            return state;
    }
}
