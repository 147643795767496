import React from 'react';

import RenderField from '../../common/components/RenderField';
import { Field } from 'redux-form';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import { dropdowns } from '../../helpers/variables';
import { normalizePrice, imoValidationError } from '../../helpers/validate';
import { parseNumberValue } from 'common/utils';
import RenderCheckbox from '../../common/components/RenderCheckbox';
import AdminFiltersActions from './AdminFiltersActions';
import Companies from './multiple/Companies';
import builders from '../../infrastructure/data/builders';

const FiltersSidebar = (props) => {
    const newCountries = props.countries.filter(
        (country) => country.name !== 'United States'
    );
    return (
        <div className={`row ${props.mobileView && 'mb-3 px-2 no-gutters'}`}>
            <div className="col-12">
                <AdminFiltersActions cancelForm={props.cancelForm} />
                <div className="row">
                    <div className="search-filter col-12 mb-1">
                        <RenderField
                            name={'table_search'}
                            placeholder="Search Yacht name, Model, or any keyword"
                            input={{
                                value: props.value,
                                onChange: props.searchFunction
                            }}
                        />
                    </div>
                </div>
                <div className="field-outer-wrapper mb-2">
                    <Field
                        className="mb-1"
                        component={RenderCheckbox}
                        label={'Search Previous Yacht Names too'}
                        name={'search_previous_names'}
                    />
                </div>

                <div className="field-outer-wrapper mb-2">
                    <Field
                        className="mb-1"
                        component={RenderCheckbox}
                        label={'Is Public?'}
                        name={'equal_sales_public'}
                    />
                </div>

                <div className="field-outer-wrapper mb-2">
                    <Field
                        className="mb-1"
                        component={RenderCheckbox}
                        label={'Is Archived'}
                        name={'equal_is_archived'}
                    />
                </div>

                <div className="mb-1">
                    <Field
                        name="like_imo"
                        type="text"
                        placeholder="IMO NUMBER"
                        className="mb-2"
                        component={RenderField}
                        validate={[imoValidationError]}
                        parse={parseNumberValue}
                        maxLength="7"
                    />
                </div>

                <div className="mb-1">
                    <Field
                        name="slug"
                        type="text"
                        placeholder="SLUG SEARCH"
                        className="mb-2"
                        component={RenderField}
                    />
                </div>

                <div className="field-outer-wrapper mb-2 mt-1">
                    <Field
                        name={'equal_curation_level'}
                        label="CURATION LEVEL"
                        component={RenderReactSelect}
                        clearable={true}
                        options={dropdowns['curation_level']}
                    />
                </div>

                <div className="field-outer-wrapper mb-2 mt-1">
                    <Field
                        name="has_external_providers"
                        placeholder=" EXTERNAL PROVIDERS"
                        className="mb-2"
                        options={props.externalProviders.map(
                            (externalProvider) => ({
                                value: externalProvider.id,
                                label: externalProvider.name
                            })
                        )}
                        multi={true}
                        component={RenderReactSelect}
                        help={'You can select multiple values.'}
                    />
                </div>

                <div className="field-outer-wrapper mb-2">
                    <div className="filter-label font-500 mb-1">
                        SALE STATUS
                    </div>
                    {props.yachtStatuses.map((status, i) => (
                        <Field
                            key={i}
                            className="mb-1"
                            component={RenderCheckbox}
                            label={status.label}
                            name={`custom_include_status-${status.value}`}
                        />
                    ))}
                </div>
                <div className="field-outer-wrapper">
                    <div className="filter-label font-500 mb-1">
                        VESSEL STATUS
                    </div>
                    {props.yachtStates.map((status, i) => (
                        <Field
                            key={i}
                            className="mb-1"
                            component={RenderCheckbox}
                            label={status.label}
                            name={`custom_include_status_detail-${status.value}`}
                        />
                    ))}
                    <Field
                        className="mb-1"
                        component={RenderCheckbox}
                        label={'Without status'}
                        name={'custom_orExist_status_detail-false'}
                    />
                </div>

                <div className="field-outer-wrapper">
                    <Field
                        name="array_equal_includes"
                        options={props.includesOptions.map(
                            ({ label, group }) => ({ label, value: group })
                        )}
                        placeholder="INCLUDE"
                        className="mb-2"
                        multi={true}
                        component={RenderReactSelect}
                        help={'You can select multiple values.'}
                    />
                </div>
            </div>

            <div className="col-12">
                <Field
                    name="has_types"
                    placeholder="TYPE"
                    className="mb-2"
                    options={props.types}
                    multi={true}
                    component={RenderReactSelect}
                    help={'You can select multiple values.'}
                />

                <Field
                    name="includes_like_builder"
                    placeholder="BUILDER"
                    className="mb-2"
                    options={builders.map((builder) => ({
                        value: builder,
                        label: builder
                    }))}
                    multi={true}
                    component={RenderReactSelect}
                    help={'You can select multiple values.'}
                />

                <div className="floating-label-wrapper mt-3 mb-2 form-group">
                    <Companies
                        form="yachts_filters"
                        single={true}
                        prefix="has_"
                    />
                </div>

                {props.type === 'sales' && (
                    <Field
                        name="include_hull_material"
                        placeholder="HULL MATERIAL"
                        className="mt-3 mb-2"
                        options={dropdowns['hull_material']}
                        multi={true}
                        component={RenderReactSelect}
                        help={'You can select multiple values.'}
                    />
                )}

                {props.type === 'sales' && (
                    <Field
                        name="include_num_engines"
                        placeholder="NUMBER OF ENGINES"
                        className="mt-3 mb-2"
                        options={dropdowns['num_engines']}
                        multi={true}
                        component={RenderReactSelect}
                        help={'You can select multiple value.'}
                    />
                )}

                {props.type === 'sales' && (
                    <Field
                        name="like_engine_manufacturer"
                        type="text"
                        placeholder="ENGINE MANUFACTURER"
                        className="mt-3 mb-2"
                        component={RenderField}
                    />
                )}

                <div className="flex-direction-column justify-content-between field-outer-wrapper">
                    <div className="filter-label font-500">YEAR</div>
                    <div className="row">
                        <div className="col-2 col-md-4 pr-0">
                            <Field
                                name="gte_year_built"
                                parse={(value) => (value ? Number(value) : '')}
                                type="number"
                                placeholder="From"
                                className="mt-0"
                                component={RenderField}
                            />
                        </div>
                        <div className="col-2 col-md-4 pr-0">
                            <Field
                                name="lte_year_built"
                                parse={(value) => (value ? Number(value) : '')}
                                type="number"
                                placeholder="To"
                                className="mt-0"
                                component={RenderField}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex-direction-column justify-content-between field-outer-wrapper">
                    <div className="filter-label font-500">LOA</div>
                    <div className="row">
                        <div className="col-2 col-md-4 pr-0">
                            <Field
                                name="gte_loa"
                                parse={(value) => (value ? Number(value) : '')}
                                type="number"
                                placeholder="From"
                                className="mt-0"
                                component={RenderField}
                            />
                        </div>
                        <div className="col-2 col-md-4 pr-0">
                            <Field
                                name="lte_loa"
                                parse={(value) => (value ? Number(value) : '')}
                                type="number"
                                placeholder="To"
                                className="mt-0"
                                component={RenderField}
                            />
                        </div>
                        <div className="col-3 col-md-4 pr-0 pr-lg-1">
                            <Field
                                name="loa_unit"
                                label=""
                                component={RenderReactSelect}
                                className="mt-0"
                                clearable={false}
                                options={[
                                    { value: 'meters', label: 'Meter' },
                                    { value: 'feet', label: 'Feet' }
                                ]}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex-direction-column justify-content-between field-outer-wrapper">
                    <div className="filter-label font-500">PRICE</div>
                    <div className="row">
                        <div className="col-2 col-md-4 pr-0">
                            <Field
                                name="gte_asking_price"
                                type="text"
                                placeholder="From"
                                className="mt-0"
                                normalize={normalizePrice}
                                component={RenderField}
                            />
                        </div>
                        <div className="col-2 col-md-4 pr-0">
                            <Field
                                name="lte_asking_price"
                                type="text"
                                placeholder="To"
                                className="mt-0"
                                normalize={normalizePrice}
                                component={RenderField}
                            />
                        </div>
                        <div className="col-3 col-md-4 pr-0 pr-lg-1">
                            <Field
                                name={`season_currency`}
                                label=""
                                component={RenderReactSelect}
                                className="mt-0"
                                clearable={false}
                                options={[
                                    { value: 'usd', label: 'USD' },
                                    { value: 'eur', label: 'EURO' },
                                    { value: 'gbp', label: 'GBP' },
                                    { value: 'aud', label: 'AUD' }
                                ]}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Field
                            name="include_country"
                            placeholder="LOCATION"
                            className="mb-2"
                            options={[
                                { name: 'America S. & C.', id: 'am-sc' },
                                { name: 'Europe', id: 'eu' },
                                { name: 'USA', id: 'usa' },
                                {
                                    name: '',
                                    id: '',
                                    disabled: true,
                                    className: 'separator',
                                    separator: ['eu', 'usa', 'am-sc']
                                }
                            ]
                                .concat(newCountries)
                                .map((val) => {
                                    const { id, name, ...others } = val;
                                    return {
                                        value: id,
                                        label: name,
                                        ...others
                                    };
                                })}
                            separators={true}
                            multi={true}
                            component={RenderReactSelect}
                            clearable={true}
                            help={'You can select multiple values.'}
                            readOnly={props.readOnly}
                        />
                    </div>
                </div>

                {props.type === 'sales' && (
                    <div className="row">
                        <div className="col-12 ">
                            <Field
                                name="include_state"
                                placeholder="STATE"
                                options={props.states.map((val) => {
                                    return { value: val.id, label: val.name };
                                })}
                                multi={true}
                                className={
                                    'mb-3 ' +
                                    (props.selectedCountry &&
                                    props.selectedCountry.includes('usa')
                                        ? ''
                                        : 'disabled')
                                }
                                component={RenderReactSelect}
                                clearable={true}
                                help={'You can select multiple values.'}
                                readOnly={props.readOnly}
                            />
                        </div>
                    </div>
                )}

                {props.type === 'sales' && (
                    <div className="flex-direction-column justify-content-between field-outer-wrapper">
                        <div className="filter-label font-500">DRAFT</div>
                        <div className="row">
                            <div className="col-2 col-md-4 pr-0">
                                <Field
                                    name="gte_draft_max"
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    className="mt-0"
                                    placeholder="Min"
                                    component={RenderField}
                                    readOnly={props.readOnly}
                                />
                            </div>
                            <div className="col-4  pr-0">
                                <Field
                                    name="lte_draft_max"
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    placeholder="Max"
                                    className="mt-0"
                                    component={RenderField}
                                    readOnly={props.readOnly}
                                />
                            </div>
                            <div className="col-3 col-md-4 pr-0 pr-lg-1">
                                <Field
                                    name="draft_max_unit"
                                    label=""
                                    component={RenderReactSelect}
                                    className="mt-0"
                                    clearable={false}
                                    options={[
                                        { value: 'meters', label: 'Meter' },
                                        { value: 'feet', label: 'Feet' }
                                    ]}
                                    readOnly={props.readOnly}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {props.type === 'sales' && (
                    <div className="flex-direction-column justify-content-between field-outer-wrapper">
                        <div className="filter-label font-500">BEAM</div>
                        <div className="row">
                            <div className="col-2 col-md-4 pr-0">
                                <Field
                                    name="gte_beam"
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    className="mt-0"
                                    placeholder="Min"
                                    component={RenderField}
                                    readOnly={props.readOnly}
                                />
                            </div>
                            <div className="col-2 col-md-4 pr-0">
                                <Field
                                    name="lte_beam"
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    className="mt-0"
                                    placeholder="Max"
                                    component={RenderField}
                                    readOnly={props.readOnly}
                                />
                            </div>
                            <div className="col-3 col-md-4 pr-0 pr-lg-1">
                                <Field
                                    name="beam_unit"
                                    label=""
                                    component={RenderReactSelect}
                                    className="mt-0"
                                    clearable={false}
                                    options={[
                                        { value: 'meters', label: 'Meter' },
                                        { value: 'feet', label: 'Feet' }
                                    ]}
                                    readOnly={props.readOnly}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {props.type === 'sales' && (
                    <div className="flex-direction-column justify-content-between field-outer-wrapper">
                        <div className="filter-label font-500">
                            MASTER CABIN
                        </div>
                        <div className="row">
                            <div className="col-2 col-md-4 pr-0">
                                <Field
                                    name="gte_master_cabin"
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    className="mt-0"
                                    placeholder="Min"
                                    component={RenderField}
                                    readOnly={props.readOnly}
                                />
                            </div>
                            <div className="col-2 col-md-4 pr-0">
                                <Field
                                    name="lte_master_cabin"
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    className="mt-0"
                                    placeholder="Max"
                                    component={RenderField}
                                    readOnly={props.readOnly}
                                />
                            </div>
                            <div className="col-3 col-md-4 pr-0 pr-lg-1">
                                <Field
                                    name="master_cabin_unit"
                                    label=""
                                    component={RenderReactSelect}
                                    className="mt-0"
                                    clearable={false}
                                    options={[
                                        {
                                            value: 'square_meters',
                                            label: 'm²'
                                        },
                                        {
                                            value: 'square_feet',
                                            label: 'ft²'
                                        }
                                    ]}
                                    readOnly={props.readOnly}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {props.type === 'sales' && (
                    <div className="flex-direction-column justify-content-between field-outer-wrapper">
                        <div className="filter-label font-500">SUNDECK</div>
                        <div className="row">
                            <div className="col-2 col-md-4 pr-0">
                                <Field
                                    name="gte_sundeck"
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    className="mt-0"
                                    placeholder="Min"
                                    component={RenderField}
                                    readOnly={props.readOnly}
                                />
                            </div>
                            <div className="col-2 col-md-4 pr-0">
                                <Field
                                    name="lte_sundeck"
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    className="mt-0"
                                    placeholder="Max"
                                    component={RenderField}
                                    readOnly={props.readOnly}
                                />
                            </div>
                            <div className="col-3 col-md-4 pr-0 pr-lg-1">
                                <Field
                                    name="sundeck_unit"
                                    label=""
                                    component={RenderReactSelect}
                                    className="mt-0"
                                    clearable={false}
                                    options={[
                                        {
                                            value: 'square_meters',
                                            label: 'm²'
                                        },
                                        {
                                            value: 'square_feet',
                                            label: 'ft²'
                                        }
                                    ]}
                                    readOnly={props.readOnly}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {props.type === 'sales' && (
                    <div className="flex-direction-column justify-content-between field-outer-wrapper">
                        <div className="filter-label font-500">SPEED</div>
                        <div className="row">
                            <div className="col-2 col-md-4 pr-0">
                                <Field
                                    name="gte_speed_cruising"
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    placeholder="From"
                                    className="mt-0"
                                    component={RenderField}
                                    readOnly={props.readOnly}
                                />
                            </div>
                            <div className="col-2 col-md-4 pr-0">
                                <Field
                                    name="lte_speed_cruising"
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    placeholder="To"
                                    className="mt-0"
                                    component={RenderField}
                                    readOnly={props.readOnly}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {props.type === 'sales' && (
                    <div className="flex-direction-column justify-content-between field-outer-wrapper">
                        <div className="filter-label font-500">CABINS</div>
                        <div className="row">
                            <div className="col-2 col-md-4 pr-0">
                                <Field
                                    name="gte_cabins"
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    placeholder="From"
                                    className="mt-0"
                                    component={RenderField}
                                    readOnly={props.readOnly}
                                />
                            </div>
                            <div className="col-2 col-md-4 pr-0">
                                <Field
                                    name="lte_cabins"
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    placeholder="To"
                                    className="mt-0"
                                    component={RenderField}
                                    readOnly={props.readOnly}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <hr className="col-xl-10 col-md-12 my-2 filter-divider" />

            <div className="col-12">
                <AdminFiltersActions cancelForm={props.cancelForm} />
            </div>
        </div>
    );
};

export default FiltersSidebar;
