import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const MissingFieldsWarningModal = (props) => {
    return (
        <div>
            <Modal isOpen={props.isOpen}>
                <ModalHeader toggle={props.toggle}>
                    <span className="custom-modal-warning__modal-title">
                        WARNING
                    </span>
                </ModalHeader>
                <hr className="mt-0 mb-1 filter-divider" />
                <ModalBody>
                    <p>
                        You are about to save a yacht that is missing basic
                        info. This may cause issues in how the yacht is
                        displayed in newsletters, proposals and/or on the
                        website.
                    </p>
                    <p>The fields that should be filled are:</p>

                    <div className="mb-1">
                        {(props.messageSalesSpecs.length > 0 ||
                            props.messageCharterSpecs.length > 0) && (
                            <p className="custom-modal-warning__section-title">
                                Specs Tab:
                            </p>
                        )}
                        {props.messageSalesSpecs.length > 0 &&
                            props.messageSalesSpecs.map((field, index) => {
                                return <li key={index}>{field}</li>;
                            })}

                        {props.messageCharterSpecs.length > 0 &&
                            props.messageCharterSpecs.map((field, index) => {
                                return <li key={index}>{field}</li>;
                            })}
                    </div>
                    <div className="mb-1">
                        {props.messageGeneral.length > 0 && (
                            <p className="custom-modal-warning__section-title">
                                More info Tab:
                            </p>
                        )}
                        {props.messageGeneral.length > 0 &&
                            props.messageGeneral.map((field, index) => {
                                return <li key={index}>{field}</li>;
                            })}
                    </div>
                    <div className="mb-1">
                        {(props.messageSalesWebsite.length > 0 ||
                            props.messageCharterWebsite.length > 0) && (
                            <p className="custom-modal-warning__section-title">
                                Website Tab:
                            </p>
                        )}
                        {props.messageSalesWebsite.length > 0 &&
                            props.messageSalesWebsite.map((field, index) => {
                                return <li key={index}>{field}</li>;
                            })}

                        {props.messageCharterWebsite.length > 0 &&
                            props.messageCharterWebsite.map((field, index) => {
                                return <li key={index}>{field}</li>;
                            })}
                    </div>
                    <div className="mb-0">
                        {props.messageRates ? (
                            <>
                                <p className="custom-modal-warning__section-title">
                                    Locations & Rates Tab:
                                </p>
                                <li>This yacht has no saved rates</li>
                            </>
                        ) : null}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={props.toggle}>
                        I understand
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};
export default MissingFieldsWarningModal;
