import {
    FETCH_EXCHANGE_RATES,
    FETCH_EXCHANGE_RATES_SUCCESS,
    FETCH_EXCHANGE_RATES_FAIL
} from './_types';

const INITIAL_STATE = {
    rates: {}
};

export default function RatesReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_EXCHANGE_RATES:
            return { ...state };
        case FETCH_EXCHANGE_RATES_SUCCESS:
            return { ...state, rates: action.payload };
        case FETCH_EXCHANGE_RATES_FAIL:
            return { ...state };
        default:
            return state;
    }
}
