import axios from 'axios';

import { API_URL } from '../helpers/variables';
import { authConfig } from '../helpers/authConfig';
import { errorHandler } from '../helpers/alerts';

import {
    FETCH_DRIVE_DEFAULTS,
    FETCH_DRIVE_DEFAULTS_SUCCESS,
    FETCH_DRIVE_DEFAULTS_ERROR,
    FETCH_DRIVE,
    FETCH_DRIVE_SUCCESS,
    FETCH_DRIVE_ERROR,
    FETCH_CREATE_DRIVE,
    FETCH_CREATE_DRIVE_SUCCESS,
    FETCH_CREATE_DRIVE_ERROR,
    FETCH_UPDATE_DRIVE,
    FETCH_UPDATE_DRIVE_SUCCESS,
    FETCH_UPDATE_DRIVE_ERROR,
    FETCH_DELETE_DRIVE,
    FETCH_DELETE_DRIVE_SUCCESS,
    FETCH_DELETE_DRIVE_ERROR
} from './_types';

const fetchDriveDefaults = params => {
    return dispatch => {
        dispatch({ type: FETCH_DRIVE_DEFAULTS });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/drive/defaults', config)
            .then(response => {
                dispatch({
                    type: FETCH_DRIVE_DEFAULTS_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_DRIVE_DEFAULTS_ERROR });

                errorHandler(dispatch, error.response, 'error');
            });
    };
};

const fetchDrive = params => {
    return dispatch => {
        dispatch({ type: FETCH_DRIVE });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/drive', config)
            .then(response => {
                dispatch({
                    type: FETCH_DRIVE_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_DRIVE_ERROR });

                errorHandler(dispatch, error.response, 'error');
            });
    };
};

const fetchCreateDriveItem = params => {
    return dispatch => {
        dispatch({ type: FETCH_CREATE_DRIVE });

        const config = {
            headers: authConfig()
        };

        console.log(params);

        return axios
            .post(API_URL + '/drive', params, config)
            .then(response => {
                dispatch({ type: FETCH_CREATE_DRIVE_SUCCESS });

                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_CREATE_DRIVE_ERROR });

                errorHandler(dispatch, error.response, 'error');
            });
    };
};

const fetchUpdateDriveItem = params => {
    return dispatch => {
        dispatch({ type: FETCH_UPDATE_DRIVE });

        const config = {
            headers: authConfig()
        };

        return axios
            .put(API_URL + '/drive/' + params.id, params, config)
            .then(response => {
                dispatch({ type: FETCH_UPDATE_DRIVE_SUCCESS });

                return response.data;
            })

            .catch(error => {
                dispatch({ type: FETCH_UPDATE_DRIVE_ERROR });

                errorHandler(dispatch, error.response, 'error');
            });
    };
};

const fetchDeleteDriveItem = params => {
    return dispatch => {
        dispatch({ type: FETCH_DELETE_DRIVE });

        const config = {
            headers: authConfig()
        };

        return axios
            .delete(API_URL + '/drive/' + params.id, config)
            .then(response => {
                dispatch({ type: FETCH_DELETE_DRIVE_SUCCESS });

                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_DELETE_DRIVE_ERROR });

                errorHandler(dispatch, error.response, 'error');
            });
    };
};

const fetchDownloadDriveItem = params => {
    return dispatch => {
        // dispatch({ type: FETCH_DOWNLOAD_DRIVE_ITEM });

        const config = {
            headers: authConfig(),
            responseType: 'blob'
        };

        axios
            .get(API_URL + '/drive/download/' + params.id, config)
            .then(response => {
                download(
                    response.data,
                    'file.' +
                        response.headers['content-type']
                            .split(';')[0]
                            .split('/')
                            .pop(),
                    response.headers['content-type']
                );
            });
    };
};

export {
    fetchDriveDefaults,
    fetchDrive,
    fetchCreateDriveItem,
    fetchUpdateDriveItem,
    fetchDeleteDriveItem,
    fetchDownloadDriveItem
};

function download(data, filename, mime, bom) {
    var blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
    var blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
    } else {
        var blobURL = window.URL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }
}
