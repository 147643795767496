import {
    FETCH_YACHTS,
    FETCH_YACHTS_SUCCESS,
    FETCH_YACHTS_FAIL,
    FETCH_BROKERS_LIST,
    FETCH_BROKERS_LIST_SUCCESS,
    FETCH_BROKERS_LIST_FAIL,
    FETCH_LOAD_YACHTS,
    FETCH_LOAD_YACHTS_SUCCESS,
    FETCH_LOAD_YACHTS_FAIL,
    INIT_SEARCH,
    FETCH_GET_SELECTED_YACHTS,
    FETCH_GET_SELECTED_YACHTS_SUCCESS,
    FETCH_GET_SELECTED_YACHTS_FAIL,
    FETCH_SELECT_YACHTS_SUCCESS,
    FETCH_DELETE_SELECTED_YACHTS_SUCCESS,
    FETCH_VIEW_YACHT_COMMENTS,
    FETCH_VIEW_YACHT_COMMENTS_SUCCESS,
    FETCH_VIEW_YACHT_COMMENTS_FAIL,
    CHANGE_YACHT_SELECTION,
    CHANGE_YACHT_SELECTION_IN_SELECTED,
    CHANGE_ALL_YACHTS_SELECTION,
    CHANGE_ALL_YACHTS_SELECTION_IN_SELECTED,
    FETCH_COMPANIES,
    FETCH_COMPANIES_SEARCH_SUCCESS,
    FETCH_COMPANIES_SEARCH_FAIL,
    YACHT_ORDER_CHANGE,
    CHANGE_YACHT_STATUS_REQUEST,
    DESELECT_ALL_YACHTS_IN_SELECTION,
    CHANGE_YACHT_STATUS_FAILURE
} from './_types';

import _findIndex from 'lodash/findIndex';

const INITIAL_STATE = {
    pagination: { page: 1, total: 0, page_size: 15, page_count: 1 },
    sorting: { order_field: 'loa', order_dir: 'DESC', search: '' },
    query: {},
    yachts: [],
    brokers: [],
    selectedYachts: {
        selection: [],
        total: 0
    }
};

export default function yachtsSearchReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_YACHTS:
            return state;
        case FETCH_YACHTS_SUCCESS:
            return {
                ...state,
                pagination: {
                    page:
                        action.payload.pagination.total === 0
                            ? 0
                            : action.payload.pagination.page >
                              action.payload.pagination.page_count
                            ? 1
                            : action.payload.pagination.page,
                    total: action.payload.pagination.total,
                    page_size: action.payload.pagination.page_size,
                    page_count: action.payload.pagination.page_count
                },
                yachts: action.payload.yachts,
                sorting: action.payload.sorting,
                query: action.payload.query
            };
        case FETCH_YACHTS_FAIL:
            return { ...state, error: action.payload };
        case FETCH_LOAD_YACHTS:
            return state;
        case FETCH_LOAD_YACHTS_SUCCESS:
            return { ...state, newsletter_yacts: action.payload };
        case FETCH_LOAD_YACHTS_FAIL:
            return { ...state, error: action.payload };
        case FETCH_BROKERS_LIST:
            return state;
        case FETCH_BROKERS_LIST_SUCCESS:
            return {
                ...state,
                brokers: action.payload
            };
        case FETCH_BROKERS_LIST_FAIL:
            return { ...state, error: action.payload };
        case INIT_SEARCH:
            return {
                ...state,
                yachts: [],
                pagination: {
                    ...state.pagination,
                    page: 1,
                    total: 0,
                    page_count: 1
                },
                query: {}
            };

        case FETCH_SELECT_YACHTS_SUCCESS:
        case FETCH_DELETE_SELECTED_YACHTS_SUCCESS:
        case FETCH_GET_SELECTED_YACHTS_SUCCESS:
            return {
                ...state,
                selectedYachts: action.payload
            };

        case FETCH_GET_SELECTED_YACHTS:
            return state;
        case FETCH_GET_SELECTED_YACHTS_FAIL:
            return { ...state, selectedYachts: [] };

        case FETCH_VIEW_YACHT_COMMENTS:
            return state;
        case FETCH_VIEW_YACHT_COMMENTS_SUCCESS: {
            //turn off the notification for new comment
            let yachtIndex = _findIndex(state.yachts, [
                'id',
                action.payload.yachtId
            ]);
            let yachts = state.yachts;
            yachts[yachtIndex].new_comments = null;
            return {
                ...state,
                yachts: yachts
            };
        }
        case FETCH_VIEW_YACHT_COMMENTS_FAIL:
            return state;

        case CHANGE_YACHT_SELECTION: {
            let yachts = state.yachts.map(yacht => {
                let yachtNew = yacht;
                if (yacht.id === action.payload.yachtId) {
                    yachtNew.selected = yacht.selected === 0 ? 1 : 0;

                    if (action.payload.type === 'sales') {
                        yachtNew.selected_on_sales = !yachtNew.selected_on_sales;
                    } else {
                        yachtNew.selected_on_charter = !yachtNew.selected_on_charter;
                    }
                }
                return yachtNew;
            });
            return {
                ...state,
                yachts: yachts
            };
        }
        case CHANGE_YACHT_SELECTION_IN_SELECTED: {
            let yachts = state.selectedYachts.selection.map(yacht => {
                let yachtNew = yacht;

                if (
                    yacht.id === action.payload.yachtId &&
                    yacht.selected !== undefined
                ) {
                    yachtNew.selected = yacht.selected === 0 ? 1 : 0;
                }
                if (
                    yacht.id === action.payload.yachtId &&
                    yacht.selected === undefined
                ) {
                    yachtNew.selected = 0;
                }

                return yachtNew;
            });

            return {
                ...state,
                selectedYachts: {
                    ...state.selectedYachts,
                    selection: yachts
                }
            };
        }

        case CHANGE_ALL_YACHTS_SELECTION: {
            let yachts = state.yachts.map(yacht => {
                let new_yacht = {
                    ...yacht,
                    selected: action.payload.selection,
                    selected_on_sales:
                        action.payload.type === 'sales'
                            ? action.payload.selection
                            : yacht.selected_on_sales,
                    selected_on_charter:
                        action.payload.type === 'charter'
                            ? action.payload.selection
                            : yacht.selected_on_charter
                };

                return new_yacht;
            });
            return {
                ...state,
                yachts: yachts
            };
        }
        case CHANGE_ALL_YACHTS_SELECTION_IN_SELECTED: {
            let yachts = state.selectedYachts.selection.map(yacht => {
                return { ...yacht, selected: action.payload };
            });
            return {
                ...state,
                selectedYachts: {
                    ...state.selectedYachts,
                    selection: yachts
                }
            };
        }
        case DESELECT_ALL_YACHTS_IN_SELECTION:
            let yachts = state.selectedYachts.selection.map(yacht => {
                yacht.selected = 0;
                return yacht;
            });

            return {
                ...state,
                selectedYachts: {
                    ...state.selectedYachts,
                    selection: yachts
                }
            };
        case YACHT_ORDER_CHANGE:
            return getReorderState(state, action.payload);
        case CHANGE_YACHT_STATUS_REQUEST:
            return {
                ...state,
                yachts: state.yachts.reduce((acc, yacht) => {
                    acc.push(
                        yacht.id === action.payload.yacht.id
                            ? {
                                  ...yacht,
                                  status: action.payload.status,
                                  status_detail: action.payload.status_detail
                              }
                            : yacht
                    );

                    return acc;
                }, [])
            };
        case CHANGE_YACHT_STATUS_FAILURE:
            return {
                ...state,
                yachts: state.yachts.reduce((acc, yacht) => {
                    acc.push(
                        yacht.id === action.payload.yacht.id
                            ? action.payload.yacht
                            : yacht
                    );

                    return acc;
                }, [])
            };
        case FETCH_COMPANIES:
        case FETCH_COMPANIES_SEARCH_SUCCESS:
        case FETCH_COMPANIES_SEARCH_FAIL:
        default:
            return state;
    }
}

function getReorderState(state, payload) {
    const { from, to } = payload,
        selection = state.selectedYachts.selection.concat([]);

    const sgn = Math.sign(to - from);
    let prev = selection[from];
    for (let i = to; sgn * i >= sgn * from; i = i - sgn) {
        const tmp = selection[i];
        selection[i] = prev;
        prev = tmp;
    }

    return { ...state, selectedYachts: { ...state.selectedYachts, selection } };
}
