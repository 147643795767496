import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import ReactSVG from 'react-svg';

import debounce from 'lodash/debounce';

import RenderField from '../components/RenderField';
import RenderToggle from '../components/RenderToggle';
import AdminActions from '../../yachts/search/AdminActions';

import { switchUserRole } from '../../auth/_actions';
import { isAdmin } from '../../infrastructure/helpers/roles';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = { value: '' };

        this.searchFunction = this.searchFunction.bind(this);
        if (this.props.menu.searchFunction)
            this.changed = debounce(this.props.menu.searchFunction, 400);
    }

    searchFunction(event) {
        const val = event.target.value;

        this.setState({ value: val }, () => {
            if (this.changed) this.changed('search', val);
        });
    }

    switchRole() {
        // Reset search value in order to get everything if role_user is changed
        this.setState({ value: '' }, () => {
            if (this.changed) this.changed('search', '');
        });

        if (
            this.props.user_role !== 'broker' &&
            this.props.user_role !== 'broker_sales' &&
            this.props.user_role !== 'broker_charter'
        ) {
            this.props.switchUserRole('broker').then(() => {
                this.props.history.push('/dashboard');
            });
        } else {
            this.props.switchUserRole(this.props.user.role).then(() => {
                this.props.history.push('/dashboard');
            });
        }
    }

    render() {
        const { menu, user, user_role } = this.props;

        return (
            <div className="header main-header" id="header">
                <div className="row">
                    <div className="col-md-11 col-xl-10">
                        <div className="row">
                            <div
                                className={`col-12 d-flex ${
                                    isAdmin(this.props.user_role)
                                        ? 'justify-content-between'
                                        : 'justify-content-start'
                                }`}
                            >
                                <div className="col-auto">
                                    <ul className="list-unstyled list-inline active-nav">
                                        {menu.parent && (
                                            <li className="nav-icon-wrap">
                                                {menu.backLink !== undefined ? (
                                                    <NavLink
                                                        exact={true}
                                                        to={menu.backLink}
                                                    >
                                                        <ReactSVG
                                                            style={{
                                                                height: 32,
                                                                width: 32
                                                            }}
                                                            path={menu.parent}
                                                        />
                                                    </NavLink>
                                                ) : (
                                                    <ReactSVG
                                                        style={{
                                                            height: 32,
                                                            width: 32
                                                        }}
                                                        path={menu.parent}
                                                    />
                                                )}
                                            </li>
                                        )}
                                        {menu.items &&
                                            menu.items.map((item, i) => {
                                                return (
                                                    <li
                                                        key={i}
                                                        className={
                                                            item.className
                                                                ? item.className
                                                                : 'plain-links'
                                                        }
                                                    >
                                                        {item.link
                                                            ? item.link
                                                            : item}
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </div>

                                {isAdmin(this.props.user_role) && (
                                    <AdminActions
                                        classname="my-auto"
                                        user_role={this.props.user_role}
                                    />
                                )}

                                {this.props.user_role === 'broker' &&
                                    this.props.history.location.pathname !==
                                        '/dashboard' &&
                                    menu.searchFunction !== undefined && (
                                        <div className="search-filter col-md-6 col-xl-4">
                                            <RenderField
                                                name={'table_search'}
                                                placeholder={menu.searchLabel}
                                                input={{
                                                    value: this.state.value,
                                                    onChange: this
                                                        .searchFunction
                                                }}
                                            />
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>

                    {
                        <div className="col-2 col-md-1 col-xl-2 text-right">
                            {user &&
                                user.role &&
                                user.role !== 'broker' &&
                                user.role !== 'broker_sales' &&
                                user.role !== 'broker_charter' && (
                                    <div className="role-switch-wrapper">
                                        <RenderToggle
                                            name={'role_switch'}
                                            label={
                                                'Switch role to ' +
                                                (user_role !== user.role
                                                    ? user.role
                                                    : 'broker')
                                            }
                                            input={{
                                                value:
                                                    user_role === 'broker' ||
                                                    user_role ===
                                                        'broker_sales' ||
                                                    user_role ===
                                                        'broker_charter'
                                                        ? true
                                                        : false,
                                                onChange: (event, v) =>
                                                    this.switchRole(event)
                                            }}
                                        />
                                    </div>
                                )}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user_role: state.auth.user_role,
        user: state.user
    };
}

export default connect(mapStateToProps, { switchUserRole })(Header);
