import React, { Component } from 'react';
import SimplePaginationWrapper from './pagination/SimplePaginationWrapper';

class SimpleTable extends Component {
    constructor(props) {
        super(props);

        let initState = props.headers.reduce((initState, elem, i) => {
            initState[elem.name] = elem.defaultSort;
            return initState;
        }, {});

        this.state = {
            initState,
            page_size: 2
        };
    }

    sortClick(key, newValue) {
        this.setState({
            ...this.state,
            initState: {
                ...this.state.initState,
                [key]: newValue
            }
        });
        this.props.tableChange(key, newValue);
    }

    render() {
        const {
            colWidths,
            headers,
            rowData,
            paging,
            actions,
            extraFilter
        } = this.props;
        return (
            <div className="table">
                {paging && (
                    <SimplePaginationWrapper
                        search={this.props.search}
                        onSearch={this.props.tableChange}
                        className={'pagination-header'}
                        total={paging.total}
                        currentPage={paging.page}
                        totalPages={paging.page_count}
                        pageChangeFunction={this.props.tableChange}
                        pageSize={paging.page_size}
                        actions={actions !== undefined ? actions : null}
                        extraFilter={extraFilter ? extraFilter : null}
                        hideExtraFilter={this.props.hideExtraFilter}
                    />
                )}

                <div className="table-header">
                    <div className="row align-items-center">
                        {headers.map((header, i) => {
                            return (
                                <div
                                    key={i}
                                    className={
                                        'header-col col-' +
                                        colWidths[i] +
                                        (header.defaultSort
                                            ? this.state.initState[
                                                  header.name
                                              ] === 'DESC'
                                                ? '  sort-icon'
                                                : ' asc sort-icon'
                                            : '')
                                    }
                                    onClick={
                                        header.sort && header.name !== 'broker'
                                            ? (e) =>
                                                  this.sortClick(
                                                      header.name,
                                                      this.state.initState[
                                                          header.name
                                                      ] === 'DESC'
                                                          ? 'ASC'
                                                          : 'DESC'
                                                  )
                                            : null
                                    }
                                >
                                    <span>{header.label}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="table-body">
                    {rowData.length > 0 ? (
                        rowData.map((row, i) => {
                            var colCounter = 0;

                            return this.props.mouseIN !== undefined &&
                                this.props.mouseOut !== undefined ? (
                                <div
                                    className="table-row popup-wrap"
                                    key={i}
                                    onMouseEnter={this.props.mouseIN.bind(
                                        this,
                                        i
                                    )}
                                    onMouseLeave={this.props.mouseOut.bind(
                                        this
                                    )}
                                >
                                    <div className="row">
                                        {row.map((rowInfo, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className={
                                                        'col-' +
                                                        colWidths[colCounter++]
                                                    }
                                                >
                                                    {rowInfo}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={
                                        'table-row ' +
                                        (this.props.bodyClass !== undefined
                                            ? this.props.bodyClass
                                            : '')
                                    }
                                    key={i}
                                >
                                    <div className="row">
                                        {row.map((rowInfo, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className={
                                                        'col-' +
                                                        colWidths[colCounter++]
                                                    }
                                                >
                                                    {rowInfo}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <span className="d-block text-center mt-2">
                            {this.props.noDataText || ''}
                        </span>
                    )}
                </div>

                {paging && (
                    <SimplePaginationWrapper
                        className={'pagination-footer'}
                        currentPage={paging.page}
                        totalPages={paging.page_count}
                        pageChangeFunction={this.props.tableChange}
                        pageSize={paging.page_size}
                        footer={true}
                    />
                )}
            </div>
        );
    }
}

export default SimpleTable;
