import React, { Component } from 'react';
import SimplePaginationWrapper from '../../common/components/pagination/SimplePaginationWrapper';
import ResultView from '../yacht/ResultView';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import Loader from '../../common/components/Loader';

import { isAdmin, isBroker } from '../../infrastructure/helpers/roles';

class Results extends Component {
    constructor(props) {
        super(props);
        this.state = { value: 'desc_loa' };

        this.handleChange = this.handleChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.initialOrderDir !== this.props.initialOrderDir &&
            nextProps.initialOrderField !== this.props.initialOrderField
        ) {
            this.setState({
                value: `${nextProps.initialOrderDir.toLowerCase()}_${
                    nextProps.initialOrderField
                }`
            });
        }
    }

    handleChange(value) {
        this.setState({ value: value });

        let order_field = value;
        let order_dir = 'ASC';

        if (order_field.indexOf('desc_') !== -1) {
            order_dir = 'DESC';
        }

        order_field = order_field.replace('desc_', '').replace('asc_', '');
        this.props.tableChange(order_field, order_dir);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            JSON.stringify(nextProps) !== JSON.stringify(this.props) ||
            JSON.stringify(nextState) !== JSON.stringify(this.state)
        );
    }

    render() {
        const { yachts, selectAll } = this.props;
        return (
            <div className="table search-results">
                {yachts.pagination && (
                    <SimplePaginationWrapper
                        className={'pagination-header'}
                        total={yachts.pagination.total}
                        currentPage={yachts.pagination.page}
                        totalPages={yachts.pagination.page_count}
                        pageChangeFunction={this.props.tableChange}
                        yachtName={
                            this.props.yachts.sorting
                                ? this.props.yachts.sorting.search
                                : ''
                        }
                        is_public={
                            this.props.yachts.query &&
                            this.props.yachts.query.equal
                                ? this.props.yachts.query.equal
                                : ''
                        }
                        is_archived={
                            this.props.yachts.query &&
                            this.props.yachts.query.equal &&
                            this.props.yachts.query.equal.is_archived
                                ? this.props.yachts.query.equal.is_archived
                                : ''
                        }
                        curation_level={
                            this.props.yachts.query &&
                            this.props.yachts.query.equal &&
                            this.props.yachts.query.equal.curation_level
                                ? this.props.yachts.query.equal.curation_level
                                : ''
                        }
                        yachtStatus={
                            this.props.yachts.query &&
                            this.props.yachts.query.equal
                                ? this.props.yachts.query.equal.status
                                : ''
                        }
                        pageSize={yachts.pagination.page_size}
                        allCheckbox={this.props.allCheckbox}
                        selected={this.props.selected}
                        hidePagination={this.props.hidePagination}
                        hideExtraFilter={this.props.hideExtraFilter}
                        extraFilter={
                            <RenderReactSelect
                                name="ordering"
                                meta={{}}
                                input={{
                                    value: this.state.value,
                                    onChange: this.handleChange
                                }}
                                clearable={false}
                                options={[
                                    {
                                        value: 'desc_loa',
                                        label: 'LOA (m) - DESC'
                                    },
                                    {
                                        value: 'asc_loa',
                                        label: 'LOA (m) - ASC'
                                    },
                                    {
                                        value: 'desc_asking_price',
                                        label: 'Asking Price ($) - DESC'
                                    },
                                    {
                                        value: 'asc_asking_price',
                                        label: 'Asking Price ($) - ASC'
                                    },
                                    {
                                        value: 'desc_year_built',
                                        label: 'Year Built - DESC'
                                    },
                                    {
                                        value: 'asc_year_built',
                                        label: 'Year Built - ASC'
                                    }
                                ]}
                            />
                        }
                    />
                )}
                <div className="yachts-wrapper mt-2">
                    {selectAll !== undefined && selectAll === true && (
                        <div
                            className={`select-all-wrapper d-flex mb-1 ${
                                this.props.userRole === 'broker'
                                    ? 'justify-content-between'
                                    : 'justify-content-end'
                            }`}
                        >
                            {this.props.showCheckbox !== undefined &&
                                this.props.showCheckbox &&
                                yachts.pagination.total <= 100 && (
                                    <button
                                        type="button"
                                        className="btn btn-main"
                                        onClick={(e) =>
                                            this.props.onSelectAllChange(
                                                e,
                                                true
                                            )
                                        }
                                    >
                                        Select all
                                    </button>
                                )}
                            {this.props.is_mobile_view &&
                                isAdmin(this.props.userRole) && (
                                    <div className="d-flex justify-content-between mb-1">
                                        <button
                                            className="btn btn-primary"
                                            onClick={(e) =>
                                                this.props.toggleSidebar(
                                                    e,
                                                    'filters'
                                                )
                                            }
                                        >
                                            View Filters
                                        </button>
                                        <button
                                            className="btn btn-primary"
                                            onClick={(e) =>
                                                this.props.toggleSidebar(
                                                    e,
                                                    'selection'
                                                )
                                            }
                                        >
                                            View Selection
                                        </button>
                                    </div>
                                )}

                            {this.props.is_mobile_view &&
                                isBroker(this.props.userRole) && (
                                    <button
                                        className="btn btn-primary"
                                        onClick={(e) =>
                                            this.props.toggleSidebar(
                                                e,
                                                'selection'
                                            )
                                        }
                                    >
                                        View Selection
                                    </button>
                                )}
                        </div>
                    )}
                    {this.props.loading ? (
                        <Loader />
                    ) : (
                        yachts &&
                        yachts.yachts &&
                        yachts.yachts.map((value, i) => {
                            return (
                                <ResultView
                                    shouldUseSelected={
                                        this.props.shouldUseSelected
                                            ? this.props.shouldUseSelected
                                            : false
                                    }
                                    viewType={this.props.viewType}
                                    key={value.id}
                                    allCheckbox={this.props.allCheckbox}
                                    showCheckbox={this.props.showCheckbox}
                                    selected={
                                        value.selected === 1 ? true : false
                                    }
                                    onCheckboxChange={
                                        this.props.onCheckboxChange
                                    }
                                    userRole={this.props.userRole}
                                    categories={this.props.categories}
                                    changeYachtCategory={(category) =>
                                        this.props.changeYachtCategory(
                                            category,
                                            value.id
                                        )
                                    }
                                    readOnly={this.props.readOnly}
                                    yacht={value}
                                    favouriteYacht={
                                        this.props.favouriteYacht !== undefined
                                            ? this.props.favouriteYacht.bind(
                                                  this
                                              )
                                            : null
                                    }
                                    hideActions={this.props.hideActions}
                                    onYachtStatusChange={
                                        this.props.onYachtStatusChange
                                    }
                                />
                            );
                        })
                    )}
                </div>
                {yachts.pagination && (
                    <SimplePaginationWrapper
                        className={'pagination-footer'}
                        currentPage={yachts.pagination.page}
                        totalPages={yachts.pagination.page_count}
                        pageChangeFunction={this.props.tableChange}
                        yachtName={
                            this.props.yachts.sorting
                                ? this.props.yachts.sorting.search
                                : ''
                        }
                        is_public={
                            this.props.yachts.query &&
                            this.props.yachts.query.equal
                                ? this.props.yachts.query.equal
                                : ''
                        }
                        is_archived={
                            this.props.yachts.query &&
                            this.props.yachts.query.equal &&
                            this.props.yachts.query.equal.is_archived
                                ? this.props.yachts.query.equal.is_archived
                                : ''
                        }
                        curation_level={
                            this.props.yachts.query &&
                            this.props.yachts.query.equal &&
                            this.props.yachts.query.equal.curation_level
                                ? this.props.yachts.query.equal.curation_level
                                : ''
                        }
                        yachtStatus={
                            this.props.yachts.query &&
                            this.props.yachts.query.equal
                                ? this.props.yachts.query.equal.status
                                : ''
                        }
                        pageSize={yachts.pagination.page_size}
                        footer={true}
                        hidePagination={this.props.hidePagination}
                    />
                )}
            </div>
        );
    }
}

export default Results;
