import React, { Component } from 'react';
import { Field } from 'redux-form';
import RenderField from '../../../common/components/RenderField';

const renderHidden = (field) => <input type="hidden" {...field.input} />;

class RenderBroker extends Component {
    render() {
        const { active, suggestions, change, select } = this.props;
        const index = 1;
        return (
            <ul className="list-unstyled companies-list">
                <li key={index}>
                    <Field
                        name={
                            this.props.type === 'sales'
                                ? 'has_brokers[0].name'
                                : 'has_charterBrokers[0].name'
                        }
                        type="text"
                        placeholder={
                            this.props.type === 'sales'
                                ? 'BROKER'
                                : 'CHARTER MANAGER'
                        }
                        component={RenderField}
                        onChange={(e, n, p) => change(index, e, n, p)}
                        autoComplete={false}
                    />

                    <Field name="has_brokers[0].id" component={renderHidden} />

                    {active === index &&
                    suggestions &&
                    suggestions.length > 0 ? (
                        <ul className="list-unstyled results-list">
                            {suggestions.map((sug, i) => {
                                return (
                                    <li key={i}>
                                        <a
                                            href="/#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                select(sug, index);
                                            }}
                                        >
                                            {(sug?.company || '') +
                                                (sug?.company && sug?.name
                                                    ? '-'
                                                    : '') +
                                                (sug?.name || '')}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : null}
                </li>
            </ul>
        );
    }
}

export default RenderBroker;
