import axios from 'axios';

import {
    FETCH_CUSTOMERS,
    FETCH_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMERS_FAIL,
    FETCH_MANAGE_CUSTOMER,
    FETCH_MANAGE_CUSTOMER_SUCCESS,
    FETCH_MANAGE_CUSTOMER_FAIL,
    FETCH_GET_CUSTOMER,
    FETCH_GET_CUSTOMER_SUCCESS,
    FETCH_GET_CUSTOMER_FAIL,
    INIT_CUSTOMER,
    FETCH_DELETE_CUSTOMER,
    FETCH_DELETE_CUSTOMER_SUCCESS,
    FETCH_DELETE_CUSTOMER_FAIL,
    FETCH_CUSTOMER_ENQUIRIES,
    FETCH_CUSTOMER_ENQUIRIES_SUCCESS,
    FETCH_CUSTOMER_ENQUIRIES_FAIL
} from './_types';

import { API_URL } from '../../helpers/variables';
import { errorHandler, successHandler } from '../../helpers/alerts';
import { authConfig } from '../../helpers/authConfig';

export function fetchCustomers(params) {
    return dispatch => {
        dispatch({ type: FETCH_CUSTOMERS });
        console.log(params);
        const config = {
            headers: authConfig(),
            params: params
        };

        axios
            .get(API_URL + '/customers', config)
            .then(response => {
                dispatch({
                    type: FETCH_CUSTOMERS_SUCCESS,
                    payload: {
                        customers:
                            params.format === 'dropdown'
                                ? response.data
                                : response.data.customers,
                        pagination: response.data.pagination,
                        sorting: {
                            order_dir: params.order_dir,
                            order_field: params.order_field,
                            page_size: params.page_size,
                            favourite: params.favourite ? params.favourite : 0,
                            search: params.search ? params.search : ''
                        }
                    }
                });
            })
            .catch(error => {
                dispatch({ type: FETCH_CUSTOMERS_FAIL });
                errorHandler(dispatch, error.response);
            });
    };
}

export function fetchManageCustomer(params) {
    return dispatch => {
        dispatch({ type: FETCH_MANAGE_CUSTOMER });

        const config = { headers: authConfig() };

        if (params.id) {
            return axios
                .put(API_URL + '/customers/' + params.id, params, config)
                .then(response => {
                    dispatch({
                        type: FETCH_MANAGE_CUSTOMER_SUCCESS,
                        payload: response.data
                    });
                    successHandler(dispatch, 'manageCustomer');

                    return response.data;
                })
                .catch(error => {
                    errorHandler(
                        dispatch,
                        error.response,
                        FETCH_MANAGE_CUSTOMER_FAIL
                    );
                });
        } else {
            return axios
                .post(API_URL + '/customers', params, config)
                .then(response => {
                    dispatch({
                        type: FETCH_MANAGE_CUSTOMER_SUCCESS,
                        payload: response.data
                    });
                    successHandler(dispatch, 'manageUser');

                    return response.data;
                })
                .catch(error => {
                    errorHandler(
                        dispatch,
                        error.response,
                        FETCH_MANAGE_CUSTOMER_FAIL
                    );
                });
        }
    };
}

export function fetchCustomer(params) {
    return dispatch => {
        dispatch({ type: FETCH_GET_CUSTOMER });

        const config = { headers: authConfig() };

        return axios
            .get(API_URL + '/customers/' + params.id, config)
            .then(response => {
                dispatch({
                    type: FETCH_GET_CUSTOMER_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch(error => {
                errorHandler(dispatch, error.response, FETCH_GET_CUSTOMER_FAIL);
            });
    };
}

export function initCustomer(customer) {
    return dispatch => {
        dispatch({ type: INIT_CUSTOMER, payload: customer });

        return new Promise(function(resolve, reject) {
            resolve('Success!');
        });
    };
}

export function fetchDeleteCustomer(params) {
    return dispatch => {
        dispatch({ type: FETCH_DELETE_CUSTOMER });

        const config = { headers: authConfig() };

        return axios
            .delete(API_URL + '/customers/' + params.id, config)
            .then(response => {
                dispatch({ type: FETCH_DELETE_CUSTOMER_SUCCESS });
                successHandler(dispatch, 'deleteCustomer');

                return response.data;
            })
            .catch(error => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_DELETE_CUSTOMER_FAIL
                );
            });
    };
}

export function getEnquiries(params) {
    const config = {
        headers: authConfig(),
        responseType: 'arraybuffer' // request response is binary
    };

    return dispatch => {
        dispatch({ type: FETCH_CUSTOMER_ENQUIRIES });

        return axios
            .get(API_URL + '/customers/' + params.id + '/enquiries', config)
            .then(response => {
                dispatch({
                    type: FETCH_CUSTOMER_ENQUIRIES_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch(error => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_CUSTOMER_ENQUIRIES_FAIL
                );
            })
            .then(r => {
                if (!r) return;
                const a = window.document.createElement('a');
                a.style = 'display:none';
                window.document.body.appendChild(a);
                const data = new Blob([r], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(data);
                a.href = url;
                a.download = 'enquiries.pdf';
                a.click();
                setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                }, 100);
            });
    };
}
