import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { ModalBody, ModalFooter, Button } from 'reactstrap';

import RenderField from '../../common/components/RenderField';

import { required } from '../../helpers/validate';

import { fetchManageSavedSearch } from '../../saved_searches/_actions';

const form = reduxForm({
    form: 'save_search'
});

class SaveSearch extends Component {
    handleFormSubmit(formProps) {
        let params = Object.assign({}, formProps);
        params.query = this.props.yachts.query;

        if (this.props.initSearch.id) {
            params.id = this.props.initSearch.id;
        }

        if (this.props.type === 'charter') {
            params.chartering = 1;
            params.query['_prefix'] = this.props.prefix;
        } else {
            params.chartering = 0;
        }

        this.props.fetchManageSavedSearch(params).then(data => {
            if (data) {
                this.props.toggle();
            }
        });
    }

    render() {
        const { handleSubmit, submitting, invalid } = this.props;

        return (
            <form
                className="modal-wrapper"
                onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
            >
                <ModalBody>
                    <div className="modal-form-wrap">
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 mb-1 mt-1">
                                <Field
                                    group="name"
                                    name="name"
                                    type="text"
                                    placeholder="NAME"
                                    validate={[required]}
                                    component={RenderField}
                                />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="submit"
                        color="primary"
                        disabled={invalid || submitting}
                    >
                        Save
                    </Button>{' '}
                    <Button
                        type="button"
                        color="secondary"
                        disabled={submitting}
                        onClick={this.props.toggle}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </form>
        );
    }
}

function mapStateToProps(state) {
    return {
        yachts: state.yachts.search,
        initSearch: state.saved_searches.initSearch,
        initialValues: {
            name: state.saved_searches.initSearch.name
        }
    };
}

export default connect(
    mapStateToProps,
    { fetchManageSavedSearch }
)(form(SaveSearch));
