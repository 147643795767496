import {
    FETCH_INIT_YACHT_LOCATIONS,
    FETCH_YACHT_LOCATIONS_SUCCESS
} from './_types';

const INITIAL_STATE = {
    location_seasons: [],
    yacht_id: null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_YACHT_LOCATIONS_SUCCESS:
            return {
                ...state,
                yacht_id: action.payload.id,
                location_seasons: action.payload.location_seasons.map(
                    season => {
                        season.locations = season.locations.map(
                            location => location.id
                        );

                        return season;
                    }
                )
            };
        case FETCH_INIT_YACHT_LOCATIONS:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default reducer;
