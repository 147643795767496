export const FETCH_INIT_YACHT_RATES = 'fetch_init_yacht_rates',
    FETCH_YACHT_LOCATIONS = 'fetch_yacht_locations',
    FETCH_YACHT_LOCATIONS_SUCCESS = 'fetch_yacht_locations_success',
    FETCH_YACHT_LOCATIONS_ERROR = 'fetch_yacht_locations_error',
    UPDATE_YACHT_LOCATIONS = 'update_yacht_locations',
    UPDATE_YACHT_LOCATIONS_SUCCESS = 'update_yacht_locations_succes',
    UPDATE_YACHT_LOCATIONS_ERROR = 'update_yacht_locations_error',
    FETCH_YACHT_RATES = 'fetch_yacht_rates',
    FETCH_YACHT_RATES_SUCCESS = 'fetch_yacht_rates_success',
    FETCH_YACHT_RATES_ERROR = 'fetch_yacht_rates_error',
    UPDATE_YACHT_RATES = 'update_yacht_rates',
    UPDATE_YACHT_RATES_SUCCESS = 'update_yacht_rates_succes',
    UPDATE_YACHT_RATES_ERROR = 'update_yacht_rates_error';
