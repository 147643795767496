import {
    FETCH_CREATE_OFFER,
    FETCH_CREATE_OFFER_SUCCESS,
    FETCH_CREATE_OFFER_FAIL,
    FETCH_OFFERS_HISTORY,
    FETCH_OFFERS_HISTORY_SUCCESS,
    FETCH_OFFERS_HISTORY_FAIL,
    FETCH_CUSTOMER_OFFER,
    FETCH_CUSTOMER_OFFER_SUCCESS,
    FETCH_CUSTOMER_OFFER_FAIL,
    INIT_OFFER
} from './_types';

const INITIAL_STATE = {
    pagination: { page: 1, total: 0, page_size: 1, page_count: 1 },
    sorting: { order_field: 'sent_at', order_dir: 'DESC' },
    history: [],
    initOffer: {
        cover_media_type: 'photo'
    },
    initCustomer: {
        first_name: '',
        last_name: ''
    }
};

export default function customersOffersReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_CREATE_OFFER:
            return state;
        case FETCH_CREATE_OFFER_SUCCESS:
            return state;
        case FETCH_CREATE_OFFER_FAIL:
            return { ...state, error: action.payload };
        case FETCH_OFFERS_HISTORY:
            return state;
        case FETCH_OFFERS_HISTORY_SUCCESS:
            return {
                ...state,
                pagination: {
                    page:
                        action.payload.pagination.total === 0
                            ? 0
                            : action.payload.pagination.page >
                              action.payload.pagination.page_count
                            ? 1
                            : action.payload.pagination.page,
                    total: action.payload.pagination.total,
                    page_size: action.payload.pagination.page_size,
                    page_count: action.payload.pagination.page_count
                },
                history: action.payload.history,
                sorting: action.payload.sorting,
                initCustomer: action.payload.customer
            };
        case FETCH_OFFERS_HISTORY_FAIL:
            return { ...state, error: action.payload };
        case FETCH_CUSTOMER_OFFER:
            return {
                ...state,
                initOffer: {}
            };
        case FETCH_CUSTOMER_OFFER_SUCCESS:
        case INIT_OFFER:
            return {
                ...state,
                initOffer: action.payload
            };
        case FETCH_CUSTOMER_OFFER_FAIL:
            return { ...state, error: action.payload };
        default:
            return state;
    }
}
