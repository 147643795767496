import React, { useState } from 'react';
import { ModalBody, ModalFooter, Button } from 'reactstrap';
import Loader from '../../../common/components/Loader';

export default function ExternalYachtConfirmation(props) {
    const [loading, setLoading] = useState(false);

    const handleFormSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        props.action(props.yacht);
    };
    return (
        <form className="modal-wrapper" onSubmit={handleFormSubmit}>
            <ModalBody>
                <div className="modal-form-wrap">
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2 mb-2 mt-2 text-center">
                            {props.modal_body}
                            {loading ? (
                                <>
                                    <div className="mt-1 mb-1">
                                        Saving yacht data...
                                    </div>
                                    <div className="external-yacht-import-loader">
                                        <Loader />
                                    </div>
                                    <div className="mt-1">
                                        Please wait until the import is
                                        complete!
                                    </div>
                                </>
                            ) : (
                                <div className="mt-1">
                                    Do you wish to continue?
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button type="submit" color="danger" disabled={loading}>
                    Confirm
                </Button>
                <Button
                    type="button"
                    color="secondary"
                    onClick={props.toggleModal}
                    disabled={loading}
                >
                    Cancel
                </Button>
            </ModalFooter>
        </form>
    );
}
