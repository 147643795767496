import axios from 'axios';
import { API_URL, LUCENT_API, LUCENT_KEY } from '../../../helpers/variables';
import { errorHandler, successHandler } from '../../../helpers/alerts';
import { authConfig } from '../../../helpers/authConfig';

import {
    FETCH_LEADS,
    FETCH_LEADS_SUCCESS,
    FETCH_LEADS_ERROR,
    FETCH_LEAD,
    FETCH_LEAD_SUCCESS,
    FETCH_LEAD_ERROR,
    FETCH_EXPORT_LEADS,
    FETCH_EXPORT_LEADS_ERROR,
    FETCH_LUCENT_FORMS,
    FETCH_LUCENT_FORMS_SUCCESS,
    FETCH_LUCENT_FORMS_ERROR,
    DELETE_LEAD,
    DELETE_LEAD_SUCCESS,
    DELETE_LEAD_ERROR
} from './_types';

import download from '../../../infrastructure/helpers/download';

const fetchLeads = (params) => {
    return (dispatch) => {
        dispatch({ type: FETCH_LEADS });

        const options = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(`${API_URL}/contact-leads`, options)
            .then((response) => {
                dispatch({
                    type: FETCH_LEADS_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch((error) => {
                dispatch({ type: FETCH_LEADS_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchLead = (id) => {
    return (dispatch) => {
        dispatch({ type: FETCH_LEAD });

        const options = {
            headers: authConfig()
        };

        return axios
            .get(`${API_URL}/contact-leads/${id}`, options)
            .then((response) => {
                dispatch({
                    type: FETCH_LEAD_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch((error) => {
                dispatch({ type: FETCH_LEAD_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchExportLeads = (params) => {
    return (dispatch) => {
        dispatch({ type: FETCH_EXPORT_LEADS });

        const options = {
            headers: authConfig(),
            params: params
            // responseType: 'blob'
        };
        console.log(options);
        return axios
            .get(`${API_URL}/contact-leads/export`, options)
            .then((response) => {
                download(
                    response.data,
                    'leads.' +
                        response.headers['content-type']
                            .split(';')[0]
                            .split('/')
                            .pop(),
                    response.headers['content-type']
                );

                return response.data;
            })
            .catch((error) => {
                console.log(error);
                dispatch({ type: FETCH_EXPORT_LEADS_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchLucentForms = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_LUCENT_FORMS });

        return axios
            .get(
                `${LUCENT_API}documents?contentType=contact_forms&limit=200&locale=en-GB&apiKey=${LUCENT_KEY}`
            )
            .then((response) => {
                dispatch({
                    type: FETCH_LUCENT_FORMS_SUCCESS,
                    payload: response.data.data
                });

                return response.data.data;
            })
            .catch((error) => {
                dispatch({ type: FETCH_LUCENT_FORMS_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const deleteLead = (id) => {
    return (dispatch) => {
        dispatch({ type: DELETE_LEAD });

        const options = {
            headers: authConfig()
        };

        return axios
            .delete(`${API_URL}/contact-leads/${id}`, options)
            .then((response) => {
                dispatch({
                    type: DELETE_LEAD_SUCCESS,
                    payload: response.data
                });
                successHandler(dispatch, 'lead_deleted');

                return response.data;
            })
            .catch((error) => {
                dispatch({ type: DELETE_LEAD_ERROR });

                errorHandler(dispatch, error.response);
            });
    };
};

export {
    fetchLeads,
    fetchLead,
    fetchExportLeads,
    fetchLucentForms,
    deleteLead
};
