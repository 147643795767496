import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { Field } from 'redux-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { required } from '../../helpers/validate';

import ActionButton from './ActionButton';
import RenderField from './RenderField';
import RenderReactSelect from './RenderReactSelect';

import add from '../../assets/svgs/add.svg';
import remove from '../../assets/svgs/remove.svg';

class RenderDraggablePoints extends Component {
    onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        this.props.fields.move(result.source.index, result.destination.index);
    }

    renderDraggable(i, index, dropdownPlaces, fields, provided, snapshot) {
        return (
            <div className="col-12 ">
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="mb-2 row pt-1"
                >
                    <div className="col-auto d-flex align-items-center">
                        <div
                            className="drag-handle"
                            style={provided.draggableStyle}
                        >
                            ::
                        </div>
                    </div>
                    <div className="col-4">
                        <Field
                            key={i}
                            name={`${i}._pivot_text`}
                            className="mb-1 m-0"
                            placeholder="TITLE"
                            validate={[required]}
                            component={RenderField}
                        />
                    </div>
                    <div className="col-xl-4 col-lg-5 col-md-6">
                        <Field
                            key={i}
                            name={`${i}.id`}
                            placeholder="CHOOSE DESTINATION"
                            component={RenderReactSelect}
                            validate={[required]}
                            options={dropdownPlaces}
                            className="m-0"
                        />
                    </div>
                    {fields.getAll().length > 1 && index !== 0 && (
                        <div className="col-2 d-flex justify-content-center">
                            <button
                                className="btn btn-empty d-inline-flex align-items-center p-0 h-100"
                                onClick={() => fields.remove(index)}
                            >
                                <ReactSVG
                                    path={remove}
                                    className="error-svg"
                                    style={{ height: 16 }}
                                />
                            </button>
                        </div>
                    )}
                </div>
                {provided.placeholder}
            </div>
        );
    }

    render() {
        const {
            dropdownPlaces,
            fields
            // meta: { touched, error, submitFailed }
        } = this.props;
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-12">
                        <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div className="" ref={provided.innerRef}>
                                        {fields.map((i, index) => (
                                            <Draggable
                                                key={i}
                                                draggableId={`${index}`}
                                                index={index}
                                            >
                                                {(provided, snapshot) =>
                                                    this.renderDraggable(
                                                        i,
                                                        index,
                                                        dropdownPlaces,
                                                        fields,
                                                        provided,
                                                        snapshot
                                                    )
                                                }
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    <div className="col-5 mb-3">
                        <ActionButton
                            className="btn-blue reverse"
                            icon={
                                <ReactSVG path={add} style={{ height: 12 }} />
                            }
                            label="Add destination"
                            click={() => fields.push({})}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default RenderDraggablePoints;
