import DirectedGraph from '../../../infrastructure/DataStructures/DirectedGraph';

function importHierarchy(exported) {
    const hierarchy = new DirectedGraph();
    const names = new Map();

    for (const root of exported) {
        importLocation(root, hierarchy, names);
    }

    return { hierarchy: hierarchy, names: names };
}

function importLocation(location, hierarchy, names) {
    if (!hierarchy.hasNode(location.value)) {
        hierarchy.addNode(location.value, location.label);
        names.set(location.label, location.value);
    }

    for (const childLocation of location.children) {
        importLocation(childLocation, hierarchy, names);
        hierarchy.addEdge(location.value, childLocation.value);
    }
}

class LocationHierarchy {
    constructor() {
        /**
         * A directed graph that represents the location hierarchy.
         * Each node is a location (identified by location.id and having location.name as label).
         * Each edge (l1, l2) represents that l1 includes l2.
         */
        this._hierarchy = null;

        /**
         * A Map that maps location names to location ids
         */
        this._names = null;
    }

    _getHierarchy() {
        if (this._hierarchy === null) {
            throw new Error('Location hierarchy not initialized');
        }

        return this._hierarchy;
    }

    _getNames() {
        if (this._names === null) {
            throw new Error('Location hierarchy not initialized');
        }

        return this._names;
    }

    load(exported) {
        const imported = importHierarchy(exported);
        this._hierarchy = imported.hierarchy;
        this._names = imported.names;

        return this;
    }

    reset() {
        this._hierarchy = null;
        this._names = null;
    }

    descendantsOf(id) {
        return this._buildExport(this._getHierarchy(), [id]);
    }

    allDescendantIds(id) {
        return this._getHierarchy().reachableFrom(id);
    }

    allAncestorIds(id) {
        return this._getHierarchy()
            .invert()
            .reachableFrom(id);
    }

    exportHierarchy(disabled_ids) {
        const hierarchy = this._getHierarchy();
        return this._buildExport(hierarchy, hierarchy.roots(), disabled_ids);
    }

    getNodeIdExcept(except = []) {
        const hierarchy = this._getHierarchy();
        return this._getID(hierarchy, hierarchy.roots(), except);
    }

    _getID(hierarchy, level_nodes, except = []) {
        let result = [];
        for (const node of level_nodes) {
            let children = this._getID(
                hierarchy,
                hierarchy.children(node),
                except
            );

            result = except.includes(node.toString())
                ? [...children, ...result]
                : [node.toString(), ...children, ...result];
        }

        return result;
    }

    _buildExport(hierarchy, level_nodes, disabled_ids = []) {
        const result = [];
        for (const node of level_nodes) {
            result.push({
                value: node.toString(),
                label: hierarchy.label(node),
                children: this._buildExport(
                    hierarchy,
                    hierarchy.children(node),
                    disabled_ids
                ),
                disabled: disabled_ids.includes(node.toString()) ? true : false
            });
        }
        return result;
    }

    findByName(name) {
        return this._getNames().get(name) || null;
    }
}

export default new LocationHierarchy();
