import React from 'react';
import { NavLink } from 'react-router-dom';

import Header from '../../../../common/header/Header';

import customers from '../../../../assets/svgs/yachts.svg';

const OffersMenu = props => {
    let menu = {
        parent: customers,
        items: [],
        backLink:
            props.user_role &&
            props.user_role !== 'broker_charter' &&
            props.user_role !== 'broker_sales'
                ? '/clients'
                : props.user_role === 'broker_charter'
                ? '/clients/charters'
                : '/clients/sales'
    };

    const view = props.match.path.includes('create')
        ? 'create'
        : props.match.path.includes('edit')
        ? 'edit'
        : 'view';

    const type = props.match.params.type;

    menu.items = [
        <NavLink exact to={`/clients/view/${props.customer.id}`}>
            {`${props.customer.first_name} ${props.customer.last_name}`}
        </NavLink>,
        <NavLink exact to={`/clients/proposals/${props.customer.id}`}>
            PROPOSAL HISTORY
        </NavLink>
    ];

    if (view === 'create') {
        menu.items = [
            ...menu.items,
            <NavLink
                exact
                to={`/clients/proposals/${props.customer.id}/${type}/create`}
            >
                CREATE
            </NavLink>
        ];
    }

    if (view === 'view' || view === 'edit') {
        const offer_id = props.match.params.offer_id;

        if (view === 'edit') {
            menu.items = [
                ...menu.items,
                <NavLink
                    exact
                    to={`/clients/proposals/${
                        props.customer.id
                    }/${type}/edit/${offer_id}`}
                >
                    EDIT
                </NavLink>
            ];
        } else {
            menu.items = [
                ...menu.items,
                <NavLink
                    exact
                    to={`/clients/proposals/${
                        props.customer.id
                    }/${type}/view/${offer_id}`}
                >
                    VIEW
                </NavLink>
            ];
        }
    }

    return <Header menu={menu} history={props.history} />;
};

export default OffersMenu;
