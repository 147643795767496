import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import IndexYachtBookingsPresenter from './IndexYachtBookingsPresenter';

import { fetchBookings } from '../../bookings/redux/_actions';

class IndexYachtBookingsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            table_headers: [
                {
                    name: 'date_from',
                    label: 'Date From'
                },
                {
                    name: 'date_to',
                    label: 'Date To'
                },
                {
                    name: 'location',
                    label: 'Location From - To'
                },
                {
                    name: 'status',
                    label: 'Status'
                },
                {
                    name: 'actions',
                    label: 'Actions'
                }
            ],
            bookingsQueryDates: {
                date_from: moment().startOf('month').format('YYYY/MM/DD'),
                date_to: moment()
                    .startOf('month')
                    .add(6, 'month')
                    .format('YYYY/MM/DD')
            }
        };
    }

    componentDidMount() {
        this.fetchBookings();
    }

    fetchBookings() {
        this.setState({ loading: true });
        const id = this.props.match.params.id;

        let params = {
            yacht_id: id,
            date_range_from: this.state.bookingsQueryDates.date_from,
            date_range_to: this.state.bookingsQueryDates.date_to,
            page_size: -1
        };

        this.props
            .fetchBookings(params)
            .then((data) => this.setState({ loading: false }));
    }

    onDateChange(action) {
        let date_from = moment(this.state.bookingsQueryDates.date_from);
        let date_to = moment(this.state.bookingsQueryDates.date_to);

        if (action === 'previous') {
            date_from = date_from
                .subtract(6, 'month')
                .startOf('month')
                .format('YYYY/MM/DD');
            date_to = date_to
                .subtract(6, 'month')
                .startOf('month')
                .format('YYYY/MM/DD');
        } else {
            date_from = date_from
                .add(6, 'month')
                .startOf('month')
                .format('YYYY/MM/DD');
            date_to = date_to
                .add(6, 'month')
                .startOf('month')
                .format('YYYY/MM/DD');
        }

        this.setState(
            {
                bookingsQueryDates: {
                    date_from: date_from,
                    date_to: date_to
                }
            },
            this.fetchBookings
        );
    }

    render() {
        return (
            <IndexYachtBookingsPresenter
                loading={this.state.loading}
                user_role={this.props.user_role}
                history={this.props.history}
                match={this.props.match}
                read_only={this.state.is_read_only}
                table_headers={this.state.table_headers}
                bookings={this.props.bookings}
                bookingsQueryDates={this.state.bookingsQueryDates}
                onPreviousDate={() => this.onDateChange('previous')}
                onNextDate={() => this.onDateChange('next')}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user_role: state.auth.user_role,
        bookings: state.bookings.bookings
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBookings: (params) => dispatch(fetchBookings(params))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndexYachtBookingsContainer);
