import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Header from '../common/header/Header';

import { fetchDriveDefaults } from './_actions';

import ReactSVG from 'react-svg';

import boat from '../assets/svgs/boatshow.svg';
import contracts from '../assets/svgs/contracts.svg';
import marketing from '../assets/svgs/marketing.svg';
import reports from '../assets/svgs/reports_01.svg';
import shipyard from '../assets/svgs/shipyard.svg';

class Drive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {}
        };
    }

    componentDidMount() {
        let params = {};

        if (this.props.user_role === 'broker_charter') {
            params.chartering = 1;
        }

        this.props.fetchDriveDefaults(params);
    }

    renderDefaults() {
        return this.props.drive.driveDefaults.map((item, index) => {
            return (
                <div
                    className="col-12 col-md-6 col-lg-4 p-1 p-lg-0 pb-lg-1 pr-xl-3 pb-xl-3 box-tab-outer"
                    key={index}
                >
                    <Link to={'/drive/' + item.id} className="tab-action">
                        <div className="d-flex btn btn-main grey border box-tab w-100">
                            <div className="tab-text">
                                <span className="tab-title">{item.name}</span>
                            </div>

                            <div className="tab-image-wrapper">
                                {this.mapTitleToSVG(item.name)}
                            </div>
                        </div>
                    </Link>
                </div>
            );
        });
    }

    mapTitleToSVG(title) {
        switch (title) {
            case 'Contracts':
                return <ReactSVG path={contracts} style={{ height: '6rem' }} />;

            case 'Shipyard Information':
                return <ReactSVG path={shipyard} style={{ height: '6rem' }} />;

            case 'Marketing Material':
                return <ReactSVG path={marketing} style={{ height: '6rem' }} />;

            case 'Boat Show Information':
                return <ReactSVG path={boat} style={{ height: '6rem' }} />;

            default:
                return <ReactSVG path={reports} style={{ height: '6rem' }} />;
        }
    }

    render() {
        return (
            <div className="content-inner-wrapper">
                <Header menu={this.state.menu} history={this.props.history} />
                <div className="content-inner">
                    <div className="row box-tabs-wrapper">
                        {this.renderDefaults()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispathcToProps = dispatch => {
    return {
        fetchDriveDefaults: e => dispatch(fetchDriveDefaults(e))
    };
};

const mapStateToProps = state => {
    return {
        user_role: state.auth.user_role,
        drive: state.drive,
        driveItems: state.drive.driveDefaults
    };
};

export default connect(
    mapStateToProps,
    mapDispathcToProps
)(Drive);
