import React from 'react';

import { ModalBody, ModalFooter, Button } from 'reactstrap';

export default class DeleteConfirmation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            deleteValue: null
        };
    }

    handleFormSubmit(event) {
        event.preventDefault();
        if (this.state.deleteValue === 'delete') {
            this.props.action();
            this.props.toggle();
        }
    }

    handleInputChange(event) {
        this.setState({ deleteValue: event.target.value });
    }

    render() {
        const { submitting, invalid } = this.props;

        return (
            <form
                className="modal-wrapper"
                onSubmit={this.handleFormSubmit.bind(this)}
            >
                <ModalBody>
                    <div className="modal-form-wrap">
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 mb-2 mt-2 text-center">
                                {this.props.body}

                                <div className="mt-1">
                                    Do you wish to continue?
                                </div>

                                <div className="delete-confirmation mt-2">
                                    <div className="floating-label-wrapper  form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Please type the word delete and press delete to confirm"
                                            name="delete"
                                            onChange={this.handleInputChange.bind(
                                                this
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="submit"
                        color="danger"
                        disabled={invalid || submitting}
                    >
                        Delete
                    </Button>
                    <Button
                        type="button"
                        color="secondary"
                        disabled={submitting}
                        onClick={this.props.toggle}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </form>
        );
    }
}
