import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateBrokerRoute = ({ component, ...rest }) => (
  <Route
    exact
    {...rest}
    render={props =>
      rest.authenticated &&
      rest.user_role &&
      (rest.user_role === 'broker' ||
        (rest.user_role === 'broker_sales' &&
          rest.path !== '/clients/charters' &&
          rest.path !== '/clients') ||
        (rest.user_role === 'broker_charter' &&
          rest.path !== '/clients/sales' &&
          rest.path !== '/clients')) ? (
        React.createElement(component, props)
      ) : !rest.authenticated ? (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      ) : (
        <p>You have no access to view this page. </p>
      )
    }
  />
);

export default PrivateBrokerRoute;
