import React from 'react';
import { connect } from 'react-redux';

import IndexDestinationsPresenter from './IndexDestinationsPresenter';

import DeleteConfirmation from '../../common/components/DeleteConfirmation';

import {
    fetchDestinations,
    fetchDeleteDestination,
    fetchUpdateDestination
} from './redux/_actions';

class IndexDestinationsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            table_headers: [
                {
                    name: 'favorite',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'title_internal',
                    label: 'Itinerary name iyc',
                    sort: true,
                    defaultSort: 'ASC'
                },
                {
                    name: 'title',
                    label: 'Public title',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'days',
                    label: 'Destinations',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'action',
                    label: 'Action',
                    sort: false,
                    defaultSort: ''
                }
            ],
            default_modal: (
                <DeleteConfirmation
                    toggle={this.onModalToggle.bind(this)}
                    action={this.deleteDestination.bind(this)}
                    body=""
                />
            ),
            modal_header: 'Delete itinerary',
            is_modal_open: false,
            destination_to_delete: null
        };
    }

    onModalToggle() {
        this.setState({
            is_modal_open: !this.state.is_modal_open
        });
    }

    refetchDestinations(page) {
        this.props.fetchDestinations({
            page: page ? page : this.props.destinations.pagination.page,
            order_field: this.props.destinations.sorting.order_field,
            order_dir: this.props.destinations.sorting.order_dir,
            page_size: this.props.destinations.pagination.page_size,
            search: this.props.destinations.sorting.search
        });
    }

    componentDidMount() {
        this.props.fetchDestinations({
            page: 1,
            page_size: 25,
            order_field: 'title_internal',
            order_dir: 'ASC'
        });
    }

    onTableChange(key, newValue) {
        let params = {
            page: key !== 'page' ? 1 : newValue,
            order_dir:
                key !== 'page' &&
                key !== 'page_size' &&
                key !== 'search' &&
                key !== 'favourites'
                    ? newValue
                    : this.props.destinations.sorting.order_dir,
            order_field:
                key !== 'page' &&
                key !== 'page_size' &&
                key !== 'search' &&
                key !== 'favourites'
                    ? key
                    : this.props.destinations.sorting.order_field,
            page_size:
                key === 'page_size'
                    ? newValue
                    : this.props.destinations.pagination.page_size,
            search:
                key === 'search'
                    ? newValue
                    : this.props.destinations.sorting.search,
            favourites:
                key === 'favourites'
                    ? newValue
                    : this.props.destinations.sorting.favourites
        };

        this.props.fetchDestinations(params);
    }

    onDeleteModalToggle(id) {
        this.setState({
            destination_to_delete: id
        });
        this.onModalToggle();
    }

    deleteDestination() {
        this.props
            .fetchDeleteDestination({ id: this.state.destination_to_delete })
            .then(data => {
                if (data) {
                    this.refetchDestinations();
                }
            });
    }

    onManageFavourite(destination) {
        let params = {
            id: destination.id,
            favourite: destination.favourite === 1 ? 0 : 1
        };
        this.props.fetchUpdateDestination(params).then(data => {
            this.onTableChange();
        });
    }

    render() {
        return (
            <IndexDestinationsPresenter
                match={this.props.match}
                history={this.props.history}
                destinations={this.props.destinations}
                table_headers={this.state.table_headers}
                is_modal_open={this.state.is_modal_open}
                modal_header={this.state.modal_header}
                default_modal={this.state.default_modal}
                onTableChange={this.onTableChange.bind(this)}
                onModalToggle={this.onModalToggle.bind(this)}
                onDeleteModalToggle={this.onDeleteModalToggle.bind(this)}
                onManageFavourite={this.onManageFavourite.bind(this)}
            />
        );
    }
}

const mapDispatchToProps = (dispatch, own_props) => {
    return {
        fetchDestinations: params => dispatch(fetchDestinations(params)),
        fetchDeleteDestination: params =>
            dispatch(fetchDeleteDestination(params)),
        fetchUpdateDestination: params =>
            dispatch(fetchUpdateDestination(params))
    };
};

const mapStateToProps = state => {
    return {
        user: state.user,
        destinations: state.destinations.destinations,
        initDest: state.destinations.destinations.initDest
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndexDestinationsContainer);
