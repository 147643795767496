import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import NewsletterCustomersReportPresenter from './NewsletterCustomersReportPresenter';

// Redux
import { fetchNewsletter } from '../../brokers/newsletters/actions';
import { fetchNewsletterReportByTag } from './redux/_actions';

// SVG
import mail from '../../assets/svgs/mail.svg';

class NewsletterCustomersReportContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            menu: {
                parent: mail,
                items: []
            },
            table_headers: [
                {
                    name: 'customer',
                    label: 'CUSTOMER EMAIL',
                    sort: false
                },
                {
                    name: 'opens',
                    label: 'OPENS',
                    sort: false
                },
                {
                    name: 'clicks',
                    label: 'CLICKS',
                    sort: false
                }
            ]
        };
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        let menu = [
            <NavLink exact to={`/newsletters/${id}/view`}>
                View
            </NavLink>,
            <NavLink
                exact
                to={`/newsletters/${id}/report`}
                isActive={() => true}
            >
                Report
            </NavLink>
        ];

        this.setState({
            newsletter_id: id,
            menu: {
                ...this.state.menu,
                items: menu
            }
        });

        this.props.fetchNewsletter({ id }).then(data => {
            if (data) {
                const params = {
                    date_from: moment(data.sent_at).format('MM/DD/YYYY'),
                    date_to: moment().format('MM/DD/YYYY'),
                    tags: [data.tag]
                };
                console.log(params);
                this.props
                    .fetchNewsletterReportByTag(params)
                    .then(data => this.setState({ loading: false }));
            }
        });
    }

    render() {
        return (
            <NewsletterCustomersReportPresenter
                history={this.props.history}
                loading={this.state.loading}
                menu={this.state.menu}
                table_headers={this.state.table_headers}
                customers_report={this.props.customers_report}
                newsletter_id={this.state.newsletter_id}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        customers_report: state.newsletter_report.customers_report
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchNewsletter: id => dispatch(fetchNewsletter(id)),
        fetchNewsletterReportByTag: params =>
            dispatch(fetchNewsletterReportByTag(params))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsletterCustomersReportContainer);
