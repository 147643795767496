import React from 'react';
import { NavLink } from 'react-router-dom';

import Header from '../../../common/header/Header';
import can from '../../../infrastructure/helpers/can';

import yachts from '../../../assets/svgs/yachts.svg';

const YachtListMenu = props => {
    let menu = {
        parent: yachts,
        items: []
    };
    
    if (can('viewSales', props.user_role)) {
        menu.items.push(
            <NavLink
                isActive={() => props.view_type === 'sales'}
                to="/yachts/view/sales"
            >
                Sales
            </NavLink>
        );
    }

    if (can('viewCharter', props.user_role)) {
        menu.items.push(
            <NavLink
                isActive={() => props.view_type === 'charter'}
                to="/yachts/view/charter"
            >
                Charter
            </NavLink>
        )
    }

    return <Header menu={menu} history={props.history} />;
};

export default YachtListMenu;
