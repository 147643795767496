import React from 'react';
import Header from '../../common/header/Header';
import { Row, Col } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import moment from 'moment';

import SimpleTable from '../../common/components/SimpleTable';

const UpdatedYachtsTable = (props) => {
    const getTableData = () => {
        return props.data.map((item) => {
            let data = [
                <div>
                    <img
                        className={'rounded-circle object-fit-cover'}
                        width="38"
                        height="38"
                        src={item.photo}
                        alt=""
                    />
                </div>,
                <div>
                    <NavLink
                        to={
                            `/yachts/view` +
                            (item.chartering === 1 ? '/charter/' : '/sales/') +
                            `${item.id}`
                        }
                        className="btn-td-action"
                    >
                        {item.name}
                    </NavLink>
                </div>,
                <div>
                    {item.chartering === 0 ? (
                        <div className="customer-cat customer-sales mr-1">
                            S
                        </div>
                    ) : (
                        <div className="customer-cat customer-chartering mr-1">
                            C
                        </div>
                    )}
                </div>,
                <div>
                    {moment(item.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                </div>
            ];

            return data;
        });
    };

    return (
        <div className="content-inner-wrapper">
            <Header menu={props.menu} history={props.history} />
            <div className="content-inner">
                <Row>
                    <Col
                        xs="12"
                        xl="8"
                        className="d-flex align-items-center justify-content-between mt-1 mb-2"
                    >
                        <h4 className="m-0">Yacht Updates</h4>
                    </Col>
                    <Col
                        xs="12"
                        xl="8"
                        className="d-flex align-items-center justify-content-between mt-1 mb-2"
                    >
                        <h4 className="m-0">
                            Last Clear Chache was:{' '}
                            {props.lastClearCache ? props.lastClearCache : '--'}
                        </h4>
                    </Col>
                    <Col xs="12">
                        <SimpleTable
                            search={true}
                            tableChange={props.onTableChange}
                            paging={
                                props.hasPagination
                                    ? props.bookings.pagination
                                    : undefined
                            }
                            colWidths={[1, 8, 1, 2]}
                            headers={props.table_headers}
                            rowData={getTableData()}
                        />
                        {props.data.length <= 0 && (
                            <div className="text-center py-sm-4">
                                There haven't been any new yacht updates.
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default UpdatedYachtsTable;
