import React from 'react';
import { FormGroup, Label } from 'reactstrap';

export default function InputField(props) {
    return (
        <FormGroup
            className={
                'floating-label-wrapper ' +
                (props.className !== undefined ? props.className : '')
            }
        >
            <Label
                className={
                    (props.meta !== undefined &&
                        props.input.value !== '' &&
                        !props.meta.error) ||
                    (props.disabled !== undefined && props.disabled)
                        ? 'float top'
                        : 'float'
                }
            >
                {props.placeholder}
            </Label>

            <input
                className={
                    props.meta !== undefined &&
                    props.meta.touched &&
                    props.meta.error
                        ? 'form-control error'
                        : 'form-control'
                }
                name={props.name}
                type={props.type}
                disabled={''}
                placeholder={
                    props.disabled !== undefined &&
                    props.disabled &&
                    !props.input.value
                        ? '-'
                        : props.placeholder
                }
                onChange={props.onChange}
            />
        </FormGroup>
    );
}
