import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import ManageLocationDecorator from './ManageLocationDecorator';
import ManageLocationPresenter from './ManageLocationPresenter';

import customers from '../../assets/svgs/customers.svg';

import {
    fetchLocation,
    fetchUpdateLocation,
    fetchLocationTree
} from './redux/_actions';

class EditLocationContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                parent: customers,
                items: [
                    <NavLink exact to={'/locations'}>
                        All
                    </NavLink>,
                    <NavLink exact to={`/locations/${props.match.params.id}/edit`}>
                        Edit
                    </NavLink>
                ]
            },
            loading: false
        };
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        this.setState({ id: id, loading: true });

        this.props
            .fetchLocation(id)
            .then(res => this.setState({ loading: false }));
    }

    render() {
        return (
            <ManageLocationPresenter
                menu={this.state.menu}
                loading={this.state.loading}
                onSubmit={this.onSubmit.bind(this)}
                onAddNewPhoto={this.props.onAddNewPhoto}
            />
        );
    }

    onSubmit(form_props) {
        const params = this.props.validateFormProps(form_props);

        this.props.fetchUpdateLocation(this.state.id, params).then(response => {
            if (response) {
                this.props.history.push('/locations');
            }
        });
    }
}

const mapStateToProps = state => {
    return {
        location: state.locations.location
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchLocation: params => dispatch(fetchLocation(params)),
        fetchUpdateLocation: (id, params) =>
            dispatch(fetchUpdateLocation(id, params)),
        fetchLocationTree: params => dispatch(fetchLocationTree(params))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageLocationDecorator()(EditLocationContainer));
