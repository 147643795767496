import React, { Component } from 'react';

class Widget extends Component {
    render() {
        return (
            <div className="table-wrapper widget">
                <div className="table-header px-1">
                    <span className="table-title">
                        {this.props.title.toUpperCase()}
                    </span>
                </div>
                {this.props.children}
            </div>
        );
    }
}

export default Widget;
