import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { Row, Col } from 'reactstrap';

import Header from '../../common/header/Header';
import Loader from '../../common/components/Loader';
import YachtsSelection from '../yachts/components/YachtsSelection';
import Instructions from '../../common/components/Instructions';
import RenderReactSelect from '../../common/components/RenderReactSelect';

// Fields
import RenderField from '../../common/components/RenderField';
import RenderRedactor from '../../common/components/RenderRedactor';

// Helpers
import { required } from '../../helpers/validate';

let EditYachtListPresenter = props => {
    return (
        <div className="content-inner-wrapper">
            <Header menu={props.menu} history={props.history} />

            <div className="content-inner">
                {props.loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <form
                        onSubmit={props.handleSubmit(props.onSubmit)}
                        className="list-form"
                    >
                        <Row>
                            <Col xs="12" lg="9">
                                <Row>
                                    <Col xs="12">
                                        <Field
                                            name="internal_title"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Internal Title"
                                            validate={[required]}
                                            className="mb-2"
                                            onChange={value => {
                                                props.changeFieldValue('internal_title', value);
                                                props.autoSubmitForm();
                                            }}
                                        />
                                    </Col>

                                    <Col xs="12" className="mb-2">
                                        <label className="upload-label text-uppercase mt-2">
                                            Notes
                                        </label>

                                        <RenderRedactor
                                            hiddenTitle={true}
                                            name="notes"
                                            readOnly={props.read_only}
                                            disabled={props.read_only}
                                            id="notes"
                                            onChange={text => {
                                                props.changeFieldValue(
                                                    'notes',
                                                    text
                                                );
                                                props.autoSubmitForm();
                                            }}
                                        />
                                    </Col>

                                    <Col xs="12" md="6" xl="4">
                                        <RenderReactSelect
                                            name="ordering"
                                            meta={{}}
                                            input={{
                                                value: props.orderingValue,
                                                onChange: props.onOrderChange
                                            }}
                                            label="SORT BY"
                                            clearable={false}
                                            options={props.orderingOptions}
                                            className="mb-0"
                                            showLabel={props.orderingValue !== null}
                                        />
                                    </Col>

                                    <Col xs="12" xl="12" className="my-1">
                                        <Instructions 
                                            mode="info"
                                            title={`You can use the 'Sort by' dropdown to sort yachts. 
                                                You can also drag and drop to manually sort yachts. 
                                                <br /> Either way, when you click save, the current order of yachts will be saved.`
                                            }
                                        />
                                    </Col>

                                    <Col xs="12">
                                        <YachtsSelection
                                            mode="edit"
                                            read_only={props.read_only}
                                            user_role="admin"
                                            showSearchOptions={false}
                                            sortable={true}
                                            type={
                                                props.initialValues
                                                    .chartering === 1
                                                    ? 'charter'
                                                    : 'sales'
                                            }
                                            search_type={
                                                props.initialValues
                                                    .chartering === 1
                                                    ? 'current_charter'
                                                    : 'current_sales'
                                            }
                                            should_show_saved_searches={false}
                                            initial_yachts={
                                                props.yachts
                                            }
                                            onChange={value => {
                                                props.onSetSelectedYacht(value)
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs="12" lg="3" className="page__actionsbar">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Save
                                </button>

                                <Instructions 
                                    mode="info"
                                    title="If you wish to <strong>add</strong> yachts to this list, you can just: "
                                    messages={[
                                        "Go to the <strong>Yachts</strong> page.</li><li>Add the yachts in question to your <strong>Current Selection</strong>.",
                                        "Return to this page.",
                                        "You'll find the yachts from your Current Selection in the list. Check the ones you want and click <strong>Save</strong>."
                                    ]}
                                    footer={`If you wish to <strong>remove</strong> yachts, you can just "uncheck" them and click <strong>Save</strong>`}
                                />
                            </Col>
                        </Row>
                    </form>
                )}
            </div>
        </div>
    );
};

EditYachtListPresenter = reduxForm({
    form: 'update_yacht_list_form',
    enableReinitialize: true
})(EditYachtListPresenter);

const  selector = formValueSelector('update_yacht_list_form');

const mapStateToProps = state => {
    const yachts = state.yacht_lists.yacht_list.yachts
        ? state.yacht_lists.yacht_list.yachts.map(yacht => {
            let new_yacht = Object.assign({}, yacht);
            if (yacht.selected === undefined) {
                new_yacht.selected = 1;
            }
            return new_yacht;
        })
        : [];
    
    return {
        yachts: selector(state, 'yachts'),
        initialValues: {
            ...state.yacht_lists.yacht_list,
            yachts: yachts,
            search_type:
                state.yacht_lists.yacht_list.chartering === 1
                    ? 'current_charter'
                    : 'current_sales'
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeFieldValue: (field, value) => dispatch(change('update_yacht_list_form', field, value))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditYachtListPresenter);
