import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';

import DestinationsMenu from './components/DestinationsMenu';
import SimpleTableActions from '../../common/components/SimpleTableActions';
import SimpleTable from '../../common/components/SimpleTable';
import SimpleModal from '../../common/components/SimpleModal';

// SVG
import add from '../../assets/svgs/add.svg';
import edit from '../../assets/svgs/edit.svg';
import deleteSVG from '../../assets/svgs/delete.svg';
import favorite from '../../assets/svgs/favorite.svg';
import favoriteEmpty from '../../assets/svgs/favorite-empty.svg';

const IndexDestinationsPresenter = props => {
    return (
        <div className="content-inner-wrapper">
            <DestinationsMenu match={props.match} history={props.history} />

            <div className="content-inner">
                <div className="row">
                    <div className="col-xl-9 col-12">
                        <SimpleTableActions
                            label="Create new itinerary template"
                            icon={add}
                            onClick={() =>
                                props.history.push('/itineraries/create')
                            }
                        />

                        <SimpleTable
                            tableChange={props.onTableChange}
                            paging={props.destinations.pagination}
                            colWidths={[
                                '1 text-center flex-05',
                                3,
                                4,
                                '2 text-center',
                                '2 text-center'
                            ]}
                            headers={props.table_headers}
                            hideExtraFilter={true}
                            rowData={
                                props.destinations.data !== undefined &&
                                props.destinations.data.map(value => {
                                    return [
                                        <div>
                                            <div className="table-favorites">
                                                <button
                                                    className="btn btn-td-action btn-empty btn-favorite d-flex align-items-center justify-content-center p-0"
                                                    onClick={() =>
                                                        props.onManageFavourite(
                                                            value
                                                        )
                                                    }
                                                >
                                                    <ReactSVG
                                                        path={
                                                            value.favourite ===
                                                            1
                                                                ? favorite
                                                                : favoriteEmpty
                                                        }
                                                        style={{
                                                            height: 16
                                                        }}
                                                    />
                                                </button>
                                            </div>
                                        </div>,
                                        <div>{value.title_internal}</div>,
                                        <div>{value.title_public}</div>,
                                        <div>{value.locations}</div>,
                                        <ul className="list-unstyled table-td-actions">
                                            <li className="text-fill-hover">
                                                <NavLink
                                                    to={`/itineraries/${
                                                        value.id
                                                    }/edit`}
                                                >
                                                    <ReactSVG
                                                        path={edit}
                                                        style={{
                                                            height: 24
                                                        }}
                                                    />
                                                </NavLink>
                                            </li>
                                            <li>
                                                <button
                                                    className="btn btn-td-action"
                                                    onClick={() =>
                                                        props.onDeleteModalToggle(
                                                            value.id
                                                        )
                                                    }
                                                >
                                                    <ReactSVG
                                                        path={deleteSVG}
                                                        style={{
                                                            height: 24
                                                        }}
                                                    />
                                                </button>
                                            </li>
                                        </ul>
                                    ];
                                })
                            }
                            actions={
                                <div className="table-favorites">
                                    <button
                                        className="btn btn-empty btn-td-action btn-favorite-action d-flex align-items-center"
                                        onClick={e =>
                                            props.onTableChange(
                                                'favourites',
                                                props.destinations.sorting
                                                    .favourites === 1
                                                    ? 0
                                                    : 1
                                            )
                                        }
                                    >
                                        <ReactSVG
                                            path={
                                                props.destinations.sorting
                                                    .favourites === 1
                                                    ? favorite
                                                    : favoriteEmpty
                                            }
                                            style={{ height: 16 }}
                                        />
                                        <span>See favorites</span>
                                    </button>
                                </div>
                            }
                        />

                        <SimpleModal
                            header={props.modal_header}
                            body={props.default_modal}
                            modalOpen={props.is_modal_open}
                            toggle={props.onModalToggle}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndexDestinationsPresenter;
