import React from 'react';
import { connect } from 'react-redux';
import CheckboxTree from 'react-checkbox-tree';

import {
    fetchLocationTree,
    resetDropdownLocations
} from '../../../pages/locations/redux/_actions';

// this function removes all children that are just an empty array.
// Having an empty array there cause the  react-checkbox-tree to malfunction
function clearLocationTreeData(data) {
    let newDataArr = data.map((i) => {
        if (i.children && i.children.length === 0) {
            delete i.children;
        } else {
            return {
                ...i,
                children: clearLocationTreeData(i.children)
            };
        }

        return { ...i };
    });
    return newDataArr;
}

/**
 *  This component is used to render chartering locations tree view,
 *  is connected to redux store.
 *  Modes of the component are: free ( all nodes are clickable and when the user clicks on one node the parents are selected as well)
 *  single ( Only one location can be checked )
 *
 *  @const  props.label string The label of the field
 *  @const  props.mode string Indicates how the tree should behave
 *  @const  props.only_for_yachts boolean If location tree values should be only those that can have yachts
 */
class LocationTree extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hierarchy: {},
            initial_locations: [],
            locations_tree: [],
            is_locations_tree_loaded: false,
            checked: [],
            expanded: [],
            filter_text: ''
        };
    }

    componentDidMount() {
        let params = {};
        if (this.props.only_for_yachts) params.only_for_yachts = true;
        this.props.fetchLocationTree(params).then((response) => {
            const hierarchy = clearLocationTreeData(response.exportHierarchy());
            this.setState({
                hierarchy: response,
                initial_locations: hierarchy,
                locations_tree: hierarchy,
                is_locations_tree_loaded: true
            });

            if (this.props.mode === 'free' && this.props.input.value) {
                const value = this.props.input.value.map((v) => v.toString());

                if (value) {
                    this.setState({
                        checked: value
                    });
                }
            }

            if (this.props.mode === 'single' && this.props.input.value) {
                const parent_id = this.props.input.value;

                if (parent_id) {
                    this.props.input.onChange(parent_id.toString());

                    this.setState({
                        checked: [parent_id.toString()]
                    });

                    let others = response.getNodeIdExcept([
                        parent_id.toString()
                    ]);
                    this.fetchLocationTreeByHierarchy(others);
                }
            }
            this.props.resetDropdownLocations(false);
        });
    }

    UNSAFE_componentWillReceiveProps(next_props) {
        if (next_props.input.value !== this.props.input.value) {
            this.onCheck(next_props.input.value);
        }
        if (
            next_props.should_reset === true &&
            next_props.should_reset !== this.props.should_reset
        ) {
            this.reset();
        }
    }

    fetchLocationTreeByHierarchy(disabled_ids = []) {
        if (Object.keys(this.state.hierarchy).length === 0) return;
        const hierarchy = clearLocationTreeData(
            this.state.hierarchy.exportHierarchy(disabled_ids)
        );
        this.setState({
            initial_locations: hierarchy,
            locations_tree: hierarchy
        });
    }

    onCheck(checked) {
        if (checked.length === 0) {
            this.setState({ checked: [] });

            this.props.input.onChange(this.props.mode === 'single' ? '' : []);
            this.fetchLocationTreeByHierarchy();
            return;
        }

        if (this.props.mode === 'single') {
            if (checked.length > 1) {
                return;
            } else {
                let current = checked[0]; // Current clicked location id

                this.props.input.onChange(current.toString());

                this.setState({
                    checked: [current.toString()]
                });

                let others = this.state.hierarchy.getNodeIdExcept([
                    current.toString()
                ]);
                this.fetchLocationTreeByHierarchy(others);
                return;
            }
        }

        this.props.input.onChange(checked);
        this.setState({ checked: checked });
    }

    reset() {
        this.setState({ checked: [] });
        this.props.input.onChange();
        this.props.resetDropdownLocations(false);
    }

    onFilterChange(e) {
        this.setState({ filter_text: e.target.value }, this.filterTree);
    }

    filterTree() {
        if (!this.state.filter_text) {
            this.setState({ locations_tree: this.state.initial_locations });

            return;
        }

        this.setState({
            locations_tree: this.state.initial_locations.reduce(
                this.filterNodes.bind(this),
                []
            )
        });
    }

    filterNodes(filtered, node) {
        const { filter_text } = this.state;
        const children = (node.children || []).reduce(
            this.filterNodes.bind(this),
            []
        );

        if (
            // Node's label matches the search string
            node.label
                .toLocaleLowerCase()
                .indexOf(filter_text.toLocaleLowerCase()) > -1 ||
            // Or a children has a matching node
            children.length
        ) {
            filtered.push({ ...node, children });
        }

        return filtered;
    }

    render() {
        return (
            <div>
                <div className="form-group">
                    {this.props.meta !== undefined &&
                        this.props.meta.touched &&
                        this.props.meta.error && (
                            <div
                                style={{
                                    color: '#ee394e',
                                    marginBottom: '7px'
                                }}
                            >
                                {this.props.meta.error}
                            </div>
                        )}
                    <label className="checkbox-tree-label">
                        {this.props.label
                            ? this.props.label
                            : 'Choose Locations'}
                    </label>

                    <input
                        className="form-control"
                        placeholder="Search..."
                        type="text"
                        value={this.state.filter_text}
                        onChange={this.onFilterChange.bind(this)}
                    />

                    {this.state.locations_tree &&
                        this.state.is_locations_tree_loaded && (
                            <div className="py-1">
                                <CheckboxTree
                                    disabled={this.props.disabled || false}
                                    showNodeIcon={false}
                                    //noCascade={false}
                                    noCascade={!this.props.should_cascade}
                                    expandOnClick={true}
                                    nodes={this.state.locations_tree}
                                    name={
                                        this.props.input.name
                                            ? this.props.input.name
                                            : null
                                    }
                                    nameAsArray={
                                        this.props.mode === 'single'
                                            ? false
                                            : true
                                    }
                                    checked={this.state.checked}
                                    expanded={this.state.expanded}
                                    onCheck={(checked, targetNode) =>
                                        this.onCheck(checked, targetNode)
                                    }
                                    onExpand={(expanded) =>
                                        this.setState({ expanded })
                                    }
                                />
                            </div>
                        )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        should_reset: state.locations.should_dropdown_locations_reset,
        locations_tree: state.locations.locationsTree
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLocationTree: (params) => dispatch(fetchLocationTree(params)),
        resetDropdownLocations: (should_reset) =>
            dispatch(resetDropdownLocations(should_reset))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationTree);
