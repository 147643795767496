import axios from 'axios';
import { API_URL } from '../../../helpers/variables';
import { errorHandler, successHandler } from '../../../helpers/alerts';
import { authConfig } from '../../../helpers/authConfig';

import {
    INIT_BOOKING,
    FETCH_BOOKINGS,
    FETCH_BOOKINGS_SUCCESS,
    FETCH_BOOKINGS_ERROR,
    FETCH_CREATE_BOOKING,
    FETCH_CREATE_BOOKING_SUCCESS,
    FETCH_CREATE_BOOKING_ERROR,
    FETCH_BOOKING,
    FETCH_BOOKING_SUCCESS,
    FETCH_BOOKING_ERROR,
    FETCH_UPDATE_BOOKING,
    FETCH_UPDATE_BOOKING_SUCCESS,
    FETCH_UPDATE_BOOKING_ERROR,
    DELETE_BOOKING,
    DELETE_BOOKING_SUCCESS,
    DELETE_BOOKING_ERROR,
    FETCH_BOOKINGS_LOCATIONS,
    FETCH_BOOKINGS_LOCATIONS_SUCCESS,
    FETCH_BOOKINGS_LOCATIONS_ERROR
} from './_types';

const initBooking = () => {
    return dispatch => dispatch({ type: INIT_BOOKING });
}

const fetchBookings = params => {
    return dispatch => {
        dispatch({ type: FETCH_BOOKINGS });

        const options = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(`${API_URL}/bookings`, options)
            .then(response => {
                dispatch({
                    type: FETCH_BOOKINGS_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_BOOKINGS_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchCreateBooking = params => {
    return dispatch => {
        dispatch({ type: FETCH_CREATE_BOOKING });

        const options = {
            headers: authConfig()
        };

        return axios
            .post(`${API_URL}/bookings`, params, options)
            .then(response => {
                dispatch({
                    type: FETCH_CREATE_BOOKING_SUCCESS,
                    payload: response.data
                });
                successHandler(dispatch, 'manage_booking');

                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_CREATE_BOOKING_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchBooking = (id, params) => {
    return dispatch => {
        dispatch({ type: FETCH_BOOKING });

        const config = { headers: authConfig() };
        return axios
            .get(`${API_URL}/bookings/${id}`, config)
            .then(response => {
                dispatch({
                    type: FETCH_BOOKING_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch(error => {
                errorHandler(dispatch, error.response, FETCH_BOOKING_ERROR);
            });
    };
};

const fetchUpdateBooking = (id, params) => {
    return dispatch => {
        dispatch({ type: FETCH_UPDATE_BOOKING });

        const options = {
            headers: authConfig()
        };

        return axios
            .put(`${API_URL}/bookings/${id}`, params, options)
            .then(response => {
                dispatch({
                    type: FETCH_UPDATE_BOOKING_SUCCESS,
                    payload: response.data
                });
                successHandler(dispatch, 'manage_booking');

                return response.data;
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: FETCH_UPDATE_BOOKING_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchDeleteBooking = id => {
    return dispatch => {
        dispatch({ type: DELETE_BOOKING });

        const options = {
            headers: authConfig(),
            params: {
                detach_yachts: true
            }
        };

        return axios
            .delete(API_URL + '/bookings/' + id, options)
            .then(response => {
                dispatch({
                    type: DELETE_BOOKING_SUCCESS,
                    payload: response.data
                });
                successHandler(dispatch, 'bookingDelete');

                return response.data;
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: DELETE_BOOKING_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchBookingsLocations = search_query => {
    return dispatch => {
        dispatch({ type: FETCH_BOOKINGS_LOCATIONS });

        const options = {
            headers: authConfig()
        };

        return axios
            .get(`${API_URL}/bookings/locations/${search_query}`, options)
            .then(response => {
                dispatch({
                    type: FETCH_BOOKINGS_LOCATIONS_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_BOOKINGS_LOCATIONS_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
}

export {
    initBooking,
    fetchBookings, 
    fetchCreateBooking, 
    fetchBooking, 
    fetchUpdateBooking,
    fetchDeleteBooking,
    fetchBookingsLocations,
};
