import React, { Component, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Label } from 'reactstrap';

import RenderImage from './RenderImage';
import RenderField from './RenderField';

class PhotoGalleryWithLabels extends Component {
    onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) this.props.reorder(oldIndex, newIndex);
    };

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        this.props.reorder(result.source.index, result.destination.index);
    }

    renderDraggable(value, index, provided) {
        return (
            <li className="list-inline-item" ref={provided.innerRef}>
                <RenderImage
                    name={this.props.name}
                    remove={() => this.props.remove(value, index)}
                    closeButton={
                        this.props.closeButton !== undefined
                            ? this.props.closeButton
                            : true
                    }
                    value={value}
                    index={index}
                    dragProps={provided.dragHandleProps}
                    dragStyle={provided.draggableStyle}
                    show_alt_text_input={this.props.show_alt_text_input}
                    locale={this.props.locale}
                    read_only={this.props.read_only}
                    onAltTextChange={this.props.onAltTextChange}
                />

                {provided.placeholder}
            </li>
        );
    }

    render() {
        const {
            galleryPhotos,
            galleryType,
            mediaType,
            edit,
            name
        } = this.props;

        const SortableItem = SortableElement(
            ({ value, indexSort, loading }) => {
                const [label, setLabel] = useState(value.label);

                return (
                    <div
                        className={`list-inline-item gallery-list-item ${
                            loading ? 'disabled' : ''
                        }`}
                    >
                        <RenderImage
                            remove={() => this.props.remove(value, indexSort)}
                            closeButton={
                                this.props.closeButton !== undefined
                                    ? this.props.closeButton
                                    : true
                            }
                            value={value}
                            index={indexSort}
                            imageClassName={
                                this.props.isImageGallery
                                    ? 'gallery-image-box-with-selection'
                                    : 'gallery-image-box'
                            }
                        />

                        {edit ? (
                            <RenderField
                                name={'label'}
                                input={{
                                    placeholder: 'Add a label',
                                    value: label ? label : '',
                                    onBlur: (ev) => {
                                        this.props.updateLabel(
                                            ev.target.value,
                                            indexSort,
                                            mediaType,
                                            name
                                        );
                                    },
                                    onChange: (ev) => {
                                        setLabel(ev.target.value);
                                    }
                                }}
                            />
                        ) : (
                            <div className={'gallery-label'}>{label}</div>
                        )}
                    </div>
                );
            }
        );

        const SortableList = SortableContainer(({ photos }) => {
            return (
                <div
                    className={
                        'mb-0 d-flex ' + (this.props.edit ? 'editable' : '')
                    }
                >
                    {photos.map((value, index) => (
                        <SortableItem
                            key={`item-${index}`}
                            index={index}
                            value={value}
                            indexSort={index}
                            disabled={!this.props.edit}
                            loading={this.props.loading}
                        />
                    ))}
                </div>
            );
        });

        return (
            <div className="gallery-photos">
                {galleryPhotos &&
                    (galleryType && galleryType === 'grid' ? (
                        <div className="gallery-photos-prewrap">
                            <Label className="gallery-label text-uppercase">
                                {this.props.label}
                            </Label>{' '}
                            {this.props.optionalLabel !== undefined ? (
                                <span className="optional">
                                    {this.props.optionalLabel}
                                </span>
                            ) : (
                                ''
                            )}
                            <div className="gallery-wrap ml-1">
                                <div className="gallery-list">
                                    <SortableList
                                        photos={this.props.galleryPhotos}
                                        onSortEnd={this.onSortEnd}
                                        axis={'xy'}
                                        lockToContainerEdges={true}
                                        useDragHandle={true}
                                        shouldCancelStart={() => {
                                            return this.props.loading;
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="gallery-photos-prewrap">
                            <Label className="gallery-label text-uppercase">
                                {this.props.label}
                            </Label>{' '}
                            {this.props.optionalLabel !== undefined ? (
                                <span className="optional">
                                    {this.props.optionalLabel}
                                </span>
                            ) : (
                                ''
                            )}
                            <div className="gallery-wrap ml-1">
                                <div className="gallery-list">
                                    <DragDropContext
                                        onDragEnd={this.onDragEnd.bind(this)}
                                    >
                                        <Droppable
                                            droppableId="droppable"
                                            direction="horizontal"
                                        >
                                            {(provided) => (
                                                <ul
                                                    className={
                                                        'list-inline mb-0 d-flex ' +
                                                        (this.props.edit
                                                            ? 'editable'
                                                            : '')
                                                    }
                                                    ref={provided.innerRef}
                                                >
                                                    {galleryPhotos.map(
                                                        (value, index) => (
                                                            <Draggable
                                                                key={index}
                                                                draggableId={`${index.id}`}
                                                                index={index}
                                                                isDragDisabled={
                                                                    !this.props
                                                                        .edit
                                                                }
                                                            >
                                                                {(provided) =>
                                                                    this.renderDraggable(
                                                                        value,
                                                                        index,
                                                                        provided
                                                                    )
                                                                }
                                                            </Draggable>
                                                        )
                                                    )}
                                                </ul>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        );
    }
}

export default PhotoGalleryWithLabels;
