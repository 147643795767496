export const FETCH_BROKERS = 'fetch_brokers',
  FETCH_BROKERS_SUCCESS = 'fetch_brokers_success',
  FETCH_BROKERS_SEARCH_SUCCESS = 'fetch_brokers_search_success',
  FETCH_BROKERS_FAIL = 'fetch_brokers_fail',
  INIT_BROKER_MODAL = 'init_broker_modal',
  FETCH_BROKER = 'fetch_broker',
  FETCH_BROKER_SUCCESS = 'fetch_broker_success',
  FETCH_BROKER_FAIL = 'fetch_broker_fail',
  FETCH_MANAGE_BROKER = 'fetch_manage_broker',
  FETCH_MANAGE_BROKER_SUCCESS = 'fetch_manage_broker_success',
  FETCH_MANAGE_BROKER_FAIL = 'fetch_manage_broker_fail',
  FETCH_DELETE_BROKER = 'fetch_delete_broker',
  FETCH_DELETE_BROKER_SUCCESS = 'fetch_delete_broker_success',
  FETCH_DELETE_BROKER_FAIL = 'fetch_delete_broker_fail';
