import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import DeleteConfirmation from '../../common/components/DeleteConfirmation';
import IndexYachtListPresenter from './IndexYachtListPresenter';

// Redux
import { fetchYachtLists, deleteYachtList } from './redux/_actions';

// SVG
import savedSearches from '../../assets/svgs/saved-searches.svg';

class IndexYachtListContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            tableSearch: '',
            menu: {
                parent: savedSearches,
                items: [
                    <NavLink exact to={'/yacht-lists'}>
                        All
                    </NavLink>
                ]
            },
            table_headers: [
                {
                    name: 'internal_name',
                    label: 'Internal Title',
                    sort: false
                },
                {
                    name: 'list_type',
                    label: 'Type | List Type',
                    sort: false
                },
                {
                    name: 'no_of_vessels',
                    label: 'Total Yachts',
                    sort: false
                },
                {
                    name: 'created_at',
                    label: 'Created on | Updated on',
                    sort: false
                },
                {
                    name: 'action',
                    label: 'Action',
                    sort: false
                }
            ],
            modal_header: 'You are about to delete a yacht list',
            modal_body: (
                <DeleteConfirmation
                    toggle={this.onToggleModal.bind(this)}
                    action={this.onDeleteConfirmed.bind(this)}
                />
            ),
            is_modal_open: false,
            yacht_list_to_delete: null,
            title_refs: [],
            tooltips_state: []
        };
    }

    componentDidMount() {
        this.props.fetchYachtLists().then((data) => {
            this.setState({ loading: false });
        });
    }

    onToggleModal() {
        this.setState({ is_modal_open: !this.state.is_modal_open });
    }

    onDeleteModalToggle(id) {
        this.setState({ yacht_list_to_delete: id });
        this.onToggleModal();
    }

    onDeleteConfirmed() {
        const id = this.state.yacht_list_to_delete;

        this.setState({ loading: true });

        this.props.deleteYachtList(id).then((response) => {
            this.props
                .fetchYachtLists()
                .then((data) => this.setState({ loading: false }));
        });
    }

    onTableChange(key, value) {
        this.setState({ title_refs: [], tooltips_state: [], loading: true });
        const current_pagination = this.props.yacht_lists.pagination;

        if (key === 'search') {
            this.setState({ tableSearch: value });
        }

        let params = {
            page: key === 'page' ? value : 1,
            page_size:
                key === 'page_size' ? value : current_pagination.page_size,
            search: key === 'search' ? value : this.state.tableSearch
        };

        this.props.fetchYachtLists(params).then((data) => {
            let tooltips = [];
            data.yacht_lists.forEach((item) => tooltips.push(false));

            this.setState({ loading: false, tooltips_state: tooltips });
        });
    }

    onCopyToClipboard(ref_index) {
        let range = document.createRange();
        range.selectNode(this.state.title_refs[ref_index]);
        window.getSelection().removeAllRanges(); // clear current selection
        window.getSelection().addRange(range); // to select text
        document.execCommand('copy');
        window.getSelection().removeAllRanges(); // to deselect

        let tooltips = this.state.tooltips_state;
        tooltips[ref_index] = true;
        this.setState({ tooltips_state: tooltips });

        setTimeout(() => {
            let tooltips = this.state.tooltips_state;
            tooltips[ref_index] = false;
            this.setState({ tooltips_state: tooltips });
        }, 1000);
    }

    render() {
        return (
            <IndexYachtListPresenter
                history={this.props.history}
                loading={this.state.loading}
                menu={this.state.menu}
                table_headers={this.state.table_headers}
                yacht_lists={this.props.yacht_lists}
                modal_header={this.state.modal_header}
                modal_body={this.state.modal_body}
                is_modal_open={this.state.is_modal_open}
                title_refs={this.state.title_refs}
                tooltips_state={this.state.tooltips_state}
                onToggleModal={this.onToggleModal.bind(this)}
                onDeleteModalToggle={this.onDeleteModalToggle.bind(this)}
                onTableChange={this.onTableChange.bind(this)}
                onCopyToClipboard={this.onCopyToClipboard.bind(this)}
            />
        );
    }
}

const mapStateToprops = (state) => {
    return {
        yacht_lists: state.yacht_lists.yacht_lists
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchYachtLists: (params) => dispatch(fetchYachtLists(params)),
        deleteYachtList: (id) => dispatch(deleteYachtList(id))
    };
};

export default connect(
    mapStateToprops,
    mapDispatchToProps
)(IndexYachtListContainer);
