import React, { Component } from 'react';
import { Field } from 'redux-form';

import ToggleCollapse from '../../common/components/ToggleCollapse';
import RenderField from '../../common/components/RenderField';
import RenderReactSelect from '../../common/components/RenderReactSelect';

class WantsToSell extends Component {
    render() {
        return (
            <ToggleCollapse
                className={'grey'}
                body={
                    <div>
                        <div className="row mt-1">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 info-label mb-1">
                                        You will receive a notification
                                        reminding you to follow up with your
                                        contact.
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-7">
                                        <Field
                                            name="sales_yacht_name"
                                            type="text"
                                            readOnly={this.props.readOnly}
                                            disabled={this.props.readOnly}
                                            placeholder="YACHT NAME"
                                            component={RenderField}
                                            className="mb-2"
                                            onChange={event =>
                                                this.props.searchChange(
                                                    event,
                                                    'sales'
                                                )
                                            }
                                            dropdownList={
                                                this.props.dropdownList
                                            }
                                            onClickList={this.props.onClickList}
                                            openSearch={this.props.openSearch}
                                        />
                                    </div>
                                    <div className="col-5">
                                        <Field
                                            name="sales_remind_weeks"
                                            readOnly={this.props.readOnly}
                                            disabled={this.props.readOnly}
                                            label="REMIND ME TO FOLLOW UP EVERY"
                                            placeholder="SELECT"
                                            component={RenderReactSelect}
                                            className="mb-0"
                                            options={[
                                                { value: 2, label: '2 weeks' },
                                                { value: 4, label: '4 weeks' },
                                                { value: 6, label: '6 weeks' },
                                                { value: 8, label: '8 weeks' },
                                                {
                                                    value: 17,
                                                    label: '4 months'
                                                },
                                                {
                                                    value: 26,
                                                    label: '6 months'
                                                },
                                                {
                                                    value: 51,
                                                    label: '12 months'
                                                }
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row" />
                            </div>
                        </div>
                    </div>
                }
                header="WANTS TO SELL"
            />
        );
    }
}

export default WantsToSell;
