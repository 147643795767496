import React from 'react';

const ManageLocationDecorator = (props) => (WrappedComponent) => {
    return class extends React.Component {
        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    validateFormProps={this.validateFormProps.bind(this)}
                />
            );
        }

        validateFormProps(form_props) {
            let params = Object.assign({}, form_props);

            // params.info.night_trip =
            //     form_props.info.night_trip === true ? 1 : 0;
            params.parents = form_props.parents ? [form_props.parents] : [];

            return params;
        }
    };
};

export default ManageLocationDecorator;
