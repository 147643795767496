import { applyMiddleware, createStore, compose } from 'redux';

import thunkMiddleware from 'redux-thunk';

import reducers from './rootReducers';
import { loadState } from './localStorage';

const preloadedState = loadState();
const middlewares = [thunkMiddleware];
const enhancers = [];

if (process.env.REACT_APP_ENV === `development`) {
    //const createLogger = require('redux-logger');
    //const loggerMiddleware = createLogger();

    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }

    //middlewares.push(loggerMiddleware);
}

const middleWare = applyMiddleware(...middlewares);
const composedEnhancers = compose(middleWare, ...enhancers);

const initialState = {
    auth: {
        authenticated: false,
        token: null
    },
    user: {}
};

export default function configureStore(init = preloadedState) {
    let state = init;

    if (typeof state === 'undefined') {
        state = initialState;
    }

    return createStore(reducers, state, composedEnhancers);
}
