import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';

import Header from '../common/header/Header';

import SimpleTableActions from '../common/components/SimpleTableActions';
import SimpleTable from '../common/components/SimpleTable';
import SimpleModal from '../common/components/SimpleModal';

import ManageBroker from './broker/ManageBroker';
import DeleteConfirmation from '../common/components/DeleteConfirmation';

import brokers from '../assets/svgs/brokers.svg';
import add from '../assets/svgs/add.svg';
import edit from '../assets/svgs/edit.svg';
import deleteSVG from '../assets/svgs/delete.svg';

import { fetchBrokers, fetchDeleteBroker, initBrokerModal } from './actions';
import _debounce from 'lodash/debounce';

class Brokers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                parent: brokers,
                items: [
                    <NavLink exact to="/contacts">
                        Broker Contacts
                    </NavLink>
                ],
                searchFunction: this.tableChange.bind(this),
                searchLabel: 'Search name, company or email'
            },
            headers: [
                {
                    name: 'name',
                    label: 'Name',
                    sort: true,
                    defaultSort: 'ASC'
                },
                {
                    name: 'company',
                    label: 'Company Name',
                    sort: true,
                    defaultSort: 'ASC'
                },
                {
                    name: 'contact',
                    label: 'Contact',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'mobile',
                    label: 'Mobile',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'main',
                    label: 'Main',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'type',
                    label: 'Type',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'action',
                    label: 'Action',
                    sort: false,
                    defaultSort: ''
                }
            ],
            modalHeader: 'Create new contact',
            modalOpen: false,
            modalManage: (
                <ManageBroker
                    toggle={this.toggleModal.bind(this)}
                    refetchBrokers={this.refetchBrokers.bind(this)}
                />
            ),
            modalDelete: (
                <DeleteConfirmation
                    toggle={this.toggleModal.bind(this)}
                    action={this.deleteBroker.bind(this)}
                    body=""
                />
            ),
            defaultModal: null,
            hover: false,
            hoverIndex: null,
            search: ''
        };

        this.handleBrokerSearch = _debounce(this.handleBrokerSearch, 500);
    }

    handleBrokerSearch() {
        this.props.fetchBrokers({ search: this.state.search_value });
    }

    componentDidMount() {
        this.props.fetchBrokers({
            page: 1,
            order_field: 'name',
            order_dir: 'ASC',
            page_size: 25
        });
        this.setState({ defaultModal: this.state.modalManage });
    }

    mouseOver(index) {
        this.setState({
            hover: true,
            hoverIndex: index
        });
    }
    mouseOut() {
        this.setState({ hover: false });
    }

    tableChange(key, newValue) {
        if (key === 'search') {
            this.setState({ search: newValue });
        }
        let params = {
            page: key !== 'page' ? 1 : newValue,
            order_dir:
                key !== 'page' && key !== 'page_size' && key !== 'search'
                    ? newValue
                    : this.props.sorting.order_dir,
            order_field:
                key !== 'page' && key !== 'page_size' && key !== 'search'
                    ? key
                    : this.props.sorting.order_field,
            search: this.state.search,

            page_size:
                key === 'page_size' ? newValue : this.props.sorting.page_size
        };

        this.props.fetchBrokers(params);
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    refetchBrokers(page) {
        this.props.fetchBrokers({
            page: page ? page : this.props.pagination.page,
            order_field: this.props.sorting.order_field,
            order_dir: this.props.sorting.order_dir,
            page_size: this.props.pagination.page_size,
            search: this.props.sorting.search
        });
    }

    deleteModal(broker) {
        this.props.initBrokerModal(broker).then(() => {
            this.setState({
                modalDelete: (
                    <DeleteConfirmation
                        toggle={this.toggleModal.bind(this)}
                        action={this.deleteBroker.bind(this)}
                        body={
                            'You are about to delete broker contact ' +
                            broker.company +
                            '.'
                        }
                    />
                )
            });
            this.setState({ defaultModal: this.state.modalDelete });
            this.setState({ modalHeader: 'Delete broker contact' });

            this.toggleModal();
        });
    }

    deleteBroker() {
        this.setState({ hover: false });
        this.props
            .fetchDeleteBroker({ id: this.props.initBroker.id })
            .then((data) => {
                if (data) {
                    if (this.props.brokers.length === 1)
                        this.refetchBrokers(
                            this.props.pagination.page - 1 > 0
                                ? this.props.pagination.page - 1
                                : 1
                        );
                    else this.refetchBrokers();
                }
            });
    }

    render() {
        return (
            <div className="content-inner-wrapper">
                <Header menu={this.state.menu} history={this.props.history} />

                <div className="content-inner">
                    <div className="row">
                        <div className="col-xl-11 col-12">
                            <SimpleTableActions
                                label="Create new broker contact"
                                icon={add}
                                onClick={() =>
                                    this.props.history.push('/contacts/create')
                                }
                            />

                            <SimpleTable
                                search={true}
                                tableChange={this.tableChange.bind(this)}
                                hideExtraFilter={true}
                                paging={this.props.pagination}
                                colWidths={[1, 2, 2, 2, 2, 1, 1]}
                                headers={this.state.headers}
                                bodyClass="popup-wrap"
                                rowData={this.props.brokers.map(
                                    (value, key) => {
                                        return [
                                            <div>
                                                <div
                                                    className="cursor-pointer d-inline"
                                                    onMouseEnter={this.mouseOver.bind(
                                                        this,
                                                        key
                                                    )}
                                                    onMouseLeave={this.mouseOut.bind(
                                                        this
                                                    )}
                                                >
                                                    {value.name}
                                                </div>

                                                <div className="popup-image">
                                                    <img
                                                        alt=""
                                                        src={value.photo}
                                                        className={
                                                            this.state.hover &&
                                                            this.state
                                                                .hoverIndex ===
                                                                key &&
                                                            value.photo !== ''
                                                                ? 'opacity'
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>,
                                            <div>
                                                <NavLink
                                                    exact
                                                    to={`/yachts/view/sales?company=${encodeURI(
                                                        value.company
                                                    )}`}
                                                >
                                                    {value.company}
                                                </NavLink>
                                            </div>,
                                            <div>{value.contact}</div>,
                                            <div>{value.mobile}</div>,
                                            <div>{value.main}</div>,
                                            <div>
                                                {(value.type === 'sales' ||
                                                    value.type === null) && (
                                                    <NavLink
                                                        exact
                                                        to={`/yachts/view/sales?broker=${value.id}`}
                                                    >
                                                        <div className="customer-cat customer-sales">
                                                            S
                                                        </div>
                                                    </NavLink>
                                                )}

                                                {value.type ===
                                                    'chartering' && (
                                                    <NavLink
                                                        exact
                                                        to={`/yachts/view/charter?charterBroker=${value.id}`}
                                                    >
                                                        <div className="customer-cat customer-chartering">
                                                            C
                                                        </div>
                                                    </NavLink>
                                                )}
                                                {value.type === 'both' && (
                                                    <>
                                                        <NavLink
                                                            exact
                                                            to={`/yachts/view/sales?broker=${value.id}`}
                                                        >
                                                            <div className="customer-cat customer-sales mr-1">
                                                                S
                                                            </div>
                                                        </NavLink>
                                                        <NavLink
                                                            exact
                                                            to={`/yachts/view/charter?charterBroker=${value.id}`}
                                                        >
                                                            <div className="customer-cat customer-chartering">
                                                                C
                                                            </div>
                                                        </NavLink>
                                                    </>
                                                )}
                                            </div>,
                                            <ul className="list-unstyled table-td-actions">
                                                <li>
                                                    <NavLink
                                                        className="btn btn-td-action"
                                                        to={
                                                            '/contacts/edit/' +
                                                            value.id
                                                        }
                                                    >
                                                        <ReactSVG
                                                            path={edit}
                                                            style={{
                                                                height: 24
                                                            }}
                                                        />
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <button
                                                        className="btn btn-td-action"
                                                        onClick={() =>
                                                            this.deleteModal(
                                                                value
                                                            )
                                                        }
                                                    >
                                                        <ReactSVG
                                                            path={deleteSVG}
                                                            style={{
                                                                height: 24
                                                            }}
                                                        />
                                                    </button>
                                                </li>
                                            </ul>
                                        ];
                                    }
                                )}
                            />
                            <SimpleModal
                                header={this.state.modalHeader}
                                body={this.state.defaultModal}
                                modalOpen={this.state.modalOpen}
                                toggle={this.toggleModal.bind(this)}
                                className="modal-lg"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetchBrokers: (e) => dispatch(fetchBrokers(e)),
        fetchDeleteBroker: (e) => dispatch(fetchDeleteBroker(e)),
        initBrokerModal: (e) => dispatch(initBrokerModal(e))
    };
}

function mapStateToProps(state) {
    return {
        user: state.user,
        brokers: state.brokers.brokers,
        initBroker: state.brokers.initBroker,
        pagination: state.brokers.pagination,
        sorting: state.brokers.sorting
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Brokers);
