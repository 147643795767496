export const GET_USER = 'get_user',
  EDIT_USER = 'edit_user',
  EDIT_USER_SUCCESS = 'edit_user_success',
  EDIT_USER_FAIL = 'edit_user_fail',
  CHANGE_PASSWORD = 'change_password',
  CHANGE_PASSWORD_SUCCESS = 'change_password_success',
  CHANGE_PASSWORD_FAIL = 'change_password_fail',
  FETCH_USER = 'fetch_user',
  FETCH_USER_SUCCESS = 'fetch_user_success',
  FETCH_USER_FAIL = 'fetch_user_fail';
