export const FETCH_SALES_LISTINGS = 'FETCH_SALES_LISTINGS',
  FETCH_SALES_LISTINGS_SUCCESS = 'FETCH_SALES_LISTINGS_SUCCESS',
  FETCH_SALES_LISTINGS_FAIL = 'FETCH_SALES_LISTINGS_FAIL',

  CREATE_SALES_LISTING = 'CREATE_SALES_LISTING',
  CREATE_SALES_LISTING_SUCCESS = 'CREATE_SALES_LISTING_SUCCESS',
  CREATE_SALES_LISTING_FAIL = 'CREATE_SALES_LISTING_FAIL',

  DELETE_SALES_LISTING = 'DELETE_SALES_LISTING',
  DELETE_SALES_LISTING_SUCCESS = 'DELETE_SALES_LISTING_SUCCESS',
  DELETE_SALES_LISTING_FAIL = 'DELETE_SALES_LISTING_FAIL';
