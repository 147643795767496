const alerts = {
    success: {
        createAmenity: {
            en: { title: 'Success', message: 'Amenity saved successfully.' }
        },
        updateAmenity: {
            en: { title: 'Success', message: 'Amenity updated successfully.' }
        },
        deleteAmenity: {
            en: { title: 'Success', message: 'Amenity deleted successfully.' }
        },
        manageBroker: {
            en: { title: 'Success', message: 'Broker saved successfully.' }
        },
        manageDestination: {
            en: { title: 'Success', message: 'Itinerary saved successfully.' }
        },
        locationCreated: {
            en: { title: 'Success', message: 'Location saved successfully.' }
        },
        locationUpdated: {
            en: { title: 'Success', message: 'Location updated successfully.' }
        },
        locationDeleted: {
            en: { title: 'Success', message: 'Location deleted successfully.' }
        },
        manageUser: {
            en: { title: 'Success', message: 'User saved successfully.' }
        },
        editUser: {
            en: { title: 'Success', message: 'Profile saved successfully.' }
        },
        changePassword: {
            en: { title: 'Success', message: 'Password changed successfully.' }
        },
        resetPassword: {
            en: {
                title: 'Success',
                message:
                    'A message has been sent to your email address with a link to reset your password'
            }
        },
        deleteBroker: {
            en: { title: 'Success', message: 'Broker deleted.' }
        },
        deleteDestination: {
            en: { title: 'Success', message: 'Itinerary deleted.' }
        },
        deleteSavedSearch: {
            en: { title: 'Success', message: 'Saved Search deleted.' }
        },
        deletePlace: {
            en: { title: 'Success', message: 'Place deleted.' }
        },
        deleteUser: {
            en: { title: 'Success', message: 'User deleted.' }
        },
        manageCustomer: {
            en: { title: 'Success', message: 'Customer saved successfully.' }
        },
        deleteCustomer: {
            en: { title: 'Success', message: 'Customer deleted.' }
        },
        manageYacht: {
            en: { title: 'Success', message: 'Yacht saved successfully.' }
        },
        deleteYacht: {
            en: { title: 'Success', message: 'Yacht deleted successfully.' }
        },
        manageYachtPhoto: {
            en: { title: 'Success', message: 'Yacht photo saved successfully.' }
        },
        manageSearch: {
            en: { title: 'Success', message: 'Search saved successfully.' }
        },
        createOffer: {
            en: { title: 'Success', message: 'Proposal created.' }
        },
        sendOffer: {
            en: { title: 'Success', message: 'Proposal sent.' }
        },
        mailYacht: {
            en: { title: 'Success', message: 'Email sent.' }
        },
        publishNews: {
            en: { title: 'Success', message: 'Post published successfully' }
        },
        editNews: {
            en: { title: 'Success', message: 'Post edited successfully' }
        },
        deleteNews: {
            en: { title: 'Success', message: 'Post deleted successfully' }
        },
        reOrderNews: {
            en: {
                title: 'Success',
                message: 'Reordering completed successfully'
            }
        },
        createNewsletter: {
            en: {
                title: 'Success',
                message: 'Newsletter created successfully.'
            }
        },
        updateNewsletter: {
            en: { title: 'Success', message: 'Newsletter saved successfully.' }
        },
        deleteNewsletter: {
            en: {
                title: 'Success',
                message: 'Newsletter deleted successfully.'
            }
        },
        newsletterSended: {
            en: { title: 'Success', message: 'Newsletter sent.' }
        },
        yacht_list_created: {
            en: {
                title: 'Success',
                message: 'Yacht list created successfully!'
            }
        },
        yacht_list_updated: {
            en: {
                title: 'Success',
                message: 'Yacht list updated successfully!'
            }
        },
        yacht_list_deleted: {
            en: {
                title: 'Success',
                message: 'Yacht list deleted successfully!'
            }
        },
        lead_deleted: {
            en: {
                title: 'Success',
                message: 'Lead deleted successfully!'
            }
        },
        manage_booking: {
            en: {
                title: 'Success',
                message: 'Booking saved successfully!'
            }
        },
        bookingDelete: {
            en: {
                title: 'Success',
                message: 'Booking deleted successfully!'
            }
        },
        websiteCache: {
            en: { title: 'Success', message: 'Website cache cleared.' }
        }
    },

    error: {
        general: {
            en: { title: 'Error', message: 'Something went wrong.' },
            el: { title: 'Error', message: 'Κάτι πήγε στραβά.' }
        },
        websiteCacheWarn: {
            en: { title: 'Warning', message: 'No changes in cache detected.' }
        },
        hubspotWarning: {
            en: {
                title: 'Hubspot Warning',
                message:
                    'Something went wrong while updating this yacht in Hubspot.'
            }
        },
        websiteCache: {
            en: {
                title: 'Error',
                message:
                    'Error clearing the cache. Please report this issue to the administrator.'
            }
        },
        api: {
            400: {
                en: { title: 'Bad Request', message: 'Something went wrong.' },
                el: { title: 'Bad Request', message: 'Κάτι πήγε στραβά.' }
            },
            401: {
                en: { title: 'Unauthorized', message: '' },
                el: { title: 'Unauthorized', message: '' }
            },
            404: {
                en: { title: 'Error', message: 'Not found!' },
                el: { title: 'Error', message: 'Not found!' }
            },
            422: {
                en: { title: 'Error', message: 'Invalid request!' },
                el: { title: 'Error', message: 'Invalid request!' }
            },
            500: {
                en: { title: 'Error', message: 'Internal server error!' },
                el: { title: 'Error', message: 'Internal server error!' }
            }
        }
    }
};

module.exports = alerts;
