import {
    FETCH_USERS,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAIL,
    INIT_USER_MODAL,
    FETCH_MANAGE_USER,
    FETCH_MANAGE_USER_SUCCESS,
    FETCH_MANAGE_USER_FAIL,
    FETCH_ALL_USERS,
    FETCH_ALL_USERS_SUCCESS,
    FETCH_ALL_USERS_FAIL
} from './_types';

const INITIAL_STATE = {
    pagination: { page: 1, total: 0, page_size: 1, page_count: 1 },
    sorting: { order_field: 'name', order_dir: 'ASC' },
    users: [],
    initUser: {},
    actionTriggered: false
};

export default function managementUsersReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_USERS:
            return state;
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                pagination: {
                    page:
                        action.payload.pagination.total === 0
                            ? 0
                            : action.payload.pagination.page >
                              action.payload.pagination.page_count
                            ? 1
                            : action.payload.pagination.page,
                    total: action.payload.pagination.total,
                    page_size: action.payload.pagination.page_size,
                    page_count: action.payload.pagination.page_count
                },
                users: action.payload.users,
                sorting: action.payload.sorting
            };
        case FETCH_USERS_FAIL:
            return { ...state, error: action.payload };
        case INIT_USER_MODAL:
            return {
                ...state,
                initUser: action.payload
            };
        case FETCH_MANAGE_USER:
        case FETCH_MANAGE_USER_SUCCESS:
        case FETCH_MANAGE_USER_FAIL:
            return state;
        case FETCH_ALL_USERS:
            return state;
        case FETCH_ALL_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload
            };
        case FETCH_ALL_USERS_FAIL:
            return { ...state, error: action.payload };
        default:
            return state;
    }
}
