export const INIT_BOOKING = 'init_booking', 
    FETCH_BOOKINGS = 'fetch_bookings',
    FETCH_BOOKINGS_SUCCESS = 'fetch_bookings_success',
    FETCH_BOOKINGS_ERROR = 'fetch_bookings_error',
    FETCH_CREATE_BOOKING = 'fetch_create_booking',
    FETCH_CREATE_BOOKING_SUCCESS = 'fetch_create_booking_error',
    FETCH_CREATE_BOOKING_ERROR = 'fetch_create_booking_success',
    FETCH_BOOKING = 'fetch_booking',
    FETCH_BOOKING_SUCCESS = 'fetch_booking_success',
    FETCH_BOOKING_ERROR = 'fetch_booking_error',
    FETCH_UPDATE_BOOKING = 'fetch_update_booking',
    FETCH_UPDATE_BOOKING_SUCCESS = 'fetch_update_booking_success',
    FETCH_UPDATE_BOOKING_ERROR = 'fetch_update_booking_error',
    DELETE_BOOKING = 'fetch_delete_booking',
    DELETE_BOOKING_SUCCESS = 'fetch_delete_booking_success',
    DELETE_BOOKING_ERROR = 'fetch_delete_booking_error',
    FETCH_BOOKINGS_LOCATIONS = 'fetch_booking_locations',
    FETCH_BOOKINGS_LOCATIONS_SUCCESS = 'fetch_booking_locations_success',
    FETCH_BOOKINGS_LOCATIONS_ERROR = 'fetch_booking_locations_error';
