import { combineReducers } from 'redux';

import search from './search/_reducers';
import yacht from './yacht/_reducers';

const yachts = combineReducers({
  search: search,
  yacht: yacht
});

export default yachts;
