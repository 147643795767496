export const YACHT_CHARTER_TABLE_PREFIX = {
    type: 'yacht_charter_info',
    charter_speed_cruising: 'yacht_charter_info',
    charter_fuel_consumption: 'yacht_charter_info',
    cabins_charter: 'yacht_charter_info',
    cabin_layout: 'yacht_charter_info',
    guests_charter: 'yacht_charter_info',
    guests_static: 'yacht_charter_info',
    guests_private: 'yacht_charter_info',
    guests_sleeping_beds: 'yacht_charter_info',
    crew_charter: 'yacht_charter_info',
    crew_passengers_ratio: 'yacht_charter_info'
};

export const INITIAL_SALES_BROKER_FILTERS = {
    include: { status_detail: ['in_operation', 'in_build'] },
    equal: { status: 'for_sale' }
};

export const YACHT_TYPE_CHARTER = 'charter';

export const YACHT_TYPE_SALES = 'sales';

export const BOOKING_STATUSES_OPTIONS = [
    { value: 'unavailable', label: 'Unavailable' },
    { value: 'shipyard', label: 'Shipyard' },
    { value: 'booked', label: 'Booked' },
    { value: 'boat_show', label: 'Boat Show' },
    { value: 'transit', label: 'Transit' },
    { value: 'option', label: 'Option' }
];

export const MAILCHIMP_KEY = process.env.REACT_APP_MAILCHIMP_KEY;
export const MAILCHIMP_API = process.env.REACT_APP_MAILCHIMP_API;
