import {
    isAdmin,
    isModerator,
    isBroker,
    isSalesBroker,
    isCharterBroker
} from '../helpers/roles';

export const viewSales = (role) => {
    return (
        isAdmin(role) ||
        isModerator(role) ||
        isBroker(role) ||
        isSalesBroker(role)
    );
};

export const viewCharter = (role) => {
    return (
        isAdmin(role) ||
        isModerator(role) ||
        isBroker(role) ||
        isCharterBroker(role)
    );
};

export const manageBookings = (role) => {
    return isAdmin(role) || isModerator(role);
};

export const manageTestimonials = (role) => {
    return isAdmin(role) || isModerator(role);
};
