import React, { Component } from 'react';
import { FieldArray } from 'redux-form';

import RenderFieldArray from '../../common/components/RenderFieldArray';
import ToggleCollapse from '../../common/components/ToggleCollapse';

class SectionSocial extends Component {
  render() {
    return (
      <div className="col-12 social-wrapper">
        <ToggleCollapse
          body={
            <div className="row">
              <div className="col-12 mt-1 pl-0">
                <FieldArray
                  name="social_media"
                  component={RenderFieldArray}
                  type="text"
                  readOnly={this.props.readOnly}
                  changeFieldValue={this.props.changeFieldValue}
                  initialValues={this.props.initialValues}
                  className="col"
                  initFirst={true}
                  classNameAddMore="mt-0"
                  props={{
                    customFields: [
                      {
                        name: 'url',
                        type: 'text',
                        placeholder: 'URL OR USERNAME',
                        className: 'col-7'
                      },
                      {
                        name: 'type',
                        type: 'select',
                        placeholder: 'TYPE',
                        options: [
                          { value: 'facebook', label: 'Facebook' },
                          { value: 'google_plus', label: 'Google Plus' },
                          { value: 'instagram', label: 'Instagram' },
                          { value: 'twitter', label: 'Twitter' },
                          { value: 'website', label: 'Website' }
                        ],
                        className: 'col-4 offset-1'
                      }
                    ]
                  }}
                />
              </div>
            </div>
          }
          header="SOCIAL MEDIA & WEBSITE"
        />
      </div>
    );
  }
}
export default SectionSocial;
