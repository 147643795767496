export const equipmentSpecial = [
    {
        name: 'jacuzzi',
        value: '',
        type: 'checkbox',
        label: 'Jacuzzi',
        className: 'mt-1'
    },
    {
        name: 'ship_safes',
        value: '',
        type: 'checkbox',
        label: 'Ship safes',
        className: 'mt-1'
    },
    {
        name: 'elevator',
        value: '',
        type: 'checkbox',
        label: 'Elevator',
        className: 'mt-1'
    },
    {
        name: 'barbeque',
        value: '',
        type: 'checkbox',
        label: 'Barbeque',
        className: 'mt-1'
    },
    {
        name: 'mini_bar',
        value: '',
        type: 'checkbox',
        label: 'Mini bar'
    },
    {
        name: 'night_vision_camera',
        value: '',
        type: 'checkbox',
        label: 'Night vision camera'
    },
    {
        name: 'handicap',
        value: '',
        type: 'checkbox',
        label: 'Wheelchair access'
    },
    {
        name: 'beach_club',
        value: '',
        type: 'checkbox',
        label: 'Beach club'
    },
    {
        name: 'gym',
        value: '',
        type: 'checkbox',
        label: 'Gym'
    },
    {
        name: 'spa',
        value: '',
        type: 'checkbox',
        label: 'Spa'
    },
    {
        name: 'slide',
        value: '',
        type: 'checkbox',
        label: 'Slide'
    },
    {
        name: 'helipad',
        value: '',
        type: 'checkbox',
        label: 'Helipad'
    },
    {
        name: 'zero_speed_stabilizers',
        value: '',
        type: 'checkbox',
        label: 'Zero Speed Stabilizers'
    },
    {
        name: 'stabilizers_underway',
        value: '',
        type: 'checkbox',
        label: 'Stabilizers Underway'
    },

    {
        name: 'kid_friendly',
        value: '',
        type: 'checkbox',
        label: 'Kid friendly'
    },
    {
        name: 'pet_friendly',
        value: '',
        type: 'checkbox',
        label: 'Pet friendly'
    },
    {
        name: 'award_winning',
        value: '',
        type: 'checkbox',
        label: 'Award winning'
    },
    {
        name: 'rich_toy_collection',
        value: '',
        type: 'checkbox',
        label: 'Rich toy collection'
    },
    {
        name: 'diving_facilities',
        value: '',
        type: 'checkbox',
        label: 'Diving Facilities and Equipment'
    },
    {
        name: 'equipment_special_info',
        value: '',
        type: 'textarea',
        placeholder: 'INFO',
        className: 'mt-2'
    }
];
