import React from 'react';
import { Field } from 'redux-form';
import ReactSVG from 'react-svg';
import { Row, Col, Button } from 'reactstrap';
import moment from 'moment';

import { seasons } from 'helpers/variables';

import LocationTree from '../../../common/components/locations/LocationTree';
import Dropdown from '../../../common/components/Dropdown';

// Fields
import RenderField from '../../../common/components/RenderField';
import RenderDatePicker from '../../../common/components/RenderDatePicker';
import RenderRedactor from '../../../common/components/RenderRedactor';
import SimpleTable from '../../../common/components/SimpleTable';
import RenderLocationRatesFields from './RenderLocationRatesFields';
// SVG
import add from '../../../assets/svgs/add.svg';
import remove from '../../../assets/svgs/remove.svg';

// Helpers
import { required } from 'helpers/validate';

const table_headers = [
    {
        name: 'date_from',
        label: 'Date From'
    },
    {
        name: 'date_to',
        label: 'Date To'
    },
    {
        name: 'rate',
        label: 'Rate/Currency'
    },
    {
        name: 'notes',
        label: 'Rate Notes'
    }
];

class RenderLocationFields extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rate_data: []
        };

        this.loc_refs = [];
    }

    setSeasonDates = (season_label, field) => {
        let season = seasons.find((season) => season.label === season_label);
        let now = moment();
        let start_date = moment(season.start_date);
        let end_date = moment(season.end_date);

        if (start_date < now) {
            start_date.add(1, 'years');
            end_date.add(1, 'years');
        }

        this.props.changeFieldValue(
            `${field}.date_from`,
            start_date.format('YYYY/MM/DD')
        );
        this.props.changeFieldValue(
            `${field}.date_to`,
            end_date.format('YYYY/MM/DD')
        );
    };

    onDuplicateEntry(field) {
        this.props.fields.push(this.props.fields.get(field));

        setTimeout(() => {
            let element = this.loc_refs[this.loc_refs.length - 1];
            if (element) {
                window.scrollTo(0, element.offsetTop + element.offsetHeight);
            }
        }, 500);
    }

    getTableData = (index) => {
        let dataRates = [];
        return this.props.fields.get(index).rates.map((i) => {
            dataRates = [
                <div>{moment(i.date_from).format('YYYY-MM-DD')}</div>,
                <div>{moment(i.date_to).format('YYYY-MM-DD')}</div>,
                <div>{i.rate + '/' + i.currency}</div>,
                <div className="text--ellipsis">{i.notes}</div>
            ];

            return dataRates;
        });
    };

    handleRateNotesChange = (locationIndex, rateIndex, text) => {
        this.props.changeFieldValue(
            `location_seasons[${locationIndex}].rates[${rateIndex}].notes`,
            text
        );
    };

    handleAddRate = (locationIndex) => {
        if (
            this.props.fields.get(locationIndex).rates &&
            this.props.fields.get(locationIndex).rates.length > 0
        ) {
            const ratesLength = this.props.fields.get(locationIndex).rates
                .length;

            const newRatesArr = [
                ...this.props.fields.get(locationIndex).rates,
                {
                    date_from: moment(
                        this.props.fields.get(locationIndex).rates[
                            ratesLength - 1
                        ].date_to
                    )
                        .add(1, 'days')
                        .format('YYYY-MM-DD')
                }
            ];
            this.props.changeFieldValue(
                `location_seasons[${locationIndex}].rates`,
                newRatesArr
            );
        } else {
            const newRatesArr = [{}];
            this.props.changeFieldValue(
                `location_seasons[${locationIndex}].rates`,
                newRatesArr
            );
        }
    };

    handleDeleteLocation = (locationIndex) => {
        let newArr = [...this.props.fields.getAll()];
        newArr.splice(locationIndex, 1);

        this.props.changeFieldValue(`location_seasons`, []);

        setTimeout(() => {
            this.props.changeFieldValue(`location_seasons`, newArr);
        }, 0);
    };

    handleDeleteRate = (locationIndex, rateIndex) => {
        let newRatesArr = [...this.props.fields.get(locationIndex).rates];
        newRatesArr.splice(rateIndex, 1);

        this.props.changeFieldValue(
            `location_seasons[${locationIndex}].rates`,
            []
        );

        setTimeout(() => {
            this.props.changeFieldValue(
                `location_seasons[${locationIndex}].rates`,
                newRatesArr
            );
        }, 0);
    };

    handleDuplicateRate = (locationIndex, rateIndex) => {
        let rowToDuplicate = this.props.fields
            .get(locationIndex)
            .rates.find((i, index) => index === rateIndex);
        const newRatesArr = [
            ...this.props.fields.get(locationIndex).rates,
            rowToDuplicate
        ];
        this.props.changeFieldValue(
            `location_seasons[${locationIndex}].rates`,
            newRatesArr
        );
    };

    render() {
        const {
            fields,
            meta: { error, submitFailed },
            read_only
        } = this.props;

        return (
            <div className="row">
                {!read_only && (
                    <div className="col-12" style={{ marginBottom: '40px' }}>
                        <Button
                            color="info"
                            onClick={() => {
                                fields.push({});

                                setTimeout(() => {
                                    let element = this.loc_refs[
                                        this.loc_refs.length - 1
                                    ];
                                    window.scrollTo(
                                        0,
                                        element.offsetTop + element.offsetHeight
                                    );
                                }, 500);
                            }}
                        >
                            <ReactSVG path={add} style={{ height: 12 }} />
                            Add operating areas
                        </Button>
                        {submitFailed && error && <span>{error}</span>}
                    </div>
                )}

                <Col xs="12">
                    {fields.map((location, index) => {
                        return (
                            <div
                                className="row"
                                key={index}
                                ref={(el) => (this.loc_refs[index] = el)}
                                style={{
                                    padding: '10px 0'
                                }}
                            >
                                <Col xs="12">
                                    {index !== 0 && <hr />}
                                    <h4>Operating areas entry #{index + 1}</h4>
                                </Col>

                                <Col xs="12" md="6">
                                    <Row>
                                        {!read_only && (
                                            <Col xs="12" className="mb-2">
                                                <h4
                                                    style={{
                                                        display: 'inline',
                                                        paddingRight: '10px'
                                                    }}
                                                >
                                                    SET DATES BY SEASON
                                                </h4>
                                                <Dropdown
                                                    header="Choose season"
                                                    options={seasons.map(
                                                        (season) => season.label
                                                    )}
                                                    onChange={(event) =>
                                                        this.setSeasonDates(
                                                            event.target
                                                                .textContent,
                                                            location
                                                        )
                                                    }
                                                />
                                            </Col>
                                        )}

                                        <Col
                                            xs="12"
                                            md="6"
                                            className="customDatePicker"
                                        >
                                            <Field
                                                name={`${location}.date_from`}
                                                type="text"
                                                component={RenderDatePicker}
                                                validate={[required]}
                                                placeholder="DATE FROM"
                                                readOnly={read_only}
                                                date_format="YYYY/MM/DD"
                                            />
                                        </Col>

                                        <Col
                                            xs="12"
                                            md="6"
                                            className="customDatePicker"
                                        >
                                            <Field
                                                name={`${location}.date_to`}
                                                type="text"
                                                component={RenderDatePicker}
                                                validate={[required]}
                                                placeholder="DATE TO"
                                                readOnly={read_only}
                                                date_format="YYYY/MM/DD"
                                            />
                                        </Col>

                                        <Col xs="12" className="mb-2">
                                            <Field
                                                name={`${location}.base_port`}
                                                type="text"
                                                component={RenderField}
                                                placeholder="BASE PORT"
                                                readOnly={read_only}
                                            />
                                        </Col>

                                        <Col xs="12" className="mb-2">
                                            <RenderRedactor
                                                name={`${location}.special_requests`}
                                                label="SPECIAL REQUESTS"
                                                onChange={(text) => {
                                                    this.props.changeFieldValue(
                                                        `${location}.special_requests`,
                                                        text
                                                    );
                                                }}
                                                readOnly={read_only}
                                                disabled={read_only}
                                                id={`location-special-requests-${index}`}
                                            />
                                        </Col>

                                        <Col xs="12">
                                            <RenderRedactor
                                                name={`${location}.licences_registrations`}
                                                label="LICENSES AND REGISTRATIONS"
                                                onChange={(text) => {
                                                    this.props.changeFieldValue(
                                                        `${location}.licences_registrations`,
                                                        text
                                                    );
                                                }}
                                                readOnly={read_only}
                                                disabled={read_only}
                                                id={`location-licences-registrations-${index}`}
                                            />
                                        </Col>
                                        <Col xs="12">
                                            <h4 className={'mt-2 mb-1'}>
                                                <b>Rates</b>
                                            </h4>
                                        </Col>
                                        {read_only ? (
                                            <Col xs="12">
                                                <SimpleTable
                                                    id={`rates_${index}`}
                                                    name={`${location}.rates`}
                                                    tableChange={''}
                                                    paging={''}
                                                    colWidths={[2, 2, 4, 4]}
                                                    headers={table_headers}
                                                    rowData={this.getTableData(
                                                        index
                                                    )}
                                                />
                                            </Col>
                                        ) : (
                                            <Col xs="12">
                                                <div className={'rates'}>
                                                    <Field
                                                        name={`${location}.rates`}
                                                        colWidths={[
                                                            2,
                                                            2,
                                                            3,
                                                            3,
                                                            2
                                                        ]}
                                                        headers={table_headers}
                                                        locationIndex={index}
                                                        location={location}
                                                        setSeasonDates={
                                                            this.setSeasonDates
                                                        }
                                                        handleRateNotesChange={
                                                            this
                                                                .handleRateNotesChange
                                                        }
                                                        handleAddRate={
                                                            this.handleAddRate
                                                        }
                                                        handleDeleteRate={
                                                            this
                                                                .handleDeleteRate
                                                        }
                                                        handleDuplicateRate={
                                                            this
                                                                .handleDuplicateRate
                                                        }
                                                        component={
                                                            RenderLocationRatesFields
                                                        }
                                                        validate={[required]}
                                                        placeholder="RATES"
                                                    />
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>

                                <Col xs="12" md="3">
                                    <Field
                                        name={`${location}.locations`}
                                        component={LocationTree}
                                        validate={[required]}
                                        placeholder="LOCATION"
                                        mode="free"
                                        should_cascade={true}
                                        disabled={read_only}
                                        only_for_yachts={true}
                                    />
                                </Col>

                                {!read_only && (
                                    <Col xs="12" md="3">
                                        <Button
                                            className="mb-1"
                                            color="danger"
                                            onClick={() =>
                                                this.handleDeleteLocation(index)
                                            }
                                        >
                                            <ReactSVG
                                                path={remove}
                                                style={{ height: 12 }}
                                            />
                                            Remove Entry
                                        </Button>

                                        <Button
                                            color="info"
                                            onClick={() =>
                                                this.onDuplicateEntry(index)
                                            }
                                        >
                                            <ReactSVG
                                                path={add}
                                                style={{ height: 12 }}
                                            />
                                            Duplicate Entry
                                        </Button>
                                    </Col>
                                )}
                            </div>
                        );
                    })}
                </Col>
            </div>
        );
    }
}

export default RenderLocationFields;
