const defaults = {
  color: '#41506E',
  backgroundColor: '#fff',
  boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
  blue: '#3293F4',
  red: '#EE394E',
  orange: '#EDC624',
  green: '#5FCF80'
};

const notificationStyle = {
  Containers: {
    tc: {
      top: '45%'
    }
  },
  MessageWrapper: {
    DefaultStyle: {
      margin: '8px 0',
      padding: 0
    }
  },

  Title: {
    DefaultStyle: {
      fontSize: '1.6rem',
      margin: '0 0 8px 0',
      padding: 0,
      fontWeight: 500
    },

    success: {
      color: defaults.green
    },

    error: {
      color: defaults.red
    },

    warning: {
      color: defaults.orange
    },

    info: {
      color: defaults.blue
    }
  },

  NotificationItem: {
    DefaultStyle: {
      fontSize: '1.4rem',
      cursor: 'default'
    },

    success: {
      borderTop: '2px solid ' + defaults.green,
      backgroundColor: defaults.backgroundColor,
      color: defaults.color,
      WebkitBoxShadow: defaults.boxShadow,
      MozBoxShadow: defaults.boxShadow,
      boxShadow: defaults.boxShadow
    },
    error: {
      borderTop: '2px solid ' + defaults.red,
      backgroundColor: defaults.backgroundColor,
      color: defaults.color,
      WebkitBoxShadow: defaults.boxShadow,
      MozBoxShadow: defaults.boxShadow,
      boxShadow: defaults.boxShadow
    },
    warning: {
      borderTop: '2px solid ' + defaults.orange,
      backgroundColor: defaults.backgroundColor,
      color: defaults.color,
      WebkitBoxShadow: defaults.boxShadow,
      MozBoxShadow: defaults.boxShadow,
      boxShadow: defaults.boxShadow
    },
    info: {
      borderTop: '2px solid ' + defaults.blue,
      backgroundColor: defaults.backgroundColor,
      color: defaults.color,
      WebkitBoxShadow: defaults.boxShadow,
      MozBoxShadow: defaults.boxShadow,
      boxShadow: defaults.boxShadow
    }
  },
  Action: {
    DefaultStyle: {
      background: defaults.backgroundColor,
      borderRadius: '25px',
      padding: '8px 24px',
      fontWeight: '400',
      margin: '8px 0',
      border: 0
    },
    success: {
      backgroundColor: defaults.green
    },
    error: {
      backgroundColor: defaults.red
    },
    warning: {
      backgroundColor: defaults.orange
    },
    info: {
      backgroundColor: defaults.blue
    }
  }
};

export default notificationStyle;
