import {
  FETCH_BROKERS,
  FETCH_BROKERS_SUCCESS,
  FETCH_BROKERS_SEARCH_SUCCESS,
  FETCH_BROKERS_FAIL,
  INIT_BROKER_MODAL,
  FETCH_BROKER,
  FETCH_BROKER_SUCCESS,
  FETCH_BROKER_FAIL,
  FETCH_MANAGE_BROKER,
  FETCH_MANAGE_BROKER_SUCCESS,
  FETCH_MANAGE_BROKER_FAIL
} from './types';

const initialState = {
  multiple: [],
  pagination: { page: 1, total: 0, page_size: 1, page_count: 1 },
  sorting: { order_field: 'company', order_dir: 'ASC' },
  brokers: [],
  initBroker: {},
  search: ''
};

export default function brokers(state = initialState, action) {
  switch (action.type) {
    case FETCH_BROKERS:
      return state;
    case FETCH_BROKERS_SUCCESS:
      return {
        ...state,
        pagination: {
          page:
            action.payload.pagination.total === 0
              ? 0
              : action.payload.pagination.page > action.payload.pagination.page_count
                ? 1
                : action.payload.pagination.page,
          total: action.payload.pagination.total,
          page_size: action.payload.pagination.page_size,
          page_count: action.payload.pagination.page_count
        },
        brokers: action.payload.brokers,
        sorting: action.payload.sorting
      };
    case FETCH_BROKERS_SEARCH_SUCCESS:
      const search = action.payload.params ? action.payload.params.search : ''

      return {
        ...state,
        search
      };
    case FETCH_BROKERS_FAIL:
      return { ...state, error: action.payload };
    case INIT_BROKER_MODAL:
      return {
        ...state,
        initBroker: action.payload
      };
    case FETCH_BROKER:
      return state;
    case FETCH_BROKER_SUCCESS:
      return {
        ...state,
        initBroker: {
          ...action.payload,
          emails: action.payload.emails.map(item => {
            return item.value;
          }),
          phones: action.payload.phones.map(item => {
            return item.value;
          })
        }
      };
    case FETCH_BROKER_FAIL:
      return state;
    case FETCH_MANAGE_BROKER:
    case FETCH_MANAGE_BROKER_SUCCESS:
    case FETCH_MANAGE_BROKER_FAIL:
      return state;
    default:
      return state;
  }
}
