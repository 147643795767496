import React, { Component } from 'react';
import { Field } from 'redux-form';
import RenderDatePicker from '../../../common/components/RenderDatePicker';
import { normalizePrice, required } from '../../../helpers/validate';
import RenderField from '../../../common/components/RenderField';
import RenderRedactor from '../../../common/components/RenderRedactor';
import RenderReactSelect from '../../../common/components/RenderReactSelect';
import { Button } from 'reactstrap';
import ReactSVG from 'react-svg';
import remove from '../../../assets/svgs/remove.svg';
import add from '../../../assets/svgs/add.svg';
import Dropdown from '../../../common/components/Dropdown';
import { seasons } from '../../../helpers/variables';

export default function RenderLocationRatesFields({
    input,
    location,
    handleRateNotesChange,
    handleAddRate,
    handleDeleteRate,
    handleDuplicateRate,
    locationIndex,
    setSeasonDates
}) {
    return (
        <div>
            {input?.value ? (
                <>
                    {input?.value.length > 0 ? (
                        input?.value.map((rate, index) => {
                            return (
                                <div
                                    className={''}
                                    key={rate.index || `ratesRow-${index}`}
                                >
                                    <div className={'rates__row'}>
                                        <div className={'rates__seasons'}>
                                            <h4>SET DATES BY SEASON</h4>
                                            <Dropdown
                                                header="Choose season"
                                                options={seasons.map(
                                                    (season) => season.label
                                                )}
                                                onChange={(event) =>
                                                    setSeasonDates(
                                                        event.target
                                                            .textContent,
                                                        `${location}.rates[${index}]`
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className={'rates__dates'}>
                                            <div className={'rates__index'}>
                                                #{index + 1}
                                            </div>
                                            <div className="rates__spaces">
                                                <Field
                                                    name={`${location}.rates[${index}].date_from`}
                                                    component={RenderDatePicker}
                                                    placeholder="DATE FROM"
                                                    validate={[required]}
                                                    date_format="YYYY/MM/DD"
                                                />
                                            </div>
                                            <div className="rates__spaces">
                                                <Field
                                                    name={`${location}.rates[${index}].date_to`}
                                                    component={RenderDatePicker}
                                                    placeholder="DATE TO"
                                                    validate={[required]}
                                                    date_format="YYYY/MM/DD"
                                                />
                                            </div>
                                            <div className="rates__spaces">
                                                <Field
                                                    name={`${location}.rates[${index}].rate`}
                                                    type="text"
                                                    component={RenderField}
                                                    placeholder="RATE"
                                                    validate={required}
                                                    normalize={normalizePrice}
                                                />
                                            </div>
                                            <div className={'rates__currency'}>
                                                <Field
                                                    name={`${location}.rates[${index}].currency`}
                                                    placeholder=""
                                                    component={
                                                        RenderReactSelect
                                                    }
                                                    clearable={false}
                                                    validate={required}
                                                    options={[
                                                        {
                                                            value: 'usd',
                                                            label: 'USD'
                                                        },
                                                        {
                                                            value: 'eur',
                                                            label: 'EURO'
                                                        },
                                                        {
                                                            value: 'gbp',
                                                            label: 'GBP'
                                                        },
                                                        {
                                                            value: 'aud',
                                                            label: 'AUD'
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className={'rates__notes'}>
                                            <RenderRedactor
                                                name={`${location}.rates[${index}].notes`}
                                                onChange={(text) => {
                                                    handleRateNotesChange(
                                                        locationIndex,
                                                        index,
                                                        text
                                                    );
                                                }}
                                                readOnly={false}
                                                disabled={false}
                                                id={`location-${locationIndex}-rates-${index}`}
                                            />
                                        </div>
                                        <div className={'rates__buttons'}>
                                            <Button
                                                size="sm"
                                                className="mr-1"
                                                color="danger"
                                                onClick={() => {
                                                    handleDeleteRate(
                                                        locationIndex,
                                                        index
                                                    );
                                                }}
                                                //onClick={() => fields.remove(index)}
                                            >
                                                <ReactSVG
                                                    size="sm"
                                                    path={remove}
                                                    style={{ height: 12 }}
                                                />
                                                Remove Entry
                                            </Button>

                                            <Button
                                                color="info"
                                                onClick={() => {
                                                    handleDuplicateRate(
                                                        locationIndex,
                                                        index
                                                    );
                                                }}
                                            >
                                                <ReactSVG
                                                    path={add}
                                                    style={{ height: 12 }}
                                                />
                                                Duplicate Entry
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <span className="d-block text-center mb-2">
                            {'No rates added yet'}
                        </span>
                    )}
                </>
            ) : null}
            <div className={'rates__toolbar'}>
                <Button
                    color="info"
                    onClick={() => {
                        handleAddRate(locationIndex);
                    }}
                >
                    <ReactSVG path={add} style={{ height: 12 }} />
                    Add rate
                </Button>
            </div>
        </div>
    );
}
