import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';

import { Row, Col } from 'reactstrap';

import Header from '../../common/header/Header';
import SimpleTable from '../../common/components/SimpleTable';
import SimpleTableActions from '../../common/components/SimpleTableActions';
import SimpleModal from '../../common/components/SimpleModal';
import moment from 'moment';
import Loader from '../../common/components/Loader';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import { functions } from '../../helpers/functions';

// SVG
import open_eye from '../../assets/svgs/open-eye.svg';
import download from '../../assets/svgs/download.svg';
import deleteSVG from '../../assets/svgs/delete.svg';

const leads = (props) => {
    return (
        <div className="content-inner-wrapper">
            <Header menu={props.menu} history={props.history} />

            <div className="content-inner">
                {props.loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <Row>
                        <Col xs="12" md="4">
                            <RenderReactSelect
                                name="ordering"
                                meta={{}}
                                input={{
                                    value: props.selectedLucentForm,
                                    onChange: props.onFormChange
                                }}
                                label="FILTER BY LUCENT FORM"
                                clearable={false}
                                options={props.lucentForms}
                                className="mb-2"
                                showLabel={props.selectedLucentForm !== null}
                            />
                        </Col>

                        <Col xs="12" lg="9">
                            <SimpleTableActions
                                label="Download leads"
                                icon={download}
                                onClick={props.onDownloadAction}
                            />

                            <SimpleTable
                                tableChange={props.onTableChange}
                                paging={props.leads.pagination}
                                colWidths={[3, 3, 3, 2, '1 text-center']}
                                headers={props.tableHeaders}
                                rowData={props.leads.data.map((lead) => {
                                    return [
                                        <div>
                                            {lead.first_name +
                                                ' ' +
                                                lead.last_name}
                                            <div
                                                className="text--ellipsis"
                                                title={
                                                    lead.submission_id
                                                        ? 'Sub ID: ' +
                                                          `${lead.submission_id}`
                                                        : 'Sub ID: ' + ' --'
                                                }
                                            >
                                                {lead.submission_id
                                                    ? 'Sub ID: ' +
                                                      '  ' +
                                                      lead.submission_id
                                                    : 'Sub ID: ' + ' --'}
                                            </div>
                                        </div>,
                                        <div>{lead.email}</div>,
                                        <div>
                                            {lead.recipient_email || '-'}
                                        </div>,
                                        <div>
                                            {moment(lead.submitted_at).format(
                                                'YYYY-MM-DD HH:mm'
                                            )}
                                        </div>,
                                        <ul className="list-unstyled table-td-actions">
                                            <li className="text-fill-hover">
                                                <NavLink
                                                    to={`/leads/${lead.id}`}
                                                >
                                                    <ReactSVG
                                                        path={open_eye}
                                                        style={{ height: 24 }}
                                                    />
                                                </NavLink>
                                            </li>
                                            {functions.isAdmin(
                                                props.userRole
                                            ) && (
                                                <li>
                                                    <button
                                                        className="btn btn-td-action"
                                                        onClick={() =>
                                                            props.onDeleteModalToggle(
                                                                lead.id
                                                            )
                                                        }
                                                    >
                                                        <ReactSVG
                                                            path={deleteSVG}
                                                            style={{
                                                                height: 24
                                                            }}
                                                        />
                                                    </button>
                                                </li>
                                            )}
                                        </ul>
                                    ];
                                })}
                            />
                        </Col>
                        <Col xs="12">
                            <SimpleModal
                                header={props.modal_header}
                                body={props.modal_body}
                                modalOpen={props.is_modal_open}
                                toggle={props.onToggleModal}
                            />
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
};

export default leads;
