import React, { Component } from 'react';
import { Field } from 'redux-form';

import ToggleCollapse from '../../common/components/ToggleCollapse';
import RenderCheckbox from '../../common/components/RenderCheckbox';
import RenderRedactor from '../../common/components/RenderRedactor';

import { isBoolean } from '../../helpers/validate';

class SectionCommunicationManagement extends Component {
    render() {
        return (
            <ToggleCollapse
                body={
                    <div className="mt-2">
                        <div className="row">
                            <div className="col-5">
                                <div className="filter-label label font-500">
                                    BOAT SHOWS
                                </div>
                                <Field
                                    name="show_preferences.monaco_yacht_show"
                                    label="Monaco Yacht Show"
                                    group="boat_shows_monaco_yacht_show"
                                    type="checkbox"
                                    normalize={isBoolean}
                                    component={RenderCheckbox}
                                    disabled={this.props.readOnly}
                                />
                                <Field
                                    name="show_preferences.cannes_festival"
                                    label="Cannes Yachting Festival"
                                    group="boat_shows_cannes_yachting_festival"
                                    type="checkbox"
                                    normalize={isBoolean}
                                    component={RenderCheckbox}
                                    disabled={this.props.readOnly}
                                />
                                <Field
                                    name="show_preferences.flibs"
                                    label="FLIBS"
                                    group="boat_shows_flibs"
                                    type="checkbox"
                                    normalize={isBoolean}
                                    component={RenderCheckbox}
                                    disabled={this.props.readOnly}
                                />
                                <Field
                                    name="show_preferences.miami_boat_show"
                                    label="Miami Boat Show"
                                    group="boat_shows_miami_boat_show"
                                    type="checkbox"
                                    normalize={isBoolean}
                                    component={RenderCheckbox}
                                    disabled={this.props.readOnly}
                                />
                                <Field
                                    name="show_preferences.palm_beach_boat_show"
                                    label="Palm Beach Boat Show"
                                    group="boat_shows_palm_beach_boat_show"
                                    type="checkbox"
                                    normalize={isBoolean}
                                    component={RenderCheckbox}
                                    disabled={this.props.readOnly}
                                />
                            </div>
                            <div className="col-5 offset-1">
                                <div className="filter-label label font-500">
                                    PROMOTION MATERIAL
                                </div>
                                <Field
                                    name="communication_preferences.yacht_collection"
                                    label="Yacht Collection"
                                    group="communication_material_yacht_collection"
                                    type="checkbox"
                                    normalize={isBoolean}
                                    component={RenderCheckbox}
                                    disabled={this.props.readOnly}
                                />
                                <Field
                                    name="communication_preferences.horizons_magazine"
                                    label="Horizons Magazine"
                                    group="communication_material_horizons_magazine"
                                    type="checkbox"
                                    normalize={isBoolean}
                                    component={RenderCheckbox}
                                    disabled={this.props.readOnly}
                                />
                                <Field
                                    name="communication_preferences.christmas_card_print"
                                    label="New Year Card (Print)"
                                    group="communication_material_christmas_card"
                                    type="checkbox"
                                    normalize={isBoolean}
                                    component={RenderCheckbox}
                                    disabled={this.props.readOnly}
                                />
                                <Field
                                    name="communication_preferences.christmas_card_electronic"
                                    label="New Year Card (Electronic)"
                                    group="communication_material_christmas_card_electronic"
                                    type="checkbox"
                                    normalize={isBoolean}
                                    component={RenderCheckbox}
                                    disabled={this.props.readOnly}
                                />
                                <Field
                                    name="communication_preferences.hanukkah_card"
                                    label="Hanukkah Card"
                                    group="communication_material_hanukkah_card"
                                    type="checkbox"
                                    normalize={isBoolean}
                                    component={RenderCheckbox}
                                    disabled={this.props.readOnly}
                                />
                                <Field
                                    name="communication_preferences.name_day_card"
                                    label="Nameday card"
                                    group="name_day_card"
                                    type="checkbox"
                                    normalize={isBoolean}
                                    component={RenderCheckbox}
                                    disabled={this.props.readOnly}
                                />
                                <Field
                                    name="communication_preferences.birthday_card"
                                    label="Birthday card"
                                    group="birthday_card"
                                    type="checkbox"
                                    normalize={isBoolean}
                                    component={RenderCheckbox}
                                    disabled={this.props.readOnly}
                                />
                                <Field
                                    name="communication_preferences.gift"
                                    label="Gift"
                                    group="communication_material_gift"
                                    type="checkbox"
                                    normalize={isBoolean}
                                    component={RenderCheckbox}
                                    disabled={this.props.readOnly}
                                />
                            </div>
                        </div>

                        <div className="row mt-1">
                            {this.props.boatShowsOther === true ? (
                                <div className="col-12">
                                    <RenderRedactor
                                        readOnly={this.props.readOnly}
                                        label="BOAT SHOWS OTHER"
                                        name="other_show"
                                        id="boat_shows_other_notes"
                                    />
                                </div>
                            ) : null}

                            {this.props.communicationMaterialOther === true ? (
                                <div
                                    className={
                                        'col-12' +
                                        (this.props.boatShowsOther === true
                                            ? ' mt-2'
                                            : '')
                                    }
                                >
                                    <RenderRedactor
                                        readOnly={this.props.readOnly}
                                        label="COMMUNICATION MATERIAL OTHER"
                                        name="other_material"
                                        id="communication_material_notes"
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                }
                header="COMMUNICATION MANAGEMENT"
            />
        );
    }
}

export default SectionCommunicationManagement;
