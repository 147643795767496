import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';
import moment from 'moment';
import Moment from 'react-moment';

import Header from '../common/header/Header';

import SimpleTableActions from '../common/components/SimpleTableActions';
import SimpleTable from '../common/components/SimpleTable';
import SimpleModal from '../common/components/SimpleModal';

import RenderToggle from '../common/components/RenderToggle';

import DeleteConfirmation from '../common/components/DeleteConfirmation';

import management from '../assets/svgs/management.svg';
import add from '../assets/svgs/add.svg';
import edit from '../assets/svgs/edit.svg';
import deleteSVG from '../assets/svgs/delete.svg';

import {
    fetchUsers,
    initUserModal,
    fetchManageUser,
    fetchDeleteUser
} from './users/_actions';

import ManageUser from './users/ManageUser';

class Management extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                parent: management,
                items: [
                    <NavLink exact to="/management">
                        Users
                    </NavLink>
                ]
            },
            headers: [
                {
                    name: 'name',
                    label: 'Name',
                    sort: true,
                    defaultSort: 'ASC'
                },
                {
                    name: 'email',
                    label: 'Email',
                    sort: true,
                    defaultSort: 'DESC'
                },
                {
                    name: 'role',
                    label: 'Role',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'login_expires',
                    label: 'Login Expires',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'Status',
                    label: 'Status',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'action',
                    label: 'Action',
                    sort: false,
                    defaultSort: ''
                }
            ],
            modalHeader: 'Create new user',
            modalOpen: false,
            modalManage: (
                <ManageUser
                    toggle={this.toggleModal.bind(this)}
                    refetchUsers={this.refetchUsers.bind(this)}
                />
            ),
            modalDelete: (
                <DeleteConfirmation
                    toggle={this.toggleModal.bind(this)}
                    action={this.deleteUser.bind(this)}
                    body=""
                />
            ),
            defaultModal: null
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        this.props.fetchUsers({
            page: 1,
            order_field: 'name',
            order_dir: 'ASC',
            page_size: 25
        });
        this.setState({ defaultModal: this.state.modalManage });
    }

    tableChange(key, newValue) {
        let params = {
            page: key !== 'page' ? 1 : newValue,
            order_dir:
                key !== 'page' && key !== 'page_size'
                    ? newValue
                    : this.props.users.sorting.order_dir,
            order_field:
                key !== 'page' && key !== 'page_size'
                    ? key
                    : this.props.users.sorting.order_field,
            page_size:
                key === 'page_size'
                    ? newValue
                    : this.props.users.pagination.page_size
        };

        this.props.fetchUsers(params);
    }

    refetchUsers(page) {
        this.props.fetchUsers({
            page: page ? page : this.props.users.pagination.page,
            order_field: this.props.users.sorting.order_field,
            order_dir: this.props.users.sorting.order_dir,
            page_size: this.props.users.pagination.page_size
        });
    }

    createModalClick() {
        this.setState({ defaultModal: this.state.modalManage });
        this.setState({ modalHeader: 'Create new user' });

        this.props.initUserModal({});
        this.toggleModal();
    }

    editModalClick(user) {
        this.setState({ defaultModal: this.state.modalManage });
        this.setState({ modalHeader: 'Edit user' });

        this.props.initUserModal(user);
        this.toggleModal();
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    activeToggle(event, user) {
        let params = {
            id: user.id,
            active: event.target.value === 0 ? 1 : '0'
        };

        this.props.fetchManageUser(params).then(data => {
            if (data) {
                this.refetchUsers();
            }
        });
    }

    deleteModal(user) {
        this.props.initUserModal(user).then(() => {
            this.setState({
                modalDelete: (
                    <DeleteConfirmation
                        toggle={this.toggleModal.bind(this)}
                        action={this.deleteUser.bind(this)}
                        body={'You are about to delete user ' + user.name + '.'}
                    />
                )
            });
            this.setState({ defaultModal: this.state.modalDelete });
            this.setState({ modalHeader: 'Delete user' });

            this.toggleModal();
        });
    }

    deleteUser() {
        this.props
            .fetchDeleteUser({ id: this.props.initUser.id })
            .then(data => {
                if (data) {
                    if (this.props.users.users.length === 1)
                        this.refetchUsers(
                            this.props.users.pagination.page - 1 > 0
                                ? this.props.users.pagination.page - 1
                                : 1
                        );
                    else this.refetchUsers();
                }
            });
    }

    render() {
        return (
            <div className="content-inner-wrapper">
                <Header menu={this.state.menu} history={this.props.history} />

                <div className="content-inner">
                    <div className="row">
                        <div className="col-xl-9 col-12">
                            <SimpleTableActions
                                label="Create new user"
                                icon={add}
                                onClick={() => this.createModalClick()}
                            />
                            <SimpleTable
                                tableChange={this.tableChange.bind(this)}
                                paging={this.props.users.pagination}
                                colWidths={[
                                    3,
                                    3,
                                    2,
                                    2,
                                    '1 text-center',
                                    '1 text-center'
                                ]}
                                headers={this.state.headers}
                                rowData={this.props.users.users.map(value => {
                                    return [
                                        <div>{value.name}</div>,
                                        <div>{value.email}</div>,
                                        <span
                                            className={
                                                'role w-100 bg-' + value.role
                                            }
                                        >
                                            {value.role.replace(/_/g, ' ')}
                                        </span>,
                                        <div>
                                            {value.last_login &&
                                                (moment(
                                                    value.last_login
                                                ).format('YYYY-MM-DD') ===
                                                moment().format(
                                                    'YYYY-MM-DD'
                                                ) ? (
                                                    <Moment fromNow ago>
                                                        {moment(
                                                            value.last_login
                                                        ).add(8, 'hours')}
                                                    </Moment>
                                                ) : (
                                                    moment(
                                                        value.last_login
                                                    ).format('DD/MM/YYYY HH:mm')
                                                ))}
                                        </div>,
                                        <div className="td-toggle">
                                            <RenderToggle
                                                name={'active' + value.id}
                                                input={{
                                                    value: value.active,
                                                    onChange: (event, v) =>
                                                        this.activeToggle(
                                                            event,
                                                            value
                                                        )
                                                }}
                                            />
                                        </div>,
                                        <ul className="list-unstyled table-td-actions">
                                            <li>
                                                <button
                                                    className="btn btn-td-action"
                                                    onClick={() =>
                                                        this.editModalClick(
                                                            value
                                                        )
                                                    }
                                                >
                                                    <ReactSVG
                                                        path={edit}
                                                        style={{ height: 24 }}
                                                    />
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className="btn btn-td-action"
                                                    onClick={() =>
                                                        this.deleteModal(value)
                                                    }
                                                >
                                                    <ReactSVG
                                                        path={deleteSVG}
                                                        style={{ height: 24 }}
                                                    />
                                                </button>
                                            </li>
                                        </ul>
                                    ];
                                })}
                            />
                        </div>
                    </div>
                </div>

                <SimpleModal
                    header={this.state.modalHeader}
                    body={this.state.defaultModal}
                    modalOpen={this.state.modalOpen}
                    toggle={this.toggleModal.bind(this)}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetchUsers: e => dispatch(fetchUsers(e)),
        initUserModal: e => dispatch(initUserModal(e)),
        fetchManageUser: e => dispatch(fetchManageUser(e)),
        fetchDeleteUser: e => dispatch(fetchDeleteUser(e))
    };
}

function mapStateToProps(state) {
    return {
        user: state.user,
        users: state.management.users,
        initUser: state.management.users.initUser
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Management);
