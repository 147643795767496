import React from 'react';
import { Field } from 'redux-form';
import ReactSVG from 'react-svg';
import { Row, Col, Button } from 'reactstrap';

// Fields
import RenderField from '../../common/components/RenderField';

// SVG
import add from 'assets/svgs/add.svg';
import remove from 'assets/svgs/remove.svg';

class RenderVirtualTourUrl extends React.Component {
    render() {
        const {
            fields,
            meta: { error, submitFailed },
            readOnly
        } = this.props;
        return (
            <div className="row">
                {!readOnly && fields.length < 5 && (
                    <div className="col-12" style={{ marginBottom: '40px' }}>
                        <Button color="info" onClick={() => fields.push('')}>
                            <ReactSVG path={add} style={{ height: 12 }} />
                            Add Virtual Tour Url
                        </Button>
                        {submitFailed && error && <span>{error}</span>}
                    </div>
                )}

                <Col xs="12">
                    {fields.map((virtual_tour_urls, index) => {
                        return (
                            <Row className="row" key={index}>
                                <Col xs="12">
                                    {index !== 0}
                                    <h4>Virtual Tour Url #{index + 1}</h4>
                                </Col>

                                <Col xs="10">
                                    <Field
                                        name={`${virtual_tour_urls}`}
                                        component={RenderField}
                                        className="mt-0"
                                        readOnly={this.props.readOnly}
                                    />
                                </Col>
                                {!readOnly && (
                                    <Button
                                        className="mb-1"
                                        color="danger"
                                        onClick={() => fields.remove(index)}
                                    >
                                        <ReactSVG
                                            path={remove}
                                            style={{ height: 12 }}
                                        />
                                        Remove Entry
                                    </Button>
                                )}
                            </Row>
                        );
                    })}
                </Col>
            </div>
        );
    }
}

export default RenderVirtualTourUrl;
