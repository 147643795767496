import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import ReactSVG from 'react-svg';
import { Row, Col, Button } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Fields
import RenderField from '../../../common/components/RenderField';

// SVG
import add from '../../../assets/svgs/add.svg';
import remove from '../../../assets/svgs/remove.svg';
import rearrange from '../../../assets/svgs/rearrange.svg';

export default function RenderToysFieldsMultiple(props) {
    const {
        fields,
        meta: { error, submitFailed },
        read_only,
        locale
        // viewType,
        // yachtfolio_id
    } = props;
    const [toysFields, setToysFields] = useState([]);

    useEffect(() => {
        removeEmptyEntries();
        setToysFields(fields);
    }, [fields, locale]);

    const removeEmptyEntries = () => {
        const fieldsItems = Array.from(fields.getAll());

        fieldsItems.map((item, index) => {
            if (
                item?.translations?.locale === undefined &&
                item.id !== undefined
            ) {
                fields.remove(index);
            }
        });
    };

    const onEndReorder = (result) => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(toysFields.getAll());
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        //removes all fields from toysFields and pushes the new ordered fields

        toysFields.removeAll();
        items.map((item) => {
            toysFields.push(item);
        });
    };

    return (
        <div>
            {!read_only && (
                <div className="col-12" style={{ marginBottom: '40px' }}>
                    <Button
                        color="info"
                        // disabled={
                        //     viewType === 'charter' &&
                        //     locale === 'en' &&
                        //     yachtfolio_id
                        // }
                        onClick={() => fields.push({})}
                    >
                        <ReactSVG path={add} style={{ height: 12 }} />
                        Add Toys
                    </Button>
                    {submitFailed && error && <span>{error}</span>}
                </div>
            )}
            <DragDropContext onDragEnd={onEndReorder}>
                <Droppable
                    droppableId="droppable-field"
                    isDropDisabled={read_only}
                >
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {toysFields &&
                                toysFields.map((toy, index) => {
                                    return (
                                        <Draggable
                                            key={`${toy}`}
                                            draggableId={`${toy}`}
                                            index={index}
                                            isDragDisabled={read_only}
                                        >
                                            {(provided) => (
                                                <div
                                                    className="row"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div className="ml-1">
                                                        <div className="drag-handle">
                                                            <ReactSVG
                                                                path={rearrange}
                                                                style={{
                                                                    height: 24,
                                                                    marginTop: 5
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-11">
                                                        <div className="field-outer-wrapper">
                                                            <Field
                                                                name={`${toy}.translations.text`}
                                                                placeholder="TITLE"
                                                                component={
                                                                    RenderField
                                                                }
                                                                className="mt-0"
                                                                readOnly={
                                                                    read_only
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {!read_only && (
                                                        <div className="col-0">
                                                            <Button
                                                                className="mb-1"
                                                                //color="danger"
                                                                onClick={() =>
                                                                    fields.remove(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <ReactSVG
                                                                    path={
                                                                        remove
                                                                    }
                                                                    style={{
                                                                        height: 20,
                                                                        fill:
                                                                            'red',
                                                                        marginBottom: 8
                                                                    }}
                                                                />
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}
