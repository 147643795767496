export const FETCH_DRIVE_DEFAULTS = 'fetch_drive_defaults',   
    FETCH_DRIVE_DEFAULTS_SUCCESS = 'fetch_drive_defaults_seccess',
    FETCH_DRIVE_DEFAULTS_ERROR = 'fetch_drive_defaults_error',
    FETCH_DRIVE = 'fetch_drive',
    FETCH_DRIVE_SUCCESS = 'fetch_drive_success',
    FETCH_DRIVE_ERROR = 'fetch_drive_error',
    FETCH_CREATE_DRIVE = 'fetch_create_drive',
    FETCH_CREATE_DRIVE_SUCCESS = 'fetch_create_drive_success',
    FETCH_CREATE_DRIVE_ERROR = 'fetch_create_drive_error',
    FETCH_UPDATE_DRIVE = 'fetch_update_drive',
    FETCH_UPDATE_DRIVE_SUCCESS = 'fetch_update_drive_success',
    FETCH_UPDATE_DRIVE_ERROR = 'fetch_update_drive_error',
    FETCH_DELETE_DRIVE = 'fetch_delete_drive',
    FETCH_DELETE_DRIVE_SUCCESS = 'fetch_delete_drive_success',
    FETCH_DELETE_DRIVE_ERROR = 'fetch_delete_drive_error';