import axios from 'axios';
import { AUTH_ERROR } from '../../auth/_types';
import {
    UPLOAD_PROFILE_PHOTO,
    UPLOAD_PROFILE_PHOTO_SUCCESS,
    UPLOAD_PROFILE_PHOTO_FAIL,
    UPLOAD_FILE,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAIL
} from './_types';

import { API_URL } from '../variables';
import { authConfig } from '../authConfig';
import { errorHandler } from '../alerts';

export function uploadProfilePhoto(params) {
    const headers = {
        headers: authConfig('image'),
        params: params
    };

    // console.log('params', params);

    return (dispatch) => {
        dispatch({
            type: UPLOAD_PROFILE_PHOTO
        });

        return axios
            .get(API_URL + '/auth/s3', headers)
            .then((response) => {
                console.log(response);

                dispatch({
                    type: UPLOAD_PROFILE_PHOTO_SUCCESS,
                    payload: { ...response.data, ...params }
                });

                return response.data;
            })
            .catch((error) => {
                dispatch({
                    type: UPLOAD_PROFILE_PHOTO_FAIL
                });
                errorHandler(dispatch, error.response, AUTH_ERROR);
            });
    };
}

export function uploadAny(params) {
    const headers = {
        headers: authConfig('image'),
        params: params
    };

    return (dispatch) => {
        dispatch({
            type: UPLOAD_FILE
        });

        return axios
            .get(API_URL + '/auth/s3', headers)
            .then((response) => {
                dispatch({
                    type: UPLOAD_FILE_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch((error) => {
                dispatch({
                    type: UPLOAD_FILE_FAIL
                });
                errorHandler(dispatch, error.response, AUTH_ERROR);
            });
    };
}
