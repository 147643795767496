import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../common/header/Header';
import LabeledInfo from '../../common/components/LabeledInfo';
import { NavLink } from 'react-router-dom';
import savedSearches from '../../assets/svgs/saved-searches.svg';
import { errorHandler } from 'helpers/alerts';
import { success } from 'react-notification-system-redux';
import SimpleModal from '../../common/components/SimpleModal';
import ExternalYachtConfirmation from 'common/components/Forms/ExternalYachtConfirmation';
import { normalizePrice } from '../../helpers/validate';
import Loader from '../../common/components/Loader';

import {
    getExternalYacht,
    importExternalYacht,
    updateExternalYacht
} from 'services/externalYachts';
import PhotoGallery from '../../common/components/PhotoGallery';
import { dropdowns } from '../../helpers/variables';

const menu = {
    parent: savedSearches,
    items: [
        <NavLink exact to={'/external-yachts'}>
            External Yachts
        </NavLink>
    ]
};

const ExternalYachtView = () => {
    const history = useHistory();
    const [externalYacht, setExternalYacht] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalheader, setModalHeader] = useState('');
    const [modalbody, setModalBody] = useState();
    const [loading, setLoading] = useState();
    const dispatch = useDispatch();
    const { id } = useParams();

    const photos = externalYacht?.PhotoGallery
        ? externalYacht.PhotoGallery.split(',').map((i) => {
              return { url: i };
          })
        : [];

    useEffect(() => {
        loadExternalYacht();
    }, []);

    const loadExternalYacht = async () => {
        setLoading(true);
        try {
            const { data } = await getExternalYacht(id);
            setExternalYacht(data);
        } catch (err) {
            errorHandler(dispatch, err?.response || err);
        } finally {
            setLoading(false);
        }
    };

    const onImportExternalYacht = async (externalYacht) => {
        try {
            const { data } = await importExternalYacht(externalYacht);
            loadExternalYacht();
            dispatch(
                success({
                    title: 'Success',
                    message: 'Yacht Saved to Davinci'
                })
            );
        } catch (err) {
            errorHandler(dispatch, err?.response || err);
        } finally {
            onModalToggle();
        }
    };

    const onUpdateExternalYacht = async (vessel_id) => {
        setLoading(true);
        try {
            const { data } = await updateExternalYacht(vessel_id);
            loadExternalYacht();
            dispatch(
                success({
                    title: 'Success',
                    message: 'Yacht Updated'
                })
            );
        } catch (err) {
            errorHandler(dispatch, err?.response || err);
        }
    };

    const onModalOpen = (yacht) => {
        //console.log('onModalOpen', modalOpen);
        setModalOpen(!modalOpen);
        setModalHeader(
            yacht.ImportedAt != null
                ? moment(yacht.ImportedAt) < moment(yacht.updated_at)
                    ? 'You are about to update a yacht in DaVinci from external source.'
                    : 'This yacht is already up to date with external source.'
                : 'You are about to import a yacht from external source into DaVinci database'
        );
        setModalBody(
            <ExternalYachtConfirmation
                toggleModal={onModalToggle}
                yacht={yacht}
                action={onImportExternalYacht}
            />
        );
    };

    const onModalToggle = () => {
        setModalOpen(false);
    };
    return (
        <div className="content-inner-wrapper">
            <Header menu={menu} history={history} />
            <div className="content-inner">
                {loading ? (
                    <div
                        style={{
                            marginRight: '200px',
                            marginTop: '200px'
                        }}
                    >
                        <Loader />
                    </div>
                ) : (
                    <>
                        <Row className="result-yacht-wrapper">
                            <Col lg="12">
                                <Row className="mb-2">
                                    <Col sm="7">
                                        <img
                                            className={
                                                'yacht-image mb-1 background-yacht-filling'
                                            }
                                            src={externalYacht.photo_original}
                                            width="350px"
                                            height="200px"
                                            alt=""
                                        />
                                    </Col>
                                    <Col sm="2">
                                        <button
                                            className="btn btn-blue"
                                            onClick={() =>
                                                onModalOpen(externalYacht)
                                            }
                                        >
                                            Add External Yacht to DaVinci
                                        </button>
                                    </Col>
                                    <Col sm="2">
                                        <button
                                            className="btn btn-primary"
                                            onClick={() =>
                                                onUpdateExternalYacht(
                                                    externalYacht.vessel_id
                                                )
                                            }
                                        >
                                            Update
                                        </button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="2" className="mb-2">
                                        <LabeledInfo
                                            label="Davinci Status"
                                            color={
                                                externalYacht.ImportedAt != null
                                                    ? moment(
                                                          externalYacht.ImportedAt
                                                      ) <
                                                      moment(
                                                          externalYacht.updated_at
                                                      )
                                                        ? 'dv_status_needs_update'
                                                        : 'dv_status_Imported'
                                                    : 'dv_status_not_Imported'
                                            }
                                            value={
                                                externalYacht.ImportedAt != null
                                                    ? moment(
                                                          externalYacht.ImportedAt
                                                      ) <
                                                      moment(
                                                          externalYacht.updated_at
                                                      )
                                                        ? 'Needs update'
                                                        : 'Imported'
                                                    : 'Not imported'
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="11">
                                        <Row>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Asking Price"
                                                    value={
                                                        externalYacht.asking_price
                                                            ? `${normalizePrice(
                                                                  externalYacht.asking_price
                                                              )} ${externalYacht?.asking_price_currency?.toUpperCase()}`
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="NAME"
                                                    value={
                                                        externalYacht.name
                                                            ? externalYacht.name
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Previous Name"
                                                    value={
                                                        externalYacht.previous_names
                                                            ? externalYacht.previous_names
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Year built"
                                                    value={
                                                        externalYacht.year_built
                                                            ? externalYacht.year_built
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Year Refit"
                                                    value={
                                                        externalYacht.year_refit
                                                            ? externalYacht.year_refit
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Builder"
                                                    value={
                                                        externalYacht.builder
                                                            ? externalYacht.builder
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Country"
                                                    value={
                                                        externalYacht.country
                                                            ? externalYacht.country
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="City"
                                                    value={
                                                        externalYacht.city
                                                            ? externalYacht.city
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="State"
                                                    value={
                                                        externalYacht.state
                                                            ? externalYacht.state
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Region"
                                                    value={
                                                        externalYacht.region
                                                            ? externalYacht.region
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Flag"
                                                    value={
                                                        externalYacht.flag
                                                            ? externalYacht.flag
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Classification"
                                                    value={
                                                        externalYacht.classification
                                                            ? externalYacht.classification
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Loa"
                                                    value={
                                                        externalYacht.loa
                                                            ? externalYacht.loa
                                                            : '--'
                                                    }
                                                />
                                            </Col>

                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Beam"
                                                    value={
                                                        externalYacht.beam
                                                            ? externalYacht.beam
                                                            : '--'
                                                    }
                                                />
                                            </Col>

                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Draft-max"
                                                    value={
                                                        externalYacht.draft_max
                                                            ? externalYacht.draft_max
                                                            : '--'
                                                    }
                                                />
                                            </Col>

                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Draft-min"
                                                    value={
                                                        externalYacht.draft_min
                                                            ? externalYacht.draft_min
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Speed Max"
                                                    value={
                                                        externalYacht.speed_max
                                                            ? externalYacht.speed_max
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Speed Cruising"
                                                    value={
                                                        externalYacht.speed_cruising
                                                            ? externalYacht.speed_cruising
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Model"
                                                    value={
                                                        externalYacht.model
                                                            ? externalYacht.model
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Type"
                                                    value={
                                                        externalYacht.type
                                                            ? externalYacht.type
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Status"
                                                    value={
                                                        externalYacht.status
                                                            ? dropdowns.status.map(
                                                                  (index) => {
                                                                      if (
                                                                          index.value ===
                                                                          externalYacht.status
                                                                      ) {
                                                                          return index.label;
                                                                      }
                                                                  }
                                                              )
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Status Detail"
                                                    value={
                                                        externalYacht.status_detail
                                                            ? externalYacht.status_detail
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Condition"
                                                    value={
                                                        externalYacht.condition
                                                            ? externalYacht.condition
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="listing Type"
                                                    value={
                                                        externalYacht.listing_type
                                                            ? externalYacht.listing_type
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Engine"
                                                    value={
                                                        externalYacht.engine
                                                            ? externalYacht.engine
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Number Of Engines"
                                                    value={
                                                        externalYacht.num_engines
                                                            ? externalYacht.num_engines
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Engine Manufacturer"
                                                    value={
                                                        externalYacht.engine_manufacturer
                                                            ? externalYacht.engine_manufacturer
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Horsepower"
                                                    value={
                                                        externalYacht.horsepower
                                                            ? externalYacht.horsepower
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Fuel Type"
                                                    value={
                                                        externalYacht.fuel_type
                                                            ? externalYacht.fuel_type
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Tonnage"
                                                    value={
                                                        externalYacht.tonnage
                                                            ? externalYacht.tonnage
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Hull Number"
                                                    value={
                                                        externalYacht.hull_number
                                                            ? externalYacht.hull_number
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Hull Type"
                                                    value={
                                                        externalYacht.hull_type
                                                            ? externalYacht.hull_type
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Hull Material"
                                                    value={
                                                        externalYacht.hull_material
                                                            ? externalYacht.hull_material
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Superstructure Material"
                                                    value={
                                                        externalYacht.superstructure_material
                                                            ? externalYacht.superstructure_material
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Deck Material"
                                                    value={
                                                        externalYacht.deck_material
                                                            ? externalYacht.deck_material
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Naval Architect"
                                                    value={
                                                        externalYacht.naval_architect
                                                            ? externalYacht.naval_architect
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Cabins"
                                                    value={
                                                        externalYacht.cabins
                                                            ? externalYacht.cabins
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Guests"
                                                    value={
                                                        externalYacht.guests
                                                            ? externalYacht.guests
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Crew"
                                                    value={
                                                        externalYacht.crew
                                                            ? externalYacht.crew
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Interior Designer"
                                                    value={
                                                        externalYacht.interior_designer
                                                            ? externalYacht.interior_designer
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Exterior Designer"
                                                    value={
                                                        externalYacht.exterior_designer
                                                            ? externalYacht.exterior_designer
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Vessel ID"
                                                    value={
                                                        externalYacht.vessel_id
                                                            ? externalYacht.vessel_id
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Davinci Yacht Id"
                                                    value={
                                                        externalYacht.davinci_yacht_id
                                                            ? externalYacht.davinci_yacht_id
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Company Id"
                                                    value={
                                                        externalYacht.CompanyID
                                                            ? externalYacht.CompanyID
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Created At"
                                                    value={
                                                        externalYacht.created_at
                                                            ? moment(
                                                                  externalYacht.created_at
                                                              ).format(
                                                                  'YYYY-MM-DD HH:mm'
                                                              )
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Updated At"
                                                    value={
                                                        externalYacht.updated_at
                                                            ? moment(
                                                                  externalYacht.updated_at
                                                              ).format(
                                                                  'YYYY-MM-DD HH:mm'
                                                              )
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                            <Col lg="2" className="mb-2">
                                                <LabeledInfo
                                                    label="Imported At"
                                                    value={
                                                        externalYacht.ImportedAt
                                                            ? moment(
                                                                  externalYacht.ImportedAt
                                                              ).format(
                                                                  'YYYY-MM-DD HH:mm'
                                                              )
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="11" className="mb-3">
                                                <LabeledInfo
                                                    label="Info"
                                                    value={
                                                        externalYacht.info
                                                            ? externalYacht.info
                                                            : '--'
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <SimpleModal
                            header={modalheader}
                            body={modalbody}
                            modalOpen={modalOpen}
                            backdrop={'static'}
                        />

                        {photos && photos.length > 0 ? (
                            <Row>
                                <PhotoGallery
                                    galleryPhotos={photos}
                                    closeButton={false}
                                    label="PHOTO GALLERY"
                                    edit={false}
                                    loading={false}
                                    galleryType="grid"
                                />
                            </Row>
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
};
export default ExternalYachtView;
