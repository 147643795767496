import React from 'react';
import { connect } from 'react-redux';
import { change, initialize } from 'redux-form';

import IndexYachtPresenter from './IndexYachtPresenter';
import SaveSearch from '../../../yachts/search/SaveSearch';
import CustomerSelection from '../../../yachts/search/CustomerSelection';
import { resetDropdownLocations } from '../../locations/redux/_actions';
import ManageYachtListModal from '../../yacht-lists/components/ManageYachtListModal';

// Redux
import { fetchManageYachts } from '../../../yachts/yacht/_actions';
import {
    fetchYachts,
    initSearch,
    fetchSelectYachts,
    fetchDeleteSelectedYachts,
    fetchSelectedYachts,
    changeYachtSelection,
    changeAllYachtsSelection,
    reorderYacht,
    updateYachtStatus,
    fetchExternalProviders
} from '../../../yachts/search/_actions';
import { fetchSavedSearch } from '../../../saved_searches/_actions';

import {
    fetchYachtList,
    initYachtList
} from '../../yacht-lists/redux/_actions';

// Helpers
import _find from 'lodash/find';
import _equal from 'lodash/isEqual';
import {
    YACHT_CHARTER_TABLE_PREFIX,
    INITIAL_SALES_BROKER_FILTERS,
    YACHT_TYPE_CHARTER
} from '../../../infrastructure/helpers/globals';
import { isAdmin, isBrokerOrLess } from '../../../infrastructure/helpers/roles';

class IndexYachtContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            menu: [],
            view_type: props.match.params.viewType,
            is_dropdown_filters_open: false,
            has_search: false,
            is_mobile_view: false,
            is_sidebar_open: false,
            initial_filters: {},
            charter_rates_locations_dates: {},
            modal_header: null,
            modal_body: null,
            is_modal_open: false,
            active_sidebar: 'filters',
            by_yacht_list: false,
            initialOrderDir: null,
            initialOrderField: null,
            forSale: true,
            externalProviders: []
        };
    }

    componentDidMount() {
        this.redirectBrokerToCorrectPath();
        this.onComponentUpdate(this.state.view_type);
        this.updateMobileView();

        if (isBrokerOrLess(this.props.user_role)) {
            this.onSetActiveSidebar('selection');
        }

        window.addEventListener('resize', this.updateMobileView.bind(this));
    }

    UNSAFE_componentWillReceiveProps(next_props) {
        const next_view_type = next_props.match.params.viewType;
        if (next_view_type !== this.state.view_type) {
            this.setState(
                {
                    has_search: false,
                    by_yacht_list: false
                },
                () => this.onComponentUpdate(next_view_type)
            );
        }
    }

    updateMobileView() {
        this.setState({
            is_mobile_view: window.innerWidth < 1200 ? true : false
        });
    }

    redirectBrokerToCorrectPath() {
        if (
            this.props.user_role === 'broker_sales' &&
            this.props.match.path === '/yachts/charter'
        ) {
            return this.props.history.push('/yachts');
        } else if (
            this.props.user_role === 'broker_charter' &&
            this.props.match.path === '/yachts/sales'
        ) {
            return this.props.history.push('/yachts/charter');
        }
    }

    onComponentUpdate(view_type, selectedSearchID) {
        let initial_filters = isAdmin(this.props.user_role)
            ? {}
            : view_type === YACHT_TYPE_CHARTER
            ? {}
            : INITIAL_SALES_BROKER_FILTERS;

        this.setState({
            loading: true,
            view_type,
            initial_filters
        });
        let params = {
            page: 1,
            page_size: 15,
            order_dir: 'DESC',
            order_field: 'loa'
        };

        if (isBrokerOrLess(this.props.user_role)) {
            params.query = Object.assign({}, initial_filters);
        }

        if (view_type === YACHT_TYPE_CHARTER) {
            params.chartering = 1;
            delete params.sales;
            if (params.query) {
                params.query['_prefix'] = YACHT_CHARTER_TABLE_PREFIX;
            }
        } else {
            params.sales = 1;
            delete params.chartering;
        }

        let URLSearch = new URLSearchParams(this.props.location.search);
        const yachtListID = URLSearch.get('yacht-list-id');
        const searchID = selectedSearchID
            ? selectedSearchID
            : URLSearch.get('search');

        if (yachtListID) {
            this.setState({ by_yacht_list: true });

            this.props.fetchYachtList(yachtListID).then((data) => {
                params.query = JSON.parse(data.dynamic_query);
                params.order_field = data.order_field || 'loa';
                params.order_dir = data.order_dir || 'DESC';
                this.setState({
                    initialOrderDir: data.order_dir,
                    initialOrderField: data.order_field
                });
                this.props
                    .fetchYachts(params)
                    .then((data) => this.setState({ loading: false }));
                this.props.fetchSelectedYachts({}, view_type);
            });
        } else if (searchID) {
            this.props.fetchSavedSearch({ id: searchID }).then((data) => {
                if (data) {
                    params.query = data.query;
                    if (view_type === YACHT_TYPE_CHARTER) {
                        params.query['_prefix'] = YACHT_CHARTER_TABLE_PREFIX;
                    }
                    this.setState({ loading: true });
                    this.props.fetchYachts(params).then((data) => {
                        if (data) {
                            this.props.fetchSelectedYachts({}, view_type);
                        }
                        this.setState({ loading: false });
                    });

                    this.setState({ has_search: true });
                }
            });
        } else if (this.props.location.search) {
            let valid = true;
            if (this.props.location.search.includes('?broker')) {
                //broker search
                let brokerId = this.props.location.search.replace(
                    '?broker=',
                    ''
                );
                params.query = {
                    has: {
                        brokers: brokerId
                    }
                };
                // this.props.fetchBroker({ id: brokerId });
            } else if (this.props.location.search.includes('?charterBroker')) {
                //broker search
                let brokerId = this.props.location.search.replace(
                    '?charterBroker=',
                    ''
                );
                params.query = {
                    has: {
                        charterBrokers: brokerId
                    }
                };
            } else if (this.props.location.search.includes('?company')) {
                //company search
                let company = this.props.location.search.replace(
                    '?company=',
                    ''
                );
                params.query = {
                    has: {
                        companies: [decodeURI(company)]
                    }
                };
            } else {
                valid = false;
            }

            if (valid) {
                this.setState({ loading: true });
                this.props.fetchYachts(params).then((data) => {
                    this.setState({ loading: false });
                });
                this.props.fetchSelectedYachts({}, view_type);
                this.setState({ has_search: true });
            }
        } else {
            this.props.initSearch();
            this.props.initYachtList();
            this.setState({ loading: true });
            this.props.fetchExternalProviders(params).then((data) => {
                this.setState({ externalProviders: data });
            });
            this.props.fetchYachts(params).then((data) => {
                this.setState({ loading: false });
            });
            this.props.fetchSelectedYachts({}, view_type);
        }
        this.props.resetForm();
    }

    _getWindowSearch() {
        let search = this.props.location.search;

        if (
            search.includes('?search=') ||
            search.includes('?broker=') ||
            search.includes('?company=')
        ) {
            return search;
        }

        return '';
    }

    hasSearch() {
        return this.props.location.search;
    }

    onUpdateSearchParameters({
        search,
        previous_names,
        for_sale,
        vesselStatuses
    }) {
        let { initial_filters } = this.state;
        if (for_sale) {
            initial_filters.equal = {
                status: 'for_sale'
            };
        } else if (initial_filters.equal && initial_filters.equal.status) {
            delete initial_filters.equal.status;
        }

        // initial_filters.include.status_detail = vesselStatuses.map(
        //     (s) => s.value
        // );

        this.setState(
            {
                search,
                previous_names,
                initial_filters,
                forSale: for_sale,
                include_status_detail: vesselStatuses.map((s) => s.value)
            },
            () =>
                this.onTableChange(
                    'search',
                    search,
                    previous_names,
                    this.state.forSale,
                    this.state.include_status_detail
                )
        );
    }

    onTableChange(
        key,
        value,
        previous_names,
        for_sale,
        vesselStatuses,
        search,
        is_public,
        is_archived,
        curation_level
    ) {
        let params = {
            page: 1,
            page_size: 15,
            order_dir: 'DESC',
            order_field: 'loa'
        };
        const pagination = Object.assign({}, this.props.yachts.pagination);
        const sorting = Object.assign({}, this.props.yachts.sorting);
        if (key === 'search') {
            params.search = value;
        }
        if (previous_names) {
            params.search_previous_names = true;
        }
        if (for_sale) {
            if (!params.query) {
                params.query = {};
            }
            params.query.equal = { status: 'for_sale' };
        }

        if (key === 'page') {
            params.search = search;
        }

        params.page =
            key === 'page'
                ? value
                : pagination.page === 0 || key === 'search'
                ? 1
                : pagination.page;
        params.page_size = key === 'page_size' ? value : pagination.page_size;
        params.order_dir = sorting.order_dir;
        params.order_field = sorting.order_field;

        if (key === 'page_size') {
            params.search = search;
            params.page = 1;
        }

        if (key === 'asking_price' || key === 'loa' || key === 'year_built') {
            params.order_dir = value;
            params.order_field = key;
        }

        if (Object.keys(this.props.yachts.query).length > 0) {
            params.query = this.props.yachts.query;
            //empty the query.include and fill it with the selected vessel status
            if (vesselStatuses) {
                params.query.include = { status_detail: [] };
                params.query.include = { status_detail: vesselStatuses };
            }
            //check if the for_sale checkbox is clicked and if not delete the query part for_sale
            if (!for_sale) {
                delete params.query.equal;
            } else {
                params.query.equal = { status: 'for_sale' };
            }
        } else {
            if (this.state.initial_filters) {
                params.query = this.state.initial_filters;
            }
        }
        if (this.state.view_type === YACHT_TYPE_CHARTER) {
            params.chartering = 1;
            delete params.sales;
            if (params.query) {
                params.query['_prefix'] = YACHT_CHARTER_TABLE_PREFIX;
            }
            if (is_public && is_public.charter_public === 1) {
                if (is_public.sales_public) {
                    delete params.query.equal;
                }
                params.query.equal = { charter_public: 1 };
            }
            if (is_archived === 1) {
                params.query.equal = { is_archived: 1 };
            }
            if (curation_level) {
                params.query.equal = { curation_level: curation_level };
            }
        } else {
            if (is_public && is_public.sales_public === 1) {
                if (is_public.charter_public) {
                    delete params.query.equal;
                }
                params.query.equal = { sales_public: 1 };
            }
            if (is_archived === 1) {
                params.query.equal = { is_archived: 1 };
            }
            if (curation_level) {
                params.query.equal = { curation_level: curation_level };
            }
            params.sales = 1;
            delete params.chartering;
        }

        this.setState({ loading: true });
        this.props.fetchYachts(params).then((data) => {
            this.setState({ loading: false });
        });
        if (key === 'page') window.scrollTo(0, 0);
    }

    onRefetchYachts(query, params) {
        let par = params
            ? params
            : {
                  page: 1,
                  order_dir: this.props.yachts.sorting.order_dir,
                  order_field: this.props.yachts.sorting.order_field,
                  page_size: this.props.yachts.pagination.page_size,
                  search: this.props.yachts.sorting.search
              };

        if (this.state.previous_names) par.search_previous_names = true;
        if (this.state.include_status_detail)
            par.include_status_detail = this.state.include_status_detail;

        if (query) {
            par.query = query;

            if (this.state.view_type === YACHT_TYPE_CHARTER) {
                par.chartering = 1;
                par.query['_prefix'] = YACHT_CHARTER_TABLE_PREFIX;
            }
        }
        this.setState({ loading: true, params: par });

        return this.props.fetchYachts(par).then((data) => {
            this.setState({ loading: false });
            if (data && query) {
                if (!_equal(query, this.state.initial_filters))
                    this.setState({ has_search: true });
                this.setState({ is_dropdown_filters_open: false });
            }
        });
    }

    onSelectionReorder(result) {
        if (
            !result ||
            !result.destination ||
            result.destination.index === result.source.index
        )
            return;
        const yachtId = result.draggableId,
            index = result.destination.index,
            oldIndex = result.source.index,
            selection = this.props.yachts.selectedYachts.selection,
            newOrder = selection[index]['_pivot_sort_index'];

        const params = {
            currentSelectionIndex: oldIndex,
            newSelectionIndex: index,
            newOrder,
            yachtId
        };

        // send change to server
        this.props.reorderYacht(params, this.state.view_type).then(() => {
            this.props.fetchSelectedYachts({}, this.state.view_type);
        });
    }

    onToggleSidebar(event, type) {
        this.setState({
            is_sidebar_open: !this.state.is_sidebar_open
        });
        this.onSetActiveSidebar(type);
    }

    onClearAllSelected() {
        this.props.changeAllYachtsSelection(
            { selection: 0 },
            this.state.view_type
        );
        let params = {};

        if (this.view_type === YACHT_TYPE_CHARTER) params.chartering = 1;

        this.props.fetchDeleteSelectedYachts(params, this.state.view_type);
    }

    onSelectYacht(event, yacht) {
        if (yacht.id) {
            this.props.changeYachtSelection(
                { yachtId: yacht.id },
                this.state.view_type
            );
        } else {
            this.props.changeAllYachtsSelection(
                { selection: 1 },
                this.state.view_type
            );
        }
        let par = {};

        if (yacht && yacht.id) {
            par.yacht_id = yacht.id;
        } else {
            par.search_id = this.props.location.search.replace('?search=', '');
        }

        if (!par.yacht_id && par.search_id === '') {
            delete par.search_id;
            par.yachtList = [];
            this.props.yachts.yachts?.map((yacht) => {
                par.yachtList.push(yacht.id);
            });
            if (this.state.view_type === YACHT_TYPE_CHARTER) {
                par.chartering = 1;
            } else {
                par.sales = 1;
            }
        }

        if (
            (yacht.id &&
                yacht.id !== undefined &&
                !_find(this.props.yachts.selectedYachts.selection, [
                    'id',
                    yacht.id
                ])) ||
            yacht === true
        ) {
            this.props.fetchSelectYachts(par, this.state.view_type);
        } else {
            this.props.fetchDeleteSelectedYachts(par, this.state.view_type);
        }
    }

    onFavouriteYacht(value) {
        let params = { id: value.id, favourite: value.favourite === 1 ? 0 : 1 };

        this.props.fetchManageYachts(params).then((data) => {
            if (data) {
                if (Object.keys(this.props.yachts.query).length > 0) {
                    let query = this.props.yachts.query;
                    this.onRefetchYachts(query);
                } else {
                    this.onRefetchYachts();
                }
            }
        });
    }

    onYachtStatusChange(yacht, params) {
        this.props
            .updateYachtStatus({ yacht, params })
            .then(() => this.onRefetchYachts());
    }

    onClearSearch(view_type) {
        if (view_type) {
            this.props.history.push('/yachts/view/' + view_type);
        }
        this.props.resetDropdownLocations(true);
        this.setState({
            has_search: false,
            by_yacht_list: false
        });
        // ! this resetForm doesn't work
        this.props.resetForm();
        this.resetFormFields();

        this.props.initYachtList();
        this.props.initSearch();

        this.props.history.push(this.props.match.url);
        //this.onComponentUpdate(this.state.view_type);
        if (this.props.user_role === 'broker') {
            window.location.reload();
        }
    }

    resetFormFields = () => {
        const fields = [
            { name: 'equal_status', value: 'for_sale' },
            {
                name: 'include_status_detail',
                value: ['in_operation', 'in_build']
            }
        ];

        fields.map((val) => {
            this.props.changeFieldValue(val.name, val.value);
            return val;
        });
    };

    onToggleSearch = () => {
        this.setState({
            is_dropdown_filters_open: !this.state.is_dropdown_filters_open
        });
    };

    setCharterRatesLocationsDates(from, to) {
        this.setState({
            charter_rates_locations_dates: { date_from: from, date_to: to }
        });
    }

    onModalToggle() {
        this.setState({ is_modal_open: !this.state.is_modal_open });
        window.location.reload();
    }

    onSavedSeachModalToggle = () => {
        this.setState({
            is_modal_open: true,
            modal_body: (
                <SaveSearch
                    prefix={YACHT_CHARTER_TABLE_PREFIX}
                    type={this.state.view_type}
                    toggle={this.onModalToggle.bind(this)}
                />
            )
        });
    };

    onCreateOfferToggle() {
        this.setState({
            modal_header: 'Make new proposal!',
            is_modal_open: true,
            modal_body: (
                <CustomerSelection
                    history={this.props.history}
                    toggle={this.onModalToggle.bind(this)}
                    type="proposal"
                    default_selection={this.state.view_type}
                    charter_rates_locations_dates={
                        this.state.charter_rates_locations_dates
                    }
                />
            )
        });
    }

    onCreateYachtListToggle() {
        const type = this.state.by_yacht_list ? 'existing' : 'new';
        const selection_type = this.state.by_yacht_list ? 'dynamic' : 'static';
        const sorting = Object.assign({}, this.props.yachts.sorting);

        this.setState({
            modal_header: 'Create/ Update Yacht List!',
            is_modal_open: true,
            modal_body: (
                <ManageYachtListModal
                    history={this.props.history}
                    type={type}
                    selection_type={selection_type}
                    sorting={sorting}
                    view_type={this.state.view_type}
                />
            )
        });
    }

    onSetActiveSidebar(type) {
        this.setState({ active_sidebar: type });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateMobileView);
    }

    render() {
        return (
            <IndexYachtPresenter
                match={this.props.match}
                history={this.props.history}
                menu={this.state.menu}
                loading={this.state.loading}
                view_type={this.state.view_type}
                user_role={this.props.user_role}
                yachts={this.props.yachts}
                has_search={this.state.has_search}
                is_dropdown_filters_open={this.state.is_dropdown_filters_open}
                is_mobile_view={this.state.is_mobile_view}
                is_sidebar_open={this.state.is_sidebar_open}
                initial_filters={this.state.initial_filters}
                modal_header={this.state.modal_header}
                modal_body={this.state.modal_body}
                is_modal_open={this.state.is_modal_open}
                active_sidebar={this.state.active_sidebar}
                initialOrderDir={this.state.initialOrderDir}
                initialOrderField={this.state.initialOrderField}
                onToggleSidebar={this.onToggleSidebar.bind(this)}
                onClearAllSelected={this.onClearAllSelected.bind(this)}
                onTableChange={this.onTableChange.bind(this)}
                onSelectYacht={this.onSelectYacht.bind(this)}
                onFavouriteYacht={this.onFavouriteYacht.bind(this)}
                onYachtStatusChange={this.onYachtStatusChange.bind(this)}
                onClearSearch={this.onClearSearch.bind(this)}
                onToggleSearch={this.onToggleSearch}
                onUpdateSearchParameters={this.onUpdateSearchParameters.bind(
                    this
                )}
                setCharterRatesLocationsDates={this.setCharterRatesLocationsDates.bind(
                    this
                )}
                onRefetchYachts={this.onRefetchYachts.bind(this)}
                onModalToggle={this.onModalToggle.bind(this)}
                onSavedSeachModalToggle={this.onSavedSeachModalToggle}
                onCreateOfferToggle={this.onCreateOfferToggle.bind(this)}
                onCreateYachtListToggle={this.onCreateYachtListToggle.bind(
                    this
                )}
                onSetActiveSidebar={this.onSetActiveSidebar.bind(this)}
                onSelectionReorder={this.onSelectionReorder.bind(this)}
                onComponentUpdate={this.onComponentUpdate.bind(this)}
                externalProviders={this.state.externalProviders}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user_role: state.auth.user_role,
        yachts: state.yachts.search
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchYachts: (e) => dispatch(fetchYachts(e)),
        fetchExternalProviders: (e) => dispatch(fetchExternalProviders(e)),
        changeYachtSelection: (params, type) =>
            dispatch(changeYachtSelection(params, type)),
        fetchSavedSearch: (params) => dispatch(fetchSavedSearch(params)),
        fetchSelectYachts: (params, type) =>
            dispatch(fetchSelectYachts(params, type)),
        fetchDeleteSelectedYachts: (params, type) =>
            dispatch(fetchDeleteSelectedYachts(params, type)),
        resetDropdownLocations: (should_reset) =>
            dispatch(resetDropdownLocations(should_reset)),
        resetForm: () => dispatch(initialize('yachts_filters')),
        reorderYacht: (e, type) => dispatch(reorderYacht(e, type)),
        initSearch: (e) => dispatch(initSearch(e)),
        updateYachtStatus: (params) => dispatch(updateYachtStatus(params)),
        changeFieldValue: (field, value) =>
            dispatch(change('yachts_filters', field, value)),
        changeAllYachtsSelection: (params, type) =>
            dispatch(changeAllYachtsSelection(params, type)),
        fetchManageYachts: (params) => dispatch(fetchManageYachts(params)),
        fetchSelectedYachts: (params, type) =>
            dispatch(fetchSelectedYachts(params, type)),
        fetchYachtList: (id) => dispatch(fetchYachtList(id)),
        initYachtList: () => dispatch(initYachtList())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndexYachtContainer);
