import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';

import Header from '../../common/header/Header';
import RenderField from '../../common/components/RenderField';
import RenderCheckbox from '../../common/components/RenderCheckbox';
import UploadField from '../../common/components/UploadField';
import SimpleModal from '../../common/components/SimpleModal';
import RenderRedactor from '../../common/components/RenderRedactor';
import ReactTelInput from 'react-telephone-input';

import { isBoolean } from '../../helpers/validate';
import { editUser, fetchUser } from './_actions';
import ChangePasswordModal from './ChangePasswordModal';

import admin from '../../assets/svgs/admin.svg';
import broker from '../../assets/svgs/broker.svg';
import moderator from '../../assets/svgs/moderator.svg';
import owner from '../../assets/svgs/owner.svg';
import alerts from 'locales/alerts';
import { warning } from 'react-notification-system-redux';
import {
    isOwner,
    isAdmin,
    isModerator,
    isBrokerOrLess
} from 'infrastructure/helpers/roles';

const form = reduxForm({
    form: 'edit_user',
    enableReinitialize: true
});

let def = 'en';

class EditUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: {
                parent: isOwner(props.user_role)
                    ? owner
                    : isAdmin(props.user_role)
                    ? admin
                    : isModerator(props.user_role)
                    ? moderator
                    : isBrokerOrLess(props.user_role)
                    ? broker
                    : broker,
                items: [
                    <NavLink exact to="/user">
                        My account
                    </NavLink>
                ]
            },
            modalHeader: 'Change password',
            modalOpen: false,
            mobileNumber: '',
            modalBody: (
                <ChangePasswordModal toggle={this.toggleModal.bind(this)} />
            ),
            loaded: false
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        this.props.fetchUser().then((data) => {
            if (data) {
                this.setState({ loaded: true });
            }
        });
    }

    handleFormSubmit(formProps) {
        let params = Object.assign({}, formProps);
        if (document.getElementById('signature_id').value) {
            params.signature = document.getElementById('signature_id').value;
        } else {
            params.signature = '';
        }
        if (this.state.mobileNumber) {
            params.phone = this.state.mobileNumber;
        }
        this.props.editUser(params).then((data) => {
            if (data?.hubspot_response?.error?.message) {
                setTimeout(() => {
                    this.props.showWarning({
                        title: alerts.error.hubspotWarning[def].title,
                        message: [
                            data.hubspot_response.error.message + ' ',
                            data.hubspot_response.error.properties.map(
                                function (prop) {
                                    return prop + ', ';
                                }
                            )
                        ],
                        autoDismiss: 5
                    });
                }, 1200);
            }
        });
    }

    handleInputChange(telNumber, selectedCountry) {
        this.setState({ mobileNumber: telNumber });
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    render() {
        return (
            <div className="content-inner-wrapper">
                <Header menu={this.state.menu} history={this.props.history} />
                {this.state.loaded && (
                    <div className="content-inner">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <form
                                    className="list-form"
                                    onSubmit={this.props.handleSubmit(
                                        this.handleFormSubmit.bind(this)
                                    )}
                                >
                                    <div className="row">
                                        <div className="col-7">
                                            <UploadField
                                                name="photo"
                                                box={true}
                                                fileLabelName="UPLOAD IMAGE"
                                                outerLabel="UPLOAD IMAGE"
                                                className="round-photo"
                                                type="single"
                                                formName="edit_user"
                                                initPhoto={
                                                    this.props.user_photo
                                                }
                                                disabled={true}
                                            />
                                            <span
                                                className={
                                                    'mb-3 role bg-' +
                                                    this.props.user_role
                                                }
                                            >
                                                {this.props.user_role.replace(
                                                    /_/g,
                                                    ' '
                                                )}
                                            </span>
                                            <Field
                                                name="first_name"
                                                type="text"
                                                placeholder="FIRST NAME"
                                                component={RenderField}
                                                readOnly={true}
                                            />

                                            <Field
                                                name="last_name"
                                                type="text"
                                                placeholder="LAST NAME"
                                                component={RenderField}
                                                readOnly={true}
                                            />
                                            <Field
                                                name="name"
                                                type="text"
                                                placeholder="FULL NAME"
                                                component={RenderField}
                                                readOnly={true}
                                            />
                                            <Field
                                                name="position"
                                                type="text"
                                                placeholder="POSITION"
                                                component={RenderField}
                                                readOnly={true}
                                            />

                                            <Field
                                                name="url"
                                                type="text"
                                                placeholder="PROFILE URL"
                                                component={RenderField}
                                                readOnly={true}
                                            />
                                            <Field
                                                name="email"
                                                type="text"
                                                placeholder="EMAIL"
                                                component={RenderField}
                                                readOnly={true}
                                            />
                                            <Field
                                                name="company"
                                                type="text"
                                                placeholder="COMPANY"
                                                component={RenderField}
                                                readOnly={true}
                                            />

                                            <Field
                                                name="country"
                                                type="text"
                                                placeholder="COUNTRY"
                                                component={RenderField}
                                                readOnly={true}
                                            />
                                            <div className="custom-select-wrapper floating-label-wrapper react-select-wrapper">
                                                <label className="Select-placeholder float top phone-with-area-code__label">
                                                    MOBILE NUMBER
                                                </label>

                                                <ReactTelInput
                                                    name="phone"
                                                    defaultCountry="us"
                                                    onChange={this.handleInputChange.bind(
                                                        this
                                                    )}
                                                    initialValue={
                                                        this.props.initialValues
                                                            .phone
                                                    }
                                                    className="phone-with-area-code"
                                                />
                                            </div>
                                            <Field
                                                name="whatsapp"
                                                normalize={isBoolean}
                                                type="checkbox"
                                                label="IS WHAT'S APP"
                                                component={RenderCheckbox}
                                                className="mb-3"
                                            />
                                            <RenderRedactor
                                                label="EMAIL SIGNATURE"
                                                name="signature"
                                                id="signature_id"
                                                withImage={true}
                                                withFontSize={true}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2 mt-2">
                                        <Button
                                            type="button"
                                            className="btn btn-empty btn-link"
                                            onClick={this.toggleModal}
                                        >
                                            CHANGE PASSWORD?
                                        </Button>
                                    </div>
                                    <div className="row">
                                        <div className="col-7 text-right">
                                            <Button
                                                type="submit"
                                                color="primary"
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                type="button"
                                                color="secondary"
                                                onClick={
                                                    this.props.history.goBack
                                                }
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
                <SimpleModal
                    header={this.state.modalHeader}
                    body={this.state.modalBody}
                    modalOpen={this.state.modalOpen}
                    toggle={this.toggleModal}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        editUser: (e) => dispatch(editUser(e)),
        fetchUser: (e) => dispatch(fetchUser(e)),
        showWarning: (options) => dispatch(warning(options))
    };
}

function mapStateToProps(state) {
    return {
        user_role: state.auth.user_role,
        user_photo: state.user.photo,
        initialValues: {
            name: state.user.name,
            first_name: state.user.first_name,
            last_name: state.user.last_name,
            email: state.user.email,
            position: state.user.position,
            phone: state.user.phone,
            whatsapp: state.user.whatsapp,
            photo: state.user.photo,
            signature: state.user.signature
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(form(EditUser));
