import React from 'react';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';

// Fields

import RenderRedactor from '../../../common/components/RenderRedactor';
import RenderField from '../../../common/components/RenderField';
import { Field } from 'redux-form';

// SVG

class RenderMoreInfoFields extends React.Component {
    render() {
        const {
            meta: { error, submitFailed },
            read_only,
            viewType
        } = this.props;
        return (
            <div className="row">
                {!read_only && (
                    <div className="col-12">
                        {submitFailed && error && <span>{error}</span>}
                    </div>
                )}

                <Col xs="12">
                    <Row className="row">
                        <Col xs="11">
                            <Field
                                name={`presentation.${viewType}MoreInfo.translations.title`}
                                type="text"
                                placeholder="TITLE"
                                component={RenderField}
                                className="mt-2"
                                readOnly={read_only}
                                maxLength="150"
                                extraInfo="Characters limit are 150"
                            />
                        </Col>

                        <Col xs="11" className="mb-1">
                            <RenderRedactor
                                name={`presentation.${viewType}MoreInfo.translations.text`}
                                label="FULL DESCRIPTION"
                                onChange={(text) => {
                                    this.props.changeFieldValue(
                                        `presentation.${viewType}MoreInfo.translations.text`,
                                        text
                                    );
                                }}
                                readOnly={this.props.read_only}
                                disabled={this.props.read_only}
                                id={`more-info-text`}
                            />
                        </Col>
                        <Col xs="1" className="mt-1">
                            {!read_only && (
                                <div>
                                    <div
                                        className="btn btn-indication"
                                        id="full-description-info"
                                    >
                                        i
                                    </div>
                                    <UncontrolledTooltip
                                        placement="right"
                                        target="full-description-info"
                                    >
                                        Further information about the yacht.
                                        Appears at the bottom of the page.
                                    </UncontrolledTooltip>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Col>
            </div>
        );
    }
}

export default RenderMoreInfoFields;
