import React, { Component } from 'react';
import { FieldArray } from 'redux-form';

import RenderFieldArray from '../../common/components/RenderFieldArray';
import ToggleCollapse from '../../common/components/ToggleCollapse';

class SectionAddress extends Component {
  render() {
    return (
      <div className="col-12 addresses-wrapper">
        <ToggleCollapse
          body={
            <div className="row">
              <div className="col-12 mt-2">
                <FieldArray
                  name="addresses"
                  component={RenderFieldArray}
                  type="text"
                  readOnly={this.props.readOnly}
                  changeFieldValue={this.props.changeFieldValue}
                  initialValues={this.props.initialValues}
                  className="col"
                  initFirst={true}
                  primary={{
                    value: this.props.primary_address,
                    name: 'primary_address'
                  }}
                  props={{
                    customFields: [
                      {
                        name: 'address',
                        type: 'text',
                        placeholder: 'ADDRESS',
                        className: 'col-7'
                      },
                      {
                        name: 'type',
                        type: 'select',
                        placeholder: 'TYPE',
                        options: [
                          { value: 'home', label: 'Home' },
                          { value: 'work', label: 'Work' }
                        ],
                        className: 'col-4 offset-1 transparent'
                      },
                      {
                        name: 'city',
                        type: 'text',
                        placeholder: 'CITY',
                        className: 'col-4'
                      },
                      {
                        name: 'zip',
                        type: 'text',
                        label: this.props.readOnly ? 'ZIP' : '',
                        placeholder: 'ZIP',
                        className: 'col-3'
                      },
                      {
                        name: 'country',
                        type: 'select',
                        placeholder: 'COUNTRY',
                        options: this.props.countries,
                        className: 'col-4 offset-1 transparent'
                      },
                      {
                        name: 'notes',
                        type: 'text',
                        placeholder: 'NOTE',
                        className: 'col-7 mb-1'
                      },
                      {
                        name: 'state',
                        type: 'select',
                        placeholder: 'STATE',
                        options: this.props.states,
                        className: 'col-4 offset-1 transparent',
                        readOnlyField: 'country',
                        isNotDisabledFor: 230
                      },
                      {
                        name: 'primary_address',
                        label: 'Primary Address',
                        groupName: 'primary_address_current',
                        type: 'radio',
                        className: 'col-4',
                        fieldClass: 'mb-0'
                      }
                    ]
                  }}
                />
              </div>
            </div>
          }
          header="ADDRESS"
        />
      </div>
    );
  }
}
export default SectionAddress;
