import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Row, Col } from 'reactstrap';

import YachtsList from './YachtsList';

import RenderReactSelect from '../../../common/components/RenderReactSelect';
import RenderRadio from '../../../common/components/RenderRadio';

import { required } from '../../../helpers/validate';

import {
    fetchSelectedYachts,
    changeYachtSelectionInSelected,
    deselectAllYachtInBrokerSelection
} from '../../../yachts/search/_actions';

import {
    fetchSavedSearches,
    fetchSavedSearchResults
} from '../../../saved_searches/_actions';

/**
 * This component is used to show yacht selection to different types of forms.
 * It has 3 mode on which it depends what yachts to show. Be carefull when you are passing props
 * initial_yachts component should mount after
 *
 *
 */
class YachtsSelection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search_type: '', // The current yacht selection  pool
            search_id: '',
            selected_yachts: [],
            yachts: {
                pagination: {
                    page: 1,
                    page_size: 100,
                    page_count: 1,
                    total: 0
                },
                yachts: []
            }
        };
    }

    componentDidMount() {
        this.setState({ search_type: this.props.search_type });
        this.props.fetchSavedSearches({
            format: 'dropdown'
        });

        if (this.props.search_type !== 'current_search') {
            this.manageSalesOrCharterUserSelection();
        } else {
            let params = {
                page: 1,
                order_dir: this.props.yachts.sorting.order_dir,
                order_field: this.props.yachts.sorting.order_field,
                page_size: this.props.yachts.pagination.page_size,
                id: this.props.search_id
            };

            this.setState({ search_id: this.props.search_id });
            this.props.fetchSavedSearchResults(params).then((data) => {
                if (this.props.mode !== 'view') {
                    this.mapUserSelectedYachtToState(this.props.yachts.yachts);
                    this.setState({ yachts: this.props.yachts });
                }

                if (this.props.mode === 'view') {
                    this.mapUserSelectedYachtToState(this.props.initial_yachts);
                    this.setState({
                        yachts: {
                            pagination: {
                                page: 1,
                                page_size: 100,
                                page_count: 1,
                                total: this.props.initial_yachts.length
                            },
                            yachts: this.props.initial_yachts
                        }
                    });
                }
            });
        }
    }

    manageSalesOrCharterUserSelection() {
        this.props
            .fetchSelectedYachts({ full_info: true }, this.props.type)
            .then((data) => {
                if (this.props.mode === 'create') {
                    this.mapUserSelectedYachtToState(data.selection);
                    this.setState({
                        yachts: this.props.userSelectionYachts
                    });
                }

                if (this.props.mode === 'edit') {
                    this.mapUserSelectedYachtToState(this.props.initial_yachts);

                    let initial_yachts_ids = this.props.initial_yachts.map(
                        (yacht) => yacht.id
                    );

                    let merged_yachts = [
                        ...this.props.initial_yachts,
                        ...this.props.userSelectionYachts.yachts.reduce(
                            (result, yacht) => {
                                if (!initial_yachts_ids.includes(yacht.id)) {
                                    let new_yacht = Object.assign(yacht);
                                    new_yacht.selected = 0;
                                    result.push(new_yacht);
                                }

                                return result;
                            },
                            []
                        )
                    ];

                    this.setState({
                        yachts: {
                            pagination: {
                                ...this.props.userSelectionYachts.pagination,
                                total: merged_yachts.length
                            },
                            yachts: merged_yachts
                        }
                    });
                }

                if (this.props.mode === 'view') {
                    this.mapUserSelectedYachtToState(this.props.initial_yachts);
                    this.setState({
                        yachts: {
                            pagination: {
                                page: 1,
                                page_size: 100,
                                page_count: 1,
                                total: this.props.initial_yachts.length
                            },
                            yachts: this.props.initial_yachts
                        }
                    });
                }
            });
    }

    onCheckboxChange(event, yacht) {
        // In order to found out if current yacht is in selected array
        // we get all ids, if we found the id then we remove it else we add it to
        // state.selected_yachts

        let selected_yachts_ids = this.state.selected_yachts.map(
            (selected_yacht) => selected_yacht.yacht_id
        );

        let selected_yachts;
        if (selected_yachts_ids.includes(yacht.id)) {
            selected_yachts = this.state.selected_yachts.filter(
                (y) => y.yacht_id !== yacht.id
            );
        } else {
            selected_yachts = [
                ...this.state.selected_yachts,
                {
                    yacht_id: yacht.id,
                    sort_index: yacht._pivot_sort_index
                }
            ];
        }

        this.setSelectedYachts(selected_yachts);

        let yachts = this.state.yachts.yachts.map((y) => {
            if (y.id === yacht.id) {
                y.selected = y.selected === 0 ? 1 : 0;
            }

            return y;
        });

        this.setState({
            yachts: {
                ...this.state.yachts,
                yachts: yachts
            }
        });
    }

    onReorder(oldIndex, newIndex) {
        let reordered = [...this.state.selected_yachts];
        const temp = { ...reordered[newIndex] };
        reordered.splice(newIndex, 1);
        reordered.splice(oldIndex, 0, temp);
        this.setSelectedYachts(reordered);

        this.changeOrderOnYachts(oldIndex, newIndex);
    }

    changeOrderOnYachts(oldIndex, newIndex) {
        let selectedYachts = this.state.yachts.yachts.filter(
            (y) => y.selected === 1
        );
        let unselectedYachts = this.state.yachts.yachts.filter(
            (y) => y.selected === 0
        );

        let reordered = [...selectedYachts];
        const temp = { ...reordered[newIndex] };
        reordered.splice(newIndex, 1);
        reordered.splice(oldIndex, 0, temp);

        this.setState({
            yachts: {
                ...this.state.yachts,
                yachts: [...reordered, ...unselectedYachts]
            }
        });
    }

    onTableChange(key, new_value) {
        let params = {
            page: key !== 'page' ? 1 : new_value,
            order_dir:
                key !== 'page' && key !== 'page_size' && key !== 'search'
                    ? new_value
                    : this.props.yachts.sorting.order_dir,
            order_field:
                key !== 'page' && key !== 'page_size' && key !== 'search'
                    ? key
                    : this.props.yachts.sorting.order_field,
            page_size:
                key === 'page_size'
                    ? new_value
                    : this.props.yachts.pagination.page_size,
            id: this.state.search_id
        };
        this.props.fetchSavedSearchResults(params).then((data) => {
            if (this.props.mode !== 'view') {
                this.mapUserSelectedYachtToState(
                    this.props.yachts.yachts,
                    true
                );
                this.setState({ yachts: this.props.yachts });
            }
        });
    }

    onSearchTypeChange(event, new_value) {
        this.setState({ selected_yachts: [] });
        this.setState({ search_type: new_value });

        if (new_value === 'current_search') {
            this.props.fetchSavedSearches({
                format: 'dropdown'
            });
        } else {
            this.manageSalesOrCharterUserSelection();
        }
    }

    getSavedSearchResults(event, new_value) {
        if (new_value && new_value !== '') {
            let params = {
                page: 1,
                order_dir: this.props.yachts.sorting.order_dir,
                order_field: this.props.yachts.sorting.order_field,
                page_size: this.props.yachts.pagination.page_size,
                id: new_value
            };
            this.setState({ search_id: new_value });
            this.props.fetchSavedSearchResults(params).then((data) => {
                if (this.props.mode !== 'view') {
                    this.mapUserSelectedYachtToState(
                        this.props.yachts.yachts,
                        true
                    );
                    this.setState({ yachts: this.props.yachts });
                }
            });
        }
    }

    mapUserSelectedYachtToState(yachts, should_merge) {
        let selected_yachts = yachts.map((yacht, index) => {
            return {
                yacht_id: yacht.id,
                sort_index: yacht._pivot_sort_index
                    ? yacht._pivot_sort_index
                    : index
            };
        });

        if (should_merge) {
            selected_yachts = [
                ...selected_yachts,
                ...this.state.selected_yachts
            ];
        }

        this.setSelectedYachts(selected_yachts);
    }

    removeYachtFromUserSelectedYachts(yacht_id) {
        let selected_yachts = this.state.selected_yachts.reduce(
            (result, yacht) => {
                if (yacht.yacht_id !== yacht_id) {
                    result.push(yacht);
                }

                return result;
            },
            []
        );

        this.setSelectedYachts(selected_yachts);
    }

    /**
     * Given an array of yachts save's them to state
     * and inform's parent component for the change
     *
     * @param {array} yachts The selected yachts to save to the state
     */
    setSelectedYachts(yachts) {
        this.setState({ selected_yachts: yachts });
        this.props.onChange(yachts);
    }

    render() {
        return (
            <Row>
                {this.props.type === 'sales' && this.props.showSearchOptions && (
                    <Col xs="12" md="4">
                        <Field
                            name="search_type"
                            label="FROM SALES SELECTION"
                            group="search_type_current"
                            className="mb-2"
                            type="radio"
                            readOnly={this.props.read_only}
                            disabled={this.props.read_only}
                            value="current_sales"
                            component={RenderRadio}
                            onChange={this.onSearchTypeChange.bind(this)}
                        />
                    </Col>
                )}

                {this.props.type === 'charter' && this.props.showSearchOptions && (
                    <Col xs="12" md="4">
                        <Field
                            name="search_type"
                            label="FROM CHARTER SELECTION"
                            group="search_type_current"
                            className="mb-2"
                            type="radio"
                            readOnly={this.props.read_only}
                            disabled={this.props.read_only}
                            value="current_charter"
                            component={RenderRadio}
                            onChange={this.onSearchTypeChange.bind(this)}
                        />
                    </Col>
                )}

                {this.props.should_show_saved_searches &&
                    this.props.showSearchOptions && (
                        <Col xs="12" md="4">
                            <Field
                                name="search_type"
                                label="SAVED SEARCH"
                                group="search_type_search"
                                className="mb-2"
                                readOnly={this.props.read_only}
                                disabled={this.props.read_only}
                                type="radio"
                                value="current_search"
                                component={RenderRadio}
                                onChange={this.onSearchTypeChange.bind(this)}
                            />
                        </Col>
                    )}

                {this.state.search_type === 'current_search' && (
                    <Col xs="12">
                        <Field
                            name="search_id"
                            placeholder="MY SAVED SEARCHES"
                            validate={[required]}
                            component={RenderReactSelect}
                            onChange={this.getSavedSearchResults.bind(this)}
                            options={this.props.saved_searches}
                            disabled={this.props.read_only}
                        />
                    </Col>
                )}

                <Col xs="12">
                    <YachtsList
                        allCheckbox={!this.props.read_only}
                        selected={this.state.selected_yachts.length}
                        shouldUseSelected={true}
                        sortable={this.props.sortable}
                        selectAll={!this.props.read_only}
                        userRole={this.props.user_role}
                        yachts={this.state.yachts}
                        hideActions={true}
                        hidePagination={
                            this.state.search_type === 'current_sales' ||
                            this.state.search_type === 'current_charter'
                                ? true
                                : false
                        }
                        hideExtraFilter={true}
                        viewType={this.props.type}
                        onCheckboxChange={this.onCheckboxChange.bind(this)}
                        onReorder={this.onReorder.bind(this)}
                        tableChange={this.onTableChange.bind(this)}
                        onChangeExternalUrl={this.props.onChangeExternalUrl}
                        readOnly={this.props.read_only}
                    />
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state, own_props) => {
    let initial_yachts_ids = own_props.initial_yachts.map((y) => y.id);

    return {
        user_role: state.auth.user_role,
        initOffer: state.customers.offers.initOffer,
        saved_searches: state.saved_searches.dropdown.map((val) => {
            return { value: val.id, label: val.name };
        }),
        yachts: {
            pagination: state.saved_searches.results.pagination,
            sorting: state.saved_searches.results.sorting,
            yachts:
                own_props.mode === 'edit'
                    ? state.saved_searches.results.yachts.map((yacht) => {
                          let y = Object.assign({}, yacht);

                          if (initial_yachts_ids.includes(yacht.id)) {
                              y.selected = 1;
                          }

                          return y;
                      })
                    : state.saved_searches.results.yachts.map((yacht) => {
                          let y = Object.assign({}, yacht);

                          if (yacht.selected === undefined) {
                              y.selected = 1;
                          }

                          return y;
                      })
        },
        userSelectionYachts: {
            pagination: {
                page: 1,
                page_size: 100,
                page_count: 1,
                total: state.yachts.search.selectedYachts.selection.length
            },
            yachts:
                own_props.mode === 'edit'
                    ? state.yachts.search.selectedYachts.selection
                    : state.yachts.search.selectedYachts.selection.map(
                          (yacht) => {
                              let y = Object.assign(yacht);

                              if (yacht.selected === undefined) {
                                  y.selected = 1;
                              }

                              return y;
                          }
                      )
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSelectedYachts: (e, type) =>
            dispatch(fetchSelectedYachts(e, type)),
        changeYachtSelectionInSelected: (e, type) =>
            dispatch(changeYachtSelectionInSelected(e, type)),
        fetchSavedSearches: (e) => dispatch(fetchSavedSearches(e)),
        fetchSavedSearchResults: (e) => dispatch(fetchSavedSearchResults(e)),
        deselectAllYachtInBrokerSelection: (e) =>
            dispatch(deselectAllYachtInBrokerSelection(e))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(YachtsSelection);
