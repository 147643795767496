export const INIT_LOCATION = 'init_location',
    FETCH_LOCATIONS = 'fetch_locations',
    FETCH_LOCATIONS_SUCCESS = 'fetch_locations_success',
    FETCH_LOCATIONS_ERROR = 'fetch_locations_error',
    FETCH_DROPDOWN_LOCATIONS = 'fetch_dropdown_locations',
    FETCH_DROPDOWN_LOCATIONS_SUCCESS = 'fetch_dropdown_locations_success',
    FETCH_DROPDOWN_LOCATIONS_ERROR = 'fetch_dropdown_locations_error',
    CREATE_LOCATION = 'create_location',
    CREATE_LOCATION_SUCCESS = 'create_location_success',
    CREATE_LOCATION_ERROR = 'create_location_error',
    UPDATE_LOCATION = 'update_location',
    UPDATE_LOCATION_SUCCESS = 'update_location_success',
    UPDATE_LOCATION_ERROR = 'update_location_error',
    DELETE_LOCATION = 'delete_location',
    DELETE_LOCATION_SUCCESS = 'delete_location_success',
    DELETE_LOCATION_ERROR = 'delete_location_error',
    FETCH_LOCATION = 'fetch_location',
    FETCH_LOCATION_SUCCESS = 'fetch_location_success',
    FETCH_LOCATION_ERROR = 'fetch_location_error',
    FETCH_LOCATIONS_HIERARCHY = 'fetch_locations_hierarchy',
    FETCH_LOCATIONS_HIERARCHY_SUCCESS = 'fetch_locations_hierarchy_success',
    FETCH_LOCATIONS_HIERARCHY_ERROR = 'fetch_locations_hierarchy_error',
    RESET_DROPDOWN_LOCATIONS = 'reset_dropdown_locations';
