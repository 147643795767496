import axios from 'axios';

import {
  FETCH_PUBLISH_NEWS,
  FETCH_PUBLISH_NEWS_SUCCESS,
  FETCH_PUBLISH_NEWS_FAIL,
  FETCH_NEWS,
  FETCH_NEWS_SUCCESS,
  FETCH_NEWS_FAIL,
  FETCH_SINGLE_NEWS,
  FETCH_SINGLE_NEWS_SUCCESS,
  FETCH_SINGLE_NEWS_FAIL,
  FETCH_EDIT_SINGLE_NEWS,
  FETCH_EDIT_SINGLE_NEWS_SUCCESS,
  FETCH_EDIT_SINGLE_NEWS_FAIL,
  FETCH_DELETE_NEWS,
  FETCH_DELETE_NEWS_SUCCESS,
  FETCH_DELETE_NEWS_FAIL,
  REORDER_NEWS,
  REORDER_NEWS_SUCCESS,
  REORDER_NEWS_FAIL
} from './_types';

import { API_URL } from '../helpers/variables';
import { errorHandler, successHandler } from '../helpers/alerts';
import { authConfig } from '../helpers/authConfig';

export function fetchNews(params = 0) {
  return dispatch => {
    dispatch({ type: FETCH_NEWS });

    const config = {
      headers: authConfig(),
      params: params
    };
    return axios
      .get(API_URL + '/news', config)
      .then(response => {
        dispatch({ type: FETCH_NEWS_SUCCESS, payload: response.data });
        return response.data;
      })
      .catch(error => {
        dispatch({ type: FETCH_NEWS_FAIL });
        errorHandler(dispatch, error.response);
      });
  };
}

export function fetchSingleNews(params) {
  return dispatch => {
    dispatch({ type: FETCH_SINGLE_NEWS });

    const config = {
      headers: authConfig()
    };
    return axios
      .get(API_URL + '/news/' + params.id, config)
      .then(response => {
        dispatch({ type: FETCH_SINGLE_NEWS_SUCCESS, payload: response.data });
        return response.data;
      })
      .catch(error => {
        dispatch({ type: FETCH_SINGLE_NEWS_FAIL });
        errorHandler(dispatch, error.response);
      });
  };
}

export function fetchEditNews(params) {
  return dispatch => {
    dispatch({ type: FETCH_EDIT_SINGLE_NEWS });

    const config = {
      headers: authConfig()
    };

    return axios
      .put(API_URL + '/news/' + params.id, params, config)
      .then(response => {
        dispatch({ type: FETCH_EDIT_SINGLE_NEWS_SUCCESS, payload: response.data });
        successHandler(dispatch, 'editNews');

        return response.data;
      })
      .catch(error => {
        dispatch({ type: FETCH_EDIT_SINGLE_NEWS_FAIL });
        errorHandler(dispatch, error.response);
      });
  };
}

export function fetchDeleteNews(params) {
  return dispatch => {
    dispatch({ type: FETCH_DELETE_NEWS });

    const config = {
      headers: authConfig()
    };

    return axios
      .delete(API_URL + '/news/' + params, config)
      .then(response => {
        dispatch({ type: FETCH_DELETE_NEWS_SUCCESS, postId: params });
        successHandler(dispatch, 'deleteNews');
        return response.data;
      })
      .catch(error => {
        dispatch({ type: FETCH_DELETE_NEWS_FAIL });
        errorHandler(dispatch, error.response);
      });
  };
}

export function fetchPublishNews(params) {
  return dispatch => {
    dispatch({ type: FETCH_PUBLISH_NEWS });

    const config = { headers: authConfig() };

    return axios
      .post(API_URL + '/news', params, config)
      .then(response => {
        dispatch({ type: FETCH_PUBLISH_NEWS_SUCCESS, payload: response.data });
        successHandler(dispatch, 'publishNews');

        return response.data;
      })
      .catch(error => {
        errorHandler(dispatch, error.response, FETCH_PUBLISH_NEWS_FAIL);
      });
  };
}

export function reOrderNews(params) {
  return dispatch => {
    dispatch({ type: REORDER_NEWS });

    const config = {
      headers: authConfig()
    };
    return axios
      .put(API_URL + '/news/' + params.id + '/reorder', params, config)
      .then(response => {
        dispatch({ type: REORDER_NEWS_SUCCESS, payload: response.data });
        successHandler(dispatch, 'reOrderNews');
        return response.data;
      })
      .catch(error => {
        dispatch({ type: REORDER_NEWS_FAIL });
        errorHandler(dispatch, error.response, REORDER_NEWS_FAIL);
      });
  };
}
