import React, { Component } from 'react';

import FormBasics from './FormBasics';
import FormTypes from './FormTypes';

import ToggleCollapse from '../../../common/components/ToggleCollapse';

import ResultView from '../ResultView';

import { accomodation } from './_typeAccomodation';
import { confidential, initialValuesConfidential } from './_typeConfidential';
import { equipmentAncillary } from './_typeEquipmentAncillary';
import { equipmentAv } from './_typeEquipmentAv';
import { equipmentCommunication } from './_typeEquipmentCommunication';
import { equipmentDeck } from './_typeEquipmentDeck';
import { equipmentDomestic } from './_typeEquipmentDomestic';
import { equipmentNavigation } from './_typeEquipmentNavigation';
import { equipmentOffice } from './_typeEquipmentOffice';
import { equipmentSecurity } from './_typeEquipmentSecurity';
import { equipmentSpecial } from './_typeEquipmentSpecial';
import { equipmentWatersport } from './_typeEquipmentWatersport';
import { machinery } from './_typeMachinery';
import { machineryAux } from './_typeMachineryAux';
import { tanks, initialValuesTanks } from './_typeTanks';
import { refits } from './_typeRefits';

import { generic } from './_basicGeneric';

class Specs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: false,
            loaded: false,
            forms: [
                { type: 'generic', label: 'Generic', fields: generic },
                { type: 'machinery', label: 'Machinery', fields: machinery },
                {
                    type: 'machinery_aux',
                    label: 'Generators & Auxiliary Systems',
                    fields: machineryAux
                },
                {
                    type: 'tanks',
                    label: 'Tank Capacities',
                    fields: tanks,
                    initialValues: initialValuesTanks
                },
                {
                    type: 'equipment_ancillary',
                    label: 'Ancillary Equipment',
                    fields: equipmentAncillary
                },
                {
                    type: 'equipment_security',
                    label: 'Safety & Security Equipment',
                    fields: equipmentSecurity
                },
                {
                    type: 'equipment_communication',
                    label: 'Communication Equipment',
                    fields: equipmentCommunication
                },
                {
                    type: 'equipment_navigation',
                    label: 'Navigation Equipment',
                    fields: equipmentNavigation
                },
                {
                    type: 'equipment_deck',
                    label: 'Deck Equipment',
                    fields: equipmentDeck
                },
                {
                    type: 'equipment_av',
                    label: 'Audio / Visual Entertainment',
                    fields: equipmentAv
                },
                {
                    type: 'equipment_office',
                    label: 'Office Equipment',
                    fields: equipmentOffice
                },
                {
                    type: 'equipment_domestic',
                    label: 'Galley & Domestic Equipment',
                    fields: equipmentDomestic
                },
                {
                    type: 'equipment_special',
                    label: 'Special Equipment & Other Features',
                    fields: equipmentSpecial
                },
                {
                    type: 'equipment_watersport',
                    label: 'Tenders & Watersport Equipment',
                    fields: equipmentWatersport
                },
                {
                    type: 'accomodation',
                    label: 'Accomodation',
                    fields: accomodation
                },
                { type: 'refits', label: 'Refit Summary', fields: refits },
                {
                    type: 'confidential',
                    label: 'Confidential',
                    fields: confidential,
                    initialValues: initialValuesConfidential
                }
            ]
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            JSON.stringify(nextProps) !== JSON.stringify(this.props) ||
            JSON.stringify(nextState) !== JSON.stringify(this.state)
        );
    }

    render() {
        return (
            <div className="yacht-forms-wrapper">
                <div className="row">
                    <div
                        className={
                            'col-12 yacht-forms-inner ' + this.props.view
                        }
                    >
                        {this.props.view === 'view' && (
                            <div className="yacht-view-wrapper">
                                <ResultView
                                    viewType={this.props.viewType}
                                    userRole={this.props.user_role}
                                    view={true}
                                    readOnly={!this.state.readOnly}
                                    yacht={this.props.initYacht}
                                    favouriteYacht={this.props.favouriteYacht.bind(
                                        this
                                    )}
                                    selectYacht={this.props.selectYacht.bind(
                                        this
                                    )}
                                    selectedYachts={this.props.selectedYachts}
                                    selected={this.props.initYacht.selected}
                                    onYachtStatusChange={
                                        this.props.onYachtStatusChange
                                    }
                                />
                            </div>
                        )}

                        {(this.props.view === 'add' ||
                            (this.props.view === 'edit' &&
                                this.props.pathType !== 'more')) && (
                            <FormBasics
                                cancelForm={this.props.cancelForm}
                                initYacht={this.props.initYacht}
                                history={this.props.history}
                                viewType={this.props.viewType}
                                locationTree={this.props.locationTree}
                            />
                        )}

                        {((this.props.view === 'edit' &&
                            this.props.pathType === 'more') ||
                            this.props.view === 'view') &&
                            this.props.initYacht &&
                            this.props.initYacht.id &&
                            this.state.forms.map((form, i) => {
                                return (
                                    <ToggleCollapse
                                        key={i}
                                        body={
                                            <FormTypes
                                                key={i}
                                                form={`yacht_type_${i}`}
                                                fields={form.fields}
                                                type={form.type}
                                                view={this.props.view}
                                                initYacht={this.props.initYacht}
                                                initialValues={
                                                    form.initialValues !==
                                                        undefined &&
                                                    Object.keys(
                                                        form.initialValues
                                                    ).length > 0
                                                        ? form.initialValues
                                                        : {}
                                                }
                                            />
                                        }
                                        header={form.label}
                                        onToggle={(e) =>
                                            this.props.onToggle(e, form)
                                        }
                                    />
                                );
                            })}
                    </div>
                </div>
            </div>
        );
    }
}

export default Specs;
