import axios from 'axios';

import {
    FETCH_PRICE_REDUCTIONS,
    FETCH_PRICE_REDUCTIONS_SUCCESS,
    FETCH_PRICE_REDUCTIONS_FAIL,
    FETCH_PRICES,
    FETCH_PRICES_SUCCESS,
    FETCH_PRICES_FAIL,
    FETCH_YACHTS_SOLD,
    FETCH_YACHTS_SOLD_SUCCESS,
    FETCH_YACHTS_SOLD_FAIL,
    FETCH_REPORTS_YACHTS,
    FETCH_REPORTS_YACHTS_SUCCESS,
    FETCH_REPORTS_YACHTS_FAIL,
    FETCH_YEARLY_SALES,
    FETCH_YEARLY_SALES_SUCCESS,
    FETCH_YEARLY_SALES_FAIL,
    HIDE_YACHT_SOLD,
    HIDE_YACHT_SOLD_SUCCESS,
    HIDE_YACHT_SOLD_FAIL,
    HIDE_PRICE_REDUCTION,
    HIDE_PRICE_REDUCTION_SUCCESS,
    HIDE_PRICE_REDUCTION_FAIL,
    HIDE_NEWEST_YACHT,
    HIDE_NEWEST_YACHT_SUCCESS,
    HIDE_NEWEST_YACHT_FAIL
} from './_types';

import { API_URL } from '../helpers/variables';
import { errorHandler } from '../helpers/alerts';
import { authConfig } from '../helpers/authConfig';
import $ from 'jquery';

export function fetchPriceReductions(params) {
    return dispatch => {
        dispatch({ type: FETCH_PRICE_REDUCTIONS });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/reports/reductions', config)
            .then(response => {
                dispatch({
                    type: FETCH_PRICE_REDUCTIONS_SUCCESS,
                    payload: {
                        data: response.data.reductions,
                        distribution: response.data.distribution,
                        params
                    }
                });
                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_PRICE_REDUCTIONS_FAIL });
                errorHandler(dispatch, error.response);
            });
    };
}

export function fetchPrices(params) {
    return dispatch => {
        dispatch({ type: FETCH_PRICES });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/reports/prices', config)
            .then(response => {
                dispatch({
                    type: FETCH_PRICES_SUCCESS,
                    payload: {
                        data: response.data,
                        type: params.type
                    }
                });
                return true;
            })
            .catch(error => {
                dispatch({ type: FETCH_PRICES_FAIL });
                errorHandler(dispatch, error.response);
            });
    };
}

export function fetchYachtsSold(params) {
    return dispatch => {
        dispatch({ type: FETCH_YACHTS_SOLD });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/reports/sold', config)
            .then(response => {
                dispatch({
                    type: FETCH_YACHTS_SOLD_SUCCESS,
                    payload: {
                        data: response.data,
                        params
                    }
                });
                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_YACHTS_SOLD_FAIL });
                errorHandler(dispatch, error.response);
            });
    };
}

export function fetchReportsYachts(type, params) {
    return dispatch => {
        dispatch({ type: FETCH_REPORTS_YACHTS });

        const config = {
            headers: authConfig(),
            params: params,
            paramsSerializer: function(params) {
                return $.param(params);
            }
        };

        let url = '/yachts';

        if (type === 'top_one_hundred') {
            url = '/reports/largest';
        } else if (type === 'new_entries') {
            url = '/reports/newest';
        }

        return axios
            .get(API_URL + url, config)
            .then(response => {
                dispatch({
                    type: FETCH_REPORTS_YACHTS_SUCCESS,
                    payload: {
                        data:
                            type !== 'private_deals' &&
                            type !== 'yachts_not_for_sale' &&
                            type !== 'new_entries'
                                ? response.data
                                : type !== 'new_entries'
                                ? response.data.yachts
                                : response.data.data,
                        pagination:
                            type !== 'private_deals' &&
                            type !== 'yachts_not_for_sale' &&
                            type !== 'new_entries'
                                ? []
                                : response.data.pagination,
                        type: type,
                        sorting:
                            type === 'new_entries'
                                ? params
                                    ? params.order_field
                                    : ''
                                : '',
                        order:
                            type === 'new_entries'
                                ? params
                                    ? params.order_dir
                                    : ''
                                : ''
                    }
                });
                return response.data;
            })
            .catch(error => {
                console.log('ERROR', error);
                dispatch({ type: FETCH_REPORTS_YACHTS_FAIL });
                errorHandler(dispatch, error.response);
            });
    };
}

export function getYearlySales(params) {
    return dispatch => {
        dispatch({ type: FETCH_YEARLY_SALES });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/reports/sales', config)
            .then(response => {
                dispatch({
                    type: FETCH_YEARLY_SALES_SUCCESS,
                    payload: response.data
                });
                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_YEARLY_SALES_FAIL });
                errorHandler(dispatch, error.response);
            });
    };
}

// hide yachts from sold table (dashboard)
export function hideSoldYachts(params) {
    return dispatch => {
        dispatch({ type: HIDE_YACHT_SOLD, payload: params.entry_id });

        const config = {
            headers: authConfig()
        };
        if (params.entry_id) {
            return axios
                .put(
                    API_URL + '/reports/toggle/' + params.entry_id,
                    params,
                    config
                )
                .then(response => {
                    dispatch({
                        type: HIDE_YACHT_SOLD_SUCCESS,
                        payload: { ...params }
                    });
                    return response.data;
                })
                .catch(error => {
                    dispatch({ type: HIDE_YACHT_SOLD_FAIL });
                    errorHandler(dispatch, error.response);
                });
        }
    };
}

//hide newest yachts (dashboard)
export function hideNewestYachts(params) {
    return dispatch => {
        dispatch({ type: HIDE_NEWEST_YACHT, payload: params.entry_id });

        const config = {
            headers: authConfig()
        };
        if (params.entry_id) {
            return axios
                .put(
                    API_URL + '/reports/toggle/' + params.entry_id,
                    {
                        target: params.target,
                        broker_visible: params.broker_visible
                    },
                    config
                )
                .then(response => {
                    dispatch({
                        type: HIDE_NEWEST_YACHT_SUCCESS,
                        payload: { ...params }
                    });
                    return response.data;
                })
                .catch(error => {
                    dispatch({ type: HIDE_NEWEST_YACHT_FAIL });
                    errorHandler(dispatch, error.response);
                });
        }
    };
}

//hide yachts from reduction table (dashboard)
export function hideReductionYachts(params) {
    return dispatch => {
        dispatch({ type: HIDE_PRICE_REDUCTION, payload: params.entry_id });

        const config = {
            headers: authConfig()
        };
        if (params.entry_id) {
            return axios
                .put(
                    API_URL + '/reports/toggle/' + params.entry_id,
                    {
                        target: params.target,
                        broker_visible: params.broker_visible
                    },
                    config
                )
                .then(response => {
                    dispatch({
                        type: HIDE_PRICE_REDUCTION_SUCCESS,
                        payload: { ...params }
                    });
                    return response.data;
                })
                .catch(error => {
                    dispatch({ type: HIDE_PRICE_REDUCTION_FAIL });
                    errorHandler(dispatch, error.response);
                });
        }
    };
}
