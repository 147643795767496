import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';
import moment from 'moment';

import Header from '../../../common/header/Header';

import SimpleTable from '../../../common/components/SimpleTable';
import SimpleModal from '../../../common/components/SimpleModal';

import add from '../../../assets/svgs/add.svg';
import edit from '../../../assets/svgs/edit.svg';
import deleteSVG from '../../../assets/svgs/delete.svg';

const IndexOffersPresenter = props => {
    return (
        <div className="content-inner-wrapper">
            <Header menu={props.menu} history={props.history} />

            <div className="content-inner">
                <div className="row">
                    <div className="col-12 col-xl-9">
                        <div className="table-actions d-flex align-items-center">
                            {props.customer.sales === 1 && (
                                <div className="d-flex align-items-center">
                                    <button
                                        type="button"
                                        className="btn btn-main btn-action d-flex align-items-center justify-content-center"
                                        onClick={() =>
                                            props.history.push(
                                                '/clients/proposals/sales/' +
                                                    props.match.params.id +
                                                    '/create'
                                            )
                                        }
                                    >
                                        <ReactSVG
                                            path={add}
                                            style={{ height: 12 }}
                                        />
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-empty action-label"
                                        onClick={() =>
                                            props.history.push(
                                                `/clients/proposals/${props.match.params.id}/sales/create`
                                            )
                                        }
                                    >
                                        Create Sales Proposal
                                    </button>
                                </div>
                            )}

                            {props.customer.chartering === 1 && (
                                <div className="d-flex align-items-center">
                                    <button
                                        type="button"
                                        className="btn btn-main btn-action d-flex align-items-center justify-content-center"
                                        onClick={() =>
                                            props.history.push(
                                                '/clients/proposals/charter/' +
                                                    props.match.params.id +
                                                    '/create'
                                            )
                                        }
                                    >
                                        <ReactSVG
                                            path={add}
                                            style={{ height: 12 }}
                                        />
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-empty action-label"
                                        onClick={() =>
                                            props.history.push(
                                                `/clients/proposals/${props.match.params.id}/charter/create`
                                            )
                                        }
                                    >
                                        Create Charter Proposal
                                    </button>
                                </div>
                            )}
                        </div>

                        <SimpleTable
                            tableChange={props.onTableChange.bind(this)}
                            paging={props.offers.pagination}
                            colWidths={[
                                3,
                                '2 text-center',
                                '2 text-left pl-2',
                                2,
                                '1 text-center',
                                '1 text-center',
                                '1 text-center'
                            ]}
                            headers={props.tableHeaders}
                            rowData={props.offers.history.map(value => {
                                return [
                                    <div>
                                        <NavLink
                                            to={`/clients/proposals/${
                                                props.match.params.id
                                            }/${
                                                value.chartering === 0
                                                    ? 'sales'
                                                    : 'charter'
                                            }/view/${value.id}`}
                                        >
                                            {value.name}
                                        </NavLink>
                                    </div>,
                                    <div>
                                        {value.yacht_count !== undefined &&
                                        value.yacht_count != null
                                            ? value.yacht_count
                                            : ''}
                                    </div>,
                                    <div>
                                        {value.sent_at && value.sent_at != null
                                            ? moment(value.sent_at).format(
                                                  'DD/MM/YYYY HH:mm'
                                              )
                                            : 'Draft'}
                                    </div>,
                                    <div>
                                        {value.expires &&
                                            moment(value.expires).format(
                                                'DD/MM/YYYY'
                                            )}
                                    </div>,
                                    <div>
                                        {value.chartering === 0 && (
                                            <span className="customer-cat customer-sales">
                                                S
                                            </span>
                                        )}
                                        {value.chartering === 1 && (
                                            <span className="customer-cat customer-chartering">
                                                C
                                            </span>
                                        )}
                                    </div>,
                                    <div>
                                        <a
                                            className="size-12"
                                            href={props.buildCustomersURL(
                                                value
                                            )}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            CLIENT URL
                                        </a>
                                    </div>,
                                    <ul className="list-unstyled table-td-actions">
                                        {!value.sent_at && (
                                            <li>
                                                <NavLink
                                                    className="btn btn-td-action"
                                                    to={`/clients/proposals/${
                                                        props.match.params.id
                                                    }/${
                                                        value.chartering
                                                            ? 'charter'
                                                            : 'sales'
                                                    }/edit/${value.id}`}
                                                >
                                                    <ReactSVG
                                                        path={edit}
                                                        style={{
                                                            height: 24
                                                        }}
                                                    />
                                                </NavLink>
                                            </li>
                                        )}
                                        <li>
                                            <button
                                                className="btn btn-td-action"
                                                onClick={() =>
                                                    props.onDeleteModal(value)
                                                }
                                            >
                                                <ReactSVG
                                                    path={deleteSVG}
                                                    style={{
                                                        height: 24
                                                    }}
                                                />
                                            </button>
                                        </li>
                                    </ul>
                                ];
                            })}
                        />
                    </div>
                </div>
            </div>

            <SimpleModal
                header={props.modalHeader}
                body={props.modalDelete}
                modalOpen={props.modalOpen}
                toggle={props.onModalToggle}
            />
        </div>
    );
};

export default IndexOffersPresenter;
