import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as notifications } from 'react-notification-system-redux';
// import { routerReducer } from 'react-router-redux';

import auth from './auth/_reducers';
import brokers from './brokers/reducers';
import customers from './customers/reducers';
import destinations from './pages/destinations/redux/_reducer';
import management from './management/reducers';
import saved_searches from './saved_searches/_reducers';
import user from './auth/user/_reducers';
import yachts from './yachts/reducers';
import reports from './reports/_reducers';
import news from './news/_reducers';
import newsletters from './brokers/newsletters/reducers';
import drive from './drive/_reducer';
import salesListings from './sales-listings/reducers';
import locations from './pages/locations/redux/_reducer';
import yacht_locations from './pages/yachts/locations/redux/_reducer';
import yacht_rates from './pages/yachts/rates/redux/_reducer';
import yacht_presentation from './pages/yachts/presentation/redux/_reducer';
import bookings from './pages/bookings/redux/_reducer';
import yacht_lists from './pages/yacht-lists/redux/_reducer';
import newsletter_report from './pages/newsletters/redux/_reducer';
import common from './helpers/common/reducers';
import leads from './pages/leads/redux/_reducer';

import {
    FETCH_INITIAL_STATE,
    FETCH_INITIAL_STATE_SUCCESS,
    FETCH_INITIAL_STATE_ERROR
} from './helpers/initial_state/_types';

import uploadPhoto from './helpers/uploadPhoto/_reducers';
import rates from './helpers/rates/_reducers';

const combinedReducer = combineReducers({
    auth: auth,
    brokers: brokers,
    common: common,
    customers: customers,
    destinations: destinations,
    form: formReducer,
    management: management,
    notifications,
    //routing: routerReducer,
    saved_searches: saved_searches,
    uploadPhoto: uploadPhoto,
    rates: rates,
    user: user,
    yachts: yachts,
    yacht_locations: yacht_locations,
    yacht_rates: yacht_rates,
    yacht_presentation: yacht_presentation,
    reports: reports,
    news: news,
    newsletters: newsletters,
    drive: drive,
    salesListings: salesListings,
    locations: locations,
    bookings: bookings,
    yacht_lists: yacht_lists,
    newsletter_report: newsletter_report,
    leads
});

function rootReducer(state, action) {
    switch (action.type) {
        case FETCH_INITIAL_STATE:
            return state;
        case FETCH_INITIAL_STATE_SUCCESS:
            return {
                ...state,
                user: { ...action.payload.user }
            };
        case FETCH_INITIAL_STATE_ERROR:
            return state;
        default:
            return combinedReducer(state, action);
    }
}

export default rootReducer;
