import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';

export default function InputField({
    options,
    onChange,
    placeholder,
    readOnly,
    isDisabled,
    className,
    isMulti,
    isSearchable,
    isClearable,
    value
}) {
    return (
        <FormGroup
            className={
                'custom-select-wrapper floating-label-wrapper react-select-wrapper' +
                (readOnly !== undefined && readOnly === true
                    ? ' readonly '
                    : ' ') +
                (isDisabled ? ' disabled ' : ' ') +
                (className !== undefined ? className : '')
            }
        >
            <Label className={'float'}>{placeholder}</Label>

            <Select
                placeholder={placeholder}
                className="mt-2 mb-2 external-yachts-filters-dropdown"
                isSearchable={isSearchable}
                options={options}
                onChange={onChange}
                isDisabled={isDisabled}
                isClearable={isClearable}
                // defaultValue={defaultValue}
                value={value}
                multi={isMulti}
            />
        </FormGroup>
    );
}
