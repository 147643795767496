import axios from 'axios';

import { API_URL } from '../helpers/variables';
import { authConfig } from '../helpers/authConfig';
import { errorHandler, successHandler } from '../helpers/alerts';

import {
  FETCH_BROKERS,
  FETCH_BROKERS_SUCCESS,
  FETCH_BROKERS_SEARCH_SUCCESS,
  FETCH_BROKERS_FAIL,
  INIT_BROKER_MODAL,
  FETCH_MANAGE_BROKER,
  FETCH_MANAGE_BROKER_SUCCESS,
  FETCH_MANAGE_BROKER_FAIL,
  FETCH_DELETE_BROKER,
  FETCH_DELETE_BROKER_SUCCESS,
  FETCH_DELETE_BROKER_FAIL,
  FETCH_BROKER,
  FETCH_BROKER_SUCCESS,
  FETCH_BROKER_FAIL
} from './types';

export function fetchBrokers(params) {
  return dispatch => {
    dispatch({ type: FETCH_BROKERS });

    const config = {
      headers: authConfig(),
      params: params
    };

    return axios
      .get(API_URL + '/brokers', config)
      .then(response => {
        if (params.multiple) {
          dispatch({
            type: FETCH_BROKERS_SEARCH_SUCCESS,
            payload: params.search !== '' ? response.data.brokers : []
          });

          return params.search !== '' ? response.data.brokers : [];
        } else {
          dispatch({
            type: FETCH_BROKERS_SUCCESS,
            payload: {
              brokers: response.data.brokers,
              pagination: response.data.pagination,
              sorting: {
                order_dir: params.order_dir,
                order_field: params.order_field,
                page_size: params.page_size
              }
            }
          });
        }
      })
      .catch(error => {
        dispatch({ type: FETCH_BROKERS_FAIL });
        errorHandler(dispatch, error.response, FETCH_BROKERS_FAIL);
      });
  };
}

export function initBrokerModal(broker) {
  return dispatch => {
    dispatch({ type: INIT_BROKER_MODAL, payload: broker });

    return new Promise(function(resolve, reject) {
      resolve('Success!');
    });
  };
}

export function fetchManageBroker(params) {
  return dispatch => {
    dispatch({ type: FETCH_MANAGE_BROKER });

    const config = { headers: authConfig() };

    if (params.id) {
      return axios
        .put(API_URL + '/brokers/' + params.id, params, config)
        .then(response => {
          dispatch({ type: FETCH_MANAGE_BROKER_SUCCESS });
          successHandler(dispatch, 'manageBroker');

          return response.data;
        })
        .catch(error => {
          errorHandler(dispatch, error.response, FETCH_MANAGE_BROKER_FAIL);
        });
    } else {
      return axios
        .post(API_URL + '/brokers', params, config)
        .then(response => {
          dispatch({ type: FETCH_MANAGE_BROKER_SUCCESS });
          successHandler(dispatch, 'manageBroker');

          return response.data;
        })
        .catch(error => {
          errorHandler(dispatch, error.response, FETCH_MANAGE_BROKER_FAIL);
        });
    }
  };
}

export function fetchDeleteBroker(params) {
  return dispatch => {
    dispatch({ type: FETCH_DELETE_BROKER });

    const config = { headers: authConfig() };

    return axios
      .delete(API_URL + '/brokers/' + params.id, config)
      .then(response => {
        dispatch({ type: FETCH_DELETE_BROKER_SUCCESS });
        successHandler(dispatch, 'deleteBroker');

        return response.data;
      })
      .catch(error => {
        errorHandler(dispatch, error.response, FETCH_DELETE_BROKER_FAIL);
      });
  };
}

export function fetchBroker(params) {
  return dispatch => {
    dispatch({ type: FETCH_BROKER });

    const config = { headers: authConfig() };

    return axios
      .get(API_URL + '/brokers/' + params.id, config)
      .then(response => {
        dispatch({ type: FETCH_BROKER_SUCCESS, payload: response.data });
        return response.data;
      })
      .catch(error => {
        errorHandler(dispatch, error.response, FETCH_BROKER_FAIL);
      });
  };
}
