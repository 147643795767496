import {
    UPLOAD_PROFILE_PHOTO,
    UPLOAD_PROFILE_PHOTO_SUCCESS,
    UPLOAD_PROFILE_PHOTO_FAIL
} from './_types';

const INITIAL_STATE = {
    uploadFile: {
        signed: '',
        url: ''
    }
};

export default function UploadPhotoReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UPLOAD_PROFILE_PHOTO:
            return { ...state };
        case UPLOAD_PROFILE_PHOTO_SUCCESS:
            return { ...state, uploadFile: action.payload };
        case UPLOAD_PROFILE_PHOTO_FAIL:
            return { ...state };
        default:
            return state;
    }
}
