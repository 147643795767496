import { dropdowns } from '../../../helpers/variables';

export const generic = [
    {
        name: 'imo',
        value: '',
        type: 'text',
        placeholder: 'IMO NUMBER'
    },
    {
        name: 'model',
        value: '',
        type: 'text',
        placeholder: 'MODEL'
    },
    {
        name: 'listing_type',
        value: '',
        type: 'select',
        label: 'LISTING TYPE',
        placeholder: 'Select',
        options: dropdowns['listing_type']
    },
    {
        name: 'condition',
        value: '',
        type: 'select',
        label: 'CONDITION',
        placeholder: 'Select',
        options: dropdowns['condition']
    },
    {
        name: 'status',
        value: '',
        type: 'select',
        label: 'STATUS',
        placeholder: 'Select',
        options: dropdowns['status']
    },
    {
        name: 'fuel_type',
        value: '',
        type: 'select',
        label: 'FUEL',
        placeholder: 'Select',
        options: dropdowns['fuel_type']
    },
    {
        name: 'hull_number',
        value: '',
        type: 'text',
        placeholder: 'HULL NUMBER'
    },
    {
        name: 'flag',
        type: 'select',
        label: 'FLAG',
        options: dropdowns['flags'],
        placeholder: 'Select Flag'
    },
    {
        name: 'num_engines',
        value: '',
        type: 'select',
        label: 'NUMBER OF ENGINES',
        placeholder: 'Select',
        options: dropdowns['num_engines']
    },
    {
        name: 'engine_manufacturer',
        value: '',
        type: 'text',
        placeholder: 'ENGINE MANUFACTURER'
    },
    {
        name: 'naval_architect',
        value: '',
        type: 'text',
        placeholder: 'NAVAL ARCHITECT'
    },
    {
        name: 'exterior_designer',
        value: '',
        type: 'text',
        placeholder: 'EXTERIOR DESIGNER'
    },
    {
        name: 'interior_designer',
        value: '',
        type: 'text',
        placeholder: 'INTERIOR DESIGNER'
    },
    {
        name: 'superstructure_material',
        value: '',
        type: 'text',
        placeholder: 'SUPERSTRUCTURE MATERIAL'
    },
    {
        name: 'classification',
        value: '',
        type: 'text',
        placeholder: 'CLASSIFICATION'
    },
    {
        name: 'remarks',
        value: '',
        type: 'text',
        placeholder: 'REMARKS'
    },
    {
        name: 'generic_info',
        value: '',
        type: 'textarea',
        placeholder: 'INFO'
    }
];
