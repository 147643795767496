export const FETCH_YACHT_PRESENTATION = 'fetch_yacht_presentation',
    FETCH_YACHT_PRESENTATION_SUCCESS = 'fetch_yacht_presentation_success',
    FETCH_YACHT_PRESENTATION_ERROR = 'fetch_yacht_presentation_error',
    UPDATE_YACHT_PRESENTATION = 'update_yacht_presentation',
    UPDATE_YACHT_PRESENTATION_SUCCESS = 'update_yacht_presentation_succes',
    UPDATE_YACHT_PRESENTATION_ERROR = 'update_yacht_presentation_error',
    FETCH_PRESENTATION_TOYS = 'fetch_presentation_toys',
    FETCH_PRESENTATION_TOYS_SUCCESS = 'fetch_presentation_toys_success',
    FETCH_PRESENTATION_TOYS_FAIL = 'fetch_presentation_toys_fail',
    UPDATE_PRESENTATION_TOYS = 'update_presentation_toys',
    UPDATE_PRESENTATION_TOYS_SUCCESS = 'update_presentation_toys_success',
    UPDATE_PRESENTATION_TOYS_FAIL = 'update_presentation_toys_fail';
