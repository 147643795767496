import React from 'react';
import { Row, Col } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import Header from '../../common/header/Header';
import Instructions from '../../common/components/Instructions';
import SimpleTable from '../../common/components/SimpleTable';
import Loader from '../../common/components/Loader';

const NewsletterCustomersReportPresenter = props => {
    return (
        <div className="content-inner-wrapper">
            <Header menu={props.menu} history={props.history} />

            <div className="content-inner">
                {props.loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <Row>
                        { props.customers_report.length > 0 && (
                            <Col xs="12" className="mb-2">
                                <Instructions title="Please keep in mind that report data is not real time!" />
                            </Col>
                        )}

                        <Col xs="12" xl="9">
                            {props.customers_report.length > 0 && (
                                <SimpleTable
                                    colWidths={[
                                        5,
                                        '3 text-center',
                                        '3 text-center'
                                    ]}
                                    headers={props.table_headers}
                                    hideExtraFilter={true}
                                    rowData={
                                        props.customers_report &&
                                        props.customers_report.map(report => {
                                            return [
                                                <div>
                                                    <NavLink
                                                        to={`/newsletters/${
                                                            props.newsletter_id
                                                        }/report/${
                                                            report._id
                                                        }/email`}
                                                    >
                                                        {report.email}
                                                    </NavLink>
                                                </div>,
                                                <div>{report.opens}</div>,
                                                <div>{report.clicks}</div>
                                            ];
                                        })
                                    }
                                />
                            )}

                            {props.customers_report.length === 0 && (
                                <p
                                    style={{
                                        color: '#41506e'
                                    }}
                                >
                                    The report for this newsletter is not ready yet. Please check again in a few minutes.
                                </p>
                            )}
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
};

export default NewsletterCustomersReportPresenter;
