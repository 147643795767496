import React, { Component } from 'react';

import { FieldArray } from 'redux-form';

import RenderFieldArray from '../../common/components/RenderFieldArray';
import RenderRedactor from '../../common/components/RenderRedactor';

import { email, required } from '../../helpers/validate';

class SectionEmailTelephone extends Component {
    render() {
        return (
            <div className="col-12 email-wrap">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-5 pr-2">
                                <FieldArray
                                    name="emails"
                                    component={RenderFieldArray}
                                    validators={[email]}
                                    readOnly={this.props.readOnly}
                                    className="col"
                                    primary={{
                                        value: this.props.primary_email,
                                        name: 'primary_email'
                                    }}
                                    changeFieldValue={
                                        this.props.changeFieldValue
                                    }
                                    initFirst={true}
                                    props={{
                                        customFields: [
                                            {
                                                name: 'value',
                                                type: 'email',
                                                validators: [required, email],
                                                placeholder: 'EMAIL',
                                                className: 'col-12'
                                            },
                                            {
                                                name: 'primary_email',
                                                label: 'Primary',
                                                checkboxName: 'primary',
                                                groupName:
                                                    'primary_email_check',
                                                type: 'radio',
                                                className: 'col-12'
                                            }
                                        ]
                                    }}
                                />
                            </div>
                            <div className="col-7">
                                <FieldArray
                                    name="phones"
                                    placeholder="ADDITIONAL PHONE"
                                    component={RenderFieldArray}
                                    type="text"
                                    readOnly={this.props.readOnly}
                                    changeFieldValue={
                                        this.props.changeFieldValue
                                    }
                                    initialValues={this.props.initialValues}
                                    className="col"
                                    initFirst={true}
                                    atLeastOne={true}
                                    primary={{
                                        value: this.props.primary_phone,
                                        name: 'primary_phone'
                                    }}
                                    props={{
                                        customFields: [
                                            {
                                                name: 'value',
                                                placeholder: 'TELEPHONE',
                                                type: 'mobile',
                                                className: 'col-8'
                                            },
                                            {
                                                name: 'phone_type',
                                                type: 'select',
                                                placeholder: 'TYPE',
                                                options: [
                                                    {
                                                        value: 'mobile',
                                                        label: 'Mobile'
                                                    },
                                                    {
                                                        value: 'landline',
                                                        label: 'Landline'
                                                    }
                                                ],
                                                className: 'col-4 transparent'
                                            },
                                            {
                                                name: 'primary_phone',
                                                label: 'Primary',
                                                checkboxName: 'primary',
                                                groupName:
                                                    'primary_phone_check',
                                                type: 'radio',
                                                className: 'col-12'
                                            }
                                        ]
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1">
                        <RenderRedactor
                            readOnly={this.props.readOnly}
                            label="NOTES"
                            name="notes"
                            id="customer_notes"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default SectionEmailTelephone;
