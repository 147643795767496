import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { success } from 'react-notification-system-redux';

import ExternalYachtsTable from './ExternalYachtsTable';
import {
    getExternalYachts,
    importExternalYacht,
    externalYachtsSearch
} from 'services/externalYachts';
import { errorHandler } from 'helpers/alerts';
import { NavLink } from 'react-router-dom';
import savedSearches from '../../assets/svgs/saved-searches.svg';
import ExternalYachtsFilters from './ExternalYachtsFilters';
import ExternalYachtConfirmation from 'common/components/Forms/ExternalYachtConfirmation';
import moment from 'moment';

const table_headers = [
    {
        name: 'yacht_name',
        label: 'Name',
        sort: false
    },
    {
        name: 'year_built',
        label: 'Year Built'
    },
    {
        name: 'builder',
        label: 'Builder',
        sort: false
    },
    {
        name: 'loa',
        label: 'Loa/meters ',
        sort: false
    },
    {
        name: 'asking_price_currency',
        label: 'Ask. Price/Currency',
        sort: false
    },
    {
        name: 'daVinci_status',
        label: 'DaVinci Status',
        sort: false
    },
    {
        name: 'action',
        label: 'Action',
        sort: false
    }
];

const menu = {
    parent: savedSearches,
    items: [
        <NavLink exact to={'/external-yachts'}>
            All
        </NavLink>
    ]
};

export default function ExternalYachts(props) {
    const dispatch = useDispatch();
    const user_role = useSelector((state) => state.auth.user_role);
    const history = useHistory();
    const match = useRouteMatch();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [externalYachts, setExternalYachts] = useState([]);
    const [externalYachtsfilters, setExternalYachtsFilters] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [modalheader, setModalHeader] = useState('');
    const [modalbody, setModalBody] = useState();

    useEffect(() => {
        loadExternalYachts();
    }, []);

    const loadExternalYachts = async () => {
        setLoading(true);
        try {
            const { data } = await getExternalYachts();
            setExternalYachts(data);
        } catch (err) {
            errorHandler(dispatch, err?.response || err);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitFilters = async (filters) => {
        //console.log('submit', filters);
        setLoading(true);
        try {
            const { data } = await externalYachtsSearch(filters);
            setExternalYachts(data);
        } catch (err) {
            errorHandler(dispatch, err?.response || err);
        } finally {
            setLoading(false);
            setExternalYachtsFilters(filters);
        }
    };

    const onTableChange = async (key, value) => {
        const current_pagination = externalYachts.pagination;
        let params = {
            page: key === 'page' ? value : 1,
            page_size:
                key === 'page_size' ? value : current_pagination.page_size,
            search: key === 'search' ? value : ''
        };
        let filtersAndPagination = {
            ...externalYachtsfilters,
            page: params.page,
            page_size: params.page_size
        };
        const { data } = await externalYachtsSearch(filtersAndPagination);
        setExternalYachts(data);
    };

    const onImportExternalYacht = async (yacht) => {
        try {
            const { data } = await importExternalYacht(yacht);
            dispatch(
                success({
                    title: 'Success',
                    message: 'Yacht Saved to Davinci'
                })
            );
        } catch (err) {
            errorHandler(dispatch, err?.response || err);
        } finally {
            onModalToggle();
            loadExternalYachts();
        }
    };

    const onModalOpen = (yacht) => {
        //console.log('onModalOpen', modalOpen);
        setModalOpen(!modalOpen);
        setModalHeader(
            yacht.ImportedAt != null
                ? moment(yacht.ImportedAt) < moment(yacht.updated_at)
                    ? 'You are about to update a yacht in DaVinci from external source.'
                    : 'This yacht is already up to date with external source.'
                : 'You are about to import a yacht from external source into DaVinci database'
        );
        setModalBody(
            <ExternalYachtConfirmation
                toggleModal={onModalToggle}
                yacht={yacht}
                action={onImportExternalYacht}
            />
        );
    };

    const onModalToggle = () => {
        setModalOpen(false);
    };

    return (
        <div className="content-inner-wrapper">
            <div className="content-inner">
                <Row>
                    <Col xs="12" xl="8">
                        <ExternalYachtsTable
                            userRole={user_role}
                            history={history}
                            table_headers={table_headers}
                            data={externalYachts}
                            menu={menu}
                            onTableChange={onTableChange}
                            onImportExternalYacht={onImportExternalYacht}
                            modalOpen={modalOpen}
                            modal_header={modalheader}
                            modal_body={modalbody}
                            onModalOpen={onModalOpen}
                            onModalToggle={onModalToggle}
                        />
                    </Col>
                    <Col xs="12" xl="4">
                        <ExternalYachtsFilters onClick={handleSubmitFilters} />
                    </Col>
                </Row>
            </div>
        </div>
    );
}
