import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, FieldArray } from 'redux-form';

import { fetchBrokers } from '../../../brokers/actions';

import RenderBroker from './RenderBroker';

class Brokers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            suggestions: [],
            active: 0
        };
    }

    select(broker, index) {
        this.setState({ active: -1 });

        let name =
            this.props.type === 'sales'
                ? 'has_brokers[0].name'
                : 'has_charterBrokers[0].name';
        let id =
            this.props.type === 'sales'
                ? 'has_brokers[0].id'
                : 'has_charterBrokers[0].id';

        this.props.changeFieldValue(
            name,
            (broker.company !== null ? broker.company : '') +
                (broker.company !== null && broker.name !== null ? '-' : '') +
                (broker.name !== null ? broker.name : '')
        );
        this.props.changeFieldValue(id, broker.id);
    }

    change(index, event, newValue, previousValue) {
        if (!this.props.guests) {
            this.setState({ active: index });
            if (this.props.type === 'charter') {
                this.props
                    .fetchBrokers({
                        search: newValue,
                        multiple: true,
                        type: this.props.type
                    })
                    .then((suggestions) => {
                        this.setState({ suggestions: suggestions });
                    });
            } else {
                this.props
                    .fetchBrokers({ search: newValue, multiple: true })
                    .then((suggestions) => {
                        this.setState({ suggestions: suggestions });
                    });
            }
        }
    }

    render() {
        return (
            <div
                className={
                    'brokers-wrap ' +
                    (this.props.className ? this.props.className : '')
                }
            >
                <FieldArray
                    name={
                        this.props.prefix !== undefined
                            ? this.props.prefix + 'charterBrokers'
                            : 'brokers'
                    }
                    single={this.props.single}
                    component={RenderBroker}
                    props={{
                        suggestions: this.state.suggestions,
                        active: this.state.active,
                        change: this.change.bind(this),
                        form: this.props.form,
                        select: this.select.bind(this),
                        type: this.props.type
                    }}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetchBrokers: (e) => dispatch(fetchBrokers(e)),
        changeFieldValue: (field, value) =>
            dispatch(change(ownProps.form, field, value))
    };
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Brokers);
