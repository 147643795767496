import React, { Component } from 'react';
import { Field } from 'redux-form';

import RenderField from '../../common/components/RenderField';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import Companies from './multiple/Companies';
import RenderCheckbox from '../../common/components/RenderCheckbox';
import Brokers from './multiple/Brokers';
import builders from '../../infrastructure/data/builders';
import { dropdowns } from '../../helpers/variables';

class ExtraFilters extends Component {
    render() {
        const newCountries = this.props.countries.filter(
            (country) => country.name !== 'United States'
        );
        return (
            <div className="yachts-extra-filters-wrapper">
                <div className="row">
                    <div className="col-5 col-lg-4">
                        {this.props.type !== 'sales' && (
                            <Field
                                name="includes_like_builder"
                                placeholder="BUILDER"
                                className=" mt-2 mb-2"
                                options={builders.map((builder) => ({
                                    value: builder,
                                    label: builder
                                }))}
                                multi={true}
                                component={RenderReactSelect}
                                help={'You can select multiple values.'}
                            />
                        )}

                        {this.props.type !== 'sales' && (
                            <Field
                                name="include_hull_type"
                                placeholder="HULL TYPE"
                                options={[
                                    {
                                        value: 'displacement',
                                        label: 'Displacement'
                                    },
                                    {
                                        value: 'semi_displacement',
                                        label: 'Semi displacement'
                                    },
                                    { value: 'open', label: 'Open' },
                                    { value: 'planing', label: 'Planing' }
                                ]}
                                multi={true}
                                component={RenderReactSelect}
                                clearable={true}
                                readOnly={this.props.readOnly}
                                className="mt-2 mb-2"
                            />
                        )}

                        {this.props.type === 'sales' && (
                            <div className="row">
                                <div className="col-6 pr-0">
                                    <Field
                                        name="lte_draft_max"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="Draft Max"
                                        className="mt-2 mb-2"
                                        component={RenderField}
                                    />
                                </div>
                                <div className="col-6 pr-0 pr-lg-1">
                                    <Field
                                        name="draft_max_unit"
                                        label=""
                                        component={RenderReactSelect}
                                        className="mt-2 mb-2"
                                        clearable={false}
                                        options={[
                                            { value: 'meters', label: 'Meter' },
                                            { value: 'feet', label: 'Feet' }
                                        ]}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="row">
                            <div className="col-6">
                                <Field
                                    name={
                                        this.props.type === 'sales'
                                            ? 'between_speed_cruising[0]'
                                            : 'between_charter_speed_cruising[0]'
                                    }
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    placeholder="SPEED FROM"
                                    className="mt-0 mb-2"
                                    component={RenderField}
                                />
                            </div>
                            <div className="col-6 pl-0">
                                <Field
                                    name={
                                        this.props.type === 'sales'
                                            ? 'between_speed_cruising[1]'
                                            : 'between_charter_speed_cruising[1]'
                                    }
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    placeholder="SPEED TO"
                                    className="mt-0 mb-2"
                                    component={RenderField}
                                />
                            </div>
                        </div>

                        {this.props.type !== 'sales' && (
                            <div className="row">
                                <div className="col-12 field-outer-wrapper">
                                    <label className="filter-label font-500 text-uppercase">
                                        Max No of Guests for Quayside Events
                                    </label>
                                </div>
                                <div className="col-6">
                                    <Field
                                        name="gte_guests_static"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="FROM"
                                        className="mt-0 mb-2"
                                        component={RenderField}
                                    />
                                </div>
                                <div className="col-6 pl-0">
                                    <Field
                                        name="lte_guests_static"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        type="number"
                                        placeholder="TO"
                                        className="mt-0 mb-2"
                                        component={RenderField}
                                    />
                                </div>
                            </div>
                        )}

                        {/* <div className="row">
                            <div className="col-4">
                                <Field
                                    name="gte_beam"
                                    parse={value =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    className="mt-0"
                                    placeholder="BEAM Min"
                                    component={RenderField}
                                    readOnly={this.props.readOnly}
                                />
                            </div>
                            <div className="col-4">
                                <Field
                                    name="lte_beam"
                                    parse={value =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    className="mt-0"
                                    placeholder="BEAM Max"
                                    component={RenderField}
                                    readOnly={this.props.readOnly}
                                />
                            </div>
                            <div className="col-4 pl-0">
                                <Field
                                    name="beam_unit"
                                    label=""
                                    component={RenderReactSelect}
                                    className="mt-0"
                                    clearable={false}
                                    options={[
                                        { value: 'meters', label: 'Meter' },
                                        { value: 'feet', label: 'Feet' }
                                    ]}
                                    readOnly={this.props.readOnly}
                                />
                            </div>
                        </div> */}

                        {this.props.type === 'charters' && (
                            <Field
                                name="equal_guests_static"
                                parse={(value) => (value ? Number(value) : '')}
                                type="number"
                                placeholder="GUESTS (Static Events)"
                                component={RenderField}
                                readOnly={this.props.readOnly}
                            />
                        )}

                        {this.props.type === 'charters' && (
                            <Field
                                name="equal_guests_private"
                                parse={(value) => (value ? Number(value) : '')}
                                type="number"
                                placeholder="GUESTS (Private Events)"
                                component={RenderField}
                                readOnly={this.props.readOnly}
                            />
                        )}

                        {this.props.type === 'sales' && (
                            <div className="row">
                                <div className="col-12">
                                    <Field
                                        name="equal_water_capacity"
                                        parse={(value) =>
                                            value ? Number(value) : ''
                                        }
                                        className="mt-0"
                                        type="number"
                                        placeholder="WATER CAPACITY"
                                        component={RenderField}
                                        readOnly={this.props.readOnly}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="col-6 offset-1 offset-lg-2">
                        <div className="row">
                            {this.props.type === 'sales' && (
                                <div className="col-8">
                                    <Field
                                        name="include_country"
                                        placeholder="LOCATION"
                                        options={[
                                            {
                                                name: 'America S. & C.',
                                                id: 'am-sc'
                                            },
                                            { name: 'Europe', id: 'eu' },
                                            { name: 'USA', id: 'usa' },
                                            {
                                                name: '',
                                                id: '',
                                                disabled: true,
                                                className: 'separator',
                                                separator: [
                                                    'eu',
                                                    'usa',
                                                    'am-sc'
                                                ]
                                            }
                                        ]
                                            .concat(newCountries)
                                            .map((val) => {
                                                const {
                                                    id,
                                                    name,
                                                    ...others
                                                } = val;
                                                return {
                                                    value: id,
                                                    label: name,
                                                    ...others
                                                };
                                            })}
                                        separators={true}
                                        multi={true}
                                        component={RenderReactSelect}
                                        clearable={true}
                                        help={'You can select multiple values.'}
                                        readOnly={this.props.readOnly}
                                        className="mt-2 mb-2"
                                    />
                                </div>
                            )}

                            {this.props.type === 'sales' && (
                                <div className="col-8">
                                    <Field
                                        name="include_state"
                                        placeholder="STATE"
                                        options={this.props.states.map(
                                            (val) => {
                                                return {
                                                    value: val.id,
                                                    label: val.name
                                                };
                                            }
                                        )}
                                        multi={true}
                                        className={
                                            'mt-0 mb-2 ' +
                                            (this.props.selectedCountry &&
                                            this.props.selectedCountry.includes(
                                                'usa'
                                            )
                                                ? ''
                                                : 'disabled')
                                        }
                                        component={RenderReactSelect}
                                        clearable={true}
                                        help={'You can select multiple values.'}
                                        readOnly={this.props.readOnly}
                                    />
                                </div>
                            )}

                            {this.props.type === 'sales' && (
                                <div className="col-8">
                                    <Field
                                        name="equal_city"
                                        type="text"
                                        placeholder="CITY"
                                        className="mt-0 mb-0"
                                        component={RenderField}
                                        readOnly={this.props.readOnly}
                                    />
                                </div>
                            )}

                            <div className="col-8">
                                <Field
                                    name="include_flag"
                                    placeholder="Flag"
                                    className="mt-2 mb-2"
                                    options={dropdowns['flags']}
                                    multi={true}
                                    component={RenderReactSelect}
                                    help={'You can select multiple values.'}
                                />
                            </div>

                            <Brokers
                                form="yachts_filters"
                                single={true}
                                className="col-8"
                                prefix="has_"
                                type={this.props.type}
                            />

                            <Companies
                                form="yachts_filters"
                                single={true}
                                className="col-8"
                                prefix="has_"
                            />

                            {this.props.type !== 'sales' && (
                                <div className="search-filter mt-0 mb-1 col-8">
                                    <Field
                                        name="like_captain_name"
                                        type="text"
                                        placeholder="Captain"
                                        className="mt-0 mb-2"
                                        component={RenderField}
                                    />
                                </div>
                            )}

                            {this.props.type !== 'sales' && (
                                <div className="search-filter mt-0 mb-1 col-8">
                                    <Field
                                        name="like_chef"
                                        type="text"
                                        placeholder="Chef"
                                        className="mt-0 mb-2"
                                        component={RenderField}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="row mt-3 mx-0">
                        <div className="col-4 mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Stabilisers at anchor"
                                name="equal_zero_speed_stabilizers"
                            />
                        </div>

                        <div className="col-4 mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Stabilisers underway"
                                name="equal_stabilizers_underway"
                            />
                        </div>

                        <div className="col-4 mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Jacuzzi"
                                name="equal_jacuzzi"
                            />
                        </div>

                        <div className="col-4  mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Helipad"
                                name="equal_helipad"
                            />
                        </div>

                        <div className="col-4  mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Wheelchair Accessibility"
                                name="equal_handicap"
                            />
                        </div>

                        <div className="col-4  mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Elevator Lift"
                                name="equal_elevator"
                            />
                        </div>

                        <div className="col-4  mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Wifi"
                                name="equal_wifi"
                            />
                        </div>

                        <div className="col-4  mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Gym / Εxercise Εquipment"
                                name="equal_gym"
                            />
                        </div>

                        <div className="col-4  mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Spa"
                                name="equal_spa"
                            />
                        </div>

                        <div className="col-4  mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Slide"
                                name="equal_slide"
                            />
                        </div>

                        <div className="col-4  mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Main Deck Cabin"
                                name="equal_main_deck_cabin"
                            />
                        </div>

                        <div className="col-4  mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Beach club"
                                name="equal_beach_club"
                            />
                        </div>

                        <div className="col-4  mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Approved RYA water sports center"
                                name="equal_approved_rya_water_sports_center "
                            />
                        </div>

                        <hr className="col-xl-12 col-md-12 my-2 filter-divider" />

                        <div className="col-4  mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Kid friendly"
                                name="equal_kid_friendly"
                            />
                        </div>

                        <div className="col-4  mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Pet friendly"
                                name="equal_pet_friendly"
                            />
                        </div>

                        <div className="col-4  mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Award winning"
                                name="equal_award_winning"
                            />
                        </div>

                        <div className="col-4  mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Rich toy collection"
                                name="equal_rich_toy_collection"
                            />
                        </div>
                        <div className="col-4  mb-1 form-group">
                            <Field
                                className="mt-0 mb-1"
                                component={RenderCheckbox}
                                label="Diving Facilities and Equipment"
                                name="equal_diving_facilities"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ExtraFilters;
