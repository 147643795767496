import React from 'react';
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

class Dropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            is_open: false
        };
    }

    render() {
        return (
            <ButtonDropdown
                isOpen={this.state.is_open}
                toggle={() => this.setState({ is_open: !this.state.is_open })}
            >
                <DropdownToggle caret color="info">
                    {this.props.header}
                </DropdownToggle>
                <DropdownMenu>
                    {this.props.options.map((option, index) => (
                        <DropdownItem key={index}>
                            <div onClick={this.props.onChange}>{option}</div>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </ButtonDropdown>
        );
    }
}

export default Dropdown;
