import {
    GET_USER,
    EDIT_USER,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAIL,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,
    FETCH_USER,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAIL
} from './_types';

const INITIAL_STATE = {};

export default function authUserReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_USER:
            return action.payload;

        case EDIT_USER:
        case EDIT_USER_FAIL:
        case CHANGE_PASSWORD:
        case CHANGE_PASSWORD_SUCCESS:
        case CHANGE_PASSWORD_FAIL:
        case FETCH_USER:
        case FETCH_USER_FAIL:
            return state;
        case EDIT_USER_SUCCESS:
            return {
                ...state,
                ...action.payload.user
            };
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                ...action.payload.user
            };
        default:
            return state;
    }
}
