import React, { Component } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';

import { Field, change } from 'redux-form';

import ReactS3Uploader from 'react-s3-uploader';
import { uploadProfilePhoto } from '../../helpers/uploadPhoto/_actions';
import RenderField from './RenderField';

import upload from '../../assets/svgs/upload.svg';
import x from '../../assets/svgs/tag-x.svg';
import file from '../../assets/svgs/file-word-resized.svg';

class UploadField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: '',
            uploadedImage: false,
            loading: false,
            multipleList: [],
            readyToSend: [],
            accepedFile: ''
        };

        this.arrayOfObjects = {};
    }

    replaceName(name) {
        let newName =
            new Date().getTime() + '_' + name.replace(/[^\w\d_\-.]+/gi, '');

        this.arrayOfObjects[name.replace(/[^\w\d_\-.]+/gi, '')] = newName;

        return newName;
    }

    getSignedUrl(file, callback) {
        let file_name = this.replaceName(file.name);

        const params = {
            filename: file_name,
            filetype: file.type,
            fileSize: file.size
        };
        // this if is added so that only the uploadFields in photos and docs section are afected
        if (this.props.accept) {
            let givenFile = params.filetype.split('/');
            // console.log(givenFile, this.props.accept);
            // console.log(this.props.accept.includes(givenFile[1]));
            if (this.props.accept.includes(givenFile[1])) {
                if (file.size < 10485760) {
                    this.setState({ accepedFile: true });
                    this.props.uploadProfilePhoto(params).then((data) => {
                        let image_data = {
                            signedUrl: data.signed,
                            fileSize: file.size,
                            filename: file_name,
                            filetype: file.type
                        };

                        callback(image_data);

                        if (
                            this.props.add !== undefined &&
                            this.props.type === 'multiple'
                        ) {
                            let found = this.state.multipleList.find(
                                (x) => x === data.url
                            );

                            if (!found)
                                this.setState({
                                    multipleList: [
                                        ...this.state.multipleList,
                                        data.url
                                    ]
                                });
                        }
                    });
                } else {
                    alert('File too large must be less than 10MB');
                    this.setState({ accepedFile: false });
                    this.setState({ loading: false });
                }
            } else {
                alert('The selected file is not accepted.');
                this.setState({ accepedFile: false });
                this.setState({ loading: false });
            }
        } else {
            if (file.size < 10485760) {
                this.setState({ accepedFile: true });
                this.props.uploadProfilePhoto(params).then((data) => {
                    let image_data = {
                        signedUrl: data.signed,
                        fileSize: file.size,
                        filename: file_name,
                        filetype: file.type
                    };

                    callback(image_data);

                    if (
                        this.props.add !== undefined &&
                        this.props.type === 'multiple'
                    ) {
                        let found = this.state.multipleList.find(
                            (x) => x === data.url
                        );

                        if (!found)
                            this.setState({
                                multipleList: [
                                    ...this.state.multipleList,
                                    data.url
                                ]
                            });
                    }
                });
            } else {
                alert('File too large must be less than 10MB');
                this.setState({ accepedFile: false });
                this.setState({ loading: false });
            }
        }
    }

    onUploadFinish(value, file) {
        let file_name = this.arrayOfObjects[
            file.name.replace(/[^\w\d_\-.]+/gi, '')
        ];

        if (this.props.type === 'single') {
            this.setState({ uploadedImage: true });
            this.setState({ loading: false });
        }

        this.setState({ url: this.props.uploadFile.url });
        this.props.changeFieldValue(this.props.name, this.props.uploadFile.url);
        if (
            this.props.add !== undefined &&
            this.props.type === 'single' &&
            this.state.accepedFile
        ) {
            this.props.add(this.props.uploadFile.url, this.props.name);
        } else if (
            this.props.add !== undefined &&
            this.props.type === 'multiple' &&
            this.state.accepedFile
        ) {
            this.state.multipleList.map((value) => {
                if (value.indexOf('/' + file_name) > -1) {
                    this.setState({
                        readyToSend: [...this.state.readyToSend, value]
                    });
                }
                return value;
            });

            if (
                this.state.readyToSend.length === this.state.multipleList.length
            ) {
                this.props.add(this.state.readyToSend, this.props.name);

                this.setState({ readyToSend: [], multipleList: [] });

                this.setState({ uploadedImage: true });
                this.setState({ loading: false });
            }
        }

        delete this.arrayOfObjects[file.name.replace(/[^\w\d_\-.]+/gi, '')];
    }

    onProgress(value) {
        this.setState({ uploadedImage: false });
        this.setState({ loading: true });
    }

    removeUpload(event) {
        this.setState({ uploadedImage: false });
        this.setState({ loading: false });

        this.setState({ url: '' });
        this.props.changeFieldValue(this.props.name, [{ url: '' }]);

        if (this.props.add !== undefined) {
            this.props.add('', this.props.name);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
        if (this.props.type === 'multiple') {
            if (
                nextProps.initPhoto &&
                nextProps.initPhoto !== undefined &&
                nextProps.initPhoto !== this.state.url &&
                //God, please forgive me for this
                nextProps.initPhoto?.[0]?.url !== prevProps?.initPhoto?.[0]?.url
            ) {
                this.setState({ uploadedImage: true });
                this.setState({ url: nextProps.initPhoto });
            }
            return;
        }

        if (
            nextProps.initPhoto &&
            nextProps.initPhoto !== undefined &&
            nextProps.initPhoto !== this.state.url &&
            nextProps.initPhoto !== prevProps?.initPhoto
        ) {
            this.setState({ uploadedImage: true });
            this.setState({ url: nextProps.initPhoto });
        }
    }

    componentDidMount() {
        if (
            this.props.initPhoto &&
            this.props.initPhoto !== undefined &&
            this.props.initPhoto !== this.state.url
        ) {
            this.setState({ uploadedImage: true });
            this.setState({ url: this.props.initPhoto });
        }
    }

    render() {
        const {
            type,
            desc,
            icon,
            box,
            label,
            preview,
            buttonOnly
        } = this.props;

        return (
            <FormGroup
                className={
                    'floating-label-wrapper upload-panel ' +
                    (box !== undefined ? 'upload-box ' : 'default-panel ') +
                    (this.props.className !== undefined
                        ? this.props.className
                        : '')
                }
            >
                {label !== undefined && (
                    <Label className="upload-label text-uppercase">
                        {label}
                    </Label>
                )}
                {type === 'single' && (
                    <Field
                        name={`${this.props.name}`}
                        component="input"
                        value={this.state.url}
                        type="hidden"
                    />
                )}

                {type === 'single' &&
                    this.state.uploadedImage &&
                    !this.state.loading && (
                        <div className="upload-single-image">
                            {preview !== false && !buttonOnly && (
                                <img
                                    src={this.state.url}
                                    onError={(event) => {
                                        event.target.src = file;
                                        event.onerror = null;
                                    }}
                                    alt=""
                                />
                            )}

                            {box !== undefined && this.props.disabled !== true && (
                                <button
                                    className="btn btn-empty"
                                    type="button"
                                    onClick={this.removeUpload.bind(this)}
                                >
                                    <ReactSVG path={x} style={{ height: 16 }} />
                                    Remove
                                </button>
                            )}

                            {box === undefined &&
                                buttonOnly !== true &&
                                this.props.disabled !== true && (
                                    <a
                                        //href={window.location.href}
                                        className="close-button"
                                        onClick={this.removeUpload.bind(this)}
                                    >
                                        x
                                    </a>
                                )}
                        </div>
                    )}

                {type === 'single' &&
                    this.state.uploadedImage &&
                    this.props.show_alt_text_input && (
                        <div style={{ width: '128px' }}>
                            <Field
                                name={`${this.props.name}.alt_text`}
                                placeholder="ALT TEXT"
                                component={RenderField}
                                readOnly={this.props.disabled}
                                className="mt-2"
                            />
                        </div>
                    )}
                {((type === 'single' && !this.state.uploadedImage) ||
                    type === 'multiple' ||
                    buttonOnly === true) &&
                    this.props.disabled !== true && (
                        <label className="upload-input">
                            <ReactS3Uploader
                                name={`${this.props.name}_file`}
                                getSignedUrl={this.getSignedUrl.bind(this)}
                                accept={
                                    this.props.accept !== undefined
                                        ? this.props.accept
                                        : 'image/*'
                                }
                                multiple={type === 'single' ? false : true}
                                onProgress={this.onProgress.bind(this)}
                                onFinish={this.onUploadFinish.bind(this)}
                                disabled={this.state.loading ? true : false}
                            />

                            <div className="input-wrap">
                                <div className="row">
                                    <div className="col upload-icon-photo">
                                        <div className="d-inline-block">
                                            {this.state.loading === true ? (
                                                <div className="loading" />
                                            ) : (
                                                <ReactSVG
                                                    path={
                                                        icon !== undefined
                                                            ? icon
                                                            : upload
                                                    }
                                                    style={{ height: 16 }}
                                                />
                                            )}
                                        </div>
                                        {this.props.fileLabelName && (
                                            <div className="d-inline-block ml-1 label-text">
                                                {this.props.fileLabelName}
                                            </div>
                                        )}
                                    </div>
                                    {desc !== undefined &&
                                        box === undefined && (
                                            <div className="description col-6">
                                                {this.props.desc}
                                            </div>
                                        )}
                                </div>
                            </div>
                        </label>
                    )}
                {type === 'single' &&
                    desc !== undefined &&
                    box !== undefined && (
                        <div className="description">{this.props.desc}</div>
                    )}
            </FormGroup>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change(ownProps.formName, field, value)),
        uploadProfilePhoto: (e) => dispatch(uploadProfilePhoto(e))
    };
}

function mapStateToProps(state, ownProps) {
    return {
        uploadFile: state.uploadPhoto.uploadFile
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadField);
