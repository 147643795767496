import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';
import moment from 'moment';

import Header from '../../common/header/Header';
import SimpleTableActions from '../../common/components/SimpleTableActions';
import SimpleTable from '../../common/components/SimpleTable';
import SimpleModal from '../../common/components/SimpleModal';
import SimplePaginationWrapper from '../../common/components/pagination/SimplePaginationWrapper';
import { Checkbox } from '../../common/components/simpleInputs';
import DeleteConfirmation from '../../common/components/DeleteConfirmation';

import {
    fetchCreateNewsletter,
    fetchNewslettersHistory,
    fetchDeleteNewsletter
} from './actions';

import add from '../../assets/svgs/add.svg';
import customers from '../../assets/svgs/customers.svg';
import deleteSVG from '../../assets/svgs/delete.svg';
import edit from '../../assets/svgs/edit.svg';

const emailStatuses = {
    draft: 'Draft',
    sent: 'Sent'
};
class NewsletterHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            api: false,
            menu: {
                parent: customers,
                items: []
            },
            params: {
                order_field: 'updated_at',
                order_dir: 'DESC',
                page: 1,
                page_size: 25,
                broker_id: this.props.user.id
            },
            headers: [
                {
                    name: 'replicate',
                    label: '',
                    sort: false
                },
                {
                    name: 'title',
                    label: 'Title',
                    sort: true,
                    defaultSort: 'DESC'
                },
                {
                    name: 'status',
                    label: 'Status',
                    sort: true,
                    defaultSort: 'DESC'
                },
                {
                    name: 'updated_at',
                    label: 'Last modified',
                    sort: true,
                    defaultSort: 'DESC'
                },
                {
                    name: 'sent_at',
                    label: 'Sent',
                    sort: true,
                    defaultSort: 'DESC'
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    sort: false,
                    defaultSort: ''
                }
            ],
            modalHeader: 'Create new contact',
            modalOpen: false,
            modalDelete: (
                <DeleteConfirmation
                    toggle={this.toggleModal.bind(this)}
                    action={this.deleteNewsletter.bind(this)}
                    body=""
                />
            ),
            selected: []
        };
    }

    componentDidMount() {
        let menu = [
            <NavLink exact to={'/newsletters'}>
                All
            </NavLink>
        ];

        const customerID = new URLSearchParams(this.props.location.search).get(
            'customer_id'
        );

        if (customerID !== null) {
            let params = Object.assign(this.state.params, {
                customer_id: customerID,
                broker_id: this.props.user.id
            });

            this.setState({ params });
            this.setState({ hasQuery: true });
        }

        this.props.fetchNewslettersHistory(this.state.params);
        this.setState({ api: true });
        this.setState({ menu: { ...this.state.menu, items: menu } });
    }

    componentDidUpdate() {
        if (!this.state.api) {
            let params = {
                order_field: 'created_at',
                order_dir: 'DESC',
                page: 1,
                page_size: 25,
                broker_id: this.props.user.id
            };

            const customerID = new URLSearchParams(
                this.props.location.search
            ).get('customer_id');

            if (customerID !== null) {
                let params = Object.assign(this.state.params, {
                    customer_id: customerID,
                    broker_id: this.props.user.id
                });
                this.setState({ params });
            }

            this.props.fetchNewslettersHistory(params);
            this.setState({ api: true });
        }
    }

    render() {
        const newsletters = this.props.newsletters
            ? {
                  data: this.props.newsletters.data,
                  pagination: this.props.newsletters.pagination
              }
            : {};

        const { selected } = this.state;

        return (
            <div className="content-inner-wrapper">
                <Header menu={this.state.menu} history={this.props.history} />

                <div className="content-inner">
                    <div className="row">
                        <div className="col-xl-11 col-12">
                            <SimpleTableActions
                                label="Create newsletter"
                                icon={add}
                                onClick={() =>
                                    this.props.history.push(
                                        '/newsletters/create'
                                    )
                                }
                            />
                            <button
                                type="submit"
                                className="btn btn-primary btn-search"
                                disabled={selected.length === 0}
                                onClick={() => {
                                    this.handleReplicate();
                                }}
                            >
                                Replicate
                            </button>
                            {newsletters && newsletters.data && (
                                <div className="table search-results">
                                    <SimplePaginationWrapper
                                        className={'pagination-header'}
                                        total={newsletters.pagination.total}
                                        currentPage={
                                            newsletters.pagination.page
                                        }
                                        totalPages={
                                            newsletters.pagination.page_count
                                        }
                                        pageChangeFunction={this.pageChange.bind(
                                            this
                                        )}
                                        pageSize={
                                            newsletters.pagination.page_size
                                        }
                                        allCheckbox={false}
                                        selected={false}
                                        hidePagination={false}
                                        hideExtraFilter={true}
                                    />
                                </div>
                            )}
                            {newsletters.data && (
                                <SimpleTable
                                    tableChange={this.tableChange.bind(this)}
                                    colWidths={[1, 3, '3 date', '2', '2', '1']}
                                    headers={this.state.headers}
                                    rowData={newsletters.data.map(value => {
                                        return [
                                            <div>
                                                <Checkbox
                                                    className="mb-0"
                                                    value={selected.includes(
                                                        value.id
                                                    )}
                                                    onChange={() => {
                                                        this.updateSelected(
                                                            value.id
                                                        );
                                                    }}
                                                />
                                            </div>,
                                            <div>
                                                <NavLink
                                                    to={
                                                        '/newsletters/' +
                                                        value.id +
                                                        '/view'
                                                    }
                                                >
                                                    {value.title}
                                                </NavLink>
                                            </div>,

                                            <div>
                                                {(value.status &&
                                                    emailStatuses[
                                                        value.status
                                                    ]) ||
                                                    ''}
                                            </div>,

                                            <div>
                                                {value.updated_at &&
                                                value.updated_at != null
                                                    ? moment(
                                                          value.updated_at
                                                      ).format(
                                                          'DD/MM/YYYY HH:mm'
                                                      )
                                                    : 'Draft'}
                                            </div>,

                                            <div>
                                                {value.sent_at &&
                                                value.sent_at != null
                                                    ? moment(
                                                          value.sent_at
                                                      ).format(
                                                          'DD/MM/YYYY HH:mm'
                                                      )
                                                    : '-'}
                                            </div>,
                                            <div>
                                                {value.status === 'sent' ? (
                                                    value.tag ? (
                                                        <ul className="list-unstyled table-td-actions">
                                                            <li>
                                                                <NavLink
                                                                    className="btn btn-blue btn-offer ghost center"
                                                                    style={{
                                                                        padding:
                                                                            '0 14px'
                                                                    }}
                                                                    to={`/newsletters/${value.id}/report`}
                                                                >
                                                                    View Report
                                                                </NavLink>
                                                            </li>
                                                        </ul>
                                                    ) : null
                                                ) : (
                                                    <ul className="list-unstyled table-td-actions">
                                                        <li>
                                                            <NavLink
                                                                className="btn btn-td-action"
                                                                to={
                                                                    '/newsletters/' +
                                                                    value.id +
                                                                    '/edit/'
                                                                }
                                                            >
                                                                <ReactSVG
                                                                    path={edit}
                                                                    style={{
                                                                        height: 24
                                                                    }}
                                                                />
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <button
                                                                className="btn btn-td-action"
                                                                onClick={() =>
                                                                    this.deleteModal(
                                                                        value
                                                                    )
                                                                }
                                                            >
                                                                <ReactSVG
                                                                    path={
                                                                        deleteSVG
                                                                    }
                                                                    style={{
                                                                        height: 24
                                                                    }}
                                                                />
                                                            </button>
                                                        </li>
                                                    </ul>
                                                )}
                                            </div>
                                        ];
                                    })}
                                />
                            )}

                            <SimpleModal
                                header={this.state.modalHeader}
                                body={this.state.modalDelete}
                                modalOpen={this.state.modalOpen}
                                toggle={this.toggleModal.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleReplicate() {
        const { selected } = this.state;
        const {
            newsletters,
            user,
            fetchCreateNewsletter,
            fetchNewslettersHistory
        } = this.props;
        const newslettersToReplicate = newsletters.data.filter(e =>
            selected.includes(e.id)
        );

        newslettersToReplicate.map(e => {
            const params = {
                broker_id: user.id,
                emails: e.emails,
                email_subject: e.email_subject,
                featured_yachts_intro_text: e.featured_yachts_intro_text,
                featured_yachts_yachts: JSON.parse(e.featured_yachts_yachts),
                intro_text: e.intro_text,
                new_on_the_market_intro_text: e.new_on_the_market_intro_text,
                new_on_the_market_yachts: JSON.parse(
                    e.new_on_the_market_yachts
                ),
                price_reduction_intro_text: e.price_reduction_intro_text,
                price_reduction_yachts: JSON.parse(e.price_reduction_yachts),
                title: `${e.title} [Draft]`,
                top_sales_intro_text: e.top_sales_intro_text,
                top_sales_deals_yachts: JSON.parse(e.top_sales_yachts),
                by_saved_search: e.by_saved_search,
                search_id: e.saved_search_id,
                customers_ids: e.customers.map(customer => customer.id),
                chartering: e.chartering
            };

            fetchCreateNewsletter(params).then(res => {
                const newslettersHistoryParams = {
                    order_field: 'created_at',
                    order_dir: 'DESC',
                    page: 1,
                    page_size: 25,
                    broker_id: this.props.user.id
                };

                fetchNewslettersHistory(newslettersHistoryParams);
                this.setState({ api: true });
            });
            return e;
        });
    }

    updateSelected(id) {
        if (this.state.selected.includes(id)) {
            this.setState(prevState => ({
                selected: prevState.selected.filter(yachtId => yachtId !== id)
            }));
        } else {
            this.setState(prevState => ({
                selected: [...prevState.selected, id]
            }));
        }
    }

    pageChange(key, value) {
        const params = this.state.params;
        params[key] = value;
        this.setState({ params });
        this.props.fetchNewslettersHistory(params);
    }

    tableChange(key, value) {
        const params = this.state.params;
        params.order_field = key;
        params.order_dir = value;
        this.setState({ params });

        this.props.fetchNewslettersHistory(params);
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    deleteModal(newsletter) {
        this.setState({
            modalDelete: (
                <DeleteConfirmation
                    toggle={this.toggleModal.bind(this)}
                    action={this.deleteNewsletter.bind(this)}
                    body={
                        'You are about to delete proposal: ' +
                        newsletter.title +
                        '.'
                    }
                />
            )
        });
        this.setState({ modalHeader: 'Delete proposal' });
        this.setState({ newsletterToDelete: newsletter });
        this.toggleModal();
    }

    deleteNewsletter() {
        this.props
            .fetchDeleteNewsletter({ id: this.state.newsletterToDelete.id })
            .then(data => {
                this.refetchNewsletters();
            });
    }

    refetchNewsletters() {
        let params = {
            order_field: 'created_at',
            order_dir: 'DESC',
            page: 1,
            page_size: 25,
            broker_id: this.props.user.id
        };

        this.props.fetchNewslettersHistory(params);
        this.setState({ api: true });
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchNewslettersHistory: e => dispatch(fetchNewslettersHistory(e)),
        fetchDeleteNewsletter: e => dispatch(fetchDeleteNewsletter(e)),
        fetchCreateNewsletter: e => dispatch(fetchCreateNewsletter(e))
    };
}

function mapStateToProps(state) {
    return {
        user: state.user,
        newsletters: state.newsletters
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterHistory);
