import React, { Component } from 'react';
import { Field, FieldArray } from 'redux-form';
import { connect } from 'react-redux';

import ToggleCollapse from '../../common/components/ToggleCollapse';
import RenderField from '../../common/components/RenderField';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import RenderFieldArray from '../../common/components/RenderFieldArray';

import DateFromToPicker from '../../common/components/datetimepicker/DateFromToPicker';
import { UncontrolledTooltip } from 'reactstrap';
import { getEnquiries } from './_actions';

import pdf from '../../assets/svgs/pdf.svg';
import ReactSVG from 'react-svg';

class SalesAgreement extends Component {
    render() {
        return (
            <ToggleCollapse
                className={'grey'}
                body={
                    <div>
                        <div className="row">
                            <div className="col-12 mt-1">
                                <div className="row">
                                    <div className="col-12 info-label mb-1">
                                        You will receive a notification
                                        reminding you to follow up with your
                                        contact.
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <Field
                                            name="ca_sales_yacht_name"
                                            type="text"
                                            readOnly={this.props.readOnly}
                                            disabled={this.props.readOnly}
                                            placeholder="YACHT NAME"
                                            component={RenderField}
                                            className="mb-1"
                                            onChange={event =>
                                                this.props.searchChange(
                                                    event,
                                                    'sales'
                                                )
                                            }
                                            dropdownList={
                                                this.props.dropdownList
                                            }
                                            onClickList={this.props.onClickList}
                                            openSearch={this.props.openSearch}
                                        />
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <DateFromToPicker
                                            labels={{
                                                from: 'CA TERMINATION DATE'
                                            }}
                                            initFromDate={
                                                this.props
                                                    .ca_sales_termination_date
                                            }
                                            onChangeFunction={date =>
                                                this.props.dateChange(
                                                    date,
                                                    'ca_sales_termination_date'
                                                )
                                            }
                                            single={true}
                                            readOnly={this.props.readOnly}
                                        />
                                        <Field
                                            name="ca_sales_termination_date"
                                            label="CA TERMINATION DATE"
                                            type="hidden"
                                            component="input"
                                            readOnly={this.props.readOnly}
                                            disabled={this.props.readOnly}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mt-3 small-caps">
                                        Enquiries log
                                    </div>
                                    {this.props.readOnly && (
                                        <div className="col mt-3 small-caps d-flex justify-content-center">
                                            <button
                                                type="button"
                                                className="m-0 p-0 btn btn-link"
                                                style={{ height: 20 }}
                                                onClick={() =>
                                                    this.props.getEnquiries({
                                                        id: this.props.id
                                                    })
                                                }
                                            >
                                                <ReactSVG
                                                    path={pdf}
                                                    style={{ height: 20 }}
                                                />
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <FieldArray
                                            name="enquiries"
                                            component={RenderFieldArray}
                                            readOnly={this.props.readOnly}
                                            changeFieldValue={
                                                this.props.changeFieldValue
                                            }
                                            initialValues={
                                                this.props.initialValues
                                            }
                                            className="col p-0"
                                            initFirst={true}
                                            props={{
                                                customFields: [
                                                    {
                                                        name: 'name',
                                                        placeholder: 'NAME',
                                                        type: 'text',
                                                        className: 'col-6'
                                                    },
                                                    {
                                                        name: 'date',
                                                        type: 'date',
                                                        className:
                                                            'col-3 d-flex align-items-center justify-content-center'
                                                    },
                                                    {
                                                        name: 'source',
                                                        type: 'select',
                                                        placeholder: 'Select',
                                                        label: 'TYPE',
                                                        options: [
                                                            {
                                                                value: 'broker',
                                                                label: 'Broker'
                                                            },
                                                            {
                                                                value: 'client',
                                                                label: 'Client'
                                                            }
                                                        ],
                                                        className:
                                                            'col-3 transparent'
                                                    },
                                                    {
                                                        name: 'notes',
                                                        placeholder:
                                                            'STATUS/NOTES',
                                                        type: 'text',
                                                        className: 'col-12'
                                                    }
                                                ]
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5 mt-1">
                                        <Field
                                            name="ca_sales_remind_weeks"
                                            readOnly={this.props.readOnly}
                                            placeholder="REMIND ME TO FOLLOW UP EVERY"
                                            component={RenderReactSelect}
                                            className="mb-0"
                                            clearable={false}
                                            options={[
                                                { value: 2, label: '2 weeks' },
                                                { value: 4, label: '4 weeks' },
                                                { value: 6, label: '6 weeks' },
                                                { value: 8, label: '8 weeks' },
                                                {
                                                    value: 17,
                                                    label: '4 months'
                                                },
                                                {
                                                    value: 26,
                                                    label: '6 months'
                                                },
                                                {
                                                    value: 51,
                                                    label: '12 months'
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div className="col-2 mt-1 d-flex justify-content-start">
                                        <div
                                            className="btn btn-info align-self-end"
                                            id="SalesRemindWeeks"
                                        >
                                            i
                                        </div>
                                        <UncontrolledTooltip
                                            placement="right"
                                            target="SalesRemindWeeks"
                                        >
                                            You will receive a notification
                                            reminding you to follow up with your
                                            contact.
                                        </UncontrolledTooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                header="CA SALES AGREEMENT"
            />
        );
    }
}

export default connect(
    state => ({ id: state.customers.customers.initCustomer.id }),
    { getEnquiries }
)(SalesAgreement);
