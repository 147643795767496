import React, { Component } from 'react';
import { connect } from 'react-redux';

import Sidebar from './common/Sidebar';

import { toggleSidebar } from './helpers/common/actions';
import { functions } from './helpers/functions';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { animating: false };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    toggleMenu() {
        this.setState({ animating: true });
        this.props.toggleSidebar();
        setTimeout(() => this.setState({ animating: false }), 500);
    }

    handleResize() {
        let result = functions.getWindowSize() < 1200;
        if (result && this.props.isSidebarOpen) this.props.toggleSidebar();
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    render() {
        return (
            <div
                className={
                    'content' +
                    (this.props.isSidebarOpen ? ' menu-close' : ' menu-open') +
                    (this.state.animating ? ' menu-closing' : '')
                }
            >
                <Sidebar
                    user={this.props.user}
                    user_role={this.props.user_role}
                    open={this.props.isSidebarOpen}
                    toggleSidebar={this.toggleMenu}
                />
                <main id="main">{this.props.children}</main>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isSidebarOpen: state.common.windowWidth
    };
}

export default connect(
    mapStateToProps,
    { toggleSidebar }
)(App);
