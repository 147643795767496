import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';

import Header from '../common/header/Header';
import DriveManageItem from './DriveManageItem';
import SimpleTableActions from '../common/components/SimpleTableActions';
import SimpleTable from '../common/components/SimpleTable';

import ReactSVG from 'react-svg';

import add from '../assets/svgs/add.svg';
import deleteSVG from '../assets/svgs/delete.svg';
import edit from '../assets/svgs/edit.svg';
import download from '../assets/svgs/download-03.svg';

import {
    fetchDrive,
    fetchCreateDriveItem,
    fetchUpdateDriveItem,
    fetchDeleteDriveItem,
    fetchDownloadDriveItem
} from './_actions';

class DriveList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                items: ['Useful Information']
            },
            headers: [
                {
                    name: 'name',
                    label: 'Name',
                    sort: true,
                    defaultSort: 'DESC'
                },
                {
                    name: 'description',
                    label: 'Description',
                    sort: true,
                    defaultSort: 'DESC'
                },
                {
                    name: 'type',
                    label: 'Type',
                    sort: true,
                    defaultSort: 'DESC'
                },
                {
                    name: 'created_at',
                    label: 'Uploaded On',
                    sort: true,
                    defaultSort: 'DESC'
                },
                {
                    name: 'action',
                    label: 'Actions',
                    sort: false,
                    defaultSort: ''
                }
            ],
            manageModalStatus: false,
            manageModalItem: null
        };
    }

    componentDidMount() {
        let params = {
            page: 1,
            page_size: 25,
            order_field: 'created_at',
            order_dir: 'DESC',
            id: this.props.match.params.id
        };

        this.props.fetchDrive(params);
    }

    toggleManageItemModal(action, item = null) {
        this.setState({
            manageModalStatus:
                this.state.manageModalStatus === true ? false : true,
            manageModalItem: item,
            manageModalAction: action
        });
    }

    renderDriveItems() {
        const items = this.props.driveItems;

        return items.map((item, index) => {
            return [
                <div>{item.name && item.name}</div>,
                <div
                    dangerouslySetInnerHTML={{ __html: item.description }}
                    className="smallDesc"
                ></div>,
                <div>{item.extension && item.extension}</div>,
                <div>
                    {item.created_at &&
                        moment(item.created_at).format('DD/MM/YYYY')}
                </div>,
                <ul className="list-unstyled table-td-actions">
                    <li>
                        <button
                            onClick={e => this.fetchDownloadDriveItem(item)}
                            className="btn btn-td-action"
                            id={'download' + item.id}
                        >
                            <ReactSVG path={download} style={{ height: 24 }} />
                        </button>

                        <UncontrolledTooltip
                            placement="bottom"
                            target={'download' + item.id}
                        >
                            Download
                        </UncontrolledTooltip>
                    </li>

                    {this.props.user_role &&
                        this.props.user_role !== 'broker' &&
                        this.props.user_role !== 'broker_charter' &&
                        this.props.user_role !== 'broker_sales' && (
                            <li>
                                <button
                                    className="btn btn-td-action"
                                    onClick={e =>
                                        this.toggleManageItemModal('edit', item)
                                    }
                                    id={'edit' + item.id}
                                >
                                    <ReactSVG
                                        path={edit}
                                        style={{ height: 24 }}
                                    />
                                </button>

                                <UncontrolledTooltip
                                    placement="bottom"
                                    target={'edit' + item.id}
                                >
                                    Edit
                                </UncontrolledTooltip>
                            </li>
                        )}
                    {this.props.user_role &&
                        this.props.user_role !== 'broker' &&
                        this.props.user_role !== 'broker_charter' &&
                        this.props.user_role !== 'broker_sales' && (
                            <li>
                                <button
                                    className="btn btn-td-action"
                                    onClick={() =>
                                        this.toggleManageItemModal(
                                            'delete',
                                            item
                                        )
                                    }
                                    id={'delete' + item.id}
                                >
                                    <ReactSVG
                                        path={deleteSVG}
                                        style={{ height: 24 }}
                                    />
                                </button>

                                <UncontrolledTooltip
                                    placement="bottom"
                                    target={'delete' + item.id}
                                >
                                    Delete
                                </UncontrolledTooltip>
                            </li>
                        )}
                </ul>
            ];
        });
    }

    fetchCreateDriveItem(values) {
        const params = Object.assign(values, {
            parent_id: this.props.match.params.id
        });

        this.props.fetchCreateDriveItem(params).then(response => {
            this.setState({
                manageModalStatus: false
            });

            let params = {
                page: 1,
                page_size: 25,
                order_field: 'created_at',
                order_dir: 'DESC',
                id: this.props.match.params.id
            };

            this.props.fetchDrive(params);
        });
    }

    fetchUpdateDriveItem(values) {
        const params = Object.assign(values, {
            parent_id: this.props.match.params.id
        });

        this.props.fetchUpdateDriveItem(params).then(response => {
            this.setState({
                manageModalStatus: false,
                manageModalItem: null
            });

            let params = {
                page: 1,
                page_size: 25,
                order_field: 'created_at',
                order_dir: 'DESC',
                id: this.props.match.params.id
            };

            this.props.fetchDrive(params);
        });
    }

    fetchDeleteDriveItem(id) {
        this.props.fetchDeleteDriveItem({ id }).then(response => {
            let params = {
                page: 1,
                page_size: 25,
                order_field: 'created_at',
                order_dir: 'DESC',
                id: this.props.match.params.id
            };

            this.props.fetchDrive(params);
        });
    }

    fetchDownloadDriveItem(item) {
        this.props.fetchDownloadDriveItem({ id: item.id });
    }

    tableChange(key, newValue) {
        let params = {
            page: key !== 'page' ? 1 : newValue,
            order_dir:
                key !== 'page' && key !== 'page_size' && key !== 'search'
                    ? newValue
                    : this.props.pagination.order_dir,
            order_field:
                key !== 'page' && key !== 'page_size' && key !== 'search'
                    ? key
                    : this.props.pagination.order_field,
            page_size:
                key === 'page_size'
                    ? newValue
                    : this.props.pagination.page_size,
            id: this.props.match.params.id
        };

        this.props.fetchDrive(params);
    }

    render() {
        return (
            <div className="content-inner-wrapper">
                <Header menu={this.state.menu} history={this.props.history} />
                <div className="content-inner">
                    <div className="row">
                        <div className="col-12 col-xl-10">
                            {this.props.user_role &&
                                this.props.user_role !== 'broker' &&
                                this.props.user_role !== 'broker_charter' &&
                                this.props.user_role !== 'broker_sales' && (
                                    <SimpleTableActions
                                        label="Upload Material"
                                        icon={add}
                                        onClick={() =>
                                            this.toggleManageItemModal('create')
                                        }
                                    />
                                )}

                            <SimpleTable
                                tableChange={this.tableChange.bind(this)}
                                colWidths={[
                                    '3 ',
                                    '4 hidden-md-down',
                                    '1 hidden-md-down',
                                    '2 date ml-auto text-center',
                                    '2 text-center ml-auto'
                                ]}
                                headers={this.state.headers}
                                rowData={this.renderDriveItems()}
                            />
                        </div>
                    </div>

                    <DriveManageItem
                        onCreateItem={this.fetchCreateDriveItem.bind(this)}
                        onUpdateItem={this.fetchUpdateDriveItem.bind(this)}
                        onDeleteItem={this.fetchDeleteDriveItem.bind(this)}
                        toggleManageModal={this.toggleManageItemModal.bind(
                            this
                        )}
                        modalStatus={this.state.manageModalStatus}
                        item={this.state.manageModalItem}
                        action={this.state.manageModalAction}
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchDrive: e => dispatch(fetchDrive(e)),
        fetchCreateDriveItem: e => dispatch(fetchCreateDriveItem(e)),
        fetchUpdateDriveItem: e => dispatch(fetchUpdateDriveItem(e)),
        fetchDeleteDriveItem: e => dispatch(fetchDeleteDriveItem(e)),
        fetchDownloadDriveItem: e => dispatch(fetchDownloadDriveItem(e))
    };
};

const mapStateToProps = state => {
    return {
        user_role: state.auth.user_role,
        driveItems: state.drive.driveItems,
        pagination: state.drive.pagination
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DriveList);
