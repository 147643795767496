import axios from 'axios';

import {
  FETCH_COMMON_DESTINATIONS,
  FETCH_COMMON_DESTINATIONS_SUCCESS,
  FETCH_COMMON_DESTINATIONS_FAIL,
  FETCH_COMMON_COUNTRIES,
  FETCH_COMMON_COUNTRIES_SUCCESS,
  FETCH_COMMON_COUNTRIES_FAIL,
  FETCH_COMMON_STATES,
  FETCH_COMMON_STATES_SUCCESS,
  FETCH_COMMON_STATES_FAIL,
  FETCH_COMMON_LOCATIONS,
  FETCH_COMMON_LOCATIONS_SUCCESS,
  FETCH_COMMON_LOCATIONS_FAIL,
  FETCH_COMMON_YACHT_TYPES,
  FETCH_COMMON_YACHT_TYPES_SUCCESS,
  FETCH_COMMON_YACHT_TYPES_FAIL,
  FETCH_GET_YACHT_LIST,
  FETCH_GET_YACHT_LIST_SUCCESS,
  FETCH_GET_YACHT_LIST_FAIL,
  TOGGLE_SIDEBAR
} from './types';

import { API_URL } from '../../helpers/variables';
import { errorHandler } from '../../helpers/alerts';
import { authConfig } from '../../helpers/authConfig';

export function fetchCountries() {
  return dispatch => {
    dispatch({ type: FETCH_COMMON_COUNTRIES });

    const config = {
      headers: authConfig()
    };

    axios
      .get(API_URL + '/common/countries', config)
      .then(response => {
        dispatch({
          type: FETCH_COMMON_COUNTRIES_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_COMMON_COUNTRIES_FAIL });
        errorHandler(dispatch, error.response);
      });
  };
}

export function fetchStates() {
  return dispatch => {
    dispatch({ type: FETCH_COMMON_STATES });

    const config = {
      headers: authConfig()
    };

    axios
      .get(API_URL + '/common/states', config)
      .then(response => {
        dispatch({
          type: FETCH_COMMON_STATES_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_COMMON_STATES_FAIL });
        errorHandler(dispatch, error.response);
      });
  };
}

export function fetchDestinations() {
  return dispatch => {
    dispatch({ type: FETCH_COMMON_DESTINATIONS });

    const config = {
      headers: authConfig()
    };

    axios
      .get(API_URL + '/common/destinations', config)
      .then(response => {
        dispatch({
          type: FETCH_COMMON_DESTINATIONS_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_COMMON_DESTINATIONS_FAIL });
        errorHandler(dispatch, error.response);
      });
  };
}

export function fetchLocations() {
  return dispatch => {
    dispatch({ type: FETCH_COMMON_LOCATIONS });

    const config = {
      headers: authConfig()
    };

    axios
      .get(API_URL + '/common/locations', config)
      .then(response => {
        dispatch({
          type: FETCH_COMMON_LOCATIONS_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_COMMON_LOCATIONS_FAIL });
        errorHandler(dispatch, error.response);
      });
  };
}

export function fetchYachtList(params) {
  return dispatch => {
    dispatch({ type: FETCH_GET_YACHT_LIST });

    const config = { headers: authConfig(), params: params };

    return axios
      .get(API_URL + '/yachts/list', config)
      .then(response => {
        dispatch({
          type: FETCH_GET_YACHT_LIST_SUCCESS,
          payload: response.data
        });

        return response.data;
      })
      .catch(error => {
        errorHandler(dispatch, error.response, FETCH_GET_YACHT_LIST_FAIL);
      });
  };
}

export function fetchTypes() {
  return dispatch => {
    dispatch({ type: FETCH_COMMON_YACHT_TYPES });

    const config = {
      headers: authConfig()
    };

    axios
      .get(API_URL + '/common/types', config)
      .then(response => {
        dispatch({
          type: FETCH_COMMON_YACHT_TYPES_SUCCESS,
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_COMMON_YACHT_TYPES_FAIL });
        errorHandler(dispatch, error.response);
      });
  };
}

export function toggleSidebar() {
  return dispatch => {
    dispatch({ type: TOGGLE_SIDEBAR });
  };
}
