import moment from 'moment';

export const API_URL = process.env.REACT_APP_API_URL;
export const CLIENT_ROOT_URL = window.location.origin;
export const SALES_LISTINGS_URL = process.env.REACT_APP_SALES_LISTINGS_URL;
export const CUSTOMERS_URL = process.env.REACT_APP_CUSTOMERS_URL;
export const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;
export const YACHT_PREVIEW_URL = process.env.REACT_APP_YACHT_PREVIEW_URL;
export const YACHT_PUBLIC_URL = process.env.REACT_APP_YACHT_PUBLIC_URL;
export const LUCENT_API = process.env.REACT_APP_LUCENT_API;
export const LUCENT_KEY = process.env.REACT_APP_LUCENT_KEY;
export const seasons = [
    {
        label: 'Summer Low Season (May 1 - June 30)',
        start_date: '05/01/' + moment().year(),
        end_date: '06/30/' + moment().year()
    },
    {
        label: 'Summer high season (July 1 - August 31)',
        start_date: '07/01/' + moment().year(),
        end_date: '08/31/' + moment().year()
    },
    {
        label: 'Summer low season 2 (September 1 - October 31)',
        start_date: '09/01/' + moment().year(),
        end_date: '10/31/' + moment().year()
    },
    {
        label: 'Winter low season 1 (November 1 - December 22)',
        start_date: '11/01/' + moment().year(),
        end_date: '12/22/' + moment().year()
    },
    {
        label: 'Winter high season (December 23 - January 7)',
        start_date: '12/23/' + moment().year(),
        end_date: '01/07/' + moment().add(1, 'year').year()
    },
    {
        label: 'Winter low season 2 (January 8 - April 30)',
        start_date: '01/08/' + moment().year(),
        end_date: '04/30/' + moment().year()
    }
];

export const loggableYachtFields = {
    name: 'Name',
    asking_price: 'Asking price',
    estimated_price: 'Estimated price',
    vat_status: 'Vat status',
    status: 'Status',
    status_detail: 'Status detail',
    vessel_id: 'Vessel ID',
    previous_names: 'Previous names',
    type: 'Type',
    builder: 'Builder',
    custom_built: 'Custom built',
    loa: 'LOA',
    year_built: 'Year build',
    beam: 'Beam',
    cabins: 'Cabins',
    hull_material: 'Hull material',
    draft_min: 'Minimum draft',
    draft_max: 'Maximum draft',
    engine: 'Engine',
    guests: 'Guests',
    country: 'Country',
    state: 'State',
    city: 'City',
    crew: 'Crew',
    asking_price_displayed: 'Asking price',
    asking_price_currency: 'Asking price currency',
    is_archived: 'Is archived',
    curation_level: 'Curation level',
    sales: 'Sales',
    chartering: 'Chartering',
    slug: 'Slug',
    sales_public: 'Sales public',
    charter_public: 'Charter public'
};
export const loggableYachtFieldsUnits = {
    name: '',
    asking_price: '$',
    asking_price_displayed: '$',
    estimated_price: '$',
    vat_status: '',
    vessel_id: '',
    previous_names: '',
    type: '',
    builder: '',
    loa: 'm',
    year_built: '',
    beam: 'm',
    cabins: '',
    hull_material: '',
    draft_min: 'm',
    draft_max: 'm',
    engine: '',
    guests: '',
    country: '',
    state: '',
    city: '',
    crew: ''
};

export const vatStatus = [
    { value: '0', label: 'Not Paid' },
    { value: '1', label: 'Paid' },
    { value: '2', label: 'Unknown' },
    { value: '3', label: 'Under Leasing' },
    { value: '4', label: 'US Duty Paid' },
    { value: '5', label: 'Other' }
];
// Keep these here for now just in case we need them

// export const additionalCountries = [
//     { abbrev: '', name: 'Channel Islands' },
//     { abbrev: 'CD', name: 'Democratic Republic Of The Congo' },
//     { abbrev: '', name: 'Dutch Caribbean' },
//     { abbrev: '', name: 'French West Indies' },
//     { abbrev: 'BQ', name: 'St. Eustatius' },
// ];

// export const mapExistingCountries = [
//     { id: '107', abbrev: 'CI', name: 'Ivory Coast', yatcoName: "Cote D'Ivoire" },
//     { id: '115', abbrev: 'KP', name: "Korea, Democratic People's Republic of", yatcoName: "Democratic People'S Republic Of Korea" },
//     { id: '169', abbrev: 'PS', name: 'Palestine', yatcoName: "Occupied Palestinian Territory" },
//     { id: '116', abbrev: 'KR', name: 'Korea, Republic of', yatcoName: "Republic Of Korea" },
//     { id: '144', abbrev: 'MD', name: 'Moldova, Republic of', yatcoName: "Republic Of Moldova" },
//     { id: '204', abbrev: 'SH', name: 'St. Helena', yatcoName: "Saint Helena" },
//     { id: '246', abbrev: 'MF', name: 'St. Maarten', yatcoName: "Saint Martin" },
//     { id: '205', abbrev: 'PM', name: 'St. Pierre and Miquelon', yatcoName: "Saint Pierre And Miquelon" },
//     { id: '247', abbrev: 'BL', name: 'St. Barts', yatcoName: "Saint-Barthelemy" },
//     { id: '185', abbrev: 'LC', name: 'Saint Lucia', yatcoName: "St. Lucia" },
//     { id: '60', abbrev: 'TL', name: 'East Timor', yatcoName: "Timor-Leste" },
//     { id: '239', abbrev: 'VI', name: 'Virgin Islands (U.S.)', yatcoName: "United States Virgin Islands" },
// ];

export const dropdowns = {
    apa: [
        { value: 0.25, label: '25%' },
        { value: 0.3, label: '30%' },
        { value: 0.35, label: '35%' },
        { value: 0.4, label: '40%' }
    ],
    hull_material: [
        { value: 'Aluminum', label: 'Aluminum' },
        { value: 'Alustar', label: 'Alustar' },
        { value: 'Cold Molded', label: 'Cold Molded' },
        { value: 'Composite', label: 'Composite' },
        { value: 'Composite Fiberglass', label: 'Composite Fiberglass' },
        { value: 'Carbon Composite', label: 'Carbon Composite' },
        { value: 'Carbon Fiber', label: 'Carbon Fiber' },
        { value: 'Fiberglass', label: 'Fiberglass' },
        { value: 'Fiberglass Sandwich', label: 'Fiberglass Sandwich' },
        { value: 'FRP', label: 'FRP' },
        { value: 'GRP', label: 'GRP' },
        { value: 'hypalion', label: 'Hypalion' },
        { value: 'Kevlar Composite', label: 'Kevlar Composite' },
        { value: 'Light Alloy', label: 'Light Alloy' },
        { value: 'Mahogany', label: 'Mahogany' },
        { value: 'Oak', label: 'Oak' },
        { value: 'Other', label: 'Other' },
        { value: 'Plywood Epoxy', label: 'Plywood Epoxy' },
        { value: 'Stainless Steel', label: 'Stainless Steel' },
        { value: 'Steel', label: 'Steel' },
        { value: 'Steel Aluminum', label: 'Steel Aluminum' },
        { value: 'Teak', label: 'Teak' },
        { value: 'Wood', label: 'Wood' },
        { value: 'Wood Epoxy', label: 'Wood Epoxy' },
        { value: 'Wood on Iron Frame', label: 'Wood on Iron Frame' }
    ],
    hull_type: [
        { value: 'Displacement', label: 'Displacement' },
        { value: 'Semi-Displacement', label: 'Semi-Displacement' },
        { value: 'Fast Displacement', label: 'Fast Displacement' },
        { value: 'Planing', label: 'Planing' }
    ],
    flags: [
        { value: 'Antigua & Barbuda', label: 'Antigua & Barbuda' },
        { value: 'Australia', label: 'Australia' },
        { value: 'Austria', label: 'Austria' },
        { value: 'Bahamas', label: 'Bahamas' },
        { value: 'Barbados', label: 'Barbados' },
        { value: 'Belgium', label: 'Belgium' },
        { value: 'Belize', label: 'Belize' },
        { value: 'Bermuda', label: 'Bermuda' },
        { value: 'Brazil', label: 'Brazil' },
        { value: 'British', label: 'British' },
        {
            value: 'British Virgin Islands',
            label: 'British Virgin Islands'
        },
        { value: 'Bulgaria', label: 'Bulgaria' },
        { value: 'Burma', label: 'Burma' },
        { value: 'Cambodia', label: 'Cambodia' },
        { value: 'Canada', label: 'Canada' },
        { value: 'Cayman Islands', label: 'Cayman Islands' },
        { value: 'Chile', label: 'Chile' },
        { value: 'Cook Islands', label: 'Cook Islands' },
        { value: 'Croatia', label: 'Croatia' },
        { value: 'Cyprus', label: 'Cyprus' },
        { value: 'Czech', label: 'Czech' },
        { value: 'Danish Delaware', label: 'Danish Delaware' },
        { value: 'Ecuador', label: 'Ecuador' },
        { value: 'Egypt', label: 'Egypt' },
        { value: 'Fiji', label: 'Fiji' },
        { value: 'Finland', label: 'Finland' },
        { value: 'French', label: 'French' },
        { value: 'Gabon', label: 'Gabon' },
        { value: 'German', label: 'German' },
        { value: 'Gibraltar', label: 'Gibraltar' },
        { value: 'Greek', label: 'Greek' },
        { value: 'Guernsey', label: 'Guernsey' },
        { value: 'Honduras', label: 'Honduras' },
        { value: 'Hong Kong', label: 'Hong Kong' },
        { value: 'India', label: 'India' },
        { value: 'Indonesia', label: 'Indonesia' },
        { value: 'Irish', label: 'Irish' },
        { value: 'Isle of Man', label: 'Isle of Man' },
        { value: 'Italian', label: 'Italian' },
        { value: 'Jamaica', label: 'Jamaica' },
        { value: 'Japan', label: 'Japan' },
        { value: 'Jersey', label: 'Jersey' },
        { value: 'Latvia', label: 'Latvia' },
        { value: 'Liberia', label: 'Liberia' },
        { value: 'Luxembourg', label: 'Luxembourg' },
        { value: 'Malaysia', label: 'Malaysia' },
        { value: 'Maldives', label: 'Maldives' },
        { value: 'Maltese', label: 'Maltese' },
        { value: 'Marshall Islands', label: 'Marshall Islands' },
        { value: 'Mauritius', label: 'Mauritius' },
        { value: 'Mexico', label: 'Mexico' },
        { value: 'Monaco', label: 'Monaco' },
        { value: 'Myanmar', label: 'Myanmar' },
        { value: 'Netherland Antilles ', label: 'Netherland Antilles ' },
        { value: 'Netherlands', label: 'Netherlands' },
        { value: 'New Zealand', label: 'New Zealand' },
        { value: 'Norway', label: 'Norway' },
        { value: 'Oman', label: 'Oman' },
        { value: 'Panama', label: 'Panama' },
        { value: 'Peru', label: 'Peru' },
        { value: 'Poland', label: 'Poland' },
        { value: 'Portugal', label: 'Portugal' },
        { value: 'Russia', label: 'Russia' },
        { value: 'Seychelles', label: 'Seychelles' },
        { value: 'Singapore', label: 'Singapore' },
        { value: 'Slovenia', label: 'Slovenia' },
        { value: 'Spanish', label: 'Spanish' },
        { value: 'St Kitts & Nevis', label: 'St Kitts & Nevis' },
        { value: 'St Maarten', label: 'St Maarten' },
        {
            value: 'St Vincent & the Grenadines',
            label: 'St Vincent & the Grenadines'
        },
        { value: 'Sweden', label: 'Sweden' },
        { value: 'Switzerland', label: 'Switzerland' },
        { value: 'Thailand', label: 'Thailand' },
        { value: 'Turkish', label: 'Turkish' },
        { value: 'Turks & Caicos', label: 'Turks & Caicos' },
        { value: 'UAE', label: 'UAE' },
        { value: 'USA', label: 'USA' },
        { value: 'Venezuela', label: 'Venezuela' }
    ],
    num_engines: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: -1, label: 'Other' }
    ],
    types: [
        { value: 1, label: 'Power' },
        { value: 2, label: 'Sail' },
        { value: -1, label: 'Both' }
    ],
    listing_type: [
        { value: 'Central / Exclusive', label: 'Central / Exclusive' },
        {
            value: 'Co-Central / Non-Exclusive',
            label: 'Co-Central / Non-Exclusive'
        },
        { value: 'Trade in', label: 'Trade in' }
    ],
    condition: [
        { value: 'Used', label: 'Used' },
        { value: 'New', label: 'New' }
    ],
    status: [
        { value: 'for_sale', label: 'For sale' },
        { value: 'not_for_sale', label: 'Withdrawn From Sale' },
        { value: 'pending', label: 'Sale Pending' },
        { value: 'sold', label: 'Sold' }
    ],
    fuel_type: [
        { value: 'Diesel', label: 'Diesel' },
        { value: 'Gas', label: 'Gas' }
    ],
    currency: [
        { value: 'usd', label: 'USD' },
        { value: 'eur', label: 'EURO' },
        { value: 'gbp', label: 'gbp' }
    ],
    length_unit: [
        { value: 'meters', label: 'Meters' },
        { value: 'feet', label: 'Feet' }
    ],
    volume_unit: [
        { value: 'liters', label: 'Liters' },
        { value: 'gallons', label: 'Gallons' }
    ],
    yacht_types: [
        { value: 'Aft Cockpit', label: 'Aft Cockpit', type: 'Both' },
        { value: 'Catamaran', label: 'Catamaran', type: 'Both' },
        { value: 'Classic Yacht', label: 'Classic Yacht', type: 'Both' },
        { value: 'Cruiser', label: 'Cruiser', type: 'Power' },
        { value: 'Cruising Ketch', label: 'Cruising Ketch', type: 'Sail' },
        {
            value: 'Cruising Sailboat',
            label: 'Cruising Sailboat',
            type: 'Sail'
        },
        {
            value: 'Cruising/Racing Sailboat',
            label: 'Cruising/Racing Sailboat',
            type: 'Sail'
        },
        { value: 'Cutter', label: 'Cutter' },
        { value: 'Expedition Yacht', label: 'Expedition Yacht', type: 'Power' },
        { value: 'Flybridge', label: 'Flybridge', type: 'Power' },
        { value: 'Hybrid Yacht', label: 'Hybrid Yacht', type: 'Both' },
        { value: 'High Performance', label: 'High Performance', type: 'Both' },
        { value: 'Houseboat', label: 'Houseboat', type: 'Power' },
        { value: 'Mega Yacht', label: 'Mega Yacht', type: 'Both' },
        { value: 'Motor Yacht', label: 'Motor Yacht', type: 'Power' },
        { value: 'Motorsailor', label: 'Motorsailor', type: 'Both' },
        {
            value: 'Performance Sailboat',
            label: 'Performance Sailboat',
            type: 'Sail'
        },
        { value: 'Racing Sailboat', label: 'Racing Sailboat', type: 'Sail' },
        { value: 'Schooner', label: 'Schooner', type: 'Sail' },
        { value: 'Sloop', label: 'Sloop', type: 'Sail' },
        { value: 'Sport Fisherman', label: 'Sport Fisherman', type: 'Power' },
        { value: 'Support Yacht', label: 'Support Yacht', type: 'Power' },
        { value: 'Tallship', label: 'Tallship', type: 'Sail' },
        { value: 'Tender', label: 'Tender', type: 'Power' },
        { value: 'Trawler', label: 'Trawler', type: 'Power' }
    ],
    yatco_yacht_types: [
        { value: 'Aft Cabin', label: 'Aft Cabin' },
        { value: 'Classic', label: 'Classic' },
        { value: 'Cockpit', label: 'Cockpit' },
        { value: 'Conversion', label: 'Conversion' },
        { value: 'Displacement', label: 'Displacement' },
        { value: 'Enclosed Bridge', label: 'Enclosed Bridge' },
        { value: 'Expedition/Explorer', label: 'Expedition/Explorer' },
        { value: 'Flybridge', label: 'Flybridge' },
        {
            value: 'Flybridge with Euro Transom',
            label: 'Flybridge with Euro Transom'
        },
        { value: 'Liveaboard', label: 'Liveaboard' },
        { value: 'Mega Yacht', label: 'Mega Yacht' },
        { value: 'Motor Yacht', label: 'Motor Yacht' },
        { value: 'Motorsailor', label: 'Motorsailor' },
        { value: 'Ocean Going', label: 'Ocean Going' },
        { value: 'Offshore', label: 'Offshore' },
        { value: 'Pilothouse', label: 'Pilothouse' },
        { value: 'Q Series', label: 'Q Series' },
        { value: 'Raised Pilothouse', label: 'Raised Pilothouse' },
        { value: 'Semi-Displacement', label: 'Semi-Displacement' },
        { value: 'Shadow/Support Vessel', label: 'Shadow/Support Vessel' },
        { value: 'Sky Lounge', label: 'Sky Lounge' },
        { value: 'Sovran', label: 'Sovran' },
        { value: 'Superyacht', label: 'Superyacht' },
        { value: 'Tri-Deck', label: 'Tri-Deck' },
        { value: 'Vintage', label: 'Vintage' },
        { value: 'X Series', label: 'X Series' }
    ],
    curation_level: [
        { value: -1, label: 'Curation level 0' },
        { value: 1, label: 'Curation level 1' },
        { value: 2, label: 'Curation level 2' },
        { value: 3, label: 'Curation level 3' }
    ]
};

export const regionCountries = {
    eu: [
        'Albania',
        'Andorra',
        'Armenia',
        'Austria',
        'Azerbaijan',
        'Belarus',
        'Belgium',
        'Bosnia and Herzegovina',
        'Bulgaria',
        'Croatia',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Estonia',
        'Finland',
        'France',
        'Georgia',
        'Germany',
        'Greece',
        'Hungary',
        'Iceland',
        'Ireland',
        'Italy',
        'Kazakhstan',
        'Kosovo',
        'Latvia',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macedonia',
        'Malta',
        'Moldova',
        'Monaco',
        'Montenegro',
        'Netherlands',
        'Norway',
        'Poland',
        'Portugal',
        'Romania',
        'Russia',
        'San Marino',
        'Serbia',
        'Slovakia',
        'Slovenia',
        'Spain',
        'Sweden',
        'Switzerland',
        'Turkey',
        'Ukraine',
        'United Kingdom',
        'Vatican City State'
    ],
    usa: ['United States'],
    'am-sc': [
        'Argentina',
        'Aruba',
        'Bahamas',
        'Barbados',
        'Belize',
        'Bolivia',
        'Brazil',
        'Cayman Islands',
        'Chile',
        'Colombia',
        'Costa Rica',
        'Cuba',
        'Dominica',
        'Dominican Republic',
        'Ecuador',
        'El Salvador',
        'French Guiana',
        'Grenada',
        'Guadeloupe',
        'Guatemala',
        'Guyana',
        'Haiti',
        'Honduras',
        'Jamaica',
        'Martinique',
        'Mexico',
        'Nicaragua',
        'Panama',
        'Paraguay',
        'Peru',
        'Puerto Rico',
        'Suriname',
        'Uruguay',
        'Venezuela'
    ]
};

export const yachtStatuses = [
    { value: 'for_sale', label: 'For sale' },
    { value: 'not_for_sale', label: 'Withdrawn From Sale' },
    { value: 'pending', label: 'Sale Pending' },
    { value: 'sold', label: 'Sold' }
];

export const yachtStates = [
    { value: 'in_build', label: 'In Build' },
    { value: 'in_operation', label: 'In Operation' },
    { value: 'on_hold', label: 'On Hold' },
    { value: 'out_of_service', label: 'Out of Service' },
    { value: 'total_loss', label: 'Total Loss' },
    { value: 'cancelled', label: 'Cancelled' },
    { value: 'under_renovation', label: 'Under Renovation' },
    { value: 'project', label: 'Project' },
    { value: 'inactive', label: 'Inactive' },
    { value: 'other', label: 'Other' }
];

export const yachtCharterStates = [
    { value: 'in_operation', label: 'In Operation' },
    { value: 'inactive', label: 'Inactive' }
];

/*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/
Array.prototype.move = function (from, to) {
    return this.splice(to, 0, this.splice(from, 1)[0]);
};

export const yachtIncludes = {
    sales: [
        { group: 'elevator', label: 'Elevator', sales: 1, charters: 1 },
        { group: 'helipad', label: 'Helipad', sales: 1, charters: 1 },
        { group: 'gym', label: 'Gym', sales: 1, charters: 1 },
        { group: 'jacuzzi', label: 'Jacuzzi', sales: 1, charters: 1 },
        {
            group: 'handicap',
            label: 'Wheelchair access',
            sales: 1,
            charters: 1
        },
        { group: 'beach_club', label: 'Beach Club', sales: 1, charters: 1 },
        { group: 'spa', label: 'Spa', sales: 1, charters: 1 },
        {
            group: 'night_vision_camera',
            label: 'Night vision camera',
            sales: 1,
            charters: 0
        }
    ],
    charters: [
        { group: 'elevator', label: 'Elevator', sales: 1, charters: 1 },
        { group: 'helipad', label: 'Helipad', sales: 1, charters: 1 },
        { group: 'gym', label: 'Gym', sales: 1, charters: 1 },
        { group: 'jacuzzi', label: 'Jacuzzi', sales: 1, charters: 1 },
        { group: 'stabilizers', label: 'Stabilizers', sales: 0, charters: 1 },
        {
            group: 'zero_speed_stabilizers',
            label: 'Zero speed stabilizers',
            sales: 0,
            charters: 1
        },
        {
            group: 'handicap',
            label: 'Wheelchair access',
            sales: 1,
            charters: 1
        },
        { group: 'beach_club', label: 'Beach Club', sales: 1, charters: 1 },
        { group: 'spa', label: 'Spa', sales: 1, charters: 1 },
        { group: 'barbeque', label: 'Barbeque', sales: 1, charters: 1 },
        { group: 'wifi', label: 'Wifi', sales: 0, charters: 1 },
        {
            group: 'master_on_main_deck',
            label: 'Master on main deck',
            sales: 0,
            charters: 1
        }
    ]
};
