import Notifications from 'react-notification-system-redux';

import { UNAUTH_USER } from '../auth/_types';

import alerts from '../locales/alerts';

let def = 'en';

export function hideNotification(uid, message) {
  return dispatch => {
    dispatch(Notifications.hide(uid));
  };
}

export function showotification(notification, level) {
  return dispatch => {
    dispatch(Notifications.show(notification, level));
  };
}

export function successHandler(dispatch, messageKey, extraOpts, type) {
  let notificationOpts = {
    ...extraOpts,
    title: alerts.success[messageKey][def].title,
    message: alerts.success[messageKey][def].message
  };

  if (type === 'info') dispatch(Notifications.info(notificationOpts));
  else dispatch(Notifications.success(notificationOpts));
}

export function errorHandler(error) {
  return dispatch => {
  let notificationOpts = {
    title: alerts.error.general[def].title,
    position: 'tc',
    autoDismiss: 0
  };

  if (error && error.status) {
    if (error.data && error.data.message && !error.data.message.message) {
      notificationOpts.message = error.data.message;
    } else if (error.data && error.data.message.message && error.data.message.message) {
      notificationOpts.message = error.data.message.message;
    }

    if (error.status === 400) {
      // (Bad Request)
      if (error.data && error.data.error === 'token_invalid') {
        try {
          localStorage.removeItem('state');
          dispatch({ type: UNAUTH_USER });
        } catch (err) {
          // Handle errors
          dispatch({ type: UNAUTH_USER });
        }
      } else {
        notificationOpts.title = alerts.error.api[400][def].title;

        if (!notificationOpts.message) {
          notificationOpts.message = alerts.error.api[400][def].message;
        }

        dispatch(Notifications.error(notificationOpts));
      }
    } else if (error.status === 401) {
      // (Unauthorized)
      if (error.data && error.data.error === 'token_invalid') {
        try {
          localStorage.removeItem('state');
          dispatch({ type: UNAUTH_USER });
        } catch (err) {
          // Handle errors
          dispatch({ type: UNAUTH_USER });
        }
      } else {
        notificationOpts.title = alerts.error.api[401][def].title;
        if (!notificationOpts.message) {
          notificationOpts.message = alerts.error.general[def].message;
        }

        dispatch(Notifications.error(notificationOpts));
      }
    } else if (error.status === 404) {
      // (Not Found)
      notificationOpts.title = alerts.error.api[404][def].title;

      if (!notificationOpts.message) {
        notificationOpts.message = alerts.error.api[404][def].message;
      }
      dispatch(Notifications.error(notificationOpts));
    } else if (error.status === 422) {
      // (Unprocessable Entity)
      notificationOpts.title = alerts.error.api[422][def].title;

      if (!notificationOpts.message) {
        notificationOpts.message = alerts.error.api[422][def].message;
      }
      dispatch(Notifications.error(notificationOpts));
    } else if (error.status === 500) {
      // (Internal server error)
      notificationOpts.title = alerts.error.api[500][def].title;

      if (!notificationOpts.message) {
        notificationOpts.message = alerts.error.api[500][def].message;
      }
      dispatch(Notifications.error(notificationOpts));
    } else {
      notificationOpts.title = alerts.error.general[def].title;

      if (!notificationOpts.message) {
        notificationOpts.message = alerts.error.general[def].message;
      }
      dispatch(Notifications.error(notificationOpts));
    }
  } else {
    if (error && error.message) {
      notificationOpts.title = alerts.error.general[def].title;
      notificationOpts.message = alerts.error.general[def].message;
    }

    dispatch(Notifications.error(notificationOpts));
  }
}
}