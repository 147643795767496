import React, { Component } from 'react';
import { Field } from 'redux-form';

import { required } from '../../helpers/validate';

import RenderFavorite from '../../common/components/RenderFavorite';
import RenderField from '../../common/components/RenderField';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import RenderRadio from '../../common/components/RenderRadio';
import { UncontrolledTooltip } from 'reactstrap';

class SectionGeneral extends Component {
    render() {
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-3">
                        <Field
                            name="title"
                            readOnly={this.props.readOnly}
                            placeholder="TITLE"
                            component={RenderReactSelect}
                            clearable={true}
                            options={[
                                { value: 'mr', label: 'Mr.' },
                                { value: 'mrs', label: 'Mrs' },
                                { value: 'miss', label: 'Miss' },
                                { value: 'dr', label: 'Dr.' },
                                { value: 'sir', label: 'Sir' },
                                { value: 'ms', label: 'Ms.' }
                            ]}
                            className="mb-1"
                        />
                    </div>
                    <div className="col-3">
                        <Field
                            name="first_name"
                            type="text"
                            readOnly={this.props.readOnly}
                            disabled={this.props.readOnly}
                            validate={[required]}
                            placeholder="FIRST NAME"
                            component={RenderField}
                            className="mb-1"
                        />
                    </div>
                    <div className="col-3">
                        <Field
                            name="middle_name"
                            type="text"
                            readOnly={this.props.readOnly}
                            disabled={this.props.readOnly}
                            placeholder="MIDDLE NAME"
                            component={RenderField}
                            className="mb-1"
                        />
                    </div>
                    <div className="col-3">
                        <Field
                            name="last_name"
                            type="text"
                            readOnly={this.props.readOnly}
                            disabled={this.props.readOnly}
                            placeholder="LAST NAME"
                            validate={[required]}
                            component={RenderField}
                            className="mb-1"
                        />
                    </div>
                </div>
                <div className="row">
                    {/*}
						<RenderFavorite
							disabled={this.props.readOnly}
							favourite={this.props.favourite}
							height={24}
							className="ml-1 mb-1"
						/>*/}

                    <div className="col-3">
                        <Field
                            name="company"
                            type="text"
                            readOnly={this.props.readOnly}
                            disabled={this.props.readOnly}
                            placeholder="COMPANY"
                            component={RenderField}
                            className="mb-1"
                        />
                    </div>
                    <div className="col-3">
                        <Field
                            name="position"
                            type="text"
                            readOnly={this.props.readOnly}
                            disabled={this.props.readOnly}
                            placeholder="POSITION"
                            component={RenderField}
                            className="mb-1"
                        />
                    </div>
                    <div className="col-3">
                        <Field
                            name="industry"
                            readOnly={this.props.readOnly}
                            placeholder="INDUSTRY"
                            component={RenderReactSelect}
                            options={this.props.industries}
                            className="mb-1"
                        />
                    </div>
                    <div className="col-3">
                        <Field
                            name="marital_status"
                            readOnly={this.props.readOnly}
                            placeholder="Marital Status"
                            component={RenderReactSelect}
                            options={[
                                { value: 'married', label: 'Married' },
                                { value: 'single', label: 'Single' },
                                { value: 'divorced', label: 'Divorced' },
                                { value: 'widowed', label: 'Widowed' }
                            ]}
                            className="mb-1"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 mt-2 d-flex align-items-baseline">
                        <div
                            className="btn btn-indication mr-1"
                            id="YearTooltip"
                            style={{ margin: '-35px 0 0 0' }}
                        >
                            i
                        </div>
                        <UncontrolledTooltip
                            placement="right"
                            target="YearTooltip"
                        >
                            You will receive a notification on your client's
                            birthday.
                        </UncontrolledTooltip>
                        <div className="radio-group-label">BIRTHDAY:</div>
                    </div>
                    <div className="col-3">
                        <Field
                            name="birth_day"
                            readOnly={this.props.readOnly}
                            placeholder="Day"
                            component={RenderReactSelect}
                            options={this.props.daysArray}
                            className="mb-2"
                        />
                    </div>
                    <div className="col-3">
                        <Field
                            name="birth_month"
                            readOnly={this.props.readOnly}
                            placeholder="Month"
                            component={RenderReactSelect}
                            options={this.props.monthsArray}
                            className="mb-2"
                        />
                    </div>
                    <div className="col-3">
                        <Field
                            name="birth_year"
                            readOnly={this.props.readOnly}
                            placeholder="Year"
                            component={RenderReactSelect}
                            options={this.props.arrayYears}
                            className="mb-2"
                            clearable={false}
                        />
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-3 d-flex align-items-center">
                        <div className="radio-group-label">Way of address:</div>
                    </div>
                    <div className="col-3 d-flex align-items-center">
                        <Field
                            group="way_of_address"
                            name="way_of_address"
                            label="None"
                            readOnly={this.props.readOnly}
                            disabled={this.props.readOnly}
                            radioValue="none"
                            component={RenderRadio}
                            className="mb-0"
                            reduxEnabled={true}
                        />
                        <Field
                            group="way_of_address"
                            name="way_of_address"
                            label="Formal"
                            readOnly={this.props.readOnly}
                            disabled={this.props.readOnly}
                            radioValue="formal"
                            component={RenderRadio}
                            className="mb-0 ml-2"
                            reduxEnabled={true}
                        />
                    </div>
                    <div className="col-3 d-flex align-items-center">
                        <Field
                            group="way_of_address"
                            name="way_of_address"
                            label="Informal"
                            readOnly={this.props.readOnly}
                            disabled={this.props.readOnly}
                            radioValue="informal"
                            component={RenderRadio}
                            className="mb-0"
                            reduxEnabled={true}
                        />
                    </div>
                    <div className="col-3  d-flex align-items-center">
                        <div className="radio-group-label">Favourite:</div>
                        <RenderFavorite
                            disabled={this.props.readOnly}
                            favourite={this.props.favourite}
                            height={24}
                            tooltip={false}
                            className="ml-1 mb-0  d-flex align-items-center"
                        />
                    </div>
                </div>
            </div>
        );
    }
}
export default SectionGeneral;
