import { loadState } from '../localStorage';

export function authConfig(options) {
  const state = loadState();
  let token = null;

  if (typeof state !== 'undefined') {
    if (state.auth && state.auth.token) token = state.auth.token;
  }
  let header = {
    Authorization: 'Bearer ' + token
  };

  if (options) {
    header['Content-Type'] = 'text/plain;charset=UTF-8';
  }
  return header;
}
