export const machinery = [
  {
    name: 'range',
    value: '',
    type: 'number',
    placeholder: 'RANGE'
  },
  {
    name: 'machinery_info',
    value: '',
    type: 'textarea',
    placeholder: 'INFO'
  }
];
