import axios from 'axios';

import {
    INIT_YACHT_LIST,
    FETCH_YACHT_LISTS,
    FETCH_YACHT_LISTS_SUCCESS,
    FETCH_YACHT_LISTS_ERROR,
    CREATE_YACHT_LIST,
    CREATE_YACHT_LIST_SUCCESS,
    CREATE_YACHT_LIST_ERROR,
    FETCH_YACHT_LIST,
    FETCH_YACHT_LIST_SUCCESS,
    FETCH_YACHT_LIST_ERROR,
    UPDATE_YACHT_LIST,
    UPDATE_YACHT_LIST_SUCCESS,
    UPDATE_YACHT_LIST_ERROR,
    DELETE_YACHT_LIST,
    DELETE_YACHT_LIST_SUCCESS,
    DELETE_YACHT_LIST_ERROR
} from './_types';

import { API_URL } from '../../../helpers/variables';
import { errorHandler, successHandler } from '../../../helpers/alerts';
import { authConfig } from '../../../helpers/authConfig';

const initYachtList = () => {
    return (dispatch) => dispatch({ type: INIT_YACHT_LIST });
};

const fetchYachtLists = (params) => {
    return (dispatch) => {
        dispatch({ type: FETCH_YACHT_LISTS });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/yacht-lists', config)
            .then((response) => {
                dispatch({
                    type: FETCH_YACHT_LISTS_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch((error) => {
                dispatch({ type: FETCH_YACHT_LISTS_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchYachtListsNoPagination = (params) => {
    return (dispatch) => {
        dispatch({ type: FETCH_YACHT_LISTS });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/yacht-lists/list', config)
            .then((response) => {
                dispatch({
                    type: FETCH_YACHT_LISTS_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch((error) => {
                dispatch({ type: FETCH_YACHT_LISTS_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const createYachtList = (params) => {
    return (dispatch) => {
        dispatch({ type: CREATE_YACHT_LIST });

        const options = {
            headers: authConfig()
        };

        return axios
            .post(API_URL + '/yacht-lists', params, options)
            .then((response) => {
                if (!response.data.error) {
                    dispatch({
                        type: CREATE_YACHT_LIST_SUCCESS,
                        payload: response.data
                    });
                    successHandler(dispatch, 'yacht_list_created');
                }

                return response.data;
            })
            .catch((error) => {
                dispatch({ type: CREATE_YACHT_LIST_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

const fetchYachtList = (id, params) => {
    return (dispatch) => {
        dispatch({ type: FETCH_YACHT_LIST });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/yacht-lists/' + id, config)
            .then((response) => {
                dispatch({
                    type: FETCH_YACHT_LIST_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch((error) => {
                console.log(error);
                dispatch({ type: FETCH_YACHT_LIST_ERROR });
                errorHandler(dispatch, error.response);
            });
    };
};

let previousCancelToken;

const updateYachtList = (id, params) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_YACHT_LIST });

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const options = {
            headers: authConfig(),
            cancelToken: source.token
        };

        if (previousCancelToken) {
            previousCancelToken.cancel();
        }

        previousCancelToken = source;

        return axios
            .put(API_URL + '/yacht-lists/' + id, params, options)
            .then((response) => {
                dispatch({
                    type: UPDATE_YACHT_LIST_SUCCESS,
                    payload: response.data
                });
                successHandler(dispatch, 'yacht_list_updated');

                return response.data;
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    dispatch({ type: UPDATE_YACHT_LIST_ERROR });
                    errorHandler(dispatch, error.response);
                }
            });
    };
};

const deleteYachtList = (id) => {
    return (dispatch) => {
        dispatch({ type: DELETE_YACHT_LIST });

        const options = {
            headers: authConfig(),
            params: {
                detach_yachts: true
            }
        };

        return axios
            .delete(API_URL + '/yacht-lists/' + id, options)
            .then((response) => {
                dispatch({
                    type: DELETE_YACHT_LIST_SUCCESS,
                    payload: response.data
                });
                successHandler(dispatch, 'yacht_list_deleted');

                return response.data;
            })
            .catch((error) => {
                dispatch({ type: DELETE_YACHT_LIST_ERROR });

                errorHandler(dispatch, error.response);
            });
    };
};

export {
    initYachtList,
    fetchYachtLists,
    fetchYachtListsNoPagination,
    createYachtList,
    fetchYachtList,
    updateYachtList,
    deleteYachtList
};
