import {
    FETCH_COMMON_DESTINATIONS,
    FETCH_COMMON_DESTINATIONS_SUCCESS,
    FETCH_COMMON_DESTINATIONS_FAIL,
    FETCH_COMMON_COUNTRIES,
    FETCH_COMMON_COUNTRIES_SUCCESS,
    FETCH_COMMON_COUNTRIES_FAIL,
    FETCH_COMMON_STATES,
    FETCH_COMMON_STATES_SUCCESS,
    FETCH_COMMON_STATES_FAIL,
    FETCH_COMMON_LOCATIONS,
    FETCH_COMMON_LOCATIONS_SUCCESS,
    FETCH_COMMON_LOCATIONS_FAIL,
    FETCH_GET_YACHT_LIST,
    FETCH_GET_YACHT_LIST_SUCCESS,
    FETCH_GET_YACHT_LIST_FAIL,
    FETCH_COMMON_YACHT_TYPES,
    FETCH_COMMON_YACHT_TYPES_SUCCESS,
    FETCH_COMMON_YACHT_TYPES_FAIL,
    TOGGLE_SIDEBAR
} from './types';

import { functions } from '../functions';

const INITIAL_STATE = {
    countries: [],
    states: [],
    destinations: [],
    locations: [],
    yachts: [],
    types: [],
    windowWidth: functions.getWindowSize() > 1200
};

export default function CommonReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_GET_YACHT_LIST:
        case FETCH_GET_YACHT_LIST_SUCCESS:
            return {
                ...state,
                yachts: action.payload
            };
        case FETCH_GET_YACHT_LIST_FAIL:
            return { ...state, error: action.payload };
        case FETCH_COMMON_COUNTRIES:
        case FETCH_COMMON_YACHT_TYPES:
            return state;
        case FETCH_COMMON_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: action.payload
            };
        case FETCH_COMMON_YACHT_TYPES_SUCCESS:
            return {
                ...state,
                types: action.payload
            };
        case FETCH_COMMON_YACHT_TYPES_FAIL:
        case FETCH_COMMON_COUNTRIES_FAIL:
            return { ...state, error: action.payload };
        case FETCH_COMMON_STATES:
            return state;
        case FETCH_COMMON_STATES_SUCCESS:
            return {
                ...state,
                states: action.payload
            };
        case FETCH_COMMON_STATES_FAIL:
            return { ...state, error: action.payload };
        case FETCH_COMMON_DESTINATIONS:
            return state;
        case FETCH_COMMON_DESTINATIONS_SUCCESS:
            return {
                ...state,
                destinations: action.payload
            };
        case FETCH_COMMON_DESTINATIONS_FAIL:
            return { ...state, error: action.payload };
        case FETCH_COMMON_LOCATIONS:
            return state;
        case FETCH_COMMON_LOCATIONS_SUCCESS:
            return {
                ...state,
                locations: action.payload
            };
        case FETCH_COMMON_LOCATIONS_FAIL:
            return { ...state, error: action.payload };
        case TOGGLE_SIDEBAR:
            return { ...state, windowWidth: !state.windowWidth };
        default:
            return state;
    }
}
