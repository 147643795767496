import React from 'react';
import { connect } from 'react-redux';

import ManageYachtBookingPresenter from './ManageYachtBookingPresenter';

import { fetchBooking, fetchDeleteBooking } from '../../bookings/redux/_actions';

class ViewYachtBookingContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            isReadOnly: true,
            pageTitle: 'VIEW BOOKING',
            isModalOpen: false
        };
    }

    componentDidMount() {
        const id = this.props.match.params.booking_id;

        this.props
            .fetchBooking(id)
            .then(response => this.setState({ loading: false }));
    }

    onDeleteConfirmed() {
        const yachtID = this.props.match.params.id;
        const bookingID = this.props.match.params.booking_id;

        this.props
            .fetchDeleteBooking(bookingID)
            .then(response => this.props.history.push(`/yachts/view/charter/${yachtID}/bookings/`))
    }

    onToggleModal() {
        this.setState({ isModalOpen: !this.state.isModalOpen })
    }

    render() {
        return (
            <ManageYachtBookingPresenter
                loading={this.state.loading}
                user_role={this.props.user_role}
                history={this.props.history}
                match={this.props.match}
                pageTitle={this.state.pageTitle}
                isReadOnly={this.state.isReadOnly}
                isModalOpen={this.state.isModalOpen}
                onSubmit={() => {}}
                onToggleModal={this.onToggleModal.bind(this)}
                onDeleteConfirmed={this.onDeleteConfirmed.bind(this)}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        user_role: state.auth.user_role
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchBooking: id => dispatch(fetchBooking(id)),
        fetchDeleteBooking: id => dispatch(fetchDeleteBooking(id))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewYachtBookingContainer);
