export const FETCH_CUSTOMERS = 'fetch_customers',
  FETCH_CUSTOMERS_SUCCESS = 'fetch_customers_success',
  FETCH_CUSTOMERS_FAIL = 'fetch_customers_fail',
  FETCH_MANAGE_CUSTOMER = 'fetch_manage_customer',
  FETCH_MANAGE_CUSTOMER_SUCCESS = 'fetch_manage_customer_success',
  FETCH_MANAGE_CUSTOMER_FAIL = 'fetch_manage_customer_fail',
  FETCH_GET_CUSTOMER = 'fetch_get_customer',
  FETCH_GET_CUSTOMER_SUCCESS = 'fetch_get_customer_success',
  FETCH_GET_CUSTOMER_FAIL = 'fetch_get_customer_fail',
  INIT_CUSTOMER = 'init_customer',
  FETCH_DELETE_CUSTOMER = 'fetch_delete_customer',
  FETCH_DELETE_CUSTOMER_SUCCESS = 'fetch_delete_customer_success',
  FETCH_DELETE_CUSTOMER_FAIL = 'fetch_delete_customer_fail',
  FETCH_CUSTOMER_ENQUIRIES = 'fetch_customer_enquiries',
  FETCH_CUSTOMER_ENQUIRIES_SUCCESS = 'fetch_customer_enquiries_success',
  FETCH_CUSTOMER_ENQUIRIES_FAIL = 'fetch_customer_enquiries_fail';
