import React from 'react';
import { NavLink } from 'react-router-dom';
import deleteSVG from '../../assets/svgs/delete.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import yachts from '../../assets/svgs/yachts_default.svg';

const showYachtPhoto = (viewType, yacht) => {
    if (
        (viewType === 'sales' && yacht.photo) ||
        (viewType === 'charter' && !yacht.charter_photo && yacht.photo)
    ) {
        return { backgroundImage: `url(${yacht.photo})` };
    }

    if (
        (viewType === 'sales' && !yacht.photo && yacht.charter_photo) ||
        (viewType === 'charter' && yacht.charter_photo)
    ) {
        return { backgroundImage: `url(${yacht.charter_photo})` };
    }

    return {
        backgroundImage: `url(${yachts})`,
        backgroundSize: '80px 70px'
    };
};

const Yacht = ({ yacht, viewType, onRemoveFromSelection }) => (
    <div className="yacht-wrap row no-gutters">
        <div className="col-4">
            <div
                className="yacht-image"
                style={showYachtPhoto(viewType, yacht)}
            />
        </div>
        <div className="col-6 pl-1">
            <NavLink to={`/yachts/view/${viewType}/${yacht.id}`}>
                {yacht.name}
            </NavLink>
            <div className="font-400 small-head mt-1">
                ID: {yacht.vessel_id}
            </div>
            <div className="font-400 small-head mt-1">{yacht.type}</div>
        </div>
        <div className="col-2 text-center">
            <button
                className="btn btn-td-action"
                onClick={(e) => onRemoveFromSelection(e, yacht)}
            >
                <div
                    className="selection-remove-icon"
                    style={{ backgroundImage: `url(${deleteSVG})` }}
                />
            </button>
        </div>
    </div>
);

export default class SelectedYachts extends React.Component {
    getFooter() {
        const { yachts } = this.props;

        return (
            yachts.total - yachts.selection.length > 0 && (
                <div className="yacht-wrap row no-gutters justify-content-center">
                    <div className="more-yachts">
                        {`${
                            yachts.total - yachts.selection.length
                        } more yachts`}
                    </div>
                </div>
            )
        );
    }

    getList() {
        const {
            yachts,
            onRemoveFromSelection,
            onOrderChanged,
            viewType
        } = this.props;
        return (
            <DragDropContext onDragEnd={onOrderChanged}>
                <Droppable droppableId="droppable-1">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {yachts.selection.map((yacht, i) => (
                                <Draggable
                                    key={yacht.id}
                                    draggableId={`${yacht.id}`}
                                    index={i}
                                >
                                    {(provided, snapshot) => (
                                        <div>
                                            <div
                                                key={i}
                                                ref={provided.innerRef}
                                                style={provided.draggableStyle}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={
                                                    snapshot.isDragging
                                                        ? 'component-dragged'
                                                        : ''
                                                }
                                            >
                                                <Yacht
                                                    yacht={yacht}
                                                    viewType={viewType}
                                                    onRemoveFromSelection={
                                                        onRemoveFromSelection
                                                    }
                                                />
                                            </div>
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    render() {
        return (
            <div className="yacht-row-wrapper result-yacht-wrapper">
                {this.getList()}
                {this.getFooter()}
            </div>
        );
    }
}
