import React from 'react';
import { Field } from 'redux-form';

import RenderField from '../../../common/components/RenderField';
// Helpers
import { required } from '../../../helpers/validate';

let RenderYachtListsSuggestions = (props) => {
    return (
        <ul className="list-unstyled companies-list">
            <li>
                <Field
                    name="yacht_list_search"
                    type="text"
                    placeholder="SEARCH YACHT LIST"
                    component={RenderField}
                    onChange={(event, value) => props.change(event, value)}
                    autoComplete={false}
                    validate={[required]}
                />

                {props.is_active &&
                props.suggestions &&
                props.suggestions.length > 0 ? (
                    <ul className="list-unstyled results-list">
                        {props.suggestions.map((suggestion, i) => {
                            return (
                                <li
                                    key={i}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.select(suggestion);
                                    }}
                                    style={{
                                        margin: '12px',
                                        color: '#41506e',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {suggestion
                                        ? suggestion.internal_title
                                        : ''}
                                </li>
                            );
                        })}
                    </ul>
                ) : null}
            </li>
        </ul>
    );
};

export default RenderYachtListsSuggestions;
