import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';

import logo from '../assets/svgs/logo.svg';
import logoDavinci from '../assets/svgs/logo-davinci.svg';

class Home extends Component {
    render() {
        return (
            <div className="login-wrapper home-wrapper d-flex align-items-center">
                <div className="brand-wrap mb-1">
                    <ReactSVG style={{ height: 48 }} path={logo} />
                </div>
                <div className="mb-1">
                    <strong>Choose where you want to redirect to.</strong>
                </div>

                <a
                    href={process.env.REACT_APP_SITE_URL}
                    className="home-link bigger-home-link"
                >
                    <span className="link-head">Go to public site</span>
                    <span className="link-text">iyc.com</span>
                </a>

                <div className="home-divader">or</div>

                <Link
                    to={
                        this.props.authenticated &&
                        this.props.user_role &&
                        this.props.user_role !== 'broker' &&
                        this.props.user_role !== 'broker_sales' &&
                        this.props.user_role !== 'broker_charter'
                            ? '/dashboard'
                            : this.props.authenticated &&
                              this.props.user_role &&
                              (this.props.user_role === 'broker' ||
                                  this.props.user_role === 'broker_sales' ||
                                  this.props.user_role === 'broker_charter')
                            ? this.props.user_role === 'broker_sales'
                                ? '/clients/sales'
                                : '/clients/charters'
                            : '/login'
                    }
                    className="home-link"
                >
                    <ReactSVG path={logoDavinci} style={{ height: 48 }} />
                    <span className="link-head">
                        Continue to Davinci Yacht Application
                    </span>
                    <span className="link-text">davinci.iyc.com</span>
                </Link>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated,
        user_role: state.auth.user_role
    };
}

export default connect(
    mapStateToProps,
    {}
)(Home);
