import React, { Component } from 'react';
import {
    yachtStates,
    yachtCharterStates,
    yachtStatuses
} from '../../helpers/variables';
import { Radio } from '../../common/components/simpleInputs';
import Datetime from 'react-datetime';

import Select from 'react-select';
import moment from 'moment';

const Actions = ({ onSubmit, onCancel }) => (
    <div className="button-container d-flex justify-content-end">
        <button onClick={onSubmit} type="button" className="btn btn-primary">
            Save
        </button>
        <button onClick={onCancel} type="button" className="btn btn-secondary">
            Cancel
        </button>
    </div>
);

class StatusEditComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: this.props.viewType,
            status: this.props.yacht.status,
            status_detail: this.props.yacht.status_detail,
            changed_at:
                this.props.yacht.changed_at ||
                moment().format('YYYY-MM-DD HH:mm:ss'),
            showDatePicker: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleSubmit() {
        const { status, status_detail, changed_at } = this.state;
        this.props.onStatusChange &&
            this.props.onStatusChange({ status, status_detail, changed_at });
    }

    handleChange(event) {
        if (event.target.name === 'status') {
            this.setState({ showDatePicker: true });
        }
        this.setState({
            [event.target.name]: event.target.value,
            changed_at: moment().format('YYYY-MM-DD HH:mm:ss')
        });
    }

    handleDateChange(date) {
        this.setState({
            changed_at: date.format('YYYY-MM-DD HH:mm:ss')
        });
    }

    valid(current) {
        let today = Datetime.moment();
        return current.isBefore(today);
    }

    render() {
        return (
            <div className="status-edit">
                <div className="status-dropdown p-2">
                    {this.props.yacht.sales === 1 && (
                        <div>
                            <div className="mb-1 heading-label">
                                Sale Status
                            </div>
                            {yachtStatuses.map(({ label, value }, i) => (
                                <Radio
                                    name="status"
                                    label={label}
                                    value={value}
                                    onChange={this.handleChange}
                                    checked={this.state.status === value}
                                    key={i}
                                />
                            ))}

                            {this.state.showDatePicker && (
                                <div className="mb-2 row col-12  align-items-center customDatePicker">
                                    <label className="heading-label">
                                        Status changed at:{' '}
                                    </label>
                                    <Datetime
                                        name="changed_at"
                                        inputProps={{ placeholder: 'Date' }}
                                        className="col-4"
                                        dateFormat={'YYYY-MM-DD'}
                                        timeFormat={false}
                                        timeConstraints={false}
                                        value={moment(
                                            this.state.changed_at
                                        ).format('DD-MM-YYYY')}
                                        isValidDate={this.valid.bind(this)}
                                        onChange={this.handleDateChange}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    <div className="d-flex align-items-center mb-2">
                        <div className="mr-1 heading-label">Vessel State</div>
                        <div className="custom-select-wrapper floating-label-wrapper react-select-wrapper form-group">
                            <Select
                                value={this.state.status_detail}
                                options={
                                    this.props.viewType === 'sales'
                                        ? yachtStates
                                        : yachtCharterStates
                                }
                                onChange={selected =>
                                    this.setState({ status_detail: selected })
                                }
                                simpleValue={true}
                            />
                        </div>
                    </div>
                    <Actions
                        onSubmit={this.handleSubmit}
                        onCancel={this.props.closeModal}
                    />
                </div>
            </div>
        );
    }
}

export default StatusEditComponent;
