import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Field,
    FieldArray,
    reduxForm,
    formValueSelector,
    change
} from 'redux-form';
import { ModalBody, ModalFooter, Button } from 'reactstrap';

import _without from 'lodash/without';

import {
    fetchCustomers,
    fetchManageCustomer
} from '../../customers/customers/_actions';

import { email, required } from '../../helpers/validate';

import RenderField from '../../common/components/RenderField';
import RenderFieldArray from '../../common/components/RenderFieldArray';
import RenderRadio from '../../common/components/RenderRadio';
import RenderReactSelect from '../../common/components/RenderReactSelect';

import _orderBy from 'lodash/orderBy';

const form = reduxForm({
    form: 'customer_selection'
});
const selector = formValueSelector('customer_selection');

class CustomerSelection extends Component {
    componentDidMount() {
        this.props.fetchCustomers({
            format: 'dropdown'
        });
    }

    handleFormSubmit(formProps) {
        let params = Object.assign({}, formProps);

        let passed_state = {
            selection: this.props.default_selection
        };

        if (this.props.default_selection === 'charter') {
            if (this.props.charter_rates_locations_dates.date_from) {
                passed_state.date_from = this.props.charter_rates_locations_dates.date_from;
            }

            if (this.props.charter_rates_locations_dates.date_to) {
                passed_state.date_to = this.props.charter_rates_locations_dates.date_to;
            }
        }

        if (formProps.customer_type === 'existing') {
            if (this.props.type === 'proposal') {
                this.props.history.push({
                    pathname: `/clients/proposals/${params.customer_id}/${this.props.default_selection}/create`,
                    state: passed_state
                });
            }
        } else if (formProps.customer_type === 'new') {
            delete params.customer_type;
            delete params.customer_id;

            if (params.emails.length > 0) {
                params.emails = _without(params.emails, undefined);

                params.emails.map((value, key) => {
                    if (parseInt(params.primary_email) === key) {
                        value.primary = 1;
                    } else {
                        value.primary = 0;
                    }
                    return value;
                });
            } else {
                delete params.emails;
            }

            if (params.phones.length > 0) {
                params.phones = _without(params.phones, undefined);

                params.phones.map((value, key) => {
                    if (parseInt(params.primary_phone) === key) {
                        value.primary = 1;
                    } else {
                        value.primary = 0;
                    }
                    return value;
                });
            } else {
                delete params.phones;
            }

            this.props.fetchManageCustomer(params).then((data) => {
                this.props.history.push({
                    pathname: `/clients/proposals/${data.id}/${this.props.default_selection}/create`,
                    state: passed_state
                });
            });
        }
    }

    render() {
        const { handleSubmit, invalid, submitting } = this.props;

        return (
            <form
                className="modal-wrapper"
                onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
            >
                <ModalBody>
                    <div className="px-3 pt-1">
                        <div className="instructions instructions--info mt-0">
                            <p className="instructions__title">
                                <strong>
                                    Please make sure that you have finalised the
                                    yachts’ order in your proposal
                                </strong>
                                .<br />
                                You will not be able to edit the order, after
                                creating the proposal from the Current
                                Selection.
                            </p>
                        </div>
                    </div>

                    <div className="px-3 py-3">
                        <div className="d-flex justify-content-between">
                            <Field
                                name="customer_type"
                                label="Existing contact"
                                group="customer_type_existing"
                                type="radio"
                                value="existing"
                                component={RenderRadio}
                            />
                            <Field
                                name="customer_type"
                                label="New contact"
                                group="customer_type_new"
                                type="radio"
                                value="new"
                                component={RenderRadio}
                            />
                        </div>
                        <div>
                            {this.props.customer_type === 'existing' ? (
                                <div className="existing_customer_select">
                                    <Field
                                        name="customer_id"
                                        placeholder="CONTACT"
                                        component={RenderReactSelect}
                                        validate={[required]}
                                        options={this.props.customers}
                                        clearable={false}
                                    />
                                </div>
                            ) : (
                                <div className="new_customer_select">
                                    <div className="row">
                                        <div className="col-6">
                                            <Field
                                                name="first_name"
                                                type="text"
                                                validate={[required]}
                                                placeholder="FIRST NAME"
                                                component={RenderField}
                                                className="mb-1"
                                            />
                                        </div>
                                        <div className="col-6">
                                            <Field
                                                name="last_name"
                                                type="text"
                                                validate={[required]}
                                                placeholder="LAST NAME"
                                                component={RenderField}
                                                className="mb-1"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 email-wrap">
                                            <FieldArray
                                                name="emails"
                                                component={RenderFieldArray}
                                                validators={[email]}
                                                readOnly={this.props.readOnly}
                                                className=""
                                                primary={{
                                                    value: this.props
                                                        .primary_email,
                                                    name: 'primary_email'
                                                }}
                                                changeFieldValue={
                                                    this.props.changeFieldValue
                                                }
                                                initFirst={true}
                                                props={{
                                                    customFields: [
                                                        {
                                                            name: 'value',
                                                            type: 'email',
                                                            validators: [
                                                                required,
                                                                email
                                                            ],
                                                            placeholder:
                                                                'EMAIL',
                                                            className: 'col-12'
                                                        },
                                                        {
                                                            name:
                                                                'primary_email',
                                                            label: 'Primary',
                                                            checkboxName:
                                                                'primary',
                                                            groupName:
                                                                'primary_email_check',
                                                            type: 'radio',
                                                            className: 'col-12',
                                                            classNameInner:
                                                                'mb-0'
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                        <div className="col-6 phone-wrap">
                                            <FieldArray
                                                name="phones"
                                                placeholder="ADDITIONAL PHONE"
                                                component={RenderFieldArray}
                                                type="text"
                                                readOnly={this.props.readOnly}
                                                changeFieldValue={
                                                    this.props.changeFieldValue
                                                }
                                                initialValues={
                                                    this.props.initialValues
                                                }
                                                initFirst={true}
                                                primary={{
                                                    value: this.props
                                                        .primary_phone,
                                                    name: 'primary_phone'
                                                }}
                                                props={{
                                                    customFields: [
                                                        {
                                                            name: 'value',
                                                            placeholder:
                                                                'TELEPHONE',
                                                            type: 'mobile',
                                                            className: 'col-12'
                                                        },
                                                        {
                                                            name:
                                                                'primary_phone',
                                                            label: 'Primary',
                                                            checkboxName:
                                                                'primary',
                                                            groupName:
                                                                'primary_phone_check',
                                                            type: 'radio',
                                                            className: 'col-12',
                                                            classNameInner:
                                                                'mb-0'
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <Field
                                                name="location"
                                                type="text"
                                                placeholder="COUNTRY"
                                                component={RenderReactSelect}
                                                options={this.props.countries}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="submit"
                        color="primary"
                        disabled={invalid || submitting}
                    >
                        {this.props.customer_type === 'existing'
                            ? 'Select & create proposal'
                            : 'Save & create proposal'}
                    </Button>{' '}
                    <Button
                        type="button"
                        color="secondary"
                        onClick={this.props.toggle}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </form>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change('customer_selection', field, value)),
        fetchCustomers: (e) => dispatch(fetchCustomers(e)),
        fetchManageCustomer: (e) => dispatch(fetchManageCustomer(e))
    };
}

function mapStateToProps(state) {
    let customers = state.customers.customers.customers.map((customer) => {
        return {
            value: customer.id,
            label: customer.last_name + ' ' + customer.first_name
        };
    });

    return {
        customer_type: selector(state, 'customer_type'),
        customers: _orderBy(customers, ['label'], ['asc']),
        countries: state.common.countries.map((val) => {
            return { value: val.id, label: val.name };
        }),
        primary_email: selector(state, 'primary_email'),
        primary_phone: selector(state, 'primary_phone'),
        initialValues: {
            customer_type: 'existing',
            emails: [],
            phones: [],
            primary_email: '0',
            primary_phone: '0'
        }
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(form(CustomerSelection));
