import axios from 'axios';

import {
    FETCH_MANAGE_SAVED_SEARCH,
    FETCH_MANAGE_SAVED_SEARCH_SUCCESS,
    FETCH_MANAGE_SAVED_SEARCH_FAIL,
    FETCH_SAVED_SEARCHES,
    FETCH_SAVED_SEARCHES_SUCCESS,
    FETCH_SAVED_SEARCHES_FAIL,
    FETCH_SAVED_SEARCH,
    FETCH_SAVED_SEARCH_SUCCESS,
    FETCH_SAVED_SEARCH_FAIL,
    FETCH_SAVED_SEARCH_RESULTS,
    FETCH_SAVED_SEARCH_RESULTS_SUCCESS,
    FETCH_SAVED_SEARCH_RESULTS_FAIL,
    INIT_SAVED_SEARCH,
    FETCH_DELETE_SAVED_SEARCH,
    FETCH_DELETE_SAVED_SEARCH_SUCCESS,
    FETCH_DELETE_SAVED_SEARCH_FAIL
} from './_types';

import { API_URL } from '../helpers/variables';
import { errorHandler, successHandler } from '../helpers/alerts';
import { authConfig } from '../helpers/authConfig';

export function fetchSavedSearches(params) {
    return dispatch => {
        dispatch({ type: FETCH_SAVED_SEARCHES });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/yachts/searches', config)
            .then(response => {
                if (params.format && params.format === 'dropdown') {
                    dispatch({
                        type: FETCH_SAVED_SEARCHES_SUCCESS,
                        payload: {
                            dropdown: response.data
                        }
                    });
                } else {
                    dispatch({
                        type: FETCH_SAVED_SEARCHES_SUCCESS,
                        payload: {
                            searches: response.data.data,
                            pagination: response.data.pagination,
                            sorting: {
                                order_dir: params.order_dir,
                                order_field: params.order_field,
                                page_size: params.page_size,
                                search: params.search ? params.search : ''
                            }
                        }
                    });
                }

                return response.data;
            })
            .catch(error => {
                dispatch({ type: FETCH_SAVED_SEARCHES_FAIL });
                errorHandler(dispatch, error.response);
            });
    };
}

export function fetchSavedSearch(params) {
    return dispatch => {
        dispatch({ type: FETCH_SAVED_SEARCH });

        const config = { headers: authConfig() };

        return axios
            .get(API_URL + '/yachts/searches/' + params.id, config)
            .then(response => {
                dispatch({
                    type: FETCH_SAVED_SEARCH_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch(error => {
                errorHandler(dispatch, error.response, FETCH_SAVED_SEARCH_FAIL);
            });
    };
}

export function fetchManageSavedSearch(params) {
    return dispatch => {
        dispatch({ type: FETCH_MANAGE_SAVED_SEARCH });

        const config = { headers: authConfig() };

        if (params.id) {
            return axios
                .put(API_URL + '/yachts/searches/' + params.id, params, config)
                .then(response => {
                    dispatch({ type: FETCH_MANAGE_SAVED_SEARCH_SUCCESS });
                    successHandler(dispatch, 'manageSearch');

                    return response.data;
                })
                .catch(error => {
                    errorHandler(
                        dispatch,
                        error.response,
                        FETCH_MANAGE_SAVED_SEARCH_FAIL
                    );
                });
        } else {
            return axios
                .post(API_URL + '/yachts/searches', params, config)
                .then(response => {
                    dispatch({ type: FETCH_MANAGE_SAVED_SEARCH_SUCCESS });
                    successHandler(dispatch, 'manageSearch');

                    return response.data;
                })
                .catch(error => {
                    errorHandler(
                        dispatch,
                        error.response,
                        FETCH_MANAGE_SAVED_SEARCH_FAIL
                    );
                });
        }
    };
}

export function fetchSavedSearchResults(params) {
    return dispatch => {
        dispatch({ type: FETCH_SAVED_SEARCH_RESULTS });

        const config = { headers: authConfig(), params: params };

        return axios
            .get(API_URL + '/yachts/searches/' + params.id + '/results', config)
            .then(response => {
                dispatch({
                    type: FETCH_SAVED_SEARCH_RESULTS_SUCCESS,
                    payload: {
                        yachts: response.data.data,
                        pagination: response.data.pagination,
                        sorting: {
                            order_dir: params.order_dir,
                            order_field: params.order_field,
                            page_size: params.page_size,
                            search: params.search ? params.search : ''
                        }
                    }
                });
                console.log(response.data);
                return response.data;
            })
            .catch(error => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_SAVED_SEARCH_RESULTS_FAIL
                );
            });
    };
}

export function initSavedSearch(saved_search) {
    return dispatch => {
        dispatch({ type: INIT_SAVED_SEARCH, payload: saved_search });

        return new Promise(function(resolve, reject) {
            resolve('Success!');
        });
    };
}

export function fetchDeleteSavedSearch(params) {
    return dispatch => {
        dispatch({ type: FETCH_DELETE_SAVED_SEARCH });

        const config = { headers: authConfig() };

        return axios
            .delete(API_URL + '/yachts/searches/' + params.id, config)
            .then(response => {
                dispatch({ type: FETCH_DELETE_SAVED_SEARCH_SUCCESS });
                successHandler(dispatch, 'deleteSavedSearch');

                return response.data;
            })
            .catch(error => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_DELETE_SAVED_SEARCH_FAIL
                );
            });
    };
}
