import React from 'react';
import { FormGroup } from 'reactstrap';

export default function CheckboxField(props) {
    return (
        <FormGroup
            className={
                (props.onDashboard
                    ? 'checkbox-wrapper mb-0 '
                    : 'checkbox-wrapper ') +
                (props.disabled ? ' disabled ' : '') +
                (props.className ? props.className : '')
            }
        >
            <div className="checkbox">
                <label>
                    <input
                        name={props.name}
                        type="checkbox"
                        checked={props.checked}
                        disabled={''}
                        onChange={props.onChange}
                        value={props.value}
                    />
                    <span
                        className={
                            props.onDashboard
                                ? 'checkmark-box dashboard-chbx'
                                : 'checkmark-box'
                        }
                    >
                        {props.label}
                    </span>
                </label>
            </div>
        </FormGroup>
    );
}
