import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';

import preview from '../../assets/svgs/preview.svg';
import yachts from '../../assets/svgs/yachts.svg';

const ManageYachtDecorator = props => WrappedComponent => {
    return class extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                menu: {
                    parents: yachts,
                    menu: [],
                    backLink: '/yachts'
                }
            };
        }

        componentDidMount() {
            let menu = [
                <NavLink
                    exact
                    to={`/yachts/edit/${this.props.match.params.viewType}/${this.props.match.params.id}`}
                >
                    Specs
                </NavLink>,
                <NavLink
                    exact
                    to={`/yachts/edit/${this.props.match.params.viewType}/${this.props.match.params.id}/more`}
                >
                    More Info
                </NavLink>,
                <NavLink
                    exact
                    to={`/yachts/edit/${this.props.match.params.viewType}/${this.props.match.params.id}/photos-docs`}
                >
                    Photos & Docs
                </NavLink>,
                <NavLink
                    exact
                    to={`/yachts/edit/${this.props.match.params.viewType}/${this.props.match.params.id}/videos`}
                >
                    Videos
                </NavLink>,
                <NavLink
                    exact
                    to={`/yachts/edit/charter/${this.props.match.params.id}/locations`}
                >
                    Locations
                </NavLink>,
                <NavLink
                    exact
                    to={`/yachts/edit/charter/${this.props.match.params.id}/rates`}
                >
                    Rates
                </NavLink>,
                {
                    link: (
                        <NavLink
                            className="yacht-nav-link yacht-nav-link-edit"
                            exact
                            to={`/yachts/view/${this.props.match.params.viewType}/${this.props.match.params.id}`}
                        >
                            <span>
                                <ReactSVG
                                    path={preview}
                                    style={{ width: 16 }}
                                />{' '}
                                View
                            </span>
                        </NavLink>
                    ),
                    className: 'ml-auto mr-0'
                }
            ];

            this.setState({
                menu: {
                    ...this.state.menu,
                    items: menu
                }
            });
        }

        render() {
            return <WrappedComponent {...this.props} menu={this.state.menu} />;
        }
    };
};

export default ManageYachtDecorator;
