import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ModalBody } from 'reactstrap';
import _remove from 'lodash/remove';

import {
    loggableYachtFields,
    loggableYachtFieldsUnits,
    vatStatus
} from '../../helpers/variables';
import { dropdowns } from '../../helpers/variables';
import { functions } from '../../helpers/functions';

import { fetchHistoryLog } from './_actions';

class HistoryLog extends Component {
    constructor(props) {
        super(props);

        this.props.fetchHistoryLog({ id: this.props.yachtId });
    }

    render() {
        let historyLog = this.props.historyLog;

        const parsingMethod = this.props.parsingMethod;
        if (parsingMethod !== undefined) {
            historyLog = historyLog.filter((log, key) => {
                if (
                    parsingMethod === 'all_except_name_logs' &&
                    log.field === 'name'
                ) {
                    return false;
                } else if (
                    parsingMethod === 'only_name_logs' &&
                    log.field !== 'name'
                ) {
                    return false;
                } else {
                    return true;
                }
            });
        }
        return (
            <ModalBody>
                <div className="row">
                    <div className="col-sm-10 offset-sm-1 mb-0 mt-1 history-logs-wrapper">
                        {historyLog.map((log, key) => {
                            let logBefore = log.before;
                            let logAfter = log.after;

                            if (
                                log.field === 'asking_price' ||
                                log.field === 'estimated_price'
                            ) {
                                logBefore = functions.thousands(
                                    parseFloat(log.before)
                                );
                                logAfter = functions.thousands(
                                    parseFloat(log.after)
                                );
                            }

                            if (log.field === 'vat_status') {
                                logBefore = vatStatus.find(
                                    (x) => x.value === log.before
                                )
                                    ? vatStatus.find(
                                          (x) => x.value === log.before
                                      ).label
                                    : '-';
                                logAfter = vatStatus.find(
                                    (x) => x.value === log.after
                                )
                                    ? vatStatus.find(
                                          (x) => x.value === log.after
                                      ).label
                                    : '-';
                            }

                            if (log.field === 'status') {
                                const bf = dropdowns.status.find(
                                        (s) => s.value === log.before
                                    ),
                                    af = dropdowns.status.find(
                                        (s) => s.value === log.after
                                    );
                                logBefore = bf ? bf.label : '';
                                logAfter = af ? af.label : '';
                            }

                            let logUnitBefore, logUnitAfter;
                            logUnitBefore = loggableYachtFieldsUnits[log.field]
                                ? loggableYachtFieldsUnits[log.field]
                                : '';
                            logUnitAfter = loggableYachtFieldsUnits[log.field]
                                ? loggableYachtFieldsUnits[log.field]
                                : '';

                            if (
                                [
                                    'beam',
                                    'loa',
                                    'draft_min',
                                    'draft_max'
                                ].indexOf(log.field) !== -1
                            ) {
                                if (log.before_unit !== 'meters') {
                                    logBefore = functions.unit(
                                        logBefore,
                                        'feet'
                                    );
                                    logUnitBefore = "'";
                                }
                                if (log.after_unit !== 'meters') {
                                    logAfter = functions.unit(logAfter, 'feet');
                                    logUnitAfter = "'";
                                }
                            }

                            let logField = loggableYachtFields[log.field];
                            return (
                                <div className="row mb-2" key={key}>
                                    <div className="col-12 date">
                                        {moment(log.created_at).format(
                                            'DD/MM/YYYY hh:mm:ss a'
                                        )}
                                    </div>
                                    <div className="col-12 desc">
                                        {`${logField} was `}
                                        <strong>
                                            {logBefore
                                                ? logBefore +
                                                  ' ' +
                                                  logUnitBefore
                                                : 'empty'}
                                        </strong>
                                        {` changed to `}
                                        <strong>
                                            {logAfter
                                                ? logAfter + ' ' + logUnitAfter
                                                : 'empty'}
                                        </strong>
                                        {log.user ? ` by user ` : ''}
                                        <br />
                                        <strong>
                                            {log.user
                                                ? ` ${log.user.name} (${log.user.email})`
                                                : null}
                                        </strong>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </ModalBody>
        );
    }
}

function mapStateToProps(state) {
    _remove(state.yachts.yacht.historyLog, function (n) {
        return n.field === 'asking_price_displayed';
    });

    return {
        historyLog: state.yachts.yacht.historyLog
    };
}

export default connect(mapStateToProps, { fetchHistoryLog })(HistoryLog);
