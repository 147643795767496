export const tanks = [
  {
    name: 'fuel_capacity',
    value: '',
    type: 'number',
    placeholder: 'FUEL CAPACITY',
    volume_unit: true
  },
  {
    name: 'water_capacity',
    value: '',
    type: 'number',
    placeholder: 'WATER CAPACITY',
    volume_unit: true
  },
  {
    name: 'grey_water_capacity',
    value: '',
    type: 'number',
    placeholder: 'GREY WATER CAPACITY',
    volume_unit: true
  },
  {
    name: 'black_water_capacity',
    value: '',
    type: 'number',
    placeholder: 'BLACK WATER CAPACITY',
    volume_unit: true
  },
  {
    name: 'water_makers',
    value: '',
    type: 'text',
    placeholder: 'WATER MAKERS'
  },
  {
    name: 'lub_oil',
    value: '',
    type: 'text',
    placeholder: 'LUB OIL'
  },
  {
    name: 'dirty_lub_oil',
    value: '',
    type: 'text',
    placeholder: 'DIRTY LUB OIL'
  },
  {
    name: 'tanks_info',
    value: '',
    type: 'textarea',
    placeholder: 'INFO'
  }
];

export const initialValuesTanks = {
  fuel_capacity_unit: 'liters',
  water_capacity_unit: 'liters',
  grey_water_capacity_unit: 'liters',
  black_water_capacity_unit: 'liters'
};
