export const FETCH_PRICE_REDUCTIONS = 'fetch_price_reductions',
  FETCH_PRICE_REDUCTIONS_SUCCESS = 'fetch_price_reductions_success',
  FETCH_PRICE_REDUCTIONS_FAIL = 'fetch_price_reductions_fail',
  FETCH_PRICES = 'fetch_prices',
  FETCH_PRICES_SUCCESS = 'fetch_prices_success',
  FETCH_PRICES_FAIL = 'fetch_prices_fail',
  FETCH_YACHTS_SOLD = 'fetch_yachts_sold',
  FETCH_YACHTS_SOLD_SUCCESS = 'fetch_yachts_sold_success',
  FETCH_YACHTS_SOLD_FAIL = 'fetch_yachts_sold_fail',
  FETCH_REPORTS_YACHTS = 'fetch_reports_yachts',
  FETCH_REPORTS_YACHTS_SUCCESS = 'fetch_reports_yachts_success',
  FETCH_REPORTS_YACHTS_FAIL = 'fetch_reports_yachts_fail',
  FETCH_YEARLY_SALES = 'fetch_yearly_sales',
  FETCH_YEARLY_SALES_SUCCESS = 'fetch_yearly_sales_success',
  FETCH_YEARLY_SALES_FAIL = 'fetch_yearly_sales_fail',
  HIDE_YACHT_SOLD = 'hide_yacht_sold',
  HIDE_YACHT_SOLD_SUCCESS = 'hide_yacht_sold_success',
  HIDE_YACHT_SOLD_FAIL = 'hide_yacht_sold_fail',
  HIDE_PRICE_REDUCTION = 'hide_price_reduction',
  HIDE_PRICE_REDUCTION_SUCCESS = 'hide_price_reduction_success',
  HIDE_PRICE_REDUCTION_FAIL = 'hide_price_reduction_fail',
  HIDE_NEWEST_YACHT = 'hide_newest_yacht',
  HIDE_NEWEST_YACHT_SUCCESS = 'hide_newest_yacht_success',
  HIDE_NEWEST_YACHT_FAIL = 'hide_newest_yacht_fail';
