import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { Row, Col } from 'reactstrap';

import Header from '../../common/header/Header';
import Loader from '../../common/components/Loader';
import LocationTree from '../../common/components/locations/LocationTree';
import PhotoGallery from '../../common/components/PhotoGallery';

// Fields
import RenderField from '../../common/components/RenderField';
import RenderRedactor from '../../common/components/RenderRedactor';
import UploadField from '../../common/components/UploadField';
import RenderCheckbox from '../../common/components/RenderCheckbox';

// Helpers
import { required, coordinates } from '../../helpers/validate';

let ManageLocationPresenter = (props) => {
    return (
        <div className="content-inner-wrapper">
            <Header menu={props.menu} history={props.history} />

            <div className="content-inner">
                {props.loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <form
                        onSubmit={props.handleSubmit(props.onSubmit)}
                        className="list-form"
                    >
                        <Row>
                            <Col xs="12" lg="9">
                                <Row>
                                    <Col xs="12">
                                        <Field
                                            name="name"
                                            component={RenderField}
                                            type="text"
                                            placeholder="Location Name"
                                            validate={[required]}
                                            className="mb-0"
                                        />
                                    </Col>

                                    <Col xs="12">
                                        <Field
                                            name="can_have_yachts"
                                            type="checkbox"
                                            component={RenderCheckbox}
                                            label="CAN HAVE YACHTS?"
                                            className="mt-2"
                                        />
                                    </Col>

                                    <Col xs="12">
                                        <Field
                                            name="parents"
                                            type="text"
                                            component={LocationTree}
                                            label="CHOOSE PARENT LOCATION"
                                            mode="single"
                                            should_cascade={false}
                                            only_for_yachts={false}
                                        />
                                    </Col>

                                    <Col xs="12">
                                        <h2>Location extra info</h2>
                                    </Col>

                                    <Col xs="12">
                                        <UploadField
                                            name="info.photo"
                                            label="Photo"
                                            desc="Dimension of photo must be 1920x1080"
                                            fileLabelName="Click here to upload photo"
                                            initPhoto={
                                                props.initialValues.info &&
                                                props.initialValues.info.photo
                                                    ? props.initialValues.info
                                                          .photo
                                                    : ''
                                            }
                                            add={(urls, name) => {
                                                props.changeFieldValue(
                                                    'info.photo',
                                                    urls
                                                );
                                            }}
                                            type="single"
                                        />
                                    </Col>

                                    <Col xs="12">
                                        {props.gallery_photos &&
                                            props.gallery_photos.length > 0 && (
                                                <PhotoGallery
                                                    galleryPhotos={props.gallery_photos.map(
                                                        (photo) => ({
                                                            url: photo
                                                        })
                                                    )}
                                                    remove={(value, index) => {
                                                        props.changeFieldValue(
                                                            'photos',
                                                            props.gallery_photos.filter(
                                                                (photo) =>
                                                                    photo !==
                                                                    value.url
                                                            )
                                                        );
                                                    }}
                                                    optionalLabel="(Optional)"
                                                    label="GALLERY PHOTO"
                                                />
                                            )}

                                        <UploadField
                                            name="gallery"
                                            desc="Dimension of photo must be 1388x780"
                                            label="GALLERY"
                                            fileLabelName="Click here to upload photo(s)"
                                            add={(urls, name) => {
                                                props.changeFieldValue(
                                                    'photos',
                                                    [
                                                        ...props.gallery_photos,
                                                        ...urls
                                                    ]
                                                );
                                            }}
                                            type="multiple"
                                            className="mb-2"
                                        />
                                    </Col>

                                    <Col xs="12">
                                        <RenderRedactor
                                            label="LOCATION DESCRIPTION"
                                            name="info.description"
                                            onChange={(text) => {
                                                props.changeFieldValue(
                                                    'info.description',
                                                    text
                                                );
                                            }}
                                            id="description"
                                        />
                                    </Col>

                                    <Col
                                        xs="12"
                                        md="4"
                                        className="coordinates-wrap"
                                    >
                                        <Field
                                            name="info.latitude"
                                            type="number"
                                            className="mb-1 coordinates-wrapper"
                                            placeholder="LATITUDE"
                                            validate={[coordinates]}
                                            parse={(value) =>
                                                value ? Number(value) : ''
                                            }
                                            component={RenderField}
                                            extraInfo="Example: 37.983810"
                                            extraInfoRight="N"
                                        />
                                    </Col>

                                    <Col
                                        xs="12"
                                        md="4"
                                        className="coordinates-wrap"
                                    >
                                        <Field
                                            name="info.longitude"
                                            type="number"
                                            className="mb-1 coordinates-wrapper"
                                            placeholder="LONGITUDE"
                                            validate={[coordinates]}
                                            parse={(value) =>
                                                value ? Number(value) : ''
                                            }
                                            component={RenderField}
                                            extraInfo="Example: 23.727539"
                                            extraInfoRight="E"
                                        />
                                    </Col>

                                    <Col xs="12" className="mt-2">
                                        <h2>Location tax rate</h2>
                                    </Col>

                                    <Col xs="12" md="4" className="mt-0">
                                        <Field
                                            name="tax_min"
                                            type="number"
                                            className="mb-1"
                                            placeholder="TAX RATE MIN (%)"
                                            parse={(value) =>
                                                value ? Number(value) : ''
                                            }
                                            component={RenderField}
                                        />
                                    </Col>
                                    <Col xs="12" md="4" className="mt-0">
                                        <Field
                                            name="tax_max"
                                            type="number"
                                            className="mb-1"
                                            placeholder="TAX RATE MAX (%)"
                                            parse={(value) =>
                                                value ? Number(value) : ''
                                            }
                                            component={RenderField}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs="12" lg="3">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Save
                                </button>
                            </Col>
                        </Row>
                    </form>
                )}
            </div>
        </div>
    );
};

ManageLocationPresenter = reduxForm({
    form: 'location_from',
    enableReinitialize: true
})(ManageLocationPresenter);

const selector = formValueSelector('location_from');

const mapStateToProps = (state) => {
    return {
        gallery_photos: selector(state, 'photos'),
        initialValues: state.locations.location
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change('location_from', field, value))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageLocationPresenter);
