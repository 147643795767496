import React from 'react';
import { Field } from 'redux-form';
import ReactSVG from 'react-svg';
import { Row, Col, Button } from 'reactstrap';

// Fields
import RenderField from '../../../../common/components/RenderField';
import UploadField from 'common/components/UploadField';

// SVG
import add from 'assets/svgs/add.svg';
import remove from 'assets/svgs/remove.svg';

class RenderOfferAttachments extends React.Component {
    render() {
        const {
            fields,
            meta: { error, submitFailed },
            read_only
        } = this.props;
        const attachments = [];
        return (
            <div className="row">
                {!read_only && fields.length < 10 && (
                    <div className="col-12" style={{ marginBottom: '40px' }}>
                        <Button color="info" onClick={() => fields.push({})}>
                            <ReactSVG path={add} style={{ height: 12 }} />
                            Add attachment
                        </Button>
                        {submitFailed && error && <span>{error}</span>}
                    </div>
                )}

                <Col xs="12">
                    {fields.map((attachment, index) => {
                        let initAttachment = {
                            translations: {},
                            images: [{}]
                        };

                        if (fields.get(index)?.title) {
                            initAttachment.translations.title = fields.get(
                                index
                            ).title;
                        }

                        if (fields.get(index)?.url) {
                            initAttachment.images[0].url = fields.get(
                                index
                            ).url;
                        }

                        attachments.push(initAttachment);
                        //console.log('attachments', attachments);
                        return (
                            <Row
                                className="row"
                                key={index}
                                style={{
                                    padding: '10px 0'
                                }}
                            >
                                <Col xs="12">
                                    {index !== 0 && <hr />}
                                    <h4>Attachment #{index + 1}</h4>
                                </Col>

                                <Col xs="12">
                                    <Field
                                        name={`${attachment}.title`}
                                        placeholder="TITLE"
                                        component={RenderField}
                                        className="mt-0"
                                        readOnly={this.props.read_only}
                                    />
                                </Col>

                                <Col xs="12" md="9">
                                    <UploadField
                                        name={`${attachment}.images[0]`}
                                        label="Attachment"
                                        initPhoto={
                                            fields.get(index).url
                                                ? fields.get(index).url
                                                : null
                                        }
                                        fileLabelName="Click here to upload an attachment"
                                        add={(url, name) => {
                                            this.props.changeFieldValue(
                                                `${attachment}.url`,
                                                url
                                            );
                                        }}
                                        type="single"
                                        className="mb-2"
                                        accept=".pdf, .jpg, .png, .xlsx"
                                    />
                                </Col>
                                {!read_only && (
                                    <Col xs="12" md="3">
                                        <Button
                                            className="mb-1"
                                            color="danger"
                                            onClick={() => fields.remove(index)}
                                        >
                                            <ReactSVG
                                                path={remove}
                                                style={{ height: 12 }}
                                            />
                                            Remove Entry
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                        );
                    })}
                </Col>
            </div>
        );
    }
}

export default RenderOfferAttachments;
