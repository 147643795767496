import axios from 'axios';
import { API_URL } from 'helpers/variables';
import { authConfig } from 'helpers/authConfig';

export function updateYachtSelection(yachtId) {
    return axios({
        url: `${API_URL}/yachts/selected/sales`,
        method: 'PUT',
        data: { yacht_id: yachtId },
        headers: authConfig()
    });
}

export function deleteYachtSelection() {
    return axios({
        url: `${API_URL}/yachts/selected/sales`,
        method: 'DELETE',
        headers: authConfig()
    });
}
