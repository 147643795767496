import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';

import { Field, reduxForm } from 'redux-form';
import RenderField from '../common/components/RenderField';
import { email, required } from '../helpers/validate';

import { loginUser } from './_actions';
import logoDavinci from '../assets/svgs/logo-davinci.svg';
import logoIyc from '../assets/svgs/logo.svg';

const form = reduxForm({
    form: 'login'
});

class Login extends Component {
    getHomeForRole(role) {
        switch (role) {
            case 'broker':
                return '/clients';
            case 'broker_sales':
                return '/clients/sales';
            case 'broker_charter':
                return '/clients/charters';
            default:
                return '/dashboard';
        }
    }

    handleFormSubmit(formProps) {
        this.props.loginUser(formProps).then(data => {
            if (data) {
                this.props.history.push(this.getHomeForRole(data.role));
            }
        });
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <div className="login-wrapper d-flex align-items-center">
                <div className="form-wrap">
                    <div className="row">
                        <div className="col-5">
                            <div className="login-brand-wrap">
                                <div className="login-brand d-flex">
                                    <Link to="/">
                                        <ReactSVG
                                            style={{ height: 48 }}
                                            path={logoDavinci}
                                        />
                                    </Link>
                                    <h4 className="ml-2 d-flex align-items-center">
                                        Davinci Yacht Application
                                    </h4>
                                </div>
                                {/* <div className="mono-wrap">
                  <strong>
                    Proudly made by <a href="https://monospacelabs.com/">Monospace Labs</a>
                  </strong>
                </div> */}
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="login-form text-center">
                                <form
                                    onSubmit={handleSubmit(
                                        this.handleFormSubmit.bind(this)
                                    )}
                                >
                                    <div className="text-center mb-3">
                                        <h4>Login to your account</h4>
                                        <strong>
                                            All fields are required.
                                        </strong>
                                    </div>
                                    <Field
                                        group="email"
                                        name="email"
                                        type="email"
                                        placeholder="EMAIL"
                                        validate={[required, email]}
                                        component={RenderField}
                                    />
                                    <Field
                                        group="password"
                                        name="password"
                                        type="password"
                                        placeholder="PASSWORD"
                                        validate={required}
                                        component={RenderField}
                                    />

                                    <button
                                        type="submit"
                                        className="btn btn-success btn-lg btn-block mt-3 mb-1"
                                    >
                                        LOGIN
                                    </button>

                                    <Link to="/reset-password">
                                        <strong>Forgot your password?</strong>
                                    </Link>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="auth-wrap d-flex align-items-end">
                    <span style={{ marginRight: 8 }}>Authorised by </span>
                    <ReactSVG style={{ height: 10 }} path={logoIyc} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authenticated: state.auth.authenticated
    };
}

export default connect(
    mapStateToProps,
    { loginUser }
)(form(Login));
