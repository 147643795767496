import { maxLength } from 'helpers/validate';
import React, { PureComponent } from 'react';
import { FormGroup, Label } from 'reactstrap';

class RenderField extends PureComponent {
    render() {
        const props = this.props;

        return (
            <FormGroup
                className={
                    'floating-label-wrapper ' +
                    (props.className !== undefined ? props.className : '')
                }
            >
                <Label
                    className={
                        (props.meta !== undefined &&
                            props.input.value !== '' &&
                            !props.meta.error) ||
                        (props.disabled !== undefined && props.disabled)
                            ? 'float top'
                            : 'float'
                    }
                >
                    {props.placeholder}
                </Label>

                <input
                    className={
                        props.meta !== undefined &&
                        props.meta.touched &&
                        props.meta.error
                            ? 'form-control error'
                            : 'form-control'
                    }
                    type={props.type}
                    id={props.group}
                    placeholder={
                        props.disabled !== undefined &&
                        props.disabled &&
                        !props.input.value
                            ? '-'
                            : props.placeholder
                    }
                    disabled={
                        props.disabled !== undefined && props.disabled === true
                    }
                    readOnly={
                        props.readOnly !== undefined && props.readOnly === true
                    }
                    autoComplete={
                        props.autoComplete === false ||
                        props.autoComplete === undefined
                            ? 'off'
                            : 'on'
                    }
                    autoFocus={props.autoFocus === true}
                    {...props.input}
                    maxLength={props.maxLength ? props.maxLength : null}
                />
                {props.meta !== undefined && (
                    <div
                        className={
                            props.meta.touched && props.meta.error
                                ? 'float error top'
                                : 'float error'
                        }
                    >
                        {props.meta.error}
                    </div>
                )}

                {props.extraInfoRight && (
                    <div className="extra-options-wrap">
                        {props.extraInfoRight}
                    </div>
                )}

                {props.meta !== undefined &&
                    !(props.meta.touched && props.meta.error) &&
                    !props.readOnly &&
                    props.extraInfo && (
                        <div className="extra-options-info">
                            {props.extraInfo}
                        </div>
                    )}

                {props.dropdownList &&
                    props.dropdownList.length > 0 &&
                    props.type === 'text' &&
                    props.openSearch &&
                    props.openSearch[props.input.name] &&
                    props.openSearch[props.input.name] === true && (
                        <div className="dropdown-wrapper">
                            <ul className="list-unstyled mb-0">
                                {props.dropdownList.map((value, key) => (
                                    <li key={key}>
                                        <a
                                            href="/#"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.onClickList(
                                                    value,
                                                    props.input.name
                                                );
                                            }}
                                        >
                                            {value.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
            </FormGroup>
        );
    }
}

export default RenderField;
