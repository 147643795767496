import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModalBody } from 'reactstrap';
import { reduxForm, Field, formValueSelector, reset } from 'redux-form';
import Notifications from 'react-notification-system-redux';

import $ from 'jquery';

import RenderRedactor from '../../common/components/RenderRedactor';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import RenderField from '../../common/components/RenderField';

import { fetchSendYachtEmail } from './_actions';

import { email, required } from '../../helpers/validate';

import basic from '../../assets/img/basic.jpg';
import extended from '../../assets/img/extended.jpg';

const form = reduxForm({
    form: 'sendyachtmail'
});

const selector = formValueSelector('sendyachtmail');

class SendMailModal extends Component {
    handleFormSubmit(formProps) {
        if (
            document.getElementById('message_content') &&
            document.getElementById('message_content').value
        ) {
            let params = Object.assign({}, formProps);
            params.message = document.getElementById('message_content').value;
            params.id = this.props.yachtId;
            params.type = this.props.viewType;
            console.log(params);
            return this.props.fetchSendYachtEmail(params).then(data => {
                if (data) {
                    $('#message_content').redactor('code.set', '');
                    this.props.resetForm();
                }
            });
        } else {
            this.props.notification('Error', 'Missing message content');
        }
    }

    render() {
        const { handleSubmit, submitting } = this.props;

        return (
            <ModalBody>
                <div className="row">
                    <div className="col-sm-10 offset-sm-1 mb-1 mt-1">
                        <form
                            className="list-form"
                            onSubmit={handleSubmit(
                                this.handleFormSubmit.bind(this)
                            )}
                        >
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-6 pr-2">
                                            <Field
                                                name="template"
                                                placeholder="SELECT TEMPLATE"
                                                component={RenderReactSelect}
                                                validate={[required]}
                                                className="mt-0 mb-2"
                                                options={[
                                                    {
                                                        value: 'basic',
                                                        label: 'Basic'
                                                    },
                                                    {
                                                        value: 'extended',
                                                        label: 'Extended'
                                                    }
                                                ]}
                                            />
                                        </div>

                                        <div className="col-12 mb-2">
                                            <img
                                                src={
                                                    this.props.template ===
                                                    'basic'
                                                        ? basic
                                                        : extended
                                                }
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 pr-2">
                                    <Field
                                        name="to_email"
                                        type="email"
                                        placeholder="TO EMAIL"
                                        className="mt-0"
                                        validate={[email, required]}
                                        component={RenderField}
                                    />
                                </div>

                                <div className="col-6  pr-2">
                                    <Field
                                        name="cc"
                                        type="email"
                                        placeholder="CC"
                                        className="mt-0"
                                        validate={[email]}
                                        component={RenderField}
                                    />
                                </div>

                                <div className="col-12">
                                    <Field
                                        name="subject"
                                        type="text"
                                        placeholder="SUBJECT"
                                        className="mt-0"
                                        validate={[required]}
                                        component={RenderField}
                                    />
                                </div>
                            </div>

                            <RenderRedactor
                                label="MESSAGE"
                                name="message"
                                id="message_content"
                            />

                            <div className="mt-2 text-right">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={submitting}
                                >
                                    Send
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </ModalBody>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        resetForm: () => dispatch(reset('sendyachtmail')),
        fetchSendYachtEmail: e => dispatch(fetchSendYachtEmail(e)),
        notification: (title, message) => {
            let notificationOpts = {
                position: 'tc',
                autoDismiss: 0,
                title: title,
                message: message
            };

            dispatch(Notifications.error(notificationOpts));
        }
    };
}

function mapStateToProps(state) {
    return {
        template: selector(state, 'template'),
        initialValues: {
            template: 'basic'
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(form(SendMailModal));
