import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModalBody, ModalFooter, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

import { changePassword } from './_actions';
import { resetPassword } from '../_actions';

import RenderPassword from '../../common/components/RenderPassword';
import { required, warn, minLength6 } from '../../helpers/validate';

const form = reduxForm({
  form: 'change_password',
  warn
});

class ChangePasswordModal extends Component {
  handleFormSubmit(formProps) {
    let params = {
      old_password: formProps.old_password,
      new_password: formProps.password
    };
    this.props.changePassword(params).then(data => {
      if (data && data.message) {
        this.props.toggle();
      }
    });
  }

  resetPasswordClick() {
    this.props.resetPassword({ email: this.props.user_email }).then(data => {
      if (data && data.message) {
        this.props.toggle();
      }
    });
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="modal-wrapper" onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
        <ModalBody>
          <div className="modal-form-wrap">
            <div className="row">
              <div className="col-sm-8 offset-sm-2 mb-0 mt-1">
                <Field
                  name="old_password"
                  className="mb-3"
                  placeholder="CURRENT PASSWORD"
                  validate={[required]}
                  component={RenderPassword}
                />
                <Field
                  name="password"
                  className="mb-3"
                  placeholder="NEW PASSWORD"
                  validate={[required, minLength6]}
                  component={RenderPassword}
                />
                <Field
                  name="password_confirmation"
                  className="mb-3"
                  placeholder="CONFIRM PASSWORD"
                  validate={[required, minLength6]}
                  component={RenderPassword}
                />
                <Button
                  type="button"
                  className="btn btn-empty btn-link p-0 mt-1"
                  onClick={() => this.resetPasswordClick()}
                >
                  FORGOT PASSWORD?
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary">
            Save
          </Button>{' '}
          <Button type="button" color="secondary" onClick={this.props.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    user_email: state.user.email
  };
}

export default connect(
  mapStateToProps,
  { changePassword, resetPassword }
)(form(ChangePasswordModal));
