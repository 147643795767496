import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { ModalBody, ModalFooter, Button } from 'reactstrap';

import RenderField from '../../common/components/RenderField';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import RenderToggle from '../../common/components/RenderToggle';
import RenderCheckbox from '../../common/components/RenderCheckbox';
import UploadField from '../../common/components/UploadField';
import RenderRedactor from '../../common/components/RenderRedactor';
import ReactTelInput from 'react-telephone-input';

import { email, isBoolean, required } from '../../helpers/validate';

import { fetchManageUser } from './_actions';
import alerts from 'locales/alerts';
import { warning } from 'react-notification-system-redux';

const form = reduxForm({
    form: 'manage_user'
});

let def = 'en';

class ManageUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //     selectedRole: this.props.initialValues.role
            photoRequired: false,
            mobileNumber: ''
        };
    }
    handleFormSubmit(formProps) {
        let params = formProps;

        if (!params.photo) {
            this.setState({ photoRequired: true });
        } else {
            if (this.props.initialValues.id !== undefined) {
                params.id = this.props.initialValues.id;
            }

            if (formProps.active === true) {
                params.active = 1;
            } else {
                params.active = 0;
            }

            if (document.getElementById('signature_id')) {
                params.signature = document.getElementById(
                    'signature_id'
                ).value;
            } else {
                params.signature = '';
            }
            if (this.state.mobileNumber) {
                params.phone = this.state.mobileNumber;
            }
            this.props.fetchManageUser(params).then((data) => {
                if (data?.hubspot_response?.error?.message) {
                    setTimeout(() => {
                        this.props.showWarning({
                            title: alerts.error.hubspotWarning[def].title,
                            message: [
                                data.hubspot_response.error.message + ' ',
                                data.hubspot_response.error.properties.map(
                                    function (prop) {
                                        return prop + ', ';
                                    }
                                )
                            ],
                            autoDismiss: 5
                        });
                    }, 1200);
                }
                if (data) {
                    this.props.refetchUsers();
                    this.props.toggle();
                }
            });
        }
    }

    handleInputChange(telNumber, selectedCountry) {
        this.setState({ mobileNumber: telNumber });
    }

    // onChangeRole = (role) => {
    //     this.setState({ selectedRole: role });
    // };

    render() {
        const { handleSubmit, submitting, invalid } = this.props;

        return (
            <form
                className="modal-wrapper"
                onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
            >
                <ModalBody className="modal-body">
                    <div className="modal-form-wrap">
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 mb-1 mt-1">
                                <UploadField
                                    name="photo"
                                    box={true}
                                    fileLabelName="UPLOAD IMAGE"
                                    outerLabel="UPLOAD IMAGE"
                                    className="round-photo"
                                    type="single"
                                    formName="manage_user"
                                    initPhoto={this.props.photo}
                                />
                                {this.state.photoRequired ? (
                                    <div className="modal-body__uploadField">
                                        REQUIRED
                                    </div>
                                ) : null}
                                <Field
                                    name="first_name"
                                    type="text"
                                    placeholder="FIRST NAME"
                                    validate={[required]}
                                    component={RenderField}
                                />

                                <Field
                                    name="last_name"
                                    type="text"
                                    placeholder="LAST NAME"
                                    validate={[required]}
                                    component={RenderField}
                                />

                                <Field
                                    name="name"
                                    type="text"
                                    validate={[required]}
                                    placeholder="USERNAME"
                                    component={RenderField}
                                />

                                <Field
                                    name="position"
                                    type="text"
                                    placeholder="POSITION"
                                    validate={[required]}
                                    component={RenderField}
                                />

                                <Field
                                    name="url"
                                    type="text"
                                    validate={[required]}
                                    placeholder="PROFILE URL"
                                    component={RenderField}
                                />

                                <Field
                                    group="email"
                                    name="email"
                                    type="email"
                                    placeholder="EMAIL"
                                    validate={[required, email]}
                                    component={RenderField}
                                />

                                <Field
                                    validate={[required]}
                                    name="role"
                                    placeholder="ROLE"
                                    clearable={false}
                                    component={RenderReactSelect}
                                    options={[
                                        {
                                            value: 'broker_sales',
                                            label: 'Broker Sales'
                                        },
                                        {
                                            value: 'broker_charter',
                                            label: 'Broker Charter'
                                        },
                                        { value: 'broker', label: 'Broker' },
                                        {
                                            value: 'moderator',
                                            label: 'Moderator'
                                        },
                                        { value: 'admin', label: 'Admin' },
                                        {
                                            value: 'admin_website',
                                            label: 'Admin Website'
                                        },
                                        {
                                            value: 'super_admin',
                                            label: 'Super Admin'
                                        },
                                        { value: 'owner', label: 'Owner' }
                                    ]}
                                    // onChange={this.onChangeRole}
                                />

                                {/* {(this.state.selectedRole === 'broker' ||
                                    this.state.selectedRole ===
                                        'broker_sales' ||
                                    this.state.selectedRole ===
                                        'broker_charter') && (
                                    <> */}
                                <Field
                                    name="company"
                                    validate={[required]}
                                    type="text"
                                    placeholder="COMPANY"
                                    component={RenderField}
                                />

                                <Field
                                    name="country"
                                    validate={[required]}
                                    placeholder="COUNTRY"
                                    clearable={true}
                                    component={RenderReactSelect}
                                    options={this.props.countries}
                                />
                                <div className="custom-select-wrapper floating-label-wrapper react-select-wrapper">
                                    <label className="Select-placeholder float top phone-with-area-code__label">
                                        MOBILE NUMBER
                                    </label>

                                    <ReactTelInput
                                        name="phone"
                                        defaultCountry="us"
                                        onChange={this.handleInputChange.bind(
                                            this
                                        )}
                                        initialValue={
                                            this.props.initialValues.phone
                                        }
                                        className="phone-with-area-code"
                                    />
                                </div>

                                <Field
                                    name="whatsapp"
                                    normalize={isBoolean}
                                    type="checkbox"
                                    label="IS WHAT'S APP"
                                    component={RenderCheckbox}
                                    className="mb-2"
                                />

                                <RenderRedactor
                                    label="EMAIL SIGNATURE"
                                    name="signature"
                                    id="signature_id"
                                    withImage={true}
                                    withFontSize={true}
                                />
                                {/* </>
                                )} */}

                                <Field
                                    name="active"
                                    label="STATUS"
                                    component={RenderToggle}
                                    className="mt-1"
                                />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="submit"
                        color="primary"
                        disabled={invalid || submitting}
                    >
                        Save
                    </Button>{' '}
                    <Button
                        type="button"
                        color="secondary"
                        disabled={submitting}
                        onClick={this.props.toggle}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </form>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetchManageUser: (e) => dispatch(fetchManageUser(e)),
        showWarning: (options) => dispatch(warning(options))
    };
}

function mapStateToProps(state) {
    return {
        initialValues: {
            id: state.management.users.initUser.id,
            photo: state.management.users.initUser.photo,
            first_name: state.management.users.initUser.first_name,
            last_name: state.management.users.initUser.last_name,
            name: state.management.users.initUser.name,
            email: state.management.users.initUser.email,
            role: state.management.users.initUser.role,
            position: state.management.users.initUser.position,
            url: state.management.users.initUser.url,
            company: state.management.users.initUser.company,
            country: state.management.users.initUser.country,
            phone: state.management.users.initUser.phone,
            whatsapp: state.management.users.initUser.whatsapp,
            signature: state.management.users.initUser.signature,
            active:
                state.management.users.initUser.active &&
                state.management.users.initUser.active === 1
                    ? true
                    : false
        },
        countries: state.common.countries.map((st) => {
            return { value: st.name, label: st.name };
        }),
        photo: state.management.users.initUser.photo
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(form(ManageUser));
