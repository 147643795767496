import axios from 'axios';

import {
    FETCH_YACHTS,
    FETCH_YACHTS_SUCCESS,
    FETCH_YACHTS_FAIL,
    FETCH_BROKERS_LIST,
    FETCH_BROKERS_LIST_SUCCESS,
    FETCH_BROKERS_LIST_FAIL,
    FETCH_LOAD_YACHTS,
    FETCH_LOAD_YACHTS_SUCCESS,
    FETCH_LOAD_YACHTS_FAIL,
    FETCH_COMPANIES,
    FETCH_COMPANIES_SEARCH_SUCCESS,
    FETCH_COMPANIES_SEARCH_FAIL,
    INIT_SEARCH,
    FETCH_GET_SELECTED_YACHTS,
    FETCH_GET_SELECTED_YACHTS_SUCCESS,
    FETCH_GET_SELECTED_YACHTS_FAIL,
    FETCH_SELECT_YACHTS,
    FETCH_SELECT_YACHTS_SUCCESS,
    FETCH_SELECT_YACHTS_FAIL,
    FETCH_DELETE_SELECTED_YACHTS,
    FETCH_DELETE_SELECTED_YACHTS_SUCCESS,
    FETCH_DELETE_SELECTED_YACHTS_FAIL,
    CHANGE_YACHT_SELECTION,
    CHANGE_YACHT_SELECTION_IN_SELECTED,
    CHANGE_ALL_YACHTS_SELECTION,
    CHANGE_ALL_YACHTS_SELECTION_IN_SELECTED,
    YACHT_ORDER_CHANGE,
    CHANGE_YACHT_STATUS_REQUEST,
    CHANGE_YACHT_STATUS_SUCCESS,
    CHANGE_YACHT_STATUS_FAILURE,
    DESELECT_ALL_YACHTS_IN_SELECTION,
    FETCH_EXTERNAL_PROVIDERS,
    FETCH_EXTERNAL_PROVIDERS_SUCCESS,
    FETCH_EXTERNAL_PROVIDERS_FAIL,
    FETCH_YACHT_EXTERNAL_PROVIDERS,
    FETCH_YACHT_EXTERNAL_PROVIDERS_SUCCESS,
    FETCH_YACHT_EXTERNAL_PROVIDERS_FAIL
} from './_types';

import { API_URL } from '../../helpers/variables';
import { errorHandler } from '../../helpers/alerts';
import { authConfig } from '../../helpers/authConfig';

import $ from 'jquery';

let previousCancelToken;

export function fetchYachts(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_YACHTS });

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const config = {
            headers: authConfig(),
            params: params,
            paramsSerializer: function (params) {
                return $.param(params);
            },
            cancelToken: source.token
        };

        if (previousCancelToken) {
            previousCancelToken.cancel();
        }

        previousCancelToken = source;
        return axios
            .get(API_URL + '/yachts', config)
            .then((response) => {
                dispatch({
                    type: FETCH_YACHTS_SUCCESS,
                    payload: {
                        yachts: response.data.yachts,
                        pagination: response.data.pagination,
                        sorting: {
                            order_dir: params.order_dir,
                            order_field: params.order_field,
                            page_size: params.page_size,
                            search: params.search ? params.search : ''
                        },
                        query: params.query ? params.query : {}
                    }
                });

                return response.data;
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    dispatch({ type: FETCH_YACHTS_FAIL });
                    errorHandler(dispatch, error.response);
                }
            });
    };
}

export function fetchLoadYachts(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_LOAD_YACHTS });
        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/yachts/load', config)
            .then((response) => {
                dispatch({
                    type: FETCH_LOAD_YACHTS_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch((error) => {
                dispatch({ type: FETCH_LOAD_YACHTS_FAIL });
                errorHandler(dispatch, error.response);
            });
    };
}

export function fetchBrokersList() {
    return (dispatch) => {
        dispatch({ type: FETCH_BROKERS_LIST });

        const config = {
            headers: authConfig(),
            params: { format: 'dropdown' }
        };

        return axios
            .get(API_URL + '/brokers', config)
            .then((response) => {
                dispatch({
                    type: FETCH_BROKERS_LIST_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch((error) => {
                dispatch({ type: FETCH_BROKERS_LIST_FAIL });
                errorHandler(dispatch, error.response);
            });
    };
}

export function fetchSalesCompanies(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_COMPANIES });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/brokers/companies', config)
            .then((response) => {
                if (params.multiple) {
                    dispatch({
                        type: FETCH_COMPANIES_SEARCH_SUCCESS,
                        payload: params.search !== '' ? response.data : []
                    });

                    return params.search !== '' ? response.data : [];
                } else {
                    dispatch({
                        type: FETCH_COMPANIES_SEARCH_SUCCESS,
                        payload: {
                            companies: response.data, //response.data.companies,
                            pagination: response.data.pagination,
                            sorting: {
                                order_dir: params.order_dir,
                                order_field: params.order_field,
                                page_size: params.page_size
                            }
                        }
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: FETCH_COMPANIES_SEARCH_FAIL });
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_COMPANIES_SEARCH_FAIL
                );
            });
    };
}

export function fetchCharterCompanies(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_COMPANIES });

        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/brokers/charterCompanies', config)
            .then((response) => {
                if (params.multiple) {
                    dispatch({
                        type: FETCH_COMPANIES_SEARCH_SUCCESS,
                        payload: params.search !== '' ? response.data : []
                    });

                    return params.search !== '' ? response.data : [];
                } else {
                    dispatch({
                        type: FETCH_COMPANIES_SEARCH_SUCCESS,
                        payload: {
                            companies: response.data, //response.data.companies,
                            pagination: response.data.pagination,
                            sorting: {
                                order_dir: params.order_dir,
                                order_field: params.order_field,
                                page_size: params.page_size
                            }
                        }
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: FETCH_COMPANIES_SEARCH_FAIL });
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_COMPANIES_SEARCH_FAIL
                );
            });
    };
}

export function initSearch() {
    return (dispatch) => {
        dispatch({ type: INIT_SEARCH });

        return new Promise(function (resolve, reject) {
            resolve('Success!');
        });
    };
}

export function fetchSelectYachts(params, type) {
    return (dispatch) => {
        dispatch({ type: FETCH_SELECT_YACHTS });

        const config = { headers: authConfig() };

        return axios
            .put(API_URL + `/yachts/selected/${type}`, params, config)
            .then((response) => {
                dispatch({
                    type: FETCH_SELECT_YACHTS_SUCCESS,
                    payload: response.data
                });
                return response.data;
            })
            .catch((error) => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_SELECT_YACHTS_FAIL
                );
            });
    };
}

export function fetchDeleteSelectedYachts(params, type) {
    return (dispatch) => {
        dispatch({ type: FETCH_DELETE_SELECTED_YACHTS });

        const config = { headers: authConfig(), params: params };

        return axios
            .delete(API_URL + `/yachts/selected/${type}`, config)
            .then((response) => {
                dispatch({
                    type: FETCH_DELETE_SELECTED_YACHTS_SUCCESS,
                    payload: response.data
                });
                return response.data;
            })
            .catch((error) => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_DELETE_SELECTED_YACHTS_FAIL
                );
            });
    };
}

export function fetchSelectedYachts(params, type) {
    return (dispatch) => {
        dispatch({ type: FETCH_GET_SELECTED_YACHTS });

        let config = { headers: authConfig() };
        if (params && params !== undefined) config.params = params;
        return axios
            .get(API_URL + `/yachts/selected/${type}`, config)
            .then((response) => {
                dispatch({
                    type: FETCH_GET_SELECTED_YACHTS_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch((error) => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_GET_SELECTED_YACHTS_FAIL
                );
            });
    };
}

export function changeYachtSelection(params, type) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_YACHT_SELECTION,
            payload: { yachtId: params.yachtId, type: type }
        });
    };
}
export function changeYachtSelectionInSelected(params, type) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_YACHT_SELECTION_IN_SELECTED,
            payload: { yachtId: params.yachtId, type: type }
        });
    };
}

export function deselectAllYachtInBrokerSelection() {
    return (dispatch) => {
        dispatch({
            type: DESELECT_ALL_YACHTS_IN_SELECTION,
            payload: {}
        });
    };
}

export function changeAllYachtsSelection(params, type) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_ALL_YACHTS_SELECTION,
            payload: {
                selection: params.selection,
                type: type
            }
        });
    };
}
export function changeAllYachtsSelectionInSelected(params) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_ALL_YACHTS_SELECTION_IN_SELECTED,
            payload: params.selection
        });
    };
}

export function reorderYacht(params, type) {
    return (dispatch) => {
        dispatch({
            type: YACHT_ORDER_CHANGE,
            payload: {
                from: params.currentSelectionIndex,
                to: params.newSelectionIndex
            }
        });

        const requestParams = {
            yacht_id: params.yachtId,
            to: params.newOrder
        };

        let config = { headers: authConfig() };
        return axios
            .put(
                API_URL + `/yachts/selected/reorder/${type}`,
                requestParams,
                config
            )
            .catch((error) => {
                errorHandler(dispatch, error.response, 'FAIL');
            });
    };
}

export function updateYachtStatus({ yacht, params }) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_YACHT_STATUS_REQUEST,
            payload: { yacht, ...params }
        });
        const config = { headers: authConfig() };
        return axios
            .post(
                API_URL + '/yachts/' + yacht.id + '/change_status',
                params,
                config
            )
            .then((response) => {
                dispatch({ type: CHANGE_YACHT_STATUS_SUCCESS });
            })
            .catch((error) => {
                dispatch({
                    type: CHANGE_YACHT_STATUS_FAILURE,
                    payload: { yacht }
                });
                errorHandler(dispatch, error.response);
            });
    };
}

export function fetchExternalProviders(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_EXTERNAL_PROVIDERS });
        const config = {
            headers: authConfig(),
            params: params
        };

        return axios
            .get(API_URL + '/external-providers/', config)
            .then((response) => {
                dispatch({
                    type: FETCH_EXTERNAL_PROVIDERS_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch((error) => {
                dispatch({ type: FETCH_EXTERNAL_PROVIDERS_FAIL });
                errorHandler(dispatch, error.response);
            });
    };
}

export function fetchYachtExternalProviders(params) {
    return (dispatch) => {
        dispatch({ type: FETCH_YACHT_EXTERNAL_PROVIDERS });

        const config = { headers: authConfig() };

        return axios
            .get(API_URL + '/yachts-external-providers/' + params.id, config)
            .then((response) => {
                dispatch({
                    type: FETCH_YACHT_EXTERNAL_PROVIDERS_SUCCESS,
                    payload: response.data
                });

                return response.data;
            })
            .catch((error) => {
                errorHandler(
                    dispatch,
                    error.response,
                    FETCH_YACHT_EXTERNAL_PROVIDERS_FAIL
                );
            });
    };
}
