import React from 'react';
import { connect } from 'react-redux';

import ManagePresentationPresenter from './ManagePresentationPresenter';
import Notifications from 'react-notification-system-redux';
import LocalizationDecorator from 'infrastructure/decorators/LocalizationDecorator';
import moment from 'moment';
import alerts from 'locales/alerts';
import { warning } from 'react-notification-system-redux';

// Redux
import {
    fetchUpdateYachtPresentation,
    fetchYachtPresentation,
    updatePresentationToys,
    fetchPresentationToys
} from './redux/_actions';
import { YACHT_TYPE_CHARTER } from 'infrastructure/helpers/globals';
import { getYachtMissingFields } from 'yachts/yacht/_actions';
import MissingFieldsWarningModal from 'yachts/yacht/sections/MissingFieldsWarningModal';

let def = 'en';

class ManagePresentationContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            current_locale: 'en',
            is_read_only: false,
            viewType: props.match.params.viewType,
            metaDescriptionError: false,
            is_archived: '',
            yachtfolio_id: '',
            salesMatches: [],
            showModal: false,
            messageSalesSpecs: [],
            messageCharterSpecs: [],
            messageSalesWebsite: [],
            messageCharterWebsite: [],
            messageGeneral: [],
            messageRates: []
        };
    }

    componentDidMount() {
        const locale = this.props.match.params.locale;
        this.setState(
            { current_locale: locale },
            this.fetchYachtPresentation.bind(this)
        );
    }

    componentDidUpdate(next_props) {
        const locale = this.props.match.params.locale;
        if (locale !== this.state.current_locale) {
            this.setState(
                { current_locale: locale },
                this.fetchYachtPresentation.bind(this)
            );
        }
    }

    fetchYachtPresentation() {
        const id = this.props.match.params.id;
        this.setState({ loading: true });
        if (this.props.match.url.includes('view')) {
            this.setState({ is_read_only: true });
        }
        this.props.fetchPresentationToys(
            this.props.match.params.id,
            'equipment_watersport'
        );
        this.props
            .fetchYachtPresentation(id, {
                locale: this.state.current_locale
            })
            .then((data) => {
                this.setState({
                    loading: false,
                    is_archived: data.is_archived,
                    yachtfolio_id: data.yachtfolio_id
                });
            });
    }

    toggleModal = () => {
        this.setState({ showModal: false });
    };

    onSubmit = async (form_props) => {
        const priceFieldsPrependName =
            this.state.viewType === YACHT_TYPE_CHARTER ? 'charter_' : 'sale_';
        const listingLabels = [];
        if (form_props.price_reduction) {
            listingLabels.push('price_reduction');
        }
        if (form_props.new_listing_sales) {
            listingLabels.push('new_listing_sales');
        }
        if (form_props.new_listing_charter) {
            listingLabels.push('new_listing_charter');
        }
        if (form_props.special_offer) {
            listingLabels.push('special_offer');
        }
        let params = {
            ...form_props,
            presentation: {
                ...form_props.presentation,
                is_poa: Boolean(form_props.presentation.is_poa),
                is_poa_charter: Boolean(form_props.presentation.is_poa_charter),
                listing_labels: listingLabels,
                [priceFieldsPrependName + 'price_dollars']:
                    this.state.viewType === YACHT_TYPE_CHARTER
                        ? form_props.presentation.charter_price_dollars
                            ? form_props.presentation.charter_price_dollars
                                  .toString()
                                  .replace(/,/g, '')
                            : null
                        : form_props.presentation.sale_price_dollars
                        ? form_props.presentation.sale_price_dollars
                              .toString()
                              .replace(/,/g, '')
                        : null,
                [priceFieldsPrependName + 'price_euros']:
                    this.state.viewType === YACHT_TYPE_CHARTER
                        ? form_props.presentation.charter_price_euros
                            ? form_props.presentation.charter_price_euros
                                  .toString()
                                  .replace(/,/g, '')
                            : null
                        : form_props.presentation.sale_price_euros
                        ? form_props.presentation.sale_price_euros
                              .toString()
                              .replace(/,/g, '')
                        : null,
                headerVideos:
                    form_props.presentation.headerVideos &&
                    form_props.presentation.headerVideos !== '' &&
                    form_props.presentation.headerVideos.length > 0
                        ? [{ url: form_props.presentation.headerVideos }]
                        : [],
                charterHeaderVideos:
                    form_props.presentation.charterHeaderVideos &&
                    form_props.presentation.charterHeaderVideos.length > 0
                        ? [
                              {
                                  url: Array.isArray(
                                      form_props.presentation
                                          .charterHeaderVideos
                                  )
                                      ? form_props.presentation
                                            .charterHeaderVideos[0]
                                      : form_props.presentation
                                            .charterHeaderVideos
                              }
                          ]
                        : [],
                promoImages:
                    form_props.presentation.promoImages !== ''
                        ? [form_props.presentation.promoImages]
                        : [],
                translations: {
                    ...form_props.presentation.translations,
                    charter_public: Boolean(
                        form_props.presentation.translations.charter_public
                    ),
                    sales_public: Boolean(
                        form_props.presentation.translations.sales_public
                    ),
                    sales_published_date: form_props.presentation.translations
                        .sales_published_date
                        ? moment(
                              form_props.presentation.translations
                                  .sales_published_date
                          ).format('YYYY/MM/DD')
                        : null,
                    charter_published_date: form_props.presentation.translations
                        .charter_published_date
                        ? moment(
                              form_props.presentation.translations
                                  .charter_published_date
                          ).format('YYYY/MM/DD')
                        : null,
                    videos:
                        form_props.presentation.translations.videos !== ''
                            ? [
                                  {
                                      url: Array.isArray(
                                          form_props.presentation.translations
                                              .videos
                                      )
                                          ? form_props.presentation.translations
                                                .videos[0]
                                          : form_props.presentation.translations
                                                .videos
                                  }
                              ]
                            : [],
                    charterVideos:
                        form_props.presentation.translations.charterVideos !==
                        ''
                            ? [
                                  {
                                      url: Array.isArray(
                                          form_props.presentation.translations
                                              .charterVideos
                                      )
                                          ? form_props.presentation.translations
                                                .charterVideos[0]
                                          : form_props.presentation.translations
                                                .charterVideos
                                  }
                              ]
                            : []
                }
            }
        };

        //if the form has changes then we need to notify the backend so they can handle curation level updates etc

        const metaTitleField =
            this.state.viewType === YACHT_TYPE_CHARTER
                ? 'charter_meta_title'
                : 'meta_title';
        const metaDescriptionField =
            this.state.viewType === YACHT_TYPE_CHARTER
                ? 'charter_meta_description'
                : 'meta_description';

        if (
            params.presentation.translations[metaTitleField] &&
            params.presentation.translations[metaTitleField].length > 150
        ) {
            const notificationOpts = {
                message: 'Meta title must be less than 150 characters!',
                position: 'tr'
            };
            this.props.errorHandler(notificationOpts);
            return;
        }

        if (
            params.presentation.translations[metaDescriptionField] &&
            params.presentation.translations[metaDescriptionField].length > 500
        ) {
            const notificationOpts = {
                message: 'Meta description must be less than 500 characters!',
                position: 'tr'
            };
            this.props.errorHandler(notificationOpts);
            return;
        }
        params.viewType = this.state.viewType;
        this.setState({ loading: true });

        await this.props
            .fetchUpdateYachtPresentation(params.id, params)
            .then((data) => {
                if (data?.hubspot_response?.error?.message) {
                    setTimeout(() => {
                        this.props.showWarning({
                            title: alerts.error.hubspotWarning[def].title,
                            message: [
                                data.hubspot_response.error.message + ' ',
                                data.hubspot_response.error.properties.map(
                                    function (prop) {
                                        return prop + ', ';
                                    }
                                )
                            ],
                            autoDismiss: 5
                        });
                    }, 1200);
                }
                this.setState({ loading: false });
            });

        await getYachtMissingFields(params).then((res) => {
            if (Object.keys(res.data).length !== 0) {
                this.setState({ showModal: true });
                if (res.data.sales?.specs) {
                    this.setState({
                        messageSalesSpecs: res.data.sales.specs
                    });
                }
                if (res.data.charter?.specs) {
                    this.setState({
                        messageCharterSpecs: res.data.charter.specs
                    });
                }
                if (res.data.charter?.rates) {
                    this.setState({
                        messageRates: res.data.charter.rates
                    });
                } else {
                    this.setState({ messageRates: null });
                }
                if (res.data.sales?.website) {
                    this.setState({
                        messageSalesWebsite: res.data.sales.website
                    });
                }
                if (res.data.charter?.website) {
                    this.setState({
                        messageCharterWebsite: res.data.charter.website
                    });
                }
                if (res.data.general) {
                    this.setState({
                        messageGeneral: res.data.general
                    });
                }
            }
        });

        let equipment_watersport = {
            jetskis: form_props.jetskis,
            seabob: form_props.seabob,
            diving_equipment: form_props.diving_equipment,
            snorkeling: form_props.snorkeling,
            paddle_board: form_props.paddle_board,
            fishing_rod: form_props.fishing_rod
        };

        await this.props.updatePresentationToys(
            params.id,
            equipment_watersport
        );

        this.props.fetchYachtPresentation(params.id, {
            locale: this.state.current_locale
        });

        this.props.fetchPresentationToys(
            this.props.match.params.id,
            'equipment_watersport'
        );
    };

    render() {
        return (
            <>
                <ManagePresentationPresenter
                    history={this.props.history}
                    match={this.props.match}
                    user_role={this.props.user_role}
                    loading={this.state.loading}
                    locale={this.state.current_locale}
                    read_only={this.state.is_read_only}
                    viewType={this.state.viewType}
                    is_archived={this.state.is_archived}
                    yachtfolio_id={this.state.yachtfolio_id}
                    metaDescriptionError={this.state.metaDescriptionError}
                    setState={(value) => {
                        this.setState(value);
                    }}
                    onSubmit={this.onSubmit.bind(this)}
                    onLocaleChange={this.props.onLocaleChange}
                    renderLocaleSelection={this.props.renderLocaleSelection}
                />
                <MissingFieldsWarningModal
                    isOpen={this.state.showModal}
                    toggle={this.toggleModal}
                    messageSalesSpecs={this.state.messageSalesSpecs}
                    messageCharterSpecs={this.state.messageCharterSpecs}
                    messageSalesWebsite={this.state.messageSalesWebsite}
                    messageCharterWebsite={this.state.messageCharterWebsite}
                    messageGeneral={this.state.messageGeneral}
                    messageRates={this.state.messageRates}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user_role: state.auth.user_role
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchYachtPresentation: (id, params) =>
            dispatch(fetchYachtPresentation(id, params)),
        fetchUpdateYachtPresentation: (id, params) =>
            dispatch(fetchUpdateYachtPresentation(id, params)),
        fetchPresentationToys: (e) => dispatch(fetchPresentationToys(e)),
        updatePresentationToys: (id, params) =>
            dispatch(updatePresentationToys(id, params)),
        errorHandler: (options) => dispatch(Notifications.error(options)),
        showWarning: (options) => dispatch(warning(options))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocalizationDecorator()(ManagePresentationContainer));
