import React, { Component } from 'react';

import { ModalBody, ModalFooter, Button } from 'reactstrap';

class EmailConfirmation extends Component {
    checkConfirm(event, newValue, previousValue) {
        if (newValue === 'delete') {
            this.props.action();
            this.props.toggle();
        }
    }

    render() {
        return (
            <div className="modal-wrapper">
                <ModalBody>
                    <div className="modal-form-wrap">
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 mb-2 mt-2 text-center">
                                {this.props.self()
                                    ? `You are about to send this proposal to 
                                yourself.`
                                    : `You are about to send this proposal to your
                                Client.`}

                                <div className="mt-1">
                                    Do you wish to continue?
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="submit"
                        color="primary"
                        onClick={this.props.action}
                    >
                        Send
                    </Button>{' '}
                    <Button
                        type="button"
                        color="secondary"
                        onClick={this.props.toggle}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </div>
        );
    }
}

export default EmailConfirmation;
