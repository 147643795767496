import React, { PureComponent } from 'react';
import { FormGroup } from 'reactstrap';

import { Field } from 'redux-form';

class RenderRadio extends PureComponent {
    render() {
        return (
            <FormGroup
                className={
                    'radio ' +
                    (this.props.className !== undefined
                        ? ' ' + this.props.className
                        : '')
                }
            >
                <label
                    className={
                        'form-check-label ' +
                        (this.props.readOnly ? 'readonly' : '')
                    }
                >
                    {this.props.reduxEnabled ? (
                        <Field
                            name={this.props.group}
                            component="input"
                            type="radio"
                            disabled={
                                this.props.disabled !== undefined &&
                                this.props.disabled === true
                                    ? true
                                    : false
                            }
                            value={this.props.radioValue}
                        />
                    ) : (
                        <input
                            type="radio"
                            className="form-control"
                            id={this.props.group}
                            disabled={
                                this.props.disabled !== undefined &&
                                this.props.disabled === true
                                    ? true
                                    : false
                            }
                            readOnly={
                                this.props.readOnly !== undefined &&
                                this.props.readOnly === true
                                    ? true
                                    : false
                            }
                            {...this.props.input}
                        />
                    )}
                    <span>{this.props.label}</span>
                </label>
            </FormGroup>
        );
    }
}

export default RenderRadio;
