import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { reduxForm, Field, change } from 'redux-form';

import Loader from '../../../common/components/Loader';
import YachtMenu from '../components/YachtMenu';

import SimpleModal from '../../../common/components/SimpleModal';
import DeleteConfirmation from '../../../common/components/DeleteConfirmation';

// Fields
import RenderDatePicker from '../../../common/components/RenderDatePicker';
import RenderReactSelect from '../../../common/components/RenderReactSelect';
import RenderRedactor from '../../../common/components/RenderRedactor';
import BookingsLocationsDropdown from '../../bookings/components/BookingsLocationsDropdown';

// Helpers
import { required } from '../../../helpers/validate';
import { BOOKING_STATUSES_OPTIONS } from '../../../infrastructure/helpers/globals';
import moment from 'moment';
import { isAdmin } from '../../../infrastructure/helpers/roles';
import RenderField from '../../../common/components/RenderField';

let ManageYachtBookingPresenter = (props) => {
    return (
        <div className="content-inner-wrapper">
            <YachtMenu
                user_role={props.user_role}
                match={props.match}
                history={props.history}
            />

            <div className="content-inner">
                {props.loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <form onSubmit={props.handleSubmit(props.onSubmit)}>
                        <Row>
                            <Col
                                xs="12"
                                xl="8"
                                className="d-flex align-items-center justify-content-between mt-1 mb-2"
                            >
                                <h4 className="m-0">{props.pageTitle}</h4>

                                {!props.isReadOnly && (
                                    <div className="d-flex">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>

                                        {props.yacht && props.yacht.id && (
                                            <NavLink
                                                to={`/yachts/view/charter/${props.yacht.id}/bookings`}
                                                className="btn btn-primary btn-red ml-1"
                                            >
                                                Cancel
                                            </NavLink>
                                        )}
                                    </div>
                                )}

                                {props.isReadOnly && (
                                    <div className="d-flex">
                                        <NavLink
                                            to={`/yachts/view/charter/${props.yacht.id}/bookings`}
                                            className="btn btn-primary btn-search"
                                        >
                                            Back to {props.yacht.name} bookings
                                        </NavLink>

                                        <NavLink
                                            to={`/bookings`}
                                            className="btn btn-primary btn-search ml-1"
                                        >
                                            Back to all bookings
                                        </NavLink>
                                    </div>
                                )}
                            </Col>

                            <Col xs="12" xl="8" className="mt-2">
                                <Row>
                                    <Col
                                        xs="12"
                                        md="6"
                                        className="customDatePicker"
                                    >
                                        <Field
                                            name="date_from"
                                            component={RenderDatePicker}
                                            placeholder="DATE FROM"
                                            validate={[required]}
                                            readOnly={props.isReadOnly}
                                            date_format="YYYY/MM/DD"
                                        />
                                    </Col>

                                    <Col
                                        xs="12"
                                        md="6"
                                        className="customDatePicker"
                                    >
                                        <Field
                                            name="date_to"
                                            component={RenderDatePicker}
                                            placeholder="DATE TO"
                                            validate={[required]}
                                            readOnly={props.isReadOnly}
                                            date_format="YYYY/MM/DD"
                                        />
                                    </Col>

                                    <Col xs="12" md="6">
                                        <BookingsLocationsDropdown
                                            name="location_from"
                                            placeholder="LOCATION FROM"
                                            readOnly={props.isReadOnly}
                                            onSelect={(suggestion) => {
                                                props.changeFieldValue(
                                                    'location_from',
                                                    suggestion
                                                );
                                            }}
                                        />
                                    </Col>

                                    <Col xs="12" md="6">
                                        <BookingsLocationsDropdown
                                            name="location_to"
                                            placeholder="LOCATION TO"
                                            readOnly={props.isReadOnly}
                                            onSelect={(suggestion) => {
                                                props.changeFieldValue(
                                                    'location_to',
                                                    suggestion
                                                );
                                            }}
                                        />
                                    </Col>

                                    <Col xs="12" md="6">
                                        <Field
                                            name="status"
                                            component={RenderReactSelect}
                                            placeholder="STATUS"
                                            options={BOOKING_STATUSES_OPTIONS}
                                            validate={[required]}
                                            readOnly={props.isReadOnly}
                                        />
                                    </Col>

                                    {props.isReadOnly && (
                                        <Col xs="12" md="6">
                                            <Field
                                                name="created_at"
                                                component={RenderField}
                                                placeholder="CREATED ON"
                                                readOnly={props.isReadOnly}
                                            />
                                        </Col>
                                    )}

                                    {props.isReadOnly && (
                                        <Col xs="12" md="6">
                                            <Field
                                                name="updated_at"
                                                component={RenderField}
                                                placeholder="UPDATED ON"
                                                readOnly={props.isReadOnly}
                                            />
                                        </Col>
                                    )}

                                    {props.isReadOnly && (
                                        <Col xs="12" md="6">
                                            <Field
                                                name="user"
                                                component={RenderField}
                                                placeholder="CREATED BY USER"
                                                input={{
                                                    value:
                                                        props.user &&
                                                        props.user.name
                                                            ? props.user.name
                                                            : ''
                                                }}
                                                readOnly={props.isReadOnly}
                                            />
                                        </Col>
                                    )}

                                    <Col xs="12">
                                        <label className="upload-label text-uppercase mt-2">
                                            Booking Notes
                                        </label>

                                        <RenderRedactor
                                            hiddenTitle={true}
                                            name="notes"
                                            readOnly={props.isReadOnly}
                                            disabled={props.isReadOnly}
                                            id="description-redactor"
                                            onChange={(text) => {
                                                props.changeFieldValue(
                                                    'notes',
                                                    text
                                                );
                                            }}
                                        />
                                    </Col>

                                    {props.isReadOnly &&
                                        isAdmin(props.user_role) && (
                                            <Col xs="12">
                                                <button
                                                    className="btn btn-primary btn-red"
                                                    onClick={
                                                        props.onToggleModal
                                                    }
                                                >
                                                    Delete
                                                </button>
                                            </Col>
                                        )}
                                </Row>
                            </Col>

                            <Col xs="12">
                                <SimpleModal
                                    header="Delete booking"
                                    body={
                                        <DeleteConfirmation
                                            toggle={props.onToggleModal}
                                            action={props.onDeleteConfirmed}
                                        />
                                    }
                                    modalOpen={props.isModalOpen}
                                    toggle={props.onToggleModal}
                                />
                            </Col>
                        </Row>
                    </form>
                )}
            </div>
        </div>
    );
};

ManageYachtBookingPresenter = reduxForm({
    form: 'manage_yacht_booking',
    enableReinitialize: true
})(ManageYachtBookingPresenter);

const mapStateToProps = (state, ownProps) => {
    let booking = Object.assign({}, state.bookings.booking);
    const yacht = booking.yacht;
    const user = booking.user;

    delete booking.yacht;
    delete booking.user;

    let initialValues = {
        ...booking,
        date_from: booking.id
            ? moment(booking.date_from).format('YYYY/MM/DD')
            : moment().format('YYYY/MM/DD'),
        date_to: booking.id
            ? moment(booking.date_to).format('YYYY/MM/DD')
            : moment().format('YYYY/MM/DD')
    };

    if (ownProps.isReadOnly) {
        initialValues = Object.assign(initialValues, {
            created_at: booking.id
                ? moment(booking.created_at).format('YYYY/MM/DD')
                : '',
            updated_at: booking.id
                ? moment(booking.updated_at).format('YYYY/MM/DD')
                : ''
        });
    }
    return {
        yacht: yacht,
        user: user,
        initialValues: initialValues
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change('manage_yacht_booking', field, value))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageYachtBookingPresenter);
