import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { submit } from 'redux-form'

import EditYachtListPresenter from './EditYachtListPresenter';

// Redux
import { fetchYachtList, updateYachtList } from './redux/_actions';

// SVG
import savedSearches from '../../assets/svgs/saved-searches.svg';
import debounce from 'lodash/debounce';

const orderingOptions = [
    {
        value: 'desc_loa',
        label: 'LOA (m) - DESC'
    },
    {
        value: 'asc_loa',
        label: 'LOA (m) - ASC'
    },
    {
        value: 'desc_name',
        label: 'Name - DESC'
    },
    {
        value: 'asc_name',
        label: 'Name - ASC'
    },
    {
        value: 'desc_year_built',
        label: 'Year Built - DESC'
    },
    {
        value: 'asc_year_built',
        label: 'Year Built - ASC'
    },
    {
        value: 'desc_builder',
        label: 'Builder - DESC'
    },
    {
        value: 'asc_builder',
        label: 'Builder - ASC'
    },
]

class EditYachtListContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            menu: {
                parent: savedSearches,
                items: []
            },
            selectedYachts: [],
            orderingValue: null,
            orderingOptions: orderingOptions,
        };

        this.autoUpdateForm = debounce(this.props.autoSubmitForm, 200);
    }

    componentDidMount() {
        let menu = [
            <NavLink exact to="/yacht-lists">
                Yacht Lists
            </NavLink>,
            <NavLink exact to={`/yacht-lists/${this.props.match.params.id}/edit`}>
                Edit
            </NavLink>
        ];

        this.setState({ menu: { ...this.state.menu, menu } });
        this.fetchYachtList();
    }

    onOrderChange(value) {
        this.setState({ orderingValue: value }, this.fetchYachtList);
    }

    fetchYachtList() {
        const id = this.props.match.params.id;
        this.setState({ loading: true });

        let params ={};

        if (this.state.orderingValue !== null) {
            params.order_dir = this.state.orderingValue.split('_')[0];
            params.order_field = this.state.orderingValue.includes('desc_')
                ? this.state.orderingValue.replace('desc_', '')
                : this.state.orderingValue.replace('asc_', '');
        }

        this.props
            .fetchYachtList(id, params)
            .then(data => {
                this.setState({loading: false })
            });
    }

    onSubmit(form_props) {
        let params = {
            internal_title: form_props.internal_title,
            type: form_props.type,
            is_public: form_props.is_public,
            notes: form_props.notes,
            yachts: this.state.selectedYachts.map(yacht => yacht.yacht_id)
        };

        this.props
            .updateYachtList(form_props.id, params);
    }

    render() {
        return (
            <EditYachtListPresenter
                history={this.props.history}
                menu={this.state.menu}
                loading={this.state.loading}
                orderingValue={this.state.orderingValue}
                orderingOptions={this.state.orderingOptions}
                onSubmit={this.onSubmit.bind(this)}
                onOrderChange={this.onOrderChange.bind(this)}
                onSetSelectedYacht={yachts => {
                    this.setState({ selectedYachts: yachts })
                    this.autoUpdateForm();
                }}
                autoSubmitForm={() => this.autoUpdateForm()}
            />
        );
    }
}

const mapStateToProps = props => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        fetchYachtList: (id, params) => dispatch(fetchYachtList(id, params)),
        updateYachtList: (id, params) => dispatch(updateYachtList(id, params)),
        autoSubmitForm: () => dispatch(submit('update_yacht_list_form'))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditYachtListContainer);
