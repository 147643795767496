import React from 'react';
import { Field } from 'redux-form';
import ReactSVG from 'react-svg';
import { Col, Button, UncontrolledTooltip } from 'reactstrap';

// Fields
import RenderField from '../../../common/components/RenderField';
import RenderRedactor from '../../../common/components/RenderRedactor';
import UploadField from '../../../common/components/UploadField';
import SelectPhotosFromGallery from 'common/components/SelectPhotosFromGallery';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ToggleCollapse from '../../../common/components/ToggleCollapse';

// SVG
import add from '../../../assets/svgs/add.svg';
import remove from '../../../assets/svgs/remove.svg';
import rearrange from '../../../assets/svgs/rearrange.svg';

class RenderKeyFeaturesFields extends React.Component {
    onEndReorder = (result) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(this.props.fields.getAll());
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        //removes all fields from toysFields and pushes the new ordered fields

        this.props.fields.removeAll();
        //this setTimeout is wrapped arround items.map only to force the renderReductor component to render correctly
        setTimeout(() => {
            items.map((item) => {
                this.props.fields.push(item);
            });
        }, 0);
    };

    render() {
        const {
            fields,
            meta: { error, submitFailed },

            read_only
            // viewType,
            // locale,
            // yachtfolio_id
        } = this.props;

        return (
            <>
                <div className="row">
                    {!read_only && (
                        <>
                            <div
                                className="col-2"
                                style={{ marginBottom: '40px' }}
                            >
                                <Button
                                    color="info"
                                    onClick={() => fields.push({})}
                                    // disabled={
                                    //     viewType === 'charter' &&
                                    //     locale === 'en' &&
                                    //     yachtfolio_id
                                    // }
                                >
                                    <ReactSVG
                                        path={add}
                                        style={{ height: 12 }}
                                    />
                                    Add Key Feature
                                </Button>
                                {submitFailed && error && <span>{error}</span>}
                            </div>

                            <div className="col-1 mt-05">
                                <div
                                    className="btn btn-indication"
                                    id="keyFeatures-info"
                                >
                                    i
                                </div>
                                <UncontrolledTooltip
                                    placement="right"
                                    target="keyFeatures-info"
                                >
                                    These appear in a carousel, in sets of three
                                    columns, near the bottom of the page. They
                                    can either be a combo of a small image, a
                                    title and the description, or just the
                                    title. Key features without a title will be
                                    ignored.
                                </UncontrolledTooltip>
                            </div>
                        </>
                    )}
                    <div className="col-12">
                        <DragDropContext onDragEnd={this.onEndReorder}>
                            <Droppable
                                droppableId="droppable-field"
                                isDropDisabled={read_only}
                            >
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {fields.map((keyFeature, index) => {
                                            const initImage =
                                                fields.get(index).images &&
                                                fields.get(index).images
                                                    .length > 0
                                                    ? fields.get(index)
                                                          .images[0].url
                                                    : null;

                                            return (
                                                <Draggable
                                                    key={`${keyFeature}`}
                                                    draggableId={`${keyFeature}`}
                                                    index={index}
                                                    isDragDisabled={read_only}
                                                >
                                                    {(provided) => (
                                                        <div>
                                                            <div
                                                                className="row"
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <div className="drag-handle col-1 mb-1">
                                                                    <ReactSVG
                                                                        path={
                                                                            rearrange
                                                                        }
                                                                        style={{
                                                                            height: 24
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-11">
                                                                    <ToggleCollapse
                                                                        initOpen={
                                                                            false
                                                                        }
                                                                        body={
                                                                            <>
                                                                                <Col xs="12 mt-2">
                                                                                    <Field
                                                                                        name={`${keyFeature}.translations.title`}
                                                                                        placeholder="TITLE"
                                                                                        component={
                                                                                            RenderField
                                                                                        }
                                                                                        className="mt-1"
                                                                                        readOnly={
                                                                                            this
                                                                                                .props
                                                                                                .read_only
                                                                                        }
                                                                                    />
                                                                                </Col>

                                                                                {read_only ? null : (
                                                                                    <Col
                                                                                        xs="12"
                                                                                        md="9"
                                                                                    >
                                                                                        <SelectPhotosFromGallery
                                                                                            name={`${keyFeature}.images[0]`}
                                                                                            label={
                                                                                                'Select from Photos'
                                                                                            }
                                                                                            selectedImages={[
                                                                                                initImage
                                                                                            ]}
                                                                                            setSelectedImages={(
                                                                                                urls
                                                                                            ) => {
                                                                                                this.props.changeFieldValue(
                                                                                                    `${keyFeature}.images[0].url`,
                                                                                                    urls &&
                                                                                                        urls[0] &&
                                                                                                        urls[0]
                                                                                                            .url
                                                                                                        ? urls[0]
                                                                                                              .url
                                                                                                        : null
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                )}

                                                                                <Col
                                                                                    xs="12"
                                                                                    md="9"
                                                                                >
                                                                                    <UploadField
                                                                                        name={`${keyFeature}.images[0]`}
                                                                                        desc="Upload image!"
                                                                                        label="KEY FEATURE IMAGE"
                                                                                        show_alt_text_input={
                                                                                            true
                                                                                        }
                                                                                        initPhoto={
                                                                                            fields.get(
                                                                                                index
                                                                                            )
                                                                                                .images &&
                                                                                            fields.get(
                                                                                                index
                                                                                            )
                                                                                                .images
                                                                                                .length >
                                                                                                0
                                                                                                ? fields.get(
                                                                                                      index
                                                                                                  )
                                                                                                      .images[0]
                                                                                                      .url
                                                                                                : null
                                                                                        }
                                                                                        disabled={
                                                                                            this
                                                                                                .props
                                                                                                .locale !==
                                                                                            'en'
                                                                                                ? true
                                                                                                : this
                                                                                                      .props
                                                                                                      .read_only
                                                                                        }
                                                                                        fileLabelName="Click here to upload photo"
                                                                                        add={(
                                                                                            url,
                                                                                            name
                                                                                        ) => {
                                                                                            this.props.changeFieldValue(
                                                                                                `${keyFeature}.images[0].url`,
                                                                                                url
                                                                                            );
                                                                                        }}
                                                                                        type="single"
                                                                                        className="mb-2"
                                                                                    />
                                                                                </Col>

                                                                                <Col
                                                                                    xs="12"
                                                                                    md="9"
                                                                                    className="mb-1"
                                                                                >
                                                                                    <RenderRedactor
                                                                                        name={`${keyFeature}.translations.text`}
                                                                                        label="DESCRIPTION"
                                                                                        onChange={(
                                                                                            text
                                                                                        ) => {
                                                                                            this.props.changeFieldValue(
                                                                                                `${keyFeature}.translations.text`,
                                                                                                text
                                                                                            );
                                                                                        }}
                                                                                        readOnly={
                                                                                            this
                                                                                                .props
                                                                                                .read_only
                                                                                        }
                                                                                        disabled={
                                                                                            true
                                                                                        }
                                                                                        id={`keyFeature-text-${index}`}
                                                                                    />
                                                                                </Col>

                                                                                {!read_only && (
                                                                                    <Col
                                                                                        xs="12"
                                                                                        md="3"
                                                                                    >
                                                                                        <Button
                                                                                            className="mb-1"
                                                                                            color="danger"
                                                                                            onClick={() =>
                                                                                                fields.remove(
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <ReactSVG
                                                                                                path={
                                                                                                    remove
                                                                                                }
                                                                                                style={{
                                                                                                    height: 12
                                                                                                }}
                                                                                            />
                                                                                            Remove
                                                                                            Entry
                                                                                        </Button>
                                                                                    </Col>
                                                                                )}
                                                                            </>
                                                                        }
                                                                        header={`Key
                                                                        Feature
                                                                        #
                                                                        ${
                                                                            index +
                                                                            1
                                                                        }`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            </>
        );
    }
}

export default RenderKeyFeaturesFields;
