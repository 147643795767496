import React from 'react';

const Instructions = ({ mode, title, messages, footer, style }) => {
    return (
        <div className={`instructions instructions--${mode}`} style={style}>
            <p className="instructions__title" dangerouslySetInnerHTML={{ __html: title}} />
            
            <ul className="instructions__messages-wrapper">
                {messages && messages.map((message, index) => (
                    <li 
                        key={index}
                        className="instructions__message" 
                        dangerouslySetInnerHTML={{ __html: message}} 
                    />
                ))}
            </ul>

            { footer && (
                <hr className="instructions__divider" />
            )}

            { footer && (
                <p className="instructions__footer" dangerouslySetInnerHTML={{ __html: footer}}/>    
            )}
        </div>
    );
}

export default Instructions;