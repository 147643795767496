import React, { Component } from 'react';

import editSVG from '../../assets/svgs/edit.svg';
import deleteSVG from '../../assets/svgs/delete.svg';
import ReactSVG from 'react-svg';
import SimpleModal from '../../common/components/SimpleModal';
import DeleteConfirmation from '../../common/components/DeleteConfirmation';
import EditSalesListingYacht from '../../common/components/EditSalesListingYacht';
import yachts from '../../assets/svgs/yachts_default.svg';

import { functions } from '../../helpers/functions';

function getLocation(city, country) {
    if (city) {
        if (country) {
            return `, Located ${city}, ${country}`;
        } else {
            return `, Located ${city}`;
        }
    } else if (country) {
        return `, Located ${country}`;
    }
    return '';
}
function yachtInfo({
    loa,
    loa_unit,
    builder,
    city,
    country,
    year_built,
    year_refit
}) {
    const loaDisplay = functions.unit(loa, loa_unit, 'feet_only');
    const metersDisplay = functions.unit(loa, loa_unit, 'meters_only');
    const Location = getLocation(city, country);
    const year = year_refit ? `${year_built}/${year_refit}` : year_built;
    return `${loaDisplay} (${metersDisplay}) ${builder} ${year}${Location}`;
}

class ListedYacht extends Component {
    state = {};

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    async editYachtModal() {
        const {
            yacht: { yachts: yacht, broker, broker2, notes, order }
        } = this.props;

        this.setState({
            defaultModal: (
                <EditSalesListingYacht
                    toggle={this.toggleModal.bind(this)}
                    action={() => this.props.removeYacht(yacht.id)}
                    yacht={yacht}
                    brokers={[broker, broker2]}
                    notes={notes}
                    order={order}
                    updateYacht={this.props.updateYacht}
                    viewType={'charter'}
                />
            )
        });
        this.setState({ modalHeader: `Update Yacht "${yacht.name}"` });

        this.toggleModal();
    }

    showYachtPhoto(viewType, yacht) {
        if (viewType === 'charter' && yacht.charter_photo) {
            return `${yacht.charter_photo}`;
        } else {
            return `${yacht.photo}`;
        }
    }

    deleteModal() {
        const {
            yacht: { yachts: yacht }
        } = this.props;
        this.setState({
            defaultModal: (
                <DeleteConfirmation
                    toggle={this.toggleModal.bind(this)}
                    action={() => this.props.removeYacht(yacht.id)}
                    body={`You are about to remove yacht "${yacht.name}" charter listing.`}
                />
            )
        });
        this.setState({ modalHeader: 'Remove Yacht from Charter Listing' });

        this.toggleModal();
    }
    render() {
        const {
            yacht: { yachts: yacht, broker, broker2, notes },
            viewType
        } = this.props;

        return (
            <div
                className="col-12 yacht-sales-listing"
                style={{ marginTop: '20px' }}
            >
                <div className="sales-listing-yachts-controls">
                    <button
                        className="control"
                        onClick={() => this.editYachtModal()}
                    >
                        <div style={{ pointerEvents: 'none' }}>
                            <ReactSVG style={{ height: 16 }} path={editSVG} />
                        </div>
                    </button>
                    <button
                        className="control"
                        onClick={() => this.deleteModal()}
                    >
                        <div style={{ pointerEvents: 'none' }}>
                            <ReactSVG style={{ height: 16 }} path={deleteSVG} />
                        </div>
                    </button>
                </div>
                <div className="row">
                    <div className="col-8" style={{ padding: '0px' }}>
                        <div className="yacht-image-sales-listing">
                            <img
                                src={this.showYachtPhoto(viewType, yacht)}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="col-4" style={{ padding: '0px' }}>
                        <div style={{ backgroundColor: '#F1F1F2' }}>
                            <h2 className="notes"> {notes || '{Notes}'} </h2>
                        </div>
                        <div className="brokers-field">
                            {broker && (
                                <div>
                                    <div className="field name">
                                        {broker.name}
                                    </div>
                                    <div className="field">{broker.mobile}</div>
                                    <div className="field">
                                        {broker.contact}
                                    </div>
                                </div>
                            )}
                            {broker2 && (
                                <div>
                                    <div className="field name">
                                        {broker2.name}
                                    </div>
                                    <div className="field">
                                        {broker2.mobile}
                                    </div>
                                    <div className="field">
                                        {broker2.contact}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            <h3 className="yacht-name">
                                {yacht.name.toUpperCase()}
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="row blue-bg footer">
                    <div
                        className="col-8"
                        style={{ textAlign: 'center', margin: 'auto' }}
                    >
                        {yachtInfo(yacht)}
                    </div>
                    <div
                        className="col-4"
                        style={{ textAlign: 'right', margin: 'auto' }}
                    >
                        {functions.priceCurrencyFormat(
                            yacht.asking_price_displayed,
                            yacht.asking_price_currency
                        )}
                    </div>
                </div>

                <SimpleModal
                    header={this.state.modalHeader}
                    body={this.state.defaultModal}
                    modalOpen={this.state.modalOpen}
                    toggle={this.toggleModal.bind(this)}
                />
            </div>
        );
    }
}

export default ListedYacht;
