import React from 'react';
import { Field } from 'redux-form';

import RenderField from '../../../common/components/RenderField';

let RenderBookingsLocationsSuggestions = props => {
    return (
        <ul className="list-unstyled companies-list">
            <li>
                <Field
                    name={props.name}
                    type="text"
                    placeholder={props.placeholder}
                    component={RenderField}
                    onChange={(event, value) => props.change(event, value)}
                    autoComplete={false}
                    readOnly={props.readOnly}
                />
                
                { props.is_active && props.suggestions &&
                props.suggestions.length > 0 ? (
                    <ul className="list-unstyled results-list">
                        {props.suggestions.map((suggestion, i) => {
                            return (
                                <li 
                                    key={i} 
                                    onClick={e => {
                                        e.preventDefault();
                                        props.select(suggestion);
                                    }}
                                    style={{ margin: '12px', color: '#41506e', cursor: 'pointer' }}
                                >
                                    {suggestion || ''}
                                </li>
                            );
                        })}
                    </ul>
                ) : null}
            </li>
        </ul>
    );
}

export default RenderBookingsLocationsSuggestions