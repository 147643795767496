import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';

import Header from '../../common/header/Header';
import Loader from '../../common/components/Loader';
import LabeledInfo from '../../common/components/LabeledInfo';
import { isAdmin } from '../../infrastructure/helpers/roles';
import SimpleModal from '../../common/components/SimpleModal';
import DeleteConfirmation from '../../common/components/DeleteConfirmation';

const leadPresenter = ({
    menu,
    history,
    loading,
    lead,
    userRole,
    onToggleModal,
    onDeleteConfirmed,
    isModalOpen
}) => {
    return (
        <div className="content-inner-wrapper">
            <Header menu={menu} history={history} />

            <div className="content-inner">
                {loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <Row className="result-yacht-wrapper">
                        <Col xs="12" lg="9">
                            <Row>
                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="SUBMITTED AT"
                                        value={moment(lead.submitted_at).format(
                                            'YYYY-MM-DD HH::mm'
                                        )}
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="USER IP ADDRESS"
                                        value={lead.ip_address}
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="NAME"
                                        value={`${lead.first_name} ${lead.last_name}`}
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="EMAIL"
                                        value={lead.email}
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="MESSAGE"
                                        value={lead.message}
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="PHONE"
                                        value={`${lead.phone_code} ${lead.phone}`}
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="RECIPIENT MAIL"
                                        value={lead.recipient_email || '-'}
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="PAGE TITLE"
                                        value={lead.page_title || '-'}
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="YACHT NAME"
                                        value={lead.yacht_name || '-'}
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="EVENT"
                                        value={lead.event_name || '-'}
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="REQUEST TYPE"
                                        value={lead.request_type || '-'}
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="PREFERRED METHOD"
                                        value={lead.preferred_method || '-'}
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="CONTACT FORM NAME"
                                        value={lead.contact_form_name || '-'}
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="PAGE URL"
                                        value={lead.page_url || '-'}
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="POLICY ACCEPTANCE"
                                        value={lead.policy_acceptance || '-'}
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="POLICY ACCEPTANCE AT"
                                        value={
                                            lead.policy_acceptance_timestamp
                                                ? moment(
                                                      lead.policy_acceptance_timestamp
                                                  ).format('YYYY-MM-DD HH::mm')
                                                : '-'
                                        }
                                    />
                                </Col>

                                <Col xs="12" md="6" xl="4" className="mb-3">
                                    <LabeledInfo
                                        label="SUBMISSION ID"
                                        value={lead.submission_id || '-'}
                                    />
                                </Col>
                                {isAdmin(userRole) && (
                                    <Col xs="12">
                                        <button
                                            className="btn btn-primary btn-red"
                                            onClick={onToggleModal}
                                        >
                                            Delete
                                        </button>
                                    </Col>
                                )}
                                <Col xs="12">
                                    <SimpleModal
                                        header="You are about to delete a lead"
                                        body={
                                            <DeleteConfirmation
                                                toggle={onToggleModal}
                                                action={onDeleteConfirmed}
                                            />
                                        }
                                        modalOpen={isModalOpen}
                                        toggle={onToggleModal}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
};
export default leadPresenter;
