import { 
    FETCH_LEADS_SUCCESS, 
    FETCH_LEAD_SUCCESS, 
    FETCH_LUCENT_FORMS_SUCCESS 
} from './_types';

const INITIAL_STATE = {
    leads: {
        data: [],
        pagination: {}
    },
    lead: {},
    lucent_forms: []
};

const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case FETCH_LEADS_SUCCESS:
            return {
                ...state,
                leads: action.payload
            }
        case FETCH_LEAD_SUCCESS:
            return {
                ...state,
                lead: action.payload
            }
        case FETCH_LUCENT_FORMS_SUCCESS:
            let ids = [];
            return {
                ...state,
                lucent_forms: action.payload.reduce((acc, form) => {
                    if (ids.includes(form.uuid)) return acc;
                    let val = {
                        label: form.title,
                        value: form.title
                    }
                    ids.push(form.uuid);
                    acc.push(val);

                    return acc;
                }, [])
            }
        default:
            return state;
    }
}

export default reducer;