import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import IndexLocationPresenter from './IndexLocationPresenter';
import DeleteConfirmation from '../../common/components/DeleteConfirmation';

import customers from '../../assets/svgs/customers.svg';

import { fetchLocations, fetchDeleteLocation } from './redux/_actions';

class IndexLocationContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                parent: customers,
                items: [
                    <NavLink exact to={'/locations'}>
                        All
                    </NavLink>,
                    <NavLink exact to={'/locations/create'}>
                        Create
                    </NavLink>
                ]
            },
            table_headers: [
                {
                    name: 'name',
                    label: 'Location name',
                    sort: false
                },
                {
                    name: 'action',
                    label: 'Action',
                    sort: false
                }
            ],
            modalHeader: 'You are about to delete location',
            defaultModal: null,
            isModalOpen: false,
            location_to_delete: null
        };
    }

    componentDidMount() {
        this.props.fetchLocations();
    }

    onToggleModal() {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    onDelete(id) {
        const location = this.props.locations.data.filter(
            (location) => location.id === id
        )[0];

        console.log('onDELETE');
        console.log('location: ', this.props.locations.data);

        let message = '';

        if (location?.yachts?.length > 0) {
            message = `You are about to delete a location with yachts attached!!`;
        } else {
            message = `You are about to delete location ${location.name}`;
        }

        this.setState({
            defaultModal: (
                <DeleteConfirmation
                    toggle={this.onToggleModal.bind(this)}
                    action={this.onDeleteConfirmed.bind(this)}
                    body={message}
                />
            ),
            isModalOpen: true,
            location_to_delete: location.id
        });
    }

    onDeleteConfirmed() {
        let id = this.state.location_to_delete;

        this.props.fetchDeleteLocation(id).then((response) => {
            this.props.fetchLocations();
        });
    }

    onTableChange(key, value) {
        const current_pagination = this.props.locations.pagination;

        let params = {
            page: key === 'page' ? value : 1,
            page_size:
                key === 'page_size' ? value : current_pagination.page_size,
            search: key === 'search' ? value : current_pagination.search
        };

        this.props.fetchLocations(params);
    }

    render() {
        return (
            <IndexLocationPresenter
                menu={this.state.menu}
                history={this.props.history}
                locations={this.props.locations}
                onTableChange={this.onTableChange.bind(this)}
                table_headers={this.state.table_headers}
                onDelete={this.onDelete.bind(this)}
                modalHeader={this.state.modalHeader}
                modalBody={this.state.defaultModal}
                onToggleModal={this.onToggleModal.bind(this)}
                isModalOpen={this.state.isModalOpen}
            />
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLocations: (params) => dispatch(fetchLocations(params)),
        fetchDeleteLocation: (id) => dispatch(fetchDeleteLocation(id))
    };
};

const mapStateToProps = (state) => {
    return {
        locations: state.locations.locations
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndexLocationContainer);
