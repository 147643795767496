import {
    FETCH_CUSTOMERS,
    FETCH_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMERS_FAIL,
    FETCH_GET_CUSTOMER,
    FETCH_GET_CUSTOMER_SUCCESS,
    FETCH_GET_CUSTOMER_FAIL,
    INIT_CUSTOMER,
    FETCH_MANAGE_CUSTOMER_SUCCESS
} from './_types';

const INITIAL_STATE = {
    pagination: { page: 1, total: 0, page_size: 1, page_count: 1 },
    sorting: {
        order_field: 'last_name',
        order_dir: 'ASC',
        favourite: 0,
        search: ''
    },
    customers: [],
    actionTriggered: false,
    initCustomer: {
        first_name: '',
        last_name: '',
        emails: [
            {
                customer_id: Number,
                primary: 1,
                type: 'customer',
                value: ''
            }
        ]
    }
};

export default function customersReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_CUSTOMERS:
            return state;
        case FETCH_CUSTOMERS_SUCCESS:
            let pagination = state.pagination;
            if (action.payload.pagination) {
                pagination = {
                    page:
                        action.payload.pagination.total === 0
                            ? 0
                            : action.payload.pagination.page >
                              action.payload.pagination.page_count
                            ? 1
                            : action.payload.pagination.page,
                    total: action.payload.pagination.total,
                    page_size: action.payload.pagination.page_size,
                    page_count: action.payload.pagination.page_count
                };
            }
            return {
                ...state,
                customers: action.payload.customers,
                pagination: pagination,
                sorting: action.payload.sorting
            };
        case FETCH_CUSTOMERS_FAIL:
            return { ...state, error: action.payload };
        case FETCH_GET_CUSTOMER:
            return state;
        case FETCH_GET_CUSTOMER_SUCCESS:
        case INIT_CUSTOMER:
            return { ...state, initCustomer: action.payload };
        case FETCH_GET_CUSTOMER_FAIL:
            return { ...state, initCustomer: {} };
        case FETCH_MANAGE_CUSTOMER_SUCCESS:
            return { ...state, initCustomer: action.payload };
        default:
            return state;
    }
}
