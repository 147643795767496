import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import ViewLeadPresenter from './ViewLeadPresenter';

import { fetchLead, deleteLead } from './redux/_actions';

import mail from '../../assets/svgs/mail.svg';

class ViewLeadContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            menu: {
                parent: mail,
                items: [
                    <NavLink exact to={'/leads'}>
                        All
                    </NavLink>
                ]
            },
            isModalOpen: false
        };
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        this.props
            .fetchLead(id)
            .then((data) => this.setState({ loading: false }));
    }

    onDeleteConfirmed() {
        const leadID = this.props.match.params.id;

        this.props
            .deleteLead(leadID)
            .then((response) => this.props.history.push('/leads'));
    }

    onToggleModal() {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    render() {
        return (
            <ViewLeadPresenter
                menu={this.state.menu}
                loading={this.state.loading}
                history={this.props.history}
                tableHeaders={this.state.tableHeaders}
                userRole={this.props.userRole}
                lead={this.props.lead}
                isModalOpen={this.state.isModalOpen}
                onToggleModal={this.onToggleModal.bind(this)}
                onDeleteConfirmed={this.onDeleteConfirmed.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userRole: state.auth.user_role,
        lead: state.leads.lead
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLead: (id) => dispatch(fetchLead(id)),
        deleteLead: (id) => dispatch(deleteLead(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewLeadContainer);
