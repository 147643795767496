import axios from 'axios';

import {
  FETCH_EXCHANGE_RATES,
  FETCH_EXCHANGE_RATES_SUCCESS,
  FETCH_EXCHANGE_RATES_FAIL
} from './_types';

export function getRealRates() {
  return dispatch => {
    dispatch({
      type: FETCH_EXCHANGE_RATES
    });

    return axios
      .get('https://api.fixer.io/latest?base=USD')
      .then(response => {
        dispatch({
          type: FETCH_EXCHANGE_RATES_SUCCESS,
          payload: response.data
        });

        return response.data;
      })
      .catch(error => {
        dispatch({
          type: FETCH_EXCHANGE_RATES_FAIL
        });
      });
  };
}
