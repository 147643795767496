import React, { PureComponent } from 'react';
import { FormGroup } from 'reactstrap';

class RenderCheckbox extends PureComponent {
    render() {
        return (
            <FormGroup
                className={
                    (this.props.onDashboard
                        ? 'checkbox-wrapper mb-0 '
                        : 'checkbox-wrapper ') +
                    (this.props.disabled ? ' disabled ' : '') +
                    (this.props.className ? this.props.className : '')
                }
            >
                <div className="checkbox">
                    <label>
                        <input
                            type="checkbox"
                            checked={
                                this.props.input.value &&
                                this.props.input.value === true
                                    ? true
                                    : false
                            }
                            disabled={
                                this.props.disabled === true ? true : false
                            }
                            {...this.props.input}
                        />
                        <span
                            className={
                                this.props.onDashboard
                                    ? 'checkmark-box dashboard-chbx'
                                    : 'checkmark-box'
                            }
                        >
                            {this.props.label}
                        </span>
                    </label>
                </div>
            </FormGroup>
        );
    }
}

export default RenderCheckbox;
