import { FETCH_INIT_YACHT_RATES, FETCH_YACHT_RATES_SUCCESS } from './_types';

const INITIAL_STATE = {
    yacht_id: null,
    rates: []
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_YACHT_RATES_SUCCESS:
            return {
                ...state,
                yacht_id: action.payload.id,
                rates: action.payload.rates
            };
        case FETCH_INIT_YACHT_RATES:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default reducer;
