import React, { Component } from 'react';
import ToggleCollapse from '../../common/components/ToggleCollapse';

import FilterFields from './FilterFields';

class WantsToBuy extends Component {
  render() {
    return (
      <ToggleCollapse
        className={'grey'}
        body={
          <div className="wants_to_buy_fields">
            <FilterFields
              type="sales"
              location=""
              prefix={'prefix'}
              form={'manage_customer'}
              readOnly={this.props.readOnly}
            />
          </div>
        }
        header="WANTS TO BUY"
      />
    );
  }
}

export default WantsToBuy;
