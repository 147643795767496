import React from 'react';
import ReactSVG from 'react-svg';
import { NavLink } from 'react-router-dom';
import { Row, Col, Tooltip } from 'reactstrap';

import Header from '../../common/header/Header';
import SimpleTable from '../../common/components/SimpleTable';
import SimpleModal from '../../common/components/SimpleModal';
import moment from 'moment';

// SVG
import add from '../../assets/svgs/add.svg';
import edit from '../../assets/svgs/edit.svg';
import deleteSVG from '../../assets/svgs/delete.svg';
import duplicate from '../../assets/svgs/icon-duplicate.svg';

let IndexYachtListPresenter = props => {
    return (
        <div className="content-inner-wrapper">
            <Header menu={props.menu} history={props.history} />

            <div className="content-inner">
                <Row>
                    <Col xs="12" xl="9">
                        <div className="table-actions d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <button
                                    type="button"
                                    className="btn btn-main btn-action d-flex align-items-center justify-content-center"
                                    onClick={() => props.history.push('/yachts/view/sales')}
                                >
                                    <ReactSVG
                                        path={add}
                                        style={{ height: 12 }}
                                    />
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-empty action-label"
                                    onClick={() => props.history.push('/yachts/view/sales')}
                                >
                                    Create Sales Yacht List
                                </button>
                            </div>

                            <div className="d-flex align-items-center">
                                <button
                                    type="button"
                                    className="btn btn-main btn-action d-flex align-items-center justify-content-center"
                                    onClick={() => props.history.push('/yachts/view/charter')}
                                >
                                    <ReactSVG
                                        path={add}
                                        style={{ height: 12 }}
                                    />
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-empty action-label"
                                    onClick={() => props.history.push('/yachts/view/charter')}
                                >
                                    Create Charter Yacht List
                                </button>
                            </div>
                        </div>
                        <SimpleTable
                            search={true}
                            tableChange={props.onTableChange}
                            paging={props.yacht_lists.pagination}
                            colWidths={[4, 2, '1 text-center', '3 text-center', '2 text-center']}
                            headers={props.table_headers}
                            hideExtraFilter={true}
                            rowData={
                                props.yacht_lists.data !== undefined &&
                                props.yacht_lists.data.map((value, index) => {
                                    return [
                                        <div
                                            ref={div =>
                                                props.title_refs.push(div)
                                            }
                                        >
                                            {value.internal_title}
                                        </div>,
                                        <div style={{ display: 'flex', alignItems: 'center'}}>
                                            {value.chartering === 0 ? (
                                                <div className="customer-cat customer-sales mr-1">
                                                    S
                                                </div>
                                            ) : (
                                                <div className="customer-cat customer-chartering mr-1">
                                                    C
                                                </div>
                                            )}
                                            {''}
                                            {value.type === 'dynamic' ? 'Filters' : 'Selection'}
                                        </div>,
                                        <div>
                                            { value.type === 'dynamic' ? '-' : value.yachts.length}
                                        </div>,
                                        <div>
                                            {value.created_at && moment(value.created_at).format('YYYY/MM/DD')}
                                            <span style={{color: '#41506e'}}>{' | '}</span>
                                            {value.updated_at && moment(value.updated_at).format('YYYY/MM/DD')}
                                        </div>,
                                        <ul className="list-unstyled table-td-actions">
                                            <li className="text-fill-hover">
                                                <NavLink
                                                    to={
                                                        value.type === 'static'
                                                            ? `/yacht-lists/${
                                                                  value.id
                                                              }/edit`
                                                            : `/yachts/view/${
                                                                  value.chartering ===
                                                                  1
                                                                      ? 'charter'
                                                                      : 'sales'
                                                              }?yacht-list-id=${
                                                                  value.id
                                                              }`
                                                    }
                                                >
                                                    <ReactSVG
                                                        path={edit}
                                                        style={{
                                                            height: 24
                                                        }}
                                                    />
                                                </NavLink>
                                            </li>
                                            <li>
                                                <button
                                                    className="btn btn-td-action"
                                                    onClick={() =>
                                                        props.onDeleteModalToggle(
                                                            value.id
                                                        )
                                                    }
                                                >
                                                    <ReactSVG
                                                        path={deleteSVG}
                                                        style={{
                                                            height: 24
                                                        }}
                                                    />
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    id={`btncp_${index}`}
                                                    className="btn btn-td-action"
                                                    onClick={() =>
                                                        props.onCopyToClipboard(
                                                            index
                                                        )
                                                    }
                                                >
                                                    <ReactSVG
                                                        path={duplicate}
                                                        style={{
                                                            height: 24
                                                        }}
                                                    />
                                                </button>

                                                <Tooltip
                                                    placement={window.innerWidth > 1200 ? 'top' : 'left'}
                                                    isOpen={
                                                        props.tooltips_state[index]
                                                    }
                                                    target={`btncp_${index}`}
                                                    autohide={true}
                                                >
                                                    List title copied to clipboard!
                                                </Tooltip>
                                            </li>
                                        </ul>
                                    ];
                                })
                            }
                        />
                    </Col>

                    <Col xs="12">
                        <SimpleModal
                            header={props.modal_header}
                            body={props.modal_body}
                            modalOpen={props.is_modal_open}
                            toggle={props.onToggleModal}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default IndexYachtListPresenter;
