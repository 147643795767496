import LocationHierarchy from '../../../common/components/locations/hierarchy';

import {
    INIT_LOCATION,
    FETCH_LOCATIONS_SUCCESS,
    FETCH_LOCATION_SUCCESS,
    FETCH_LOCATIONS_HIERARCHY_SUCCESS,
    FETCH_DROPDOWN_LOCATIONS_SUCCESS,
    RESET_DROPDOWN_LOCATIONS
} from './_types';

const LOCATIONS = {
    data: [],
    pagination: {
        page: 1,
        page_count: 1,
        page_size: 15,
        total: 1,
        search: ''
    }
};

const LOCATION_TYPE = {
    info: {}
};

const INITIAL_STATE = {
    locationsTree: LocationHierarchy.load([]),
    dropdown_locations: [],
    locations: LOCATIONS,
    location: LOCATION_TYPE,
    should_dropdown_locations_reset: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INIT_LOCATION:
            return {
                ...state,
                location: LOCATION_TYPE
            };
        case FETCH_LOCATIONS_SUCCESS:
            return {
                ...state,
                locations: {
                    data: action.payload.locations,
                    pagination: action.payload.pagination
                }
            };
        case FETCH_DROPDOWN_LOCATIONS_SUCCESS:
            return {
                ...state,
                dropdown_locations: action.payload
            };
        case FETCH_LOCATION_SUCCESS:
            return {
                ...state,
                location: {
                    ...action.payload,
                    parents: [action.payload.parents]
                }
            };
        case FETCH_LOCATIONS_HIERARCHY_SUCCESS:
            return {
                ...state,
                locationsTree: action.payload
            };
        case RESET_DROPDOWN_LOCATIONS:
            return {
                ...state,
                should_dropdown_locations_reset: action.payload
            };
        default:
            return state;
    }
};

export default reducer;
