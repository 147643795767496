import axios from 'axios';
import { API_URL } from 'helpers/variables';
import { authConfig } from 'helpers/authConfig';

const url = `${API_URL}/testimonials`;

/**
 * Returns a list of testimonials
 * @returns {AxiosPromise}
 */
export function getYachtTestimonials(yachtId) {
    return axios({
        url: `${url}/${yachtId}`,
        method: 'GET',
        headers: authConfig()
    });
}

export function getTestimonial(yachtId, testimonialId) {
    return axios({
        url: `${url}/${yachtId}/${testimonialId}`,

        method: 'GET',
        headers: authConfig()
    });
}

/**
 * Adds a new testimonial
 * @param resource
 * @returns {AxiosPromise}
 */
export function addTestimonial(resource) {
    return axios({
        url: `${url}`,
        method: 'POST',
        data: { ...resource },
        headers: authConfig()
    });
}

/**
 * Edits an existing testimonial
 * @param resource
 * @returns {AxiosPromise}
 */
export function editTestimonial(resource) {
    return axios({
        url: `${url}/${resource.id}`,
        method: 'PUT',
        data: { ...resource },
        headers: authConfig()
    });
}

/**
 * Deletes a testimonial
 * @param id
 * @returns {AxiosPromise}
 */
export function deleteTestimonial(id) {
    return axios({
        url: `${url}/${id}`,
        method: 'DELETE',
        headers: authConfig()
    });
}
