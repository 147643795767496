import {
    FETCH_SALES_LISTINGS,
    FETCH_SALES_LISTINGS_SUCCESS,
    FETCH_SALES_LISTINGS_FAIL
} from './_types';

const INITIAL_STATE = {
    pagination: { page: 1, total: 0, page_size: 1, page_count: 1 },
    sorting: { order_field: 'title_internal', order_dir: 'ASC', favourites: 0 },
    salesListings: [],
    initDestination: {}
};

export default function salesListingsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_SALES_LISTINGS:
            return state;
        case FETCH_SALES_LISTINGS_SUCCESS:
            return {
                ...state,
                pagination: {
                    page:
                        action.payload.pagination.total === 0
                            ? 0
                            : action.payload.pagination.page >
                              action.payload.pagination.page_count
                            ? 1
                            : action.payload.pagination.page,
                    total: action.payload.pagination.total,
                    page_size: action.payload.pagination.page_size,
                    page_count: action.payload.pagination.page_count
                },
                salesListings: action.payload.salesListings,
                sorting: action.payload.sorting
            };
        case FETCH_SALES_LISTINGS_FAIL:
            return { ...state, error: action.payload };
        default:
            return state;
    }
}
