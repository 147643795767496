import React, { Component } from 'react';
import { Field } from 'redux-form';
import RenderField from '../../../common/components/RenderField';

class RenderCompany extends Component {
    render() {
        const {
            active,
            suggestions,
            change,
            select,
            charterSearch
        } = this.props;

        const index = 1;

        return (
            <ul className="list-unstyled companies-list">
                <li key={index}>
                    <Field
                        name={
                            charterSearch
                                ? 'has_charterCompanies[0].name'
                                : 'has_companies[0].name'
                        }
                        type="text"
                        placeholder="COMPANY"
                        component={RenderField}
                        onChange={(e, n, p) => change(index, e, n, p)}
                        autoComplete={false}
                    />

                    {active === index &&
                    suggestions &&
                    suggestions.length > 0 ? (
                        <ul className="list-unstyled results-list">
                            {suggestions.map((sug, i) => {
                                return (
                                    <li key={i}>
                                        <a
                                            href="/#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                select(sug, index);
                                            }}
                                        >
                                            {sug ? sug.company : ''}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : null}
                </li>
            </ul>
        );
    }
}

export default RenderCompany;
