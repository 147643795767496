import React from 'react';
import { connect } from 'react-redux';

import ManageOfferDecorator from './ManageOfferDecorator';
import ManageOfferPresenter from './ManageOfferPresenter';
import EmailConfirmation from './components/EmailConfirmation';

//  Redux
import { fetchCustomer } from '../../../customers/customers/_actions';
import { fetchCustomerOffer, fetchSendOffer } from './redux/_actions';

class ViewOfferContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            modal_header: 'Note',
            self: false,
            is_modal_open: false,
            modal_email: (
                <EmailConfirmation
                    toggle={this.onModalToggle.bind(this)}
                    action={this.sendToCustomer.bind(this)}
                    body=""
                    self={this.getSelfVariable}
                />
            )
        };
    }

    componentDidMount() {
        const customer_id = this.props.match.params.id;
        const offer_id = this.props.match.params.offer_id;

        this.props.fetchCustomer({ id: customer_id });
        this.props
            .fetchCustomerOffer({ id: offer_id })
            .then((response) => this.setState({ loading: false }));
    }

    sendToCustomer(e) {
        this.setState({ loading: true });
        this.onModalToggle();
        if (this.state.self) {
            this.props
                .fetchSendOffer({
                    id: this.props.match.params.offer_id,
                    self: this.state.self
                })
                .then((data) => {
                    this.setState({ loading: false });

                    if (data) {
                        this.props.history.push(
                            '/clients/proposals/' + this.props.match.params.id
                        );
                    }
                });
        } else {
            this.props
                .fetchSendOffer({ id: this.props.match.params.offer_id })
                .then((data) => {
                    this.setState({ loading: false });

                    if (data) {
                        this.props.history.push(
                            '/clients/proposals/' + this.props.match.params.id
                        );
                    }
                });
        }
    }

    getSelfVariable = () => {
        return this.state.self;
    };

    onModalToggle(sendToSelf) {
        if (sendToSelf === true) {
            this.setState({ self: true });
        } else {
            this.setState({ self: false });
        }
        this.setState({
            is_modal_open: !this.state.is_modal_open
        });
    }

    render() {
        return (
            <ManageOfferPresenter
                loading={this.state.loading}
                history={this.props.history}
                match={this.props.match}
                user={this.props.user}
                user_role={this.props.user_role}
                type={this.props.type}
                customer={this.props.initCustomer}
                read_only={true}
                onSubmit={() => {}}
                is_modal_open={this.state.is_modal_open}
                modal_header={this.state.modal_header}
                modal_email={this.state.modal_email}
                onModalToggle={this.onModalToggle.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        user_role: state.auth.user_role,
        initOffer: state.customers.offers.initOffer,
        initCustomer: state.customers.customers.initCustomer,
        dropdown_destinations: state.destinations.dropdown_destinations
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCustomer: (params) => dispatch(fetchCustomer(params)),
        fetchCustomerOffer: (params) => dispatch(fetchCustomerOffer(params)),
        fetchSendOffer: (params) => dispatch(fetchSendOffer(params))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageOfferDecorator()(ViewOfferContainer));
