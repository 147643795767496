import React, { Component } from 'react';
import { Field } from 'redux-form';

import ReactSVG from 'react-svg';

import RenderField from '../../common/components/RenderField';
import add from '../../assets/svgs/add.svg';
import remove from '../../assets/svgs/remove.svg';

const renderHidden = (field) => <input type="hidden" {...field.input} />;

class RenderBrokers extends Component {
    render() {
        const {
            fields,
            active,
            suggestions,
            change,
            select,
            single
        } = this.props;

        return (
            <ul className="list-unstyled brokers-list">
                {single === undefined && (
                    <li className="mb-1">
                        <button
                            className="btn btn-blue"
                            type="button"
                            onClick={() => fields.push()}
                        >
                            <ReactSVG path={add} style={{ width: 12 }} />
                            {this.props.type === 'sales'
                                ? 'Add broker'
                                : 'Add charter manager'}
                        </button>
                    </li>
                )}
                {fields.map((broker, index) => (
                    <li key={index}>
                        <Field
                            name={`${broker}.name`}
                            type="text"
                            placeholder={
                                this.props.type === 'sales'
                                    ? 'BROKER'
                                    : 'CHARTER MANAGER'
                            }
                            component={RenderField}
                            onChange={(e, n, p) => change(index, e, n, p)}
                            autoComplete={false}
                        />
                        <Field name={`${broker}.id`} component={renderHidden} />

                        <button
                            className="btn btn-empty btn-remove"
                            type="button"
                            onClick={() => fields.remove(index)}
                        >
                            <ReactSVG path={remove} style={{ width: 16 }} />
                        </button>

                        {active === index &&
                        suggestions &&
                        suggestions.length > 0 ? (
                            <ul className="list-unstyled results-list">
                                {suggestions.map((sug, i) => {
                                    return (
                                        <li key={sug.id}>
                                            <a
                                                href="/#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    select(sug, index);
                                                }}
                                            >
                                                {(sug?.company || '') +
                                                    (sug?.company && sug?.name
                                                        ? '-'
                                                        : '') +
                                                    (sug?.name || '')}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : null}
                    </li>
                ))}
            </ul>
        );
    }
}

export default RenderBrokers;
