import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';

import {
    fetchYacht,
    fetchManageYachts,
    fetchGetYachtType,
    fetchDeleteYacht,
    initYacht,
    changeYachtSelection,
    changeYachtSelectionSales,
    changeYachtSelectionCharter
} from './_actions';
import {
    fetchSelectYachts,
    fetchDeleteSelectedYachts,
    updateYachtStatus
} from '../search/_actions';

import YachtMenu from '../../pages/yachts/components/YachtMenu';
import SimpleModal from '../../common/components/SimpleModal';
import DeleteConfirmation from '../../common/components/DeleteConfirmation';
import RenderCheckbox from '../../common/components/RenderCheckbox';

import Specs from './sections/Specs';

const form = reduxForm({
    form: 'manage_yacht_status',
    enableReinitialize: true
});

class ViewYacht extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            view: 'view',
            modalHeader: 'Delete yacht',
            modalOpen: false
        };

        this.toggleFlag = this.toggleFlag.bind(this);
        this.handleYachtStatusChange = this.handleYachtStatusChange.bind(this);
    }

    toggleFlag(field, value) {
        let params = { id: this.props.initYacht.id, [field]: value };

        this.props.fetchManageYachts(params);
    }

    favouriteYacht(value) {
        let params = { id: value.id, favourite: value.favourite === 1 ? 0 : 1 };

        this.props.fetchManageYachts(params).then(data => {
            if (data) {
                this.props.fetchYacht({ id: this.props.match.params.id });
            }
        });
    }

    deleteYacht() {
        this.props
            .fetchDeleteYacht({ id: this.props.initYacht.id })
            .then(response => {
                if (response) {
                    this.props.history.push(
                        `/yachts/view/${this.props.match.params.viewType}`
                    );
                }
            });
    }

    deleteModal() {
        this.setState({
            modalDelete: (
                <DeleteConfirmation
                    toggle={this.toggleModal.bind(this)}
                    action={this.deleteYacht.bind(this)}
                    body={
                        "You are about to delete yacht '" +
                        this.props.initYacht.name +
                        "'"
                    }
                />
            )
        });
        this.toggleModal();
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    onToggle(e, form) {
        if (
            this.props.initYacht.id &&
            (!this.props.initYachtTypes[form.type] ||
                (this.props.initYachtTypes[form.type] &&
                    this.props.initYachtTypes[form.type].yacht_id !==
                        this.props.initYacht.id))
        ) {
            let params = {};
            params.id = this.props.initYacht.id;
            params.type = form.type;

            if (form.type !== 'generic') this.props.fetchGetYachtType(params);
        }
    }

    handleYachtStatusChange(yacht, params) {
        this.props
            .updateYachtStatus({ yacht, params })
            .then(() =>
                this.props.fetchYacht({ id: this.props.match.params.id })
            );
    }

    fetchSelectedYachts() {
        this.props.fetchSelectedYachts();
    }

    selectYacht(event, yacht, type) {
        let par = {};

        if (yacht && yacht.id) {
            par.yacht_id = yacht.id;
        }

        if (type === 'sales') {
            this.props.changeYachtSelectionSales();
        }

        if (type === 'charter') {
            this.props.changeYachtSelectionCharter();
        }

        this.props.changeYachtSelection();
        if (yacht.id && type === 'sales' && !yacht.selected_on_sales) {
            this.props.fetchSelectYachts(par, type);
        } else if (
            yacht.id &&
            type === 'charter' &&
            !yacht.selected_on_charter
        ) {
            this.props.fetchSelectYachts(par, type);
        } else {
            this.props.fetchDeleteSelectedYachts(par, type);
        }
    }

    componentDidMount() {
        this.props.initYachtFunc();

        if (this.props.match.params && this.props.match.params.viewType) {
            this.setState({ viewType: this.props.match.params.viewType });
        }

        if (this.props.match.params && this.props.match.params.id) {
            this.props
                .fetchYacht({ id: this.props.match.params.id })
                .then(data => {
                    if (data) {
                        console.log('YACHT DATA', data);
                        this.setState({
                            loaded: true
                        });
                    }
                });
        }
    }

    render() {
        const { user_role } = this.props;

        return (
            <div className="content-inner-wrapper">
                <YachtMenu
                    user_role={this.props.user_role}
                    match={this.props.match}
                    history={this.props.history}
                    onDeleteYacht={this.deleteModal.bind(this)}
                />

                <div className="content-inner">
                    {user_role !== 'broker' &&
                        user_role !== 'broker_sales' &&
                        user_role !== 'broker_charter' &&
                        this.state.loaded === true && (
                            <div className="row">
                                <div className="col-xl-5 col-8 offset-4">
                                    <form className="list-form">
                                        <div className="row align-items-center justify-content-end mb-1">
                                            <div className="col-xl-3 col-4 d-flex justify-content-end">
                                                <Field
                                                    name="private_deed"
                                                    label="Private deal"
                                                    group="private_deed"
                                                    type="checkbox"
                                                    className="mb-0 mt-0 align-select"
                                                    onChange={(e, value) =>
                                                        this.toggleFlag(
                                                            'private_deed',
                                                            !this.props
                                                                .initialValues
                                                                .private_deed
                                                                ? 1
                                                                : 0
                                                        )
                                                    }
                                                    component={RenderCheckbox}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                    {this.state.loaded === true && (
                        <Specs
                            onToggle={this.onToggle.bind(this)}
                            user_role={this.props.user_role}
                            initYacht={this.props.initYacht}
                            history={this.props.history}
                            favouriteYacht={this.favouriteYacht.bind(this)}
                            view={this.state.view}
                            selectYacht={this.selectYacht.bind(this)}
                            selectedYachts={this.state.selectedYachts}
                            onYachtStatusChange={this.handleYachtStatusChange}
                            viewType={this.state.viewType}
                        />
                    )}
                </div>

                <SimpleModal
                    header={this.state.modalHeader}
                    body={this.state.modalDelete}
                    modalOpen={this.state.modalOpen}
                    toggle={this.toggleModal.bind(this)}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        updateYachtStatus: e => dispatch(updateYachtStatus(e)),
        fetchYacht: e => dispatch(fetchYacht(e)),
        initYachtFunc: e => dispatch(initYacht(e)),
        fetchManageYachts: e => dispatch(fetchManageYachts(e)),
        fetchGetYachtType: e => dispatch(fetchGetYachtType(e)),
        fetchDeleteYacht: e => dispatch(fetchDeleteYacht(e)),
        fetchSelectYachts: (e, type) => dispatch(fetchSelectYachts(e, type)),
        fetchDeleteSelectedYachts: (e, type) =>
            dispatch(fetchDeleteSelectedYachts(e, type)),
        changeYachtSelection: e => dispatch(changeYachtSelection(e)),
        changeYachtSelectionSales: e => dispatch(changeYachtSelectionSales(e)),
        changeYachtSelectionCharter: e =>
            dispatch(changeYachtSelectionCharter(e)),
        changeFieldValue: (field, value) =>
            dispatch(change('manage_yacht_status', field, value))
    };
}

function mapStateToProps(state, ownProps) {
    return {
        user_role: state.auth.user_role,
        initYacht: state.yachts.yacht.initYacht,
        initYachtTypes: state.yachts.yacht.initYachtTypes,
        initialValues: {
            status: state.yachts.yacht.initYacht.status,
            private_deed: state.yachts.yacht.initYacht.private_deed
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(form(ViewYacht));
