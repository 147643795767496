import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Field,
    change,
    formValueSelector,
    FieldArray,
    FormSection
} from 'redux-form';
import Notifications from 'react-notification-system-redux';
import RenderField from '../../common/components/RenderField';
import RenderCheckbox from '../../common/components/RenderCheckbox';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import RenderTags from '../../common/components/RenderTags';
import ToggleCollapse from '../../common/components/ToggleCollapse';
import ExtraFilters from './ExtraFilters';
import { normalizePrice } from '../../helpers/validate';
import { dropdowns } from '../../helpers/variables';
import builders from '../../infrastructure/data/builders';

let includesCheckboxes = {
    sales: [
        { group: 'elevator', label: 'Elevator', sales: 1, charters: 1 },
        { group: 'helipad', label: 'Helipad', sales: 1, charters: 1 },
        { group: 'gym', label: 'Gym', sales: 1, charters: 1 },
        { group: 'jacuzzi', label: 'Jacuzzi', sales: 1, charters: 1 },
        {
            group: 'handicap',
            label: 'Wheelchair access',
            sales: 1,
            charters: 1
        },
        { group: 'beach_club', label: 'Beach Club', sales: 1, charters: 1 },
        { group: 'spa', label: 'Spa', sales: 1, charters: 1 },
        { group: 'slide', label: 'Slide', sales: 1, charters: 1 },
        {
            group: 'night_vision_camera',
            label: 'Night vision camera',
            sales: 1,
            charters: 0
        }
    ],
    charters: [
        { group: 'elevator', label: 'Elevator', sales: 1, charters: 1 },
        { group: 'helipad', label: 'Helipad', sales: 1, charters: 1 },
        { group: 'gym', label: 'Gym', sales: 1, charters: 1 },
        { group: 'jacuzzi', label: 'Jacuzzi', sales: 1, charters: 1 },
        { group: 'stabilizers', label: 'Stabilizers', sales: 0, charters: 1 },
        {
            group: 'zero_speed_stabilizers',
            label: 'Zero speed stabilizers',
            sales: 0,
            charters: 1
        },
        {
            group: 'handicap',
            label: 'Wheelchair access',
            sales: 1,
            charters: 1
        },
        { group: 'beach_club', label: 'Beach Club', sales: 1, charters: 1 },
        { group: 'spa', label: 'Spa', sales: 1, charters: 1 },
        { group: 'barbeque', label: 'Barbeque', sales: 1, charters: 1 },
        { group: 'wifi', label: 'Wifi', sales: 0, charters: 1 },
        {
            group: 'master_on_main_deck',
            label: 'Master on main deck',
            sales: 0,
            charters: 1
        }
    ]
};

const RenderCheckboxes = ({ fields, min, max, type, disabled }) => (
    <div className="checkboxes-wrapper d-flex justify-content-between">
        {includesCheckboxes[type].map((field, i) => {
            return i >= min && i <= max ? (
                <Field
                    key={i}
                    value={false}
                    name={`equal_${field.group}`}
                    group={includesCheckboxes[type][i].group}
                    label={includesCheckboxes[type][i].label}
                    component={RenderCheckbox}
                    disabled={disabled}
                />
            ) : null;
        })}
    </div>
);

const selector = formValueSelector('manage_customer');
class Filters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false
        };
    }

    render() {
        const { readOnly } = this.props;
        return (
            <div className="yachts-filters-wrapper toggled">
                <FormSection name="interest_query">
                    <div className="row mt-2">
                        <div className="col-12 mb-1">
                            <div className="filter-label font-500 mb-1">
                                INCLUDE
                            </div>
                            <FieldArray
                                name="includesCheckboxes"
                                component={RenderCheckboxes}
                                props={{
                                    min: 0,
                                    max: this.props.type === 'sales' ? 3 : 5,
                                    type: this.props.type,
                                    disabled: readOnly
                                }}
                            />

                            <FieldArray
                                name="includesCheckboxes"
                                component={RenderCheckboxes}
                                props={{
                                    min: this.props.type === 'sales' ? 4 : 6,
                                    max: this.props.type === 'sales' ? 9 : 11,
                                    type: this.props.type,
                                    disabled: readOnly
                                }}
                            />
                        </div>

                        <div className="col-5">
                            <Field
                                name="like_name"
                                type="text"
                                placeholder="BOAT NAME"
                                component={RenderField}
                                readOnly={readOnly}
                                disabled={readOnly}
                            />

                            <Field
                                name="has_types"
                                placeholder="TYPE"
                                options={this.props.types}
                                multi={true}
                                component={RenderReactSelect}
                                help={
                                    readOnly
                                        ? ''
                                        : 'You can select multiple values.'
                                }
                                readOnly={readOnly}
                            />

                            {this.props.type === 'sales' && (
                                <Field
                                    name="like_model"
                                    type="text"
                                    placeholder="MODEL"
                                    component={RenderField}
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                />
                            )}

                            {this.props.type === 'sales' && (
                                <Field
                                    name="include_hull_material"
                                    placeholder="HULL MATERIAL"
                                    options={dropdowns['hull_material']}
                                    multi={true}
                                    component={RenderReactSelect}
                                    help={
                                        readOnly
                                            ? ''
                                            : 'You can select multiple values.'
                                    }
                                    readOnly={readOnly}
                                />
                            )}
                            {this.props.type === 'sales' && (
                                <Field
                                    name="include_num_engines"
                                    placeholder="NUMBER OF ENGINES"
                                    options={dropdowns['num_engines']}
                                    multi={true}
                                    component={RenderReactSelect}
                                    help={
                                        readOnly
                                            ? ''
                                            : 'You can select multiple value.'
                                    }
                                    readOnly={readOnly}
                                />
                            )}

                            {this.props.type === 'sales' && (
                                <Field
                                    name="like_engine_manufacturer"
                                    type="text"
                                    placeholder="ENGINE MANUFACTURER"
                                    component={RenderField}
                                    readOnly={readOnly}
                                />
                            )}

                            {this.props.type === 'charters' && (
                                <Field
                                    name="equal_guests"
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    placeholder="GUESTS"
                                    component={RenderField}
                                    readOnly={readOnly}
                                />
                            )}

                            {this.props.type === 'charters' && (
                                <Field
                                    name="equal_cabins"
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    placeholder="CABINS"
                                    component={RenderField}
                                    readOnly={readOnly}
                                />
                            )}

                            {this.props.type === 'charters' && (
                                <Field
                                    name="equal_crew"
                                    parse={(value) =>
                                        value ? Number(value) : ''
                                    }
                                    type="number"
                                    placeholder="CREW"
                                    component={RenderField}
                                    readOnly={readOnly}
                                />
                            )}
                        </div>

                        <div className="col-6 offset-1 d-flex flex-direction-column">
                            {this.props.type === 'sales' && (
                                <div className="row">
                                    <div className="col-9">
                                        <Field
                                            name="equal_vessel_id"
                                            type="text"
                                            placeholder="VESSEL ID"
                                            component={RenderField}
                                            readOnly={readOnly}
                                        />
                                    </div>
                                    <div className="col-9">
                                        <Field
                                            name="includes_like_builder"
                                            placeholder="BUILDER"
                                            className="mb-2"
                                            options={builders.map(
                                                (builder) => ({
                                                    value: builder,
                                                    label: builder
                                                })
                                            )}
                                            multi={true}
                                            component={RenderReactSelect}
                                            help={
                                                readOnly
                                                    ? ''
                                                    : 'You can select multiple values.'
                                            }
                                            readOnly={readOnly}
                                        />
                                    </div>
                                </div>
                            )}

                            {this.props.type === 'charters' && (
                                <div className="row">
                                    <div className="col-9">
                                        <Field
                                            name="season"
                                            placeholder="SEASON"
                                            component={RenderReactSelect}
                                            className="mb-1"
                                            options={[
                                                {
                                                    value: 'summer',
                                                    label: 'Summer'
                                                },
                                                {
                                                    value: 'winter',
                                                    label: 'Winter'
                                                }
                                            ]}
                                            readOnly={readOnly}
                                        />
                                    </div>

                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <Field
                                                    readOnly={
                                                        !this.props.season ||
                                                        this.props.season === ''
                                                    }
                                                    name={`gte_${this.props.season}_low_season_rate`}
                                                    type="text"
                                                    placeholder="FROM LOW RATE"
                                                    normalize={normalizePrice}
                                                    className="mb-1"
                                                    component={RenderField}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <Field
                                                    readOnly={
                                                        !this.props.season ||
                                                        this.props.season === ''
                                                    }
                                                    name={`lte_${this.props.season}_low_season_rate`}
                                                    type="text"
                                                    placeholder="TO HIGH RATE"
                                                    normalize={normalizePrice}
                                                    className="mb-1"
                                                    component={RenderField}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <Field
                                                    readOnly={
                                                        !this.props.season ||
                                                        this.props.season === ''
                                                    }
                                                    name={`season_currency`}
                                                    label=""
                                                    component={
                                                        RenderReactSelect
                                                    }
                                                    className="mb-1"
                                                    clearable={false}
                                                    options={[
                                                        {
                                                            value: 'usd',
                                                            label: 'USD'
                                                        },
                                                        {
                                                            value: 'eur',
                                                            label: 'EURO'
                                                        },
                                                        {
                                                            value: 'gbp',
                                                            label: 'GBP'
                                                        },
                                                        {
                                                            value: 'aud',
                                                            label: 'AUD'
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {this.props.type === 'charters' && (
                                <div className="row">
                                    <div className="col-12">
                                        <Field
                                            name={`has_locations`}
                                            placeholder="LOCATION"
                                            options={this.props.locations.map(
                                                function (x) {
                                                    return {
                                                        value: x.id,
                                                        label: x.name
                                                    };
                                                }
                                            )}
                                            multi={true}
                                            className="mb-1"
                                            component={RenderReactSelect}
                                            readOnly={readOnly}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="row mt-auto">
                                <div className="col-9">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-2">
                                            <div className="filter-label font-500 mb-2">
                                                YEAR
                                            </div>
                                        </div>
                                        <div className="col-4 pl-0">
                                            <Field
                                                name="between_year_built[0]"
                                                parse={(value) =>
                                                    value ? Number(value) : ''
                                                }
                                                type="number"
                                                placeholder=""
                                                className="mt-0"
                                                component={RenderField}
                                                readOnly={readOnly}
                                                disabled={readOnly}
                                            />
                                        </div>
                                        <div className="col-auto pr-1">
                                            <div className="filter-label mb-2">
                                                To
                                            </div>
                                        </div>
                                        <div className="col-4 pl-0">
                                            <Field
                                                name="between_year_built[1]"
                                                parse={(value) =>
                                                    value ? Number(value) : ''
                                                }
                                                type="number"
                                                placeholder=""
                                                className="mt-0"
                                                component={RenderField}
                                                readOnly={readOnly}
                                                disabled={readOnly}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-9">
                                            <div className="row align-items-center justify-content-between">
                                                <div className="col-2">
                                                    <div className="filter-label font-500 mb-2">
                                                        LOA
                                                    </div>
                                                </div>
                                                <div className="col-4 pl-0">
                                                    <Field
                                                        name="between_loa[0]"
                                                        parse={(value) =>
                                                            value
                                                                ? Number(value)
                                                                : ''
                                                        }
                                                        type="number"
                                                        placeholder=""
                                                        className="mt-0"
                                                        component={RenderField}
                                                        readOnly={readOnly}
                                                        disabled={readOnly}
                                                    />
                                                </div>
                                                <div className="col-auto pr-1">
                                                    <div className="filter-label mb-2">
                                                        To
                                                    </div>
                                                </div>
                                                <div className="col-4 pl-0">
                                                    <Field
                                                        name="between_loa[1]"
                                                        parse={(value) =>
                                                            value
                                                                ? Number(value)
                                                                : ''
                                                        }
                                                        type="number"
                                                        placeholder=""
                                                        className="mt-0"
                                                        component={RenderField}
                                                        readOnly={readOnly}
                                                        disabled={readOnly}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-3 pl-0">
                                            <Field
                                                name="between_loa[2]"
                                                label=""
                                                component={RenderReactSelect}
                                                className="mt-0"
                                                clearable={false}
                                                options={[
                                                    {
                                                        value: 'meters',
                                                        label: 'Meter'
                                                    },
                                                    {
                                                        value: 'feet',
                                                        label: 'Feet'
                                                    }
                                                ]}
                                                readOnly={readOnly}
                                            />
                                        </div>
                                    </div>

                                    {this.props.type === 'sales' && (
                                        <div className="row">
                                            <div className="col-9">
                                                <div className="row align-items-center justify-content-between">
                                                    <div className="col-2">
                                                        <div className="filter-label font-500 mb-2">
                                                            PRICE
                                                        </div>
                                                    </div>
                                                    <div className="col-4 pl-0 ml-1">
                                                        <Field
                                                            name="between_asking_price[0]"
                                                            type="text"
                                                            placeholder=""
                                                            className="mt-0"
                                                            normalize={
                                                                normalizePrice
                                                            }
                                                            component={
                                                                RenderField
                                                            }
                                                            readOnly={readOnly}
                                                        />
                                                    </div>
                                                    <div className="col-auto pr-1">
                                                        <div className="filter-label mb-2">
                                                            To
                                                        </div>
                                                    </div>
                                                    <div className="col-4 pl-0">
                                                        <Field
                                                            name="between_asking_price[1]"
                                                            type="text"
                                                            placeholder=""
                                                            className="mt-0"
                                                            normalize={
                                                                normalizePrice
                                                            }
                                                            component={
                                                                RenderField
                                                            }
                                                            readOnly={readOnly}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-3 pl-0">
                                                <Field
                                                    name="between_asking_price[2]"
                                                    component={
                                                        RenderReactSelect
                                                    }
                                                    className="mt-0"
                                                    clearable={false}
                                                    options={[
                                                        {
                                                            value: 'usd',
                                                            label: 'USD'
                                                        },
                                                        {
                                                            value: 'eur',
                                                            label: 'EURO'
                                                        },
                                                        {
                                                            value: 'gbp',
                                                            label: 'GBP'
                                                        },
                                                        {
                                                            value: 'aud',
                                                            label: 'AUD'
                                                        }
                                                    ]}
                                                    readOnly={readOnly}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mb-1">
                            <ToggleCollapse
                                initOpen={true}
                                body={
                                    <ExtraFilters
                                        states={this.props.states}
                                        countries={this.props.countries}
                                        selectedCountry={this.props.country}
                                        type={this.props.type}
                                        changeFieldValue={this.props.changeFieldValue.bind(
                                            this
                                        )}
                                        newTag={this.props.new_builder}
                                        readOnly={readOnly}
                                        leftColClassName="col-5"
                                        rightColClassName="col-6 offset-1"
                                        rightColSubClassName="col-9"
                                    />
                                }
                                header="EXTRA FIELDS"
                            />
                        </div>
                    </div>
                </FormSection>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change(ownProps.form, 'interest_query.' + field, value)),
        notification: (title, message) => {
            let notificationOpts = {
                position: 'tc',
                autoDismiss: 0,
                title: title,
                message: message
            };

            dispatch(Notifications.error(notificationOpts));
        }
    };
}

function mapStateToProps(state, ownProps) {
    let initialValues = {
        between_asking_price: ['', '', 'usd'],
        between_loa: ['', '', 'meters'],
        draft_max_unit: 'meters',
        beam_unit: 'meters',
        has_brokers: [''],
        has_companies: [''],
        exist: {},
        season: '',
        season_currency: 'usd'
    };

    return {
        yachts: state.yachts.search,
        countries: state.common.countries.map((st) => {
            return { id: st.name, name: st.name };
        }),
        states: state.common.states.map((st) => {
            return { id: st.name, name: st.name };
        }),
        types: state.common.types.map((type) => ({
            value: type.id,
            label: type.text
        })),
        locations: state.common.locations,
        brokers: state.yachts.search.brokers,
        country: selector(state, 'include_country'),
        new_builder: selector(state, 'interest_query.new_builder'),
        season: selector(state, 'season'),
        initialValues: initialValues,
        realRates: state.rates.rates.rates
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
