import axios from 'axios';
import {
    AUTH_USER,
    AUTH_ERROR,
    UNAUTH_USER,
    SWITCH_USER_ROLE,
    FETCH_RESET_PASSWORD,
    FETCH_RESET_PASSWORD_SUCCESS,
    FETCH_RESET_PASSWORD_FAIL,
    FETCH_RESET_PASSWORD_LOADING
} from './_types';

import { GET_USER } from './user/_types';

import { API_URL } from '../helpers/variables';
import { errorHandler, successHandler } from '../helpers/alerts';

export function loginUser(params) {
    return function (dispatch) {
        return axios
            .post(API_URL + '/auth/login', params)
            .then((response) => {
                dispatch({
                    type: AUTH_USER,
                    payload: {
                        token: response.data.token,
                        user_role: response.data.user.role
                    }
                });

                dispatch({
                    type: GET_USER,
                    payload: response.data.user
                });

                return response.data.user;
            })
            .catch((error) => {
                errorHandler(dispatch, error.response, AUTH_ERROR);
            });
    };
}

export function logoutUser() {
    return function (dispatch) {
        localStorage.removeItem('state');
        dispatch({ type: UNAUTH_USER });
    };
}

export function switchUserRole(role) {
    return function (dispatch) {
        dispatch({ type: SWITCH_USER_ROLE, payload: role });

        return new Promise(function (resolve, reject) {
            resolve('Success!');
        });
    };
}

export function resetPassword(params, extraOpts = {}) {
    return (dispatch) => {
        dispatch({ type: FETCH_RESET_PASSWORD });
        dispatch({ type: FETCH_RESET_PASSWORD_LOADING, payload: true });
        if (params.token) {
            return axios
                .put(API_URL + '/auth/reset', params, {})
                .then((response) => {
                    dispatch({ type: FETCH_RESET_PASSWORD_SUCCESS });
                    successHandler(dispatch, 'changePassword', extraOpts);
                    return response.data;
                })
                .catch((error) => {
                    errorHandler(
                        dispatch,
                        error.response,
                        FETCH_RESET_PASSWORD_FAIL
                    );
                })
                .finally(() => {
                    dispatch({
                        type: FETCH_RESET_PASSWORD_LOADING,
                        payload: false
                    });
                });
        } else {
            return axios
                .post(API_URL + '/auth/reset', params, {})
                .then((response) => {
                    dispatch({ type: FETCH_RESET_PASSWORD_SUCCESS });
                    successHandler(dispatch, 'resetPassword', extraOpts);
                    return response.data;
                })
                .catch((error) => {
                    errorHandler(
                        dispatch,
                        error.response,
                        FETCH_RESET_PASSWORD_FAIL
                    );
                })
                .finally(() => {
                    dispatch({
                        type: FETCH_RESET_PASSWORD_LOADING,
                        payload: false
                    });
                });
        }
    };
}
