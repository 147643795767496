import { FETCH_DRIVE_DEFAULTS_SUCCESS, FETCH_DRIVE_SUCCESS } from './_types';

const initialState = {
    driveDefaults: [],
    driveItems: []
};

export default function drive(state = initialState, action) {
    switch (action.type) {
        case FETCH_DRIVE_DEFAULTS_SUCCESS:
            return {
                ...state,
                driveDefaults: action.payload
            };
        case FETCH_DRIVE_SUCCESS:
            return {
                ...state,
                driveItems: action.payload.items,
                pagination: action.payload.pagination
            };
        default:
            return state;
    }
}
