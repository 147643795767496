import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ListedYacht from './ListedYacht';

class SalesListingYachts extends Component {
    getList() {
        const { yachts, removeYacht, updateYacht, viewType } = this.props;
        if (yachts.length === 0)
            return <h2> No Yachts added to this Sales Listing</h2>;
        return (
            <div onDrop={this.onDrop.bind(this)}>
                <div onDragOver={e => e.preventDefault()}>
                    {yachts.map((yacht, i) => {
                        return (
                            <div
                                id={yacht.id}
                                ref={'yacht' + yacht.id}
                                draggable="true"
                                onDragStart={this.onDragStart.bind(this)}
                                key={yacht.id}
                                order={yacht.order}
                                onDrop={e => {
                                    return false;
                                }}
                            >
                                <ListedYacht
                                    yacht={yacht}
                                    updateYacht={updateYacht}
                                    removeYacht={removeYacht}
                                    viewType={viewType}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    onDragStart(event) {
        event.dataTransfer.setData('text', event.target.id);
    }

    onDrop(event) {
        event.preventDefault();
        const yachtID = event.dataTransfer.getData('text');
        const nodeToMove = ReactDOM.findDOMNode(this.refs['yacht' + yachtID]);
        const parentNode = nodeToMove.parentNode;

        const source = Array.from(parentNode.children).indexOf(nodeToMove);
        const destination = Array.from(parentNode.children).indexOf(
            event.target
        );

        const moveToPos = source < destination ? destination + 1 : destination;

        event.target.parentNode.insertBefore(
            nodeToMove,
            event.target.parentNode.children[moveToPos]
        );

        event.dataTransfer.clearData();
        this.props.onOrderChanged({
            yachtID: yachtID,
            source: source,
            destination: destination
        });
    }

    render() {
        return <div className="col-12 col-lg-10">{this.getList()}</div>;
    }
}

export default SalesListingYachts;
