import React, { Component } from 'react';
import { connect } from 'react-redux';

import YachtListMenu from '../../pages/yachts/components/YachtListMenu';
import YachtMenu from '../../pages/yachts/components/YachtMenu';

import { fetchYacht, initYacht, fetchGetYachtType } from './_actions';

import Specs from './sections/Specs';

import { fetchLocationTree } from '../../pages/locations/redux/_actions';

class ManageYacht extends Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: false,
            loaded: false,
            activeView: 'specs',
            view: '',
            pathType:
                props.match.params &&
                props.match.params.id &&
                props.match.url.indexOf(
                    '/yachts/edit/' +
                        props.match.params.viewType +
                        '/' +
                        props.match.params.id +
                        '/more'
                ) !== -1
                    ? 'more'
                    : ''
        };
    }

    componentDidMount() {
        this.props.fetchLocationTree();
        if (this.props.match.params && this.props.match.params.id) {
            this.props
                .fetchYacht({ id: this.props.match.params.id })
                .then((data) => {
                    if (data) {
                        this.setState({ loaded: true, view: 'edit' });
                    }
                });
        } else {
            this.setState({ loaded: true, view: 'add' });
        }
    }

    componentWillUnmount() {
        this.props.initYachtFunc({});
    }

    cancelForm() {
        if (this.props.match.params && this.props.match.params.id) {
            this.props.history.push(
                '/yachts/view/' +
                    this.props.match.params.viewType +
                    '/' +
                    this.props.match.params.id
            );
        } else {
            this.props.history.push('/yachts/view/sales');
        }
    }

    onToggle(e, form) {
        if (
            this.props.initYacht.id &&
            (!this.props.initYachtTypes[form.type] ||
                (this.props.initYachtTypes[form.type] &&
                    this.props.initYachtTypes[form.type].yacht_id !==
                        this.props.initYacht.id))
        ) {
            let params = {};
            params.id = this.props.initYacht.id;
            params.type = form.type;

            if (form.type !== 'generic') this.props.fetchGetYachtType(params);
        }
    }

    render() {
        return (
            <div className="content-inner-wrapper">
                {this.state.loaded && this.props.initYacht.id && (
                    <YachtMenu
                        user_role={this.props.user_role}
                        match={this.props.match}
                        history={this.props.history}
                    />
                )}

                {this.state.loaded && !this.props.initYacht.id && (
                    <YachtListMenu
                        user_role={this.props.user_role}
                        match={this.props.match}
                        history={this.props.history}
                    />
                )}

                <div className="content-inner">
                    {this.state.loaded === true && (
                        <Specs
                            onToggle={this.onToggle.bind(this)}
                            cancelForm={this.cancelForm.bind(this)}
                            initYacht={this.props.initYacht}
                            history={this.props.history}
                            pathType={this.state.pathType}
                            view={this.state.view}
                            viewType={this.props.match.params.viewType}
                            locationTree={this.props.locationTree}
                        />
                    )}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetchYacht: (e) => dispatch(fetchYacht(e)),
        initYachtFunc: (e) => dispatch(initYacht(e)),
        fetchGetYachtType: (e) => dispatch(fetchGetYachtType(e)),
        fetchLocationTree: () => dispatch(fetchLocationTree())
    };
}

function mapStateToProps(state, ownProps) {
    return {
        initYacht:
            ownProps.match.params && ownProps.match.params.id
                ? state.yachts.yacht.initYacht
                : {},
        user_role: state.auth.user_role,
        initYachtTypes: state.yachts.yacht.initYachtTypes,
        locationTree: state.locations.location_tree
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageYacht);
