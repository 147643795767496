import React, { Component } from 'react';
import { Field } from 'redux-form';

const renderTag = (field) => (
    <input
        type="email"
        className="form-control"
        // style={{width: (8*Object.keys(field.input.value).length)+4}}
        size={Object.keys(field.input.value).length}
        disabled
        {...field.input}
    />
);

class RenderTagField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleKeyPress(event, v) {
        if (
            event.key === 'Tab' ||
            event.key === 'Enter' ||
            event.type === 'blur'
        ) {
            event.preventDefault();

            if (this.props.newTag !== undefined && this.props.newTag !== '') {
                if (
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                        this.props.newTag
                    )
                ) {
                    this.props.fields.push({ name: this.props.newTag.trim() });
                    this.props.changeFieldValue(this.props.newTagName, '');
                    this.setState({ error: false });
                } else this.setState({ error: true });
            } else {
                this.setState({ error: false });
            }
        }
    }

    render() {
        const { fields, readOnly, tagPrefix, validators } = this.props;

        return (
            <div className="tags-list">
                <div className="tag-label">
                    {this.props.name ? this.props.name : 'TAGS'}
                </div>
                <ul
                    className={
                        'list-unstyled' +
                        (readOnly !== undefined && readOnly === true
                            ? ' readonly'
                            : '')
                    }
                >
                    {fields.map((tag, index) => (
                        <li key={index}>
                            <Field
                                name={`${tagPrefix}[${index}].name`}
                                component={renderTag}
                                validate={
                                    validators !== undefined ? validators : null
                                }
                            />
                            {readOnly === undefined || readOnly === false ? (
                                <button
                                    className="btn btn-empty btn-remove"
                                    type="button"
                                    onClick={() => fields.remove(index)}
                                />
                            ) : null}
                        </li>
                    ))}
                    {readOnly === undefined || readOnly === false ? (
                        <li className="tag-input">
                            <Field
                                name={this.props.newTagName}
                                type="email"
                                className="form-control"
                                placeholder={'Type'}
                                autoComplete={'false'}
                                onKeyDown={this.handleKeyPress}
                                component="input"
                                onBlur={this.handleKeyPress}
                            />
                        </li>
                    ) : null}
                </ul>
                {!this.state.error ? (
                    <div className="tag-tab-info">
                        Add values separated by Tab key.
                    </div>
                ) : (
                    <div className="tag-tab-info-error">
                        The entered value is not an email.
                    </div>
                )}
            </div>
        );
    }
}

export default RenderTagField;
