import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import IndexOffersPresenter from './IndexOffersPresenter';

import { CUSTOMERS_URL } from '../../../helpers/variables';

import DeleteConfirmation from '../../../common/components/DeleteConfirmation';

import customers from '../../../assets/svgs/customers.svg';

import {
    fetchOffersHistory,
    fetchInitOffer,
    fetchDeleteOffer
} from './redux/_actions';

class IndexOffersContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                parent: customers,
                items: [],
                backLink:
                    this.props.user_role &&
                    this.props.user_role !== 'broker_charter' &&
                    this.props.user_role !== 'broker_sales'
                        ? '/clients'
                        : this.props.user_role === 'broker_charter'
                        ? '/clients/charters'
                        : '/clients/sales'
            },
            tableHeaders: [
                {
                    name: 'name',
                    label: 'Proposal Name',
                    sort: true,
                    defaultSort: 'ASC'
                },
                {
                    name: 'boats_number',
                    label: 'Boats Selected',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'sent_at',
                    label: 'Last proposal',
                    sort: true,
                    defaultSort: 'DESC'
                },
                {
                    name: 'url_exp_date',
                    label: 'URL Exp. date',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'category',
                    label: 'Category',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'client_url',
                    label: '',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'action',
                    label: 'Action',
                    sort: false,
                    defaultSort: ''
                }
            ],
            modalHeader: 'Create new contact',
            modalOpen: false,
            modalDelete: (
                <DeleteConfirmation
                    toggle={this.onModalToggle.bind(this)}
                    action={this.deleteOffer.bind(this)}
                    body=""
                />
            )
        };
    }

    componentDidMount() {
        let params = {
            page: 1,
            page_size: 25,
            order_field: 'sent_at',
            order_dir: 'DESC',
            customer_id: this.props.match.params.id
        };

        this.props.fetchOffersHistory(params);
        this.props.initOfferModal({});
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.match.params && this.props.match.params.id) {
            let menu = [
                <NavLink
                    exact
                    to={'/clients/view/' + this.props.match.params.id}
                >
                    {nextProps.initCustomer.first_name
                        ? `${nextProps.initCustomer.first_name} ${nextProps.initCustomer.last_name}`
                        : ''}
                </NavLink>,
                <NavLink
                    exact
                    to={'/clients/proposals/' + this.props.match.params.id}
                >
                    Proposal history
                </NavLink>
            ];

            this.setState({ menu: { ...this.state.menu, items: menu } });
        }
    }

    onTableChange(key, newValue) {
        let params = {
            page: key !== 'page' ? 1 : newValue,
            order_dir:
                key !== 'page' && key !== 'page_size'
                    ? newValue
                    : this.props.offers.sorting.order_dir,
            order_field:
                key !== 'page' && key !== 'page_size'
                    ? key
                    : this.props.offers.sorting.order_field,
            page_size:
                key === 'page_size'
                    ? newValue
                    : this.props.offers.pagination.page_size,
            customer_id: this.props.match.params.id
        };

        this.props.fetchOffersHistory(params);
    }

    refetchOffers(page) {
        this.props.fetchOffersHistory({
            page: page ? page : this.props.offers.pagination.page,
            order_field: this.props.offers.sorting.order_field,
            order_dir: this.props.offers.sorting.order_dir,
            page_size: this.props.offers.pagination.page_size,
            customer_id: this.props.match.params.id
        });
    }

    onModalToggle() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    onDeleteModal(offer) {
        this.props.initOfferModal(offer).then(() => {
            this.setState({
                modalDelete: (
                    <DeleteConfirmation
                        toggle={this.onModalToggle.bind(this)}
                        action={this.deleteOffer.bind(this)}
                        body={
                            'You are about to delete proposal: ' +
                            offer.name +
                            '.'
                        }
                    />
                )
            });
            this.setState({ modalHeader: 'Delete proposal' });

            this.onModalToggle();
        });
    }

    deleteOffer() {
        this.props
            .fetchDeleteOffer({ id: this.props.initOffer.id })
            .then((data) => {
                if (data) {
                    if (this.props.offers.history.length === 1)
                        this.refetchOffers(
                            this.props.offers.pagination.page - 1 > 0
                                ? this.props.offers.pagination.page - 1
                                : 1
                        );
                    else this.refetchOffers();
                }
            });
    }

    buildCustomersURL(offer) {
        if (offer.sent_at) {
            let url = `${CUSTOMERS_URL}/proposals/${offer.url}`;
            return url;
        } else {
            let url = `${CUSTOMERS_URL}/proposals/${offer.url}/?preview=1`;
            return url;
        }
    }

    render() {
        return (
            <IndexOffersPresenter
                {...this.props}
                menu={this.state.menu}
                modalHeader={this.state.modalHeader}
                modalDelete={this.state.modalDelete}
                modalOpen={this.state.modalOpen}
                tableHeaders={this.state.tableHeaders}
                customer={this.props.initCustomer}
                offers={this.props.offers}
                onTableChange={this.onTableChange.bind(this)}
                buildCustomersURL={this.buildCustomersURL.bind(this)}
                onModalToggle={this.onModalToggle.bind(this)}
                onDeleteModal={this.onDeleteModal.bind(this)}
            />
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetchOffersHistory: (e) => dispatch(fetchOffersHistory(e)),
        initOfferModal: (e) => dispatch(fetchInitOffer(e)),
        fetchDeleteOffer: (e) => dispatch(fetchDeleteOffer(e))
    };
}

function mapStateToProps(state) {
    return {
        initCustomer: state.customers.offers.initCustomer,
        user: state.user,
        offers: state.customers.offers,
        initOffer: state.customers.offers.initOffer,
        user_role: state.auth.user_role
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndexOffersContainer);
