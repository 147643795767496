import axios from 'axios';
import { API_URL } from 'helpers/variables';
import { authConfig } from 'helpers/authConfig';

const url = `${API_URL}/users`;

/**
 * Let the server know the user is still active
 * @returns {AxiosPromise}
 */
export function ping() {
    return axios({
        url: `${url}/ping`,
        method: 'POST',
        headers: authConfig()
    });
}
