import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Field,
    change,
    reduxForm,
    formValueSelector,
    FieldArray
} from 'redux-form';
import { Button } from 'reactstrap';

import $ from 'jquery';

import _filter from 'lodash/filter';
import _without from 'lodash/without';

import moment from 'moment';

import { normalizePrice, imoValidationError } from 'helpers/validate';
import { dropdowns } from 'helpers/variables';
import { parseNumberValue } from 'common/utils';
import DateFromToPicker from 'common/components/datetimepicker/DateFromToPicker';

import RenderRedactor from 'common/components/RenderRedactor';
import RenderField from 'common/components/RenderField';
import RenderReactSelect from 'common/components/RenderReactSelect';
import RenderFieldArray from 'common/components/RenderFieldArray';
import RenderCheckbox from 'common/components/RenderCheckbox';
import Notifications from 'react-notification-system-redux';

import { functions } from 'helpers/functions';

import {
    fetchManageYachtType,
    fetchGetYachtType,
    fetchManageYachts
} from '../_actions';

const form = reduxForm({
    enableReinitialize: true
});

let initialState = {};

function formatDate(params, dateFieldName) {
    if (params[dateFieldName]) {
        if (
            moment(params[dateFieldName]).format('YYYY-MM-DD') ===
            'Invalid date'
        ) {
            params[dateFieldName] = '';
        } else {
            params[dateFieldName] = moment(params[dateFieldName]).format(
                'YYYY-MM-DD'
            );
        }
    }
}

class FormTypes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            locations: []
        };
    }

    handleFormSubmit(formProps) {
        let params = Object.assign({}, formProps);
        let valid = true;
        delete params.selected; //remove unwanted field

        params.id = this.props.initYacht.id;
        params.section_type = this.props.type;

        formatDate(params, 'date_recorded');
        formatDate(params, 'date_visited');
        formatDate(params, 'listing_date');
        formatDate(params, 'expiration_date');
        formatDate(params, 'sold_date');

        let prices = _filter(this.props.fields, { type: 'price' });
        if (prices && prices.length !== 0) {
            prices.map((field) => {
                if (params[field.name]) {
                    params[field.name] = params[field.name].replace(/,/g, '');
                    params[field.name.replace('_displayed', '')] = params[
                        field.name
                    ]
                        .toString()
                        .replace(/,/g, '');
                    delete params[field.name + '_currency'];
                }
                return field;
            });
        }

        let units = _filter(this.props.fields, { unit: true });
        if (units && units.length !== 0) {
            units.map((field) => {
                if (params[field.name]) {
                    params[field.name] = functions.toMeters(
                        params[field.name],
                        params[field.name + '_unit']
                    );
                }
                return field;
            });
        }

        let volume_unit = _filter(this.props.fields, { volume_unit: true });
        if (volume_unit && volume_unit.length !== 0) {
            volume_unit.map((field) => {
                if (params[field.name]) {
                    params[field.name] = functions.toLiters(
                        params[field.name],
                        params[field.name + '_unit']
                    );
                }
                return field;
            });
        }

        if (
            $('#' + this.props.type + '_info').length > 0 &&
            params[this.props.type + '_info'] !== undefined
        ) {
            params.info = document.getElementById(
                this.props.type + '_info'
            ).value;
        }
        delete params[this.props.type + '_info'];

        if (
            $('#' + this.props.type + '_notes').length > 0 &&
            params[this.props.type + '_notes'] !== undefined
        ) {
            params.notes = document.getElementById(
                this.props.type + '_notes'
            ).value;
        }
        delete params[this.props.type + '_notes'];

        params.hasChanges = this.props.dirty;

        if (
            $('#short_description').length > 0 &&
            params['short_description'] !== undefined
        ) {
            params.short_description = document.getElementById(
                'short_description'
            ).value;
        }
        if (params.status === null) {
            valid = false;
            this.props.notification(
                'Warning',
                <>
                    <p>Status must have a value.</p>
                </>
            );
        }
        if (valid === true) {
            if (params.locations) {
                let initValuesLocIdsArray;
                if (
                    this.props.initialValues.locations !== undefined &&
                    this.props.initialValues.locations.length > 0
                ) {
                    let initValuesLocIds = this.props.initialValues.locations.map(
                        (l) => {
                            return l && l.id ? l.id : '';
                        }
                    );

                    if (this.state.locations.length > 0) {
                        initValuesLocIdsArray = this.state.locations;
                    } else {
                        initValuesLocIdsArray = _without(initValuesLocIds, '');
                    }
                }

                this.setState(
                    {
                        locations: params.locations
                    },
                    function () {
                        if (initValuesLocIdsArray !== undefined) {
                            this.state.locations.map((value, key) => {
                                if (
                                    initValuesLocIdsArray[key] !== undefined &&
                                    parseInt(initValuesLocIdsArray[key]) !==
                                        parseInt(value)
                                ) {
                                    params.locations_changed = 1;
                                }
                                return value;
                            });

                            if (
                                initValuesLocIdsArray.length !==
                                this.state.locations.length
                            ) {
                                params.locations_changed = 1;
                            }
                        }

                        if (params.section_type === 'generic') {
                            delete params.section_type;
                            return this.props.fetchManageYachts(params);
                        } else {
                            return this.props.fetchManageYachtType(params);
                        }
                    }
                );
            } else {
                if (params.section_type === 'generic') {
                    delete params.section_type;
                    return this.props.fetchManageYachts(params);
                } else {
                    return this.props.fetchManageYachtType(params);
                }
            }
        }
    }

    dateChange(date, field) {
        this.props.changeFieldValue(field.name, date.fromDate);
    }

    render() {
        const { handleSubmit, submitting } = this.props;

        let mainClass = this.props.view === 'view' ? 'col-3' : 'col-5';
        let secClass = this.props.view === 'view' ? 'col-3' : 'col-5 offset-1';

        return (
            <form
                className="list-form"
                onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
            >
                <div className="row">
                    <div
                        className={
                            this.props.view === 'view' ? 'col-12' : 'col-8'
                        }
                    >
                        <div className="row">
                            {this.props.viewFields.map((field, i) => {
                                let elem = '';
                                let isFieldImo =
                                    field.type === 'text' &&
                                    field.name === 'imo';
                                if (field.type === 'date') {
                                    elem = (
                                        <div
                                            key={i}
                                            className={
                                                i % 2 === 0
                                                    ? mainClass
                                                    : secClass
                                            }
                                        >
                                            <DateFromToPicker
                                                labels={{
                                                    from: field.placeholder
                                                }}
                                                afterTodayValid={true}
                                                beforeTodayValid={true}
                                                initFromDate={
                                                    this.props[field.name]
                                                }
                                                onChangeFunction={(date) =>
                                                    this.dateChange(date, field)
                                                }
                                                single={true}
                                                disabled={
                                                    this.props.view === 'view'
                                                }
                                            />

                                            <Field
                                                name={field.name}
                                                type="hidden"
                                                component="input"
                                            />
                                        </div>
                                    );
                                } else if (field.type === 'textarea') {
                                    elem = (
                                        <div
                                            key={i}
                                            className={
                                                'col-11 mb-2 ' +
                                                (field.className
                                                    ? field.className
                                                    : '')
                                            }
                                        >
                                            <RenderRedactor
                                                label={field.placeholder}
                                                name={field.name}
                                                readOnly={
                                                    this.props.view === 'view'
                                                }
                                                disabled={
                                                    this.props.view === 'view'
                                                }
                                                onChange={(text) => {
                                                    this.props.changeFieldValue(
                                                        field.name,
                                                        text
                                                    );
                                                }}
                                                id={field.name}
                                            />
                                        </div>
                                    );
                                } else if (field.type === 'price') {
                                    elem = (
                                        <div
                                            key={i}
                                            className={
                                                i % 2 === 0
                                                    ? mainClass
                                                    : secClass
                                            }
                                        >
                                            <div className="row">
                                                <div
                                                    className={
                                                        this.props.view ===
                                                        'view'
                                                            ? 'col-12'
                                                            : 'col-8'
                                                    }
                                                >
                                                    <Field
                                                        key={i}
                                                        name={field.name}
                                                        type="text"
                                                        placeholder={
                                                            field.placeholder
                                                        }
                                                        normalize={
                                                            normalizePrice
                                                        }
                                                        readOnly={
                                                            this.props.view ===
                                                            'view'
                                                        }
                                                        disabled={
                                                            this.props.view ===
                                                            'view'
                                                        }
                                                        component={RenderField}
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        this.props.view ===
                                                        'view'
                                                            ? 'hidden'
                                                            : 'col-4 pl-0'
                                                    }
                                                >
                                                    <Field
                                                        name={`${field.name.replace(
                                                            '_displayed',
                                                            ''
                                                        )}_currency`}
                                                        placeholder="CURRENCY"
                                                        component={
                                                            RenderReactSelect
                                                        }
                                                        clearable={false}
                                                        options={[
                                                            {
                                                                value: 'usd',
                                                                label: 'USD'
                                                            },
                                                            {
                                                                value: 'eur',
                                                                label: 'EURO'
                                                            },
                                                            {
                                                                value: 'gbp',
                                                                label: 'GBP'
                                                            },
                                                            {
                                                                value: 'aud',
                                                                label: 'AUD'
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else if (field.type === 'array') {
                                    elem = (
                                        <div key={i} className={'col-11 mb-2'}>
                                            <FieldArray
                                                name={field.name}
                                                placeholder={field.placeholder}
                                                component={RenderFieldArray}
                                                moreLabel={field.moreLabel}
                                                className={field.className}
                                                readOnly={
                                                    this.props.view === 'view'
                                                }
                                                props={
                                                    field.name === 'locations'
                                                        ? {
                                                              customFields: [
                                                                  {
                                                                      name:
                                                                          'id',
                                                                      label:
                                                                          'LOCATION',
                                                                      type:
                                                                          'select',
                                                                      className:
                                                                          'col-12',
                                                                      options: this
                                                                          .props
                                                                          .locations,
                                                                      placeholder:
                                                                          'Select',
                                                                      multipleDropdown: field.multipleDropdown
                                                                          ? field.multipleDropdown
                                                                          : false
                                                                  }
                                                              ]
                                                          }
                                                        : {}
                                                }
                                            />
                                        </div>
                                    );
                                } else if (
                                    field.type === 'number' &&
                                    field.unit === true
                                ) {
                                    elem = (
                                        <div
                                            key={i}
                                            className={
                                                i % 2 === 0
                                                    ? mainClass
                                                    : secClass
                                            }
                                        >
                                            <div className="row">
                                                <div
                                                    className={
                                                        this.props.view ===
                                                        'view'
                                                            ? 'col-12'
                                                            : 'col-8'
                                                    }
                                                >
                                                    <Field
                                                        name={field.name}
                                                        type={
                                                            this.props.view ===
                                                            'view'
                                                                ? 'text'
                                                                : 'number'
                                                        }
                                                        placeholder={
                                                            field.placeholder
                                                        }
                                                        readOnly={
                                                            this.props.view ===
                                                            'view'
                                                        }
                                                        disabled={
                                                            this.props.view ===
                                                            'view'
                                                        }
                                                        parse={
                                                            field.type ===
                                                            'number'
                                                                ? (value) =>
                                                                      value
                                                                          ? Number(
                                                                                value
                                                                            )
                                                                          : null
                                                                : null
                                                        }
                                                        component={RenderField}
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        this.props.view ===
                                                        'view'
                                                            ? 'hidden'
                                                            : 'col-4 pl-0'
                                                    }
                                                >
                                                    <Field
                                                        name={`${field.name}_unit`}
                                                        placeholder="UNIT"
                                                        component={
                                                            RenderReactSelect
                                                        }
                                                        clearable={false}
                                                        options={
                                                            dropdowns[
                                                                'length_unit'
                                                            ]
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else if (
                                    field.type === 'number' &&
                                    field.volume_unit === true
                                ) {
                                    elem = (
                                        <div
                                            key={i}
                                            className={
                                                i % 2 === 0
                                                    ? mainClass
                                                    : secClass
                                            }
                                        >
                                            <div className="row">
                                                <div
                                                    className={
                                                        this.props.view ===
                                                        'view'
                                                            ? 'col-12'
                                                            : 'col-8'
                                                    }
                                                >
                                                    <Field
                                                        name={field.name}
                                                        type={
                                                            this.props.view ===
                                                            'view'
                                                                ? 'text'
                                                                : 'number'
                                                        }
                                                        placeholder={
                                                            field.placeholder
                                                        }
                                                        readOnly={
                                                            this.props.view ===
                                                            'view'
                                                        }
                                                        disabled={
                                                            this.props.view ===
                                                            'view'
                                                        }
                                                        parse={
                                                            field.type ===
                                                            'number'
                                                                ? (value) =>
                                                                      value
                                                                          ? Number(
                                                                                value
                                                                            )
                                                                          : null
                                                                : null
                                                        }
                                                        component={RenderField}
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        this.props.view ===
                                                        'view'
                                                            ? 'hidden'
                                                            : 'col-4 pl-0'
                                                    }
                                                >
                                                    <Field
                                                        name={`${field.name}_unit`}
                                                        placeholder="UNIT"
                                                        component={
                                                            RenderReactSelect
                                                        }
                                                        options={
                                                            dropdowns[
                                                                'volume_unit'
                                                            ]
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else if (field.type === 'checkbox') {
                                    elem = (
                                        <>
                                            {i === 14 && (
                                                <>
                                                    <div className="col-8">
                                                        <hr className="col-12 my-1 filter-divider" />
                                                    </div>
                                                </>
                                            )}

                                            <div
                                                key={i}
                                                className={
                                                    i % 2 === 0
                                                        ? mainClass
                                                        : secClass
                                                }
                                            >
                                                <Field
                                                    key={i}
                                                    name={field.name}
                                                    label={field.label}
                                                    group={field.name}
                                                    type="checkbox"
                                                    readOnly={
                                                        this.props.view ===
                                                        'view'
                                                    }
                                                    disabled={
                                                        this.props.view ===
                                                        'view'
                                                    }
                                                    component={RenderCheckbox}
                                                    className={
                                                        this.props.view ===
                                                        'view'
                                                            ? i <= 3
                                                                ? 'mt-1'
                                                                : ''
                                                            : field.className
                                                    }
                                                />
                                            </div>
                                        </>
                                    );
                                } else {
                                    elem = (
                                        <div
                                            key={i}
                                            className={
                                                i % 2 === 0
                                                    ? mainClass
                                                    : secClass
                                            }
                                        >
                                            {field.name && (
                                                <Field
                                                    key={i}
                                                    name={field.name}
                                                    type={field.type}
                                                    placeholder={
                                                        field.placeholder
                                                    }
                                                    readOnly={
                                                        this.props.view ===
                                                        'view'
                                                    }
                                                    disabled={
                                                        this.props.view ===
                                                        'view'
                                                    }
                                                    parse={
                                                        field.type ===
                                                            'number' ||
                                                        isFieldImo
                                                            ? parseNumberValue
                                                            : null
                                                    }
                                                    validate={
                                                        isFieldImo
                                                            ? [
                                                                  imoValidationError
                                                              ]
                                                            : null
                                                    }
                                                    maxLength={
                                                        isFieldImo ? '7' : null
                                                    }
                                                    options={
                                                        field.type === 'select'
                                                            ? field.name ===
                                                              'locations'
                                                                ? this.props
                                                                      .locations
                                                                : field.options
                                                            : null
                                                    }
                                                    multi={
                                                        field.multipleDropdown
                                                            ? field.multipleDropdown
                                                            : false
                                                    }
                                                    label={
                                                        field.type === 'select'
                                                            ? field.label
                                                            : null
                                                    }
                                                    clearable={true}
                                                    component={
                                                        field.type === 'select'
                                                            ? RenderReactSelect
                                                            : RenderField
                                                    }
                                                />
                                            )}
                                        </div>
                                    );
                                }

                                return this.props.view === 'view' ||
                                    this.props.view !== 'view'
                                    ? elem
                                    : null;
                            })}

                            {this.props.viewFields.length > 0 &&
                                this.props.view !== 'view' && (
                                    <div className="col-11 text-right mb-1">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={submitting}
                                        >
                                            Save
                                        </Button>{' '}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        changeFieldValue: (field, value) =>
            dispatch(change(ownProps.form, field, value)),
        fetchManageYachtType: (e) => dispatch(fetchManageYachtType(e)),
        fetchGetYachtType: (e) => dispatch(fetchGetYachtType(e)),
        fetchManageYachts: (e) => dispatch(fetchManageYachts(e)),
        notification: (title, message) => {
            let notificationOpts = {
                position: 'tc',
                autoDismiss: 0,
                title: title,
                message: message
            };

            dispatch(Notifications.error(notificationOpts));
        }
    };
}

function mapStateToProps(state, ownProps) {
    const selector = formValueSelector(ownProps.form);
    let initialValues = {};

    let viewFields = [].concat(ownProps.fields);

    if (Object.keys(initialValues).length === 0) {
        let values = {};
        if (ownProps.initYacht && ownProps.initYacht.id) {
            if (ownProps.initialValues) {
                values = Object.assign(values, ownProps.initialValues);
            }
            ownProps.fields.forEach((f) => {
                if (f.name) {
                    //check for empty fields (used for styling)
                    if (ownProps.type === 'generic') {
                        values[f.name] = state.yachts.yacht.initYacht[f.name]
                            ? state.yachts.yacht.initYacht[f.name]
                            : '';
                        if (f.name === 'generic_info') {
                            values[f.name] = state.yachts.yacht.initYacht[
                                'info'
                            ]
                                ? state.yachts.yacht.initYacht['info']
                                : '';
                        }
                    } else {
                        values[f.name] =
                            state.yachts.yacht.initYachtTypes[ownProps.type] &&
                            state.yachts.yacht.initYachtTypes[ownProps.type][
                                f.name
                            ]
                                ? state.yachts.yacht.initYachtTypes[
                                      ownProps.type
                                  ][f.name]
                                : '';
                    }
                    if (ownProps.type === 'charter_info') {
                        if (
                            state.yachts.yacht.initYacht.locations &&
                            state.yachts.yacht.initYacht.locations.length !== 0
                        ) {
                            values.locations = state.yachts.yacht.initYacht.locations.map(
                                (value) => {
                                    return value.id;
                                }
                            );
                        } else if (ownProps.view !== 'view') {
                            values.locations = [{}];
                        }
                    }
                    if (f.type === 'number') {
                        if (
                            state.yachts.yacht.initYachtTypes[ownProps.type] &&
                            state.yachts.yacht.initYachtTypes[ownProps.type][
                                f.name + '_unit'
                            ]
                        ) {
                            values[f.name + '_unit'] =
                                state.yachts.yacht.initYachtTypes[
                                    ownProps.type
                                ][f.name + '_unit'];
                        }

                        if (f.unit === true && values[f.name] !== '') {
                            values[f.name] = functions.unit(
                                values[f.name],
                                values[f.name + '_unit'],
                                ownProps.view === 'view'
                            );
                        }
                        if (f.volume_unit && values[f.name] !== '') {
                            values[f.name] = functions.volumeUnit(
                                values[f.name],
                                values[f.name + '_unit'],
                                ownProps.view === 'view'
                            );
                        }
                    } else if (f.type === 'date') {
                        if (
                            moment(values[f.name]).format('YYYY-MM-DD') ===
                            'Invalid date'
                        ) {
                            values[f.name] = '';
                        }
                    } else if (f.type === 'price') {
                        if (
                            state.yachts.yacht.initYachtTypes[ownProps.type] &&
                            state.yachts.yacht.initYachtTypes[ownProps.type][
                                f.name.replace('_displayed', '') + '_currency'
                            ]
                        ) {
                            values[
                                f.name.replace('_displayed', '') + '_currency'
                            ] =
                                state.yachts.yacht.initYachtTypes[
                                    ownProps.type
                                ][
                                    f.name.replace('_displayed', '') +
                                        '_currency'
                                ];
                            delete values[f.name + '_currency'];
                        }

                        if (ownProps.view === 'view') {
                            values[f.name] = functions.currency(
                                values[f.name],
                                values[
                                    f.name.replace('_displayed', '') +
                                        '_currency'
                                ],
                                true
                            );
                        } else {
                            values[f.name] = functions.thousands(
                                values[f.name],
                                values[
                                    f.name.replace('_displayed', '') +
                                        '_currency'
                                ]
                            );
                        }
                    } else if (f.name === ownProps.type + '_notes') {
                        //this is useless, should either be above textarea block or deleted
                        if (
                            state.yachts.yacht.initYachtTypes[ownProps.type] &&
                            state.yachts.yacht.initYachtTypes[ownProps.type][
                                'notes'
                            ]
                        ) {
                            values[f.name] =
                                state.yachts.yacht.initYachtTypes[
                                    ownProps.type
                                ]['notes'];
                        }

                        let t = $('#' + ownProps.type + '_notes')
                            .prev()
                            .children()
                            .text();
                        if (
                            (t.length === 0 || t.length === 1) &&
                            values[f.name] !== '' &&
                            !initialState[f.name]
                        ) {
                            initialState[f.name] = true;

                            $('#' + ownProps.type + '_notes').redactor(
                                'code.set',
                                values[f.name]
                            );
                        }
                    } else if (f.type === 'textarea') {
                        let fieldName = f.name;

                        if (f.name === ownProps.type + '_info') {
                            fieldName = ownProps.type + '_info';

                            if (
                                state.yachts.yacht.initYachtTypes[
                                    ownProps.type
                                ] &&
                                state.yachts.yacht.initYachtTypes[
                                    ownProps.type
                                ]['info']
                            ) {
                                values[f.name] =
                                    state.yachts.yacht.initYachtTypes[
                                        ownProps.type
                                    ]['info'];
                            }
                        } else {
                            if (
                                state.yachts.yacht.initYachtTypes[
                                    ownProps.type
                                ] &&
                                state.yachts.yacht.initYachtTypes[
                                    ownProps.type
                                ][f.name]
                            ) {
                                values[f.name] =
                                    state.yachts.yacht.initYachtTypes[
                                        ownProps.type
                                    ][f.name];
                            }
                        }

                        let t = $('#' + fieldName)
                            .prev()
                            .children()
                            .text();
                        if (
                            (t.length === 0 || t.length === 1) &&
                            values[f.name] !== '' &&
                            !initialState[f.name]
                        ) {
                            initialState[f.name] = true;

                            $('#' + fieldName).redactor(
                                'code.set',
                                values[f.name]
                            );
                        }
                    }

                    if (values[f.name] === '' && ownProps.view === 'view') {
                        viewFields = _without(viewFields, f);
                    }
                }
            });
        } else {
            if (ownProps.initialValues) {
                values = Object.assign(values, ownProps.initialValues);
            }

            values.locations = [{}];
        }

        initialValues = Object.assign(initialValues, values);
    }

    return {
        date_visited: selector(state, 'date_visited'),
        listing_date: selector(state, 'listing_date'),
        expiration_date: selector(state, 'expiration_date'),
        sold_date: selector(state, 'sold_date'),
        date_recorded: selector(state, 'date_recorded'),
        last_service: selector(state, 'last_service'),
        initialValues: initialValues,
        initYachtTypes: state.yachts.yacht.initYachtTypes,
        locations: state.common.locations.map((val) => {
            return { value: val.id, label: val.name };
        }),
        viewFields: viewFields,
        realRates: state.rates.rates.rates
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(form(FormTypes));
