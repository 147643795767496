import React, { Component } from 'react';

import WantsToBuy from './WantsToBuy';
import SalesAgreement from './SalesAgreement';
import WantsToSell from './WantsToSell';

class SectionClientSettings extends Component {
  render() {
    return (
      <div className="col-12 grouped-panel-wrapper">
        {this.props.sales_agreement === true &&
          this.props.user_role !== 'broker_charter' && (
            <div className="grouped-panel">
              <div className="row">
                <div className="col-12">
                  <SalesAgreement
                    readOnly={this.props.readOnly}
                    dateChange={this.props.dateChange}
                    ca_sales_termination_date={this.props.ca_sales_termination_date}
                    initialValues={this.props.initialValues}
                    changeFieldValue={this.props.changeFieldValue}
                    dropdownList={this.props.dropdownList}
                    searchChange={this.props.searchChange}
                    onClickList={this.props.onClickList}
                    openSearch={this.props.openSearch}
                  />
                </div>
              </div>
            </div>
          )}
        {this.props.wants_to_buy === true &&
          this.props.user_role !== 'broker_charter' && (
            <div className="grouped-panel">
              <div className="row">
                <div className="col-12">
                  <WantsToBuy
                    readOnly={this.props.readOnly}
                    changeFieldValue={this.props.changeFieldValue}
                    new_tag={this.props.new_tag}
                  />
                </div>
              </div>
            </div>
          )}
        {this.props.wants_to_sell === true &&
          this.props.user_role !== 'broker_charter' && (
            <div className="grouped-panel">
              <div className="row">
                <div className="col-12">
                  <WantsToSell
                    readOnly={this.props.readOnly}
                    dateChange={this.props.dateChange}
                    dropdownList={this.props.dropdownList}
                    searchChange={this.props.searchChange}
                    onClickList={this.props.onClickList}
                    openSearch={this.props.openSearch}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default SectionClientSettings;
