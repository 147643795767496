import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { reduxForm, Field, change } from 'redux-form';

import Loader from '../../../common/components/Loader';
import YachtMenu from '../components/YachtMenu';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';

// Fields

import RenderTextarea from '../../../common/components/RenderTextarea';
import RenderField from '../../../common/components/RenderField';

// Helpers
import { errorHandler } from 'helpers/alerts';
import { getTestimonial } from 'services/testimonials';

import moment from 'moment';

export default function ViewYachtTestimonial(props) {
    const user_role = useSelector((state) => state.auth.user_role);
    const history = useHistory();
    const match = useRouteMatch();
    const params = useParams();
    const isReadOnly = true;
    const dispatch = useDispatch();

    const [loadingTestimonial, setLoadingTestimonial] = useState(false);
    const [testimonial, setTestimonial] = useState(null);

    useEffect(() => {
        loadTestimonial();
    }, []);

    const loadTestimonial = async () => {
        setLoadingTestimonial(true);
        try {
            const { data } = await getTestimonial(
                params.id,
                params.testimonialId
            );
            setTestimonial(data);
            //props.initialize({ ...data, date: moment(data.date) });
            dispatch(change('view_yacht_testimonial', 'title', data.title));
            dispatch(
                change(
                    'view_yacht_testimonial',
                    'description',
                    data.description
                )
            );
            dispatch(
                change(
                    'view_yacht_testimonial',
                    'date',
                    moment(data.date).format('DD/MM/YYYY')
                )
            );
            dispatch(
                change(
                    'view_yacht_testimonial',
                    'created_at',
                    moment(data.created_at).format('DD/MM/YYYY')
                )
            );
            dispatch(
                change(
                    'view_yacht_testimonial',
                    'updated_at',
                    moment(data.updated_at).format('DD/MM/YYYY')
                )
            );
        } catch (err) {
            errorHandler(dispatch, err?.response || err);
        } finally {
            setLoadingTestimonial(false);
        }
    };

    return (
        <div className="content-inner-wrapper">
            <YachtMenu user_role={user_role} match={match} history={history} />

            <div className="content-inner">
                {props.loading ? (
                    <Loader style={{ marginTop: '120px' }} />
                ) : (
                    <Row>
                        <Col
                            xs="12"
                            xl="8"
                            className="d-flex align-items-center justify-content-between mt-1 mb-2"
                        >
                            <h4 className="m-0">VIEW TESTIMONIAL</h4>
                            {isReadOnly && (
                                <div className="d-flex">
                                    {
                                        <NavLink
                                            to={`/yachts/view/charter/${params.id}/testimonials`}
                                            className="btn btn-primary btn-search"
                                        >
                                            Back to yacht testimonials
                                        </NavLink>
                                    }
                                </div>
                            )}
                        </Col>

                        <Col xs="12" xl="8" className="mt-2">
                            <Row>
                                <Col
                                    xs="12"
                                    md="6"
                                    className="customDatePicker"
                                >
                                    <Field
                                        name="title"
                                        type="text"
                                        component={RenderField}
                                        readOnly={isReadOnly}
                                        placeholder="NAME"
                                        value={'kostas'}
                                    />
                                </Col>

                                <Col
                                    xs="12"
                                    md="6"
                                    className="customDatePicker"
                                >
                                    <Field
                                        name="date"
                                        component={RenderField}
                                        placeholder="TESTIMONIAL DATE"
                                        readOnly={isReadOnly}
                                        date_format="YYYY/MM/DD"
                                    />
                                </Col>

                                {isReadOnly && (
                                    <Col xs="12" md="6">
                                        <Field
                                            name="created_at"
                                            component={RenderField}
                                            placeholder="CREATED ON"
                                            readOnly={isReadOnly}
                                        />
                                    </Col>
                                )}

                                {isReadOnly && (
                                    <Col xs="12" md="6">
                                        <Field
                                            name="updated_at"
                                            component={RenderField}
                                            placeholder="UPDATED ON"
                                            readOnly={isReadOnly}
                                        />
                                    </Col>
                                )}

                                <Col xs="12">
                                    <label className="upload-label text-uppercase mt-2">
                                        Description
                                    </label>

                                    <Field
                                        rows="30"
                                        name="description"
                                        component={RenderTextarea}
                                        readOnly={isReadOnly}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
}

const ViewTestimonialForm = (ViewYachtTestimonial = reduxForm({
    form: 'view_yacht_testimonial'
})(ViewYachtTestimonial));
