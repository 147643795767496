import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import ManageLocationDecorator from './ManageLocationDecorator';
import ManageLocationPresenter from './ManageLocationPresenter';

import customers from '../../assets/svgs/customers.svg';

import { initLocation, fetchCreateLocation } from './redux/_actions';

class CreateLocationContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                parent: customers,
                items: [
                    <NavLink exact to={'/locations'}>
                        All
                    </NavLink>,
                    <NavLink exact to={'/locations/create'}>
                        Create
                    </NavLink>
                ]
            }
        };
    }

    componentDidMount() {
        this.props.initLocation();
    }

    render() {
        return (
            <ManageLocationPresenter
                menu={this.state.menu}
                onSubmit={this.onSubmit.bind(this)}
                onAddNewPhoto={this.props.onAddNewPhoto}
            />
        );
    }

    onSubmit(form_props) {
        const params = this.props.validateFormProps(form_props);

        this.props.fetchCreateLocation(params).then(response => {
            if (response) {
                this.props.history.push('/locations');
            }
        });
    }
}

const mapDispatchToProps = dispatch => {
    return {
        initLocation: params => dispatch(initLocation(params)),
        fetchCreateLocation: params => dispatch(fetchCreateLocation(params))
    };
};

export default connect(
    state => ({}),
    mapDispatchToProps
)(ManageLocationDecorator()(CreateLocationContainer));
